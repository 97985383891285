import React from 'react';
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import { TEXT_TRANSFORMATION_DATA } from '../../../../constant';

type TextTransformationPropsType = {
  description?: string;
  transformation: [];
  setTransformation: (val: []) => void;
};

function TextTransformation({ description, transformation, setTransformation }: TextTransformationPropsType) {
  const handleAddNewItem = () => {
    const item = {
      id: (Math.random() + 1).toString(36).substring(2, 8),
      value: {
        name: '',
        value: '',
      },
    };

    const arr: any = [...transformation];
    arr.push(item);
    setTransformation(arr);
  };

  const handleChangeItem = (id: string, value: DropdownListDataType) => {
    const arr: any = [...transformation];

    const index = arr.findIndex((e: { id: string }) => e.id === id);

    if (index < 0) return;

    arr[index] = {
      ...arr[index],
      value,
    };

    setTransformation(arr);
  };

  const handleRemoveItem = (id: string) => {
    const arr: any = [...transformation].filter((e: { id: string }) => e.id !== id);

    setTransformation(arr);
  };

  return (
    <div>
      <p className="rule-container-group-content-label">Text transformation</p>

      <p className="rule-container-group-content-description">
        {description ||
          'AWS WAF applies all transformations to the request before evaluating it. If multiple text transformations are added, then text transformations are applied in the order presented below with the top of the list being applied first.'}
      </p>

      <div className="list-container">
        {transformation?.length
          ? transformation.map(({ id, value }: { id: string; value: DropdownListDataType }) => (
              <div key={id} className="item-container">
                <DropdownAtom
                  id={`dropdown-text-transform-${id}`}
                  className="mw-50"
                  data={TEXT_TRANSFORMATION_DATA}
                  placeholder="Choose an inspection option"
                  value={value}
                  handleClick={val => handleChangeItem(id, val)}
                />

                <button className="btn-default" onClick={() => handleRemoveItem(id)}>
                  Remove
                </button>
              </div>
            ))
          : null}
      </div>

      <button className="btn-primary space-8" onClick={handleAddNewItem}>
        Add text transformation
      </button>

      <p className="rule-container-group-content-description">You can add up to 10 text transformations.</p>
    </div>
  );
}

export default TextTransformation;
