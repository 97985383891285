import { gql } from '@apollo/client';

const query = gql`
query getAwsKeyRotationStatus($request: AwsGetKeyRotationStatusRequest, $cloudId: Long, $region: String) {
  getAwsKeyRotationStatus(
    getKeyRotationStatusRequest: $request
    cloudId: $cloudId
    region: $region
  ) {
    result
    nextToken
    data {
      ... on AwsGetKeyRotationStatusResponse {
        keyRotationEnabled
        keyId
        rotationPeriodInDays
        nextRotationDate
        onDemandRotationStartDate
      }
    }
  }
}`;
export default query;