import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsDescribeSubnetsResponse } from 'graphql/types/AwsSubnet';

export interface IAwsSubnetsVariables {
  cloudId: number;
  region: string;
  request: {
    maxResults: number;
    subnetIds?: string[];
    dryRun?: boolean;
    filters?: {
      name: string;
      values: string[];
    }
  }
}

export interface IAwsSubnetsResponseData {
  getAwsSubnets: IGqlResponseNewData<AwsDescribeSubnetsResponse>;
}

const lazyGetAwsSubnetsPageByParam = () => useLazyQuery<IAwsSubnetsResponseData, IAwsSubnetsVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsSubnetsPageByParam;