import React from 'react';
import DetailTab from "pages/v2/Organ/Management/EC2/components/DetailTab";
import {IMgdDetailKeyValueProps, TextTypeEnum} from "layouts/v3/MgdLayout";

function Details({data, isLoading, handleLinkDetail}: any) {
  const {
    volumeId,
    size,
    volumeType,
    state,
    iops,
    throughput,
    encryption,
    kmsKeyId,
    fastRestored,
    snapshotId,
    availabilityZone,
    createTime,
    multiAttachEnabled,
    attachments,
    volumeStatus,
    alias
  } = data || {}

  const detailsData: IMgdDetailKeyValueProps[] = [
    {
      title: 'Volume ID',
      description: volumeId,
      type: TextTypeEnum.COPY_LINK,
      handleItemClick: handleLinkDetail
    },
    {
      title: 'Size',
      description: size ? `${size} GIB` : '',
    },
    {
      title: 'Type',
      description: volumeType,
    },
    {
      title: 'Volume status',
      description: volumeStatus?.volumeStatus?.status ? 'Okay' : '...',
    },
    {
      title: 'Volume state',
      description: state || '...',
    },
    {
      title: 'IOPS',
      description: iops,
    },
    {
      title: 'Throughput',
      description: throughput,
    },
    {
      title: 'Encryption',
      description: encryption ? '...' : 'Not encrypted',
    },
    {
      title: 'KMS key ID',
      description: kmsKeyId,
    },
    {
      title: 'KMS key alias',
      description: alias?.targetKeyId,
    },
    {
      title: 'KMS key ARN',
      description: alias?.aliasName?.replace('alias/', ''),
    },
    {
      title: 'Fast snapshot restored',
      description: fastRestored,
    },
    {
      title: 'SnapShot',
      description: snapshotId,
      type: TextTypeEnum.COPY
    },
    {
      title: 'Availability zone',
      description: availabilityZone,
    },
    {
      title: 'Created',
      description: createTime,
    },
    {
      title: 'Multi-Attach enabled',
      description: multiAttachEnabled,
    },
    {
      title: 'Attached resources',
      description: `${attachments?.[0]?.instanceId}:${attachments?.[0]?.device}(${attachments?.[0]?.state})`,
      type: TextTypeEnum.LINK
    },
    {
      title: 'Outposts',
      description: '',
    },
  ]

  return (
    <DetailTab title='Details' data={detailsData} isApiLoading={isLoading}/>
  );
}

export default Details;