import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useOutletContext } from 'react-router-dom';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import MgdLayout, {
  IMgdDetailKeyValueProps,
  IMgdTabProps,
  IMgdTablePaginationProps,
  IMgdTotalPageProps,
  MgdLayoutTypeEnum,
  TextTypeEnum,
} from 'layouts/v3/MgdLayout';
import DeleteNetworkModal from './DeleteNetworkModal';
import UpdateTagsModal, { TagRowData } from './UpdateTagsModal';
import EditSubnetAssociationModal from './EditSubnetAssociationModal';
import { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import { lazyGetEnumTypeCode } from 'graphql/queries/getEnumTypeCode';
import CurrentStatusCard from 'pages/v2/Organ/Report/components/CurrentStatusCard';
import {
  actionDropdownData,
  actionValue,
  detailVpcKeys,
  detailVpcTextDes,
  detailVpcTabs,
  detailRouteTableTabs,
  detailSubnetTabs,
  detailInternetGWTabs,
  detailNetworkALCTabs,
  mainTabs,
  vpcFilterDropdown,
  routeTableFilterDropdown,
  subnetFilterDropdown,
  internetGWFilterDropdown,
  networkACLFilterDropdown,
  detailInternetGWKeys,
  detailInternetGWTextDes,
  detailTagColumns,
  elasticIPFilterDropdown,
  detailElasticIPTabs,
  detailElasticIPKeys,
  detailElasticIPTextDes,
  detailNATGWTabs,
  detailRoutetableTextDes,
  detailRoutetableKeys,
  detailSubnetTextDes,
  detailSecurityGroupTabs,
  securityGroupFilterDropdown,
  NETWORK_RULES_PROTOCOL_MAPPING,
  natGWFilterDropdown,
  detailSubnetKeys,
  detailNatGWKeys,
  detailNatGWTextDes,
  detailNetworkAclTextDes,
  detailNetworkAclKeys,
  NETWORK_RULES_TYPE_MAPPING,
  SECURITY_GROUP_RULES_TYPE_MAPPING,
} from './Common/Constant';
import lazyGetAwsRouteTables from 'graphql/queries/getAwsRouteTables';
import lazyGetAwsNetworkAcls from 'graphql/queries/getAwsNetworkAcls';
import lazyGetAllAwsInternetGateways from 'graphql/queries/getAwsAllInternetGateways';
import lazyGetAllAwsNetworkAcls from 'graphql/queries/getAwsAllNetworkAcls';
import lazyGetAwsSubnetsPageByParam from 'graphql/queries/getAwsSubnets';
import lazyGetAwsAllAddresses from 'graphql/queries/getAwsAllAddress';
import MgdTooltip from 'components/v2/MgdTooltip';
import lazyGetAwsAvailabilityZones from 'graphql/queries/getAwsAvailabilityZones';
import lazyGetAllAwsSecurityGroups from 'graphql/queries/getAwsAllSecurityGroups';
import lazyGetAllAwsNatGateways from 'graphql/queries/getAwsAllNatGateways';
import lazyGetAwsSubnetCidrReservations from 'graphql/queries/getSubnetCidrReservations';
import lazyIGetResourceShareAssociations from 'graphql/queries/getAwsResourceShareAssociations';
import {
  getNetworkPortRangeValue,
  getNetworkTypeValue,
  getSecurityGroupRuleTypeValue,
} from './Common/Function/Network';
import lazyGetAwsVpnGateways from 'graphql/queries/getAwsVpnGateways';
import lazyGetAwsSecurityGroupRules from 'graphql/queries/getAwsSecurityGroupRules';
import NetWorkACLCreation from 'pages/v2/Organ/Management/Network/NetWorkACLCreation';
import SecurityGroupCreation from 'pages/v2/Organ/Management/Network/SecurityGroupCreation';
import EditNetworkRuleModal, { RuleRowData } from './EditNetworkRuleModal';
import EditSecurityGroupRuleModal, {
  RowSecurityGroupRuleData,
} from 'pages/v2/Organ/Management/Network/EditSecurityGroupRuleModal';
import lazyGetAllAwsVpc from 'graphql/queries/getAwsAllVPC';
import lazyGetAllAwsRouteTable from 'graphql/queries/getAwsAllRouteTable';
import lazyGetAllAwsSubnet from 'graphql/queries/getAwsAllSubnets';
import { orderAlphabetical } from '../Utils/Sorting';
import { variableCombineNextToken } from '../Utils';
import { handleFormatText } from 'utils/Common';
import { FilterPropsType } from '../EC2/components/Image/types';
interface INetworkProps {
  projectId: number;
  cloudName: string;
  regionCode: string;
  regions: string[];
  pageBackClick: () => void;
  currentRouteState: any;
  setCurrentRouteState: (state?: { [key: string]: string } | null) => void;
  recentRelatedClouds: DropdownListDataType[];
  relatedCloudSelected: DropdownListDataType;
  recentRelatedCloudOnChange: (option: DropdownListDataType) => void;
}

interface ITabProps {
  id: string;
  name: string;
  title: string;
  description?: string;
}

const Network = ({
  projectId,
  cloudName,
  regionCode,
  regions,
  pageBackClick,
  currentRouteState,
  setCurrentRouteState,
  recentRelatedClouds,
  relatedCloudSelected,
  recentRelatedCloudOnChange,
}: INetworkProps) => {
  const { state } = useLocation();

  const { defaultRegionList, updateDefaultRegionList } = useOutletContext<{
    defaultRegionList: { projectId: number; cloudId: number; name: string; value: string }[];
    updateDefaultRegionList: (projectId: number, clouId: number, name: string, value: string) => void;
  }>();
  const [screenName, setScreenName] = useState<'DATA_GRID' | 'DETAIL' | 'CREATE_NW_ACL' | 'CREATE_SG'>('DATA_GRID');
  const [initialRender, setInitialRender] = useState<boolean>(false);
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [selected, setSelected] = useState<string>('');
  const [regionList, setRegionList] = useState<DropdownListDataType[]>([]);
  const [selectedRegion, setSelectedRegion] = useState<DropdownListDataType>({
    name: '',
    value: '',
  });
  const [mainTabSelected, setMainTabSelected] = useState<IMgdTabProps>(mainTabs[0]);
  const [mainSearchDropdown, setMainSearchDropdown] = useState<DropdownListDataType[]>(vpcFilterDropdown);
  const [mainFilterValue, setMainFilterValue] = useState<DropdownListDataType>(mainSearchDropdown[0]);
  const [mainSearchValue, setMainSearchValue] = useState<FilterPropsType>({
    name: mainFilterValue.value.toString(),
    value: '',
  });
  const [finalMainSearchValue, setFinalMainSearchValue] = useState<FilterPropsType>();
  const [isSetDefaultFilterfield, setIsSetDefaultFilterfield] = useState<boolean>(true);

  const [layoutType, setLayoutType] = useState<MgdLayoutTypeEnum>(MgdLayoutTypeEnum.MGD_LIST_SUMMARY);
  const [mainSelected, setMainSelected] = useState<string>('');
  const [mainTblRows, setMainTblRows] = useState<RowType[]>([]);
  const [mainNextToken, setMainNextToken] = useState<string>('');
  const [awsVpcPageData, setAwsVpcPageData] = useState<any[]>([]);
  const [awsRouteTablesPageData, setAwsRouteTablesPageData] = useState<any[]>([]);
  const [awsSubnetPageData, setAwsSubnetPageData] = useState<any[]>([]);
  const [awsInternetGWPageData, setAwsInternetGWPageData] = useState<any[]>([]);
  const [awsNatGWPageData, setAwsNatGWPageData] = useState<any[]>([]);
  const [awsElasticIPPageData, setAwsElasticIPPageData] = useState<any[]>([]);
  const [awsNetworkAclsPageData, setAwsNetworkAclsPageData] = useState<any[]>([]);
  const [awsSecurityGroupsPageData, setAwsSecurityGroupsPageData] = useState<any[]>([]);
  const [mainTblColumns, setMainTblColumns] = useState<ColumnType[]>([]);
  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [mainTablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  const [detailApiIsLoading, setDetailApiIsLoading] = useState<boolean>(false);

  const [detailTabList, setDetailTabList] = useState<IMgdTabProps[]>(detailVpcTabs);
  const [detailTabSelected, setDetailTabSelected] = useState<IMgdTabProps>();
  const [detailTblRows, setDetailTblRows] = useState<RowType[]>([]);
  const [detailNextToken, setDetailNextToken] = useState<string>('');
  const [detailTblColumns, setDetailTblColumns] = useState<ColumnType[]>([]);
  const [detailTblTotal, setDetailTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [detailTablePagination, setDetailTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });
  const [mainDelBtnVisible, setMainDelBtnVisible] = useState<boolean>(false);
  const [mainCreateBtnVisible, setMainCreateBtnVisible] = useState<boolean>(false);

  const [isShowDeletePopup, setIsShowDeletePoup] = useState(false);
  const [deletePopupRows, setDeletePopupRows] = useState<RowType[]>([]);
  const [deletePopupColumns, setDeletePopupColumns] = useState<ColumnType[]>([]);
  const [isShowUpdateTagsPopup, setIsShowUpdateTagsPoup] = useState(false);
  const [isShowUpdateRulePopup, setIsShowUpdateRulePopup] = useState(false);
  const [isShowUpdateSecurityGroupRulePopup, setIsShowUpdateSecurityGroupRulePopup] = useState(false);
  const [detailEditBtnVisible, setDetailEditBtnVisible] = useState<boolean>(false);
  const [detailEditBtnTitle, setDetailEditBtnTitle] = useState<string>('');
  const [updateTagsPopupRows, setUpdateTagsPopupRows] = useState<TagRowData[]>([]);
  const [updateTagsPopupColumns, setUpdateTagsPopupColumns] = useState<string[]>([]);
  const [updateTagsPopupHeader, setUpdateTagsPopupHeader] = useState<string>('');
  const [updateTagsPopupSubHeader, setUpdateTagsPopupSubHeader] = useState<string>('');
  const [isShowEditSubnetPopup, setIsShowEditSubnetPoup] = useState(false);
  const [updateRulePopupRows, setUpdateRulePopupRows] = useState<RuleRowData[]>([]);
  const [updateSecurityGroupRulePopupRows, setUpdateSecurityGroupRulePopupRows] = useState<RowSecurityGroupRuleData[]>(
    [],
  );
  const [updateRulePopupHeader, setUpdateRulePopupHeader] = useState<string>('');
  const [isUpdateInboundPopup, setIsUpdateInboundPopup] = useState(true);

  const [getAllAwsVpc, { loading: awsVpcLoading }] = lazyGetAllAwsVpc();
  const [getAwsRouteTables, { loading: awsRouteTableLoading }] = lazyGetAwsRouteTables();
  const [getAllAwsRouteTable, { loading: routeTableLoading }] = lazyGetAllAwsRouteTable();
  const [getAllAwsSubnet, { loading: awsAllSubnetLoading }] = lazyGetAllAwsSubnet();
  const [getAwsNetworkAcls, { loading: awsNetworkAclLoading }] = lazyGetAwsNetworkAcls();
  const [getAllAwsNetworkAcls, { loading: awsNetworkAlcPageLoading }] = lazyGetAllAwsNetworkAcls();
  const [getAwsInternetGateways, { loading: awsIGWPageLoading }] = lazyGetAllAwsInternetGateways();
  const [getAwsVpnGateways, { loading: awsVpnGWLoading }] = lazyGetAwsVpnGateways();
  const [getAllAwsNatGateways, { loading: awsNatGWLoading }] = lazyGetAllAwsNatGateways();
  const [getAwsAddresses, { loading: awsElasticIPLoading }] = lazyGetAwsAllAddresses();
  const [getAwsSubnet, { loading: awsSubnetLoading }] = lazyGetAwsSubnetsPageByParam();
  const [getAwsAvailabilityZones, { loading: awsAZLoading }] = lazyGetAwsAvailabilityZones();
  const [getAwsSubnetCidrReservations, { loading: awsSubnetCIDRResLoading }] = lazyGetAwsSubnetCidrReservations();
  const [getAwsAllSecurityGroups, { loading: awsSGPageLoading }] = lazyGetAllAwsSecurityGroups();
  const [getResourceShareAssociations, { loading: resourceSharedAssLoading }] = lazyIGetResourceShareAssociations();
  const [getAwsSecurityGroupRules, { loading: awsSGLoading }] = lazyGetAwsSecurityGroupRules();

  const [getEnumTypeCode] = lazyGetEnumTypeCode();

  const mainApiIsLoading = useMemo((): boolean => {
    return (
      awsVpcLoading ||
      routeTableLoading ||
      awsAllSubnetLoading ||
      awsNatGWLoading ||
      awsIGWPageLoading ||
      awsElasticIPLoading ||
      awsNetworkAlcPageLoading ||
      awsSGPageLoading ||
      awsNetworkAclLoading ||
      awsRouteTableLoading ||
      awsAZLoading
    );
  }, [
    awsVpcLoading,
    routeTableLoading,
    awsAllSubnetLoading,
    awsNatGWLoading,
    awsIGWPageLoading,
    awsElasticIPLoading,
    awsNetworkAlcPageLoading,
    awsSGPageLoading,
    awsNetworkAclLoading,
    awsRouteTableLoading,
    awsAZLoading,
  ]);
  useEffect(() => {
    setDetailApiIsLoading(
      awsSubnetLoading ||
        awsRouteTableLoading ||
        awsIGWPageLoading ||
        awsVpnGWLoading ||
        awsNetworkAclLoading ||
        awsSubnetCIDRResLoading ||
        resourceSharedAssLoading ||
        awsNetworkAlcPageLoading ||
        awsSGLoading ||
        awsSGPageLoading,
    );
  }, [
    awsSubnetLoading,
    awsRouteTableLoading,
    awsIGWPageLoading,
    awsVpnGWLoading,
    awsNetworkAclLoading,
    awsSubnetCIDRResLoading,
    resourceSharedAssLoading,
    awsNetworkAlcPageLoading,
    awsSGLoading,
    awsSGPageLoading,
  ]);

  const onSearchKeyDownHandler = useCallback(
    (e: any) => {
      if (e.key === 'Enter' && mainSearchValue) {
        if (mainSearchValue.value != '') {
          setFinalMainSearchValue({ name: mainSearchValue.name, value: mainSearchValue.value });
        } else {
          setFinalMainSearchValue(undefined);
        }
      }
    },
    [mainSearchValue],
  );

  const getVariableData = useCallback(
    (requestType: string) => {
      if (
        requestType == mainTabs[0].id ||
        requestType == mainTabs[1].id ||
        requestType == mainTabs[2].id ||
        requestType == mainTabs[3].id ||
        requestType == mainTabs[4].id ||
        requestType == mainTabs[5].id ||
        requestType == mainTabs[6].id ||
        requestType == mainTabs[7].id
      ) {
        if (currentRouteState?.tabId && currentRouteState?.key) {
          return {
            cloudId: relatedCloudSelected.value,
            region: selectedRegion.value as string,
            request: {
              maxResults: mainTablePagination.limit,
              filters: {
                name: currentRouteState?.key,
                values: [currentRouteState?.value],
              },
            },
          };
        }

        if (finalMainSearchValue) {
          return {
            cloudId: relatedCloudSelected.value,
            region: selectedRegion.value as string,
            request: {
              maxResults: mainTablePagination.limit,
              filters: {
                name: finalMainSearchValue?.name || '',
                values: [finalMainSearchValue?.value || ''],
              },
            },
          };
        }
        resetMainStates();
        return {
          cloudId: relatedCloudSelected.value,
          region: selectedRegion.value as string,
          request: {
            maxResults: mainTablePagination.limit,
          },
        };
      }
      return {
        cloudId: relatedCloudSelected.value,
        region: selectedRegion.value as string,
        request: {
          maxResults: mainTablePagination.limit,
        },
      };
    },
    [selectedRegion, finalMainSearchValue, currentRouteState, relatedCloudSelected],
  );

  // reset to the detail first tab selected
  const setDefaultDetailTabSelected = useCallback(() => {
    switch (mainTabSelected) {
      case mainTabs[0]:
        setDetailTabSelected(detailVpcTabs[0]);
        break;
      case mainTabs[1]:
        setDetailTabSelected(detailRouteTableTabs[0]);
        break;
      case mainTabs[2]:
        setDetailTabSelected(detailSubnetTabs[0]);
        break;
      case mainTabs[3]:
        setDetailTabSelected(detailInternetGWTabs[0]);
        break;
      case mainTabs[4]:
        setDetailTabSelected(detailNATGWTabs[0]);
        break;
      case mainTabs[5]:
        setDetailTabSelected(detailElasticIPTabs[0]);
        break;
      case mainTabs[6]:
        setDetailTabSelected(detailNetworkALCTabs[0]);
        break;
      case mainTabs[7]:
        setDetailTabSelected(detailSecurityGroupTabs[0]);
        break;
      default:
        break;
    }
  }, [mainTabSelected]);

  const fetchMoreMainRowsHandler = useCallback(
    (nextToken: string) => {
      switch (mainTabSelected) {
        case mainTabs[0]:
          getVpcMainDataRows(nextToken);
          break;
        case mainTabs[1]:
          getRouteTableMainDataRows(nextToken);
          break;
        case mainTabs[2]:
          getSubnetMainDataRows(nextToken);
          break;
        case mainTabs[3]:
          getInternetGWMainDataRows(nextToken);
          break;
        case mainTabs[4]:
          getNatGatewayMainDataRows(nextToken);
          break;
        case mainTabs[5]:
          getElasticIPMainDataRows(nextToken);
          break;
        case mainTabs[6]:
          getNetworkAclMainDataRows(nextToken);
          break;
        case mainTabs[7]:
          getSecurityGroupMainDataRows(nextToken);
          break;
        default:
          break;
      }
    },
    [mainTabSelected, awsSecurityGroupsPageData],
  );

  const mainRowsCurrentPage = useMemo(() => {
    if (mainTablePagination.currentPage > mainTblTotal.totalPage && !!mainNextToken) {
      fetchMoreMainRowsHandler(mainNextToken);
    }
    const startIndex = (mainTablePagination.currentPage - 1) * mainTablePagination.itemPerPage;
    const endIndex = startIndex + mainTablePagination.itemPerPage;

    return orderAlphabetical(mainTblRows, mainTablePagination.target, mainTablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [mainTblRows, mainTablePagination, mainTblTotal, mainNextToken]);

  const detailRowsCurrentPage = useMemo(() => {
    const startIndex = (detailTablePagination.currentPage - 1) * detailTablePagination.itemPerPage;
    const endIndex = startIndex + detailTablePagination.itemPerPage;

    return orderAlphabetical(detailTblRows, detailTablePagination.target, detailTablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [detailTblRows, detailTablePagination]);

  const getVpcMainDataRows = useCallback(
    (nextToken?: string) => {
      if (selectedRegion.value != '') {
        getAllAwsVpc({
          variables: variableCombineNextToken(getVariableData(mainTabs[0].id), nextToken),
        }).then(({ data: awsVpcResponse }) => {
          const awsVpcData = awsVpcResponse?.getAwsAllVPC;
          if (awsVpcData?.data && awsVpcData.data?.length && awsVpcData.data.length > 0) {
            let awsVpcs: RowType[] = [];
            const awsVpcIds: string[] = [];
            awsVpcData.data.map(vpc => {
              awsVpcIds.push(vpc.vpcId);
              awsVpcs.push({
                ...vpc,
                id: vpc.vpcId,
                name: vpc?.tags?.find(t => t?.key == 'Name')?.value,
                enableDnsHostnames: true,
                enableDnsSupport: false,
                enableNetworkAddressUsageMetrics: false,
                firewallRuleAssociations: null,
              });
            });
            if (awsVpcs.length > 0 && awsVpcIds.length > 0) {
              const awsVariable = {
                cloudId: Number(relatedCloudSelected.value),
                region: selectedRegion.value.toString(),
                request: {
                  maxResults: 50,
                  filters: {
                    name: 'vpc-id',
                    values: awsVpcIds,
                  },
                },
              };
              getAwsRouteTables({ variables: awsVariable }).then(({ data: awsRouteTableData }) => {
                if (awsRouteTableData?.getAwsRouteTables?.data?.[0]?.routeTables) {
                  const awsRouteTableValues = awsRouteTableData?.getAwsRouteTables?.data?.[0]?.routeTables;
                  awsVpcs = awsVpcs.map(vpc => {
                    const routeTables = {
                      ...awsRouteTableValues.filter(
                        (routeTable: any) =>
                          routeTable.vpcId == vpc.vpcId &&
                          routeTable?.associations.find((association: any) => association.main === true),
                      )[0],
                    }

                    return ({
                      ...vpc,
                      routeTables,
                      mainRouteTableId: String(routeTables?.associations[0]?.routeTableId || '-')
                    })
                  });
                }

                getAwsNetworkAcls({ variables: awsVariable }).then(({ data: awsNetworkAclsData }) => {
                  awsVpcs = awsVpcs.map(vpc => {
                    const networkAcls = awsNetworkAclsData?.getAwsNetworkAcls?.data?.[0]?.networkAcls.filter(
                      (netAcl: any) => netAcl.vpcId == vpc.vpcId,
                    )
                    return ({
                      ...vpc,
                      networkAcls,
                      mainNetAclId: String(networkAcls?.length ? networkAcls[0]?.networkAclId : '-')
                    })
                  });

                  let allAwsVpcs: RowType[] = [];
                  if (nextToken) {
                    allAwsVpcs = [...awsVpcPageData, ...awsVpcs];
                  } else {
                    allAwsVpcs = awsVpcs;
                  }
                  setAwsVpcPageData(allAwsVpcs);
                  setMainNextToken(awsVpcData?.nextToken as string);
                  setMainTblRows(allAwsVpcs);
                  setMainTblTotal({
                    totalPage: Math.ceil(allAwsVpcs.length / mainTablePagination.itemPerPage),
                    totalElement: allAwsVpcs.length,
                  });
                });
              });
            }
          } else {
            setAwsVpcPageData([]);
            setMainTblRows([]);
            setMainNextToken('');
          }
        });
      }
    },
    [selectedRegion, awsVpcPageData, mainTablePagination, getVariableData, relatedCloudSelected],
  );

  const getRouteTableMainDataRows = useCallback(
    (nextToken?: string) => {
      if (selectedRegion.value != '') {
        getAllAwsRouteTable({
          variables: variableCombineNextToken(getVariableData(mainTabs[1].id), nextToken),
        }).then(({ data: awsRoutetableData }) => {
          const routeTableData: RowType[] = [];
          const getAwsAllRouteTable = awsRoutetableData?.getAwsAllRouteTable;
          if (getAwsAllRouteTable) {
            getAwsAllRouteTable?.data?.map(routetable => {
              routeTableData.push({
                ...routetable,
                name: routetable?.tags?.find(t => t?.key == 'Name')?.value,
                id: routetable.routeTableId,
                main: routetable?.associations?.length == 1 ? (routetable.associations[0].main ? 'Yes' : 'No') : 'No'
              });
            });
            let allAwsRouteTables: RowType[] = [];
            if (nextToken) {
              allAwsRouteTables = [...awsRouteTablesPageData, ...routeTableData];
            } else {
              allAwsRouteTables = routeTableData;
            }
            setAwsRouteTablesPageData(allAwsRouteTables);
            setMainNextToken(getAwsAllRouteTable?.nextToken as string);
            setMainTblRows(allAwsRouteTables);
            setMainTblTotal({
              totalPage: Math.ceil(allAwsRouteTables.length / mainTablePagination.itemPerPage),
              totalElement: allAwsRouteTables.length,
            });
          } else {
            setAwsRouteTablesPageData([]);
            setMainTblRows([]);
            setMainNextToken('');
          }
        });
      }
    },
    [selectedRegion, awsRouteTablesPageData, mainTablePagination, getVariableData],
  );

  const getSubnetMainDataRows = useCallback(
    (nextToken?: string) => {
      if (selectedRegion.value != '') {
        getAllAwsSubnet({ variables: variableCombineNextToken(getVariableData(mainTabs[2].id), nextToken) }).then(
          ({ data: awsSubnetData }) => {
            const subnetDataResult: RowType[] = [];
            const getAwsAllSubnets = awsSubnetData?.getAwsAllSubnets;

            if (getAwsAllSubnets) {
              const subnetIds = awsSubnetData.getAwsAllSubnets?.data?.map(subnet => {
                return subnet.subnetId;
              });
              const vpcIds = awsSubnetData.getAwsAllSubnets?.data?.map(subnet => {
                return subnet.vpcId;
              });
              const zoneIds = awsSubnetData.getAwsAllSubnets?.data?.map(subnet => {
                return subnet.availabilityZoneId;
              });

              const variable = {
                cloudId: Number(relatedCloudSelected.value),
                region: selectedRegion.value as string,
                request: {
                  zoneIds: zoneIds,
                },
              };

              getAwsAvailabilityZones({ variables: variable }).then(({ data: availabilityZone }) => {
                const routetableVar = {
                  cloudId: Number(relatedCloudSelected.value),
                  region: selectedRegion.value as string,
                  request: {
                    maxResults: 50,
                    filters: {
                      name: 'association.subnet-id',
                      values: subnetIds,
                    },
                  },
                };
                getAwsRouteTables({ variables: routetableVar }).then(({ data: routetableData }) => {
                  const networkAclVar = {
                    cloudId: Number(relatedCloudSelected.value),
                    region: selectedRegion.value as string,
                    request: {
                      maxResults: 50,
                      filters: {
                        name: 'vpc-id',
                        values: vpcIds,
                      },
                    },
                  };
                  getAwsNetworkAcls({ variables: networkAclVar }).then(({ data: networkAclData }) => {
                    const zones = availabilityZone?.getAwsAvailabilityZones?.data.availabilityZones;
                    const routeTables = routetableData?.getAwsRouteTables?.data?.[0]?.routeTables;
                    const networkAcls = networkAclData?.getAwsNetworkAcls?.data?.[0]?.networkAcls;
                    const subnets = awsSubnetData?.getAwsAllSubnets?.data;

                    let listRouteTableWithSubnet: any[] = [];
                    let listNetworkAclWithSubnet: any[] = [];

                    routeTables?.forEach(item => {
                      listRouteTableWithSubnet = listRouteTableWithSubnet.concat(item?.associations);
                    });
                    networkAcls?.forEach(item => {
                      listNetworkAclWithSubnet = listNetworkAclWithSubnet.concat(item?.associations);
                    });

                    subnets?.map(subnet => {
                      const routeTable = listRouteTableWithSubnet?.find(item => item.subnetId === subnet.subnetId);
                      const networkAcl = listNetworkAclWithSubnet?.find(item => item.subnetId === subnet.subnetId);
                      const zone = zones?.find(item => item.zoneId === subnet.availabilityZoneId);

                      subnetDataResult.push({
                        ...subnet,
                        id: subnet.subnetId,
                        name: subnet?.tags?.find(t => t?.key == 'Name')?.value,
                        routeTableId: routeTable?.routeTableId ? routeTable.routeTableId : '-',
                        networkAclId: networkAcl?.networkAclId ? networkAcl.networkAclId : '-',
                        networkBorderGroup: zone?.networkBorderGroup ? zone?.networkBorderGroup : '-',
                      });
                    });

                    let allAwsSubnets: RowType[] = [];
                    if (nextToken) {
                      allAwsSubnets = [...awsSubnetPageData, ...subnetDataResult];
                    } else {
                      allAwsSubnets = subnetDataResult;
                    }
                    setAwsSubnetPageData(allAwsSubnets);
                    setMainNextToken(getAwsAllSubnets?.nextToken as string);
                    setMainTblRows(allAwsSubnets);
                    setMainTblTotal({
                      totalPage: Math.ceil(allAwsSubnets.length / mainTablePagination.itemPerPage),
                      totalElement: allAwsSubnets.length,
                    });
                  });
                });
              });
            } else {
              setAwsSubnetPageData([]);
              setMainTblRows([]);
              setMainNextToken('');
            }
          },
        );
      }
    },
    [selectedRegion, awsSubnetPageData, mainTablePagination, getVariableData, relatedCloudSelected],
  );

  const getInternetGWMainDataRows = useCallback(
    (nextToken?: string) => {
      if (selectedRegion.value != '') {
        getAwsInternetGateways({
          variables: variableCombineNextToken(getVariableData(mainTabs[3].id), nextToken),
        }).then(({ data: awsInternetGWData }) => {
          const internetGWData: RowType[] = [];
          if (awsInternetGWData) {
            const getAwsAllInternetGateways = awsInternetGWData.getAwsAllInternetGateways;
            getAwsAllInternetGateways?.data?.map(internetGW => {
              internetGWData.push({
                ...internetGW,
                id: internetGW.internetGatewayId,
                name: internetGW?.tags?.find(t => t?.key == 'Name')?.value,
                state: internetGW?.attachments && internetGW.attachments[0]?.state,
                vpcId: internetGW?.attachments && internetGW.attachments[0]?.vpcId,
              });
            });
            let allAwsInternetGW: RowType[] = [];
            if (nextToken) {
              allAwsInternetGW = [...awsInternetGWPageData, ...internetGWData];
            } else {
              allAwsInternetGW = internetGWData;
            }
            setAwsInternetGWPageData(allAwsInternetGW);
            setMainNextToken(getAwsAllInternetGateways?.nextToken as string);
            setMainTblRows(allAwsInternetGW);
            setMainTblTotal({
              totalPage: Math.ceil(allAwsInternetGW.length / mainTablePagination.limit),
              totalElement: allAwsInternetGW.length,
            });
          } else {
            setAwsInternetGWPageData([]);
            setMainTblRows([]);
            setMainNextToken('');
          }
        });
      }
    },
    [selectedRegion, awsInternetGWPageData, mainTablePagination, getVariableData],
  );

  const getNatGatewayMainDataRows = useCallback(
    (nextToken?: string) => {
      if (selectedRegion.value != '') {
        getAllAwsNatGateways({ variables: variableCombineNextToken(getVariableData(mainTabs[4].id), nextToken) }).then(
          ({ data: awsNatGWData }) => {
            const natGWData: RowType[] = [];
            if (awsNatGWData) {
              const getAwsAllNatGateways = awsNatGWData.getAwsAllNatGateways;
              getAwsAllNatGateways?.data?.forEach(item => {
                natGWData.push({
                  ...item,
                  id: item.natGatewayId,
                  name: item?.tags?.find(t => t?.key == 'Name')?.value,
                  stateMessage: '-',
                  primaryPublicIpv4Address: item.natGatewayAddresses[0].isPrimary
                    ? item.natGatewayAddresses[0].publicIp
                    : '-',
                  primaryPrivateIpv4Address: item.natGatewayAddresses[0].privateIp,
                  primaryNetworkInterfaceId: item.natGatewayAddresses[0].networkInterfaceId,
                });
              });
              let allAwsNatGW: RowType[] = [];
              if (nextToken) {
                allAwsNatGW = [...awsNatGWPageData, ...natGWData];
              } else {
                allAwsNatGW = natGWData;
              }
              setAwsNatGWPageData(allAwsNatGW);
              setMainNextToken(getAwsAllNatGateways?.nextToken as string);
              setMainTblRows(allAwsNatGW);
              setMainTblTotal({
                totalPage: Math.ceil(allAwsNatGW.length / mainTablePagination.limit),
                totalElement: allAwsNatGW.length,
              });
            } else {
              setAwsNatGWPageData([]);
              setMainTblRows([]);
              setMainNextToken('');
            }
          },
        );
      }
    },
    [selectedRegion, awsNatGWPageData, mainTablePagination, getVariableData],
  );

  const getElasticIPMainDataRows = useCallback(
    (nextToken?: string) => {
      if (selectedRegion.value != '') {
        const variable = getVariableData(mainTabs[5].id);
        const elasticVariable = {
          cloudId: variable.cloudId,
          region: variable.region,
          request: {
            filters: {...variable.request.filters}
          },
        };
        getAwsAddresses({ variables: variableCombineNextToken(elasticVariable, nextToken) }).then(
          ({ data: awsAddressesData }) => {
            if (awsAddressesData) {
              const elasticIPData: RowType[] = [];
              const getAwsAddresses = awsAddressesData?.getAwsAddresses;
              getAwsAddresses?.data?.map(address => {
                elasticIPData.push({
                  ...address,
                  id: address.publicIp,
                  name: address?.tags?.find(t => t?.key == 'Name')?.value,
                  type: address?.publicIp ? 'Public IP' : '-',
                  reverseDNSRecord: '-',
                  associatedInstanceId: '-',
                });
              });
              let allAwsElasticIP: RowType[] = [];
              if (nextToken) {
                allAwsElasticIP = [...awsElasticIPPageData, ...elasticIPData];
              } else {
                allAwsElasticIP = elasticIPData;
              }
              setAwsElasticIPPageData(allAwsElasticIP);
              setMainNextToken(getAwsAddresses?.nextToken as string);
              setMainTblRows(allAwsElasticIP);
              setMainTblTotal({
                totalPage: Math.ceil(allAwsElasticIP.length / mainTablePagination.limit),
                totalElement: allAwsElasticIP.length,
              });
            } else {
              setAwsElasticIPPageData([]);
              setMainTblRows([]);
              setMainNextToken('');
            }
          },
        );
      }
    },
    [selectedRegion, awsElasticIPPageData, mainTablePagination, getVariableData],
  );

  const getNetworkAclMainDataRows = useCallback(
    (nextToken?: string) => {
      if (selectedRegion.value != '') {
        getAllAwsNetworkAcls({ variables: variableCombineNextToken(getVariableData(mainTabs[6].id), nextToken) }).then(
          ({ data: awsNetworkAclData }) => {
            if (awsNetworkAclData) {
              const networkAclData: RowType[] = [];
              const getAwsAllNetworkAcls = awsNetworkAclData.getAwsAllNetworkAcls;
              getAwsAllNetworkAcls?.data?.map(networkAcl => {
                networkAclData.push({
                  ...networkAcl,
                  id: networkAcl.networkAclId,
                  name: networkAcl?.tags?.find(t => t?.key == 'Name')?.value,
                  inbound: `${networkAcl?.entries?.filter((e: { egress: boolean }) => e?.egress == false)?.length} Inbound rules`,
                  outbound: `${networkAcl?.entries?.filter((e: any) => e?.egress == true)?.length} Outbound rules`
                });
              });
              let allAwsNetworkAcls: RowType[] = [];
              if (nextToken) {
                allAwsNetworkAcls = [...awsNetworkAclsPageData, ...networkAclData];
              } else {
                allAwsNetworkAcls = networkAclData;
              }
              setAwsNetworkAclsPageData(allAwsNetworkAcls);
              setMainNextToken(getAwsAllNetworkAcls?.nextToken as string);
              setMainTblRows(allAwsNetworkAcls);
              setMainTblTotal({
                totalPage: Math.ceil(allAwsNetworkAcls.length / mainTablePagination.limit),
                totalElement: allAwsNetworkAcls.length,
              });
            } else {
              setAwsElasticIPPageData([]);
              setMainTblRows([]);
              setMainNextToken('');
            }
          },
        );
      }
    },
    [selectedRegion, awsNetworkAclsPageData, mainTablePagination, getVariableData],
  );

  const getSecurityGroupMainDataRows = useCallback(
    (nextToken?: string) => {
      if (selectedRegion.value != '') {
        getAwsAllSecurityGroups({
          variables: variableCombineNextToken(getVariableData(mainTabs[6].id), nextToken),
        }).then(({ data: awsSecurityGroupData }) => {
          if (awsSecurityGroupData) {
            const securityGroupData: RowType[] = [];
            const getAwsAllSecurityGroup = awsSecurityGroupData.getAwsAllSecurityGroups;
            getAwsAllSecurityGroup?.data?.map(sg => {
              securityGroupData.push({
                ...sg,
                name: sg?.tags?.find(t => t?.key == 'Name')?.value,
                id: sg.groupId,
              });
            });
            let allSecurityGroups: RowType[] = [];
            if (nextToken) {
              allSecurityGroups = [...awsSecurityGroupsPageData, ...securityGroupData];
            } else {
              allSecurityGroups = securityGroupData;
            }
            setAwsSecurityGroupsPageData(allSecurityGroups);
            setMainNextToken(getAwsAllSecurityGroup?.nextToken as string);
            setMainTblRows(allSecurityGroups);
            setMainTblTotal({
              totalPage: Math.ceil(allSecurityGroups.length / mainTablePagination.itemPerPage),
              totalElement: allSecurityGroups.length,
            });
          } else {
            setAwsElasticIPPageData([]);
            setMainTblRows([]);
            setMainNextToken('');
          }
        });
      }
    },
    [selectedRegion, awsSecurityGroupsPageData, mainTablePagination, getVariableData],
  );

  useEffect(() => {
    setMainTablePagination({ ...mainTablePagination, currentPage: 1 });
    switch (mainTabSelected) {
      case mainTabs[0]:
        getVpcMainDataRows();
        break;
      case mainTabs[1]:
        getRouteTableMainDataRows();
        break;
      case mainTabs[2]:
        getSubnetMainDataRows();
        break;
      case mainTabs[3]:
        getInternetGWMainDataRows();
        break;
      case mainTabs[4]:
        getNatGatewayMainDataRows();
        break;
      case mainTabs[5]:
        getElasticIPMainDataRows();
        break;
      case mainTabs[6]:
        getNetworkAclMainDataRows();
        break;
      case mainTabs[7]:
        getSecurityGroupMainDataRows();
        break;
      default:
        break;
    }
  }, [mainTabSelected, getVariableData]);

  useEffect(() => {
    if (screenName) {
      const hasDefaultRegion = defaultRegionList.find(r => {
        return r.projectId === projectId && r.cloudId === relatedCloudSelected.value;
      });
      if (!!hasDefaultRegion) {
        setSelectedRegion({
          name: hasDefaultRegion.name,
          value: hasDefaultRegion.value,
        });
      } else {
        getEnumTypeCode({
          variables: { text: regionCode || 'AwsRegionCode' },
        }).then(res => {
          if (res.data) {
            const regionsRes: DropdownListDataType[] = res.data.getEnumTypeCode.data.filter(val =>
              regions.includes(val.value),
            ).map((val) => ({
              ...val,
              name: val.value
            }));
            setRegionList(regionsRes);
            const currentRegion = regionsRes.find(
              r => r.name === selectedRegion.name && r.value === selectedRegion.value,
            );
            setSelectedRegion(currentRegion || regionsRes[0]);
          }
        });
      }
    }
  }, [screenName, defaultRegionList, relatedCloudSelected]);

  const getDetailsTblDataByTabId = useCallback(() => {
    let detailRows: RowType[] = [];
    let detailColumns: ColumnType[] = [];
    switch (mainTabSelected) {
      case mainTabs[0]:
        if (
          detailTabSelected &&
          (detailTabSelected.id == detailVpcTabs[1].id || detailTabSelected.id == detailVpcTabs[2].id)
        ) {
          const detailData = awsVpcPageData.find(mainData => mainData.id === mainSelected);
          if (detailTabSelected.id == detailVpcTabs[1].id) {
            detailColumns = [
              { label: 'Address type', field: 'addressType', sort: true },
              { label: 'CIDR', field: 'cidr', sort: true },
              {
                label: 'Network border group',
                field: 'networkGroup',
                sort: true,
              },
              { label: 'Pool', field: 'pool', sort: true },
              {
                label: 'Status',
                field: 'currentStatus',
                sort: true,
                renderCell: (row: RowType) => <CurrentStatusCard status={row.currentStatus} />,
              },
            ];
            // get CIDR IPv4
            if (detailData?.cidrBlockAssociationSet[0]) {
              const status = detailData?.cidrBlockAssociationSet[0]?.cidrBlockState?.state
                ? detailData?.cidrBlockAssociationSet[0]?.cidrBlockState?.state
                : '?';
              detailRows.push({
                addressType: 'IPv4',
                cidr: detailData?.cidrBlockAssociationSet?.cidrBlock,
                networkGroup: '-',
                pool: '-',
                currentStatus: status,
              });
            }
            // get CIDR IPv6
            if (detailData?.ipv6CidrBlockAssociationSet) {
              detailRows.push({
                addressType: '...',
                cidr: detailData?.cidrBlockAssociationSet?.cidrBlock,
                networkGroup: '-',
                pool: '-',
                currentStatus: '?',
              });
            }

            setDetailTblTotal({
              totalPage: Math.ceil(detailRows.length / detailTablePagination.limit),
              totalElement: detailRows.length,
            });
          } else {
            detailColumns = [
              { label: 'Key', field: 'key', sort: true },
              { label: 'Value', field: 'value', sort: true },
            ];

            detailData?.tags?.map((tag: any) => {
              detailRows.push({
                key: tag.key,
                value: tag.value,
              });
            });
          }
          setDetailTblRows(detailRows);
        }
        break;
      case mainTabs[1]:
        if (
          detailTabSelected &&
          (detailTabSelected.id == detailRouteTableTabs[1].id ||
            detailTabSelected.id == detailRouteTableTabs[2].id ||
            detailTabSelected.id == detailRouteTableTabs[3].id ||
            detailTabSelected.id == detailRouteTableTabs[4].id ||
            detailTabSelected.id == detailRouteTableTabs[5].id)
        ) {
          const detailsData: any = awsRouteTablesPageData.find(item => item.routeTableId === mainSelected);

          if (detailTabSelected.id == detailRouteTableTabs[1].id) {
            // Routes
            detailColumns = [
              {
                label: 'Destination',
                field: 'destinationCidrBlock',
                sort: true,
              },
              {
                label: 'Target',
                field: 'target',
                sort: true,
                renderCell: (row: RowType) => <div className="link">{handleFormatText(row.target)}</div>,
              },
              { label: 'Status', field: 'status', sort: true },
              { label: 'Propagated', field: 'propagated', sort: true },
            ];

            detailsData.routes.forEach((row: any) => {
              detailRows.push({
                destinationCidrBlock: row.destinationCidrBlock,
                target: row.natGatewayId ? row.natGatewayId : row.gatewayId,
                status: row.state,
                propagated: row.origin === 'EnableVgwRoutePropagation' ? 'Yes' : 'No',
              });
            });
            setDetailTblRows(detailRows);
            setDetailTblTotal({
              totalPage: Math.ceil(detailRows.length / detailTablePagination.limit),
              totalElement: detailRows.length,
            });
          } else if (detailTabSelected.id == detailRouteTableTabs[2].id) {
            // subnet associations
            const awsSubnetVariable = {
              cloudId: Number(relatedCloudSelected.value),
              region: selectedRegion.value as string,
              request: {
                maxResults: 50,
                filters: {
                  name: 'vpc-id',
                  values: [detailsData.vpcId],
                },
              },
            };
            getAwsSubnet({ variables: awsSubnetVariable }).then(({ data: awsSubnetData }) => {
              if (awsSubnetData) {
                const subnetIds = awsSubnetData.getAwsSubnets?.data?.[0].subnets.map(subnet => subnet.subnetId);
                const awsRoutetableVariable = {
                  cloudId: Number(relatedCloudSelected.value),
                  region: selectedRegion.value as string,
                  request: {
                    maxResults: 50,
                    filters: {
                      name: 'association.subnet-id',
                      values: subnetIds,
                    },
                  },
                };
                getAwsRouteTables({ variables: awsRoutetableVariable }).then(({ data: awsRoutetableData }) => {
                  if (awsRoutetableData) {
                    const subnetVpc = awsRoutetableData?.getAwsRouteTables?.data?.[0]?.routeTables[0].associations[0];
                    const subnetRoutetable = awsSubnetData.getAwsSubnets?.data?.[0].subnets;
                    const subnet = subnetRoutetable.find(item => item.subnetId === subnetVpc?.subnetId);
                    const subnetName = subnet?.tags.find(item => item.key === 'Name');

                    detailsData.associations.forEach((row: any) => {
                      detailRows.push({
                        name: subnetName?.value,
                        subnetId: subnet?.subnetId,
                        ipv4cidr: subnet?.cidrBlock,
                        ipv6cidr: '-',
                      });
                    });
                    setDetailTblRows(detailRows);
                    setDetailTblTotal({
                      totalPage: Math.ceil(detailRows.length / detailTablePagination.limit),
                      totalElement: detailRows.length,
                    });
                  }
                });
              } else {
                detailsData.associations.forEach((row: any) => {
                  detailRows.push({
                    name: '-',
                    subnetId: row.subnetId,
                    ipv4cidr: '-',
                    ipv6cidr: '-',
                  });
                });
                setDetailTblRows(detailRows);
              }
            });

            detailColumns = [
              { label: 'Name', field: 'name', sort: true },
              { label: 'Subnet ID', field: 'subnetId', sort: true },
              { label: 'IPv4 CIDR', field: 'ipv4cidr', sort: true },
              { label: 'IPv6 CIDR', field: 'ipv6cidr', sort: true },
            ];
          } else if (detailTabSelected.id == detailRouteTableTabs[3].id) {
            // edge associations
            const gatewayIds = detailsData.associations.map((item: any) => {
              return item.gatewayId ? item.gatewayId : '';
            });

            const internetGWVariable = {
              cloudId: Number(relatedCloudSelected.value),
              region: selectedRegion.value as string,
              request: {
                maxResults: 50,
                filters: {
                  name: 'internet-gateway-id',
                  values: gatewayIds,
                },
                pageInfo: { page: 0, size: 100, orders: [] },
              },
            };
            getAwsInternetGateways({ variables: internetGWVariable }).then(({ data: internetGWData }) => {
              const getAwsAllInternetGateways = internetGWData?.getAwsAllInternetGateways?.data;
              if (getAwsAllInternetGateways?.length && getAwsAllInternetGateways.length > 0) {
                getAwsAllInternetGateways.forEach(item => {
                  const subnetName = item?.tags.find((r: { key: string }) => r.key === 'Name')?.value;
                  detailRows.push({
                    subnetId: subnetName ? subnetName : '-',
                    state: item?.attachments[0]?.state,
                    vpcId: item?.attachments[0]?.vpcId,
                    ownerId: item.ownerId,
                  });
                });
                setDetailTblRows(detailRows);
                setDetailTblTotal({
                  totalPage: 1,
                  totalElement: detailRows.length,
                });
              } else {
                setDetailTblRows([]);
                setDetailTblTotal({
                  totalPage: 1,
                  totalElement: detailRows.length,
                });
              }
            });
            detailColumns = [
              { label: 'ID', field: 'subnetId', sort: true },
              { label: 'State', field: 'state', sort: true },
              { label: 'VPC', field: 'vpcId', sort: true },
              { label: 'Owner', field: 'ownerId', sort: true },
            ];
          } else if (detailTabSelected.id == detailRouteTableTabs[4].id) {
            // route propagation
            const vpnGWVariable = {
              cloudId: Number(relatedCloudSelected.value),
              region: selectedRegion.value as string,
              request: {
                filters: {
                  name: 'attachment.vpc-id',
                  values: [detailsData.vpcId],
                },
              },
            };

            getAwsVpnGateways({ variables: vpnGWVariable }).then(({ data: vpnGWData }) => {
              const vpnGatewaysData = vpnGWData?.getAwsVpnGateways?.data?.vpnGateways;
              if (vpnGatewaysData?.length && vpnGatewaysData.length > 0) {
                vpnGatewaysData.forEach(item => {
                  detailRows.push({
                    virtualPrivateGateway: item.vpnGatewayId,
                    propagation: detailsData.propagatingVgws[0].gatewayId === item.vpnGatewayId ? 'Yes' : 'No',
                  });
                  setDetailTblRows(detailRows);
                  setDetailTblTotal({
                    totalPage: 1,
                    totalElement: detailRows.length,
                  });
                });
              } else {
                setDetailTblRows([]);
                setDetailTblTotal({
                  totalPage: 1,
                  totalElement: detailRows.length,
                });
              }
            });

            detailColumns = [
              {
                label: 'Virtual private gateway',
                field: 'virtualPrivateGateway',
                sort: true,
              },
              { label: 'Propagation', field: 'propagation', sort: true },
            ];
          } else {
            // Tags
            detailColumns = [
              { label: 'Key', field: 'key', sort: true },
              { label: 'Value', field: 'value', sort: true },
            ];
            detailsData?.tags?.map((tag: any) => {
              detailRows.push({
                key: tag.key,
                value: tag.value,
              });
            });
            setDetailTblRows(detailRows);
          }
        }
        break;
      case mainTabs[2]:
        const detailsData: any = awsSubnetPageData.find(item => item.subnetId === mainSelected);
        if (detailTabSelected && detailTabSelected.id == detailSubnetTabs[1].id) {
          // route table tab
          const awsSubnetVariable = {
            cloudId: Number(relatedCloudSelected.value),
            region: selectedRegion.value as string,
            request: {
              maxResults: 50,
              filters: {
                name: 'association.subnet-id',
                values: [detailsData.subnetId],
              },
            },
          };
          getAwsRouteTables({ variables: awsSubnetVariable }).then(({ data: subnetData }) => {
            subnetData?.getAwsRouteTables?.data?.[0]?.routeTables[0]?.routes?.map(item => {
              detailRows.push({
                destination: item.destinationCidrBlock,
                target: item.gatewayId,
              });
            });
            setDetailTblRows(detailRows);
            setDetailTblTotal({
              totalPage: Math.ceil(detailRows.length / detailTablePagination.limit),
              totalElement: detailRows.length,
            });
          });
          detailColumns = [
            { label: 'Destination', field: 'destination', sort: true },
            { label: 'Target', field: 'target', sort: true },
          ];
        } else if (
          (detailTabSelected && detailTabSelected.id == detailSubnetTabs[2].id) ||
          detailTabSelected?.id == detailSubnetTabs[3].id
        ) {
          // network acl inbound/outbound tab
          const awsNetworkAclVariable = {
            cloudId: Number(relatedCloudSelected.value),
            region: selectedRegion.value as string,
            request: {
              maxResults: 50,
              filters: {
                name: 'vpc-id',
                values: [detailsData.vpcId],
              },
            },
          };

          getAwsNetworkAcls({ variables: awsNetworkAclVariable }).then(({ data: networkAclData }) => {
            const data = networkAclData?.getAwsNetworkAcls?.data?.[0]?.networkAcls[0].entries.filter(
              (e: { egress: boolean }) =>
                e.egress === (detailTabSelected.id == detailNetworkALCTabs[1].id) ? false : true,
            );
            data?.map(item => {
              const protocolCode = item?.protocol ? item.protocol : '-';
              let protocolMapping = NETWORK_RULES_PROTOCOL_MAPPING[protocolCode];
              if (!protocolMapping) {
                protocolMapping = NETWORK_RULES_PROTOCOL_MAPPING['-'];
              }
              detailRows.push({
                ruleNumber: item.ruleNumber.toString() == '32767' ? '*' : item.ruleNumber,
                type: getNetworkTypeValue(
                  item.portRange,
                  item.icmpTypeCode,
                  item?.ipv6CidrBlock ? true : false,
                  protocolCode,
                ),
                protocol: protocolMapping.protocol,
                portRange: getNetworkPortRangeValue(item.portRange, item.icmpTypeCode),
                source: item.cidrBlock ? item.cidrBlock : item.ipv6CidrBlock,
                allowDeny: item.ruleAction,
              });
            });
            setDetailTblRows(detailRows);
            setDetailTblTotal({
              totalPage: 1,
              totalElement: detailRows.length,
            });
          });
          detailColumns = [
            { label: 'Rule number', field: 'ruleNumber', sort: true },
            { label: 'Type', field: 'type', sort: true },
            { label: 'Protocol', field: 'protocol', sort: true },
            { label: 'Port range', field: 'portRange', sort: true },
            {
              label: detailTabSelected.id == detailSubnetTabs[2].id ? 'Source' : 'Destination',
              field: 'source',
              sort: true,
            },
            { label: 'Allow/Deny', field: 'allowDeny', sort: true },
          ];
        } else if (detailTabSelected && detailTabSelected.id == detailSubnetTabs[4].id) {
          // cidr reservations
          const variable = {
            cloudId: Number(relatedCloudSelected.value),
            region: selectedRegion.value as string,
            request: {
              maxResults: 50,
              subnetId: currentRouteState?.key || detailsData.subnetId,
            },
          };
          getAwsSubnetCidrReservations({ variables: variable }).then(({ data: subnetCidrData }) => {
            subnetCidrData?.getAwsSubnetCidrReservations.data.subnetIpv4CidrReservations?.forEach(item => {
              detailRows.push({
                cidrReservationId: item.subnetCidrReservationId,
                cidr: item.cidr,
                reservationType: item.reservationType,
              });
            });
            setDetailTblRows(detailRows);
            setDetailTblTotal({
              totalPage: Math.ceil(detailRows.length / detailTablePagination.limit),
              totalElement: detailRows.length,
            });
          });
          detailColumns = [
            {
              label: 'CIDR reservation ID',
              field: 'cidrReservationId',
              sort: true,
            },
            { label: 'CIDR', field: 'cidr', sort: true },
            { label: 'Reservation type', field: 'reservationType', sort: true },
          ];
        } else if (detailTabSelected && detailTabSelected.id == detailSubnetTabs[5].id) {
          // sharing tab
          const resourceShareAssociationVar = {
            cloudId: Number(relatedCloudSelected.value),
            region: selectedRegion.value as string,
            request: {
              maxResults: 50,
              associationType: 'RESOURCE',
              resourceArn: detailsData.subnetArn,
            },
          };
          getResourceShareAssociations({
            variables: resourceShareAssociationVar,
          }).then(({ data: resource }) => {
            resource?.getResourceShareAssociations?.data.resourceShareAssociations.forEach(item => {
              detailRows.push({
                name: item.resourceShareName,
                resourceShareArn: item.resourceShareArn,
                status: item.status,
                createdAt: item.creationTime,
                lastUpdate: item.lastUpdatedTime,
              });
            });
            setDetailTblRows(detailRows);
            setDetailTblTotal({
              totalPage: Math.ceil(detailRows.length / detailTablePagination.limit),
              totalElement: detailRows.length,
            });
          });
          detailColumns = [
            { label: 'Name', field: 'name', sort: true },
            {
              label: 'Resource share ARN',
              field: 'resourceShareArn',
              sort: true,
            },
            { label: 'Status', field: 'status', sort: true },
            { label: 'Created at', field: 'createdAt', sort: true },
            { label: 'Last update', field: 'lastUpdate', sort: true },
          ];
        } else {
          // tags tab
          detailColumns = [
            { label: 'Key', field: 'key', sort: true },
            { label: 'Value', field: 'value', sort: true },
          ];
          detailsData?.tags?.map((tag: any) => {
            detailRows.push({
              key: tag.key,
              value: tag.value,
            });
          });
          setDetailTblRows(detailRows);
        }
        break;
      case mainTabs[3]:
        if (detailTabSelected && detailTabSelected.id == detailInternetGWTabs[1].id) {
          const detailData = awsInternetGWPageData.find(mainData => mainData.id === mainSelected);
          if (detailTabSelected.id == detailInternetGWTabs[1].id) {
            detailColumns = detailTagColumns;

            detailData?.tags?.map((tag: any) => {
              detailRows.push({
                key: tag.key,
                value: tag.value,
              });
            });
            setDetailTblRows(detailRows);
          }
        }
        break;
      case mainTabs[4]:
        const detailNatGWData = awsNatGWPageData.find(item => item.id === mainSelected);
        if (detailTabSelected && detailTabSelected.id == detailNATGWTabs[1].id) {
          // secondary ipv4 addresses tab
          detailColumns = [
            {
              label: 'Private IPv4 address',
              field: 'prrivateIpv4Address',
              sort: true,
            },
            {
              label: 'Network interface ID',
              field: 'networkInterfaceId',
              sort: true,
            },
            { label: 'Status', field: 'state', sort: true },
            { label: 'Failure message', field: 'failureMessage', sort: true },
          ];

          detailRows.push({
            prrivateIpv4Address: detailNatGWData.primaryPrivateIpv4Address,
            networkInterfaceId: detailNatGWData.primaryNetworkInterfaceId,
            state: detailNatGWData.state,
            failureMessage: detailNatGWData.failureMessage,
          });
          setDetailTblRows(detailRows);
        } else {
          // tags tab
          detailColumns = [
            { label: 'Key', field: 'key', sort: true },
            { label: 'Value', field: 'value', sort: true },
          ];
          detailNatGWData?.tags.forEach((tag: any) => {
            detailRows.push({
              key: tag.key,
              value: tag.value,
            });
          });
          setDetailTblRows(detailRows);
        }
        break;
      case mainTabs[5]:
        if (detailTabSelected && detailTabSelected.id == detailElasticIPTabs[1].id) {
          const detailElasticIPData = awsElasticIPPageData.find(mainData => mainData.id === mainSelected);
          if (detailTabSelected.id == detailElasticIPTabs[1].id) {
            detailColumns = detailTagColumns;

            detailElasticIPData?.tags?.map((tag: any) => {
              detailRows.push({
                key: tag.key,
                value: tag.value,
              });
            });
          }
          setDetailTblRows(detailRows);
        }
        break;
      case mainTabs[6]:
        if (
          detailTabSelected &&
          (detailTabSelected.id == detailNetworkALCTabs[1].id ||
            detailTabSelected.id == detailNetworkALCTabs[2].id ||
            detailTabSelected.id == detailNetworkALCTabs[3].id ||
            detailTabSelected.id == detailNetworkALCTabs[4].id)
        ) {
          const detailData = awsNetworkAclsPageData.find(mainData => mainData.id === mainSelected);
          if (
            detailTabSelected.id == detailNetworkALCTabs[1].id ||
            detailTabSelected.id == detailNetworkALCTabs[2].id
          ) {
            detailColumns = [
              { label: 'Rule number', field: 'ruleNumber', sort: true },
              { label: 'Type', field: 'networkType', sort: true },
              { label: 'Protocol', field: 'protocol', sort: true },
              { label: 'Port range', field: 'portRange', sort: true },
              {
                label: detailTabSelected.id == detailNetworkALCTabs[1].id ? 'Source' : 'Destination',
                field: 'cidr',
                sort: true,
              },
              {
                label: 'Allow/Deny',
                field: 'ruleAction',
                sort: true,
                renderCell: (row: RowType) => (
                  <>{<div className={row.ruleAction.toLowerCase()}>{handleFormatText(row?.ruleAction)}</div>}</>
                ),
              },
            ];
            detailData.entries
              .filter((e: { egress: boolean }) =>
                e.egress === (detailTabSelected.id == detailNetworkALCTabs[1].id) ? false : true,
              )
              .forEach((row: any) => {
                const protocolCode = row?.protocol ? row.protocol : '-';
                let protocolMapping = NETWORK_RULES_PROTOCOL_MAPPING[protocolCode];
                if (!protocolMapping) {
                  protocolMapping = NETWORK_RULES_PROTOCOL_MAPPING['-'];
                }

                detailRows.push({
                  ruleNumber: row.ruleNumber == '32767' ? '*' : row.ruleNumber,
                  networkType: getNetworkTypeValue(row.portRange, row.icmpTypeCode, row?.ipv6CidrBlock, protocolCode),
                  cidr: row.cidrBlock ? row.cidrBlock : row.ipv6CidrBlock,
                  protocol: protocolMapping.protocol,
                  portRange: getNetworkPortRangeValue(row.portRange, row.icmpTypeCode),
                  ruleAction: row.ruleAction === 'allow' ? 'Allow' : 'Deny',
                });
              });
            setDetailTablePagination({
              limit: 100,
              itemPerPage: 100,
              target: 'modifiedAt',
              direction: OrderDirection.DES,
              currentPage: 1,
            });
            setDetailTblTotal({
              totalPage: 1,
              totalElement: detailRows.length,
            });
          } else if (
            detailTabSelected.id == detailRouteTableTabs[2].id ||
            detailTabSelected.id == detailRouteTableTabs[3].id
          ) {
            const listSubnetIds: string[] = detailData.associations.map((association: any) => association.subnetId);

            detailColumns = [
              { label: 'Name', field: 'name', sort: true },
              { label: 'Subnet ID', field: 'subnetId', sort: true },
              { label: 'Associated with', field: 'networkAclId', sort: true },
              {
                label: 'Availability zone',
                field: 'availabilityZone',
                sort: true,
              },
              { label: 'IPv4 CIDR', field: 'ipv4cidr', sort: true },
              { label: 'IPv6 CIDR', field: 'ipv6cidr', sort: true },
            ];

            detailData.associations.forEach((row: any) => {
              detailRows.push({
                id: row.subnetId,
                name: '-',
                subnetId: row.subnetId,
                networkAclId: 'networkAclId',
                availabilityZone: 'availabilityZone',
                ipv4cidr: '-',
                ipv6cidr: '-',
              });
            });
            setDetailTablePagination({
              limit: 100,
              itemPerPage: 100,
              target: 'modifiedAt',
              direction: OrderDirection.DES,
              currentPage: 1,
            });
            setDetailTblTotal({
              totalPage: 1,
              totalElement: detailRows.length,
            });
          } else {
            detailColumns = [
              { label: 'Key', field: 'key', sort: true },
              { label: 'Value', field: 'value', sort: true },
            ];

            detailData?.tags?.map((tag: any) => {
              detailRows.push({
                key: tag.key,
                value: tag.value,
              });
            });
          }
        }
        setDetailTblRows(detailRows);
        break;
      case mainTabs[7]:
        if (
          detailTabSelected &&
          (detailTabSelected.id == detailSecurityGroupTabs[1].id ||
            detailTabSelected.id == detailSecurityGroupTabs[2].id ||
            detailTabSelected.id == detailSecurityGroupTabs[3].id)
        ) {
          const detailData = awsSecurityGroupsPageData.find(mainData => mainData.id === mainSelected);
          if (
            detailTabSelected.id == detailSecurityGroupTabs[1].id ||
            detailTabSelected.id == detailSecurityGroupTabs[2].id
          ) {
            const securityGroupRuleVariable = {
              cloudId: Number(relatedCloudSelected.value),
              region: selectedRegion.value as string,
              request: {
                maxResults: 50,
                filters: {
                  name: 'group-id',
                  values: [mainSelected],
                },
              },
            };
            getAwsSecurityGroupRules({
              variables: securityGroupRuleVariable,
            }).then(({ data }) => {
              data?.getAwsSecurityGroupRules?.data?.[0]?.securityGroupRules
                ?.filter(s =>
                  detailTabSelected.id == detailSecurityGroupTabs[1].id ? s.isEgress === false : s.isEgress === true,
                )
                .forEach(item => {
                  const sgRuleDataConvert = getSecurityGroupRuleTypeValue(item.fromPort, item.toPort, item.ipProtocol);
                  detailRows.push({
                    name: item.tags?.find((r: { key: string }) => r.key === 'Name')?.value,
                    securityGroupRuleId: item.securityGroupRuleId,
                    ipVersion: item.cidrIpv4 ? 'IPv4' : item.cidrIpv6 ? 'IPv6' : '-',
                    type: sgRuleDataConvert.type,
                    cidr: item.cidrIpv4 ? item.cidrIpv4 : item.cidrIpv6 ? item.cidrIpv6 : '-',
                    protocol: sgRuleDataConvert.protocol,
                    portRange: sgRuleDataConvert.portRange,
                    description: item.description ? item.description : '-',
                  });
                });
              setDetailTblRows(detailRows);
              setDetailTblTotal({
                totalPage: 1,
                totalElement: detailRows.length,
              });
            });

            detailColumns = [
              { label: 'Name', field: 'name', sort: true },
              {
                label: 'Security group rule ID',
                field: 'securityGroupRuleId',
                sort: true,
              },
              { label: 'IP version', field: 'ipVersion', sort: true },
              { label: 'Type', field: 'type', sort: true },
              { label: 'Protocol', field: 'protocol', sort: true },
              { label: 'Port range', field: 'portRange', sort: true },
              {
                label: detailTabSelected.id == detailSecurityGroupTabs[1].id ? 'Source' : 'Destination',
                field: 'cidr',
                sort: true,
              },
              { label: 'Description', field: 'description', sort: true },
            ];

            setDetailTblTotal({
              totalPage: Math.ceil(detailRows.length / detailTablePagination.limit),
              totalElement: detailRows.length,
            });
          } else {
            detailColumns = [
              { label: 'Key', field: 'key', sort: true },
              { label: 'Value', field: 'value', sort: true },
            ];

            detailData?.tags?.map((tag: any) => {
              detailRows.push({
                key: tag.key,
                value: tag.value,
              });
            });
            setDetailTblRows(detailRows);
          }
        }
        break;
      default:
        break;
    }

    setDetailTblColumns(detailColumns);
  }, [detailTabSelected, mainSelected, mainTabSelected, currentRouteState, relatedCloudSelected]);

  //detailTabSelected
  const getDetailsDataByTabId = useMemo(() => {
    if (mainSelected != '') {
      const detailsData: IMgdDetailKeyValueProps[] = [];
      const detailsDataOrdered: IMgdDetailKeyValueProps[] = [];
      switch (mainTabSelected) {
        case mainTabs[0]:
          const detailData = awsVpcPageData.find(mainData => mainData.id === mainSelected);
          if (detailData) {
            Object.entries(detailData).map(([key, value]) => {
              if (detailVpcKeys.indexOf(key) > -1) {
                const textDes = detailVpcTextDes.find(text => text.id == key);
                let desValue = '-';
                if (typeof value === 'string') {
                  desValue = value.toString();
                  if (key === detailVpcKeys[4]) desValue = desValue.charAt(0).toUpperCase() + desValue.slice(1);
                }

                if (typeof value === 'boolean') {
                  desValue = value === true ? 'Enabled' : 'Disabled';
                  if (key === detailVpcKeys[8]) desValue = value === true ? 'Yes' : 'No';
                }
                if (textDes) {
                  detailsData.push({
                    id: key,
                    type: textDes.type as TextTypeEnum,
                    title: textDes.value,
                    description: desValue,
                  });
                }
              }
            });

            detailVpcKeys.map(key => {
              //routeTableId_3 - Main route table
              if (key === detailVpcKeys[6]) {
                const textDes = detailVpcTextDes.find(text => text.id == key);
                let desValue = detailData?.routeTables?.associations[0]?.routeTableId;
                if (textDes) {
                  detailsData.push({
                    id: key,
                    type: textDes.type as TextTypeEnum,
                    title: textDes.value,
                    description: desValue ? desValue : '-',
                  });
                }
              }

              //networkAclId_4 - Main network ACL
              if (key === detailVpcKeys[7]) {
                const textDes = detailVpcTextDes.find(text => text.id == key);
                let desValue = detailData?.networkAcls[0]?.networkAclId;
                if (textDes) {
                  detailsData.push({
                    id: key,
                    type: textDes.type as TextTypeEnum,
                    title: textDes.value,
                    description: desValue ? desValue : '-',
                  });
                }
              }

              //ipv6Pool_5 - IPV6 pool
              if (key === detailVpcKeys[10]) {
                const textDes = detailVpcTextDes.find(text => text.id == key);
                let desValue = detailData?.ipv6CidrBlockAssociationSet;
                if (textDes) {
                  detailsData.push({
                    id: key,
                    type: textDes.type as TextTypeEnum,
                    title: textDes.value,
                    description: desValue ? desValue : '-',
                  });
                }
              }

              //ipv6CidrBlock_6 - IP6 CIDR
              if (key === detailVpcKeys[11]) {
                const textDes = detailVpcTextDes.find(text => text.id == key);
                let desValue = detailData?.ipv6CidrBlockAssociationSet?.ipv6CidrBlock;
                if (textDes) {
                  detailsData.push({
                    id: key,
                    type: textDes.type as TextTypeEnum,
                    title: textDes.value,
                    description: desValue ? desValue : '-',
                  });
                }
              }

              //FirewallRuleGroupAssociation.xxx_7', value: 'Network address usage metrics'
              if (key === detailVpcKeys[12] || key === detailVpcKeys[13]) {
                const textDes = detailVpcTextDes.find(text => text.id == key);
                let desValue = detailData?.firewallRuleAssociations;
                if (textDes) {
                  detailsData.push({
                    id: key,
                    type: textDes.type as TextTypeEnum,
                    title: textDes.value,
                    description: desValue ? desValue : '-',
                  });
                }
              }
            });
          }

          // order data
          detailVpcKeys.map(key => {
            const dataByKey = detailsData.find(data => data.id == key);
            if (dataByKey) detailsDataOrdered.push(dataByKey);
          });
          break;
        case mainTabs[1]:
          const detailsRouteTableData = awsRouteTablesPageData.find(item => item.routeTableId === mainSelected);
          const formatedData = detailRoutetableTextDes.map(item => {
            let description = '';
            if (item.id === 'subnetAssociations') {
              const listsubnetIDs = detailsRouteTableData?.associations.map((item: any) => item.subnetId);
              description =
                detailsRouteTableData?.associations.length > 1
                  ? {
                      type: item.id,
                      title: 'subnets',
                      values: listsubnetIDs,
                    }
                  : detailsRouteTableData?.associations[0].subnetId;
            } else if (item.id === 'associations') {
              description =
                detailsRouteTableData?.associations.length > 1
                  ? 'No'
                  : detailsRouteTableData?.associations[0].main
                  ? 'Yes'
                  : 'No';
            } else {
              description = detailsRouteTableData?.[item.id];
            }
            return {
              id: item.id,
              type: item.type as TextTypeEnum,
              description: description,
              title: item.value,
            };
          });
          detailsDataOrdered.push(...formatedData);
          break;
        case mainTabs[2]:
          const detailsSubnetData = awsSubnetPageData.find(item => item.subnetId === mainSelected);

          detailSubnetTextDes.map(item => {
            let description;
            if (item.id === 'ipv6CidrBlock') {
              description = detailsSubnetData?.ipv6CidrBlockAssociationSet
                ? detailsSubnetData.ipv6CidrBlockAssociationSet.ipv6CidrBlock
                : '-';
            } else if (item.id === 'defaultForAz') {
              description = detailsSubnetData?.defaultForAz ? 'Yes' : 'No';
            } else if (item.id === 'mapPublicIpOnLaunch') {
              description = detailsSubnetData?.mapPublicIpOnLaunch ? 'Yes' : 'No';
            } else if (item.id === 'assignIpv6AddressOnCreation') {
              description = detailsSubnetData?.assignIpv6AddressOnCreation ? 'Yes' : 'No';
            } else if (item.id === 'assignIpv6AddressOnCreation') {
              description = detailsSubnetData?.assignIpv6AddressOnCreation ? 'Yes' : 'No';
            } else if (item.id === 'mapCustomerOwnedIpOnLaunch') {
              description = detailsSubnetData?.mapCustomerOwnedIpOnLaunch ? 'Yes' : 'No';
            } else if (item.id === 'hostnameType') {
              description = detailsSubnetData?.privateDnsNameOptionsOnLaunch
                ? detailsSubnetData.privateDnsNameOptionsOnLaunch.hostnameType
                : '-';
            } else if (item.id === 'enableResourceNameDnsARecord') {
              description = detailsSubnetData?.privateDnsNameOptionsOnLaunch?.enableResourceNameDnsARecord
                ? 'available'
                : 'disabled';
            } else if (item.id === 'enableResourceNameDnsAAAARecord') {
              description = detailsSubnetData?.privateDnsNameOptionsOnLaunch?.enableResourceNameDnsAAAARecord
                ? 'available'
                : 'disabled';
            } else if (item.id === 'enableDns64') {
              description = detailsSubnetData?.enableDns64 ? 'available' : 'disabled';
            } else {
              description = detailsSubnetData?.[item.id];
            }
            detailsData.push({
              id: item.id,
              type: item.type as TextTypeEnum,
              description: description,
              title: item.value,
            });
          });
          detailsDataOrdered.push(...detailsData);
          break;
        case mainTabs[3]:
          const detailInternetGWData = awsInternetGWPageData.find(mainData => mainData.id === mainSelected);
          if (detailInternetGWData) {
            Object.entries(detailInternetGWData).map(([key, value]) => {
              if (detailInternetGWKeys.indexOf(key) > -1) {
                const textDes = detailInternetGWTextDes.find(text => text.id == key);
                let desValue = '-';
                if (typeof value === 'string') {
                  desValue = value.toString();
                }
                if (textDes) {
                  detailsData.push({
                    id: key,
                    type: textDes.type as TextTypeEnum,
                    title: textDes.value,
                    description: desValue,
                  });
                }
              }
            });

            detailInternetGWKeys.map(key => {
              const dataByKey = detailsData.find(data => data.id == key);
              if (dataByKey) detailsDataOrdered.push(dataByKey);
            });
          }
          break;
        case mainTabs[4]:
          const detailsNatGWData = awsNatGWPageData.find(item => item?.natGatewayId === mainSelected);
          const formatedNatGWData = detailNatGWTextDes.map(item => {
            return {
              id: item.id,
              type: item.type as TextTypeEnum,
              description: detailsNatGWData?.[item.id],
              title: item.value,
            };
          });
          detailsDataOrdered.push(...formatedNatGWData);
          break;
        case mainTabs[5]:
          const detailElasticIPData = awsElasticIPPageData.find(mainData => mainData.id === mainSelected);
          if (detailElasticIPData) {
            Object.entries(detailElasticIPData).map(([key, value]) => {
              if (detailElasticIPKeys.indexOf(key) > -1) {
                const textDes = detailElasticIPTextDes.find(text => text.id == key);
                let desValue = '-';
                if (typeof value === 'string') {
                  desValue = value.toString();
                }
                if (textDes) {
                  detailsData.push({
                    id: key,
                    type: textDes.type as TextTypeEnum,
                    title: textDes.value,
                    description: desValue,
                  });
                }
              }
            });

            detailElasticIPKeys.map(key => {
              const dataByKey = detailsData.find(data => data.id == key);
              if (dataByKey) detailsDataOrdered.push(dataByKey);
            });
          }
          break;
        case mainTabs[6]:
          const detailNetworkAclData = awsNetworkAclsPageData.find(mainData => mainData.id === mainSelected);
          const formatedNetworkAclData = detailNetworkAclTextDes.map(item => {
            let description = '';
            if (item.id === 'associations') {
              const listsubnetIDs = detailNetworkAclData?.associations.map((item: any) => item.subnetId);
              description =
                listsubnetIDs?.length > 1
                  ? {
                      type: item.id,
                      title: 'subnets',
                      values: listsubnetIDs,
                    }
                  : detailNetworkAclData?.associations[0].subnetId;
            } else if (item.id === 'isDefault') {
              description = detailNetworkAclData?.isDefault ? 'Yes' : 'No';
            } else {
              description = detailNetworkAclData?.[item.id];
            }

            return {
              id: item.id,
              type: item.type as TextTypeEnum,
              description: description,
              title: item.value,
            };
          });
          detailsDataOrdered.push(...formatedNetworkAclData);
          break;
        case mainTabs[7]:
          const detailSecurityGroupData = awsSecurityGroupsPageData.find(mainData => mainData.id === mainSelected);
          const detailSecurityGroupKeys = [
            {
              id: 'name',
              type: TextTypeEnum.NORMAL,
              description: detailSecurityGroupData?.tags?.find((r: { key: string }) => r.key === 'Name')?.value || '-',
              title: 'Security group name',
            },
            {
              id: 'groupId',
              type: TextTypeEnum.NORMAL,
              description: detailSecurityGroupData?.groupId,
              title: 'Security group ID',
            },
            {
              id: 'description',
              type: TextTypeEnum.NORMAL,
              description: detailSecurityGroupData?.description,
              title: 'Description',
            },
            {
              id: 'vpcId',
              type: TextTypeEnum.LINK,
              description: detailSecurityGroupData?.vpcId,
              title: 'VPC ID',
            },
            {
              id: 'ownerId',
              type: TextTypeEnum.COPY,
              description: detailSecurityGroupData?.ownerId,
              title: 'Owner',
            },
            {
              id: 'inboundRuleCount',
              type: TextTypeEnum.NORMAL,
              description:
                detailSecurityGroupData?.ipPermissions?.length > 1
                  ? `${detailSecurityGroupData?.ipPermissions?.length} permission entries`
                  : `${detailSecurityGroupData?.ipPermissionsEgress?.length} permission entry`,
              title: 'inbound rules count',
            },
            {
              id: 'outboundRuleCount',
              type: TextTypeEnum.NORMAL,
              description:
                detailSecurityGroupData?.ipPermissionsEgress?.length > 1
                  ? `${detailSecurityGroupData?.ipPermissionsEgress?.length} permission entries`
                  : `${detailSecurityGroupData?.ipPermissionsEgress?.length} permission entry`,
              title: 'outbound rules count',
            },
          ];
          detailsDataOrdered.push(...detailSecurityGroupKeys);
          break;
        default:
          break;
      }
      getDetailsTblDataByTabId();
      return detailsDataOrdered;
    } else {
      return [];
    }
  }, [mainSelected, detailTabSelected]);

  const mainTabClickHandler = useCallback(
    (tabSelected: IMgdTabProps) => {
      setMainTabSelected(mainTabs.find(tabItem => tabItem.id === tabSelected.id) || mainTabs[0]);

      setMainSelected('');
      setIsSetDefaultFilterfield(true);
      setMainSearchValue({ name: '', value: '' });
      resetMainStates();
      if (currentRouteState) {
        setCurrentRouteState?.(null);
      }

      switch (tabSelected) {
        case mainTabs[0]:
          getVpcMainDataRows();
          break;
        case mainTabs[1]:
          getRouteTableMainDataRows();
          break;
        case mainTabs[2]:
          getSubnetMainDataRows();
          break;
        case mainTabs[3]:
          getInternetGWMainDataRows();
          break;
        case mainTabs[4]:
          getNatGatewayMainDataRows();
          break;
        case mainTabs[5]:
          getElasticIPMainDataRows();
          break;
        case mainTabs[6]:
          getNetworkAclMainDataRows();
          break;
        case mainTabs[7]:
          getSecurityGroupMainDataRows();
          break;
        default:
          break;
      }
    },
    [selectedRegion, currentRouteState],
  );

  useEffect(() => {
    if (currentRouteState?.tabId) {
      mainTabClickHandler(mainTabs.find(tab => tab.id === currentRouteState?.tabId)!);
      setMainSearchValue({
        name: currentRouteState?.key,
        value: currentRouteState?.value,
      });
      setMainFilterValue({
        name: currentRouteState?.key,
        value: currentRouteState?.value,
      });
    }
  }, [currentRouteState]);

  // INIT LOADING
  useEffect(() => {
    resetMainStates();
    resetDetailStates();
    const hasDefaultRegion = defaultRegionList.find(r => {
      return r.projectId === projectId && r.cloudId === relatedCloudSelected.value;
    });
    if (!!hasDefaultRegion) {
      setSelectedRegion({
        name: hasDefaultRegion.name,
        value: hasDefaultRegion.value,
      });
    } else {
      getEnumTypeCode({
        variables: { text: regionCode || 'AwsRegionCode' },
      }).then(res => {
        if (res.data) {
          const regionsRes: DropdownListDataType[] = res.data.getEnumTypeCode.data.filter(val =>
            regions.includes(val.value),
          ).map((val) => ({
            ...val,
            name: val.value
          }));
          setRegionList(regionsRes);
          const currentRegion = regionsRes.find(
            r => r.name === selectedRegion.name && r.value === selectedRegion.value,
          );
          setSelectedRegion(currentRegion || regionsRes[0]);
        }
      });
    }
  }, [relatedCloudSelected]);

  // INIT LOADING FIRST TAB
  useEffect(() => {
    if (initialRender == false && selectedRegion.value != '') {
      setInitialRender(true);
      getVpcMainDataRows();
    }
  }, [selectedRegion]);

  const pinButtonClickHandle = useCallback(() => {
    updateDefaultRegionList(
      projectId,
      Number(relatedCloudSelected.value),
      selectedRegion.name || '',
      selectedRegion.value.toString(),
    );
  }, [relatedCloudSelected, selectedRegion, projectId]);

  const handleHyperlinkMainClick = (e: any, tab: IMgdTabProps, searchVal: string, filter: DropdownListDataType) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    setMainTabSelected(tab);
    setMainFilterValue(filter);
    setMainSearchValue({ name: filter.value as string, value: searchVal });
    resetDetailStates();
  };

  useEffect(() => {
    let mainColumns: ColumnType[] = [];
    switch (mainTabSelected) {
      case mainTabs[0]:
        // VPC tab
        mainColumns = [
          {
            label: 'Name',
            field: 'name',
            sort: true,
            renderCell: (row: RowType) => <>{handleFormatText(row?.name || '-')}</>,
          },
          {
            label: 'VPC ID',
            field: 'vpcId',
            sort: true,
            renderCell: (row: RowType) => (
              <>
                {
                  <div
                    className="link"
                    onClick={e => {
                      e.stopPropagation();
                      setMainSelected(row?.id as string);
                      setScreenName('DETAIL');
                      setDetailTabSelected(detailVpcTabs[1]);
                    }}
                  >
                    {handleFormatText(row?.vpcId)}
                  </div>
                }
              </>
            ),
          },
          {
            label: 'Status',
            field: 'state',
            sort: true,
            renderCell: (row: RowType) => (
              <>
                {
                  <div className="status">
                    <p>{handleFormatText(row?.state)}</p>
                  </div>
                }
              </>
            ),
          },
          { label: 'IPv4 CIDR', field: 'cidrBlock', sort: true },
          {
            label: 'IPv6 CIDR',
            field: 'ipv6CidrBlockAssociationSet.ipv6CidrBlock',
            sort: true,
            renderCell: (row: RowType) => (
              <>
                {handleFormatText(String(
                  row?.ipv6CidrBlockAssociationSet?.ipv6CidrBlock ? row.ipv6CidrBlockAssociationSet.ipv6CidrBlock : '-',
                ))}
              </>
            ),
          },
          { label: 'DHCP option set', field: 'dhcpOptionsId', sort: true },
          {
            label: 'Main route table',
            field: 'mainRouteTableId',
            sort: true,
            renderCell: (row: RowType) => (
              <>
                {
                  <div
                    className="link"
                    onClick={e => {
                      handleHyperlinkMainClick(
                        e,
                        mainTabs[1],
                        row?.networkAcls && row?.routeTables?.associations[0]?.routeTableId,
                        routeTableFilterDropdown[0],
                      );
                    }}
                  >
                    {handleFormatText(row.mainRouteTableId)}
                  </div>
                }
              </>
            ),
          },
          {
            label: 'Main network ACL',
            field: 'mainNetAclId',
            sort: true,
            renderCell: (row: RowType) => (
              <>
                {
                  <div
                    className="link"
                    onClick={e => {
                      handleHyperlinkMainClick(
                        e,
                        mainTabs[6],
                        row?.networkAcls && row?.routeTables?.associations[0]?.networkAclId,
                        networkACLFilterDropdown[1],
                      );
                    }}
                  >
                    {handleFormatText(row.mainNetAclId)}
                  </div>
                }
              </>
            ),
          },
          {
            label: 'Tenancy',
            field: 'instanceTenancy',
            sort: true,
            renderCell: (row: RowType) => (
              <>
                {handleFormatText(row?.instanceTenancy && row?.instanceTenancy.charAt(0).toUpperCase() + row?.instanceTenancy.slice(1))}
              </>
            ),
          },
          {
            label: 'Default VPC',
            field: 'isDefault',
            renderCell: (row: RowType) => <>{row.isDefault === false ? 'No' : 'Yes'}</>,
            sort: true,
          },
          { label: 'Owner ID', field: 'ownerId', sort: true },
        ];
        break;
      case mainTabs[1]:
        mainColumns = [
          {
            label: 'Name',
            field: 'name',
            sort: true,
            renderCell: (row: RowType) => <>{handleFormatText(row?.name || '-')}</>,
          },
          {
            label: 'Route Table ID',
            field: 'routeTableId',
            sort: true,
            renderCell: (row: RowType) => (
              <>
                {
                  <div
                    className="link"
                    onClick={e => {
                      e.stopPropagation();
                      setMainSelected(row?.id as string);
                      setScreenName('DETAIL');
                      setDetailTabSelected(detailRouteTableTabs[1]);
                    }}
                  >
                    {handleFormatText(row?.routeTableId)}
                  </div>
                }
              </>
            ),
          },
          {
            label: 'Explict subnet associations',
            field: 'associations',
            sort: true,
            renderCell: (row: RowType) => (
              <>
                {row?.associations?.length == 1 ? (
                  <div
                    className="link"
                    onClick={e => {
                      handleHyperlinkMainClick(e, mainTabs[2], row.associations[0].subnetId, subnetFilterDropdown[0]);
                    }}
                  >
                    {handleFormatText(row.associations[0].subnetId)}
                  </div>
                ) : (
                  <div id={`my-tooltip-${row?.routeTableId}`}>
                    <MgdTooltip id={row?.routeTableId} title={`${row.associations?.length} subnets`}>
                      {row?.associations?.length > 1 &&
                        row?.associations.map((item: any, index: number) => {
                          return (
                            <div
                              className="link"
                              onClick={e => {
                                handleHyperlinkMainClick(e, mainTabs[2], item.subnetId, subnetFilterDropdown[0]);
                              }}
                              key={index}
                            >
                              {handleFormatText(item.subnetId)}
                            </div>
                          );
                        })}
                    </MgdTooltip>
                  </div>
                )}
              </>
            ),
          },
          { label: 'edge associations', field: 'edgeAssociations', sort: true },
          {
            label: 'Main',
            field: 'main',
            sort: true,
          },
          {
            label: 'VPC',
            field: 'vpcId',
            sort: true,
            renderCell: (row: RowType) => (
              <>
                {
                  <div
                    className="link"
                    onClick={e => {
                      handleHyperlinkMainClick(e, mainTabs[0], row?.vpcId, vpcFilterDropdown[0]);
                    }}
                  >
                    {handleFormatText(row?.vpcId)}
                  </div>
                }
              </>
            ),
          },
          { label: 'Owner', field: 'ownerId', sort: true },
        ];
        break;
      case mainTabs[2]:
        // Subnet Tab
        mainColumns = [
          {
            label: 'Name',
            field: 'name',
            sort: true,
            renderCell: (row: RowType) => <>{handleFormatText(row?.name || '-')}</>,
          },
          {
            label: 'Subnet ID',
            field: 'subnetId',
            sort: true,
            renderCell: (row: RowType) => (
              <>
                {
                  <div
                    className="link"
                    onClick={e => {
                      e.stopPropagation();
                      setMainSelected(row?.id as string);
                      setScreenName('DETAIL');
                      setDetailTabSelected(detailSubnetTabs[1]);
                    }}
                  >
                    {handleFormatText(row?.subnetId)}
                  </div>
                }
              </>
            ),
          },
          {
            label: 'State',
            field: 'state',
            sort: true,
            renderCell: (row: RowType) => (
              <>
                {
                  <div className="status">
                    <p>{handleFormatText(row?.state)}</p>
                  </div>
                }
              </>
            ),
          },
          {
            label: 'VPC',
            field: 'vpcId',
            sort: true,
            renderCell: (row: RowType) => (
              <>
                {
                  <div
                    className="link"
                    onClick={e => {
                      handleHyperlinkMainClick(e, mainTabs[0], row?.vpcId, vpcFilterDropdown[0]);
                    }}
                  >
                    {handleFormatText(row?.vpcId)}
                  </div>
                }
              </>
            ),
          },
          { label: 'IPv4 CIDR', field: 'cidrBlock', sort: true },
          {
            label: 'IPv6 CIDR',
            field: 'ipv6CidrBlock',
            sort: true,
            renderCell: (row: RowType) => (
              <>
                {handleFormatText(row.ipv6CidrBlockAssociationSet?.ipv6CidrBlock ? row.ipv6CidrBlockAssociationSet.ipv6CidrBlock : '-')}
              </>
            ),
          },
          { label: 'Availability Zone', field: 'availabilityZone', sort: true },
          {
            label: 'Availability Zone ID',
            field: 'availabilityZoneId',
            sort: true,
          },
          {
            label: 'Network border group',
            field: 'networkBorderGroup',
            sort: true,
          },
          {
            label: 'Route table',
            field: 'routeTableId',
            sort: true,
            renderCell: (row: RowType) => (
              <>
                {
                  <div
                    className="link"
                    onClick={e => {
                      handleHyperlinkMainClick(e, mainTabs[1], row?.routeTableId, routeTableFilterDropdown[0]);
                    }}
                  >
                    <p>{handleFormatText(row.routeTableId)}</p>
                  </div>
                }
              </>
            ),
          },
          {
            label: 'Network ACL',
            field: 'networkAclId',
            sort: true,
            renderCell: (row: RowType) => (
              <>
                {
                  <div
                    className="link"
                    onClick={e => {
                      handleHyperlinkMainClick(e, mainTabs[6], row?.networkAclId, networkACLFilterDropdown[0]);
                    }}
                  >
                    <p>{handleFormatText(row.networkAclId)}</p>
                  </div>
                }
              </>
            ),
          },
          {
            label: 'Default subnet',
            field: 'defaultForAz',
            sort: true,
            renderCell: (row: RowType) => <>{row.defaultForAz ? 'Yes' : 'No'}</>,
          },
          {
            label: 'Auto-assign public IPv4 address',
            field: 'mapPublicIpOnLaunch',
            sort: true,
            renderCell: (row: RowType) => <>{row.mapPublicIpOnLaunch ? 'Yes' : 'No'}</>,
          },
          {
            label: 'Auto-assign customer-owned IPv4 address',
            field: 'mapCustomerOwnedIpOnLaunch',
            sort: true,
            renderCell: (row: RowType) => <>{row.mapCustomerOwnedIpOnLaunch ? 'Yes' : 'No'}</>,
          },
          {
            label: 'Customer-owned IPv4 pool',
            field: 'customerOwnedIpv4Pool',
            sort: true,
            renderCell: (row: RowType) => <>{handleFormatText(row.customerOwnedIpv4Pool ? row.customerOwnedIpv4Pool : '-')}</>,
          },
          {
            label: 'Auto-assign IPv6 address',
            field: 'assignIpv6AddressOnCreation',
            sort: true,
            renderCell: (row: RowType) => <>{row.assignIpv6AddressOnCreation ? 'Yes' : 'No'}</>,
          },
          { label: 'Owner ID', field: 'ownerId', sort: true },
        ];
        break;
      case mainTabs[3]:
        // Internet G/W Tab
        mainColumns = [
          {
            label: 'Name',
            field: 'name',
            sort: true,
            renderCell: (row: RowType) => <>{handleFormatText(row?.name || '-')}</>,
          },
          {
            label: 'Internet gateway ID',
            field: 'internetGatewayId',
            sort: true,
            renderCell: (row: RowType) => (
              <>
                {
                  <div
                    className="link"
                    onClick={e => {
                      e.stopPropagation();
                      setMainSelected(row?.id as string);
                      setScreenName('DETAIL');
                      setDetailTabSelected(detailInternetGWTabs[1]);
                    }}
                  >
                    {handleFormatText(row?.internetGatewayId)}
                  </div>
                }
              </>
            ),
          },
          {
            label: 'State',
            field: 'state',
            sort: true,
            renderCell: (row: RowType) => (
              <>
                {
                  <div className="status">
                    <p>{handleFormatText(row?.state)}</p>
                  </div>
                }
              </>
            ),
          },
          {
            label: 'VPC ID',
            field: 'vpcId',
            sort: true,
            renderCell: (row: RowType) => (
              <>
                {
                  <div
                    className="link"
                    onClick={e => {
                      handleHyperlinkMainClick(
                        e,
                        mainTabs[0],
                        row?.attachments && row?.attachments[0]?.vpcId,
                        vpcFilterDropdown[0],
                      );
                    }}
                  >
                    {handleFormatText(row?.vpcId)}
                  </div>
                }
              </>
            ),
          },
          { label: 'Owner ID', field: 'ownerId', sort: true },
        ];
        break;
      case mainTabs[4]:
        // NAT G/W Tab
        mainColumns = [
          {
            label: 'Name',
            field: 'name',
            sort: true,
            renderCell: (row: RowType) => <>{handleFormatText(row?.name || '-')}</>,
          },
          {
            label: 'Nat gateway ID',
            field: 'id',
            sort: true,
            renderCell: (row: RowType) => (
              <>
                {
                  <div
                    className="link"
                    onClick={e => {
                      e.stopPropagation();
                      setMainSelected(row?.id as string);
                      setScreenName('DETAIL');
                      setDetailTabSelected(detailNATGWTabs[1]);
                    }}
                  >
                    {handleFormatText(row?.id)}
                  </div>
                }
              </>
            ),
          },
          { label: 'Connectivity type', field: 'connectivityType', sort: true },
          { label: 'State', field: 'state', sort: true },
          { label: 'State message', field: 'stateMessage', sort: true },
          {
            label: 'Primary public IPv4 address',
            field: 'primaryPublicIpv4Address',
            sort: true,
            renderCell: (row: RowType) => <>{<div className="link">{handleFormatText(row?.primaryPublicIpv4Address)}</div>}</>,
          },
          {
            label: 'Primary private IPv4 address',
            field: 'primaryPrivateIpv4Address',
            sort: true,
          },
          {
            label: 'Primary network interface ID',
            field: 'primaryNetworkInterfaceId',
            sort: true,
          },
          {
            label: 'VPC',
            field: 'vpcId',
            sort: true,
            renderCell: (row: RowType) => (
              <>
                {
                  <div
                    className="link"
                    onClick={e => {
                      handleHyperlinkMainClick(e, mainTabs[0], row.vpcId, vpcFilterDropdown[0]);
                    }}
                  >
                    {handleFormatText(row?.vpcId)}
                  </div>
                }
              </>
            ),
          },
          {
            label: 'Subnet',
            field: 'subnetId',
            sort: true,
            renderCell: (row: RowType) => (
              <>
                {
                  <div
                    className="link"
                    onClick={e => {
                      handleHyperlinkMainClick(e, mainTabs[2], row?.subnetId, subnetFilterDropdown[0]);
                    }}
                  >
                    {handleFormatText(row?.subnetId)}
                  </div>
                }
              </>
            ),
          },
          { label: 'Created', field: 'createTime', sort: true },
          { label: 'Deleted', field: '', sort: true },
        ];
        break;
      case mainTabs[5]:
        // Elastic IP Tab
        mainColumns = [
          {
            label: 'Name',
            field: 'name',
            sort: true,
            renderCell: (row: RowType) => <>{handleFormatText(row?.name || '-')}</>,
          },
          {
            label: 'Allocated IPv4 address',
            field: 'publicIp',
            sort: true,
            renderCell: (row: RowType) => (
              <>
                {
                  <div
                    className="link"
                    onClick={e => {
                      e.stopPropagation();
                      setMainSelected(row?.id as string);
                      setScreenName('DETAIL');
                      setDetailTabSelected(detailElasticIPTabs[1]);
                    }}
                  >
                    {handleFormatText(row?.publicIp)}
                  </div>
                }
              </>
            ),
          },
          { label: 'Type', field: 'type', sort: true },
          { label: 'Allocation ID', field: 'allocationId', sort: true },
          {
            label: 'Reverse DNS record',
            field: 'reverseDNSRecord',
            sort: true,
          },
          {
            label: 'Associated instance ID',
            field: 'associatedInstanceId',
            sort: true,
          },
          {
            label: 'Private IP address',
            field: 'privateIpAddress',
            sort: true,
            renderCell: (row: RowType) => <>{handleFormatText(row?.privateIpAddress ? row.privateIpAddress : '-')}</>,
          },
          {
            label: 'Association ID',
            field: 'associationId',
            sort: true,
            renderCell: (row: RowType) => <>{handleFormatText(row?.associationId ? row.associationId : '-')}</>,
          },
          {
            label: 'Network interface owner account ID',
            field: 'networkInterfaceOwnerId',
            sort: true,
            renderCell: (row: RowType) => <>{handleFormatText(row?.networkInterfaceOwnerId ? row.networkInterfaceOwnerId : '-')}</>,
          },
          {
            label: 'Network border group',
            field: 'networkBorderGroup',
            sort: true,
          },
        ];
        break;
      case mainTabs[6]:
        // Network ACL tab
        mainColumns = [
          {
            label: 'Name',
            field: 'name',
            sort: true,
            renderCell: (row: RowType) => <>{handleFormatText(row?.name || '-')}</>,
          },
          {
            label: 'Network ACL ID',
            field: 'networkAclId',
            sort: true,
            renderCell: (row: RowType) => (
              <>
                {
                  <div
                    className="link"
                    onClick={e => {
                      e.stopPropagation();
                      setMainSelected(row?.id as string);
                      setScreenName('DETAIL');
                      setDetailTabSelected(detailNetworkALCTabs[1]);
                    }}
                  >
                    {handleFormatText(row?.networkAclId)}
                  </div>
                }
              </>
            ),
          },
          {
            label: 'Associated with',
            field: 'associations',
            sort: true,
            renderCell: (row: RowType) => (
              <>
                {row?.associations?.length == 1 ? (
                  <div
                    className="link"
                    onClick={e => {
                      handleHyperlinkMainClick(e, mainTabs[2], row?.associations[0].subnetId, subnetFilterDropdown[0]);
                    }}
                  >
                    {handleFormatText(row?.associations[0].subnetId)}
                  </div>
                ) : (
                  <div id={`my-tooltip-${row?.networkAclId}`}>
                    <MgdTooltip id={row?.networkAclId} title={`${row?.associations?.length} subnets`}>
                      {row?.associations?.length > 1 &&
                        row?.associations.map((item: any, index: number) => {
                          return (
                            <div
                              className="link"
                              onClick={e => {
                                handleHyperlinkMainClick(e, mainTabs[2], item.subnetId, subnetFilterDropdown[0]);
                              }}
                              key={index}
                            >
                              {handleFormatText(item.subnetId)}
                            </div>
                          );
                        })}
                    </MgdTooltip>
                  </div>
                )}
              </>
            ),
          },
          {
            label: 'Default VPC',
            field: 'isDefault',
            renderCell: (row: RowType) => <>{row.isDefault === false ? 'No' : 'Yes'}</>,
            sort: true,
          },
          {
            label: 'VPC',
            field: 'vpcId',
            sort: true,
            renderCell: (row: RowType) => (
              <>
                {
                  <div
                    className="link"
                    onClick={e => {
                      handleHyperlinkMainClick(e, mainTabs[0], row?.vpcId, vpcFilterDropdown[0]);
                    }}
                  >
                    {handleFormatText(row?.vpcId)}
                  </div>
                }
              </>
            ),
          },
          {
            label: 'Inbound rules count',
            field: 'inbound',
            sort: true,
          },
          {
            label: 'Outbound rules count',
            field: 'outbound',
            sort: true,
          },
          { label: 'Owner', field: 'ownerId', sort: true },
        ];
        break;
      case mainTabs[7]:
        // Security group tab
        mainColumns = [
          {
            label: 'Name',
            field: 'name',
            sort: true,
            renderCell: (row: RowType) => <>{handleFormatText(row?.name || '-')}</>,
          },
          {
            label: 'Security group ID',
            field: 'groupId',
            sort: true,
            renderCell: (row: RowType) => (
              <>
                {
                  <div
                    className="link"
                    onClick={e => {
                      e.stopPropagation();
                      setMainSelected(row?.id as string);
                      setScreenName('DETAIL');
                      setDetailTabSelected(detailSecurityGroupTabs[1]);
                    }}
                  >
                    {handleFormatText(row?.groupId)}
                  </div>
                }
              </>
            ),
          },
          { label: 'Security group name', field: 'groupName', sort: true },
          {
            label: 'VPC',
            field: 'vpcId',
            sort: true,
            renderCell: (row: RowType) => (
              <>
                {
                  <div
                    className="link"
                    onClick={e => {
                      handleHyperlinkMainClick(e, mainTabs[0], row?.vpcId, vpcFilterDropdown[0]);
                    }}
                  >
                    {handleFormatText(row?.vpcId)}
                  </div>
                }
              </>
            ),
          },
          { label: 'Description', field: 'description', sort: true },
          { label: 'Owner', field: 'ownerId', sort: true },
          {
            label: 'Inbound rules count',
            field: 'ipPermissions',
            sort: true,
            renderCell: (row: RowType) => (
              <>
                {row?.ipPermissions?.length > 1
                  ? `${row?.ipPermissions?.length} permission entries`
                  : `${row?.ipPermissionsEgress?.length} permission entry`}
              </>
            ),
          },
          {
            label: 'Outbound rules count',
            field: 'ipPermissionsEgress',
            sort: true,
            renderCell: (row: RowType) => (
              <>
                {row?.ipPermissionsEgress?.length > 1
                  ? `${row?.ipPermissionsEgress?.length} permission entries`
                  : `${row?.ipPermissionsEgress?.length} permission entry`}
              </>
            ),
          },
        ];
        break;
      default:
        break;
    }
    setMainTblColumns(mainColumns);
  }, [mainTabSelected, selectedRegion]);

  // filter handler
  useEffect(() => {
    let searchDropdown: DropdownListDataType[] = [];
    let mainFilterValue: DropdownListDataType = { value: '' };
    let detailTabList: IMgdTabProps[] = [];
    let detailTabSelected: IMgdTabProps = { id: '', name: '', title: '' };

    switch (mainTabSelected) {
      case mainTabs[0]:
        searchDropdown = vpcFilterDropdown;
        mainFilterValue = vpcFilterDropdown[0];
        setMainSearchValue(prev => ({
          name: vpcFilterDropdown[0].value.toString(),
          value: prev.value,
        }));
        detailTabList = detailVpcTabs;
        detailTabSelected = detailVpcTabs[0];
        break;
      case mainTabs[1]:
        searchDropdown = routeTableFilterDropdown;
        mainFilterValue = routeTableFilterDropdown[0];
        setMainSearchValue(prev => ({
          name: routeTableFilterDropdown[0].value.toString(),
          value: prev.value,
        }));
        detailTabList = detailRouteTableTabs;
        detailTabSelected = detailRouteTableTabs[0];
        break;
      case mainTabs[2]:
        searchDropdown = subnetFilterDropdown;
        mainFilterValue = subnetFilterDropdown[0];
        setMainSearchValue(prev => ({
          name: subnetFilterDropdown[0].value.toString(),
          value: prev.value,
        }));
        detailTabList = detailSubnetTabs;
        detailTabSelected = detailSubnetTabs[0];
        break;
      case mainTabs[3]:
        searchDropdown = internetGWFilterDropdown;
        mainFilterValue = internetGWFilterDropdown[0];
        setMainSearchValue(prev => ({
          name: internetGWFilterDropdown[0].value.toString(),
          value: prev.value,
        }));
        detailTabList = detailInternetGWTabs;
        detailTabSelected = detailInternetGWTabs[0];
        break;
      case mainTabs[4]:
        searchDropdown = natGWFilterDropdown;
        mainFilterValue = natGWFilterDropdown[0];
        setMainSearchValue(prev => ({
          name: natGWFilterDropdown[0].value.toString(),
          value: prev.value,
        }));
        detailTabList = detailNATGWTabs;
        detailTabSelected = detailNATGWTabs[0];
        break;
      case mainTabs[5]:
        searchDropdown = elasticIPFilterDropdown;
        mainFilterValue = elasticIPFilterDropdown[0];
        setMainSearchValue(prev => ({
          name: elasticIPFilterDropdown[0].value.toString(),
          value: prev.value,
        }));
        detailTabList = detailElasticIPTabs;
        detailTabSelected = detailElasticIPTabs[0];
        break;
      case mainTabs[6]:
        searchDropdown = networkACLFilterDropdown;
        mainFilterValue = networkACLFilterDropdown[0];
        setMainSearchValue(prev => ({
          name: networkACLFilterDropdown[0].value.toString(),
          value: prev.value,
        }));
        detailTabList = detailNetworkALCTabs;
        detailTabSelected = detailNetworkALCTabs[0];
        break;
      case mainTabs[7]:
        searchDropdown = securityGroupFilterDropdown;
        mainFilterValue = securityGroupFilterDropdown[0];
        setMainSearchValue(prev => ({
          name: securityGroupFilterDropdown[0].value.toString(),
          value: prev.value,
        }));
        detailTabList = detailSecurityGroupTabs;
        detailTabSelected = detailSecurityGroupTabs[0];
        break;
      default:
        detailTabList = detailVpcTabs;
        break;
    }

    if (isSetDefaultFilterfield) {
      setIsSetDefaultFilterfield(false);
      setMainFilterValue(mainFilterValue);
    }

    setMainSearchDropdown(searchDropdown);
    setDetailTabList(detailTabList);
    setDetailTabSelected(detailTabSelected);
  }, [mainTabSelected]);

  const resetDetailStates = useCallback(() => {
    setDetailTblRows([]);
    setDetailTblColumns([]);
  }, []);

  const resetMainStates = useCallback(() => {
    setCheckedList([]);
    setAwsVpcPageData([]);
    setAwsRouteTablesPageData([]);
    setAwsSubnetPageData([]);
    setAwsInternetGWPageData([]);
    setAwsNatGWPageData([]);
    setAwsElasticIPPageData([]);
    setAwsNetworkAclsPageData([]);
    setAwsSecurityGroupsPageData([]);
  }, []);

  const handleHDetailHyperlinkMainClick = useCallback((currTab: IMgdTabProps, data: any) => {
    switch (currTab) {
      case mainTabs[0]:
        if (data?.id === detailVpcKeys[6]) {
          setMainTabSelected(mainTabs[1]);
          setMainFilterValue(routeTableFilterDropdown[0]);
          setMainSearchValue({
            name: routeTableFilterDropdown[0].value as string,
            value: data?.description,
          });
          resetDetailStates();
          resetMainStates();
        } else if (data?.id === detailVpcKeys[7]) {
          setMainTabSelected(mainTabs[6]);
          setMainFilterValue(networkACLFilterDropdown[14]);
          setMainSearchValue({
            name: networkACLFilterDropdown[14].value as string,
            value: data?.description,
          });
          resetDetailStates();
        }
        break;
      case mainTabs[1]:
        if (data.id === detailRoutetableKeys[3]) {
          setMainTabSelected(mainTabs[0]);
          setMainFilterValue(vpcFilterDropdown[0]);
          setMainSearchValue({
            name: vpcFilterDropdown[0].value as string,
            value: data?.description,
          });
          resetDetailStates();
        } else if (data?.id === detailRoutetableKeys[2]) {
          setMainTabSelected(mainTabs[2]);
          setMainFilterValue(subnetFilterDropdown[0]);
          setMainSearchValue({
            name: subnetFilterDropdown[0].value as string,
            value: data?.description,
          });
          resetDetailStates();
        }
        break;
      case mainTabs[2]:
        if (data.id === detailSubnetKeys[10]) {
          setMainTabSelected(mainTabs[1]);
          setMainFilterValue(routeTableFilterDropdown[0]);
          setMainSearchValue({
            name: routeTableFilterDropdown[0].value as string,
            value: data?.description,
          });
          resetDetailStates();
        } else if (data.id === detailSubnetKeys[11]) {
          setMainTabSelected(mainTabs[6]);
          setMainFilterValue(networkACLFilterDropdown[0]);
          setMainSearchValue({
            name: networkACLFilterDropdown[0].value as string,
            value: data?.description,
          });
          resetDetailStates();
        }
        break;
      case mainTabs[3]:
        setMainTabSelected(mainTabs[0]);
        setMainFilterValue(vpcFilterDropdown[0]);
        setMainSearchValue({
          name: vpcFilterDropdown[0].value as string,
          value: data?.description,
        });
        resetDetailStates();
        break;
      case mainTabs[4]:
        if (data.id === detailNatGWKeys[7]) {
          setMainTabSelected(mainTabs[0]);
          setMainFilterValue(vpcFilterDropdown[0]);
          setMainSearchValue({
            name: vpcFilterDropdown[0].value as string,
            value: data?.description,
          });
          resetDetailStates();
        } else if (data.id === detailNatGWKeys[8]) {
          setMainTabSelected(mainTabs[2]);
          setMainFilterValue(subnetFilterDropdown[0]);
          setMainSearchValue({
            name: subnetFilterDropdown[0].value as string,
            value: data?.description,
          });
          resetDetailStates();
        }
        break;
      case mainTabs[6]:
        if (data.id === detailNetworkAclKeys[1]) {
          setMainTabSelected(mainTabs[2]);
          setMainFilterValue(subnetFilterDropdown[0]);
          setMainSearchValue({
            name: subnetFilterDropdown[0].value as string,
            value: data?.description,
          });
          resetDetailStates();
        }
        if (data.id === detailNetworkAclKeys[3]) {
          setMainTabSelected(mainTabs[0]);
          setMainFilterValue(vpcFilterDropdown[0]);
          setMainSearchValue({
            name: vpcFilterDropdown[0].value as string,
            value: data?.description,
          });
          resetDetailStates();
        }
        break;
      case mainTabs[7]:
        if (data.id === 'vpcId') {
          setMainTabSelected(mainTabs[0]);
          setMainFilterValue(vpcFilterDropdown[0]);
          setMainSearchValue({
            name: vpcFilterDropdown[0].value as string,
            value: data?.description,
          });
          resetDetailStates();
        }
        break;
    }
  }, []);

  useEffect(() => {
    if (checkedList.length > 0) {
      setMainSelected(checkedList[checkedList.length - 1]);
    } else {
      setMainSelected('');
    }
  }, [checkedList]);

  useEffect(() => {
    switch (mainTabSelected) {
      case mainTabs[6]:
        setMainDelBtnVisible(true);
        setMainCreateBtnVisible(true);
        setDetailEditBtnVisible(true);
        switch (detailTabSelected) {
          case detailNetworkALCTabs[1]:
            setDetailEditBtnTitle('Edit inbound rules');
            break;
          case detailNetworkALCTabs[2]:
            setDetailEditBtnTitle('Edit outbound rules');
            break;
          case detailNetworkALCTabs[3]:
            setDetailEditBtnTitle('Edit subnet associations');
            break;
          case detailNetworkALCTabs[4]:
            setDetailEditBtnTitle('Message tags');
            break;

          default:
            setDetailEditBtnTitle('');
            break;
        }
        break;
      case mainTabs[7]:
        setMainDelBtnVisible(true);
        setMainCreateBtnVisible(true);
        setDetailEditBtnVisible(true);
        switch (detailTabSelected) {
          case detailSecurityGroupTabs[1]:
            setDetailEditBtnTitle('Edit inbound rules');
            break;
          case detailSecurityGroupTabs[2]:
            setDetailEditBtnTitle('Edit outbound rules');
            break;
          case detailSecurityGroupTabs[3]:
            setDetailEditBtnTitle('Message tags');
            break;

          default:
            setDetailEditBtnTitle('');
            break;
        }
        break;

      default:
        setMainDelBtnVisible(false);
        setMainCreateBtnVisible(false);
        setDetailEditBtnVisible(false);
        setDetailEditBtnTitle('');
        break;
    }
  }, [mainTabSelected, detailTabSelected]);

  useEffect(() => {
    if (isShowDeletePopup && checkedList.length > 0) {
      switch (mainTabSelected) {
        case mainTabs[6]:
          const deleteNetworkAclTblRows: RowType[] = [];
          checkedList.map(checked => {
            const networkAcl = mainTblRows.find(row => {
              return row.id == checked;
            });
            if (networkAcl)
              deleteNetworkAclTblRows.push({
                id: networkAcl?.id,
                networkAcl: networkAcl?.networkAclId,
                vpcId: networkAcl?.vpcId,
              });
          });
          setDeletePopupRows(deleteNetworkAclTblRows);
          setDeletePopupColumns([
            { label: 'Name', field: 'name', sort: true },
            { label: 'Network ACL ID', field: 'networkAcl', sort: true },
            { label: 'VPC ID', field: 'vpcId', sort: true },
          ]);
          break;
        case mainTabs[7]:
          const deleteSGTblRows: RowType[] = [];
          checkedList.map(checked => {
            const securityGroup = mainTblRows.find(row => {
              return row.id == checked;
            });
            if (securityGroup)
              deleteSGTblRows.push({
                id: securityGroup?.id,
                securityGroup: securityGroup?.groupId,
                status: 'Will be deleted',
                reason: 'No associated resources',
              });
          });
          setDeletePopupRows(deleteSGTblRows);
          setDeletePopupColumns([
            { label: 'Security group', field: 'securityGroup', sort: true },
            { label: 'Status', field: 'status', sort: true },
            { label: 'Reason', field: 'reason', sort: true },
          ]);
          break;

        default:
          break;
      }
    }
  }, [isShowDeletePopup]);

  const detailEditBtnClickHandler = useCallback(async () => {
    if (mainTabSelected == mainTabs[6]) {
      if (detailTabSelected === detailNetworkALCTabs[3]) {
        //open modal edit
        setIsShowEditSubnetPoup(true);
        return;
      }
      const networkAcl = awsNetworkAclsPageData.find(network => {
        return network.id === mainSelected;
      });
      if (networkAcl) {
        if (detailTabSelected === detailNetworkALCTabs[1] || detailTabSelected === detailNetworkALCTabs[2]) {
          const ruleRows: RuleRowData[] = [];
          networkAcl?.entries
            ?.filter((e: any) => (e.egress === (detailTabSelected == detailNetworkALCTabs[1]) ? false : true))
            .forEach((row: any) => {
              let type = '';
              if (!row.icmpTypeCode && row.protocol === '-1' && !row.portRange) {
                type = 'all_traffic';
              } else if (!row.icmpTypeCode && row.protocol != '-1' && !row.portRange) {
                type = 'custom_protocol';
              } else if (!row.icmpTypeCode && row.protocol == '6' && row.portRange) {
                if (row.portRange.from === 0 && row.portRange.to === 65535) {
                  type = 'all_tcp';
                } else if (row.portRange.from !== row.portRange.to || !NETWORK_RULES_TYPE_MAPPING[row.portRange.from]) {
                  type = 'custom_tcp';
                } else {
                  type = row.portRange.from.toString();
                }
              } else if (!row.icmpTypeCode && row.protocol == '17' && row.portRange) {
                if (row.portRange.from === 0 && row.portRange.to === 65535) {
                  type = 'all_udp';
                } else if (row.portRange.from !== row.portRange.to || !NETWORK_RULES_TYPE_MAPPING[row.portRange.from]) {
                  type = 'custom_udp';
                } else {
                  type = row.portRange.from.toString();
                }
              } else if (
                row.icmpTypeCode &&
                (row.icmpTypeCode.type !== -1 || row.icmpTypeCode.code !== -1) &&
                row.protocol == '1'
              ) {
                type = 'custom_icmp_ipv4';
              } else if (
                row.icmpTypeCode &&
                (row.icmpTypeCode.type !== -1 || row.icmpTypeCode.code !== -1) &&
                row.protocol == '58'
              ) {
                type = 'custom_icmp_ipv6';
              } else if (
                row.icmpTypeCode &&
                row.icmpTypeCode.type === -1 &&
                row.icmpTypeCode.code === -1 &&
                row.protocol == '1'
              ) {
                type = 'all_icmp_ipv4';
              } else if (
                row.icmpTypeCode &&
                row.icmpTypeCode.type === -1 &&
                row.icmpTypeCode.code === -1 &&
                row.protocol == '58'
              ) {
                type = 'all_icmp_ipv6';
              } else {
                type = row.portRange.from.toString();
              }
              let protocol = row.protocol;
              if (type === 'custom_icmp_ipv4' || type === 'custom_icmp_ipv6') {
                protocol = row.icmpTypeCode.type.toString();
              }
              let portRange = 'All';
              if (row.portRange && row.portRange.from == row.portRange.to) {
                portRange = row.portRange.from.toString();
              } else if (type === 'custom_icmp_ipv4' || type === 'custom_icmp_ipv6') {
                if ((protocol === '3' || protocol === '5' || protocol === '12') && row.icmpTypeCode) {
                  portRange = row.icmpTypeCode.code.toString();
                } else {
                  portRange = 'N/A';
                }
              }
              ruleRows.push({
                ruleNumber: row.ruleNumber,
                type: type,
                protocol: protocol,
                portRange: portRange,
                source: row.cidrBlock ? row.cidrBlock : row.ipv6CidrBlock,
                action: row.ruleAction,
              });
            });
          ruleRows.pop();
          setUpdateRulePopupRows(ruleRows);
          setUpdateRulePopupHeader(
            detailTabSelected == detailNetworkALCTabs[1] ? 'Edit Inbound rules' : 'Edit Outbound rules',
          );
          setIsUpdateInboundPopup(detailTabSelected == detailNetworkALCTabs[1]);
          setIsShowUpdateRulePopup(true);
          return;
        }
        if (detailTabSelected === detailNetworkALCTabs[4]) {
          const tagRows: TagRowData[] = [];
          networkAcl?.tags?.map((tag: { key: string; value: string }, index: number) => {
            tagRows.push({
              index,
              valueKey: tag?.key,
              valueOptional: tag?.value,
            });
          });
          setUpdateTagsPopupRows(tagRows);
          setUpdateTagsPopupColumns(['Key', 'Value-option']);
          setUpdateTagsPopupHeader('Tags');
          setUpdateTagsPopupSubHeader('Available subnets');
          setIsShowUpdateTagsPoup(true);
          return;
        }
      }
    }
    if (mainTabSelected == mainTabs[7]) {
      if (detailTabSelected === detailSecurityGroupTabs[3]) {
        const securityGroup = awsSecurityGroupsPageData.find(sg => {
          return sg.id === mainSelected;
        });
        if (securityGroup) {
          const tagRows: TagRowData[] = [];
          securityGroup?.tags?.map((sg: { key: string; value: string }, index: number) => {
            tagRows.push({
              index,
              valueKey: sg?.key,
              valueOptional: sg?.value,
            });
          });
          setUpdateTagsPopupRows(tagRows);
          setUpdateTagsPopupColumns(['Key', 'Value']);
          setUpdateTagsPopupHeader('Manage Tags');
          setUpdateTagsPopupSubHeader('');
          setIsShowUpdateTagsPoup(true);
        }
        return;
      } else if (detailTabSelected === detailSecurityGroupTabs[1] || detailTabSelected === detailSecurityGroupTabs[2]) {
        const ruleRows: RowSecurityGroupRuleData[] = [];
        const securityGroupRuleVariable = {
          cloudId: Number(relatedCloudSelected.value),
          region: selectedRegion.value as string,
          request: {
            maxResults: 50,
            filters: {
              name: 'group-id',
              values: [mainSelected],
            },
          },
        };

        const securityGroupRules = await getAwsSecurityGroupRules({
          variables: securityGroupRuleVariable,
        }).then(({ data }) => {
          return data?.getAwsSecurityGroupRules;
        });
        securityGroupRules?.data?.[0]?.securityGroupRules
          .filter(s =>
            detailTabSelected.id == detailSecurityGroupTabs[1].id ? s.isEgress === false : s.isEgress === true,
          )
          .forEach(row => {
            let type = '';
            if (row.ipProtocol === '-1' && row.fromPort === -1 && row.toPort === -1) {
              type = 'all_traffic';
            } else if (row.ipProtocol === 'tcp') {
              if (row.fromPort === 0 && row.toPort === 65535) {
                type = 'all_tcp';
              } else if (row.fromPort !== row.toPort || !SECURITY_GROUP_RULES_TYPE_MAPPING[row.fromPort]) {
                type = 'custom_tcp';
              } else {
                type = row.fromPort.toString();
              }
            } else if (row.ipProtocol === 'udp') {
              if (row.fromPort === 0 && row.toPort === 65535) {
                type = 'all_udp';
              } else if (row.fromPort !== row.toPort || !SECURITY_GROUP_RULES_TYPE_MAPPING[row.fromPort]) {
                type = 'custom_udp';
              } else {
                type = row.fromPort.toString();
              }
            } else if (row.ipProtocol === 'icmp') {
              if (row.fromPort === -1 && row.toPort === -1) {
                type = 'all_icmp_ipv4';
              } else {
                type = 'custom_icmp_ipv4';
              }
            } else if (row.ipProtocol === 'icmpv6') {
              if (row.fromPort === -1 && row.toPort === -1) {
                type = 'all_icmp_ipv6';
              } else {
                type = 'custom_icmp_ipv6';
              }
            } else {
              type = row.fromPort.toString();
            }
            let protocol = 'All';
            if (type == 'custom_tcp' || type == 'all_tcp' || type == '22' || type == '25' || type == '53_tcp') {
              protocol = 'TCP';
            } else if (type == 'custom_udp' || type == 'all_udp' || type == '53_udp') {
              protocol = 'UDP';
            } else if (type == 'all_icmp_ipv4') {
              protocol = 'ICMP';
            } else if (type == 'all_icmp_ipv6') {
              protocol = 'IPv6 ICMP';
            } else if (type == 'all_traffic') {
              protocol = 'All';
            } else if (type == 'custom_icmp_ipv4') {
              protocol = row.fromPort.toString();
            }
            let portRange = 'All';
            if (row.fromPort === 0 && row.toPort === 65535) {
              portRange = `${row.fromPort} - ${row.toPort}`;
            } else if (type === 'custom_icmp_ipv4') {
              if (protocol === '3' || protocol === '5' || protocol === '12') {
                portRange = row.toPort.toString();
              } else {
                portRange = 'N/A';
              }
            } else if (row.fromPort !== -1) {
              portRange = row.fromPort.toString();
            }

            ruleRows.push({
              securityGroupRuleId: row.securityGroupRuleId,
              type: type,
              protocol: protocol,
              portRange: portRange,
              destination: row.cidrIpv4 ? row.cidrIpv4 : row.cidrIpv6 ? row.cidrIpv6 : '-',
              description: row.description,
            });
          });

        setUpdateSecurityGroupRulePopupRows(ruleRows);
        setUpdateRulePopupHeader(
          detailTabSelected == detailSecurityGroupTabs[1] ? 'Edit Inbound rules' : 'Edit Outbound rules',
        );
        setIsUpdateInboundPopup(detailTabSelected == detailSecurityGroupTabs[1]);
        setIsShowUpdateSecurityGroupRulePopup(true);
        return;
      }
    }
    setUpdateTagsPopupRows([]);
    setUpdateTagsPopupColumns([]);
    setUpdateTagsPopupHeader('');
    setUpdateTagsPopupSubHeader('');
    setIsShowUpdateTagsPoup(false);
    setIsShowEditSubnetPoup(false);
    setUpdateRulePopupRows([]);
    setUpdateRulePopupHeader('');
    setIsUpdateInboundPopup(true);
    setIsShowUpdateRulePopup(false);
    return;
  }, [relatedCloudSelected]);

  const handleClickCreateBtn = () => {
    switch (mainTabSelected) {
      case mainTabs[6]:
        setScreenName('CREATE_NW_ACL');
        break;
      case mainTabs[7]:
        setScreenName('CREATE_SG');
        break;
      default:
        break;
    }
  };

  const renderShowing = useMemo(() => {
    switch (screenName) {
      case 'DETAIL':
        const detailInfo: [{ key: string; value: string }] = mainTblRows.find(r => r?.vpcId == mainSelected)?.tags;
        const nameTitle = detailInfo?.find(t => t?.key == 'Name')?.value;
        return (
          <MgdLayout
            layoutType={MgdLayoutTypeEnum.MGD_DETAIL}
            tablePagination={detailTablePagination}
            updateTablePagination={(key: string, value: number | string | OrderDirection) =>
              setDetailTablePagination(prev => ({
                ...prev,
                [key]: value,
              }))
            }
            total={{
              totalElement: detailTblRows.length,
              totalPage: 1,
            }}
            rows={detailTblRows}
            checkedList={checkedList}
            reportCheckedList={(list: string[]) => setCheckedList(list)}
            reportSelected={(id: string) => setMainSelected(id)}
            pageTitle={`${mainTabSelected.name} / ${mainSelected} ${
              nameTitle != undefined ? '(' + nameTitle + ')' : ''
            }`}
            hasFavorite={false}
            headerDropList={regionList}
            headerDropValue={selectedRegion}
            headerDropOnchange={val => {
              setSelectedRegion(() => ({
                name: val.name ? val.name : '',
                value: String(val.value),
              }));
            }}
            pageBackClick={() => {
              setMainSelected('');
              setScreenName('DATA_GRID');
              setDetailTabSelected(detailVpcTabs[0]);
            }}
            pinBtnActHandle={() => {}}
            pinBtnDisabled={false}
            mainTabList={mainTabs}
            mainTabItemClick={(id: string) => {
              const networkTab: ITabProps | undefined = mainTabs.find(val => val.id === id);
              setMainTabSelected(() => ({
                id: id,
                title: String(networkTab?.title),
                name: String(networkTab?.name),
                description: networkTab?.description,
              }));
            }}
            mainTitle={detailVpcTabs[0].title}
            mainTabSelected={mainTabSelected}
            columns={detailTblColumns}
            currentReport={selected}
            detailActionTitle={detailTabSelected && detailTabSelected.title}
            detailActionValue={actionValue}
            detailActionList={actionDropdownData}
            detailTabList={detailTabList.slice(1)}
            detailTabSelected={detailTabSelected}
            detailTabItemClick={setDetailTabSelected}
            detailsData={getDetailsDataByTabId}
            detailHyperLinkClick={(currTab: IMgdTabProps, data: any) => {
              setScreenName('DATA_GRID');
              handleHDetailHyperlinkMainClick(currTab, data);
            }}
            detailsTblData={{
              rows: detailRowsCurrentPage,
              columns: detailTblColumns,
              total: detailTblTotal,
              tablePagination: detailTablePagination,
              updateTablePagination: (key: string, value: number | string | OrderDirection) =>
                setDetailTablePagination(prev => ({
                  ...prev,
                  [key]: value,
                })),
            }}
            detailEditBtnVisible={detailEditBtnVisible}
            detailEditBtnClicked={detailEditBtnClickHandler}
            detailEditBtnTitle={detailEditBtnTitle}
            detailIsLoading={detailApiIsLoading}
          />
        );
      case 'CREATE_NW_ACL':
        return (
          <NetWorkACLCreation
            listVpc={[
              {
                id: 0,
                name: 'Vpc-0a58a948ec4c594ba1 (nemo-dev-vpc)',
                description: 'nemo-dev-vpc',
                value: 'vpc-0a58a948ec4c594ba',
              },
              {
                id: 1,
                name: 'Vpc-0a58a948ec4c594ba2 (nemo-dev-vpc)',
                description: 'nemo-dev-vpc',
                value: 'vpc-0a58a948ec4c594ba',
              },
              {
                id: 2,
                name: 'Vpc-0a58a948ec4c594ba3 (nemo-dev-vpc)',
                description: 'nemo-dev-vpc',
                value: 'vpc-0a58a948ec4c594ba',
              },
              {
                id: 3,
                name: 'Vpc-0a58a948ec4c594ba4 (nemo-dev-vpc)',
                description: 'nemo-dev-vpc',
                value: 'vpc-0a58a948ec4c594ba',
              },
            ]}
            pageBackClick={() => {
              setScreenName('DATA_GRID');
              setMainTabSelected(mainTabs[6]);
              setDetailTabSelected(detailNetworkALCTabs[0]);
            }}
          />
        );
      case 'CREATE_SG':
        return (
          <SecurityGroupCreation
            listVpc={[
              {
                id: 0,
                name: 'Vpc-0a58a948ec4c594ba1 (nemo-dev-vpc)',
                description: 'nemo-dev-vpc',
                value: 'vpc-0a58a948ec4c594ba',
              },
              {
                id: 1,
                name: 'Vpc-0a58a948ec4c594ba2 (nemo-dev-vpc)',
                description: 'nemo-dev-vpc',
                value: 'vpc-0a58a948ec4c594ba',
              },
              {
                id: 2,
                name: 'Vpc-0a58a948ec4c594ba3 (nemo-dev-vpc)',
                description: 'nemo-dev-vpc',
                value: 'vpc-0a58a948ec4c594ba',
              },
              {
                id: 3,
                name: 'Vpc-0a58a948ec4c594ba4 (nemo-dev-vpc)',
                description: 'nemo-dev-vpc',
                value: 'vpc-0a58a948ec4c594ba',
              },
            ]}
            pageBackClick={() => {
              setScreenName('DATA_GRID');
              setMainTabSelected(mainTabs[7]);
              setDetailTabSelected(detailSecurityGroupTabs[0]);
            }}
          />
        );
      default:
        return (
          <MgdLayout
            layoutType={layoutType}
            pageTitle={cloudName}
            hasFavorite={true}
            pageBackClick={pageBackClick}
            recentRelatedClouds={recentRelatedClouds}
            relatedCloudSelected={relatedCloudSelected}
            recentRelatedCloudOnChange={relatedCloud => recentRelatedCloudOnChange(relatedCloud)}
            headerDropList={regionList}
            headerDropValue={selectedRegion}
            headerDropOnchange={val => {
              setSelectedRegion(() => ({
                name: val.name ? val.name : '',
                value: String(val.value),
              }));
            }}
            rows={mainRowsCurrentPage}
            nextToken={mainNextToken}
            fetchMoreTotalRows={nextToken => fetchMoreMainRowsHandler(nextToken)}
            columns={mainTblColumns}
            total={mainTblTotal}
            tablePagination={mainTablePagination}
            updateTablePagination={(key: string, value: number | string | OrderDirection) =>
              setMainTablePagination(prev => ({
                ...prev,
                [key]: value,
              }))
            }
            checkedList={checkedList}
            reportCheckedList={(list: string[]) => setCheckedList(list)}
            currentReport={mainSelected}
            reportSelected={(id: string) => {
              setCheckedList([id]);
              setMainSelected(id);
              setDefaultDetailTabSelected();
            }}
            mainIsLoading={mainApiIsLoading}
            isPined={
              !!defaultRegionList.find(r => {
                return r.projectId === projectId && r.cloudId === relatedCloudSelected.value;
              })
            }
            pinBtnActHandle={pinButtonClickHandle}
            pinBtnDisabled={false}
            mainTabList={mainTabs}
            mainTabItemClick={mainTabClickHandler}
            mainTitle={mainTabSelected.title}
            mainTabSelected={mainTabSelected}
            mainTypeList={mainSearchDropdown}
            mainTypeValue={mainFilterValue}
            mainTypeDropdownOnchange={val => {
              setMainFilterValue(val);
              setMainSearchValue(prev => ({
                ...prev,
                name: val.value.toString(),
              }));
            }}
            searchValue={mainSearchValue?.value}
            searchInputOnchange={str => {
              setMainSearchValue(prev => ({ name: prev?.name, value: str }));
              if (currentRouteState) {
                setCurrentRouteState?.(null);
              }
            }}
            onSearchKeyDown={(event: any) => onSearchKeyDownHandler(event)}
            mainDelBtnVisible={mainDelBtnVisible}
            mainDelBtnClicked={() => setIsShowDeletePoup(!isShowDeletePopup)}
            mainDelBtnDisabled={checkedList.length <= 0}
            mainCreateBtnVisible={mainCreateBtnVisible}
            mainCreateBtnClicked={() => {
              handleClickCreateBtn();
            }}
            detailTabList={detailTabList}
            detailTabItemClick={setDetailTabSelected}
            detailTitle={detailTabSelected && detailTabSelected.title}
            detailsData={getDetailsDataByTabId}
            detailTabSelected={detailTabSelected}
            detailHyperLinkClick={handleHDetailHyperlinkMainClick}
            detailsTblData={{
              rows: detailRowsCurrentPage,
              nextToken: detailNextToken,
              columns: detailTblColumns,
              total: detailTblTotal,
              tablePagination: detailTablePagination,
              updateTablePagination: (key: string, value: number | string | OrderDirection) =>
                setDetailTablePagination(prev => ({
                  ...prev,
                  [key]: value,
                })),
            }}
            detailIsLoading={detailApiIsLoading}
          />
        );
    }
  }, [
    regionList,
    selectedRegion,
    mainTabSelected,
    mainTblRows,
    mainRowsCurrentPage,
    detailRowsCurrentPage,
    mainTblColumns,
    mainTblTotal,
    mainSelected,
    detailTabSelected,
    mainTablePagination,
    mainSearchDropdown,
    mainFilterValue,
    mainSearchValue,
    detailTabList,
    detailTblRows,
    detailTblColumns,
    detailTblTotal,
    detailTablePagination,
    checkedList,
    mainDelBtnVisible,
    mainCreateBtnVisible,
    detailEditBtnVisible,
    detailEditBtnTitle,
    defaultRegionList,
    mainApiIsLoading,
    detailApiIsLoading,
    relatedCloudSelected,
    recentRelatedClouds,
  ]);

  return (
    <div className="category" style={{ height: '100%' }}>
      {renderShowing}
      <DeleteNetworkModal
        rows={deletePopupRows}
        columns={deletePopupColumns}
        header={'Delete network ACLs'}
        onDelete={checkedList => {
          console.log('Modal Delete: ', checkedList);
        }}
        open={isShowDeletePopup}
        onClose={() => setIsShowDeletePoup(!isShowDeletePopup)}
      />
      <UpdateTagsModal
        header={updateTagsPopupHeader}
        subHeader={updateTagsPopupSubHeader}
        currentDatas={updateTagsPopupRows}
        columns={updateTagsPopupColumns}
        open={isShowUpdateTagsPopup}
        onSave={(oldTags: TagRowData[], newTags: TagRowData[]) => {
          console.log('currentDataRows: ', oldTags);
          console.log('tagRows: ', newTags);
        }}
        onClose={() => setIsShowUpdateTagsPoup(!isShowUpdateTagsPopup)}
      />
      <EditSubnetAssociationModal
        rows={detailTblRows}
        columns={[
          { label: 'Name', field: 'name', sort: true },
          { label: 'Subnet ID', field: 'subnetId', sort: true },
          { label: 'Associated with', field: 'networkAclId', sort: true },
          { label: 'Availability Zone', field: 'availabilityZone', sort: true },
          { label: 'IPv4 CIDR', field: 'ipv4cidr', sort: true },
          { label: 'IPv6 CIDR', field: 'ipv6cidr', sort: true },
        ]}
        header={'Edit subnet associations'}
        onDelete={checkedList => {
          console.log('Modal Edit Subnet: ', checkedList);
        }}
        open={isShowEditSubnetPopup}
        onClose={() => setIsShowEditSubnetPoup(!isShowEditSubnetPopup)}
      />
      <EditSecurityGroupRuleModal
        header={updateRulePopupHeader}
        currentData={updateSecurityGroupRulePopupRows}
        subTitle={
          isUpdateInboundPopup
            ? 'Inbound rules control the incoming traffic that’s allowed to reach the instance.'
            : 'Outbound rules control the outgoing traffic that’s allowed to reach the instance.'
        }
        isInbound={isUpdateInboundPopup}
        open={isShowUpdateSecurityGroupRulePopup}
        onClose={() => setIsShowUpdateSecurityGroupRulePopup(!isShowUpdateSecurityGroupRulePopup)}
        onSave={() => {}}
      />
      <EditNetworkRuleModal
        header={updateRulePopupHeader}
        currentData={updateRulePopupRows}
        isInbound={isUpdateInboundPopup}
        open={isShowUpdateRulePopup}
        onClose={() => setIsShowUpdateRulePopup(!isShowUpdateRulePopup)}
        onSave={() => {}}
      />
    </div>
  );
};
export default Network;
