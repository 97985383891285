import { gql } from '@apollo/client';

const query = gql`
  query getAwsAllListFirewalls($request: AwsListFirewallsRequest, $cloudId: Long, $region: String) {
    getAwsAllListFirewalls(listFirewallsRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsFirewallMetadata {
          firewallName
          firewallArn
        }
      }
    }
  }
`;
export default query;
