import { useState, useMemo, useEffect, useCallback } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import IconStatelessAction from 'assets/svgs/v3/ico_stateless_actions.svg';
import IconSearch from 'assets/svgs/v3/ico_search.svg';
import Icon from 'components/v2/atoms/Icon';
import './index.scss';
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import CheckboxAtom from 'components/v2/atoms/CheckboxAtom';
import MultiSelectAutocomplete from 'components/v2/atoms/AutocompleteInputAtom';
import CopyableText from 'components/v3/CopyableText';
import AutoInputSelected from 'components/v2/atoms/AutoCompleteDropdownAtom';
import RadioAtom from 'components/v2/atoms/RadioAtom';
import InputAtom from 'components/v2/atoms/InputAtom';

interface IStatelessActionsProps extends IBaseModalProps {
  header: string;
  onChangeValue?: (index: number, value: string) => void;
}

const StatelessActionsModal = ({
  header,
  onChangeValue,
  ...baseModalProps
}: IStatelessActionsProps) => {
  const [payload, setPayload] = useState<string>('');

  useEffect(() => {
    if (baseModalProps.open) {
      setPayload('');
    }
  }, [baseModalProps.open]);

  const [packetValues, setPacketValues] = useState<string>('same');
  const [fullPacketValues, setFullPacketValues] = useState<string>('pass');
  const [fragmentedPacketValues, setFragmentedPacketValues] = useState<string>('pass');
  const [fragmentedItemValue, setFragmentedItemValue] = useState<string>('');
  const [ isShowFragment, setIsShowFragment] = useState<boolean>(false);

  return (
    <BaseModal
      title={() => <><Icon width={32} height={32} src={IconStatelessAction} />{header}</>}
      {...baseModalProps}>
      <div className="stateless-actions-modal">
        <div className="stateless-actions">
          <div className="row-1">
            <p className="text-title">Fragmented packets</p>
            <div className="radio-content">
              <RadioAtom
                label={'Use the same actions for all packets'}
                value={'same'}
                name={''}
                checked={packetValues}
                onChange={() => {
                  setPacketValues('same');
                }}/>

              <RadioAtom
                label={'Use different actions for full packets and fragmented packets'}
                value={'different'}
                name={''}
                checked={packetValues}
                onChange={() => {
                  setPacketValues('different');
                  setIsShowFragment(true);
                }}/>
            </div>
          </div>

          {packetValues === 'different' &&
            <>
              {isShowFragment &&
                <div className="row-2">
                  <InputAtom
                    placeholder={'Add empty item'}
                    className={'input-add'}
                    onChangeValue={(val) => {
                      setFragmentedItemValue(val);
                    }}
                    value={fragmentedItemValue}
                    required={true}
                    noClear={false}/>

                  <button className="remove-btn" onClick={() => {
                    setIsShowFragment(false);
                  }}>
                    Remove
                  </button>
                </div>
              }
            </>
          }

          <div className="row-3">
            <p className="text-title">Default actions for full packets</p>
            <p className="text-description">Rule action</p>
            <RadioAtom
              label={'Pass'}
              value={'pass'}
              name={''}
              checked={fullPacketValues}
              onChange={() => {
                setFullPacketValues('pass');
              }}/>

            <RadioAtom
              label={'Drop'}
              value={'drop'}
              name={''}
              checked={fullPacketValues}
              onChange={() => {
                setFullPacketValues('drop');
              }}/>

            <RadioAtom
              label={'Forward to stateful rule groups'}
              value={'forward'}
              name={''}
              checked={fullPacketValues}
              onChange={() => {
                setFullPacketValues('forward');
              }} />
          </div>

          {packetValues === 'different' &&
            <>
              <div className="row-3">
                <p className="text-title">Default actions for fragmented packets</p>
                <p className="text-description">Rule action</p>
                <RadioAtom
                  label={'Pass'}
                  value={'pass'}
                  name={''}
                  checked={fragmentedPacketValues}
                  onChange={() => {
                    setFragmentedPacketValues('pass');
                  }} />

                <RadioAtom
                  label={'Drop'}
                  value={'drop'}
                  name={''}
                  checked={fragmentedPacketValues}
                  onChange={() => {
                    setFragmentedPacketValues('drop');
                  }} />

                <RadioAtom
                  label={'Forward to stateful rule groups'}
                  value={'forward'}
                  name={''}
                  checked={fragmentedPacketValues}
                  onChange={() => {
                    setFragmentedPacketValues('forward');
                  }} />
              </div>
            </>
          }

        </div>
        <div className="button-group">
          <button onClick={baseModalProps.onClose}>Cancel</button>
          <button className="delete-btn" onClick={() => { }}>
            Save
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default StatelessActionsModal;
