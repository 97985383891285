import NetworkTitle from "components/v3/NetworkTitle";
import React, {useEffect, useMemo, useRef, useState} from "react";
import './index.scss';
import RequiredIcon from "assets/svgs/v2/ico_require.svg";
import InputAtom from "components/v2/atoms/InputAtom";
import {DropdownListDataType} from "components/v2/atoms/DropdownAtom";
import CheckboxAtom from "components/v2/atoms/CheckboxAtom";
import DetailMultiColumn, {DetailMultiColumProps} from "layouts/v3/MgdLayout/components/DetailMultiColumns";
import classNames from "classnames";
import ArrowIcon from "assets/svgs/v2/ico_dropdown_arrow.svg";
import {TextTypeEnum} from "layouts/v3/MgdLayout";
import Table from 'components/v2/dataDisplay/Table';
import {ColumnType, OrderDirection, RowType} from "@Types/v2/Table";

const ipAddressTypeDropdownList: DropdownListDataType[] = [
  {
    id: 1,
    name: 'IPV4',
    description: '',
    value: 'IPV4'
  },
  {
    id: 2,
    name: 'IPV6',
    description: '',
    value: 'IPV6'
  },
  {
    id: 3,
    name: 'Dualstack',
    description: '',
    value: 'Dualstack'
  }
]

const availabilityZone: DropdownListDataType[] = [
  {
    id: 1,
    name: 'zone 1',
    description: '',
    value: 'zone 1'
  },
  {
    id: 2,
    name: 'zone 2',
    description: '',
    value: 'zone 2'
  },
  {
    id: 3,
    name: 'zone 3',
    description: '',
    value: 'zone 3'
  }
]

const subnetDropdownList: DropdownListDataType[] = [
  {
    id: 1,
    name: 'subnet 1',
    description: '',
    value: 'subnet 1'
  },
  {
    id: 2,
    name: 'subnet 2',
    description: '',
    value: 'subnet 2'
  },
  {
    id: 3,
    name: 'subnet 3',
    description: '',
    value: 'subnet 3'
  }
]

type FirewallCreationProps = {
  pageBackClick: () => void;
}

interface IFirewallDetail {
  firewallName: string;
  description: string;
}

interface IFirewallSubnet {
  availabilityZone: DropdownListDataType;
  subnet: DropdownListDataType;
  ipAddressType: DropdownListDataType;
}

interface ICustomDropdownProps {
  id: string;
  data: DropdownListDataType[];
  value: DropdownListDataType;
  className?: string;
  style?: any;
  placeholder?: string;
  disabled?: boolean;
  error?: boolean;
  upsideDown?: number;
  handleClick: (val: DropdownListDataType) => void;
}

export const CustomDropdown = (props: ICustomDropdownProps) => {
  const {
    id,
    data,
    value,
    className,
    style,
    placeholder,
    disabled = false,
    error,
    upsideDown,
    handleClick
  } = props;

  const listRef = useRef<null | HTMLDivElement>(null);

  const [bg, setBg] = useState({
    width: 0,
    top: 0,
    left: 0
  });

  const openMenu = (parentId: string) => {
    Array.from(document.getElementsByClassName('dropdown-list')).forEach(el => {
      const parentEl = el.parentElement;
      if (parentEl?.id === parentId) {
        if (el.classList.contains('none')) {
          el.classList.remove('none');
        } else {
          el.classList.add('none');
        }
      } else {
        el.classList.add('none');
      }
    });
  };

  const closeAll = () => {
    Array.from(document.getElementsByClassName('dropdown-list')).forEach(el => el.classList.add('none'));
  };

  useEffect(() => {
    document.addEventListener('click', closeAll);

    return () => {
      document.removeEventListener('click', closeAll);
    };
  }, []);

  return (
    <div className={classNames('dropdown-atom', className)} id={`${id}-wrap`}>
      <button
        type="button"
        style={style}
        id={id}
        disabled={disabled}
        className={classNames(error && 'error')}
        onClick={(e) => {
          e.stopPropagation();
          openMenu(`${id}-wrap`);

          const dropdownAtomEl = document.getElementById(`${id}-wrap`);
          const listWrapEl = document.getElementById(`list-wrap ${id}`);

          if (!dropdownAtomEl || !listWrapEl) return;
          if (window.innerHeight - e.currentTarget.getBoundingClientRect().bottom > dropdownAtomEl.offsetHeight + listWrapEl.offsetHeight + 5) {
            setBg({
              width: e.currentTarget.offsetWidth,
              top: e.currentTarget.getBoundingClientRect().bottom + 5,
              left: e.currentTarget.getBoundingClientRect().left
            });
          } else {
            setBg({
              width: e.currentTarget.offsetWidth,
              top: e.currentTarget.getBoundingClientRect().bottom - (dropdownAtomEl.offsetHeight + listWrapEl.offsetHeight + 5),
              left: e.currentTarget.getBoundingClientRect().left
            });
          }
        }}
      >
        <input readOnly placeholder={placeholder} value={value.name} disabled={disabled}/>
        <img src={ArrowIcon} width={24} height={24}/>
      </button>
      {!disabled &&
          <div className="dropdown-list none" ref={listRef}>
              <div
                  id={`list-wrap ${id}`}
                  className="list-wrapper"
                  style={{
                    top: bg.top,
                    left: bg.left,
                    width: bg.width
                  }}>
                  <ul>
                    {data.map((d, idx) =>
                      <li
                        key={`${idx}-${d.value}`}
                        style={{height: 'unset', padding: '8px 17px'}}
                        className="list-item"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleClick(d);
                          e.currentTarget.parentElement?.parentElement?.parentElement?.classList.add('none');
                          setBg({
                            width: 0,
                            top: 0,
                            left: 0
                          });
                        }}
                      >
                        <div style={{}}>
                          <div style={{display: 'flex', flexDirection: 'column'}}>
                            <p className='custom-dropdown-item-label'>{d.name}</p>
                            {d.description && <p className='custom-dropdown-item-description'>{d.description}</p>}
                          </div>
                        </div>
                      </li>
                    )}
                  </ul>
              </div>
          </div>
      }
    </div>
  );
}

const FirewallCreation = (props: FirewallCreationProps) => {
  const { pageBackClick } = props;
  const [screenName, setScreenName] = useState<'CREATION' | 'PREVIEW'>('CREATION')
  const [firewallDetails, setFirewallDetails] = useState<IFirewallDetail>({firewallName: 'acl-92jfkdjfjdf0334', description: '4 Subnets'});
  const [firewallSubnets, setFirewallSubnets] = useState<IFirewallSubnet[]>([{availabilityZone: availabilityZone[0], subnet: subnetDropdownList[0], ipAddressType: ipAddressTypeDropdownList[0]}]);
  const [isEnableDeleteProtection, setIsEnableDeleteProtection] = useState<boolean>(true);
  const [isEnableSubnetChangeProtection, setIsEnableSubnetChangeProtection] = useState<boolean>(true);
  const [isCustomizeEncryptionSettings, setIsCustomizeEncryptionSettings] = useState<boolean>(true);
  const [tags, setTags] = useState<{key: string, value?: string}[]>([{key: 'A', value: 'B'}]);
  const [associatedVpc, setAssociatedVpc] = useState<string>('vpc-0934ndfmnfdf');
  const [associatedFirewallPolicy, setAssociatedFirewallPolicy] = useState<string>('test2firewallpolicy');

  const firewallData = useMemo(() => {
    const data: DetailMultiColumProps[] = [
      {
        title: 'Firewall details',
        contents: [
          {
            title: 'Name',
            description: firewallDetails.firewallName,
            type: TextTypeEnum.NORMAL
          },
          {
            title: 'Description',
            description: firewallDetails.description,
            type: TextTypeEnum.NORMAL
          }
        ]
      }
    ];
    return {
      title: 'Firewall',
      data: data
    }
  }, [firewallDetails]);

  const vpcAndSubnetData = useMemo(() => {
    const data: DetailMultiColumProps[] = [
      {
        title: 'VPC and subnets',
        contents: [
          {
            title: 'Associated VPC',
            description: associatedVpc,
            type: TextTypeEnum.LINK
          },
          {
            title: 'Firewall subnets',
            description: firewallSubnets.length > 1 ? `${firewallSubnets.length} subnets` : `${firewallSubnets[0].ipAddressType.name} ${firewallSubnets[0].subnet.name}`,
            type: TextTypeEnum.NORMAL
          }
        ]
      }
    ];
    return {
      title: 'Firewall',
      data: data
    }
  }, [firewallSubnets]);

  const protectionAgainstChangesData = useMemo(() => {
    const data: DetailMultiColumProps[] = [
      {
        title: 'Protection against changes',
        contents: [
          {
            title: 'Delete protection',
            description: isEnableDeleteProtection ? 'Enable' : 'Disable',
            type: TextTypeEnum.NORMAL
          },
          {
            title: 'Subnet change protection',
            description: isEnableSubnetChangeProtection ? 'Enable' : 'Disable',
            type: TextTypeEnum.NORMAL
          }
        ]
      }
    ];
    return {
      title: 'Associated settings',
      data: data
    };
  }, [isEnableDeleteProtection, isEnableSubnetChangeProtection]);

  const customerManagedKeyData = useMemo(() => {
    const data: DetailMultiColumProps[] = [
      {
        title: 'Customer managed key',
        contents: [
          {
            title: 'Key type',
            description: 'AWS owned key',
            type: TextTypeEnum.NORMAL
          }
        ]
      }
    ];
    return {
      title: '',
      data: data
    }
  }, []);

  const firewallPolicyData = useMemo(() => {
    const data: DetailMultiColumProps[] = [
      {
        title: 'Firewall policy',
        contents: [
          {
            title: 'Associated  firewall policy',
            description: associatedFirewallPolicy,
            type: TextTypeEnum.NORMAL
          }
        ]
      }
    ];
    return {
      title: 'Firewall policy',
      data: data
    }
  }, [associatedFirewallPolicy]);

  const tagsData = useMemo(() => {
    const data: {rows: RowType[], columns: ColumnType[]} = {
      rows: tags,
      columns: [
        {field: 'key', label: 'Key', sort: true},
        {field: 'value', label: 'Value', sort: true}
      ]
    }
    return {
      title: 'Tags',
      data: data
    }
  }, [tags]);

  const handleChangeFirewallDetails = (field: string, value: string) => {
    setFirewallDetails({
      ...firewallDetails,
      [field]: value
    });
  }

  const handleChangeFirewallSubnet = (field: string, value: DropdownListDataType, index: number) => {
    const newFirewallSubnets = firewallSubnets.map((item, i) => {
      if(index === i) {
        return {
          ...item,
          [field]: value
        }
      } else {
        return item;
      }
    });

    setFirewallSubnets(newFirewallSubnets);
  }

  const handleRemoveFirewallSubnet = (index: number) => {
    const newFirewallSubnets = firewallSubnets.slice();
    newFirewallSubnets.splice(index, 1);

    setFirewallSubnets(newFirewallSubnets);
  }

  const handleAddFirewallSubnet = () => {
    const newSubnet: IFirewallSubnet[] = firewallSubnets.concat({
      availabilityZone: availabilityZone[0],
      subnet: subnetDropdownList[0],
      ipAddressType: ipAddressTypeDropdownList[0]
    });

    setFirewallSubnets(newSubnet);
  }

  const handleAddTag = () => {
    const newTags = tags.concat({key: 'A', value: 'B'});
    setTags(newTags);
  }

  const handleRemoveTag = (index: number) => {
    const newTags = tags.slice();
    newTags.splice(index, 1);
    setTags(newTags);
  }

  const handleChangeTag = (field: 'key' | 'value', value: string, index: number) => {
    const newTags = tags.map((item, i) => {
      if(index === i) {
        return {
          ...item,
          [field]: value
        }
      } else {
        return item;
      }
    });
    setTags(newTags);
  }

  const handleOnClickNext = () => {
    setScreenName("PREVIEW");
  }

  const handleOnclickCancel = () => {
    pageBackClick();
  }

  const handleOnclickPrevious = () => {
    switch(screenName) {
      case "CREATION":
        pageBackClick();
        break;
      case "PREVIEW":
        setScreenName("CREATION");
        break;
      default:
        pageBackClick();
    }
  }

  const handleOnClickCreateFirewall = () => {

  }

  function handleSelectAssociatedVpc(val: DropdownListDataType) {
    setAssociatedVpc(val.name ?? '');
  }

  return (
    <div id="mgd-list-layout">
      <div className="row-1 flex j-between a-center">
        <div className="flex j-start a-center" id="title">
          <NetworkTitle pageTitle={screenName === "CREATION" ? 'Create firewall' : 'Create firewall: Review'} id={321} name={'name'} hasPrefixIcon={false}
                        hasFavorite={false} pageBackClick={pageBackClick}/>
        </div>
      </div>

      <div className="one-page" style={{height: 'calc(100% - 70px)', overflowY: 'auto'}}>
        <div className="flex j-between a-center">
          {
            screenName === "CREATION" &&
              <div className='firewall-rule-group'>
                  <div className='rule-group-container'>
                      <div className="title-group">
                          <p className='title'>Describe firewall</p>
                          <p className='sub-title'>Name and describe your firewall so you can easily identify it and
                              distinguish
                              it from other resources.</p>
                      </div>
                      <div className='rule-group-details-container'>
                          <div className='rule-group-detail-title'>
                              Firewall details
                          </div>
                          <div className='rule-group-detail-content'>
                              <div className='detail-content-row'>
                                  <div className='detail-content-column-left'>
                                      Firewall name
                                      <img style={{marginLeft: 5}} src={RequiredIcon} width={4} height={4}/>
                                  </div>
                                  <div className='detail-content-column-right'>
                                      <div className='full-width'>
                                          <InputAtom value={firewallDetails.firewallName} noClear={true}
                                                     onChangeValue={(value: string) => handleChangeFirewallDetails('firewallName', value)}/>
                                      </div>
                                      <p>Enter a unique name for the firewall. You can’t change the name of the firewall
                                          after
                                          creation.</p>
                                  </div>
                              </div>
                              <div className='detail-content-row'>
                                  <div className='detail-content-column-left'>
                                      Description
                                  </div>
                                  <div className='detail-content-column-right'>
                                      <div className='full-width'>
                                          <InputAtom value={firewallDetails.description} noClear={true}
                                                     onChangeValue={(value: string) => handleChangeFirewallDetails('description', value)}/>
                                      </div>
                                      <p>The description can have 0-256 characters.</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>

                  <div className='rule-group-container'>
                      <div className="title-group">
                          <p className='title'>Configure VPC and subnets</p>
                          <p className='sub-title'>The firewall protects the subnets within an Amazon Virtual Private Cloud
                              (VPC)
                              by filtering traffic going between the subnets and locations outside of your VPC. After you
                              create the
                              firewall and its associated firewall policy, configure your VPC to route traffic through the
                              endpoints
                              created by the firewall.</p>
                      </div>

                      <div className='rule-group-details-container'>
                          <div className='rule-group-detail-title' style={{display: 'flex', gap: 20}}>
                              VPC
                              <span className='rule-group-detail-sub-title'>For each Availability Zone where you want protection, provide Network Firewall with a public subnet that’s dedicated to the firewall endpoint. Only use the firewall subnets that you specify here for the firewall. Don’t use them for any other purpose.</span>
                          </div>
                          <div className='firewall-detail-title'>
                              VPC
                              <div className='firewall-detail-sub-title'>Choose the VPC where you want to create this
                                  firewall.</div>
                          </div>
                          <div className='rule-group-detail-content' style={{paddingTop: 0}}>
                              <CustomDropdown
                                  id={'vpc-firewall-dropdown'}
                                  style={{height: 40}}
                                  data={[]}
                                  value={{value: ''}}
                                  handleClick={(val) => {handleSelectAssociatedVpc(val)}}/>
                          </div>
                          <div className='firewall-detail-title'>
                              Firewall subnets
                              <div className='firewall-detail-sub-title'>Each subnet must have on available IP address. You
                                  can’t
                                  change the Subnet’s IP address type after creation.</div>
                          </div>
                          <div className='rule-group-details-container' style={{border: 'none'}}>
                              <div className='config-rule-row'>
                                  <div className='rule-group-detail-content'
                                       style={{padding: '0px 16px 16px 16px', gap: 4}}>
                                      <div className='config-rule-row'>
                                          <div className='config-rule-column'>
                                              <div className='firewall-detail-sub-title'>
                                                  Availability Zone
                                              </div>
                                          </div>
                                          <div className='config-rule-column'>
                                              <div className='firewall-detail-sub-title'>
                                                  Subnet
                                              </div>
                                          </div>
                                          <div className='config-rule-column'>
                                              <div className='firewall-detail-sub-title'>
                                                  IP address type
                                              </div>
                                          </div>
                                          <div style={{marginLeft: 6, opacity: 0, pointerEvents: 'none'}}>
                                              <button className="button"
                                                      style={{margin: 0}}
                                                      onClick={() => {
                                                      }}
                                              >
                                                  Remove subnet
                                              </button>
                                          </div>
                                      </div>
                                    {
                                      firewallSubnets.map((item, index) => {
                                        return (
                                          <div key={index} className='config-rule-row'>
                                            <div className='config-rule-column'>
                                              <CustomDropdown
                                                id={`availability-zone-${index}`}
                                                style={{height: 40}}
                                                data={availabilityZone}
                                                value={firewallSubnets[index].availabilityZone}
                                                handleClick={(val) => {
                                                  handleChangeFirewallSubnet('availabilityZone', val, index)
                                                }}/>
                                            </div>
                                            <div className='config-rule-column'>
                                              <CustomDropdown
                                                id={`subnet-${index}`}
                                                style={{height: 40}}
                                                data={subnetDropdownList}
                                                value={firewallSubnets[index].subnet}
                                                handleClick={(val) => {
                                                  handleChangeFirewallSubnet('subnet', val, index)
                                                }}/>
                                            </div>
                                            <div className='config-rule-column'>
                                              <CustomDropdown
                                                id={`ip-address-type-${index}`}
                                                style={{height: 40}}
                                                data={ipAddressTypeDropdownList}
                                                value={firewallSubnets[index].ipAddressType}
                                                handleClick={(val) => {
                                                  handleChangeFirewallSubnet('ipAddressType', val, index)
                                                }}/>
                                            </div>
                                            <button className="button"
                                                    style={{margin: 0}}
                                                    onClick={() => {
                                                      handleRemoveFirewallSubnet(index)
                                                    }}
                                            >
                                              Remove subnet
                                            </button>
                                          </div>
                                        )
                                      })
                                    }
                                      <button className="button next-button"
                                              style={{margin: '12px 0px 0px 0px'}}
                                              onClick={() => {
                                                handleAddFirewallSubnet()
                                              }}
                                      >
                                          Add new subnet
                                      </button>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>

                  <div className='rule-group-container'>
                      <div className="title-group">
                          <p className='title'>Configure advanced settings- optional</p>
                          <p className='sub-title'>Enable protection against changes and configure a customer manages AW5
                              Key
                              Management Service (KMS) key to encrypt and decrypt your resources.</p>
                      </div>
                      <div className='rule-group-details-container'>
                          <div className='rule-group-detail-title' style={{display: 'flex', gap: 20}}>
                              Protection against changes
                              <span className='rule-group-detail-sub-title'>Protect your firewall from accidental deletion and against changes to subnet associations.</span>
                          </div>
                          <div className='firewall-detail-title'>
                              Delete protection
                              <div className='firewall-detail-sub-title'>Protects the firewall from deletion. If enabled,
                                  Network
                                  firewall won’t delete the firewall if it’s in use.</div>
                          </div>
                          <div className='rule-group-detail-content' style={{padding: '0px 16px', gap: 16}}>
                              <div style={{display: 'flex', gap: 8, alignItems: 'center'}}>
                                  <CheckboxAtom id='delete-protection-checkbox' checked={isEnableDeleteProtection}
                                                onchange={() => {
                                                  setIsEnableDeleteProtection(!isEnableDeleteProtection)
                                                }}/>
                                  <p className='text-content'>Enable</p>
                              </div>
                          </div>
                          <div className='firewall-detail-title'>
                              Subnet change protection
                              <div className='firewall-detail-sub-title'>Protects the firewall against changes to the subnet
                                  associations. If enabled, you can’t change in active firewall’s subnet associations.</div>
                          </div>
                          <div className='rule-group-detail-content' style={{padding: '0px 16px 16px 16px', gap: 16}}>
                              <div style={{display: 'flex', gap: 8, alignItems: 'center'}}>
                                  <CheckboxAtom id='delete-protection-checkbox' checked={isEnableSubnetChangeProtection}
                                                onchange={() => {
                                                  setIsEnableSubnetChangeProtection(!isEnableSubnetChangeProtection)
                                                }}/>
                                  <p className='text-content'>Enable</p>
                              </div>
                          </div>
                      </div>
                      <div className='rule-group-details-container' style={{marginTop: 12}}>
                          <div className='rule-group-detail-title' style={{display: 'flex', gap: 20}}>
                              Customer managed key
                              <span className='rule-group-detail-sub-title'>You can use a customer managed key in AWS Key Management Service (KMS) to encrypt your data at rest. If you don’t configure a customer managed key, Network Firewall encrypts your data using an AWS managed key.</span>
                          </div>
                          <div className='firewall-detail-title'>
                              <div className='firewall-detail-sub-title'>Your data is encrypted by default with a key that
                                  AWS owns
                                  and manages for you. To choose a different key, customize your encryption settings.
                              </div>
                          </div>
                          <div className='rule-group-detail-content' style={{padding: '0px 16px 16px 16px', gap: 16}}>
                              <div style={{display: 'flex', gap: 8, alignItems: 'center'}}>
                                  <CheckboxAtom id='delete-protection-checkbox' checked={isCustomizeEncryptionSettings}
                                                onchange={() => {
                                                  setIsCustomizeEncryptionSettings(!isCustomizeEncryptionSettings)
                                                }}/>
                                  <p className='text-content'>Customize encryption settings (advanced)</p>
                              </div>
                          </div>
                      </div>

                      <div className='rule-group-details-container' style={{marginTop: 12}}>
                          <div className='rule-group-detail-title' style={{display: 'flex', gap: 20}}>
                              Associated firewall policy
                              <span className='rule-group-detail-sub-title'>The firewall policy contains a list of rule groups that define how the firewall inspects and manages web traffic. You can configure the associated firewall policy after you create the firewall.</span>
                          </div>
                          <div className='firewall-detail-title'>
                              Firewall policy
                              <div className='firewall-detail-sub-title'>Associate an existing firewall policy</div>
                          </div>
                          <div className='firewall-detail-title'>
                              Choose firewall policy
                              <div className='firewall-detail-sub-title'>Choose the firewall policy to associate with this
                                  firewall.</div>
                          </div>
                          <div className='rule-group-detail-content' style={{padding: '0px 16px 16px 16px', gap: 16}}>
                              <CustomDropdown
                                  id={'firewall-policy-dropdown'}
                                  placeholder='Choose a firewall policy'
                                  style={{height: 40}}
                                  data={[]}
                                  value={{value: ''}}
                                  handleClick={() => {
                                  }}/>
                          </div>
                      </div>

                      <div className='rule-group-details-container' style={{marginTop: 12}}>
                          <div className='rule-group-detail-title' style={{display: 'flex', gap: 20}}>
                              Tags
                          </div>
                          <div className='rule-group-detail-content' style={{gap: 4}}>
                              <div className='config-rule-row'>
                                  <div className='config-rule-column'>
                                      <div className='firewall-detail-sub-title'>
                                          Key
                                      </div>
                                  </div>
                                  <div className='config-rule-column'>
                                      <div className='firewall-detail-sub-title'>
                                          Value - optional
                                      </div>
                                  </div>
                                  <div style={{marginLeft: 6, opacity: 0, pointerEvents: 'none'}}>
                                      <button className="button"
                                              style={{margin: 0}}
                                              onClick={() => {
                                              }}
                                      >
                                          Remove tag
                                      </button>
                                  </div>
                              </div>
                            {
                              tags.map((item, index) => {
                                return (
                                  <div key={index} className='config-rule-row'>
                                    <div className='config-rule-column'>
                                      <InputAtom value={item.key} onChangeValue={(value) => {
                                        handleChangeTag('key', value, index)
                                      }}/>
                                    </div>
                                    <div className='config-rule-column'>
                                      <InputAtom value={item.value} onChangeValue={(value) => {
                                        handleChangeTag('value', value, index)
                                      }}/>
                                    </div>
                                    <button className="button"
                                            style={{margin: 0}}
                                            onClick={() => {
                                              handleRemoveTag(index)
                                            }}
                                    >
                                      Remove tag
                                    </button>
                                  </div>
                                )
                              })
                            }
                              <button className="button next-button"
                                      style={{margin: '12px 0px 0px 0px'}}
                                      onClick={() => {
                                        handleAddTag()
                                      }}
                              >
                                  Add tag
                              </button>
                              <div className='firewall-detail-title' style={{padding: 0}}>
                                  <div className='firewall-detail-sub-title'>You can add up to 48 more tags.
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>

                  <div className='firewall-rule-group-bottom'>
                      <button className="button"
                              style={{margin: 0}}
                              onClick={() => {
                                handleOnclickCancel();
                              }}
                      >
                          Cancel
                      </button>
                      <button className="button"
                              style={{marginLeft: 8}}
                              onClick={() => {
                                handleOnclickPrevious();
                              }}
                      >
                          Previous
                      </button>
                      <button className="button next-button"
                              style={{marginLeft: 8}}
                              onClick={() => {
                                handleOnClickNext()
                              }}
                      >
                          Next
                      </button>
                  </div>
              </div>
          }
          {
            screenName === "PREVIEW" &&
              <div className='firewall-rule-group'>
                  <div className='view-group-container'>
                      <div className='view-group-detail-title'>{firewallData.title}</div>
                      <DetailMultiColumn data={firewallData.data} currentTab={{id: '', name: '', title: ''}} style={{marginBottom: 0}}/>
                  </div>

                  <div className='view-group-container'>
                      <div className='view-group-detail-title'>{vpcAndSubnetData.title}</div>
                      <DetailMultiColumn data={vpcAndSubnetData.data} currentTab={{id: '', name: '', title: ''}} style={{marginBottom: 0}}/>
                  </div>

                  <div className='view-group-container'>
                      <div className='view-group-detail-title'>{protectionAgainstChangesData.title}</div>
                      <DetailMultiColumn data={protectionAgainstChangesData.data}
                                         currentTab={{id: '', name: '', title: ''}} style={{marginBottom: 0}}/>
                  </div>

                  <div className='view-group-container' style={{borderTop: 'none'}}>
                      <DetailMultiColumn data={customerManagedKeyData.data} currentTab={{id: '', name: '', title: ''}}
                                         style={{marginBottom: 0, marginTop: 0}}/>
                  </div>

                  <div className='view-group-container'>
                      <div className='view-group-detail-title'>{firewallPolicyData.title}</div>
                      <DetailMultiColumn data={firewallPolicyData.data} currentTab={{id: '', name: '', title: ''}} style={{marginBottom: 0}}/>
                  </div>

                  <div className='view-group-container' style={{borderBottom: '1px solid #E0E0E0', padding: 0}}>
                      <div className='view-group-detail-title'>{tagsData.title}</div>
                      <div className="detail-table" style={{borderBottom: 'none'}}>
                          <div className='header-container'>
                              <div className='table-detail-title'>
                                  <p>{tagsData.title}</p>
                              </div>
                          </div>
                          <Table
                              rows={tagsData.data.rows}
                              columns={tagsData.data.columns}
                              sortOption={{
                                target: '',
                                direction: OrderDirection.DES,
                                onChangeSort: () => {
                                }
                              }}
                          />
                      </div>
                  </div>

                  <div className='firewall-rule-group-bottom'>
                      <button className="button"
                              style={{margin: 0}}
                              onClick={() => {
                                handleOnclickCancel();
                              }}
                      >
                          Cancel
                      </button>
                      <button className="button"
                              style={{marginLeft: 8}}
                              onClick={() => {
                                handleOnclickPrevious();
                              }}
                      >
                      Previous
                      </button>
                      <button className="button next-button"
                              style={{marginLeft: 8}}
                              onClick={() => {
                                handleOnClickCreateFirewall()
                              }}
                      >
                          Create firewall
                      </button>
                  </div>
              </div>
          }
        </div>
      </div>
    </div>
  )
}
export default FirewallCreation;