import './index.scss';
import Icon from 'components/v2/atoms/Icon';
import ClearIcon from 'assets/svgs/v2/ico_input_clear.svg';
import ClearErrorIcon from 'assets/svgs/v2/ico_input_clear_error.svg';
import PwHideIcon from 'assets/svgs/v2/ico_pw_hide.svg';
import PwShowIcon from 'assets/svgs/v2/ico_pw_show.svg';
import classNames from 'classnames';
import { ChangeEvent, useCallback, useMemo, useRef, useState } from 'react';

interface IInputAtomProps {
  className?: string;
  placeholder?: string;
  type?: string;
  id?: string;
  error?: boolean;
  value?: string;
  defaultValue?: string;
  required?: boolean;
  disabled?: boolean;
  noClear?: boolean;
  onChangeValue?: (value: string) => void;
  hasPrefixIcon?: boolean;
  srcPrefixIcon?: string;
  hasSuffixIcon?: boolean;
  srcSuffixIcon?: string;
  prefixIconOnClick?: () => void;
  onKeyDown?: (e: any) => void;
  topDescription?: string;
  bottomDescription?: string;
}
const InputAtom = (props: IInputAtomProps) => {
  const {
    className,
    placeholder = '',
    type = 'text',
    id,
    error = false,
    value,
    defaultValue = '',
    required,
    disabled = false,
    noClear = false,
    onChangeValue,
    hasPrefixIcon = false,
    srcPrefixIcon = '',
    hasSuffixIcon = false,
    srcSuffixIcon = '',
    prefixIconOnClick,
    onKeyDown,
    topDescription,
    bottomDescription,
  } = props;

  const [show, setShow] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChangeValue && onChangeValue(e?.target?.value);
      const siblingClearBtn = e.currentTarget.parentElement?.getElementsByClassName('clear');
      if (siblingClearBtn && siblingClearBtn[0]) {
        if (e.target.value === '') {
          (siblingClearBtn[0] as HTMLButtonElement).classList.remove('show');
        } else {
          (siblingClearBtn[0] as HTMLButtonElement).classList.add('show');
        }
      }
    },
    [onChangeValue],
  );

  const inputNode = useMemo(() => {
    if (topDescription || bottomDescription) {
      return (
        <td className="extented-input-container">
          {!!topDescription && <p>{topDescription}</p>}
          <input
            ref={inputRef}
            placeholder={placeholder}
            type={show ? 'text' : type}
            className={classNames(className, error && 'error')}
            {...(id && { id: id })}
            {...(required && { required: required })}
            value={value}
            disabled={disabled}
            onChange={onChange}
            style={{
              paddingRight: type === 'password' ? '87px' : '51px',
              paddingLeft: hasPrefixIcon === true ? '40px' : '17px',
            }}
            onKeyDown={e => onKeyDown && onKeyDown(e)}
          />
          {!!bottomDescription && <p>{bottomDescription}</p>}
        </td>
      );
    }

    return (
      <input
        ref={inputRef}
        placeholder={placeholder}
        type={show ? 'text' : type}
        className={classNames(className, error && 'error')}
        {...(id && { id: id })}
        {...(required && { required: required })}
        value={value}
        disabled={disabled}
        onChange={onChange}
        style={{
          paddingRight: type === 'password' ? '87px' : '51px',
          paddingLeft: hasPrefixIcon === true ? '40px' : '17px',
        }}
        onKeyDown={e => onKeyDown && onKeyDown(e)}
      />
    );
  }, [
    placeholder,
    required,
    show,
    className,
    error,
    id,
    value,
    disabled,
    onChange,
    type,
    hasPrefixIcon,
    onKeyDown,
    topDescription,
    bottomDescription,
  ]);

  return (
    <div className="input-atom">
      {hasPrefixIcon && (
        <Icon className="prefix-btn" onClick={prefixIconOnClick} width={16} height={16} src={srcPrefixIcon} />
      )}
      {inputNode}
      {hasSuffixIcon && (
        <Icon className="suffix-btn" onClick={prefixIconOnClick} width={16} height={16} src={srcSuffixIcon} />
      )}
      <span className="btn-group">
        {type === 'password' &&
          (show ? (
            <img src={PwShowIcon} width={26} height={26} className="eye" onClick={() => setShow(prev => !prev)} />
          ) : (
            <img src={PwHideIcon} width={26} height={26} className="eye" onClick={() => setShow(prev => !prev)} />
          ))}
        {!disabled && !noClear && (value !== '' || defaultValue !== '') && (
          <img
            src={error ? ClearErrorIcon : ClearIcon}
            width={24}
            height={24}
            className="clear show"
            onClick={e => {
              const siblingInput = e.currentTarget.parentElement?.parentElement?.getElementsByTagName('input');
              if (siblingInput && siblingInput[0]) {
                (siblingInput[0] as HTMLInputElement).value = '';
                e.currentTarget.classList.remove('show');
                onChangeValue && onChangeValue('');
                inputRef.current?.focus();
              }
            }}
          />
        )}
      </span>
    </div>
  );
};
export default InputAtom;
