import { gql } from '@apollo/client';

const query = gql`
query getAwsAvailabilityZones($request: AwsDescribeAvailabilityZonesRequest, $cloudId: Long, $region: String) {
  getAwsAvailabilityZones(describeAvailabilityZonesRequest: $request, cloudId: $cloudId, region: $region) {
    result
     data{
      ... on AwsDescribeAvailabilityZonesResponse {
        availabilityZones{
          state
          optInStatus
          messages{
            message
          }
          regionName
          zoneName
          zoneId
          groupName
          networkBorderGroup
          zoneType
          parentZoneName
          parentZoneName
          parentZoneId
        }
          }
        }
      }
    }

`;
export default query;