import PageDetailTitle from "pages/v2/Organ/Management/EC2/components/PageDetailTitle";
import {REQUEST_TYPE} from "pages/v2/Organ/Management/EC2/components/SpotRequest/Common/Constant";
import Fleet from "pages/v2/Organ/Management/EC2/components/SpotRequest/Detail/Fleet";
import Instance from "pages/v2/Organ/Management/EC2/components/SpotRequest/Detail/Instance";
import { SpotRequestDetailPropsType } from '../types';
import { AwsSpotFleetRequestConfig, AwsSpotInstanceRequest } from 'graphql/types/AwsDescribeSpotInstanceFleetRequestsCombinedResponse';

const SpotRequestDetail = (props: SpotRequestDetailPropsType) => {
  const {currentSpotInstance, pageBackClick, cloudId, region} = props;

  return (
    <div className='ec2-detail'>
      <PageDetailTitle
        title={currentSpotInstance?.requestId}
        pageBackClick={pageBackClick}
        isDelete
        onDelete={() => {}}
      />

      <>
        {
          currentSpotInstance ? (
            currentSpotInstance?.requestType === REQUEST_TYPE.INSTANCE ? (
              <Instance currentSpotInstance={currentSpotInstance.responseData as AwsSpotInstanceRequest} />
            ) : (
              <Fleet
              cloudId={cloudId}
              region={region}
              currentSpotFleetInstance={currentSpotInstance.responseData as AwsSpotFleetRequestConfig}
            />
            )
          ) : null
        }
      </>
    </div>
  );
}

export default SpotRequestDetail;