import { gql } from '@apollo/client';

const query = gql`
  query getAwsListUsers($request: AwsListUsersRequest, $cloudId: Long, $region: String) {
    getAwsListUsers(listUsersRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsListUsersResponse {
          users {
            path
            userName
            userId
            arn
            createDate
            passwordLastUsed
            permissionsBoundary {
              permissionsBoundaryType
              permissionsBoundaryArn
            }
            tags {
              key
              value
            }
          }
          isTruncated
          marker
        }
      }
    }
  }
`;
export default query;
