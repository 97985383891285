import { gql } from '@apollo/client';

const query = gql`
query getAwsAllVPC($request: AwsDescribeVpcsRequest, $cloudId: Long, $region: String) {
  getAwsAllVPC(describeVpcRequest: $request, cloudId: $cloudId, region: $region) {
    result
    nextToken
    data {
      ... on AwsVpc {
        cidrBlock
        dhcpOptionsId
        state
        instanceTenancy
        ipv6CidrBlockAssociationSet {
          associationId
          ipv6CidrBlock
          ipv6CidrBlockState {
            state
            statusMessage
          }
          networkBorderGroup
          ipv6Pool
        }
        cidrBlockAssociationSet {
          associationId
          cidrBlock
          cidrBlockState {
            state
            statusMessage
          }
        }
        isDefault
        vpcId
        ownerId
        tags {
          key
          value
        }
      }
    }
  }
}`;
export default query;