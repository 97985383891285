import { gql } from '@apollo/client';

const query = gql`
  mutation updateAwsIPSet($request: AwsUpdateIpSetRequest, $cloudId: Long, $region: String) {
    updateAwsIPSet(updateIpSetRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsUpdateIpSetResponse {
          nextLockToken
        }
      }
    }
  }
`;
export default query;
