import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsDescribeImageAttributeResponse } from 'graphql/types/AwsDescribeImageAttribute';

export interface IAwsDescribeImageAttributeVariables {
  cloudId: number;
  region: string;
  request: {
    attribute: string;
    imageId: string;
    dryRun?: boolean;
  }
}

export interface IAwsAwsDescribeImageAttributeResponseData {
  getAwsDescribeImageAttribute: IGqlResponseNewData<AwsDescribeImageAttributeResponse>;
}

const lazyGetAwsDescribeImageAttribute = () => useLazyQuery<IAwsAwsDescribeImageAttributeResponseData, IAwsDescribeImageAttributeVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsDescribeImageAttribute;