import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsDescribeTargetGroupAttributesResponse } from 'graphql/types/AwsDescribeTargetGroupAttributesResponse';

export interface IGetAwsDescribeTargetGroupAttributesVariables {
  cloudId: number;
  region: string;
  request: {
    targetGroupArn: string;
  };
}

export interface IAwsDescribeTargetGroupAttributesResponseData {
  getAwsDescribeTargetGroupAttributes: IGqlResponseNewData<AwsDescribeTargetGroupAttributesResponse>;
}

const lazyGetAwsDescribeTargetGroupAttributes = () =>
  useLazyQuery<IAwsDescribeTargetGroupAttributesResponseData, IGetAwsDescribeTargetGroupAttributesVariables>(query, {
    fetchPolicy: 'no-cache',
  });
export default lazyGetAwsDescribeTargetGroupAttributes;
