import Icon from 'components/v2/atoms/Icon';
import CopyIcon from 'assets/svgs/v3/ico_copy.svg';
import React, { useState } from 'react';
import './index.scss';

interface ICopyableTextProps {
  value?: string;
  text: string;
  onTextClicked?: () => void;
}

const CopyableText = ({ value, text, onTextClicked }: ICopyableTextProps) => {
  const [statusTooltip, setStatusTooltip] = useState<string>('Copy to clipboard');

  const copyToClipBoard = () => {
    navigator.clipboard.writeText(value || text).then(
      function () {
        setStatusTooltip('Copied');
        setTimeout(() => {
          setStatusTooltip('Copy to clipboard');
        }, 2000);
      },
      function (err) {
        console.error('Async: Could not copy text: ', err);
      },
    );
  };

  if (onTextClicked) {
    return (
      <div className='copyable-text-with-link tooltip'>
        <div className="flex value cus-break-word-parent">
          <div className='link-icon-container'>
            <Icon width={20} height={14} src={CopyIcon} onClick={copyToClipBoard} />
            <span className="tooltiptext">{statusTooltip}</span>
          </div>
          <p onClick={onTextClicked} className="cus-break-word-child link">
            {text}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="copyable-text tooltip" onClick={copyToClipBoard}>
      <div className="flex value cus-break-word-parent">
        <Icon width={20} height={14} src={CopyIcon} onClick={() => {}} />
        <p className="cus-break-word-child">{text}</p>
      </div>
      <span className="tooltiptext">{statusTooltip}</span>
    </div>
  );
};

export default CopyableText;
