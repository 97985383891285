import { gql } from '@apollo/client';

const query = gql`
query getCloudInfoPageByParam($reqData: ReqGetCloudInfoPage) {
  getCloudInfoPageByParam(reqData: $reqData) {
    result
    number
    size
    first
    last
    numberOfElements
    totalPages
    totalElements
    content {
      ... on ProjectCloud {
        id
        projectId
    		projectName
        rootAccount
        name
        cloudId
        attrKey1
        attrValue1
        attrKey2
        attrValue2
        msspAttrKey
        msspAttrValue
        serviceType
        regionCnt
        modifiedByName
        modifiedAt
      }
    }
  }
}
`;
export default query;