import {DropdownListDataType} from "components/v2/atoms/DropdownAtom";

export const networkInterfaceFilterDropdown: DropdownListDataType[] = [
  {id: 1, name: 'Network interface ID', value: 'network-interface-id'},
  {id: 2, name: 'VPC ID', value: 'vpc-id'},
  {id: 3, name: 'Subnet ID', value: 'subnet-id'},
  {id: 4, name: 'Allocation ID', value: 'allocation-id'},
  {id: 4, name: 'Association ID', value: 'association-id'},
  {id: 4, name: 'Attachment ID', value: 'attachment-id'},
  {id: 4, name: 'Instance ID', value: 'instance-id'},
  {id: 4, name: 'Public IPv4 address', value: 'public-ip'},
]

export const networkInterfaceTab = [
  { id: 'details', name: 'Details', title: 'Details'},
  { id: 'tags', name: 'Tags', title: 'Tags' },
];
