import React from 'react';
import { RadioButtonGroupType } from './types';
import RadioAtom from 'components/v2/atoms/RadioAtom';

const RadioButtonGroup = (props: RadioButtonGroupType) => {
  const { data, value, onChangeValue } = props;

  return (
    <div id="radio-button-group">
      {data.map( radioButton=> (
        <RadioAtom
          value={value}
          name={radioButton.name}
          key={radioButton.id}
          label={radioButton.label}
          checked={radioButton.value}
          onChange={() => onChangeValue(radioButton.value)}
        />
      ))}
    </div>
  );
};

export default RadioButtonGroup;
