import { useState, useEffect } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import IconEditRequest from 'assets/svgs/v3/ico_edit_vpc_subnets.svg';
import Icon from 'components/v2/atoms/Icon';
import './index.scss';
import RadioAtom from 'components/v2/atoms/RadioAtom';
import InputAtom from 'components/v2/atoms/InputAtom';
import Table from 'components/v2/dataDisplay/Table';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import { IMgdTablePaginationProps } from 'layouts/v3/MgdLayout';
import SearchIcon from 'assets/svgs/v3/ico_search.svg';

interface IEditSampledRequestsModalProps extends IBaseModalProps {
  header: string;
  columns: ColumnType[];
  rows: RowType[];
  onChangeValue?: (index: number, value: string) => void;
}

const EditSampledRequestsModal = ({
  header,
  columns,
  rows,
  onChangeValue,
  ...baseModalProps
}: IEditSampledRequestsModalProps) => {
  const [payload, setPayload] = useState<string>('');
  const [mainTablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 10,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  useEffect(() => {
    if (baseModalProps.open) {
      setPayload('');
    }
  }, [baseModalProps.open]);

  const [requestSamplingOptionValues, setRequestSamplingOptionValues] = useState<string>('enable');
  const [aclDefaultActionValue, setAclDefaultActionValue] = useState<string>('enable');
  const [rulesInputValue, setRulesInputValue] = useState<string>('');

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={IconEditRequest} />
          {header}
        </>
      )}
      {...baseModalProps}
    >
      <div className="edit-sampled-request-modal">
        <div className="edit-sampled-request">
          <div className="row-1">
            <div className="header">
              <div className="text-title">Request sampling options</div>
              <div className="text-description">
                If you disable request samples, you can’t vie requests that match your web ACL rules.
              </div>
            </div>
            <p className="text-note">Options</p>
            <div className="radio-content">
              <RadioAtom
                label={'Enable sample requests'}
                value={'enable'}
                name={''}
                checked={requestSamplingOptionValues}
                onChange={() => {
                  setRequestSamplingOptionValues('enable');
                }}
              />

              <RadioAtom
                label={'Disable sample requests'}
                value={'disable'}
                name={''}
                checked={requestSamplingOptionValues}
                onChange={() => {
                  setRequestSamplingOptionValues('disable');
                }}
              />

              <RadioAtom
                label={'Enable sample requests with exclusions'}
                value={'enableWithExclutions'}
                name={''}
                checked={requestSamplingOptionValues}
                onChange={() => {
                  setRequestSamplingOptionValues('enableWithExclutions');
                }}
              />
            </div>
          </div>
          <div className="row-2">
            <div className="header">
              <div className="text-title">Rules</div>
              <div className="text-description">Choose the rules that you want to disable request sampling for</div>
              <InputAtom
                placeholder={'Find rules'}
                className={'input-add'}
                onChangeValue={val => {
                  setRulesInputValue(val);
                }}
                value={rulesInputValue}
                required={true}
                noClear={true}
                hasSuffixIcon={true}
                srcSuffixIcon={SearchIcon}
              />
            </div>

            <div className="table-container">
              <Table
                rows={rows}
                columns={columns}
                className={'table-edit-requests'}
                reportCheckedList={(list: string[]) => {}}
                reportSelected={(id: string) => {}}
                sortOption={{
                  target: mainTablePagination.target,
                  direction: mainTablePagination.direction,
                  onChangeSort: () => {},
                }}
                horizontalScrollable={true}
              />
            </div>
          </div>
          <div className="row-3">
            <div className="header">
              <div className="text-title">Request sampling for web ACL default action</div>
              <div className="text-description">Enable or disable request samplign for the web ACL default action.</div>
            </div>
            <p className="text-note">Request sampling forweb ACL default action</p>
            <RadioAtom
              label={'Enable'}
              value={'enable'}
              name={''}
              checked={aclDefaultActionValue}
              onChange={() => {
                setAclDefaultActionValue('enable');
              }}
            />

            <RadioAtom
              label={'Disable'}
              value={'disable'}
              name={''}
              checked={aclDefaultActionValue}
              onChange={() => {
                setAclDefaultActionValue('disable');
              }}
            />
          </div>
        </div>
        <div className="button-group">
          <button onClick={baseModalProps.onClose}>Cancel</button>
          <button className="delete-btn" onClick={() => {}}>
            Save
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default EditSampledRequestsModal;
