import { gql } from '@apollo/client';

const query = gql`
query getAwsSecurityGroupRules($request: AwsDescribeSecurityGroupRulesRequest, $cloudId: Long, $region: String) {
  getAwsSecurityGroupRules(
    describeSecurityGroupRulesRequest: $request
    cloudId: $cloudId
    region: $region
  ) {
    result
    data {
      ... on AwsDescribeSecurityGroupRulesResponse {
        securityGroupRules {
          securityGroupRuleId
          groupId
          groupOwnerId
          isEgress
          ipProtocol
          fromPort
          toPort
          cidrIpv4
          cidrIpv6
          prefixListId
          referencedGroupInfo {
            groupId
            peeringStatus
            userId
            vpcId
            vpcPeeringConnectionId
          }
          description
          tags {
            key
            value
          }
        }
        nextToken
      }
    }
  }
}`;
export default query;