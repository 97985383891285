import { gql } from '@apollo/client';

const query = gql`
mutation deleteRuleGroup($reqData: AwsDeleteRuleGroupRequest, $cloudId: Long, $region: String) {
  deleteRuleGroup(deleteRuleGroupRequest: $reqData, cloudId: $cloudId, region: $region) {
    result
    data {
      __typename
      }
    }
  }
`;
export default query;