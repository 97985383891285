import { gql } from '@apollo/client';

const query = gql`
query getAwsDescribeSpotFleetInstances($request: AwsDescribeSpotFleetInstancesRequest, $cloudId: Long, $region: String) {
  getAwsDescribeSpotFleetInstances(describeSpotFleetInstancesRequest: $request, cloudId: $cloudId, region: $region) {
    result
    data {
      ... on AwsDescribeSpotFleetInstancesResponse {
        activeInstances {
          instanceId
          instanceType
          spotInstanceRequestId
          instanceHealth
        }
        nextToken
        spotFleetRequestId
      }
    }
    }
  }
`;
export default query;
