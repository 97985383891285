import { gql } from '@apollo/client';

const query = gql`
  query getAwsGroupIAM($request: AwsGetGroupIAMRequest, $cloudId: Long, $region: String) {
    getAwsGroupIAM(getGroupIAMRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsGetGroupIAMResponse {
          group {
            path
            groupId
            groupName
            arn
            createDate
          }
          users {
            path
            userName
            userId
            arn
            createDate
            passwordLastUsed
            permissionsBoundary {
              permissionsBoundaryType
              permissionsBoundaryArn
            }
            tags {
              key
              value
            }
          }
          isTruncated
          marker
        }
      }
    }
  }
`;
export default query;
