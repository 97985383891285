import {IMgdDetailKeyValueProps} from "layouts/v3/MgdLayout";
import DetailTab from "../../../DetailTab";
import { getFormatedDateWithTimezone } from "utils/Common";

import './style.scss'

function Summary({data}: any) {
  const {
    group,
  } = data || {}

  const {
    groupName,
    createDate,
    arn
  } = group || {}

  const summary: IMgdDetailKeyValueProps[] = [
    {
      title: 'User group name',
      description: groupName,
    },
    {
      title: 'Creation time',
      description: getFormatedDateWithTimezone(createDate, 'MMM DD, YYYY HH:MM'),
    },
    {
      title: 'ARN',
      description: arn,
    },
  ]

  return (
    <div className='user-group-details-tab'>
      <DetailTab title='Summary' data={summary} />
    </div>
  );
}

export default Summary;