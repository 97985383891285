import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import InputAtom from 'components/v2/atoms/InputAtom';
import SearchIcon from 'assets/svgs/v3/ico_search.svg';
import { variableCombineNextToken } from 'pages/v2/Organ/Management/Utils';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import Table from 'components/v2/dataDisplay/Table';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdLayout';
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';
import {
  REQUEST_TYPE,
  spotRequestFilterDropdown,
} from 'pages/v2/Organ/Management/EC2/components/SpotRequest/Common/Constant';
import { FilterPropsType } from 'pages/v2/Organ/Management/EC2/components/Image/types';
import { getBetweenTwoDate, handleFormatText } from 'utils/Common';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import { SpotRequestPropsType } from '../../types';
import lazyGetAwsDescribeSpotInstanceFleetRequestsCombined, {
  IAwsDescribeSpotInstanceFleetRequestsCombinedVariables,
} from 'graphql/queries/getAwsDescribeSpotInstanceFleetRequestsCombined';
import { SpotInstanceRowType } from '../types';

const SpotRequest = (props: SpotRequestPropsType) => {
  const { cloudId, region, onSpotInstanceSelected, onSpotInstanceIdClicked } = props;

  const [getAwsDescribeSpotInstanceFleetRequestsCombined, { loading: awsDescribeInstanceLoading }] =
    lazyGetAwsDescribeSpotInstanceFleetRequestsCombined();

  const [nextToken, setNextToken] = useState<string>('');
  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [mainTblRows, setMainTblRows] = useState<RowType[]>([]);
  const [mainTablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });
  const [mainFilterValue, setMainFilterValue] = useState<DropdownListDataType>(spotRequestFilterDropdown[0]);
  const [searchValue, setSearchValue] = useState<FilterPropsType>({
    name: mainFilterValue.value as string,
    value: '',
  });
  const [scopeNFilterVariable, setScopeNFilterVariable] = useState<FilterPropsType>();

  const columns = useMemo((): ColumnType[] => {
    return [
      {
        label: 'Request ID',
        field: 'requestId',
        sort: true,
        renderCell: (row: RowType) => (
          <span
            className="link"
            onClick={e => {
              e.stopPropagation();
              const rowSelected = mainTblRows.find(item => item.requestId === row?.requestId);
              if (!rowSelected) return;
              onSpotInstanceSelected(rowSelected as SpotInstanceRowType);
              onSpotInstanceIdClicked();
            }}
          >
            {handleFormatText(row?.requestId)}
          </span>
        ),
      },
      {
        label: 'Request type',
        field: 'requestType',
        sort: true,
      },
      {
        label: 'Instance type',
        field: 'instance',
        sort: true,
        renderCell: (row: RowType) =>
          row?.instance?.map((e: string, idx: number) => <div key={`spot-request-instance-type-${idx}`}>{handleFormatText(e)}</div>),
      },
      {
        label: 'State',
        field: 'state',
        sort: true,
      },
      {
        label: 'Capacity',
        field: 'capacity',
        sort: true,
        renderCell: (row: RowType) =>
          row?.requestType === REQUEST_TYPE.INSTANCE ? (
            <div
              className="link"
              onClick={e => {
                e.stopPropagation();
              }}
            >
              {handleFormatText(row?.capacity)}
            </div>
          ) : (
            <div>{handleFormatText(row?.capacity)}</div>
          ),
      },
      {
        label: 'Status',
        field: 'status',
        sort: true,
      },
      {
        label: 'Persistence',
        field: 'persistence',
        sort: true,
      },
      {
        label: 'Created',
        field: 'createTime',
        sort: true,
      },
    ];
  }, [mainTblRows]);

  const fetchSpotInstances = useCallback(
    (nextToken?: string, isInitial?: boolean) => {
      const requestVariable: IAwsDescribeSpotInstanceFleetRequestsCombinedVariables = {
        cloudId: cloudId,
        region: region,
        request: {
          maxResults: mainTablePagination.limit,
          awsDescribeSpotInstanceRequestsRequest: {},
          awsDescribeSpotFleetRequestsRequest: {},
        },
      };
      const combinedVariable = variableCombineNextToken(requestVariable, nextToken);
      getAwsDescribeSpotInstanceFleetRequestsCombined({ variables: combinedVariable }).then(
        ({ data: spotInstancesResponse }) => {
          const spotInstances = spotInstancesResponse?.getAwsDescribeSpotInstanceFleetRequestsCombined?.data?.[0];
          if (spotInstances) {
            const awsSpotInstanceFleetRequeset =
              spotInstances?.awsDescribeSpotFleetRequestsResponse?.spotFleetRequestConfigs;
            const awsSpotInstanceRequest = spotInstances?.awsDescribeSpotInstanceRequestsResponse?.spotInstanceRequests;
            const spotInstanceRows: SpotInstanceRowType[] = [];
            setNextToken(spotInstances?.awsDescribeSpotInstanceRequestsResponse?.nextToken);
            if (awsSpotInstanceFleetRequeset) {
              awsSpotInstanceFleetRequeset.map(spotInstance => {
                spotInstanceRows.push({
                  id: spotInstance.spotFleetRequestId,
                  requestId: spotInstance.spotFleetRequestId,
                  requestType: REQUEST_TYPE.FLEET,
                  instance: spotInstance.spotFleetRequestConfig?.launchSpecifications?.length
                    ? spotInstance.spotFleetRequestConfig.launchSpecifications.map(
                        ({ instanceType }: { instanceType: string }) => instanceType || '...',
                      )
                    : ['...'],
                  state: spotInstance.spotFleetRequestState,
                  capacity: `${spotInstance.spotFleetRequestConfig?.onDemandTargetCapacity} of ${spotInstance.spotFleetRequestConfig?.targetCapacity}`,
                  status: spotInstance.activityStatus,
                  persistence: spotInstance.spotFleetRequestConfig?.type || '...',
                  createTime: getBetweenTwoDate(spotInstance.createTime),
                  responseData: spotInstance,
                });
              });
            }
            if (awsSpotInstanceRequest) {
              awsSpotInstanceRequest.map(spotInstance => {
                spotInstanceRows.push({
                  id: spotInstance.spotInstanceRequestId,
                  requestId: spotInstance.spotInstanceRequestId,
                  requestType: REQUEST_TYPE.INSTANCE,
                  instance: spotInstance.launchSpecification?.instanceType
                    ? [spotInstance.launchSpecification?.instanceType]
                    : ['...'],
                  state: spotInstance.state,
                  capacity: spotInstance.instanceId,
                  status: spotInstance.status?.code,
                  persistence: spotInstance.type || '...',
                  createTime: getBetweenTwoDate(spotInstance.createTime),
                  responseData: spotInstance,
                });
              });
            }

            let totalSpotInstances: RowType[] = [];
            if (isInitial) {
              totalSpotInstances = spotInstanceRows;
            } else {
              totalSpotInstances = [...mainTblRows, ...spotInstanceRows];
            }

            setMainTblRows(totalSpotInstances);
            setMainTblTotal({
              totalPage: Math.ceil(totalSpotInstances.length / mainTablePagination.itemPerPage),
              totalElement: totalSpotInstances.length,
            });
          }
        },
      );
    },
    [mainTablePagination, cloudId, region, mainTblRows],
  );

  const mainRowsCurrentPage = useMemo((): RowType[] => {
    if (mainTablePagination.currentPage > mainTblTotal.totalPage && !!nextToken) {
      fetchSpotInstances(nextToken);
    }
    const startIndex = (mainTablePagination.currentPage - 1) * mainTablePagination.itemPerPage;
    const endIndex = startIndex + mainTablePagination.itemPerPage;

    let totalRows = mainTblRows;
    if (scopeNFilterVariable && scopeNFilterVariable?.value !== '' && scopeNFilterVariable?.name !== '') {
      totalRows = mainTblRows.filter(row => {
        if (scopeNFilterVariable.name === spotRequestFilterDropdown[2].value) {
          return row[scopeNFilterVariable.name].includes(scopeNFilterVariable.value);
        }
        return row[scopeNFilterVariable.name] === scopeNFilterVariable.value;
      });
    }

    setMainTblTotal({
      totalPage: Math.ceil(totalRows.length / mainTablePagination.itemPerPage),
      totalElement: totalRows.length,
    });

    return orderAlphabetical(totalRows, mainTablePagination.target, mainTablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [mainTblRows, scopeNFilterVariable, mainTablePagination, nextToken]);

  useEffect(() => {
    fetchSpotInstances('', true);
  }, [cloudId]);

  const handleSearch = useCallback(() => {
    updateTablePagination('currentPage', 1);

    if (searchValue?.value) {
      setScopeNFilterVariable(searchValue);
      return;
    }

    setScopeNFilterVariable(undefined);
  }, [searchValue]);

  const handleSelectItem = useCallback(
    (id: string) => {
      const rowSelected = mainTblRows.find(row => row.id === id);

      if (!rowSelected) return;

      onSpotInstanceSelected(rowSelected as SpotInstanceRowType);
    },
    [mainTblRows],
  );

  const updateTablePagination = (key: string, value: number | string | OrderDirection) => {
    setMainTablePagination(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <Fragment>
      <div className="row-3 flex j-between a-center">
        <div className="title">
          <p>Spot requests</p>
        </div>

        <div className="flex action a-center">
          <DropdownAtom
            id="types-dropdown"
            className=""
            data={spotRequestFilterDropdown}
            value={mainFilterValue}
            handleClick={val => {
              setMainFilterValue(val);
              setSearchValue(prev => ({ ...prev, name: val.value as string }));
            }}
          />

          <InputAtom
            type={'text'}
            value={searchValue.value}
            defaultValue={''}
            onChangeValue={e => setSearchValue(prev => ({ name: prev?.name, value: e }))}
            hasPrefixIcon={true}
            srcPrefixIcon={SearchIcon}
            prefixIconOnClick={() => {}}
          />

          <button className="action-btn" onClick={handleSearch}>
            Search
          </button>
        </div>
      </div>

      {!mainRowsCurrentPage.length && !awsDescribeInstanceLoading ? (
        <div className="data-grid-wrap">
          <p className="empty-row">Empty</p>
        </div>
      ) : (
        <div className="data-grid-wrap">
          <Table
            rows={mainRowsCurrentPage}
            columns={columns}
            reportCheckedList={() => {}}
            reportSelected={id => handleSelectItem(id)}
            sortOption={{
              target: mainTablePagination.target,
              direction: mainTablePagination.direction,
              onChangeSort: (target: string, dir: OrderDirection) => {
                updateTablePagination('target', target);
                updateTablePagination('direction', dir);
              },
            }}
            isLoading={awsDescribeInstanceLoading}
            horizontalScrollable={true}
          />

          {mainRowsCurrentPage && mainRowsCurrentPage.length > 0 && !awsDescribeInstanceLoading && (
            <div className="pagination-wrapper flex a-center">
              <p className="flex a-center">
                Total <span>{mainTblTotal.totalElement}</span>
              </p>
              <TableManagePagination
                ableFetchMore={!!nextToken}
                currentPage={mainTablePagination.currentPage}
                updateCurrentPage={page =>
                  setMainTablePagination(prev => ({
                    ...prev,
                    ['currentPage']: page,
                  }))
                }
                totalPage={mainTblTotal.totalPage}
              />
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default SpotRequest;
