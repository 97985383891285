import React from 'react';

type JsonViewerProps = {
  data: any;
  title?: string;
  isJsonOnly?: boolean;
};

const JsonViewer = (props: JsonViewerProps) => {
  const { data, title, isJsonOnly } = props;

  if (isJsonOnly) {
    return (
      <div className="detail-block-container">
        <div className="content-container-json-viewer">
          <pre>{JSON.stringify(data, null, 4)}</pre>
        </div>
      </div>
    );
  }

  return (
    <div className="detail-block-container">
      <div className="header-container">
        <div className="group-title">
          <div className="title">{title}</div>
        </div>
      </div>
      <div className="content-container-json-viewer">
        <pre>{JSON.stringify(data, null, 4)}</pre>
      </div>
    </div>
  );
};

export default JsonViewer;
