import { gql } from '@apollo/client';

const query = gql`
query getAwsAllDescribeSnapshots($request: AwsDescribeSnapshotsRequest, $cloudId: Long, $region: String) {
  getAwsAllDescribeSnapshots(describeSnapshotsRequest: $request, cloudId: $cloudId, region: $region) {
    result
    nextToken
     data{
      ... on AwsSnapshot {
        dataEncryptionKeyId
        description
        encrypted
        kmsKeyId
        ownerId
        progress
        snapshotId
        startTime
        state
        stateMessage
        volumeId
        volumeSize
        ownerAlias
        outpostArn
        tags {
          key
        	value
        }
        storageTier
        restoreExpiryTime
        sseType
      }
        }
        }
      }
  `;
export default query;