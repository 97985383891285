import { gql } from '@apollo/client';

const query = gql`
  query getAwsListGroupsForUser($request: AwsListGroupsForUserRequest, $cloudId: Long, $region: String) {
    getAwsListGroupsForUser(listGroupsForUserRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsListGroupsForUserResponse {
          groups {
            path
            groupName
            groupId
            arn
            createDate
          }
          isTruncated
          marker
        }
      }
    }
  }
`;
export default query;
