const EVALUATION_WINDOW = [
  {
    value: 60,
    name: '1 minute (60seconds)',
  },
  {
    value: 120,
    name: '2 minutes (120 seconds)',
  },
  {
    value: 300,
    name: '5 minutes (300 seconds)',
  },
  {
    value: 600,
    name: '10 minutes (600 seconds)',
  },
];

const REQUEST_AGGREGATION = [
  {
    id: 'Source IP address',
    value: 'IP',
    name: 'Source IP address',
    label: 'Source IP address',
    description:
      'Use only the IP address from the web request origin. If a web request goes through one or more proxies to load balancers, this will contain the address of the last proxy, and not the originating address of the client.',
  },
  {
    id: 'IP address in header',
    value: 'FORWARDED_IP',
    name: 'IP address in header',
    label: 'IP address in header',
    description:
      'Use only a client address in an HTTP header. Use caution with this option, as headers can be handled inconsistently by proxies and they can be modified to bypass inspection.',
  },
  {
    id: 'Custom keys',
    value: 'CUSTOM_KEYS',
    name: 'Custom keys',
    label: 'Custom keys',
    description:
      'Create an aggregation key using one or more component types, selected from a list. You can combine an IP address option with other components and you can create keys that don’t use an IP address.',
  },
  {
    id: 'Count all',
    value: 'CONSTANT',
    name: 'Count all',
    label: 'Count all',
    description: 'Count and rate limit all requests that match the rule’s scope-down statement.',
  },
];

const INSPECTION_AND_RATE_LIMIT = [
  {
    id: 'Consider all requests',
    value: 'Consider all requests',
    name: 'Consider all requests',
    label: 'Consider all requests',
  },
  {
    id: 'Only consider requests that match the criteria in a rule statementr',
    value: 'Only consider requests that match the criteria in a rule statement',
    name: 'Only consider requests that match the criteria in a rule statement',
    label: 'Only consider requests that match the criteria in a rule statement',
  },
];

const REQUEST_AGGREGATION_KEY = [
  {
    value: 'Request components',
    name: 'Request components',
    children: [
      {
        value: 'Header',
        name: 'Header',
      },
      {
        value: 'Query string',
        name: 'Query string',
      },
      {
        value: 'Query argument',
        name: 'Query argument',
      },
      {
        value: 'Cookie',
        name: 'Cookie',
      },
      {
        value: 'Label namespace',
        name: 'Label namespace',
      },
      {
        value: 'HTTP method',
        name: 'HTTP method',
      },
      {
        value: 'URI path',
        name: 'URI path',
      },
    ]
  },
  {
    value: 'IP address {must be a secondary key}',
    name: 'IP address {must be a secondary key}',
    children: [
      {
        value: 'Source IP address',
        name: 'Source IP address',
      },
      {
        value: 'IP address in header',
        name: 'IP address in header',
      },
    ]
  },
];

export { EVALUATION_WINDOW, REQUEST_AGGREGATION, INSPECTION_AND_RATE_LIMIT, REQUEST_AGGREGATION_KEY };
