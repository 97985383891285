import { useLazyQuery } from '@apollo/client';
import query from './query';
import { Paging } from 'graphql/types/Paging';
import { AwsAddressType } from 'graphql/types/AwsAddress';
import { IGqlResponseNewData } from 'graphql/types';

export interface IAwsAddresssVariables {
  cloudId: number;
  region: string;
  request: {
    nextToken?: string;
    publicIps?: string[];
    allocationIds?: string[];
    filters?: {
      name: string;
      values: string[];
    };
    dryRun?: boolean;
    pageInfo?: Paging;
  }
}

export interface IAwsDescribeAddressResponseData {
  getAwsAddresses: IGqlResponseNewData<AwsAddressType>;
}

const lazyGetAwsAllAddresses = () => useLazyQuery<IAwsDescribeAddressResponseData, IAwsAddresssVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsAllAddresses;