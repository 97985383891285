import axios, { AxiosRequestConfig } from 'axios';
import { IDefaultRes } from './schema';
import Auth from './Auth';
import SignUp from './SignUp';
import Terms from './Terms';
import User from './User';
import File from './File';
import Memo from './Memo';
import Organ from './Organ';
import Project from './Project';
import Report from './Report';
import Manage from './Manage';
import Member from './Member';
import Cloud from './Cloud';

// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
export type BaseApiConfig<_T> = {
  key: string;
  config: AxiosRequestConfig
}
export type BaseDummyRes<T> = {
  data: IDefaultRes & T
}

const instance = axios.create({
  headers: {
    'Content-Type': 'application/json'
  },
  baseURL: process.env.REACT_APP_NEMO_API
});

const apis = { 
  Auth: Auth(instance),
  SignUp: SignUp(instance), 
  Terms: Terms(instance), 
  User: User(instance),
  File: File(instance),
  Memo: Memo(instance),
  Organ: Organ,
  Project: Project,
  Report: Report,
  Manage: Manage,
  Member: Member(instance),
  Cloud: Cloud(instance)
};

export default apis;