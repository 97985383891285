import { gql } from '@apollo/client';

const query = gql`
query getAwsDescribeTargetHealth($request: AwsDescribeTargetHealthRequest, $cloudId: Long, $region: String) {
  getAwsDescribeTargetHealth(describeTargetHealthRequest: $request, cloudId: $cloudId, region: $region) {
    result
    data {
      ... on AwsDescribeTargetHealthResponse {
        targetHealthDescriptions {
          target{
            id
            port
            availabilityZone
          }
          healthCheckPort
          targetHealth {
            state
            reason
            description
          }
          anomalyDetection {
            result
            mitigationInEffect
          }
        }
        }
      }
    }
  }
`;
export default query;