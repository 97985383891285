import './index.scss';

interface ICheckboxAtomProps {
  id?: string;
  checked?: boolean
  disabled?: boolean
  onchange?: () => void;
}
const CheckboxAtom = ({ id, checked, disabled, onchange }:ICheckboxAtomProps) => {
  return (
    <label className="checkbox-atom" >
      <input type="checkbox" {...(id && { id: id })} checked={checked} onChange={onchange} disabled={disabled}/>
      <span />
    </label>
  );
};
export default CheckboxAtom;