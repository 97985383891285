import { gql } from '@apollo/client';

const query = gql`
query getAwsDescribeCertificate($request: AwsDescribeCertificateRequest, $cloudId: Long, $region: String) {
  getAwsDescribeCertificate(
    describeCertificateRequest: $request
    cloudId: $cloudId
    region: $region
  ) {
    result
    data {
      ... on AwsDescribeCertificateResponse {
        certificate {
          certificateArn
          domainName
          subjectAlternativeNames
          domainValidationOptions {
            domainName
            validationEmails
            validationDomain
            validationStatus
            resourceRecord {
              name
              type
              value
            }
            validationMethod
          }
          serial
          subject
          issuer
          createdAt
          issuedAt
          importedAt
          status
          revokedAt
          revocationReason
          notBefore
          notAfter
          keyAlgorithm
          signatureAlgorithm
          inUseBy
          failureReason
          type
          renewalSummary {
            renewalStatus
            domainValidationOptions {
              domainName
              validationEmails
              validationDomain
              validationStatus
              resourceRecord {
                name
                type
                value
              }
              validationMethod
            }
            renewalStatusReason
            updatedAt
          }
          keyUsages {
            name
          }
          extendedKeyUsages {
            name
            oid
          }
          certificateAuthorityArn
          renewalEligibility
          options {
            certificateTransparencyLoggingPreference
          }
        }
      }
    }
  }
}`;
export default query;