import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdLayout';
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import InputAtom from 'components/v2/atoms/InputAtom';
import SearchIcon from 'assets/svgs/v3/ico_search.svg';
import Table from 'components/v2/dataDisplay/Table';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';
import { keyPairActionDropdown } from 'pages/v2/Organ/Management/EC2/components/KeyPair/Common/Constant';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import lazyGetAwsDescribeKeyPairs, { IAwsDescribeKeyPairsVariables } from 'graphql/queries/getAwsDescribeKeyPairs';
import { KeyRepairPropsType } from '../../types';
import { AwsKeyPairInfo } from 'graphql/types/AwsDescribeKeyPairsResponse';
import { getFormatedDateWithTimezone } from 'pages/v2/Organ/Management/Firewall/Common/Function';

const KeyPair = (props: KeyRepairPropsType) => {
  const { cloudId, region } = props;
  const [getAwsDescribeKeyPairs, { loading: awsDescribeKeyPairLoading }] = lazyGetAwsDescribeKeyPairs();

  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [mainTblRows, setMainTblRows] = useState<RowType[]>([]);
  const [mainTablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });
  const [mainFilterValue, setMainFilterValue] = useState<DropdownListDataType>({
    name: '',
    value: '',
  });
  const [searchValue, setSearchValue] = useState('');
  const [scopeNFilterVariable, setScopeNFilterVariable] = useState<string>('');
  const [currentKeyPair, setCurrentKeyPair] = useState<AwsKeyPairInfo | undefined>(undefined);

  const columns = useMemo((): ColumnType[] => {
    return [
      {
        label: 'Name',
        field: 'keyName',
        sort: true,
      },
      {
        label: 'Type',
        field: 'keyType',
        sort: true,
      },
      {
        label: 'Created',
        field: 'createTime',
        sort: true,
      },
      {
        label: 'Fingerprint',
        field: 'keyFingerprint',
        sort: true,
      },
      {
        label: 'ID',
        field: 'keyPairId',
        sort: true,
      },
    ];
  }, []);

  const keyPairActionOption = keyPairActionDropdown.map(e => ({
    ...e,
    disable: e.value === keyPairActionDropdown[0].value ? false : !currentKeyPair,
  }));

  const mainRowsCurrentPage = useMemo(() => {
    const startIndex = (mainTablePagination.currentPage - 1) * mainTablePagination.itemPerPage;
    const endIndex = startIndex + mainTablePagination.itemPerPage;

    return orderAlphabetical(mainTblRows, mainTablePagination.target, mainTablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [mainTblRows, mainTblTotal, mainTablePagination, searchValue]);

  const fetchAwsDescribeKeyPairs = useCallback(() => {
    const requestVariable: IAwsDescribeKeyPairsVariables = !scopeNFilterVariable ? {
      cloudId: cloudId,
      region: region,
      request: {},
    } : {
      cloudId: cloudId,
      region: region,
      request: {
        filters: [{
          name: 'key-name',
          values: [scopeNFilterVariable]
        }]
      },
    };
    getAwsDescribeKeyPairs({ variables: requestVariable }).then(({ data: awsDescribeKeyPairResponse }) => {
      const awsDescribeKeyPairs = awsDescribeKeyPairResponse?.getAwsDescribeKeyPairs?.data?.[0]?.keyPairs;
      if (awsDescribeKeyPairs) {
        const keypairRows: RowType[] = [];
        awsDescribeKeyPairs.map(keyPair => {
          keypairRows.push({
            id: keyPair?.keyPairId,
            ...keyPair,
            createTime: getFormatedDateWithTimezone(keyPair?.createTime, 'MMM DD, YYYY HH:MM'),
          });
        });
        setMainTblRows(keypairRows);
        setMainTblTotal({
          totalPage: Math.ceil(keypairRows.length / mainTablePagination.itemPerPage),
          totalElement: keypairRows.length,
        });
      }
    });
  }, [cloudId, region, mainTablePagination, scopeNFilterVariable]);

  useEffect(() => {
    fetchAwsDescribeKeyPairs();
  }, [scopeNFilterVariable, cloudId]);

  const handleSearch = useCallback(() => {
    if (searchValue) {
      setScopeNFilterVariable(searchValue);
      return;
    }

    setScopeNFilterVariable('');
  }, [searchValue]);

  const handleSelectItem = (id: string) => {
    const keypair = mainTblRows.find(item => item.id === id);

    if (!keypair) return;

    setCurrentKeyPair(keypair as AwsKeyPairInfo);
  };

  const handleCreateKeyPair = () => {};

  const updateTablePagination = (key: string, value: number | string | OrderDirection) => {
    setMainTablePagination(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <Fragment>
      <div className="row-3 flex j-between a-center">
        <div className="title">
          <p>Keypairs</p>
        </div>

        <div className="flex action a-center">
          <InputAtom
            type={'text'}
            value={searchValue}
            defaultValue={''}
            onChangeValue={e => setSearchValue(e)}
            hasPrefixIcon={true}
            srcPrefixIcon={SearchIcon}
            prefixIconOnClick={() => {}}
          />

          <button className="action-btn" onClick={handleSearch}>
            Search
          </button>

          <button className="action-btn" onClick={handleCreateKeyPair}>
            Create key pair
          </button>

          <DropdownAtom
            id="action-dropdown"
            className=""
            data={keyPairActionOption}
            value={mainFilterValue}
            handleClick={val => {
              if (val?.disable) return;

              setMainFilterValue(val);
            }}
          />
        </div>
      </div>

      {!mainRowsCurrentPage.length && !awsDescribeKeyPairLoading ? (
        <div className="data-grid-wrap">
          <p className="empty-row">Empty</p>
        </div>
      ) : (
        <div className="data-grid-wrap">
          <Table
            rows={mainRowsCurrentPage}
            columns={columns}
            reportCheckedList={() => {}}
            reportSelected={id => handleSelectItem(id)}
            sortOption={{
              target: mainTablePagination.target,
              direction: mainTablePagination.direction,
              onChangeSort: (target: string, dir: OrderDirection) => {
                updateTablePagination('target', target);
                updateTablePagination('direction', dir);
              },
            }}
            isLoading={awsDescribeKeyPairLoading}
            horizontalScrollable={true}
          />

          {mainRowsCurrentPage?.length && !awsDescribeKeyPairLoading ? (
            <div className="pagination-wrapper flex a-center">
              <p className="flex a-center">
                Total <span>{mainTblTotal.totalElement}</span>
              </p>
              <TableManagePagination
                ableFetchMore={false}
                currentPage={mainTablePagination.currentPage}
                updateCurrentPage={page =>
                  setMainTablePagination(prev => ({
                    ...prev,
                    ['currentPage']: page,
                  }))
                }
                totalPage={mainTblTotal.totalPage}
              />
            </div>
          ) : null}
        </div>
      )}
    </Fragment>
  );
};

export default KeyPair;
