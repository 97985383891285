import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsDescribeSpotInstanceFleetRequestsCombinedResponse } from 'graphql/types/AwsDescribeSpotInstanceFleetRequestsCombinedResponse';

export interface IAwsDescribeSpotInstanceFleetRequestsCombinedVariables {
  cloudId: number;
  region: string;
  request: {
    maxResults: number;
    awsDescribeSpotInstanceRequestsRequest: {};
    awsDescribeSpotFleetRequestsRequest: {};
  };
}

export interface IAwsDescribeSpotInstanceFleetRequestsCombinedResponseData {
  getAwsDescribeSpotInstanceFleetRequestsCombined: IGqlResponseNewData<AwsDescribeSpotInstanceFleetRequestsCombinedResponse>;
}

const lazyGetAwsDescribeSpotInstanceFleetRequestsCombined = () =>
  useLazyQuery<
    IAwsDescribeSpotInstanceFleetRequestsCombinedResponseData,
    IAwsDescribeSpotInstanceFleetRequestsCombinedVariables
  >(query, {
    fetchPolicy: 'no-cache',
  });
export default lazyGetAwsDescribeSpotInstanceFleetRequestsCombined;
