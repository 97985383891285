import React from 'react';
import {POSITION_INSIDE_HEADER} from "pages/v2/Organ/Management/WAF/CustomRuleModal/constant";
import RadioAtom from "components/v2/atoms/RadioAtom";

type PositionInsideHeaderPropsType = {
  isHideOption?: boolean;
  positionInsideHeader: string;
  setPositionInsideHeader: (val: string) => void;
};

function PositionInsideHeader({isHideOption, positionInsideHeader, setPositionInsideHeader}: PositionInsideHeaderPropsType) {
  return (
    <div>
      <p className='rule-container-group-content-label'>Position inside header</p>

      <p className='rule-container-group-content-description'>The header can contain IP addresses of the
        original client and of any proxies. For example, the header value could be ’10.1.1.1.1, 127.0.0.0,
        10.10.10.10’ where the first IP address identifies the original client and the rest identify proxies
        that the request went through. Specify the position of the IP address that you want to inspect from
        this list.</p>

      {
        isHideOption ? (
          <p className='rule-container-group-content-note'>Use the first IP address value in the header to inspect against the rule.</p>
        ) : (
          <div className='space-8'>
            {
              POSITION_INSIDE_HEADER.map(({id, label, value, name, description}) => (
                <RadioAtom
                  key={id}
                  label={label}
                  value={value}
                  name={name}
                  description={description}
                  checked={positionInsideHeader}
                  onChange={setPositionInsideHeader}
                />
              ))
            }
          </div>
        )
      }
      
    </div>
  );
}

export default PositionInsideHeader;