import { useEffect, useMemo, useState, useCallback } from 'react';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import Table from 'components/v2/dataDisplay/Table';
import TablePagination from 'components/v2/dataDisplay/TablePagination';
import { AwsWebACL } from 'graphql/types/AwsWebACL';
import EditDefaultWebAclActionModal from '../../../EditDefaultWebAclActionModal';
import EditTokenImmunityTimeModal from '../../../EditTokenImmunityTimeModal';
import TokenDomainListModal, { IDomainRowData } from '../../../TokenDomainListModal';
import DeleteResource from 'pages/v2/Organ/Management/Firewall/DeleteResource';
import EditCloudworkMetricsModal from '../../../EditCloudworkMetricsModal';
import DeleteRuleModal from '../../../RuleGroup/UpdateRuleGroup/DeleteRule';
import updateAwsWebACL from 'graphql/mutations/updateAwsWebACL';
import { AwsUpdateWebAclRequestType } from 'graphql/types/AwsUpdateWebAcl';
import _ from 'lodash';

interface IRuleDetailProps {
  webAcl: AwsWebACL;
  cloudId: number;
  region: string;
  lockToken: string;
  getWebACLs: () => void;
}

const RuleDetail = ({ webAcl, cloudId, region, lockToken, getWebACLs }: IRuleDetailProps) => {
  const [rows, setRows] = useState<RowType[]>([]);
  const [totalRules, setTotalRules] = useState({
    totalPage: 0,
    totalElement: 0,
  });
  const [ruleTablePagination, setRuleTablePagination] = useState({
    limit: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [selected, setSelected] = useState<string>('');
  const [isEditWebAclOpened, setIsEditWebAclOpened] = useState<boolean>(false);
  const [isCapchaTokenOpened, setIsCapchaTokenOpened] = useState<boolean>(false);
  const [isChallengeTokenOpened, setIsChallengeTokenOpened] = useState<boolean>(false);
  const [isTokenDomainOpened, setIsTokenDomainOpened] = useState<boolean>(false);
  const [isDeleteWafOpened, setIsDeleteWafOpened] = useState<boolean>(false);
  const [isDeleteRuleModalVisible, setDeleteRuleModalVisible] = useState<boolean>(false);

  const [editAwsWebACL] = updateAwsWebACL();

  const columns: ColumnType[] = useMemo(() => {
    return [
      {
        label: 'Name',
        field: 'name',
        sort: true,
      },
      {
        label: 'Action',
        field: 'action',
        sort: true,
      },
      {
        label: 'Priority',
        field: 'priority',
        sort: true,
      },
      {
        label: 'Custom response',
        field: 'customResponse',
        sort: true,
      },
    ];
  }, []);

  useEffect(() => {
    let rules: any[] = [];
    webAcl.rules.map(rule => {
      rules.push({
        ...rule,
        id: rule.name,
      });
    });
    setRows(rules);
    setTotalRules({
      totalElement: webAcl.rules.length,
      totalPage: Math.ceil(webAcl.rules.length / ruleTablePagination.limit),
    });
  }, [webAcl.rules]);

  const onAddRulesButtonClick = useCallback(() => {}, []);

  const onEditRuleButtonClick = useCallback(() => {}, []);

  const onDeleteRuleButtonClick = useCallback(() => {
    setDeleteRuleModalVisible(true);
  }, []);

  const getEditAwsWebACLVariables = useCallback(
    (webACL: any) => {
      return {
        cloudId,
        region: region !== 'CloudFront' ? String(region) : 'us-east-1',
        request: {
          scope: region !== 'CloudFront' ? 'REGIONAL' : 'CLOUDFRONT',
          ...webACL,
        },
      };
    },
    [cloudId, region, lockToken],
  );

  const handleEditCaptchaTokenImmunityTime = useCallback(
    (value: number) => {
      if (value === webAcl?.captchaConfig?.immunityTimeProperty?.immunityTime) {
        return;
      }

      const updatedWebAcl = {
        ..._.omit(webAcl, [
          'arn',
          'capacity',
          'labelNamespace',
          'managedByFirewallManager',
          'preProcessFirewallManagerRuleGroups',
          'rules',
          '__typename',
        ]),
        captchaConfig: {
          immunityTimeProperty: {
            immunityTime: value,
          },
        },
        lockToken,
      };
      editAwsWebACL({
        variables: getEditAwsWebACLVariables(_.omitBy(updatedWebAcl, value => value === null || value === '')),
      }).then(() => {
        setIsCapchaTokenOpened(false);
        getWebACLs();
      });
    },
    [webAcl, getEditAwsWebACLVariables, lockToken],
  );

  const handleEditChallengeTokenImmunityTime = useCallback(
    (value: number) => {
      if (value === webAcl?.challengeConfig?.immunityTimeProperty?.immunityTime) {
        return;
      }

      const updatedWebAcl = {
        ..._.omit(webAcl, [
          'arn',
          'capacity',
          'labelNamespace',
          'managedByFirewallManager',
          'preProcessFirewallManagerRuleGroups',
          'rules',
          '__typename',
        ]),
        challengeConfig: {
          immunityTimeProperty: {
            immunityTime: value,
          },
        },
        lockToken,
      };
      editAwsWebACL({
        variables: getEditAwsWebACLVariables(_.omitBy(updatedWebAcl, value => value === null || value === '')),
      }).then(() => {
        setIsChallengeTokenOpened(false);
        getWebACLs();
      });
    },
    [webAcl, getEditAwsWebACLVariables, lockToken],
  );

  const handleEditTokenDomainList = useCallback(
    (list: IDomainRowData[]) => {
      const updatedWebAcl = {
        ..._.omit(webAcl, [
          'arn',
          'capacity',
          'labelNamespace',
          'managedByFirewallManager',
          'preProcessFirewallManagerRuleGroups',
          'rules',
          '__typename',
        ]),
        tokenDomains: list.map((item: IDomainRowData) => item.domain).filter(domain => !!domain.trim()),
        lockToken,
      };
      editAwsWebACL({
        variables: getEditAwsWebACLVariables(_.omitBy(updatedWebAcl, value => value === null || value === '')),
      }).then(() => {
        setIsTokenDomainOpened(false);
        getWebACLs();
      });
    },
    [webAcl, getEditAwsWebACLVariables, lockToken],
  );

  console.log('webAcl', webAcl);

  return (
    <>
      <div className="resize-container vertical">
        <div className="details">
          <div className="row-3">
            <div className="title flex j-between a-center">
              <div>
                <p>Rules</p>
              </div>
              <div className="flex action a-center">
                <button className="edit-btn" onClick={onEditRuleButtonClick} disabled={true}>
                  Edit
                </button>
                <button className="action-btn" onClick={onDeleteRuleButtonClick} disabled={!selected}>
                  Delete
                </button>
                <button className="action-btn" onClick={onAddRulesButtonClick}>
                  Add rules
                </button>
              </div>
            </div>
          </div>

          {webAcl.rules.length == 0 ? (
            <div className="data-grid-wrap">
              <p className="empty-row">Empty</p>
            </div>
          ) : (
            <div className="data-grid-wrap">
              <Table
                rows={rows}
                columns={columns}
                reportCheckedList={(list: string[]) => {
                  setCheckedList(list);
                  if (!list.includes(selected)) {
                    setSelected('');
                  }

                  if (list.length === 1) {
                    setSelected(list[0]);
                  }
                }}
                reportSelected={(id: string) => {
                  setSelected(id);
                }}
                horizontalScrollable
              />
              <div className="pagination-wrapper flex a-center">
                <p className="flex a-center">
                  Total <span>{webAcl.rules.length}</span>
                </p>
                <TablePagination
                  currentPage={ruleTablePagination.currentPage}
                  updateCurrentPage={page => {
                    setRuleTablePagination(prev => ({
                      ...prev,
                      currentPage: page,
                    }));
                  }}
                  totalPage={totalRules.totalPage}
                />
              </div>
            </div>
          )}
        </div>
        <div className="details">
          <div className="row-3">
            <div className="title border-bottom">
              <p>Web ACL capacity units (WCUs) used by your web ACL</p>
              <p>
                The WCUs used by the web ACL will be less than or equal to the sum of the capacities for all of the
                rules in the web ACL.
              </p>
            </div>
            <div className="content unit">
              <p>{webAcl.capacity}/5000 WCUs</p>
            </div>
          </div>
        </div>
        <div className="details">
          <div className="row-3">
            <div className="title flex j-between a-center">
              <div>
                <p>Default web ACL action for requests that don't match any rules</p>
              </div>
              <div className="flex action a-center">
                <button className="edit-btn" onClick={() => setIsEditWebAclOpened(true)} disabled={false}>
                  Edit
                </button>
              </div>
            </div>
          </div>
          <div className="content">
            {!webAcl.defaultAction ? (
              <div className="data-grid-wrap">
                <p className="empty-row">Empty</p>
              </div>
            ) : (
              <div className="data-grid-wrap">
                <div className="data-table">
                  <table>
                    <thead>
                      <tr className="header">
                        <th style={{ paddingLeft: '32px' }}>Action</th>
                        <th>Custom request headers</th>
                      </tr>
                    </thead>
                    <tbody>
                      {webAcl.defaultAction.allow && (
                        <>
                          <tr>
                            <td style={{ paddingLeft: '32px' }}>
                              <div>Allow</div>
                            </td>
                            <td>
                              <div>
                                {webAcl.defaultAction.allow.customRequestHandling
                                  ? JSON.stringify(webAcl.defaultAction.allow.customRequestHandling)
                                  : '-'}
                              </div>
                            </td>
                          </tr>
                        </>
                      )}
                      {webAcl.defaultAction.block && (
                        <>
                          <tr>
                            <td style={{ paddingLeft: '32px' }}>
                              <div>Block</div>
                            </td>
                            <td>
                              <div>
                                {webAcl.defaultAction.block.customResponse
                                  ? JSON.stringify(webAcl.defaultAction.block.customResponse)
                                  : '-'}
                              </div>
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="details">
          <div className="row-3">
            <div className="title flex j-between a-center border-bottom">
              <div>
                <p>Web ACL CAPTCHA configuration</p>
                <p>
                  This configuration applies to rules in this web ACL that use the CAPTCHA action and don't explicitly
                  define their own CAPTCHA configuration.
                </p>
              </div>
              <div className="flex action a-center">
                <button className="edit-btn" onClick={() => setIsCapchaTokenOpened(true)} disabled={false}>
                  Edit
                </button>
              </div>
            </div>

            <div className="content">
              <p>Immunity time</p>
              <p>{webAcl.captchaConfig?.immunityTimeProperty?.immunityTime || 300} seconds</p>
            </div>
          </div>
        </div>
        <div className="details">
          <div className="row-3">
            <div className="title flex j-between a-center border-bottom">
              <div>
                <p>Web ACL Challenge configuration</p>
                <p>
                  This configuration applies to rules in this web ACL that use the Challenge actions and don't
                  explicitly define their own Challenge configurations.
                </p>
              </div>
              <div className="flex action a-center">
                <button className="edit-btn" onClick={() => setIsChallengeTokenOpened(true)} disabled={false}>
                  Edit
                </button>
              </div>
            </div>

            <div className="content">
              <p>Immunity time</p>
              <p>{webAcl.challengeConfig?.immunityTimeProperty?.immunityTime || 300} seconds</p>
            </div>
          </div>
        </div>
        <div className="details">
          <div className="row-3">
            <div className="title flex j-between a-center">
              <div>
                <p>Token domain list ({webAcl.tokenDomains?.length || 0})</p>
              </div>
              <div className="flex action a-center">
                <button className="edit-btn" onClick={() => setIsTokenDomainOpened(true)} disabled={false}>
                  Edit
                </button>
              </div>
            </div>
          </div>
          <div className="content">
            {!webAcl.tokenDomains || webAcl.tokenDomains.length == 0 ? (
              <div className="data-grid-wrap-domain">
                <p className="empty-row">Empty</p>
              </div>
            ) : (
              <div className="data-grid-wrap-domain">
                <table>
                  <thead>
                    <tr className="header">
                      <th>Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {webAcl.tokenDomains.map((t, index) => (
                      <tr key={`web-acl-token-domain-${index}`}>
                        <p>{t}</p>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>

      <EditDefaultWebAclActionModal
        header={'Edit the default web ACL action for requests that don’t match any rules'}
        open={isEditWebAclOpened}
        onClose={() => setIsEditWebAclOpened(false)}
      />

      <EditTokenImmunityTimeModal
        header={'Edit the CAPTCHA token immunity time for the web ACL'}
        subHeader={'Immunity time'}
        note={
          'Specify how long a CAPTCHA token can be used after it’s created. The AWS WAF default setting is 300 seconds. You can configure this at the web ACL level and the run level. The web ACL Configuration applies to all rules that don’t have their own setting.'
        }
        onSave={handleEditCaptchaTokenImmunityTime}
        open={isCapchaTokenOpened}
        onClose={() => setIsCapchaTokenOpened(false)}
      />

      <EditTokenImmunityTimeModal
        header={'Edit the challenge token immunity time fo rthe web ACL'}
        subHeader={'Immunity time'}
        note={
          'Specify how long a Challenge token can be used after it’s created. The AWS WAF default setting is 300 seconds. You can configure this at the web ACL level and the run level. The web ACL Configuration applies to all rules that don’t have their own setting.'
        }
        onSave={handleEditChallengeTokenImmunityTime}
        open={isChallengeTokenOpened}
        onClose={() => setIsChallengeTokenOpened(false)}
      />

      <TokenDomainListModal
        header={'Token domain list'}
        currentDatas={webAcl?.tokenDomains?.map((domain, index) => ({
          index,
          domain,
        }))}
        note={'You can add <number> more domains'}
        columns={['Domain']}
        onSave={(oldDomains: IDomainRowData[], newDomains: IDomainRowData[]) => {
          handleEditTokenDomainList([...oldDomains, ...newDomains]);
        }}
        open={isTokenDomainOpened}
        onClose={() => setIsTokenDomainOpened(false)}
      />

      <DeleteResource
        header={'Delete waf-seoul-sample-test?'}
        titleWarning={'Are you sure you want to delete the web ACL waf-seoul-sample test?'}
        subWarning={'This will delete the selected web ACL. Deleting the web ACL cannot be undone.'}
        onDelete={() => {}}
        open={isDeleteWafOpened}
        onClose={() => setIsDeleteWafOpened(false)}
      />

      <DeleteRuleModal
        resourceName={selected}
        open={isDeleteRuleModalVisible}
        onClose={() => setDeleteRuleModalVisible(false)}
        onDelete={() => {}}
        subTitle={`Are you sure you want to remove ${selected} from the web ACL?`}
        description={'This will remove the selected rules from the web ACL.'}
      />
    </>
  );
};
export default RuleDetail;
