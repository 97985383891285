import { useLazyQuery } from '@apollo/client';
import query from './query';

export interface IAwsPolicyVersionVariables {
  cloudId: number;
  region: string;
  request: {
    policyArn: string;
    versionId: string;
  };
}

export interface IAwsPolicyVersionResponseData {
  getAwsPolicyVersion: any;
}

const lazyGetAwsPolicyVersion = () =>
  useLazyQuery<IAwsPolicyVersionResponseData, IAwsPolicyVersionVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsPolicyVersion;
