import LabelInput from 'components/v2/LabelInput';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import Icon from 'components/v2/atoms/Icon';
import IconWarning from 'assets/svgs/v3/ico_warning.svg';
import IconDelete from 'assets/svgs/v3/ico_delete_network.svg';
import './index.scss';

interface IDeleteRuleModalProps extends IBaseModalProps {
  header: string;
  titleWarning: string;
  note: string;
  valueInput?: string;
  onDelete: () => void;
}

const DeleteRuleModal = (props: IDeleteRuleModalProps) => {
  const { header, titleWarning, note, valueInput, onDelete, ...baseModalProps } = props;
  
  return (
    <BaseModal
      {...baseModalProps}
      title={() => (
        <>
          <Icon width={32} height={32} src={IconDelete} />
          {header}
        </>
      )}
    >
      <div className="delete-rule-modal">
        <div className="delete-rule">
          <div className="header">
            <div className="delete-warning">
              <Icon width={32} height={32} src={IconWarning} />
              <div className="content">
                <p className="detail-warning">{titleWarning}</p>
              </div>
            </div>
            {!!valueInput ? (
              <>
                <LabelInput title={note} value={valueInput} placeholder={'delete'} disabled={true} />
              </>
            ) : (
              <>
                <div className="note">
                  {note.split('\n').map((line, index) => (
                    <p key={index}>
                      {line}
                      <br />
                    </p>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>

        <div className={'button-group'}>
          <button onClick={baseModalProps.onClose}>Cancel</button>
          <button className="delete-btn" onClick={() => {}}>
            Delete
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default DeleteRuleModal;
