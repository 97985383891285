import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseData, IGqlResponseNewData } from 'graphql/types';
import { AwsIPSetResponse } from 'graphql/types/AwsIPSet';

export interface IGetAwsIPSetVariables {
  cloudId: number;
  region: string;
  request: {
    id: string;
    name: string;
    scope: string;
  }
}

export interface IAwsIPSetResponseData {
  getAwsIPSet: IGqlResponseNewData<AwsIPSetResponse>;
}

const lazyGetAwsIPSet = () => useLazyQuery<IAwsIPSetResponseData, IGetAwsIPSetVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsIPSet;