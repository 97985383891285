import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsListIPSetsResponse } from 'graphql/types/AwsListIPSet';

export interface IGetAwsListIPSetsVariables {
  cloudId: number;
  region: string;
  request: {
    scope: string;
  };
}

export interface IAwsListIPSetsResponseData {
  getAwsListIPSets: IGqlResponseNewData<AwsListIPSetsResponse>;
}

const lazyGetAwsListIPSets = () =>
  useLazyQuery<IAwsListIPSetsResponseData, IGetAwsListIPSetsVariables>(query, {
    fetchPolicy: 'no-cache',
  });
export default lazyGetAwsListIPSets;
