import React from 'react';
import InputAtom from 'components/v2/atoms/InputAtom';
import DropdownContainer from '../../../components/DropdownContainer';
import { EVALUATION_WINDOW } from './constant';
import { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';

type RateLimitingPropsType = {
  rateLimit: string;
  setRateLimit: (val: string) => void;
  evaluationWindow: DropdownListDataType;
  setEvaluationWindow: (val: DropdownListDataType) => void;
};

function RateLimiting({ rateLimit, setRateLimit, evaluationWindow, setEvaluationWindow }: RateLimitingPropsType) {
  return (
    <div className="rule-container-group">
      <p className="rule-container-group-title">Rate-limiting criteria</p>

      <div className="rule-container-group-content">
        <div>
          <p className="rule-container-group-content-label">Rate limits</p>

          <p className="rule-container-group-content-description">
            The maximum number of requests to allow during the specified time window that satisfy your criteria. You can
            narrow the scope of the requests using scope-down statement. You can group requests by component types for
            count aggregation. You must provde at least one aggregation component or a scope-down statement.
          </p>

          <div className="input-container w-100">
            <InputAtom placeholder="" value={rateLimit} onChangeValue={setRateLimit} noClear />

            <p className="text-note">Rate limit must be between 100 and 20,000,000.</p>
          </div>
        </div>

        <DropdownContainer
          title="Evaluation window"
          description="The amount of time to use for request counts."
          options={EVALUATION_WINDOW}
          value={evaluationWindow}
          onChange={setEvaluationWindow}
        />
      </div>
    </div>
  );
}

export default RateLimiting;
