export const MOCK_STATELESS_RULE_GROUP = [
  { id: 1, priority: 1, name: 'test-stateless-group-1', capacity: 2 },
  { id: 2, priority: 2, name: 'test-stateless-group-2', capacity: 2 },
  { id: 3, priority: 3, name: 'test-stateless-group-3', capacity: 2 },
  { id: 4, priority: 4, name: 'test-stateless-group-4', capacity: 2 },
  { id: 5, priority: 5, name: 'test-stateless-group-5', capacity: 2 },
  { id: 6, priority: 6, name: 'test-stateless-group-6', capacity: 2 },
  { id: 7, priority: 7, name: 'test-stateless-group-7', capacity: 2 },
  { id: 8, priority: 8, name: 'test-stateless-group-8', capacity: 2 },
  { id: 9, priority: 9, name: 'test-stateless-group-9', capacity: 2 },
  { id: 10, priority: 10, name: 'test-stateless-group-10', capacity: 2 },
  { id: 11, priority: 11, name: 'test-stateless-group-11', capacity: 2 },
  { id: 12, priority: 12, name: 'test-stateless-group-12', capacity: 2 },
  { id: 13, priority: 13, name: 'test-stateless-group-13', capacity: 2 },
  { id: 14, priority: 14, name: 'test-stateless-group-14', capacity: 2 },
  { id: 15, priority: 15, name: 'test-stateless-group-15', capacity: 2 },
  { id: 16, priority: 16, name: 'test-stateless-group-16', capacity: 2 },
  { id: 17, priority: 17, name: 'test-stateless-group-17', capacity: 2 },
  { id: 18, priority: 18, name: 'test-stateless-group-18', capacity: 2 },
  { id: 19, priority: 19, name: 'test-stateless-group-19', capacity: 2 },
  { id: 20, priority: 20, name: 'test-stateless-group-20', capacity: 2 },
  { id: 21, priority: 21, name: 'test-stateless-group-21', capacity: 2 },
  { id: 22, priority: 22, name: 'test-stateless-group-22', capacity: 2 },
  { id: 23, priority: 23, name: 'test-stateless-group-23', capacity: 2 },
  { id: 24, priority: 24, name: 'test-stateless-group-24', capacity: 2 },
  { id: 25, priority: 25, name: 'test-stateless-group-25', capacity: 2 },
  { id: 26, priority: 26, name: 'test-stateless-group-26', capacity: 2 },
];

export const MOCK_STATEFUL_RULE_GROUP = [
  { id: 1, priority: 1, name: 'test-stateful-group-1', capacity: 2 },
  { id: 2, priority: 2, name: 'test-stateful-group-2', capacity: 2 },
  { id: 3, priority: 3, name: 'test-stateful-group-3', capacity: 2 },
  { id: 4, priority: 4, name: 'test-stateful-group-4', capacity: 2 },
  { id: 5, priority: 5, name: 'test-stateful-group-5', capacity: 2 },
  { id: 6, priority: 6, name: 'test-stateful-group-6', capacity: 2 },
  { id: 7, priority: 7, name: 'test-stateful-group-7', capacity: 2 },
  { id: 8, priority: 8, name: 'test-stateful-group-8', capacity: 2 },
  { id: 9, priority: 9, name: 'test-stateful-group-9', capacity: 2 },
  { id: 10, priority: 10, name: 'test-stateful-group-10', capacity: 2 },
  { id: 11, priority: 11, name: 'test-stateful-group-11', capacity: 2 },
  { id: 12, priority: 12, name: 'test-stateful-group-12', capacity: 2 },
  { id: 13, priority: 13, name: 'test-stateful-group-13', capacity: 2 },
  { id: 14, priority: 14, name: 'test-stateful-group-14', capacity: 2 },
  { id: 15, priority: 15, name: 'test-stateful-group-15', capacity: 2 },
  { id: 16, priority: 16, name: 'test-stateful-group-16', capacity: 2 },
  { id: 17, priority: 17, name: 'test-stateful-group-17', capacity: 2 },
  { id: 18, priority: 18, name: 'test-stateful-group-18', capacity: 2 },
  { id: 19, priority: 19, name: 'test-stateful-group-19', capacity: 2 },
  { id: 20, priority: 20, name: 'test-stateful-group-20', capacity: 2 },
  { id: 21, priority: 21, name: 'test-stateful-group-21', capacity: 2 },
  { id: 22, priority: 22, name: 'test-stateful-group-22', capacity: 2 },
  { id: 23, priority: 23, name: 'test-stateful-group-23', capacity: 2 },
  { id: 24, priority: 24, name: 'test-stateful-group-24', capacity: 2 },
  { id: 25, priority: 25, name: 'test-stateful-group-25', capacity: 2 },
  { id: 26, priority: 26, name: 'test-stateful-group-26', capacity: 2 },
];

export const MOCK_AWS_KMS_KEY_DETAILS = {
  KEY_ARN: "Am:aws:kms:ap-northeast-2-4434353543-110key/424323/9cn3j4kjdkfjkdsjfskmd",
  KEY_STATUS: "Enabled",
  KEY_ALIASES: "Key aliases",
}

export const FIREWALL_POLICY_CREATION = {
  CREATE_FIREWALL_POLICY_TITLE: "Create firewall policy",
  CREATE_FIREWALL_POLICY_REVIEW_TITLE: "Create firewall policy: Review",
  
  // Describe Firewall Policy Step
  DESCRIBE_FIREWALL_POLICY_TITLE: 'Describe firewall policy',
  DESCRIBE_FIREWALL_POLICY_SUB_TITLE:
    'Name and describe your firewall policy so you can easily identify it and distinguish it from other resources.',
  POLICY_DETAIL_TITLE: 'Firewall policy details',
  POLICY_DETAIL_NAME_TITLE: 'Name',
  POLICY_DETAIL_NAME_SUBTITLE:
    'Enter a unique anme for the firewall. The name must have 1-128 characters. Valid characters: a-z, A-Z, 0-9 and - (hyphen). The name can’t start or end with a hyphen, and it can’t contain two consecutive hyphens.',
  POLICY_DETAIL_NAME_PLACEHOLDER: 'Enter firewall policy name',
  POLICY_DETAIL_DESCRIPTION: 'Description',
  POLICY_DETAIL_SUBDESCRIPTION: 'The description can have 0-256 characters.',
  POLICY_DETAIL_DESCRIPTION_PLACEHOLDER: 'Enter firewall policy description',
  STREAM_EXCEPTION_POLICY_TITLE: 'Stream exception policy',
  STREAM_EXCEPTION_POLICY_DESCRIPTION:
    'Choose how Network Firewall handles traffic when a network connection breaks midstream.',
  STREAM_EXCEPTION_POLICY_DROP_LABEL: 'Drop',
  STREAM_EXCEPTION_POLICY_DROP_DESCRIPTION: 'Drop all subsequent traffic going to the firewall.',
  STREAM_EXCEPTION_POLICY_CONTINUE_LABEL: 'Continue',
  STREAM_EXCEPTION_POLICY_CONTINUE_DESCRIPTION: 'Continue processing rules without context from previous traffic.',
  STREAM_EXCEPTION_POLICY_REJECT_LABEL: 'Reject',
  STREAM_EXCEPTION_POLICY_REJECT_DESCRIPTION:
    'Fails closed, sends a TCP reset packet to the sender, and drops all subsequent traffic going to the firewall.',

  // Add Rule Group Step
  ADD_RULE_GROUP_TITLE: 'Add rule groups-optional',
  ADD_RULE_GROUP_SUBTITLE:
    'Rule groups are reusable collection of rules and a rule defines the patterns to look for in requests and the action to take when a request matches the pattern.',
  STATELESS_DEFAULTS_ACTIONS_TITLE: 'Stateless default actions',
  STATELESS_DEFAULTS_ACTIONS_SUBTITLE:
    'Stateless default actions determine how Network Firewall should handle packets that don’t match any stateless rule group contained in this policy. You must set stateless default action regardless of whether you define stateless rule groups for the policy.',
  FRAGMENTED_PACKETS_TITLE: 'Fragmented packets',
  FRAGMENTED_PACKETS_SUB_TITLE: 'Choose how to treat fragmented packets.',
  FRAGMENTED_PACKETS_USE_SAME_ACTION_RADIO_LABEL: 'Use the same actions for all packets',
  FRAGMENTED_PACKETS_USE_DIFFERENT_ACTION_RADIO_LABEL: 'Use different actions for full packets and fragmented packets',
  RULE_ACTION_TITLE: 'Rule action',
  RULE_ACTION_SUBTITLE: 'Choose how to handle a packet that matches teh rule’s match criteria.',
  RULE_ACTION_PASS_RADIO_LABEL: 'Pass',
  RULE_ACTION_PASS_RADIO_DESCRIPTION:
    'Discontinue all inspection of the packet and permit it to go to its intended destination.',
  RULE_ACTION_DROP_RADIO_LABEL: 'Drop',
  RULE_ACTION_DROP_RADIO_DESCRIPTION:
    'Discontinue all inspection of the packet and block it from going to its intended destination.',
  RULE_ACTION_FORWARD_RADIO_LABEL: 'Forward to stateful rule groups',
  RULE_ACTION_FORWARD_DESCRIPTION:
    'Discontinue stateless inspection of the packet and forward it to the stateful file engine for inspection.',
  RULE_ACTION_FULL_PACKETS_TITLE: 'Rule action - full packets',
  RULE_ACTION_FULL_PACKETS_SUBTITLE: 'Choose how to handle a packet that matches teh rule’s match criteria.',
  RULE_ACTION_FULL_PACKETS_PASS_RADIO_LABEL: 'Pass',
  RULE_ACTION_FULL_PACKETS_PASS_RADIO_DESCRIPTION:
    'Discontinue all inspection of the packet and permit it to go to its intended destination.',
  RULE_ACTION_FULL_PACKETS_DROP_RADIO_LABEL: 'Drop',
  RULE_ACTION_FULL_PACKETS_DROP_RADIO_DESCRIPTION:
    'Discontinue all inspection of the packet and block it from going to its intended destination.',
  RULE_ACTION_FULL_PACKETS_FORWARD_RADIO_LABEL: 'Forward to stateful rule groups',
  RULE_ACTION_FULL_PACKETS_FORWARD_DESCRIPTION:
    'Discontinue stateless inspection of the packet and forward it to the stateful file engine for inspection.',
  RULE_ACTION_FRAGMENTED_PACKETS_TITLE: 'Rule action - fragmented packets',
  RULE_ACTION_FRAGMENTED_PACKETS_SUBTITLE: 'Choose how to handle a packet that matches the rule’s match criteria.',
  RULE_ACTION_FRAGMENTED_PACKETS_PASS_RADIO_LABEL: 'Pass',
  RULE_ACTION_FRAGMENTED_PACKETS_PASS_RADIO_DESCRIPTION:
    'Discontinue all inspection of the packet and permit it to go to its intended destination.',
  RULE_ACTION_FRAGMENTED_PACKETS_DROP_RADIO_LABEL: 'Drop',
  RULE_ACTION_FRAGMENTED_PACKETS_DROP_RADIO_DESCRIPTION:
    'Discontinue all inspection of the packet and block it from going to its intended destination.',
  RULE_ACTION_FRAGMENTED_PACKETS_FORWARD_RADIO_LABEL: 'Forward to stateful rule groups',
  RULE_ACTION_FRAGMENTED_PACKETS_FORWARD_DESCRIPTION:
    'Discontinue stateless inspection of the packet and forward it to the stateful file engine for inspection.',
  STATELESS_RULE_GROUP_TITLE: 'Stateless rule groups',
  STATELESS_RULE_GROUP_MOVE_UP_BUTTON: 'Move up',
  STATELESS_RULE_GROUP_MOVE_DOWN_BUTTON: 'Move down',
  STATELESS_RULE_GROUP_DELETE_BUTTON: 'Delete',
  STATELESS_RULE_GROUP_ADD_STATELESS_BUTTON: 'Add stateless rule groups',
  STATELESS_RULE_GROUP_PRIORITY_COLUMN_TITLE: 'Priority',
  STATELESS_RULE_GROUP_NAME_COLUMN_TITLE: 'Name',
  STATELESS_RULE_GROUP_CAPACITY_COLUMN_TITLE: 'Capacity',
  STATEFUL_DEFAULTS_ACTIONS_TITLE: 'Stateful rule evaluation order and default actions',
  STATEFUL_DEFAULTS_ACTIONS_SUBTITLE: 'The way that your stateful rules are ordered for evaluation.',
  RULE_EVALUATION_ORDER_TITLE: 'Rule evaluation order',
  RULE_EVALUATION_ORDER_SUBTITLE: 'Choose how to treat fragmented packets.',
  RULE_EVALUATION_STRICT_ORDER_LABEL: 'Strict order - recommended',
  RULE_EVALUATION_STRICT_ORDER_DESCRIPTION:
    'Rules are processed in the order that you define, starting with the first rule.',
  RULE_EVALUATION_ACTION_ORDER_LABEL: 'Action order',
  RULE_EVALUATION_ACTION_ORDER_DESCRIPTION:
    'Rules with a pass action are processed first, followed by drop, reject, and alert actions. This option was previously named Default order.',
  RULE_EVALUATION_DROP_ACTION_TITLE: 'Drop action - optional',
  RULE_EVALUATION_DROP_ACTION_SUBTITLE:
    'Choose the default drop action that Network Firewall should take on packets that doesn’t match any stateful rules',
  RULE_EVALUATION_DROP_ALL: 'Drop all',
  RULE_EVALUATION_DROP_ESTABLISHED: 'Drop established',
  RULE_EVALUATION_ALERT_ACTION_TITLE: 'Alert action - optional',
  RULE_EVALUATION_ALERT_ACTION_SUBTITLE:
    'Generate alert messages for each matching rule. To send the alert messages to the firewall’s logs, add a logging configuration to the firewall after creation.',
  RULE_EVALUATION_ALERT_ACTION_ALL_LABEL: 'All',
  RULE_EVALUATION_ALERT_ACTION_ALL_DESCRIPTION: 'Log an ALERT_ALL message on all packets.',
  RULE_EVALUATION_ALERT_ACTION_ESTABLISHED_LABEL: 'Established',
  RULE_EVALUATION_ALERT_ACTION_ESTABLISHED_DESCRIPTION: 'Log an ALERT_ALL message on all packets.',
  STATEFUL_RULE_GROUP_TITLE: 'Stateful rule groups',
  STATEFUL_RULE_GROUP_MOVE_UP_BUTTON: 'Move up',
  STATEFUL_RULE_GROUP_MOVE_DOWN_BUTTON: 'Move down',
  STATEFUL_RULE_GROUP_DELETE_BUTTON: 'Delete',
  STATEFUL_RULE_GROUP_ADD_STATEFUL_BUTTON: 'Add stateful rule groups',
  STATEFUL_RULE_GROUP_PRIORITY_COLUMN_TITLE: 'Priority',
  STATEFUL_RULE_GROUP_NAME_COLUMN_TITLE: 'Name',
  STATEFUL_RULE_GROUP_CAPACITY_COLUMN_TITLE: 'Capacity',
  CAPACITY_UNITS_BY_STATELESS_GROUP_TITLE: 'Capacity units consumed by stateless rule groups',
  CAPACITY_UNITS_BY_STATELESS_GROUP_DESCRIPTION:
    'The total capacity units consumed by stateless rule groups can’t exceed 30,000.',
  CAPACITY_UNITS_BY_STATEFUL_GROUP_TITLE: 'Capacity units consumed by stateful rule groups',
  CAPACITY_UNITS_BY_STATEFUL_GROUP_DESCRIPTION:
    'The total capacity units consumed by stateful rule groups can’t exceed 30,000.',

  // Configure advanced settings step
  CONFIGURE_ADVANCED_SETTINGS_TITLE: 'Configure advanced settings - optional',
  CONFIGURE_ADVANCED_SETTINGS_SUBTITLE: 'Configure advanced settings for the firewall policy.',
  CUSTOMER_MANAGED_KEY_TITLE: 'Customer managed key',
  CUSTOMER_MANAGED_KEY_SUBTITLE:
    'You can use a customer managed key in AWS Key Management Service (KMS) to encrypt your data at rest. If you don’t configure a customer managed key Network Firewall encrypts your data using an AWS managed key.',
  CUSTOMER_MANAGED_KEY_DESCRIPTION:
    'Your data is encrypted by default with a key that AWS owns and manages for you. To choose a different key, customize your encryption settings.',
  CUSTOMIZE_ENCRYPTION_SETTINGS_CHECKBOX_LABEL: 'Customize encryption settings (advanced)',
  CHOOSE_A_KEY_PLACEHOLDER: 'Choose an AWS KMS key or enter an ARN',
  CHOOSE_A_KEY_REMOVE_BUTTON: 'Remove',
  KEY_DETAILS_TITLE: 'AWS KMS key details',
  KEY_ARN_LABEL: 'Key ARN',
  KEY_STATUS_LABEL: 'Key status',
  KEY_ALIASES_LABEL: 'Key aliases',
  POLICY_VARIABLES_TITLE: 'Policy variables',
  POLICY_VARIABLES_DESCRIPTION:
    'Enter one or more CIDRs to override the default HOME_NET CIDR. Network Firewall supports both IPv4 and IPv6.',
  HOME_NET_VARIABLES_OVERRIDE_VALUES_TITLE: 'HOME_NET variables override values-optional',
  HOME_NET_VARIABLES_OVERRIDE_VALUES_DESCRIPTION: 'Enter 1 CIDR per line.',

  // Add TLS inspection configuration step
  ADD_TLS_INSPECTION_CONFIGURATION_TITLE: "Add TLS inspection configuration-optional",
  ADD_TLS_INSPECTION_CONFIGURATION_DESCRIPTION: "A TLS inspection configuration contains SSL/TLS certificate references and 5-tuple settings that Network Firewall uses to decrypt traffic going to the firewalls you associate with this policy. Optionally add a TLS inspection configuration or create a TLS inspection configuration.",
  TLS_INSPECTION_CONFIGURATIONS_TITLE: "TLS inspection configurations",
  TLS_INSPECTION_CONFIGURATIONS_DESCRIPTION: "Add an existing TLS inspection configuration to this policy.",
  TLS_INSPECTION_CONFIGURATIONS_DROPDOWN_LABEL: "No TLS inspection configuration associated with the resource.",
  TLS_INSPECTION_CONFIGURATIONS_DROPDOWN_PLACEHOLDER: "No TLS inspection configuration associated. Select TLS inspection configuration.",
  TLS_INSPECTION_CONFIGURATIONS_REMOVE_BUTTON: "Remove",
  TLS_INSPECTION_CONFIGURATIONS_DROPDOWN_DESCRIPTION: "You can add 1 TLS inspection configuration.",

  // Add tags step
  ADD_TAGS_TITLE: "Add tags-optional",
  ADD_TAGS_DESCRIPTION: "A tag is a label that you assign to an AWS resource. You can use tags to search and filter your resources or track your AWS costs.",
  FIREWALL_POLICY_TAGS_TITLE: "Firewall policy tags",
  FIREWALL_POLICY_DESCRIPTION: "These are the tags for the firewall policy.",
  ADD_TAG_TITLE: "No tags associated with the resource.",
  ADD_TAG_BUTTON: "Add tag",
  ADD_TAG_DESCRIPTION: "You can add up to 50 more tags.",
};
