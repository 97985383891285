import { useLazyQuery } from '@apollo/client';
import query from './query';
import { Paging } from 'graphql/types/Paging';
import { AwsFirewallPolicyMetadata } from 'graphql/types/AwsListFirewallPolicy';
import { IGqlResponseNewData } from 'graphql/types';

export interface IAwsAllListFirewallPoliciesVariables {
  cloudId: number;
  region: string;
  request: {
    maxResults: number;
    nextToken?: string;
    dryRun?: boolean;
    filters?: {
      name: string;
      values: string[];
    };
    pageInfo?: Paging;
  };
}

export interface IAwsAllListFirewallPoliciesResponseData {
  getAwsAlllistFirewallPolicies: IGqlResponseNewData<AwsFirewallPolicyMetadata>;
}

const lazyGetAwsAllListFirewallPolicies = () =>
  useLazyQuery<IAwsAllListFirewallPoliciesResponseData, IAwsAllListFirewallPoliciesVariables>(query, {
    fetchPolicy: 'no-cache',
  });
export default lazyGetAwsAllListFirewallPolicies;
