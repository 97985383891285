import { useLazyQuery } from '@apollo/client';
import query from './query';

export interface IAwsPolicyVariables {
  cloudId: number;
  region: string;
  request: {
    policyArn: string;
  };
}

export interface IAwsPolicyResponseData {
  getAwsPolicy: any;
}

const lazyGetAwsPolicy = () =>
  useLazyQuery<IAwsPolicyResponseData, IAwsPolicyVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsPolicy;
