import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from '../../types';
import { AwsUpdateIpSetResponse } from 'graphql/types/AwsUpdateIpSetResponse';

export interface IUpdateAwsIPSetVariables {
  cloudId: number;
  region: string;
  request: {
    name: string;
    scope: string;
    id: string;
    description: string;
    addresses: string[];
    lockToken: string;
  }
}

export interface IUpdateAwsIPSetResponseData {
  updateAwsIPSet: IGqlResponseNewData<AwsUpdateIpSetResponse>;
}

const updateAwsIPSetMutation = () => useMutation<IUpdateAwsIPSetResponseData, IUpdateAwsIPSetVariables>(query);
export default updateAwsIPSetMutation;