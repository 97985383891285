import React from 'react';
import InputAtom from "components/v2/atoms/InputAtom";

type HeaderFieldNamePropsType = {
  headerFieldName: string;
  setHeaderFieldName: (val: string) => void;
};

function HeaderFieldName({headerFieldName, setHeaderFieldName}: HeaderFieldNamePropsType) {
  return (
    <div>
      <p className='rule-container-group-content-label'>Header field name</p>

      <p className='rule-container-group-content-description'>X-Forwarded-For (XFF) is the most commonly used
        header for the client and proxy IP addresses. If you’re using a different header, you can enter the
        name
        here.</p>

      <div className="input-container">
        <InputAtom
          placeholder='X-Forwarded-For'
          onChangeValue={val => {
            if (val.length <= 128) setHeaderFieldName(val);
          }}
          value={headerFieldName}
          required={true}
          noClear={true}
          className='mw-50'
        />

        <p>
          Header field name must have 1-255 characters. Valid characters: A-Z, a-z, 0-9, and -(hyphen).
        </p>
      </div>
    </div>
  );
}

export default HeaderFieldName;