import { useState, useMemo, useEffect } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import InputAtom from 'components/v2/atoms/InputAtom';
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import './index.scss';
import MultiSelectAutocomplete from 'components/v2/atoms/AutocompleteInputAtom';
import {RuleRowData} from "pages/v2/Organ/Management/Network/EditNetworkRuleModal";
import Icon from "components/v2/atoms/Icon";
import InboundIcon from "assets/svgs/v3/ico_inbound.svg";
import OutboundIcon from "assets/svgs/v3/ico_outbound.svg";
import {NETWORK_RULE_CUSTOM_ICMP_PORT_DROPDOWN, NETWORK_RULE_CUSTOM_ICMP_PORT_PAR_PRO_DROPDOWN, NETWORK_RULE_CUSTOM_ICMP_PORT_RED_MES_DROPDOWN, RULE_CUSTOM_ICPM_PROTOCOL_DROPDOWN, RULE_PROTOCOL_DROPDOWN, SG_RULE_TYPE_DROPDOWN} from "pages/v2/Organ/Management/Network/Common/Constant";

interface IEditSecurityGroupRuleModalProps extends IBaseModalProps {
  header: string;
  subTitle?: string;
  isInbound: boolean;
  currentData: RowSecurityGroupRuleData[];
  onSave: (data: RuleRowData[]) => void;
}

export interface RowSecurityGroupRuleData {
  securityGroupRuleId: string;
  type: string;
  protocol: string;
  portRange: string;
  destination: string;
  description: string;
}

const dummySourceList: DropdownListDataType[] = [
  {id: 0, name: '0.00.000', value: '0.00.000'},
  {id: 1, name: '0.00.001', value: '0.00.001'},
]

const EditSecurityGroupRuleModal = ({
                          header,
                          isInbound,
                          subTitle,
                          currentData,
                          onSave,
                          ...baseModalProps
                        }: IEditSecurityGroupRuleModalProps) => {

  const [rows, setRows] = useState<RowSecurityGroupRuleData[]>([]);

  const handleCreate = () => {
    setRows((prevState) => {
      const newState = prevState.concat({
        securityGroupRuleId: '',
        type: 'custom_tcp',
        protocol: '6',
        portRange: '0',
        destination: '',
        description: ''
      });
      return newState;
    })
  };
  

  const handleDelete = (indexToRemove: number) => {
    const newData = rows.filter((_, index) => index != indexToRemove)
    setRows(newData);
  }

  const handleChange = (index: number, value: string, propName: keyof RowSecurityGroupRuleData) => {
    const newRows = rows.map((row, i) =>
      i === index ? { ...row, [propName]: value } : row
    );
    setRows(newRows);
  };

  const handleDeleteSelected = (index: number, propName: keyof RowSecurityGroupRuleData) => {
    const newRows = rows.map((row, i) =>
      i === index ? ({ ...row, [propName]: [] })  : row
    );
    setRows(newRows);
  };

  const getProtocolDataByType = (type: string) => {
    let data: DropdownListDataType[] = [];
    if (type === 'custom_icmp_ipv4' || type === 'custom_protocol') {
      data = RULE_CUSTOM_ICPM_PROTOCOL_DROPDOWN;
    }
    return data;
  }

  const disabledByTypeAndProtocol = (type: string, protocol: string) => {
    let disabled = true;
    if (type === 'custom_tcp' || type === 'custom_udp') {
      disabled = false;
    }
    if (type === 'custom_icmp_ipv4' || type == 'custom_icmp_ipv6') {
      if (protocol === '3' || protocol === '5' || protocol === '12') {
        disabled = false;
      }
    }
    return disabled;
  }
  
  const getPortRangeDataByType = (type: string, protocol: string) => {
    let data: DropdownListDataType[] = [{
      name: 'N/A', value: 'N/A'
    }];
    if (type === 'custom_icmp_ipv4' || type == 'custom_icmp_ipv6') {
      if (protocol === '3') {
        data = NETWORK_RULE_CUSTOM_ICMP_PORT_DROPDOWN;
      }
      if (protocol === '5') {
        data = NETWORK_RULE_CUSTOM_ICMP_PORT_RED_MES_DROPDOWN;
      }
      if (protocol === '12') {
        data = NETWORK_RULE_CUSTOM_ICMP_PORT_PAR_PRO_DROPDOWN;
      }
    }
    return data;
  }

  const disabledByType = (type: string) => {
    let disabled = true;
    if (type === 'custom_protocol' || type === 'custom_icmp_ipv4' || type == 'custom_icmp_ipv6') {
      disabled = false;
    }
    return disabled;
  }

  return (
    <BaseModal title={() => <><Icon width={32} height={32} src={isInbound ? InboundIcon : OutboundIcon}/>{header}</>}
               {...baseModalProps}
    >
      <div className="edit-inbound-rules-model">
        <div className="edit-inbound-rules-sub-title">{subTitle}</div>
        <div className="horizontal-line"></div>
        <table className="table-rules">
          <thead className="table-header">
          <tr>
            <th>Security group rule ID</th>
            <th>Type</th>
            <th>Protocol</th>
            <th>Port range</th>
            <th>Source</th>
            <th>Description (optional)</th>
          </tr>
          </thead>
          <tbody className="table-body">
          {currentData.map((row, index) => (
            <tr key={index}>
              <td>
                <label>{row.securityGroupRuleId}</label>
              </td>
              <td>
                <DropdownAtom
                  id={'type-rules' + `${index}`}
                  data={SG_RULE_TYPE_DROPDOWN}
                  value={{
                    name: SG_RULE_TYPE_DROPDOWN.find((item) => item.value === row.type)?.name,
                    value: row.type
                  }}
                  handleClick={ (val:any) =>handleChange(index, val.value as string, "type")}/>
              </td>
              <td>
                {row.type === 'custom_icmp_ipv4' || row.type === 'custom_protocol' ?
                  <DropdownAtom
                  id={'protocol-rules' + `${index}`}
                  disabled={disabledByType(row.type)}
                  data={getProtocolDataByType(row.type)}
                  value={{
                    name: getProtocolDataByType(row.type).find((item) => item.value === row.protocol)?.name,
                    value: row.protocol,
                  }}
                    handleClick={(val) => handleChange(index, val.value as string, "protocol")}
                  />
                  :
                  <InputAtom
                    value={row.protocol}
                    disabled={disabledByType(row.type)}
                    noClear={true}
                    onChangeValue={(value: string) => handleChange(index, value, "protocol")}
                  />
                }
              </td>
              <td>
              {row.type === 'custom_icmp_ipv4' || row.type === '' ?
                  <DropdownAtom
                    id={'port-range-rules' + `${index}`}
                    disabled={disabledByTypeAndProtocol(row.type, row.protocol)}
                    data={getPortRangeDataByType(row.type, row.protocol)}
                    value={{
                      name: getPortRangeDataByType(row.type, row.protocol).find(val => val.value === row.portRange)?.name || '',
                      value: row.portRange
                    }}
                    handleClick={(val) => handleChange(index, val.value as string, "portRange")}
                  />
                  :
                  <InputAtom
                    value={row.portRange}
                    disabled={disabledByTypeAndProtocol(row.type, row.protocol)}
                    noClear={true}
                    onChangeValue={(value: string) => handleChange(index, value, "portRange")}
                  />
                }
              </td>
              <td>
                <MultiSelectAutocomplete
                  className={"source" + `${index}`}
                  placeholder={'0.00.000'}
                  data={dummySourceList}
                  selectedValues={row.destination == '' ?[]:[row.destination] }
                  handleClick={(val: DropdownListDataType) => {
                    handleChange(index, val.value.toString(), "destination")
                  }}

                  handleRemove={(val: string) => {
                    handleDeleteSelected(index,"destination")
                  }}/>
              </td>
              <td>
                <InputAtom
                  value={row.destination}
                />
              </td>
              <td >
                <button onClick={() => handleDelete(index)}>Remove</button>
              </td>
            </tr>
          ))}
          <tr >
            <td>
              <button className="add-button" onClick={handleCreate}>Add new tag</button>
            </td>
          </tr>
          </tbody>
        </table>
        <div className="button-group">
          <button onClick={baseModalProps.onClose}>Cancel</button>
          <button className="save-btn" onClick={() => {console.log(rows)}}>Save changes </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default EditSecurityGroupRuleModal;
