import { gql } from '@apollo/client';

const query = gql`
query getAwsDescribeVolumeStatus($request: AwsDescribeVolumeStatusRequest, $cloudId: Long, $region: String) {
  getAwsDescribeVolumeStatus(describeVolumeStatusRequest: $request, cloudId: $cloudId, region: $region) {
    result
     data{
      ... on AwsDescribeVolumeStatusResponse {
       nextToken
        volumeStatuses {
          actions {
            code
            description
            eventId
            eventType
          }
          availabilityZone
          outpostArn
          events {
            description
            eventId
            eventType
            notAfter
            notBefore
            instanceId
          }
          volumeId
          volumeStatus {
            details {
              name
              status
            }
            status
          }
          attachmentStatuses {
            ioPerformance
            instanceId
          }
        }
        }
      }
    }
  }
`;
export default query;
