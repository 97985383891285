import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsDescribeSnapshotAttributeResponse } from 'graphql/types/AwsDescribeSnapshotAttributeResponse';

export interface IAwsDescribeSnapshotAttributeVariables {
  cloudId: number;
  region: string;
  request: {
    attribute: string;
    snapshotId: string;
    dryRun?: boolean;
  }
}

export interface IAwsAwsDescribeSnapshotAttributeResponseData {
  getAwsDescribeSnapshotAttribute: IGqlResponseNewData<AwsDescribeSnapshotAttributeResponse>;
}

const lazyGetAwsDescribeSnapshotAttribute = () => useLazyQuery<IAwsAwsDescribeSnapshotAttributeResponseData, IAwsDescribeSnapshotAttributeVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsDescribeSnapshotAttribute;