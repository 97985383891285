import { gql } from '@apollo/client';

const query = gql`
  query getAwsListEntitiesForPolicy($request: AwsListEntitiesForPolicyRequest, $cloudId: Long, $region: String) {
    getAwsListEntitiesForPolicy(listEntitiesForPolicyRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsListEntitiesForPolicyResponse {
          policyGroups {
            groupName
            groupId
          }
          policyUsers {
            userName
            userId
          }
          policyRoles {
            roleName
            roleId
          }
          isTruncated
          marker
        }
      }
    }
  }
`;
export default query;
