import { useLazyQuery } from '@apollo/client';
import query from './query';
import { AwsDescribeRuleGroupResponse } from 'graphql/types/AwsDescribeRuleGroup';
import { IGqlResponseNewData } from 'graphql/types';

export interface IAwsDescribeRuleGroupVariables {
  cloudId: number;
  region: string;
  request: {
    ruleGroupName?: string;
    ruleGroupArn: string;
    type?: string;
    analyzeRuleGroup?: boolean;
  };
}

export interface IAwsDescribeRuleGroupResponseData {
  getAwsDescribeRuleGroup: IGqlResponseNewData<AwsDescribeRuleGroupResponse>;
}

const lazyGetAwsDescribeRuleGroup = () =>
  useLazyQuery<IAwsDescribeRuleGroupResponseData, IAwsDescribeRuleGroupVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsDescribeRuleGroup;
