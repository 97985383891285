import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from '../../types';
import { AwsUpdateWebAclRequestType, AwsUpdateWebAclResponseType } from 'graphql/types/AwsUpdateWebAcl';

export type UpdateAwsWebACLVariablesType = {
  cloudId: number;
  region: string;
  request: AwsUpdateWebAclRequestType;
};

export type UpdateAwsWebACLResponseData = {
  updateAwsWebACL: IGqlResponseNewData<AwsUpdateWebAclResponseType>;
};

const updateAwsWebACL = () => useMutation<UpdateAwsWebACLResponseData, UpdateAwsWebACLVariablesType>(query);
export default updateAwsWebACL;
