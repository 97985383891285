import React, { useMemo, useEffect } from 'react';
import { SetRulePriorityPropsType } from '../type';
import { StepContainer } from '../components';
import DetailTable from 'pages/v2/Organ/Management/EC2/components/DetailTable';
import { RULES_COLUMN_LIST } from '../configs';
import { ButtonTypeEnum } from '../components/Button';

const SetRulePriority = (props: SetRulePriorityPropsType) => {
  const { title } = props;

  const rulesActionButtons = useMemo(() => {
    return [
      {
        id: 'moveUp',
        label: 'Move up',
        type: ButtonTypeEnum.GENERAL,
        onClick: () => {},
        disabled: true,
      },
      {
        id: 'moveDown',
        label: 'Move down',
        type: ButtonTypeEnum.GENERAL,
        onClick: () => {},
        disabled: true,
      },
    ];
  }, []);

  return (
    <StepContainer title={title}>
      <DetailTable title={'Rules'} columns={RULES_COLUMN_LIST} data={[]} rightButtons={rulesActionButtons} />
    </StepContainer>
  );
};

export default SetRulePriority;
