/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useMemo, useState } from 'react';
import './index.scss';

import { ColumnType, RowType, OrderDirection } from '@Types/v2/Table';
import TableCheckbox from '../TableCheckbox';
import { handleFormatText } from 'utils/Common';

interface ITableProps {
  /** 외부 오버라이딩 커스텀 CSS를 위한 className */
  className?: string;
  /** 컬럼방식 테이블의 컬럼data. thead에 컬럼label을 출력할 때 사용 및 해당 field와 일치하는 row data의 렌더 또는 출력을 위해 필요 */
  columns: ColumnType[];
  /** 컬럼방식 테이블의 로우data. tbody에 column의 field와 일치하는 key의 value를 렌더 또는 출력 */
  rows: RowType[];
  /** 테이블 내 체크박스의 토글에 의해 checkedList가 변경될 때 상위 컴포넌트에 배열을 보고 */
  reportCheckedList?: (list: string[]) => void;
  /** 클릭에 의해 selected가 된 id를 상위 컴포넌트에 보고 */
  reportSelected?: (select: string) => void;
  /** 정렬옵션. sort: 정렬화살표를 눌러 선택한 해당 th의 field, order: 오름차순 또는 내림차순, onChangeSort: 클릭한 컬럼의 field와 order을 통해 row 데이터를 재정렬하는 함수 */
  sortOption?: {
    target: string;
    direction: OrderDirection;
    onChangeSort: (target: string, dir: OrderDirection) => void;
  }
  /** tbody의 각 row데이터의 단순 순번나열을 보고 싶을 경우 사용하며 offset이 지정된 경우 props로 currentPage를 넣어주기, offset이 없는 경우 1 */
  numberOption?: number;
  horizontalScrollable?: boolean;
  isAllRowChecked?: boolean;
  isLoading?: boolean;
}

const Table = ({ 
  rows,
  columns, 
  className,
  reportCheckedList,
  reportSelected,
  sortOption,
  numberOption = undefined,
  horizontalScrollable = false,
  isAllRowChecked = false,
  isLoading = false,
}:ITableProps) => {

  /* 테이블의 기본 옵션 states 관리 */
  const [checkedList, setCheckedList] = useState<string[]>([]);

  /* tbody 영역 memo */
  const renderBody = useMemo(() => {
    if (isLoading) {
      return (
        <tr className="progresss">
          <td colSpan={columns.length + 1}>
            <div className="progress-container">
              <div className='progress-gif' />
              Loading ...
            </div>
          </td>
        </tr>
      );
    }

    return (
      rows.map((row, rowIdx) => (
        <tr 
          key={`row-${rowIdx}`}
          onClick={() => {
            reportSelected && reportSelected(String(row.id));
            setCheckedList([]);
            
            if (horizontalScrollable && reportCheckedList) {
              setCheckedList([String(row.id)]);
            }
          }}
        >
          { 
            reportCheckedList && (
              <td>
                <div className="td__checkbox">
                  <div
                    onClick={(e) => e.stopPropagation()}
                    className='td__checkbox__wrap'
                  >
                    <TableCheckbox
                      defaultValue={checkedList.includes(String(row.id))}
                      onChange={() => {
                        if (checkedList.includes(String(row.id))) {
                          const left = checkedList.filter(li => li !== String(row.id));
                          setCheckedList(left);
                          reportCheckedList(left);
                        } else {
                          setCheckedList([...checkedList, String(row.id)]);
                          reportCheckedList([...checkedList, String(row.id)]);
                        }
                      }}
                    />
                  </div>
                </div>
              </td>
            )
          } 
          {
            numberOption && (
              <td>
                {
                  numberOption === 1 
                    ? rowIdx + 1 
                    : (rows.length * (numberOption - 1)) + rowIdx + 1 
                }
              </td>
            )
          }
          {
            columns.map((col, idx) => (
              <td key={`row-${rowIdx}-col-${idx}`}>
                <div>
                  {
                    col.renderCell
                      ? col.renderCell(row)
                      : handleFormatText((row as { [key: string]: any })[col.field])
                  }
                </div>
              </td>
            )) 
          }
        </tr>
      ))
    );
  },[rows, columns, sortOption, checkedList, isLoading]);

  return (
    <div className={`data-table ${className}`}>
      <table>
        {!horizontalScrollable &&
          <thead>
            <tr className="header">
              { reportCheckedList && (
                <th style={{width: '87px', maxWidth: '87px'}}>
                  <div className="th__checkbox">
                    <TableCheckbox
                      defaultValue={checkedList.length > 0 && checkedList.length === rows.length}
                      mixed={checkedList.length !== 0 && checkedList.length < rows.length}
                      onChange={() => {
                        const ids = rows.map(row => String(row.id));
                        if (checkedList.length === rows.length) {
                          setCheckedList([]);
                          reportCheckedList([]);
                        } else {
                          setCheckedList(ids);
                          reportCheckedList(ids);
                        }
                      }}
                    />
                  </div>


                </th>)}
              {numberOption && <th style={{width: '88px', maxWidth: '88px', paddingLeft: '32px'}}>#</th>}
              {columns.map((col, idx) => (
                <th
                  key={`col-${idx}`}
                  {...(col.width && { style: { width: col.width + 'px' } })}
                >
                  <div>
                    {
                      col.renderHeader 
                        ? col.renderHeader() 
                        : col.label 
                          ? col.label 
                          : col.field
                    }
                    {
                      sortOption && col.sort &&
                        <button
                          className={`sort-btn
                          ${sortOption.direction === OrderDirection.ASC && 'upside-down'} 
                          ${col.field === sortOption.target && 'sorted'}`
                        }
                        onClick={() => {
                          if (sortOption.target !== col.field) {
                            sortOption.onChangeSort(col.field, OrderDirection.DES)
                          } else {
                            sortOption.onChangeSort(col.field, sortOption.direction === OrderDirection.ASC ? OrderDirection.DES : OrderDirection.ASC)
                          }
                        }}
                      />
                    }
                  </div>
                </th> ))}
            </tr>
          </thead>
        }
        <tbody>
          {horizontalScrollable &&
            <tr className="horizontal-header">
              { reportCheckedList && (
                <th style={{width: '87px', maxWidth: '87px'}}>
                  <div className="th__checkbox">
                    <TableCheckbox
                      defaultValue={checkedList.length > 0 && checkedList.length === rows.length}
                      mixed={checkedList.length !== 0 && checkedList.length < rows.length}
                      onChange={() => {
                        const ids = rows.map(row => String(row.id));
                        if (checkedList.length === rows.length) {
                          setCheckedList([]);
                          reportCheckedList([]);
                        } else {
                          setCheckedList(ids);
                          reportCheckedList(ids);
                        }
                      }}
                    />
                  </div>
                </th>)}
              {numberOption && <th style={{width: '88px', maxWidth: '88px', paddingLeft: '32px'}}>#</th>}
              {columns.map((col, idx) => (
                <th
                  key={`col-${idx}`}
                  {...(col.width && { style: { width: col.width + 'px' } })}
                >
                  <div>
                    {
                      col.renderHeader 
                        ? col.renderHeader() 
                        : col.label 
                          ? col.label 
                          : col.field
                    }
                    {
                      sortOption && col.sort &&
                        <button
                          className={`sort-btn
                          ${sortOption.direction === OrderDirection.ASC && 'upside-down'} 
                          ${col.field === sortOption.target && 'sorted'}`
                        }
                        onClick={() => {
                          if (sortOption.target !== col.field) {
                            sortOption.onChangeSort(col.field, OrderDirection.DES)
                          } else {
                            sortOption.onChangeSort(col.field, sortOption.direction === OrderDirection.ASC ? OrderDirection.DES : OrderDirection.ASC)
                          }
                        }}
                      />
                    }
                  </div>
                </th> ))}
              </tr>
          }
          {renderBody}
        </tbody>
      </table>
    </div>
  );
};
export default Table;
