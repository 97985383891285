import { gql } from '@apollo/client';

const query = gql`
query getAwsVpnGateways($request: AwsDescribeVpnGatewaysRequest, $cloudId: Long, $region: String) {
  getAwsVpnGateways(describeVpnGatewaysRequest: $request, cloudId: $cloudId, region: $region) {
    result
    data {
      ... on AwsDescribeVpnGatewaysResponse {
        vpnGateways {
          availabilityZone
          state
          type
          vpcAttachments {
            state
            vpcId
          }
          vpnGatewayId
          amazonSideAsn
          tags{
            key
            value
          }
        }
        }
      }
    }
  }
`;

export default query;