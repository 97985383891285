import { gql } from '@apollo/client';

const query = gql`
  query getAwsListDistributions($request: AwsListDistributionsRequest, $cloudId: Long, $region: String) {
    getAwsListDistributions(listDistributionsRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsListDistributionsResponse {
          distributionList {
            marker
            nextMarker
            maxItems
            isTruncated
            quantity
            items {
              id
              arn
              status
              lastModifiedTime
              domainName
              aliases {
                quantity
                items
              }
              origins {
                quantity
                items {
                  id
                  domainName
                  originPath
                  customHeaders {
                    quantity
                    items {
                      headerName
                      headerValue
                    }
                  }
                  s3OriginConfig {
                    originAccessIdentity
                  }
                  customOriginConfig {
                    httpPort
                    httpsPort
                    originProtocolPolicy
                    originSslProtocols {
                      quantity
                      items
                    }
                    originReadTimeout
                    originKeepaliveTimeout
                  }
                  connectionAttempts
                  connectionTimeout
                  originShield {
                    enabled
                    originShieldRegion
                  }
                  originAccessControlId
                }
              }
              originGroups {
                quantity
                items {
                  id
                  failoverCriteria {
                    statusCodes {
                      quantity
                      items
                    }
                  }
                  members {
                    quantity
                    items {
                      originId
                    }
                  }
                }
              }
              defaultCacheBehavior {
                targetOriginId
                trustedSigners {
                  enabled
                  quantity
                  items
                }
                trustedKeyGroups {
                  enabled
                  quantity
                  items
                }
                viewerProtocolPolicy
                allowedMethods {
                  quantity
                  items
                  cachedMethods {
                    quantity
                    items
                  }
                }
                smoothStreaming
                compress
                lambdaFunctionAssociations {
                  quantity
                  items {
                    lambdaFunctionARN
                    eventType
                    includeBody
                  }
                }
                functionAssociations {
                  quantity
                  items {
                    functionARN
                    eventType
                  }
                }
                fieldLevelEncryptionId
                realtimeLogConfigArn
                cachePolicyId
                originRequestPolicyId
                responseHeadersPolicyId
                forwardedValues {
                  queryString
                  cookies {
                    forward
                    whitelistedNames {
                      quantity
                      items
                    }
                  }
                  headers {
                    matchPattern {
                      all
                      includedHeaders
                      excludedHeaders
                    }
                    matchScope
                    invalidFallbackBehavior
                    oversizeHandling
                  }
                  queryStringCacheKeys {
                    quantity
                    items
                  }
                }
                minTTL
                defaultTTL
                maxTTL
              }
              cacheBehaviors {
                quantity
                items {
                  pathPattern
                  targetOriginId
                  trustedSigners {
                    enabled
                    quantity
                    items
                  }
                  trustedKeyGroups {
                    enabled
                    quantity
                    items
                  }
                  viewerProtocolPolicy
                  allowedMethods {
                    quantity
                    items
                    cachedMethods {
                      quantity
                      items
                    }
                  }
                  smoothStreaming
                  compress
                  lambdaFunctionAssociations {
                    quantity
                    items {
                      lambdaFunctionARN
                      eventType
                      includeBody
                    }
                  }
                  functionAssociations {
                    quantity
                    items {
                      functionARN
                      eventType
                    }
                  }
                  fieldLevelEncryptionId
                  realtimeLogConfigArn
                  cachePolicyId
                  originRequestPolicyId
                  responseHeadersPolicyId
                  forwardedValues {
                    queryString
                    cookies {
                      forward
                      whitelistedNames {
                        quantity
                        items
                      }
                    }
                    headers {
                      matchPattern {
                        all
                        includedHeaders
                        excludedHeaders
                      }
                      matchScope
                      invalidFallbackBehavior
                      oversizeHandling
                    }
                    queryStringCacheKeys {
                      quantity
                      items
                    }
                  }
                  minTTL
                  defaultTTL
                  maxTTL
                }
              }
              customErrorResponses {
                quantity
                items {
                  errorCode
                  responsePagePath
                  responseCode
                  errorCachingMinTTL
                }
              }
              comment
              priceClass
              enabled
              viewerCertificate {
                cloudFrontDefaultCertificate
                iamCertificateId
                acmCertificateArn
                sslSupportMethod
                minimumProtocolVersion
                certificate
                certificateSource
              }
              restrictions {
                geoRestriction {
                  restrictionType
                  quantity
                  items
                }
              }
              webACLId
              httpVersion
              isIPV6Enabled
              aliasICPRecordals {
                cname
                icpRecordalStatus
              }
              staging
            }
          }
        }
      }
    }
  }
`;
export default query;
