import { gql } from '@apollo/client';

const query = gql`
query getAwsDescribeVolumeAttribute($request: AwsDescribeVolumeAttributeRequest, $cloudId: Long, $region: String) {
  getAwsDescribeVolumeAttribute(describeVolumeAttributeRequest: $request, cloudId: $cloudId, region: $region) {
    result
     data{
      ... on AwsDescribeVolumeAttributeResponse {
        autoEnableIO {
          value
        }
        productCodes {
          productCodeId
          productCodeType
        }
        volumeId
      }
    }
  }
}`;
export default query;
