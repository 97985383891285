import {Fragment, useMemo, useState} from 'react';
import {fleetTab} from "pages/v2/Organ/Management/EC2/components/SpotRequest/Common/Constant";
import Description from "pages/v2/Organ/Management/EC2/components/SpotRequest/Common/Components/Fleet/Description";
import Instances from "pages/v2/Organ/Management/EC2/components/SpotRequest/Common/Components/Fleet/Instances";
import History from "pages/v2/Organ/Management/EC2/components/SpotRequest/Common/Components/Fleet/History";

import './index.scss';
import { SpotFleetInstanceDetailPagePropsType } from '../types';
import { ColumnType } from '@Types/v2/Table';
import TagTab from '../../TagTab';

const Fleet = (props: SpotFleetInstanceDetailPagePropsType) => {
  const {cloudId, region, currentSpotFleetInstance} = props;
  const volumeTabDetail = [...fleetTab];
  volumeTabDetail.shift();
  // @ts-ignore
  const [tabSelected, setTabSelected] = useState<IMgdTabProps>(volumeTabDetail[0]);

  const tagColumns = useMemo((): ColumnType[] => {
    return [
      {
        label: 'Key',
        field: 'key',
        sort: true,
      },
      {
        label: 'Value',
        field: 'value',
        sort: true,
      },
    ];
  }, []);

  const renderTab = () => {
    switch (tabSelected) {
      case volumeTabDetail[0]:
        return <TagTab title={'Tags'} rows={currentSpotFleetInstance?.tags} columns={tagColumns} />

      case volumeTabDetail[1]:
        return <Instances cloudId={cloudId} region={region} currentSpotFleetInstance={currentSpotFleetInstance} />;

      case volumeTabDetail[2]:
        return (
          <History
            cloudId={cloudId}
            region={region}
            currentSpotFleetInstance={currentSpotFleetInstance}
          />
        )

      default:
        return null
    }
  }
  return (
    <Fragment>
      <Description currentSpotFleetInstance={currentSpotFleetInstance} />

      <div className='ec2-detail-tab'>
        <div className="detail-tab flex a-center">
          {volumeTabDetail.map(tab => {
            return (
              <button
                className={`detail-tab-items ${tabSelected?.id === tab.id && 'active'}`}
                key={tab.id}
                data-tab={tab.id}
                onClick={e => setTabSelected(tab)}
              >
                <p>{tab.name}</p>
              </button>
            );
          })}
        </div>

        <div className="content-tab">
          {renderTab()}
        </div>
      </div>
    </Fragment>
  );
}

export default Fleet;