import { useLazyQuery } from '@apollo/client';
import query from './query';
import { AwsDescribeCertificateResponse } from 'graphql/types/AwsDescribeCertificate';

export interface IAwsDescribeCertificateVariables {
  cloudId: number;
  region: string;
  request: {
    certificateArn: string;
  }
}

export interface IAwsDescribeCertificateResponseData {
  getAwsDescribeCertificate: AwsDescribeCertificateResponse;
}

const lazyGetAwsDescribeCertificate = () => useLazyQuery<IAwsDescribeCertificateResponseData, IAwsDescribeCertificateVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsDescribeCertificate;