import React from 'react';

import './index.scss'

type FooterPropsType = {
  onCancel: () => void;
  onSubmit?: () => void;
};

function Footer({onCancel, onSubmit}: FooterPropsType) {
  return (
    <div className='footer-custom-rule-modal'>
      <button className='btn-default' onClick={onCancel}>Cancel</button>

      <button className='btn-primary' onClick={onSubmit}>Add Rule</button>
    </div>
  );
}

export default Footer;