import { gql } from '@apollo/client';

const query = gql`
query getAwsListRuleGroup($request: AwsListRuleGroupsRequest, $cloudId: Long, $region: String) {
  getAwsListRuleGroup(
    ListRuleGroupsRequest: $request
    cloudId: $cloudId
    region: $region
  ) {
    result
    data {
      ... on AwsListRuleGroupsResponse {
        nextMarker
        ruleGroups {
          name
          id
          description
          lockToken
          arn
        }
      }
    }
  }
}`;
export default query;