import { gql } from '@apollo/client';

const query = gql`
query getAwsListResourceTags($request: AwsListResourceTagsRequest, $cloudId: Long, $region: String) {
  getAwsListResourceTags(
    listResourceTagsRequest: $request
    cloudId: $cloudId
    region: $region
  ) {
    result
    data {
      ... on AwsListResourceTagsResponse {
        tags {
          key
          value
        }
        nextMarker
        truncated
      }
    }
  }
}`;
export default query;