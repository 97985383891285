import {gql} from '@apollo/client';

const query = gql`
query getAwsGroupQuery($request: AwsGetGroupQueryRequest, $cloudId: Long, $region: String) {
  getAwsGroupQuery(
    getGroupQueryRequest: $request
    cloudId: $cloudId
    region: $region
  ) {
    result
    data {
      ... on AwsGetGroupQueryResponse {
        groupQuery {
          groupName
          resourceQuery {
            type
            query
          }
        }
      }
    }
  }
}`;
export default query;