import ReactDOM from 'react-dom';
import React, { useState, ChangeEvent, useEffect } from 'react';
import { DropdownListDataType } from '../DropdownAtom';
import './index.scss';
import ArrowIcon from 'assets/svgs/v2/ico_dropdown_arrow.svg';

interface AutoCompleteDropdownProps {
  data: DropdownListDataType[];
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  error?: boolean;
  icon?: string;
  handleClick: (val: DropdownListDataType) => void;
  handleChange: (val: string) => void;
  handleAddClass: (val: boolean) => void;
}

const AutoCompleteDropdownAtom: React.FC<AutoCompleteDropdownProps> = ({
  className,
  data,
  placeholder,
  icon,
  handleClick,
  handleChange,
  handleAddClass
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [filteredData, setFilteredData] =
    useState<DropdownListDataType[]>(data);
  const [showData, setShowData] = useState<boolean>(false);
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setInputValue(value);
      handleChange(value);
      handleAddClass(false);
      if (value) {
        const filtered = data.filter(
          suggestion =>
            String(suggestion.value)
              .toLowerCase()
              .includes(value.toLowerCase())
        );
        setFilteredData(filtered);
        handleAddClass(filtered.length > 0);
        setShowData(filtered.length > 0);
      } else {
        setShowData(false);
      }
    };

  return (
    <div className="auto-completed-dropdown-atom">
       <div className={"input-region "+ className}>
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          placeholder={placeholder}
        />
        
        <img src={!!icon ? icon : ArrowIcon} width={24} height={24} />
      </div>

      <div className="dropdown-list">
        {showData && inputValue && (
          <ul className="no-scrollbar">
            {filteredData.map(data => (
              <li
                key={data.id}
                onClick={() => {
                  handleClick(data);
                     setInputValue(data.value as string);
                     setShowData(false);
                  
                }}>
                {data.value}
                {data.description && <><p className="text-description">{data.description}</p></>}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};
export default AutoCompleteDropdownAtom;
