import { useEffect, useRef, useState } from 'react';
import './index.scss';

import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import Labeled from 'components/v2/atoms/Labeled';
import InfoCard from 'pages/v2/Organ/Project/components/cards/InfoCard';
import Icon from 'components/v2/atoms/Icon';
import AuthenticationIcon from 'assets/svgs/v3/ico_authentication.svg';
import ScopeIcon from 'assets/svgs/v3/ico_scope.svg';
import AccountIcon from 'assets/svgs/v3/ico_account.svg';
import ExternalIcon from 'assets/svgs/v3/ico_external.svg';
import CheckboxAtom from 'components/v2/atoms/CheckboxAtom';
import { IFeatureProps, IConfigAccountProps, IValidateAssumeRoleProps, IEnumTypeCodeProps } from '@Types/v3/content';
import { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import lazyValidateAssumeRoleConnection from 'graphql/queries/validateAssumeRoleConnection';
import { ErrorCode } from '@Types/error';

interface IReviewAccountModalProps extends IBaseModalProps {
  features: IFeatureProps[];
  scope: string;
  configAccount: IConfigAccountProps;
  functionType: DropdownListDataType[];
  region: string[];
  msspScopeList: IEnumTypeCodeProps[];
  title: () => JSX.Element;
  onReview: () => void;
  onComplete: () => void;
}

const ReviewAccountModal = ({
  features,
  scope,
  configAccount,
  functionType,
  region,
  msspScopeList,
  title,
  onComplete,
  ...baseModalProps
}: IReviewAccountModalProps) => {
  const [payload, setPayload] = useState<IValidateAssumeRoleProps[]>([]);
  const [validateTime, setValidateTime] = useState<number>(0);
  const [status, setStatus] = useState<string>('Progressing');

  const timeoutRef = useRef(null);

  const [validateAssumeRoleConnection] = lazyValidateAssumeRoleConnection();

  const validateAssumeRole = (accountId: string, arn: string) => {
    if (accountId && region && configAccount.externalId && arn) {
      return validateAssumeRoleConnection({
        variables: {
          reqData: {
            externalId: configAccount.externalId,
            roleArn: arn,
            accountId: accountId,
            regionList: region,
          },
        },
      });
    }
  };

  const handleValidateAssumeRole = async () => {
    const arr = payload.map(el => {
      if (el.status !== 'Success') {
        return validateAssumeRole(el.accountId, el.arn);
      }
    });

    const result = await Promise.all(arr);

    const updatePayload: IValidateAssumeRoleProps[] = [...payload].map((el, index) => {
      const { data }: any = result[index];
      if (data && data.validateAssumeRoleConnection.result === ErrorCode.SUCCESS) {
        return {
          ...el,
          status: 'Success',
        };
      }

      return el;
    });

    const checkStatus = updatePayload.filter((el: IValidateAssumeRoleProps) => el.status === 'Success').length;
    if (checkStatus === updatePayload.length) {
      setStatus('Successful');
    } else if (checkStatus < updatePayload.length && checkStatus < 0) {
      setStatus('Partial failed');
    } else {
      setStatus('Failed');
    }
    setPayload(updatePayload);
    setValidateTime(validateTime + 1);
  };

  const validate = () => {
    // @ts-ignore
    timeoutRef.current = setTimeout(() => {
      handleValidateAssumeRole();
    }, 10000);
  };

  useEffect(() => {
    let newPayload: {
      status: string;
      accountId: string;
      arn: string;
    }[] = [];

    if (scope === 'organ000' && configAccount.members) {
      configAccount.members.map(member => {
        newPayload.push({
          status: 'Fail',
          accountId: member.id,
          arn: member.arn,
        });
      });
      setPayload(newPayload);
    } else {
      if (configAccount.accountId) {
        newPayload.push({
          status: 'Fail',
          accountId: configAccount.accountId,
          arn: configAccount.roleArn,
        });
        setPayload(newPayload);
      }
    }
  }, [baseModalProps.open, configAccount]);

  useEffect(() => {
    if (status !== 'Successful' && validateTime < 6) {
      // validating
      if (baseModalProps.open) {
        validate();
      }
    }

    return () => {
      if (timeoutRef?.current) clearTimeout(timeoutRef.current);
    };
  });

  const handleClearTimeout = () => {
    if (!timeoutRef.current) return;

    clearTimeout(timeoutRef.current);
  };

  const handleClearState = () => {
    setPayload([]);
    setValidateTime(0);
    setStatus('Progressing');
  };

  const handleOnPreview = () => {
    handleClearTimeout();
    handleClearState();
    baseModalProps.onReview();
  };

  const handleOnClose = () => {
    handleClearTimeout();
    handleClearState();
    // @ts-ignore
    baseModalProps.onClose();
  };

  const handleGetTextTransform = (value: string) => {
    const item = msspScopeList?.length ? msspScopeList.find(e => e?.value === value) : null;
    return item?.name || value;
  };

  return (
    <BaseModal {...baseModalProps} title={title} onClose={handleOnClose}>
      <div className="review-account-modal">
        <div className={`row-1 flex col`}>
          <Labeled title="Review status" required={true}>
            <div className="review-info">Review account details, security capabilities and permissions.</div>
          </Labeled>
          <div className="status-info">
            <InfoCard
              icon={<Icon width={32} height={32} src={AuthenticationIcon} />}
              title="Authentication"
              content={() => <>{status}</>}
            />
            <InfoCard
              icon={<Icon width={32} height={32} src={ScopeIcon} />}
              title="Scope"
              content={() => <>{handleGetTextTransform(scope)}</>}
            />
            {scope === 'organ000' ? (
              <>
                <InfoCard
                  icon={<Icon width={32} height={32} src={AccountIcon} />}
                  title="Account ID"
                  content={() => <></>}
                />
                <table className="revieww-status">
                  {configAccount.members?.map(member => (
                    <tr>
                      <td>{member.name}</td>
                      <td>{member.id}</td>
                      <td>{payload.find(el => el.accountId === member.id)?.status || 'Fail'}</td>
                    </tr>
                  ))}
                </table>
              </>
            ) : (
              <InfoCard
                icon={<Icon width={32} height={32} src={AccountIcon} />}
                title="Account ID"
                content={() => <>{configAccount.accountId}</>}
              />
            )}
            <InfoCard
              icon={<Icon width={32} height={32} src={ExternalIcon} />}
              title="externalId"
              content={() => <>{configAccount.externalId}</>}
            />
          </div>
          <Labeled title="Security capabilities and permissions" required={true} />
          {functionType.map(ft => (
            <label className="labeled">
              <CheckboxAtom disabled={true} checked={features.find(f => f.attrKey == ft.value)?.attrValue} />
              <ul>
                <li className="function-name">{ft.name}</li>
                <li className="function-desc">{ft.description}</li>
              </ul>
            </label>
          ))}
        </div>
        <div className="row-2">
          <div className="button-group">
            <button className="big-sub-btn" onClick={handleOnPreview}>
              Preview
            </button>
            <button
              className="big-sub-btn"
              disabled={status !== 'Failed'}
              onClick={() => {
                setValidateTime(0);
              }}
            >
              Retry
            </button>
            <button className="big-main-btn" disabled={status !== 'Successful'} onClick={onComplete}>
              Complete
            </button>
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default ReviewAccountModal;
