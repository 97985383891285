import {gql} from '@apollo/client';

const query = gql`
query getAwsDescribeKey($request: AwsDescribeKeyRequest, $cloudId: Long, $region: String) {
  getAwsDescribeKey(describeKeyRequest: $request, cloudId: $cloudId, region: $region) {
    result
     data{
      ... on AwsDescribeKeyResponse {
        keyMetadata{
          awsAccountId
          keyId
          arn
          creationDate
          enabled
          description
          keyUsage
          keyState
          deletionDate
          validTo
          origin
          customKeyStoreId
          cloudHsmClusterId
          expirationModel
          keyManager
          customerMasterKeySpec
          keySpec
          encryptionAlgorithms
          signingAlgorithms
          multiRegion
          multiRegionConfiguration {
            multiRegionKeyType
            primaryKey {
              arn
              region
            }
            replicaKeys {
              arn
              region
            }
          }
          pendingDeletionWindowInDays
          macAlgorithms
          xksKeyConfiguration {
            id
          }
        }
        }
      }
    }
  }
`;
export default query;