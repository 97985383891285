import { useLazyQuery } from '@apollo/client';
import { IGqlResponseData } from 'graphql/types';
import query from './query';
import { AwsDescribeResourceShareAssociationsResponse } from 'graphql/types/AwsResourceShareAssociations';

export interface IGetResourceShareAssociationsVariables {
  cloudId: number;
  region: string;
  request: {
    associationType?: string;
    resourceShareArns?: string[];
    resourceArn?: string;
    principal?: string;
    associationStatus?: string;
    nextToken?: string;
    maxResults?: number;
  }
}

export interface IGetResourceShareAssociationsResponseData {
  getResourceShareAssociations: IGqlResponseData<AwsDescribeResourceShareAssociationsResponse>;
}

const lazyIGetResourceShareAssociations = () => useLazyQuery<IGetResourceShareAssociationsResponseData, IGetResourceShareAssociationsVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyIGetResourceShareAssociations;