import React, { useMemo, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AddRulesAndRuleGroupsStepPropsType } from '../type';
import { Input, RadioButtonGroup, SectionContainer, SectionTitle, StepContainer } from '../components';
import DetailTable from 'pages/v2/Organ/Management/EC2/components/DetailTable';
import { DEFAULT_ACTION_RADIO_LIST, DefaultActionEnum, RULES_COLUMN_LIST } from '../configs';
import Button, { ButtonTypeEnum } from '../components/Button';
import SectionItem from '../components/SectionItem';
import './styles.scss';
import CheckboxAtom from 'components/v2/atoms/CheckboxAtom';
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import TextareaAtom from 'components/v2/atoms/TextareaAtom';
import JsonViewer from 'layouts/v3/MgdLayout/components/JsonViewer';
import JSONViewer from 'components/JSONViewer';
import CustomRuleModal from '../../../CustomRuleModal';
import { ButtonPropsType, ButtonType } from '../components/types';
import ManagedRuleModal from '../../../ManagedRuleModal';

const DEFAULT_DOMAIN = '';

const DEFAULT_HEADER = {
  key: '',
  value: '',
};

type DomainType = string;

type HeaderType = {
  key: string;
  value: string;
};

const SPECIFY_RESPONSE_BODY_DROPDOWN_LIST: Array<DropdownListDataType> = [{ value: '', name: '-' }];

const MAXIMUM_DOMAIN_ALLOWED = 10;

const AddRulesAndRuleGroupsStep = (props: AddRulesAndRuleGroupsStepPropsType) => {
  const { title, description, setCreationData, cloudId, region } = props;

  const [defaultAction, setDefaultAction] = useState<DefaultActionEnum>(DefaultActionEnum.ALLOW);
  const [domains, setDomains] = useState<Array<DomainType>>([DEFAULT_DOMAIN]);
  const [headers, setHeaders] = useState<Array<HeaderType>>([{ ...DEFAULT_HEADER }]);
  const [customResponse, setCustomResponse] = useState(false);
  const [responseCode, setResponseCode] = useState('');
  const [specifyResponseBody, setSpecifyResponseBody] = useState<DropdownListDataType>({ value: '' });
  const [isCustomRuleModalVisible, setCustomRuleModalVisible] = useState(false);
  const [isManagedRuleModalVisible, setManagedRuleModalVisible] = useState(false);
  const [addRuleDropdownValue, setAddRuleDropdownValue] = useState({ name: 'Add rule', value: '' });
  const [rules, setRules] = useState<any>([]);

  useEffect(() => {
    setCreationData({
      defaultAction,
      domains,
      headers,
      customResponse,
      responseCode,
      specifyResponseBody,
    });
  }, [defaultAction, domains, headers, customResponse, responseCode, specifyResponseBody]);

  const onRadioButtonGroupValueChanged = useCallback((value: string) => {
    setDefaultAction(value as DefaultActionEnum);
  }, []);

  const onDropdownValueChanged = useCallback((dropdown: DropdownListDataType) => {
    if (dropdown.value === 'managedRule') {
      setManagedRuleModalVisible(true);
    }

    if (dropdown.value === 'ownRule') {
      setCustomRuleModalVisible(true);
    }
  }, []);

  const rulesActionButtons: Array<ButtonType> = useMemo(() => {
    return [
      {
        id: 'edit',
        label: 'Edit',
        type: ButtonTypeEnum.GENERAL,
        onClick: () => {},
      },
      {
        id: 'delete',
        label: 'Delete',
        type: ButtonTypeEnum.PRIMARY,
        onClick: () => {},
      },
      {
        id: 'add',
        label: 'Add rule',
        type: ButtonTypeEnum.DROPDOWN,
        onClick: () => {},
        dropdownList: [
          { name: 'Add managed rule groups', value: 'managedRule' },
          { name: 'Add my own rules and rule groups', value: 'ownRule' },
        ],
        dropdownValue: addRuleDropdownValue,
        onDropdownValueChanged,
      },
    ];
  }, []);

  const handleAddNewCustomHeader = useCallback(() => {
    setHeaders([...headers, { ...DEFAULT_HEADER }]);
  }, [headers]);

  const handleRemoveCustomHeader = useCallback(
    (index: number) => {
      let newHeaders = [...headers];
      newHeaders.splice(index, 1);
      setHeaders(newHeaders);
    },
    [headers],
  );

  const handleCustomHeaderValueChanged = useCallback(
    (value: string, key: 'key' | 'value', index: number) => {
      let newHeaders = [...headers];
      newHeaders[index][key] = value;
      setHeaders(newHeaders);
    },
    [headers],
  );

  const handleAddTokenDomain = useCallback(() => {
    setDomains([...domains, DEFAULT_DOMAIN]);
  }, [domains]);

  const handleRemoveTokenDomain = useCallback(
    (index: number) => {
      let newDomains = [...domains];
      newDomains.splice(index, 1);
      setDomains(newDomains);
    },
    [domains],
  );

  const handleDomainValueChanged = useCallback(
    (value: string, index: number) => {
      let newDomains = [...domains];
      newDomains[index] = value;
      setDomains(newDomains);
    },
    [domains],
  );

  const handleAddRule = useCallback((rule: any) => {
    setCustomRuleModalVisible(false);

    setRules((prev: any) => [...prev, rule]);
  }, []);

  const headerNode = useMemo(() => {
    return (
      <>
        {headers.map((header, index) => (
          <div className="header-row-container">
            <Input
              title={'Key'}
              placeholder="Header name"
              value={header.key}
              onValueChanged={value => handleCustomHeaderValueChanged(value, 'key', index)}
              isVertical
              isRequired
            />
            <Input
              title={'Value'}
              placeholder="Header value"
              value={header.value}
              onValueChanged={value => handleCustomHeaderValueChanged(value, 'value', index)}
              isVertical
            />
            <Button label="Remove" onClick={() => handleRemoveCustomHeader(index)} />
          </div>
        ))}
        <Button label={'Add new custom header'} onClick={handleAddNewCustomHeader} type={ButtonTypeEnum.PRIMARY} />
      </>
    );
  }, [headers]);

  const domainNode = useMemo(() => {
    return (
      <>
        {domains.map((domain, index) => (
          <div className="header-row-container">
            <Input
              title={'Key'}
              placeholder="Enter domain"
              value={domain}
              onValueChanged={value => handleDomainValueChanged(value, index)}
              isVertical
              isRequired
            />
            <Button label="Remove" onClick={() => handleRemoveTokenDomain(index)} />
          </div>
        ))}
        {domains.length < MAXIMUM_DOMAIN_ALLOWED && (
          <Button label={'Add new custom header'} onClick={handleAddTokenDomain} type={ButtonTypeEnum.PRIMARY} />
        )}
      </>
    );
  }, [domains]);

  const responseNode = useMemo(() => {
    return (
      <>
        <Input
          title="Response code"
          onValueChanged={setResponseCode}
          value={responseCode}
          isVertical
          placeholder="Enter response code"
        />
        <SectionTitle
          title="Response headers"
          description="Specify the custom headers to be included in the custom response. The header key cannot be “content-type”."
          caption="- optional"
          customStyle="title-section-without-padding"
        />
        {headers.map((header, index) => (
          <div className="header-row-container">
            <Input
              title={'Key'}
              placeholder="Header name"
              value={header.key}
              onValueChanged={value => handleCustomHeaderValueChanged(value, 'key', index)}
              isVertical
              isRequired
            />
            <Input
              title={'Value'}
              placeholder="Header value"
              value={header.value}
              onValueChanged={value => handleCustomHeaderValueChanged(value, 'value', index)}
              isVertical
            />
            <Button label="Remove" onClick={() => handleRemoveCustomHeader(index)} />
          </div>
        ))}
        <Button label={'Add new custom header'} onClick={handleAddNewCustomHeader} type={ButtonTypeEnum.PRIMARY} />
        <SectionTitle
          title="Choose how you would like to specify the response body"
          description="Select an existing réponse body or create a new one. You can use a response body anywhere in the web ACL or rule group where you create it."
          caption="- optional"
          customStyle="title-section-without-padding"
        />
        <DropdownAtom
          id="specify-response-body-dropdown"
          placeholder="-"
          data={SPECIFY_RESPONSE_BODY_DROPDOWN_LIST}
          value={specifyResponseBody}
          handleClick={setSpecifyResponseBody}
        />
        <SectionTitle
          title="Response body"
          description="The response body can be plain text, HTML, or JSON."
          customStyle="title-section-without-padding"
        />
        <JSONViewer data={{ Error: 'access denied' }} bottomDescription="Response body cannot exceed 4 KB in size." />
      </>
    );
  }, [responseCode, headers]);

  const customRequestNode = useMemo(() => {
    if (defaultAction === 'allow') {
      return (
        <SectionItem.Collapsible
          title={'Custom request'}
          caption="- optional"
          description="With the Allow action, you can add custom headers to the web request. AWS WAF prefixes your custom header names with x-amzn-waf- when it inserts them."
        >
          <SectionItem.Container>{headerNode}</SectionItem.Container>
        </SectionItem.Collapsible>
      );
    }

    return (
      <SectionItem.Collapsible
        title={'Custom response'}
        caption="- optional"
        description="With the Block action, you can send a custom response to the web request."
      >
        <SectionItem.Container>
          <SectionItem.Checkbox
            label="Enable"
            checked={customResponse}
            onchange={() => setCustomResponse(!customResponse)}
          />
          {customResponse && responseNode}
        </SectionItem.Container>
      </SectionItem.Collapsible>
    );
  }, [defaultAction, customResponse, responseNode]);

  const ruleRows = useMemo(() => {
    return rules.map((rule: any) => {
      const { name, action } = rule;

      return {
        name: name,
        capacity: 1,
        action: Object.keys(action)[0],
      };
    });
  }, [rules]);

  return (
    <StepContainer title={title} description={description}>
      <DetailTable
        title={'Rules'}
        description={
          'If a request matches a rule, take the corresponding action. The rules are prioritized in order they appear.'
        }
        columns={RULES_COLUMN_LIST}
        data={ruleRows}
        rightButtons={rulesActionButtons}
        reportCheckedList={() => {}}
      />

      <SectionContainer
        title="Web ACL capacity units (WCUs) used by your web ACL"
        description="The WCUs used by the web ACL will be less than or equal to the sum of the capacities for all of the rules in the web ACL."
      >
        <SectionItem.Container title={'Default web ACL action for requests that don’t match any rules'}>
          <SectionItem.StyledText text={'125/5000 WCUs'} />
        </SectionItem.Container>
      </SectionContainer>

      <SectionContainer title="Default web ACL action for requests that don’t match any rules">
        <SectionTitle title={'Default action'} />
        <RadioButtonGroup
          data={DEFAULT_ACTION_RADIO_LIST}
          value={defaultAction}
          onChangeValue={onRadioButtonGroupValueChanged}
        />
        {customRequestNode}
      </SectionContainer>

      {defaultAction === 'allow' && (
        <SectionContainer
          title="Token domain list"
          description="Enable the use of tokens across multiple protected applications by entering the application domains here. Tokens are used by the Challenge and CAPTCHA rule actions, the application integration SDKs, and the ATP and Bot Control managed rule groups."
          caption="- optional"
          bottomDescription={`You can add ${MAXIMUM_DOMAIN_ALLOWED - domains.length} more domains.`}
        >
          <SectionItem.Container>{domainNode}</SectionItem.Container>
        </SectionContainer>
      )}

      <CustomRuleModal
        isOpen={isCustomRuleModalVisible}
        onClose={() => setCustomRuleModalVisible(false)}
        cloudId={cloudId}
        selectedRegion={region}
        onAddRule={handleAddRule}
      />

      <ManagedRuleModal isVisible={isManagedRuleModalVisible} onClose={() => setManagedRuleModalVisible(false)} />
    </StepContainer>
  );
};

AddRulesAndRuleGroupsStep.propTypes = {};

export default AddRulesAndRuleGroupsStep;
