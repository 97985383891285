import { useState, useMemo, useEffect } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import IconEditFirewallDetail from 'assets/svgs/v3/ico_edit_firewall_detail.svg';
import Icon from 'components/v2/atoms/Icon';
import './index.scss';
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import CheckboxAtom from 'components/v2/atoms/CheckboxAtom';

interface IProtectionAgainstChangeProps extends IBaseModalProps {
  header: string;
  onChangeValue?: (index: number, value: string) => void;
}

const ProtectionAgainstChangeModal = ({
  header,
  onChangeValue,
  ...baseModalProps
}: IProtectionAgainstChangeProps) => {
  const [payload, setPayload] = useState<string>('');

  useEffect(() => {
    if (baseModalProps.open) {
      setPayload('');
    }
  }, [baseModalProps.open]);

  return (
    <BaseModal
      title={() => <><Icon width={32} height={32} src={IconEditFirewallDetail} />{header}</>}
      {...baseModalProps}>
      <div className="protection-change-modal">
        <div className="protection-change">
          <div className="delete-protection">
            <p className="text-title">Delete protection</p>
            <p className="text-description">Protects the firewall from deletion. If enabled, Network firewall won’t delete the firewall if it’s in use.</p>
            <label className="labeled">
              <CheckboxAtom id='checkbox-protection'
                checked={true}
                onchange={() => { }
                }
              />
              Enable
            </label>
          </div>

          <div className="delete-protection">
            <p className="text-title">Subnet change protection</p>
            <p className="text-description">Protects the firewall against changes to the subnet associations. If enabled, you can’t change in active firewall’s subnet associations</p>
            <label className="labeled">
              <CheckboxAtom id="checkbox-protection"
                checked={true}
                onchange={() => { }
                }
              />
              Enable
            </label>
          </div>
        </div>
        <div className="button-group">
          <button onClick={baseModalProps.onClose}>Cancel</button>
          <button className="delete-btn" onClick={() => { }}>
            Save
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default ProtectionAgainstChangeModal;
