import Description from 'pages/v2/Organ/Management/EC2/components/SpotRequest/Common/Components/Instance/Description';
import TagTab from '../../TagTab';
import { SpotInstanceDetailPagePropsType } from '../types';
import { ColumnType } from '@Types/v2/Table';
import { Fragment, useMemo } from 'react';
import './index.scss';

const Instance = (props: SpotInstanceDetailPagePropsType) => {
  const { currentSpotInstance } = props;
  const tagColumns = useMemo((): ColumnType[] => {
    return [
      {
        label: 'Key',
        field: 'key',
        sort: true,
      },
      {
        label: 'Value',
        field: 'value',
        sort: true,
      },
    ];
  }, []);
  return (
    <Fragment>
      <Description currentSpotInstance={currentSpotInstance} />
      <div className="instance-tag">
        <TagTab
          title={'Tags'}
          description={'Apply tags to your resources to help organize and identify them.'}
          rows={currentSpotInstance?.tags}
          columns={tagColumns}
        />
      </div>
    </Fragment>
  );
};

export default Instance;
