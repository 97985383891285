import { ColumnType, RowType } from '@Types/v2/Table';
import Table from 'components/v2/dataDisplay/Table';
import { AwsWebACL } from 'graphql/types/AwsWebACL';
import { useEffect, useMemo, useState } from 'react';
import CustomResponseModal from '../../../CustomResponseModal';
import { CONTENT_TYPE_MAPPING, CUSTOME_RESPONSE_BODY_COLUMNS } from '../../../Commons/Constant';

interface ICustomResponseProps {
  customResponseBodyRows: RowType[];
  onSelectedCustomReponseBody: (rowId: string) => void;
  onEditResponseBody: () => void;
}

const CustomResponse = (props: ICustomResponseProps) => {
  const {customResponseBodyRows, onSelectedCustomReponseBody, onEditResponseBody} = props;

  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [selected, setSelected] = useState<string>('');

  const [isCreateCustomResponseOpened, setIsCreateCustomResponseOpened] = useState<boolean>(false);

  const updateBtnDisabled = useMemo((): boolean => {
    return selected === '' || checkedList.length !== 1;
  }, [selected, checkedList]);

  return (
    <>
      <div className="resize-container vertical">
        <div className="details">
          <div className="row-3">
            <div className="title flex j-between a-center">
              <div>
                <p>Custom response bodies ({customResponseBodyRows.length || 0})</p>
                <p>
                  Custom response bodies are plain text, JSON, or HTML content that you use to customize a response to
                  blocked requests. You can use a response body anywhere in the web ACL where you create it.
                </p>
              </div>
              <div className="flex action a-center">
                <button className="action-btn" onClick={() => {}} disabled={updateBtnDisabled}>
                  Delete
                </button>
                <button className="edit-btn" onClick={() => onEditResponseBody()} disabled={updateBtnDisabled}>
                  Edit
                </button>
                <button className="action-btn" onClick={() => setIsCreateCustomResponseOpened(true)} disabled={false}>
                  Create custom response body
                </button>
              </div>
            </div>
          </div>
          {customResponseBodyRows.length == 0 ? (
            <div className="data-grid-wrap">
              <p className="empty-row">Empty</p>
            </div>
          ) : (
            <div className="data-grid-wrap">
              <Table
                rows={customResponseBodyRows}
                columns={CUSTOME_RESPONSE_BODY_COLUMNS}
                reportCheckedList={(list: string[]) => {
                  setCheckedList(list);
                  if (list.length > 0) {
                    setSelected(list[list.length - 1]);
                    onSelectedCustomReponseBody(list[list.length - 1]);
                  }
                }}
                reportSelected={(id: string) => {
                  setSelected(id);
                  setCheckedList([id]);
                  onSelectedCustomReponseBody(id);
                }}
                horizontalScrollable={true}
              />
            </div>
          )}
        </div>
      </div>

      <CustomResponseModal
        header={'Create custom response body'}
        open={isCreateCustomResponseOpened}
        onClose={() => setIsCreateCustomResponseOpened(false)}
        name={''}
        contentType={CONTENT_TYPE_MAPPING['APPLICATION_JSON'].value}
        responseBody={''}
        onSave={() => {}}
        />
    </>
  );
};
export default CustomResponse;
