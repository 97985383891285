import { useLazyQuery } from '@apollo/client';
import query from './query';
import { Paging } from 'graphql/types/Paging';
import { AwsFirewallMetadata } from 'graphql/types/AwsListFirewalls';
import { IGqlResponseNewData } from 'graphql/types';

export interface IAwsAllListFirewallsPageVariables {
  cloudId: number;
  region: string;
  request: {
    maxResults: number;
    nextToken?: string;
    vpcIds?: string[];
    dryRun?: boolean;
    filters?: {
      name: string;
      values: string[];
    };
    pageInfo?: Paging;
  };
}

export interface IAwsAllListFirewallsPageResponseData {
  getAwsAllListFirewalls: IGqlResponseNewData<AwsFirewallMetadata>;
}

const lazyGetAwsAllListFirewallsPage = () =>
  useLazyQuery<IAwsAllListFirewallsPageResponseData, IAwsAllListFirewallsPageVariables>(query, {
    fetchPolicy: 'no-cache',
  });
export default lazyGetAwsAllListFirewallsPage;
