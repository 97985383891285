import { TextTypeEnum } from "layouts/v3/MgdLayout";

export interface ITextDescription {
  id: string; value: string; type: string;
};

export const LOAD_BALANCER_SUMMARY_TAB_LIST = [
  { id: 'detail', name: 'Detail', title: 'Detail' },
  { id: 'listeners', name: 'Listeners', title: 'Listeners' },
  { id: 'networkMapping', name: 'Network mapping', title: 'Network mapping' },
  { id: 'security', name: 'Security', title: 'Security' },
  { id: 'attributes', name: 'Attributes', title: 'Attributes' },
  { id: 'tags', name: 'Tags', title: 'Tags' },
];

export const LOAD_BALANCER_DETAIL_TAB_LIST = [
  { id: 'listeners', name: 'Listeners', title: 'Listeners' },
  { id: 'networkMapping', name: 'Network mapping', title: 'Network mapping' },
  { id: 'security', name: 'Security', title: 'Security' },
  { id: 'attributes', name: 'Attributes', title: 'Attributes' },
  { id: 'tags', name: 'Tags', title: 'Tags' },
];

export const detailTabKeys = [
  'type',
  'status',
  'vpcId',
  'ipAddressType',
  'scheme',
  'canonicalHostedZoneId',
  'availabilityZones',
  'createdTime',
  'loadBalancerArn',
  'dnsName',
];

export const detailTabTextDes: ITextDescription[] = [
  { id: 'type', value: 'Load balancer type', type: TextTypeEnum.NORMAL },
  { id: 'status', value: 'Status', type: TextTypeEnum.NORMAL },
  { id: 'vpcId', value: 'VPC', type: TextTypeEnum.NORMAL },
  { id: 'ipAddressType', value: 'IP address type', type: TextTypeEnum.NORMAL },
  { id: 'scheme', value: 'Sheme', type: TextTypeEnum.NORMAL },
  { id: 'canonicalHostedZoneId', value: 'Hosted zone', type: TextTypeEnum.NORMAL },
  { id: 'availabilityZones', value: 'Availability zones', type: TextTypeEnum.LINK },
  { id: 'createdTime', value: 'Date created', type: TextTypeEnum.NORMAL },
  { id: 'loadBalancerArn', value: 'Load balancer ARN', type: TextTypeEnum.COPY },
  { id: 'dnsName', value: 'DNS name', type: TextTypeEnum.COPY },
];

export const attributeTabTrafficConfigKeys = [
  'routing.http.x_amzn_tls_version_and_cipher_suite.enabled',
  'routing.http2.enabled',
  'waf.fail_open.enabled',
  'idle_timeout.timeout_seconds',
  'client_keep_alive.seconds',
];

export const attributeTabTrafficConfigTextDes: ITextDescription[] = [
  { id: 'routing.http.x_amzn_tls_version_and_cipher_suite.enabled', value: 'TLS version and cipher headers', type: TextTypeEnum.NORMAL },
  { id: 'routing.http2.enabled', value: 'HTTP/2', type: TextTypeEnum.NORMAL },
  { id: 'waf.fail_open.enabled', value: 'WAF fall open', type: TextTypeEnum.NORMAL },
  { id: 'idle_timeout.timeout_seconds', value: 'Connection idle timeout', type: TextTypeEnum.NORMAL },
  { id: 'client_keep_alive.seconds', value: 'HTTP client keep alive duration', type: TextTypeEnum.NORMAL },
];

export const attributeTabPackHandlingKeys = [
  'routing.http.desync_mitigation_mode',
  'routing.http.drop_invalid_header_fields.enabled',
  'routing.http.xff_header_processing.mode',
  'routing.http.xff_client_port.enabled',
  'routing.http.preserve_host_header.enabled',
];

export const attributeTabPackHandlingTextDes: ITextDescription[] = [
  { id: 'routing.http.desync_mitigation_mode', value: 'Desunc mitigation mode', type: TextTypeEnum.NORMAL },
  { id: 'routing.http.drop_invalid_header_fields.enabled', value: 'Drop invalid header fields', type: TextTypeEnum.NORMAL },
  { id: 'routing.http.xff_header_processing.mode', value: 'X-forwarded for header', type: TextTypeEnum.NORMAL },
  { id: 'routing.http.xff_client_port.enabled', value: 'Client port preservation', type: TextTypeEnum.NORMAL },
  { id: 'routing.http.preserve_host_header.enabled', value: 'Preserve host header', type: TextTypeEnum.NORMAL },
];

export const attributeTabAZRoutingConfigKeys = [
  'load_balancing.cross_zone.enabled',
];

export const attributeTabAZRoutingConfigTextDes: ITextDescription[] = [
  { id: 'load_balancing.cross_zone.enabled', value: 'Cross-zone load balancing', type: TextTypeEnum.NORMAL },
];

export const attributeTabMonitoringKeys = [
  'access_logs.s3.enabled',
  'connection_logs.s3.enabled',
];

export const attributeTabMonitoringTextDes: ITextDescription[] = [
  { id: 'access_logs.s3.enabled', value: 'Access logs', type: TextTypeEnum.NORMAL },
  { id: 'connection_logs.s3.enabled', value: 'Connection logs', type: TextTypeEnum.NORMAL },
];

export const attributeTabProtectionKeys = [
  'deletion_protection.enabled',
];

export const attributeTabProtectionTextDes: ITextDescription[] = [
  { id: 'deletion_protection.enabled', value: 'Deletion protection', type: TextTypeEnum.NORMAL },
];