import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from '../../types';

export interface IDeleteRegexPatternSetVariables {
  cloudId: number;
  region: string;
  reqData: {
    name: string;
    scope: string;
    id: string;
    lockToken: string;
  }
}

export interface IDeleteRegexPatternSetResponseData {
  deleteRegexPatternSet: IGqlResponseNewData<undefined>;
}

const deleteRegexPatternSetMutation = () => useMutation<IDeleteRegexPatternSetResponseData, IDeleteRegexPatternSetVariables>(query);
export default deleteRegexPatternSetMutation;