import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsDescribeInstanceType } from 'graphql/types/AwsDescribeInstance';

export type AwsAllDescribeInstancesRequestType = {
  cloudId: number;
  region: string;
  request: {
    instanceIds?: Array<string>;
    maxResults?: number;
    nextToken?: string;
    AwsAllDescribeInstancesType?: Array<string>;
    dryRun?: boolean;
    filters?: {
      name: string;
      values: Array<string>;
    }
  }
}

export interface AwsAllDescribeInstancesResponseType {
  getAwsAllDescribeInstances: IGqlResponseNewData<AwsDescribeInstanceType>;
}

const lazyGetAllAwsDescribeInstances = () => useLazyQuery<AwsAllDescribeInstancesResponseType, AwsAllDescribeInstancesRequestType>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAllAwsDescribeInstances;