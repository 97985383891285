import { gql } from '@apollo/client';

const query = gql`
mutation updateAwsRuleGroup($reqData: AwsUpdateRuleGroupRequest, $cloudId: Long, $region: String) {
  updateAwsRuleGroup(updateRuleGroupRequest: $reqData, cloudId: $cloudId, region: $region) {
    result
    data {
      ... on AwsUpdateRuleGroupResponse {
        nextLockToken
      }
    }
  }
}
`;
export default query;