import { useLazyQuery } from '@apollo/client';
import { IGqlResponseNewData } from 'graphql/types';
import query from './query';
import { AwsDescribeSecurityGroupsResponse } from 'graphql/types/AwsSecurityGroup';
import { Paging } from 'graphql/types/Paging';

export interface IGetAwsSecurityGroupsVariables {
  cloudId: number;
  region: string;
  request: {
    maxResults: number;
    nextToken?: string;
    filters?: {
      name: string;
      values: string[]
    };
    dryRun?: boolean;
    pageInfo?: Paging;
  }
}

export interface IGetAwsSecurityGroupsResponseData {
  getAwsSecurityGroups: IGqlResponseNewData<AwsDescribeSecurityGroupsResponse>;
}

const lazyGetAwsSecurityGroups = () => useLazyQuery<IGetAwsSecurityGroupsResponseData, IGetAwsSecurityGroupsVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsSecurityGroups;