import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {OrderDirection, RowType} from "@Types/v2/Table";
import {IMgdTablePaginationProps, IMgdTotalPageProps} from "layouts/v3/MgdLayout";
import DropdownAtom, {DropdownListDataType} from "components/v2/atoms/DropdownAtom";
import {variableCombineNextToken} from "pages/v2/Organ/Management/Utils";
import InputAtom from "components/v2/atoms/InputAtom";
import SearchIcon from "assets/svgs/v3/ico_search.svg";
import Table from "components/v2/dataDisplay/Table";
import TableManagePagination from "components/v2/dataDisplay/TableManagePagination";
import lazyGetAwsAllDescribeVolumes from "graphql/queries/getAwsAllDescribeVolumes";
import lazyGetAwsListAliases from "graphql/queries/getAwsListAliases";
import lazyGetAwsDescribeVolumeStatus from "graphql/queries/getAwsDescribeVolumeStatus";
import {ebsVolumeFilterDropdown} from "pages/v2/Organ/Management/EC2/components/EBSVolume/Common/Constant";
import {FilterPropsType} from "pages/v2/Organ/Management/EC2/components/Image/types";
import {orderAlphabetical} from "pages/v2/Organ/Management/Utils/Sorting";
import { handleFormatText } from 'utils/Common';

const EBSVolume = ({cloudId, region, setDetailItem, setPageDetail}: any) => {
  const [mainIsLoading, setMainIsLoading] = useState<boolean>(false);
  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [mainTblRows, setMainTblRows] = useState<RowType[]>([]);
  const [mainTablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });
  const [mainNextToken, setMainNextToken] = useState<string>('');

  const [mainFilterValue, setMainFilterValue] = useState<DropdownListDataType>(ebsVolumeFilterDropdown[0]);
  const [searchValue, setSearchValue] = useState<FilterPropsType>({
    name: mainFilterValue.value as string,
    value: '',
  });
  const [scopeNFilterVariable, setScopeNFilterVariable] = useState<FilterPropsType>();

  const handleGetNameTag = (data: any) => {
    if (!data?.length) return '';

    const item = data.find((e: any) => e.key === 'Name')

    return item?.value || '';
  }

  const columns: any = [
    {
      label: 'Name',
      field: 'name',
      sort: true,
    },
    {
      label: 'Volume ID',
      field: 'volumeId',
      sort: true,
      renderCell: (row: RowType) => (
        <>
          {
            <div
              className="link"
              onClick={e => {
                e.stopPropagation();
                setPageDetail(row);
              }}
            >
              {handleFormatText(row?.volumeId)}
            </div>
          }
        </>
      ),
    },
    {
      label: 'Type',
      field: 'volumeType',
      sort: true,
    },
    {
      label: 'Size',
      field: 'size',
      sort: true,
    },
    {
      label: 'IOPS',
      field: 'iops',
      sort: true,
    },
    {
      label: 'Throughput',
      field: 'throughput',
      sort: true,
    },
    {
      label: 'Snapshot',
      field: 'snapshotId',
      sort: true,
    },
    {
      label: 'Created',
      field: 'createTime',
      sort: true,
    },
    {
      label: 'Availability',
      field: 'availabilityZone',
      sort: true,
    },
    {
      label: 'Volume state',
      field: 'state',
      sort: true,
    },
    {
      label: 'Attached resources',
      field: 'attachedResources',
      sort: true,
    },
    {
      label: 'Volume status',
      field: 'volumeStatus',
      sort: true,
    },
    {
      label: 'Encryption',
      field: 'encryption',
      sort: true,
    },
    {
      label: 'KMS key ID',
      field: 'kmsKeyId',
      sort: true,
    },
    {
      label: 'KMS key alias',
      field: 'kmsKeyAlias',
      sort: true,
    },
    {
      label: 'Fast snapshot restored',
      field: 'fastSnapshotRestored',
      sort: true,
    },
    {
      label: 'Multi-Attach enabled',
      field: 'multiAttachEnabled',
      sort: true,
    },
  ];

  const [getAwsAllDescribeVolumes] = lazyGetAwsAllDescribeVolumes();
  const [getAwsDescribeVolumeStatus] = lazyGetAwsDescribeVolumeStatus();
  const [getAwsListAliases] = lazyGetAwsListAliases();

  const awsDescribeVariable = useMemo((): {
    request: { maxResults: number; filters: {}[] };
    cloudId: string;
    region: string
  } => {
    // @ts-ignore
    let searchFilterItem = {};

    if (scopeNFilterVariable) {
      searchFilterItem = {
        name: scopeNFilterVariable?.name,
        values: [scopeNFilterVariable?.value]
      }
    }

    return {
      cloudId,
      region,
      request: {
        maxResults: mainTablePagination.limit,
        filters: [{ ...searchFilterItem }]
      },
    };
  }, [cloudId, region, scopeNFilterVariable]);

  const handleGetAwsAllDescribeVolumes = useCallback(async (nextToken?: string) => {
    return getAwsAllDescribeVolumes({
      variables: variableCombineNextToken(awsDescribeVariable, nextToken),
    })

  }, [awsDescribeVariable]);

  const handleGetAwsDescribeVolumeStatus = async (volumeIds: string) => {
    if (!volumeIds) return {};

    const payload = {
      cloudId,
      region,
      request: {
        volumeIds,
      }
    }

    return getAwsDescribeVolumeStatus({
      variables: variableCombineNextToken(payload),
    })

  };

  const handleGetAwsListAliases = async (keyId: string) => {
    if (!keyId) return {};

    const payload = {
      cloudId,
      region,
      request: {
        keyId,
      }
    }

    return getAwsListAliases({
      variables: variableCombineNextToken(payload),
    })
  };

  const handleGetData = useCallback(async (nextToken?: string, isInitial?: boolean) => {
    try {
      setMainIsLoading(true)
      const res: any = await handleGetAwsAllDescribeVolumes(nextToken)
      setMainNextToken(res?.nextToken as string);

      const arrRes = res?.data?.getAwsAllDescribeVolumes?.data?.length
        ? (
          await Promise.all(res.data.getAwsAllDescribeVolumes.data.map(async (e: any) => {
            const [resAwsDescribeVolumeStatus, resAwsListAliases]: any = await Promise.all([handleGetAwsDescribeVolumeStatus(e.volumeId), handleGetAwsListAliases(e.kmsKeyId)])
            const attachedResources = e.attachments?.[0];

            return {
              ...e,
              id: (Math.random() + 1).toString(36).substring(2, 8),
              name: handleGetNameTag(e?.tags),
              attachedResources: `${attachedResources?.instanceId}:${attachedResources?.device}(${attachedResources?.state})`,
              volumeStatus: resAwsDescribeVolumeStatus?.data?.getAwsDescribeVolumeStatus?.data?.[0]?.volumeStatuses?.volumeStatus?.status ? 'Okay' : '...',
              encryption: e.encryption ? 'Encryption' : 'Not encrypted',
              kmsKeyAlias: resAwsListAliases?.data?.getAwsListAliases?.data?.[0]?.aliases?.[0]?.aliasName?.replace('alias/', '') || '',
              fastSnapshotRestored: e.fastRestored ? 'Yes' : 'No',
              multiAttachEnabled: e.multiAttachEnabled ? 'Yes' : 'No',
            }
          }))
        )
        : [];

      let allRows: RowType[] = [];
      if (isInitial) {
        allRows = arrRes;
      } else {
        allRows = [...mainTblRows, ...arrRes];
      }

      setMainTblTotal({
        totalPage: Math.ceil(allRows.length / mainTablePagination.itemPerPage),
        totalElement: allRows.length,
      });
      setMainTblRows(allRows);
      setMainIsLoading(false)
    } catch (e) {
      setMainIsLoading(false)
    }
  }, [awsDescribeVariable, mainTblRows, mainNextToken, mainTblTotal, mainTablePagination])

  const mainRowsCurrentPage = useMemo(() => {
    if (mainTablePagination.currentPage > mainTblTotal.totalPage && !!mainNextToken) {
      handleGetData(mainNextToken);
    }

    const startIndex = (mainTablePagination.currentPage - 1) * mainTablePagination.itemPerPage;
    const endIndex = startIndex + mainTablePagination.itemPerPage;

    return orderAlphabetical(mainTblRows, mainTablePagination.target, mainTablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [mainTblRows, mainTblTotal, mainTablePagination, mainNextToken, searchValue]);

  useEffect(() => {
    handleGetData('', true)
  }, [awsDescribeVariable]);

  const handleSearch = useCallback(() => {
    if (searchValue?.value) {
      setScopeNFilterVariable(searchValue);
      return;
    }

    setScopeNFilterVariable(undefined);
  }, [searchValue]);

  const handleSelectItem = (id: string) => {
    const item = mainTblRows.find((e) => e.id === id)

    if (!item) return;

    setDetailItem(item)
  }

  const updateTablePagination = (key:string, value: number | string | OrderDirection) => {
    setMainTablePagination(prev => ({
      ...prev,
      [key]: value
    }))
  }

  return (
    <>
      <div className="row-3 flex j-between a-center">
        <div className="title">
          <p>Elastic Block store volumes</p>
        </div>

        <div className="flex action a-center">
          <DropdownAtom
            id="types-dropdown"
            className=""
            data={ebsVolumeFilterDropdown}
            value={mainFilterValue}
            handleClick={(val) => {
              setMainFilterValue(val);
              setSearchValue(prev => ({ ...prev, name: val.value as string }));
            }}
          />

          <InputAtom
            type={'text'}
            value={searchValue.value}
            defaultValue={''}
            onChangeValue={(e) => setSearchValue(prev => ({ name: prev?.name, value: e }))}
            hasPrefixIcon={true}
            srcPrefixIcon={SearchIcon}
            prefixIconOnClick={() => {
            }}
          />

          <button className="action-btn" onClick={handleSearch}>Search</button>
        </div>
      </div>

      {!mainRowsCurrentPage.length && !mainIsLoading
        ?
        <div className="data-grid-wrap">
          <p className="empty-row">Empty</p>
        </div>
        :
        <div className="data-grid-wrap">
          <Table
            rows={mainRowsCurrentPage}
            columns={columns}
            reportCheckedList={() => {}}
            reportSelected={(id) => handleSelectItem(id)}
            sortOption={{
              target: mainTablePagination.target,
              direction: mainTablePagination.direction,
              onChangeSort: (target: string, dir: OrderDirection) => {
                updateTablePagination('target', target);
                updateTablePagination('direction', dir);
              }
            }}
            isLoading={mainIsLoading}
            horizontalScrollable={true}
          />

          {mainRowsCurrentPage?.length && !mainIsLoading ?
            (
              <div className="pagination-wrapper flex a-center">
                <p className="flex a-center">Total <span>{mainTblTotal.totalElement}</span></p>
                <TableManagePagination
                  ableFetchMore={!!mainNextToken}
                  currentPage={mainTablePagination.currentPage}
                  updateCurrentPage={(page) => setMainTablePagination(prev => ({
                    ...prev,
                    ['currentPage']: page,
                  }))}
                  totalPage={mainTblTotal.totalPage}
                />
              </div>
            ) : null
          }
        </div>
      }
    </>
  );
};

export default EBSVolume;
