import { IMgdTabProps } from "layouts/v3/MgdLayout";
import { ITabProps } from "../../RuleGroup/RuleGroupDetail";
import { RadioButtonType } from "../../WebACL/CreateWebACL/components/types";
import { ColumnType } from "@Types/v2/Table";

export enum MgdWafPageEnum {
  MGD_LIST = 'MGD_WAF_LIST',
  MGD_CREATE = 'MGD_WAF_CREATE',
  MGD_WEB_DETAIL = 'MGD_WEB_ACL_DETAIL',
  MGD_CREATE_IP = 'MGD_CREATE_IP',
  MGD_IP_DETAIL = 'MGD_IP_SET_DETAIL',
  MGD_REGEX_PATTERN_DETAIL = 'MGD_REGEX_PATTERN_DETAIL',
  MGD_RULE_GROUP_DETAIL = 'MGD_RULE_GROUP_DETAIL',
  MGD_CREATE_PREVIEW = 'MGD_CREATE_PREVIEW',
  MGD_CREATE_REGEX_PATTERN = 'MGD_CREATE_REGEX_PATTERN',
};

export const CLOUDFRONT_OPTION = {
  name: 'Global (CloudFront)',
  value: 'CloudFront',
};

export enum WafScopeEnum {
  CLOUDFRONT = 'CLOUDFRONT',
  REGIONAL = 'REGIONAL',
}

export const CLOUDFRONT_REGION = 'us-east-1';

export const wafTabs: IMgdTabProps[] = [
  {
    id: 'webAcl',
    name: 'Web ACL',
    title: 'Web ACLs'
  },
  {
    id: 'ipsets',
    name: 'IPSets',
    title: 'IPSets'
  },
  {
    id: 'regexPatternSet',
    name: 'Regex patten set',
    title: 'Regex patten set'
  },
  {
    id: 'ruleGroups',
    name: 'Rule groups',
    title: 'Rule groups'
  }
];

export const RESOURCE_TYPE_DATA = [
  {
    id: 'aws-cloudfront',
    value: 'aws-cloudfront',
    name: 'resource-type',
    label: 'Amazon CLoudFront distributions'
  },
  {
    id: 'region-resource',
    value: 'region-resource',
    name: 'resource-type',
    label: 'Regional resources (Application Load Balancers, Amazon API Gateway REST APIs)'
  }
];

export const AWS_RESOURCE_REGIONAL_TYPE_DATA = [
  {
    id: 'application',
    value: 'application',
    name: 'aws-resource-type',
    label: 'Application Load Balancer'
  },
  {
    id: 'manuallyArnInput',
    value: 'manuallyArnInput',
    name: 'aws-resource-type',
    label: 'Manually ARN input'
  },
];

export const AWS_RESOURCE_CLOUDFRONT_TYPE_DATA = [
  {
    id: 'cloudFrontDistribution',
    value: 'cloudFrontDistribution',
    name: 'cloud-front-distribution',
    label: 'CloudFront Distribution'
  },
];

export type AwsArnData = {
  id: string;
  service?: string;
  region?: string;
  accountId?: string;
  resourceType?: string;
  resourceName?: string;
}

export const getRegionFromArnsData = (arn: string | undefined) => {
  if(arn){
    const arnComponents = arn?.split(':');
    return arnComponents[3] || '';
  }
  return '';
}

export const ruleGroupDetailTabs: ITabProps[] = [
  {
    id: 'info',
    name: 'Overview',
    title: 'Overview'
  },
  {
    id: 'customResponseBodies',
    name: 'Custom response bodies',
    title: 'Custom response bodies'
  }
];

export const IP_VERSION_DATA: RadioButtonType[] = [
  {
    id: 'ipV4',
    value: 'IPV4',
    name: 'ip-vesrion',
    label: 'IPV4'
  },
  {
    id: 'ipV6',
    value: 'IPV6',
    name: 'ip-vesrion',
    label: 'IPv6'
  }
];

export const CUSTOME_RESPONSE_BODY_COLUMNS: ColumnType[] = [
  {
    label: 'Name',
    field: 'name',
  },
  {
    label: 'Content type',
    field: 'cellLableContentType',
  },
];

export interface IContentTypeContentProps {
  radioLable: string;
  cellLable: string;
  value: string;
}
export interface IContentTypeProps {
  [key: string]: IContentTypeContentProps;
}
export const CONTENT_TYPE_MAPPING: IContentTypeProps = {
  'APPLICATION_JSON': {radioLable: 'JSON', cellLable: 'application/json', value: 'APPLICATION_JSON'},
  'TEXT_HTML': {radioLable: 'HTML', cellLable: 'text/html', value: 'TEXT_HTML'},
  'TEXT_PLAIN': {radioLable: 'Plain text', cellLable: 'text/plain', value: 'TEXT_PLAIN'},
  '': {radioLable: '',  cellLable: '', value: ''}
};