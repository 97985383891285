import { PropsWithChildren, useEffect, useState } from 'react';
import './index.scss';

import { ResizableBox } from 'react-resizable';
import Handle from 'assets/svgs/v2/aside_resize_handle.svg';

const ChatBotAsideLnb = ({ children }:PropsWithChildren) => {

  const [asideWidth, setAsideWidth] = useState<number>(Number(localStorage.getItem('chatBotNavWidth')) || 192);
  const [asideHeight, setAsideHeight] = useState<number>(0);

  const changeAsideWidth = (width: number) => {
    setAsideWidth(width);
    localStorage.setItem('chatBotNavWidth', width.toString());
  };

  useEffect(() => {
    const resizeHandler = () => {
      const element = document.getElementById('chatbot-aside');

      if (element) {
        setAsideWidth(element.clientWidth);
        setAsideHeight(element.clientHeight);
      }
    };
    resizeHandler();

    window.addEventListener('chatbot-resize', resizeHandler);

    return () => window.removeEventListener('chatbot-resize', resizeHandler);
  },[]);
  
  return (
    <aside id="chatbot-aside">
      <ResizableBox
        draggableOpts={{ draggable: true }}
        width={asideWidth}
        height={asideHeight}
        minConstraints={[192, asideHeight]}
        maxConstraints={[400, asideHeight]}
        onResizeStart={e => e.preventDefault()}
        onResize={(_, { size }) => changeAsideWidth(size.width)}
        resizeHandles={["w"]}
        handle={<img src={Handle} width={8} height={40} className="chatbot-aside-handle" />}
      >
      <div>
        {children}
      </div>
      </ResizableBox>
    </aside>
  )
};

export default ChatBotAsideLnb;