import React from 'react';
import DetailTab from "pages/v2/Organ/Management/EC2/components/DetailTab";
import {IMgdDetailKeyValueProps, TextTypeEnum} from "layouts/v3/MgdLayout";
import { getBetweenTwoDate } from 'utils/Common';

import './style.scss'

function Details({data}: any) {
  const {
    // networkDetails
    networkInterfaceId,
    tagSet,
    description,
    status,
    interfaceType,
    groups,
    vpcId,
    subnetId,
    availabilityZone,
    ownerIdb,
    requesterId,
    requesterManaged,
    sourceDestCheck,

    // ipAddresses
    privateIpAddress,
    privateDnsName,
    privateIpAddresses,
    ipv6Addresses,
    association,
    macAddress,
    ipv4Prefixes,
    ipv6Prefixes,

    // instanceDetails
    // networkInterfaceAttachment
    attachment,

    // idleConnectionTrackingTimeout
    connectionTrackingConfiguration,
  } = data || {}

  const handleGetNameTag = (data: any) => {
    if (!data?.length) return '';

    const item = data.find((e: any) => e.key === 'Name')

    return item?.value || '';
  }

  const networkDetails: IMgdDetailKeyValueProps[] = [
    {
      title: 'Network interface ID',
      description: networkInterfaceId,
      type: TextTypeEnum.COPY
    },
    {
      title: 'Name',
      description: handleGetNameTag(tagSet),
    },
    {
      title: 'Description',
      description: description,
    },
    {
      title: 'Network interface status',
      description: status || '...',
    },
    {
      title: 'Interface Type',
      description: interfaceType === 'interface' ? 'Elastic network interface' : interfaceType,
    },
    {
      title: 'security groups',
      description: `${groups?.[0]?.groupId}(${groups?.[0]?.groupName})`,
      type: TextTypeEnum.COPY_LINK
    },
    {
      title: 'VPC ID',
      description: vpcId,
      type: TextTypeEnum.LINK
    },
    {
      title: 'Subnet ID',
      description: subnetId,
      type: TextTypeEnum.LINK
    },
    {
      title: 'Availability zone',
      description: availabilityZone,
    },
    {
      title: 'Owner',
      description: ownerIdb,
      type: TextTypeEnum.COPY
    },
    {
      title: 'Requester ID',
      description: requesterId,
      type: TextTypeEnum.COPY
    },
    {
      title: 'Requester-managed',
      description: requesterManaged?.toString() || '...',
    },
    {
      title: 'Source/dest. check',
      description: sourceDestCheck?.toString() || 'false',
    }
  ]

  const ipAddresses: IMgdDetailKeyValueProps[] = [
    {
      title: 'Private IPv4 address',
      description: privateIpAddress,
    },
    {
      title: 'Private IPv4 DNS',
      description: privateDnsName,
    },
    {
      title: 'Elastic Fabric Adapter',
      description: interfaceType === 'efa' ? 'true' : 'false',
    },
    {
      title: 'Public IPv4 address',
      description: privateIpAddresses?.length ? privateIpAddresses.map((e: any) => e.association?.publicIp).filter((e: string) => e) : '',
    },
    {
      title: 'Public IPv4 DNS',
      description: privateIpAddresses?.length ? privateIpAddresses.map((e: any) => e.association?.publicDnsName).filter((e: string) => e) : '',
    },
    {
      title: 'IPv6 addresses',
      description: ipv6Addresses?.length ? ipv6Addresses.map((e: any) => e.ipv6Address).filter((e: string) => e) : '',
    },
    {
      title: 'Secondary private IPv4 addresses',
      description: privateIpAddresses?.length ? privateIpAddresses.map((e: any) => !e.primary && e.privateIpAddress).filter((e: string) => e) : '',
    },
    {
      title: 'Association ID',
      description: association?.associationId,
      type: TextTypeEnum.COPY
    },
    {
      title: 'Elastic IP address owner',
      description: association?.ipOwnerId,
      type: TextTypeEnum.COPY
    },
    {
      title: 'MAC address',
      description: macAddress,
      type: TextTypeEnum.COPY
    },
    {
      title: 'IPv4 Prefix Delegation',
      description: ipv4Prefixes?.length ? ipv4Prefixes.map((e: any) => e.ipv4Prefix).filter((e: string) => e) : '',
    },
    {
      title: 'IPv6 Prefix Delegation',
      description: ipv6Prefixes?.length ? ipv6Prefixes.map((e: any) => e.ipv6Prefix).filter((e: string) => e) : '',
    },
    {
      title: 'Primary IPv6 address',
      description: ipv6Prefixes?.length ? ipv6Prefixes.map((e: any) => e.isPrimaryIpv6 && e.ipv6Address).filter((e: string) => e) : '',
    }
  ]

  const instanceDetails: IMgdDetailKeyValueProps[] = [
    {
      title: 'Instance ID',
      description: attachment?.instanceId,
      type: TextTypeEnum.LINK
    },
    {
      title: 'Instance owner',
      description: attachment?.instanceOwnerId,
    },
    {
      title: 'Device index',
      description: attachment?.deviceIndex,
    },
    {
      title: 'Allocation ID',
      description: association?.allocationId,
      type: TextTypeEnum.COPY
    },
  ]

  const networkInterfaceAttachment: IMgdDetailKeyValueProps[] = [
    {
      title: 'Attachment status',
      description: attachment?.status,
    },
    {
      title: 'Attachment ID',
      description: attachment?.attachmentId,
      type: TextTypeEnum.COPY
    },
    {
      title: 'Attachment time',
      description: getBetweenTwoDate(attachment?.attachTime),
    },
    {
      title: 'Delete on termination',
      description: attachment?.deleteOnTermination?.toString() || 'false',
    },
    {
      title: 'Network card index',
      description: attachment?.networkCardIndex,
    },
    {
      title: 'ENA Express',
      description: attachment?.enaSrdSpecification?.enaSrdEnabled ? 'enabled' : 'disabled',
    },
    {
      title: 'ENA express UDP',
      description: attachment?.enaSrdSpecification?.enaSrdUdpSpecification?.enaSrdUdpEnabled ? 'enabled' : 'disabled',
    },
  ]

  const idleConnectionTrackingTimeout: IMgdDetailKeyValueProps[] = [
    {
      title: 'TCP established timeout',
      description: connectionTrackingConfiguration?.tcpEstablishedTimeout,
    },
    {
      title: 'UDP stream timeout',
      description: connectionTrackingConfiguration?.udpStreamTimeout,
    },
    {
      title: 'UDP timeout',
      description: connectionTrackingConfiguration?.udpTimeout,
    },
  ]

  return (
    <div className='network-interface-details-tab'>
      <DetailTab title='Network interface details' data={networkDetails} />

      <DetailTab title='IP addresses' data={ipAddresses} />

      <DetailTab title='Instance details' data={instanceDetails} />

      <DetailTab title='Network interface attachment' data={networkInterfaceAttachment} />

      <DetailTab title='Idle connection tracking timeout' data={idleConnectionTrackingTimeout} />
    </div>
  );
}

export default Details;