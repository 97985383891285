import React, {useEffect, useState} from 'react';
import Details from "pages/v2/Organ/Management/EC2/components/EBSVolume/Common/Components/Details";
import StatusChecks from "pages/v2/Organ/Management/EC2/components/EBSVolume/Common/Components/StatusChecks";
import Tags from "pages/v2/Organ/Management/EC2/components/EBSVolume/Common/Components/Tags";
import {ebsVolumeTab} from "pages/v2/Organ/Management/EC2/components/EBSVolume/Common/Constant";
import lazyGetAwsDescribeVolumes from "graphql/queries/getAwsDescribeVolumes";
import {variableCombineNextToken} from "pages/v2/Organ/Management/Utils";
import lazyGetAwsDescribeVolumeStatus from "graphql/queries/getAwsDescribeVolumeStatus";
import lazyGetAwsListAliases from "graphql/queries/getAwsListAliases";
import lazyGetAwsDescribeVolumeAttribute from "graphql/queries/getAwsDescribeVolumeAttribute";

import './index.scss';

const EBSVolumeSummary = ({cloudId, region, detailItem, setPageDetail}: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any>();
  const [tabSelected, setTabSelected] = useState(ebsVolumeTab[0]);
  const [volumeAttribute, setVolumeAttribute] = useState(null);

  const [getAwsDescribeVolumes] = lazyGetAwsDescribeVolumes();
  const [getAwsDescribeVolumeStatus] = lazyGetAwsDescribeVolumeStatus();
  const [getAwsListAliases] = lazyGetAwsListAliases();
  const [getAwsDescribeVolumeAttribute] = lazyGetAwsDescribeVolumeAttribute();

  const handleGetAwsDescribeVolumes = async (volumeIds: string) => {
    if (!volumeIds) return {};

    const payload = {
      cloudId,
      region,
      request: {
        volumeIds
      },
    };

    return getAwsDescribeVolumes({
      variables: variableCombineNextToken(payload),
    })
  }

  const handleGetAwsDescribeVolumeStatus = async (volumeIds: string) => {
    if (!volumeIds) return {};

    const payload = {
      cloudId,
      region,
      request: {
        volumeIds,
      }
    }

    return getAwsDescribeVolumeStatus({
      variables: variableCombineNextToken(payload),
    })
  };

  const handleGetAwsListAliases = async (keyId: string) => {
    if (!keyId) return {};

    const payload = {
      cloudId,
      region,
      request: {
        keyId,
      }
    }

    return getAwsListAliases({
      variables: variableCombineNextToken(payload),
    })
  };

  const handleGetAwsDescribeVolumeAttribute = async (volumeId: string) => {
    try {
      setIsLoading(true)
      if (!volumeId) return {};

      const payload = {
        cloudId,
        region,
        request: {
          volumeId,
          attribute : "autoEnableIO"
        }
      }

      const res = await getAwsDescribeVolumeAttribute({
        variables: variableCombineNextToken(payload),
      })

      setVolumeAttribute(res?.data?.getAwsDescribeVolumeAttribute?.data?.[0])
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
    }
  };

  const handleGetDetail = async () => {
    try {
      setIsLoading(true)
      const [resAwsDescribeVolumes, resAwsDescribeVolumeStatus, resAwsListAliases]: any = await Promise.all([
        handleGetAwsDescribeVolumes(detailItem?.volumeId),
        handleGetAwsDescribeVolumeStatus(detailItem?.volumeId),
        handleGetAwsListAliases(detailItem?.kmsKeyId)
      ])

      const data = {
        volumeStatus: resAwsDescribeVolumeStatus?.data?.getAwsDescribeVolumeStatus?.data?.[0]?.volumeStatuses,
        alias: resAwsListAliases?.data?.getAwsListAliases?.data?.[0]?.aliases?.[0],
        ...resAwsDescribeVolumes?.data?.getAwsDescribeVolumes?.data?.[0]?.volumes?.[0],
      }

      setData(data)
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setTabSelected(ebsVolumeTab[0])

    if (!detailItem?.id) return;

    handleGetDetail()
  }, [detailItem?.id]);

  useEffect(() => {
    setVolumeAttribute(null)

    if (!detailItem?.id || tabSelected.id !== ebsVolumeTab[1].id) return;

    handleGetAwsDescribeVolumeAttribute(detailItem?.volumeId)
  }, [tabSelected]);

  const renderTab = () => {
    switch (tabSelected.id) {
      case ebsVolumeTab[0].id:
        return <Details data={data} isLoading={isLoading} handleLinkDetail={() => setPageDetail(detailItem)} />

      case ebsVolumeTab[1].id:
        return <StatusChecks data={data} volumeAttribute={volumeAttribute} isLoading={isLoading}/>

      case ebsVolumeTab[2].id:
        return <Tags data={data} />

      default:
        return null
    }
  }

  return data?.volumeId ? (
    <div className="box-summary">
      <div className="box-summary-id">{data?.volumeId}</div>

      <div className="detail-tab flex a-center">
        {ebsVolumeTab.map(tab => {
          return (
            <button
              className={`detail-tab-items ${tabSelected?.id === tab.id && 'active'}`}
              key={tab.id}
              data-tab={tab.id}
              onClick={e => setTabSelected(tab)}
            >
              <p>{tab.name}</p>
            </button>
          );
        })}
      </div>

      <div className="content-tab">
        {renderTab()}
      </div>
    </div>
  ) : (
    <div className="detail-data">
      <p className="empty-page">Select a item</p>
    </div>
  );
};

export default EBSVolumeSummary;
