import {gql} from '@apollo/client';

const query = gql`
query getAwsLoggingConfigurationFirewall($request: AwsDescribeLoggingConfigurationRequest, $cloudId: Long, $region: String) {
  getAwsLoggingConfigurationFirewall(describeLoggingConfigurationRequest: $request, cloudId: $cloudId, region: $region) {
    result
     data{
      ... on AwsDescribeLoggingConfigurationResponse {
        firewallArn
        loggingConfiguration {
          logType
          logDestinationType
          logDestination
        }
        }
      }
    }
  }
`;
export default query;