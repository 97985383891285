import { gql } from '@apollo/client';

const query = gql`
  query getAwsLoginProfile($request: AwsGetLoginProfileRequest, $cloudId: Long, $region: String) {
    getAwsLoginProfile(getLoginProfileRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsGetLoginProfileResponse {
          loginProfile {
            userName
            createDate
            passwordResetRequired
          }
        }
      }
    }
  }
`;
export default query;
