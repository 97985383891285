import { gql } from '@apollo/client';

const query = gql`
query getAwsRouteTables($request: AwsDescribeRouteTablesRequest, $cloudId: Long, $region: String) {
  getAwsRouteTables(
    describeRouteTablesRequest: $request
    cloudId: $cloudId
    region: $region
  ) {
    result
    data {
      ... on AwsDescribeRouteTablesResponse {
        routeTables {
          ... on AwsRouteTable {
            associations {
              ... on AwsRouteTableAssociation {
                main
                routeTableAssociationId
                routeTableId
                subnetId
                gatewayId
                associationState {
                  ... on AwsRouteTableAssociationState {
                    state
                    statusMessage
                  }
                }
              }
            }
            propagatingVgws {
              ... on AwsPropagatingVgw {
                gatewayId
              }
            }
            routes {
              ... on AwsRoute {
                destinationCidrBlock
                destinationIpv6CidrBlock
                destinationPrefixListId
                egressOnlyInternetGatewayId
                gatewayId
                instanceId
                instanceOwnerId
                natGatewayId
                transitGatewayId
                localGatewayId
                carrierGatewayId
                networkInterfaceId
                origin
                state
                vpcPeeringConnectionId
                coreNetworkArn
              }
            }
            vpcId
            ownerId
            tags {
              ... on AwsTag {
                key
                value
              }
            }
          }
        }
      }
    }
  }
}`;
export default query;