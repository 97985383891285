import { useCallback, useEffect, useState } from 'react';
import { OrderDirection, RowType } from '@Types/v2/Table';
import './index.scss';

export interface ITableManagePaginationProps {
  /** 페이지네이션에 최대 보여줄 페이지 갯수. 최대 5개까지 보여준다고 하면 5로 설정 ex) 1 2 3 4 5 @default 5 */
  pageCount?: number;
  /** can fetch more data if it available */
  ableFetchMore: boolean;
  /** 현재 보고 있는 페이지 */
  currentPage: number;
  /** 현재 보고 있는 페이지를 갱신하는 함수 */
  updateCurrentPage?: (page: number) => void;
  /** 총 페이지 수 */
  totalPage: number;
}

const TableManagePagination = ({
  currentPage,
  updateCurrentPage,
  totalPage,
  pageCount = 5,
  ableFetchMore,
}: ITableManagePaginationProps) => {
  /* 나열될 페이지 갯수중 가장 최좌측에 위치할 startPosition */
  const [startPosition, setStartPosition] = useState(1);

  /** 현재페이지 갱신에 따른 startPosition 갱신  */
  useEffect(() => {
    if (currentPage >= startPosition + 5) {
      setStartPosition(prev => prev + 1);
    } else if (currentPage < startPosition) {
      setStartPosition(prev => prev - 1);
    }
  }, [startPosition, currentPage]);

  return totalPage !== 0 ? (
    <div className="table-pagination">
      {/* 좌측 영역 */}
      <button
        className="page-prev-btn"
        disabled={currentPage <= 1}
        onClick={() => updateCurrentPage && updateCurrentPage(currentPage - 1)}
      />

      {/* 중간 영역 */}
      <div className="page-numbers">
        {[...Array(pageCount)].map(
          (_, idx) =>
            startPosition + idx <= totalPage && (
              <button
                key={idx}
                className="number"
                disabled={startPosition + idx === currentPage}
                onClick={() => updateCurrentPage && updateCurrentPage(startPosition + idx)}
              >
                {startPosition + idx}
              </button>
            ),
        )}
      </div>

      {/* 우측 영역 */}
      <button
        className="page-next-btn"
        disabled={currentPage >= totalPage && !ableFetchMore}
        onClick={() => updateCurrentPage && updateCurrentPage(currentPage + 1)}
      />
    </div>
  ) : (
    <></>
  );
};

export default TableManagePagination;
