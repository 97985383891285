import { gql } from '@apollo/client';

const query = gql`
query getAwsPublicKey($request: AwsGetPublicKeyRequest, $cloudId: Long, $region: String) {
  getAwsPublicKey(
    getPublicKeyRequest: $request
    cloudId: $cloudId
    region: $region
  ) {
    result
    nextToken
    data {
      ... on AwsGetPublicKeyResponse {
        keyId
        publicKey
        customerMasterKeySpec
        keySpec
        keyUsage
        encryptionAlgorithms
        signingAlgorithms
      }
    }
  }
}`;
export default query;