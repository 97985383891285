import Icon from "components/v2/atoms/Icon";
import BaseModal, { IBaseModalProps } from "components/v2/modals/BaseModal";
import IconEditRequest from 'assets/svgs/v3/ico_edit_vpc_subnets.svg';
import { useEffect, useState } from "react";
import TextareaAtom from "components/v2/atoms/TextareaAtom";
import './AddRegexPattern.scss';

interface AddRegexPatternModalPropsType extends IBaseModalProps {
  header: string;
  onSave: (regexPatterns: string) => void;
}

const AddRegexPatternModal = (props: AddRegexPatternModalPropsType) => {
  const {header, onSave, ...baseModalProps} = props;
  const [regexPattern, setRegexPattern] = useState<string>('');

  useEffect(() => {
    if (baseModalProps.open) {
    }
  }, [baseModalProps.open]);
  
  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={IconEditRequest} />
          <div className="title-header-modal">{header}</div>
        </>
      )}
      {...baseModalProps}
    >
      <div className="add-regex-pattern-model">

        <div className="content-container">
          <div className="title">
            <p>IP address</p>
          </div>
          <div className="input-container">
            <TextareaAtom
              placeholder={'^(http|https):\\/\\/'}
              className={'input-add'}
              onChangeValue={setRegexPattern}
              value={regexPattern}
              required={true}
              noClear={false}
            />
            <p className="text-note">
              Enter one IP address per line in CIDR format
            </p>
          </div>
        </div>

        <div className="button-group">
          <button className="btn" onClick={baseModalProps.onClose}>
            Cancel
          </button>
          <button className="btn save-btn" onClick={() => onSave(regexPattern)} disabled={regexPattern.trim() === ''}>
            Save
          </button>
        </div>
      </div>
    </BaseModal>
  );
}

export default AddRegexPatternModal;