import { useLazyQuery } from '@apollo/client';
import query from './query';

export interface IAwsGroupPolicyVariables {
  cloudId: number;
  region: string;
  request: {
    groupName: string;
    policyName: string;
  };
}

export interface IAwsGroupPolicyResponseData {
  getAwsGroupPolicy: any;
}

const lazyGetAwsGroupPolicy = () =>
  useLazyQuery<IAwsGroupPolicyResponseData, IAwsGroupPolicyVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsGroupPolicy;
