import { useState, useMemo, useEffect } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import IconEditFirewallDetail from 'assets/svgs/v3/ico_edit_firewall_detail.svg';
import Icon from 'components/v2/atoms/Icon';
import './index.scss';
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';

interface IEditFirewallPolicyProps extends IBaseModalProps {
  header: string;
  onChangeValue?: (index: number, value: string) => void;
}

 const typeList: DropdownListDataType[] = [
    {id: 0, name: 'test-2-firewallpolicy', value: 'all'},
    {id: 1, name: 'aylee-firewallpolicy', value: 'custom_protocal'},
    {id: 2, name: 'Curidyfirewallpolicty', value: 'custom_tcp'},
    {id: 3, name: 'Joefirewallpolicy', value: 'custom_udp'},
  ]

const EditFirewallPolicyModal = ({
  header,
  onChangeValue,
  ...baseModalProps
}: IEditFirewallPolicyProps) => {
  const [payload, setPayload] = useState<string>('');

  useEffect(() => {
    if (baseModalProps.open) {
      setPayload('');
    }
  }, [baseModalProps.open]);

  const [dropdownSelected,setDropdownSelected] =  useState<DropdownListDataType>(typeList[0]);

  return (
    <BaseModal
      title={() => <><Icon width={32} height={32} src={IconEditFirewallDetail} />{header}</>}
      {...baseModalProps}>
      <div className="edit-firewall-policy-modal">
        <div className="edit-firewall-policy">
          <p>Firewall policy</p>
          <DropdownAtom
            id="list-firewall-policy"
            data={typeList}
            value={{
              name: dropdownSelected.name,
              value: dropdownSelected.value
            }}
            handleClick={(val) => { setDropdownSelected(val)}}
            className="list-firewall-policy-wrap"
          />
        </div>
        <div className="button-group">
          <button onClick={baseModalProps.onClose}>Cancel</button>
          <button className="delete-btn" onClick={() => { }}>
            Save changes
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default EditFirewallPolicyModal;
