import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroupPropsType } from './types';
import Button from './Button';
import _ from 'lodash';

const ButtonGroup = (props: ButtonGroupPropsType) => {
  const { buttons } = props;

  return (
    <div className="button-group-container">
      {buttons.map(button => (
        <Button key={_.uniqueId('button')} {...button} />
      ))}
    </div>
  );
};

export default ButtonGroup;
