import { gql } from '@apollo/client';

const query = gql`
query getAwsListWebACLs($request: AwsListWebACLsRequest, $cloudId: Long, $region: String) {
  getAwsListWebACLs(
    describeListWebACLsRequest: $request
    cloudId: $cloudId
    region: $region
  ) {
    result
    data {
      ... on AwsListWebAcLsResponse {
        webACLs {
          name
          id
          description
          lockToken
          arn
        }
        nextMarker
      }
    }
  }
}
`;
export default query;