import { useLazyQuery } from '@apollo/client';
import query from './query';
import { AwsListAliasesEntry } from 'graphql/types/AwsListAliases';

export interface IAwsListAliasesVariables {
  cloudId: number;
  region: string;
  request: {
    keyId: string;
  }
}

export interface IAwsListAliasesResponseData {
  getAwsListAliases: AwsListAliasesEntry;
}

const lazyGetAwsListAliases = () => useLazyQuery<IAwsListAliasesResponseData, IAwsListAliasesVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsListAliases;