import { gql } from '@apollo/client';

const query = gql`
  query getAwsFirewallPolicy($request: AwsDescribeFirewallPolicyRequest, $cloudId: Long, $region: String) {
    getAwsFirewallPolicy(describeFirewallPolicyRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsDescribeFirewallPolicyResponse {
          updateToken
          firewallPolicyResponse {
            firewallPolicyName
            firewallPolicyArn
            firewallPolicyId
            description
            firewallPolicyStatus
            tags {
              key
              value
            }
            consumedStatelessRuleCapacity
            consumedStatefulRuleCapacity
            numberOfAssociations
            encryptionConfiguration {
              keyId
              type
            }
            lastModifiedTime
          }
          firewallPolicy {
            statelessRuleGroupReferences {
              resourceArn
              priority
            }
            statelessDefaultActions
            statelessFragmentDefaultActions
            statelessCustomActions {
              actionName
              actionDefinition {
                publishMetricAction {
                  dimensions {
                    value
                  }
                }
              }
            }
            statefulRuleGroupReferences {
              resourceArn
              priority
              override {
                action
              }
            }
            statefulDefaultActions
            statefulEngineOptions {
              ruleOrder
              streamExceptionPolicy
            }
            tlsInspectionConfigurationArn
            policyVariables {
              ruleVariables
            }
          }
        }
      }
    }
  }
`;
export default query;
