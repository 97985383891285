import { useEffect, useMemo, useState } from 'react';
import { instanceTab } from 'pages/v2/Organ/Management/EC2/components/SpotRequest/Common/Constant';
import Description from 'pages/v2/Organ/Management/EC2/components/SpotRequest/Common/Components/Instance/Description';
import { InstancePropsType } from '../types';
import { ColumnType } from '@Types/v2/Table';
import { IMgdTabProps } from 'layouts/v3/MgdLayout';
import TagTab from '../../TagTab';

const Instance = (props: InstancePropsType) => {
  const { currentSpotInstance } = props;
  const [tabSelected, setTabSelected] = useState<IMgdTabProps>(instanceTab[0]);

  useEffect(() => {
    if (!currentSpotInstance) return;

    setTabSelected(instanceTab[0])
  }, [currentSpotInstance]);

  const tagColumns = useMemo((): ColumnType[] => {
    return [
      {
        label: 'Key',
        field: 'key',
        sort: true,
      },
      {
        label: 'Value',
        field: 'value',
        sort: true,
      },
    ];
  }, []);

  const renderTab = () => {
    switch (tabSelected) {
      case instanceTab[0]:
        return <Description currentSpotInstance={currentSpotInstance} />;

      case instanceTab[1]:
        return (
          <TagTab
            title={'Tags'}
            description={'Apply tags to your resources to help organize and identify them.'}
            rows={currentSpotInstance?.tags}
            columns={tagColumns}
          />
        );

      default:
        return null;
    }
  };

  return (
    <div className="box-summary">
      <div className="box-summary-id">{currentSpotInstance?.spotInstanceRequestId}</div>

      <div className="detail-tab flex a-center">
        {instanceTab.map(tab => {
          return (
            <button
              className={`detail-tab-items ${tabSelected?.id === tab.id && 'active'}`}
              key={tab.id}
              data-tab={tab.id}
              onClick={e => setTabSelected(tab)}
            >
              <p>{tab.name}</p>
            </button>
          );
        })}
      </div>

      <div className="content-tab">{renderTab()}</div>
    </div>
  );
};

export default Instance;
