import React, { useState, useMemo, useCallback, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { LoadBalancerPropsType } from '../../types';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import { LOAD_BALANCER_SEARCH_TYPES, LOAD_BALANCE_COLUMN_LIST } from '../../../configs';
import { OrderDirection } from '@Types/v2/Table';
import InputAtom from 'components/v2/atoms/InputAtom';
import SearchIcon from 'assets/svgs/v3/ico_search.svg';
import Table from 'components/v2/dataDisplay/Table';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';
import lazyGetAwsLoadBalancers from 'graphql/queries/getAwsLoadBalancers';
import { AwsLoadBalancer } from 'graphql/types/AwsLoadBalancer';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import { getFormatedDateWithTimezone } from 'pages/v2/Organ/Management/Firewall/Common/Function';

const LoadBalancer = (props: LoadBalancerPropsType) => {
  const { tabInformation, cloudId, region, onNameClicked, onLoadBalancerSelected } = props;

  // API
  const [getAwsLoadBalancers, { loading: awsLoadBalancersLoading }] = lazyGetAwsLoadBalancers();

  // State
  const [tablePagination, setTablePagination] = useState({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });
  const [searchValue, setSearchValue] = useState<{ name: string; value: string }>({ name: '', value: '' });
  const [searchType, setSearchType] = useState(LOAD_BALANCER_SEARCH_TYPES[0]);
  const [loadBalancers, setLoadBalancers] = useState<Array<AwsLoadBalancer>>([]);

  const getLoadBalancers = useCallback(() => {
    const variables = {
      cloudId: cloudId,
      region: region.value as string,
      request: {
        pageSize: 100,
      },
    };

    getAwsLoadBalancers({ variables }).then(res => {
      setLoadBalancers(res?.data?.getAwsLoadBalancers?.data?.[0]?.loadBalancers ?? []);
    });
  }, [getAwsLoadBalancers, cloudId]);

  // Init data
  useEffect(() => {
    getLoadBalancers();
  }, [getLoadBalancers]);

  const updateTablePagination = useCallback((key: string, value: any) => {
    setTablePagination(prevState => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  }, []);

  const onHandleLoadBalancerNameClicked = useCallback(
    (loadBalancerId: string) => {
      onLoadBalancerSelected(loadBalancers.find(lb => lb.loadBalancerName === loadBalancerId)!);
      onNameClicked();
    },
    [loadBalancers],
  );

  const loadBalancerTableRows = useMemo(() => {
    return loadBalancers?.map(loadBalancer => {
      const { loadBalancerName, dnsName, state, vpcId, availabilityZones, type, createdTime } = loadBalancer;

      return {
        id: loadBalancerName,
        name: loadBalancerName ?? '-',
        dnsName: dnsName ?? '-',
        state: state?.code ?? '-',
        vpcId: vpcId ?? '-',
        availabilityZones: availabilityZones?.map(zone => zone?.zoneName),
        type: type,
        dateCreated: getFormatedDateWithTimezone(createdTime, 'MMM DD, YYYY HH:MM') ?? '-',
        onLoadBalancerNameClicked: onHandleLoadBalancerNameClicked,
      };
    });
  }, [loadBalancers]);

  const currentPageLoadBalancerTableRows = useMemo(() => {
    const firstItemIndex = (tablePagination.currentPage - 1) * tablePagination.itemPerPage;
    const lastItemIndex = tablePagination.currentPage * tablePagination.itemPerPage - 1;

    const arr = loadBalancerTableRows?.filter((_, index) => index >= firstItemIndex && index <= lastItemIndex);

    const startIndex = (tablePagination.currentPage - 1) * tablePagination.itemPerPage;
    const endIndex = startIndex + tablePagination.itemPerPage;

    return orderAlphabetical(arr, tablePagination.target, tablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [loadBalancerTableRows, tablePagination]);

  const isLoading = useMemo(() => {
    return awsLoadBalancersLoading;
  }, [awsLoadBalancersLoading]);

  return (
    <>
      <div className="row-3 flex j-between a-center">
        <div className="title">
          <p>{tabInformation.title}</p>
          <p>
            Elastic Load Balancing scales your load balancer capacity automatically in response to changes in incoming
            traffic.
          </p>
        </div>

        <div className="flex action a-center">
          <DropdownAtom
            id="types-dropdown"
            className=""
            data={LOAD_BALANCER_SEARCH_TYPES}
            value={searchType}
            handleClick={setSearchType}
          />
          <InputAtom
            type={'text'}
            value={searchValue?.value}
            defaultValue={''}
            onChangeValue={value => setSearchValue(prev => ({ name: prev.name, value }))}
            hasPrefixIcon={true}
            srcPrefixIcon={SearchIcon}
            prefixIconOnClick={() => {}}
          />
        </div>
      </div>
      <div className="data-grid-wrap">
        {currentPageLoadBalancerTableRows.length === 0 && isLoading === false ? (
          <p className="empty-row">Empty</p>
        ) : (
          <Fragment>
            <Table
              rows={currentPageLoadBalancerTableRows}
              columns={LOAD_BALANCE_COLUMN_LIST}
              reportCheckedList={() => {}}
              reportSelected={id => {
                onLoadBalancerSelected(loadBalancers.find(item => item.loadBalancerName === id)!);
              }}
              sortOption={{
                target: tablePagination.target,
                direction: tablePagination.direction,
                onChangeSort: (target: string, dir: OrderDirection) => {
                  updateTablePagination('target', target);
                  updateTablePagination('direction', dir);
                },
              }}
              horizontalScrollable={true}
              isLoading={isLoading}
            />
            {loadBalancerTableRows?.length > 0 && !isLoading && (
              <div className="pagination-wrapper flex a-center">
                <p className="flex a-center">
                  Total <span>{loadBalancerTableRows.length}</span>
                </p>
                <TableManagePagination
                  ableFetchMore={false}
                  currentPage={tablePagination.currentPage}
                  updateCurrentPage={page => updateTablePagination('currentPage', page)}
                  totalPage={Math.ceil(loadBalancerTableRows.length / tablePagination.itemPerPage)}
                />
              </div>
            )}
          </Fragment>
        )}
      </div>
    </>
  );
};

export default LoadBalancer;
