import { gql } from '@apollo/client';

const query = gql`
mutation createAwsIPSet($reqData: AwsCreateIpSetRequest, $cloudId: Long, $region: String) {
  createAwsIPSet(createIpSetRequest: $reqData, cloudId: $cloudId, region: $region) {
    result
    data {
      ... on AwsCreateIpSetResponse {
        summary{
          name
          id
          description
          lockToken
          arn
        }
        }
      }
    }
  }
`;
export default query;