import { gql } from '@apollo/client';

const query = gql`
query getAwsDescribeSnapshotAttribute($request: AwsDescribeSnapshotAttributeRequest, $cloudId: Long, $region: String) {
  getAwsDescribeSnapshotAttribute(describeSnapshotAttributeRequest: $request, cloudId: $cloudId, region: $region) {
    result
     data{
      ... on AwsDescribeSnapshotAttributeResponse {
        createVolumePermissions {
          group
          userId
        }
        productCodes {
          productCodeId
          productCodeType
        }
        snapshotId
      }
        }
        }
      }
`;
export default query;