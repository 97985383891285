import { useLazyQuery } from '@apollo/client';
import query from './query';
import { AwsDescribeFirewallPolicyResponse } from 'graphql/types/AwsFirewallPolicy';
import { IGqlResponseNewData } from 'graphql/types';

export interface IGetAwsFirewallPolicyVariables {
  cloudId: number;
  region: string;
  request: {
    firewallPolicyName?: string;
    firewallPolicyArn?: string;
    maxResults?: number;
    nextToken?: string;
    filters?: {
      name: string;
      values: string[];
    };
  };
}

export interface IAwsFirewallPolicyResponseData {
  getAwsFirewallPolicy: IGqlResponseNewData<AwsDescribeFirewallPolicyResponse>;
}

const lazyGetAwsFirewallPolicy = () =>
  useLazyQuery<IAwsFirewallPolicyResponseData, IGetAwsFirewallPolicyVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsFirewallPolicy;
