import { DetailHyperLinkPropsType } from './types';
import PageDetailTitle from '../PageDetailTitle';
import Details from './SummaryTabs/Details';
import LBTargetGroupSummary from './LBTargetGroupSummary';
import './LBTargetGroupDetail.scss'
import { Fragment } from 'react';

const LBTargetGroupDetail = (props: DetailHyperLinkPropsType) => {
  const { targetGroup, onBackButtonClicked, cloudId, region, onDeleteButtonClicked } = props;

  return (
    <div className="ec2-detail scroll">
      {targetGroup && (
        <Fragment>
          <PageDetailTitle title={targetGroup.targetGroupName} pageBackClick={onBackButtonClicked} isDelete onDelete={onDeleteButtonClicked}/>
          <Details targetGroup={targetGroup} cloudId={cloudId} region={region} />
          <LBTargetGroupSummary targetGroup={targetGroup} cloudId={cloudId} region={region} isSummaryHidden={true} />
        </Fragment>
      )}
    </div>
  );
};

export default LBTargetGroupDetail;
