import { useLazyQuery } from '@apollo/client';
import query from './query';
import { Paging } from 'graphql/types/Paging';
import { AwsGroupIdentifier } from 'graphql/types/AwsListGroups';
import { IGqlResponseNewData } from 'graphql/types';

export interface IAwsAllListGroupsPageVariables {
  cloudId: number;
  region: string;
  request: {
    maxResults: number;
    nextToken?: string;
    filters?: {
      name: string;
      values: string[];
    };
    pageInfo?: Paging;
  };
}

export interface IAwsAllListGroupsResponseData {
  getAwsAllListGroups: IGqlResponseNewData<AwsGroupIdentifier>;
}

const lazyGetAwsAllListGroups = () =>
  useLazyQuery<IAwsAllListGroupsResponseData, IAwsAllListGroupsPageVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsAllListGroups;
