import React, {useState} from 'react';
import NetworkTitle from "components/v3/NetworkTitle";
import {InputForm, NoItem, TagsList, tagType} from "pages/v2/Organ/Management/Network/NetWorkACLCreation";
import DropdownAtom, {DropdownListDataType} from "components/v2/atoms/DropdownAtom";
import {NetworkRuleTable, RuleRowData} from "pages/v2/Organ/Management/Network/EditNetworkRuleModal";
import '../EditNetworkRuleModal/index.scss';
import {
  disabledByType, disabledByTypeAndProtocol,
  getPortRangeByTypeAndProtocol, getPortRangeDataByType,
  getProtocolByType, getProtocolDataByType
} from "pages/v2/Organ/Management/Network/Common/Function/Network";

type sgCreationProps = {
  listVpc: DropdownListDataType[];
  pageBackClick: () => void;
}

type ruleNetworkType = 'INBOUND' | 'OUTBOUND';

const SecurityGroupCreation = (props: sgCreationProps) => {
  const { listVpc, pageBackClick } = props;
  const [vpc, setVpc] = useState<DropdownListDataType>(listVpc[0]);
  const [isShow, setIsShow] = useState<boolean>(false);
  const [listTags, setListTags] = useState<tagType[]>([]);
  const [securityGroupName, setSecurityGroupName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [ruleInboundRows, setRuleInboundRows] = useState<RuleRowData[]>([]);
  const [ruleOutboundRows, setRuleOutboundRows] = useState<RuleRowData[]>([]);

  const handleRemoveTag = (index: number) => {
    if (index > -1) {
      const newTags = listTags.slice();
      newTags.splice(index, 1);
      setListTags(newTags);
    }
  }

  const handleUpdateTag = (key: string, value: string, index: number) => {
    const newTags = listTags.map((tag, i) => {
      if(index === i) {
        return {
          ...tag,
          [key]: value
        }
      }
      return tag;
    });

    setListTags(newTags);
  }

  const handleShowTagsList = () => {
    setIsShow(true);
  }

  const handleAddTag = () => {
    const newTags = listTags.slice();
    newTags.push({
      key: 'A',
      value: 'B'
    });

    setListTags(newTags);
  }

  const handleCreate = () => {
    const data = {
      name: securityGroupName,
      description: description,
      vpc: vpc,
      tags: listTags
    };

    console.log('Create security group with data: ', data);
  }

  const handleCreateRow = (type: ruleNetworkType) => {
    if(type === 'INBOUND') {
      setRuleInboundRows((prevState) => {
        return prevState.concat({
          ruleNumber: '',
          type: 'custom_tcp',
          protocol: '6',
          portRange: '0',
          source: '0.0.0.0/0',
          action: 'allow'
        });
      });
    } else {
      setRuleOutboundRows((prevState) => {
        return prevState.concat({
          ruleNumber: '',
          type: 'custom_tcp',
          protocol: '6',
          portRange: '0',
          source: '0.0.0.0/0',
          action: 'allow'
        });
      });
    }

  };

  const handleChange = (index: number, value: string, propName: keyof RuleRowData, type: ruleNetworkType) => {
    const rows = type === 'INBOUND' ? ruleInboundRows : ruleOutboundRows;
    const newRows = rows.map((row, i) => {
        if (i == index) {
          const newRow = {...row, [propName]: value}
          if (propName === 'type') {
            const newValue = getProtocolByType(value, row.protocol, row.portRange);
            newRow.protocol = newValue.protocol;
            newRow.portRange = newValue.portRange;
          }
          if (propName === 'protocol') {
            newRow.portRange = getPortRangeByTypeAndProtocol(row.type, value, row.portRange);
          }
          return newRow;
        } else {
          return row;
        }
      }
    );
    if(type === 'INBOUND') {
      setRuleInboundRows(newRows);
    } else {
      setRuleOutboundRows(newRows);
    }
  };

  const handleDelete = (indexToRemove: number, type: ruleNetworkType) => {
    const row = type === 'INBOUND' ? ruleInboundRows : ruleOutboundRows;
    const newData = row.filter((_, index) => index != indexToRemove)
    if(type === 'INBOUND') {
      setRuleInboundRows(newData);
    } else {
      setRuleOutboundRows(newData);
    }
  }

  return (
    <div id="mgd-list-layout">
      <div className="row-1 flex j-between a-center">
        <div className="flex j-start a-center" id="title">
          <NetworkTitle pageTitle={'Create security group'} id={321} name={'name'} hasPrefixIcon={false}
                        hasFavorite={false} pageBackClick={pageBackClick}/>
        </div>
        <div className="flex a-center" id="action">
          <button className="action-btn" onClick={() => {
          }}>Delete
          </button>
        </div>
      </div>

      <div className="one-page" style={{height: 'calc(100% - 70px)', overflowY: 'auto'}}>
        <div className="row-3 flex j-between a-center">
          <div className="title">
            <p>Basic details</p>
          </div>
        </div>

        <div className="overview-container">
          <div className='nw-acl-screen'>
            <div className='nw-acl-container'>
              <div className='nw-acl-setting-container'>
                <div className='nw-acl-flex'>
                  <div className='title'>Security group name</div>
                  <InputForm
                    type='INPUT'
                    value={{value: securityGroupName}}
                    setValue={(key: string, value: string, number: number) => {setSecurityGroupName(value)}}
                  />
                </div>

                <div className='nw-acl-flex'>
                  <div className='title'>Description</div>
                  <InputForm
                    type='INPUT'
                    value={{value: description}}
                    setValue={(key: string, value: string, number: number) => {setDescription(value)}}
                  />
                </div>

                <div className='nw-acl-flex'>
                  <div className='title'>VPC</div>
                  <div>
                    <DropdownAtom
                      id=""
                      className=""
                      data={listVpc}
                      value={vpc}
                      handleClick={(value) => {
                        setVpc(value);
                      }}/>
                  </div>
                </div>
              </div>

              <div className='nw-acl-tags-container'>
                <div className='tags-title'>Inbound rules</div>
                <div className='tags-body-container'>
                  <div className="edit-inbound-rules-model" style={{width: '100%', padding: '16px'}}>
                    <NetworkRuleTable
                      btnName='Delete'
                      rows={ruleInboundRows}
                      handleChange={(index: number, value: string, propName: keyof RuleRowData) => {handleChange(index, value, propName, 'INBOUND')}}
                      disabledByType={(type: string) => disabledByType(type)}
                      getProtocolDataByType={(type: string) => getProtocolDataByType(type)}
                      disabledByTypeAndProtocol={(type: string, protocol: string) => disabledByTypeAndProtocol(type, protocol)}
                      getPortRangeDataByType={(type: string, protocol: string) => getPortRangeDataByType(type, protocol)}
                      handleDelete={(indexToRemove: number) => {handleDelete(indexToRemove, 'INBOUND')}}
                      handleCreate={() => {handleCreateRow('INBOUND')}}
                    />
                  </div>
                </div>
              </div>

              <div className='nw-acl-tags-container'>
                <div className='tags-title'>Outbound rules</div>
                <div className='tags-body-container'>
                  <div className="edit-inbound-rules-model" style={{width: '100%', padding: '16px'}}>
                    <NetworkRuleTable
                      btnName='Delete'
                      rows={ruleOutboundRows}
                      handleChange={(index: number, value: string, propName: keyof RuleRowData) => {
                        handleChange(index, value, propName, 'OUTBOUND')
                      }}
                      disabledByType={(type: string) => disabledByType(type)}
                      getProtocolDataByType={(type: string) => getProtocolDataByType(type)}
                      disabledByTypeAndProtocol={(type: string, protocol: string) => disabledByTypeAndProtocol(type, protocol)}
                      getPortRangeDataByType={(type: string, protocol: string) => getPortRangeDataByType(type, protocol)}
                      handleDelete={(indexToRemove: number) => {
                        handleDelete(indexToRemove, 'OUTBOUND')
                      }}
                      handleCreate={() => {
                        handleCreateRow('OUTBOUND')
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className='nw-acl-tags-container'>
                <div className='tags-title'>Tags</div>
                <div className='tags-body-container'>
                  {
                    isShow ?
                      <TagsList tags={listTags}
                                handleRemoveTag={handleRemoveTag}
                                handleUpdateTag={(key: string, value: string, index: number) => {
                                  handleUpdateTag(key, value, index)
                                }}
                      />
                      :
                      <NoItem handleShowTagsList={() => {
                        handleShowTagsList()
                      }}/>
                  }
                  {
                    isShow &&
                    <button className="button add-tag-button" style={{marginLeft: 16, marginBottom: 16}}
                            onClick={() => {
                              handleAddTag();
                            }}>Add tag
                    </button>
                  }
                </div>
              </div>
            </div>

            <div className='nw-acl-container-bottom'>
              <button className="button"
                      style={{margin: 0}}
                      onClick={() => {
                        pageBackClick();
                      }}
              >
                Cancel
              </button>
              <button className="button add-tag-button"
                      style={{marginLeft: 8}}
                      onClick={() => {
                        handleCreate()
                      }}
              >
                Create security group
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SecurityGroupCreation;