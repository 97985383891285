import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsInstanceStatusResponseType } from 'graphql/types/AwsDescribeInstanceStatus';

export type AwsDescribeInstanceStatusRequestType = {
  cloudId: number;
  region: string;
  request: {
    maxResults?: number;
    nextToken?: string;
    AwsDescribeInstanceStatusType?: Array<string>;
    dryRun?: boolean;
    filters?: {
      name: string;
      values: Array<string>;
    };
    includeAllInstances?: boolean;
  };
};

export interface AwsDescribeInstanceStatusResponseType {
  getAwsDescribeInstanceStatus: IGqlResponseNewData<AwsInstanceStatusResponseType>;
}

const lazyGetAwsDescribeInstanceStatus = () =>
  useLazyQuery<AwsDescribeInstanceStatusResponseType, AwsDescribeInstanceStatusRequestType>(query, {
    fetchPolicy: 'no-cache',
  });
export default lazyGetAwsDescribeInstanceStatus;
