import { useLazyQuery } from '@apollo/client';
import query from './query';
import { AwsTLSInspectionConfigurationResponse } from 'graphql/types/AwsTlsInspectionConfiguration';
import { IGqlResponseNewData } from 'graphql/types';

export interface IGetAwsTLSInspectionConfigurationVariables {
  cloudId: number;
  region: string;
  request: {
    tlsInspectionConfigurationArn?: string;
    tlsInspectionConfigurationName?: string;
  };
}

export interface IAwsTLSInspectionConfigurationResponseData {
  getAwsTLSInspectionConfiguration: IGqlResponseNewData<AwsTLSInspectionConfigurationResponse>;
}

const lazyGetAwsTLSInspectionConfiguration = () =>
  useLazyQuery<IAwsTLSInspectionConfigurationResponseData, IGetAwsTLSInspectionConfigurationVariables>(query, {
    fetchPolicy: 'no-cache',
  });
export default lazyGetAwsTLSInspectionConfiguration;
