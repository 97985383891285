import { gql } from '@apollo/client';

const query = gql`query getEnumTypeCode($text: String) {
  getEnumTypeCode(text: $text) {
    result
    data {
      ... on EnumTypeCode {
        description
        name
        value
        defaultYn
      }
    }
  }
}`;
export default query;