import React, { useMemo } from 'react';
import { TagsPropsType } from '../types';
import DetailTable from '../../DetailTable';
import { TAG_TABLE_COLUMNS } from '../configs';

const Tags = (props: TagsPropsType) => {
  const { instance } = props;

  const tags = useMemo(() => {
    return (
      instance?.tags?.map(tag => {
        const { key, value } = tag;

        return {
          key,
          value,
          id: key,
        };
      }) ?? []
    );
  }, [instance]);

  return <DetailTable title="Tags" data={tags} columns={TAG_TABLE_COLUMNS} />;
};

export default Tags;
