import { gql } from '@apollo/client';

const query = gql`
  mutation UpdateAwsWebACL($request: AwsUpdateWebAclRequest, $cloudId: Long, $region: String) {
    UpdateAwsWebACL(updateWebAclRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsUpdateWebAclResponse {
          nextLockToken
        }
      }
    }
  }
`;
export default query;
