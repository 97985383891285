import { gql } from '@apollo/client';

const query = gql`
  query getAwsAllListGroups($request: AwsListGroupsRequest, $cloudId: Long, $region: String) {
    getAwsAllListGroups(listGroupsRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsGroupIdentifier {
          groupName
          groupArn
        }
      }
    }
  }
`;
export default query;
