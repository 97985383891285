import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';

export interface IAwsDescribeVolumeAttributeVariables {
  cloudId: number;
  region: string;
  request: {
    volumeId: string;
    attribute: string;
  };
}

export interface IAwsDescribeVolumeAttributeResponseData {
  getAwsDescribeVolumeAttribute: IGqlResponseNewData<any>;
}

const lazyGetAwsDescribeVolumeAttribute = () =>
  useLazyQuery<IAwsDescribeVolumeAttributeResponseData, IAwsDescribeVolumeAttributeVariables>(query, {
    fetchPolicy: 'no-cache',
  });
export default lazyGetAwsDescribeVolumeAttribute;
