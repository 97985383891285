import { useState, useEffect } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import IconEditFirewallDetail from 'assets/svgs/v3/ico_edit_firewall_detail.svg';
import Icon from 'components/v2/atoms/Icon';
import './index.scss';
import RadioAtom from 'components/v2/atoms/RadioAtom';
import InputAtom from 'components/v2/atoms/InputAtom';

interface IEditFirewallPolicyDetailProps extends IBaseModalProps {
  header: string;
  onChangeValue?: (index: number, value: string) => void;
}

const EditFirewallPolicyDetailModal = ({
  header,
  onChangeValue,
  ...baseModalProps
}: IEditFirewallPolicyDetailProps) => {

  const [firewallHandleValues, setfirewallHandleValues] = useState<string>('drop');
  const [descriptionValue, setDescriptionValue] = useState<string>('');
  useEffect(() => {
    if (baseModalProps.open) {
      setfirewallHandleValues('drop');
      setDescriptionValue('');
    }
  }, [baseModalProps.open]);

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={IconEditFirewallDetail} />
          {header}
        </>
      )}
      {...baseModalProps}>

      <div className="firewall-policy-details-modal">
        <div className="firewall-policy-details">
          <div className="row-1">
              <p className="text-title">Name</p>
              <p className="text-description">Aylee-test</p>
              <p className="text-title">Description</p>
                <InputAtom
                  placeholder={'Add empty item'}
                  onChangeValue={val => {
                    if(val.length <= 256) setDescriptionValue(val);
                  }}
                  value={descriptionValue}
                  required={true}
                  noClear={false}
                />
              <p className="text-title">The description can have 0-256 characters.</p>
          </div>

          <div className="row-2">
            <p className="text-title">Stream exception policy</p>
            <p className="text-description">Choose how Network Firewall handles traffic when a network connection breaks midstream.</p>
            <RadioAtom
              label={'Drop'}
              description={'Drop all subsequent traffic going to the firewall.'}
              value={'drop'}
              name={''}
              checked={firewallHandleValues}
              onChange={() => {setfirewallHandleValues('drop')}}
            />

            <RadioAtom
              label={'Continue'}
              description={'Continue processing rules without context from previous traffic.'}
              value={'continue'}
              name={''}
              checked={firewallHandleValues}
              onChange={() => {setfirewallHandleValues('continue')}}
            />

            <RadioAtom
              label={'Reject'}
              description={'Fails closed, sends a TCP reset packet to the sender, and drops all subsequent traffic going to the firewall.'}
              value={'reject'}
              name={''}
              checked={firewallHandleValues}
              onChange={() => {setfirewallHandleValues('reject')}}
            />

          </div>
        </div>
        <div className="button-group">
          <button onClick={baseModalProps.onClose}>Cancel</button>
          <button className="delete-btn" onClick={() => {}}>
            Save
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default EditFirewallPolicyDetailModal;
