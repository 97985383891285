import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import DetailTabTargetGroup from '../Common/components/DetailTabTargetGroup';
import { IMgdDetailKeyValueProps, TextTypeEnum } from 'layouts/v3/MgdLayout';
import { DetailPropsType } from '../types';
import lazyGetAwsDescribeTargetHealth, { IGetAwsDescribeTargetHealthVariables } from 'graphql/queries/getAwsDescribeTargetHealth';
import { AwsTargetHealthDescription } from 'graphql/types/AwsDescribeTargetHealthResponse';

const Details = (props: DetailPropsType) => {
  const { targetGroup, cloudId, region, isSummaryHidden } = props;
  const [getAwsDescribeTargetHealth, {loading: awsDescribeTargetHealthLoading}] = lazyGetAwsDescribeTargetHealth();

  const [targetHealthDescriptions, setTargetHealthDescriptions] = useState<AwsTargetHealthDescription[]>([]);

  const fetchGetAwsDescribeTargetHealth = useCallback(() => {
    const healthVariableRequest: IGetAwsDescribeTargetHealthVariables = {
      cloudId: cloudId,
      region: region,
      request: {
        targetGroupArn: targetGroup?.targetGroupArn
      },
    };
    getAwsDescribeTargetHealth({variables: healthVariableRequest}).then(({data: awsDescribeTargetHealthData}) => {
      setTargetHealthDescriptions(awsDescribeTargetHealthData?.getAwsDescribeTargetHealth?.data?.[0]?.targetHealthDescriptions || []);
    });
  }, [cloudId, region, targetGroup]);

  const detailsTargetData = useMemo((): IMgdDetailKeyValueProps[] => {

    return [
      {
        title: 'Target type',
        description: targetGroup?.targetType,
        type: TextTypeEnum.COPY,
      },
      {
        title: 'Protocol:Port',
        description: `${targetGroup?.protocol}:${targetGroup?.port}`,
        type: TextTypeEnum.STATUS,
      },
      {
        title: 'Protocol version',
        description: targetGroup?.protocolVersion,
        type: TextTypeEnum.NORMAL,
      },
      {
        title: 'VPC',
        description: targetGroup?.vpcId,
        type: TextTypeEnum.LINK,
      },
      {
        title: 'IP address type',
        description: targetGroup?.ipAddressType,
        type: TextTypeEnum.NORMAL,
      },
      {
        title: 'Load balancer',
        description: targetGroup?.loadBalancers?.map((lb) => lb.loadBalancerName),
        type: TextTypeEnum.LINK,
      },
    ];
  }, [targetGroup]);

  const detailsTargetHealthData = useMemo((): IMgdDetailKeyValueProps[] => {
    
    return [
      {
        title: 'Total targets',
        description: targetHealthDescriptions.length,
        type: TextTypeEnum.NORMAL,
      },
      {
        title: 'Health',
        description: targetHealthDescriptions.filter((target) => target?.targetHealth?.state == 'healthy').length,
        type: TextTypeEnum.GREEN,
      },
      {
        title: 'Anomalous',
        description: targetHealthDescriptions.filter((target) => target?.anomalyDetection?.result == 'anomalous').length,
        type: TextTypeEnum.RED,
      },
      {
        title: 'Unhealth',
        description: targetHealthDescriptions.filter((target) => target?.targetHealth?.state == 'unhealthy').length,
        type: TextTypeEnum.NORMAL,
      },
      {
        title: 'Unused',
        description: targetHealthDescriptions.filter((target) => target?.targetHealth?.state == 'unused').length,
        type: TextTypeEnum.NORMAL,
      },
      {
        title: 'Initial',
        description: targetHealthDescriptions.filter((target) => target?.targetHealth?.state == ' initial').length,
        type: TextTypeEnum.NORMAL,
      },
      {
        title: 'Draining',
        description: targetHealthDescriptions.filter((target) => target?.targetHealth?.state == 'draining').length,
        type: TextTypeEnum.NORMAL,
      },
    ];
  }, [targetHealthDescriptions]);

  useEffect(() => {
    fetchGetAwsDescribeTargetHealth();
  }, []);

  if (awsDescribeTargetHealthLoading) {
    return (
      <div className="detail-info">
        <div className="progress-container">
          <div className="progress-gif" />
          Loading ...
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      <DetailTabTargetGroup
        title="Details"
        description={targetGroup?.targetGroupArn}
        textTypeDescription="COPY"
        data={detailsTargetData}
        handleLinkClick={() => {}}
      />

      <DetailTabTargetGroup
        styleClass={'border-top-none'}
        numberOfColumns={1}
        data={detailsTargetHealthData}
        handleLinkClick={() => {}}
      />
    </Fragment>
  );
}

export default Details;
