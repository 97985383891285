import { useLazyQuery } from '@apollo/client';
import query from './query';
import { AwsGetGroupQueryResponse } from 'graphql/types/AwsGroupQuery';
import { IGqlResponseNewData } from 'graphql/types';

export interface IGetAwsGroupQueryVariables {
  cloudId: number;
  region: string;
  request: {
    groupName?: string;
    group?: string;
  }
}

export interface IAwsGroupQueryResponseData {
  getAwsGroupQuery: IGqlResponseNewData<AwsGetGroupQueryResponse>;
}

const lazyGetAwsGroupQuery = () => useLazyQuery<IAwsGroupQueryResponseData, IGetAwsGroupQueryVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsGroupQuery;