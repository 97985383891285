import Icon from 'components/v2/atoms/Icon';
import React, { useEffect, useState } from 'react';
import BackIcon from 'assets/svgs/v3/ico_back.svg';
import FavoriteIcon from 'assets/svgs/v3/ico_favorite.svg';
import UnFavoriteIcon from 'assets/svgs/v3/ico_un_favorite.svg';
import AwsIcon from 'assets/svgs/v3/aws.svg';
import { useOutletContext } from 'react-router-dom';
import { MgdLayoutTypeEnum } from 'layouts/v3/MgdLayout';

interface INetworkTitleProps {
  pageTitle: string;
  hasPrefixIcon?: boolean;
  onClick?: () => void;
  id: number;
  name: string;
  hasFavorite?: boolean;
  pageBackClick: () => void
}

interface IFavoriteList {
  id: number;
  name: string;
}

const NetworkTitle = ({ pageTitle, hasPrefixIcon, onClick, id, name, hasFavorite, pageBackClick }: INetworkTitleProps) => {
  const [isFavorite, setIsFavorite] = useState<boolean>(false);

  const { favoriteList, updateFavoriteList } = useOutletContext<{
    favoriteList: IFavoriteList[];
    updateFavoriteList: (value: IFavoriteList[]) => void;
  }>();

  useEffect(() => {
    if (favoriteList.find(item => item.id === id)) {
      return setIsFavorite(true);
    }
    return setIsFavorite(false);
  }, []);

  const onFavorite = () => {
    onClick?.();
    setIsFavorite(!isFavorite);

    //!NOTE: can be use it in Parent Component instead onClick
    if (favoriteList.find(item => item.id === id)) {
      updateFavoriteList(favoriteList.filter(favor => favor.id !== id));
    } else {
      updateFavoriteList([...favoriteList, { id, name }]);
    }
  };

  return (
    <>
      <Icon className="back-btn" width={12} height={12} src={BackIcon} onClick={pageBackClick}/>
      {hasPrefixIcon && <Icon width={16} height={16} src={AwsIcon} />}
      {pageTitle}
      {hasFavorite &&
        <Icon
          onClick={onFavorite}
          width={16}
          height={16}
          src={!isFavorite ? UnFavoriteIcon : FavoriteIcon}
        />
      }
    </>
  );
};

export default NetworkTitle;
