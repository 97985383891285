import React from 'react';
import { SectionContainerPropsType } from './types';
import SectionTitle from './SectionTitle';
import './styles.scss';

const SectionContainer = (props: SectionContainerPropsType) => {
  const { title, children, caption, rightButtons, description, bottomDescription, customStyle } = props;

  return (
    <div className={`section-container ${customStyle}`}>
      {title && (
        <SectionTitle
          title={title}
          hasBottomBorder
          caption={caption}
          description={description}
          rightButtons={rightButtons}
        />
      )}
      <div className="section-content">{children}</div>
      {bottomDescription && <p className='bottom-description'>{bottomDescription}</p>}
    </div>
  );
};

export default SectionContainer;
