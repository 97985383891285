import React, {useEffect, useState} from 'react';
import {ebsVolumeTab} from "pages/v2/Organ/Management/EC2/components/EBSVolume/Common/Constant";
import lazyGetAwsDescribeVolumes from "graphql/queries/getAwsDescribeVolumes";
import lazyGetAwsDescribeVolumeStatus from "graphql/queries/getAwsDescribeVolumeStatus";
import lazyGetAwsListAliases from "graphql/queries/getAwsListAliases";
import lazyGetAwsDescribeVolumeAttribute from "graphql/queries/getAwsDescribeVolumeAttribute";
import {variableCombineNextToken} from "pages/v2/Organ/Management/Utils";
import Details from "pages/v2/Organ/Management/EC2/components/EBSVolume/Common/Components/Details";
import StatusChecks from "pages/v2/Organ/Management/EC2/components/EBSVolume/Common/Components/StatusChecks";
import Tags from "pages/v2/Organ/Management/EC2/components/EBSVolume/Common/Components/Tags";
import PageDetailTitle from "pages/v2/Organ/Management/EC2/components/PageDetailTitle";

import './index.scss';

function EBSVolumeDetail({cloudId, region, pageDetail, pageBackClick}: any) {
  const [isLoadingDetail, setIsLoadingDetail] = useState(false);
  const [isLoadingStatus, setIsLoadingStatus] = useState(false);

  const volumeTabDetail = [...ebsVolumeTab];
  volumeTabDetail.shift();
  const [data, setData] = useState<any>();
  // @ts-ignore
  const [tabSelected, setTabSelected] = useState(volumeTabDetail[0]);
  const [volumeAttribute, setVolumeAttribute] = useState(null);

  const [getAwsDescribeVolumes] = lazyGetAwsDescribeVolumes();
  const [getAwsDescribeVolumeStatus] = lazyGetAwsDescribeVolumeStatus();
  const [getAwsListAliases] = lazyGetAwsListAliases();
  const [getAwsDescribeVolumeAttribute] = lazyGetAwsDescribeVolumeAttribute();

  const handleGetAwsDescribeVolumes = async (volumeIds: string) => {
    if (!volumeIds) return {};

    const payload = {
      cloudId,
      region,
      request: {
        volumeIds
      },
    };

    return getAwsDescribeVolumes({
      variables: variableCombineNextToken(payload),
    })
  }

  const handleGetAwsDescribeVolumeStatus = async (volumeIds: string) => {
    if (!volumeIds) return {};

    const payload = {
      cloudId,
      region,
      request: {
        volumeIds,
      }
    }

    return getAwsDescribeVolumeStatus({
      variables: variableCombineNextToken(payload),
    })
  };

  const handleGetAwsListAliases = async (keyId: string) => {
    if (!keyId) return {};

    const payload = {
      cloudId,
      region,
      request: {
        keyId,
      }
    }

    return getAwsListAliases({
      variables: variableCombineNextToken(payload),
    })
  };

  const handleGetAwsDescribeVolumeAttribute = async (volumeId: string) => {
    try {
      setIsLoadingStatus(true)
      if (!volumeId) return {};

      const payload = {
        cloudId,
        region,
        request: {
          volumeId,
          attribute : "autoEnableIO"
        }
      }

      const res = await getAwsDescribeVolumeAttribute({
        variables: variableCombineNextToken(payload),
      })

      setVolumeAttribute(res?.data?.getAwsDescribeVolumeAttribute?.data?.[0])
      setIsLoadingStatus(false)
    } catch (e) {
      setIsLoadingStatus(false)
    }
  };

  const handleGetDetail = async () => {
    try {
      setIsLoadingDetail(true)
      const [resAwsDescribeVolumes, resAwsDescribeVolumeStatus, resAwsListAliases]: any = await Promise.all([
        handleGetAwsDescribeVolumes(pageDetail?.volumeId),
        handleGetAwsDescribeVolumeStatus(pageDetail?.volumeId),
        handleGetAwsListAliases(pageDetail?.kmsKeyId)
      ])

      const data = {
        volumeStatus: resAwsDescribeVolumeStatus?.data?.getAwsDescribeVolumeStatus?.data?.[0]?.volumeStatuses,
        alias: resAwsListAliases?.data?.getAwsListAliases?.data?.[0]?.aliases?.[0],
        ...resAwsDescribeVolumes?.data?.getAwsDescribeVolumes?.data?.[0]?.volumes?.[0],
      }

      setData(data)
      setIsLoadingDetail(false)
    } catch (e) {
      setIsLoadingDetail(false)
    }
  }

  useEffect(() => {
    setTabSelected(volumeTabDetail[0])

    if (!pageDetail?.id) return;

    handleGetDetail()
  }, [pageDetail?.id]);

  useEffect(() => {
    setVolumeAttribute(null)

    if (!pageDetail?.id || tabSelected.id !== volumeTabDetail[0].id) return;

    handleGetAwsDescribeVolumeAttribute(pageDetail?.volumeId)
  }, [tabSelected]);

  const renderTab = () => {
    switch (tabSelected.id) {
      case volumeTabDetail[0].id:
        return <StatusChecks data={data} volumeAttribute={volumeAttribute} isLoading={isLoadingStatus} />

      case volumeTabDetail[1].id:
        return <Tags data={data} />

      default:
        return null
    }
  }
  
  return (
    <div className='ec2-detail'>
      <PageDetailTitle
        title={pageDetail?.volumeId}
        pageBackClick={pageBackClick}
        isDelete
        onDelete={() => {
        }}
      />

      <>
        <Details data={data} isLoading={isLoadingDetail} />

        <div className='ec2-detail-tab'>
          <div className="detail-tab flex a-center">
            {volumeTabDetail.map(tab => {
              return (
                <button
                  className={`detail-tab-items ${tabSelected?.id === tab.id && 'active'}`}
                  key={tab.id}
                  data-tab={tab.id}
                  onClick={e => setTabSelected(tab)}
                >
                  <p>{tab.name}</p>
                </button>
              );
            })}
          </div>

          <div className="content-tab">
            {renderTab()}
          </div>
        </div>
      </>
    </div>
  );
}

export default EBSVolumeDetail;