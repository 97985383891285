import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export interface IAddProjectCloudVariables {
  reqAddProjectCloud: {
    projectId: number;
    rootAccount: string;
    cloudId: number;
    name: string;
    serviceType: string
    features: string
    msspAttrKey: string
    msspAttrValue: string
    attrKey1?: string;
    attrValue1?: string;
    attrKey2?: string;
    attrValue2?: string;
    attrKey3?: string;
    attrValue3?: string;
    attrKey4?: string;
    attrValue4?: string;
  }
}

export interface IAddProjectCloudResponseData {
  addProjectCloud: IGqlResponseData<undefined>;
}

const addProjectCloud = () => useMutation<IAddProjectCloudResponseData, IAddProjectCloudVariables>(query);
export default addProjectCloud;