import Icon from "components/v2/atoms/Icon";
import BaseModal, { IBaseModalProps } from "components/v2/modals/BaseModal";
import IconEditRequest from 'assets/svgs/v3/ico_edit_vpc_subnets.svg';
import { useCallback, useEffect, useState } from "react";
import InputAtom from 'components/v2/atoms/InputAtom';
import './EditRuleGroupInfo.scss';

interface EditRuleGroupInfoModalPropsType extends IBaseModalProps {
  description: string;
  onSave: (description: string) => void;
}

const EditRuleGroupInfoModal = (props: EditRuleGroupInfoModalPropsType) => {
  const {description, onSave, ...baseModalProps} = props;
  const [descriptionUpdate, setDescriptionUpdate] = useState<string>(description);

  const handleSaveButton = useCallback(() => {
    onSave(descriptionUpdate);
  }, [descriptionUpdate, onSave]);

  useEffect(() => {
    if (baseModalProps.open) {
      setDescriptionUpdate(description);
    }
  }, [baseModalProps.open]);
  
  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={IconEditRequest} />
          <div className="title-header-modal">Edit Rule Group Info</div>
        </>
      )}
      {...baseModalProps}
    >
      <div className="edit-regex-pattern-set-info-model">

        <div className="content-container">
          <div className="title">
            <p>Description</p>
          </div>
          <div className="input-container">
            <InputAtom
              placeholder={'입력'}
              className={'input-add'}
              onChangeValue={setDescriptionUpdate}
              value={descriptionUpdate}
              required={true}
              noClear={false}
            />
          </div>
        </div>

        <div className="button-group">
          <button className="btn" onClick={baseModalProps.onClose}>
            Cancel
          </button>
          <button className="btn save-btn" onClick={handleSaveButton} disabled={descriptionUpdate.trim() === ''}>
            Save
          </button>
        </div>
      </div>
    </BaseModal>
  );
}

export default EditRuleGroupInfoModal;