import { useState, useEffect } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import IconEditRule from 'assets/svgs/v3/ico_policy_variables.svg';
import IconAddNewRule from 'assets/svgs/v3/ico_add_new_rule.svg'
import Icon from 'components/v2/atoms/Icon';
import './index.scss';
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import RadioAtom from 'components/v2/atoms/RadioAtom';
import InputAtom from 'components/v2/atoms/InputAtom';

interface IEditRuleModalProps extends IBaseModalProps {
  enumType: 'editRule' | 'addRule'
  header: string;
  onChangeValue?: (index: number, value: string) => void;
}

export interface ruleTrafficRowData {
  index: number;
  valueKeyword: string;
  valueSetting: string;
}

const protocolDummy: DropdownListDataType[] = [];
const sourcePortDummy: DropdownListDataType[] = [];
const destinationPortDummy: DropdownListDataType[] = [];

const EditRuleModal = ({ enumType, header, onChangeValue, ...baseModalProps }: IEditRuleModalProps) => {
  const [ruleTrafficRows, setRuleTrafficRows] = useState<ruleTrafficRowData[]>([]);
  const [sourceInputValue, setSourceInputValue] = useState<string>('');
  const [destinationInputValue, setDestinationInputValue] = useState<string>('');
  const [sourceTextareaValue, setSourceTextareaValue] = useState<string>('');
  const [destinationTextareaValue, setDestinationTextareaValue] = useState<string>('');
  const [sourcePortTextareaValue, setSourcePortTextareaValue] = useState<string>('');
  const [destinationPortTextareaValue, setDestinationPortTextareaValue] = useState<string>('');

  const [trafficValue, setTrafficValue] = useState<string>('forward');
  const [actionTrafficValue, setActionTrafficValue] = useState<string>('pass');
  const [payload, setPayload] = useState<string>('');

  useEffect(() => {
    if (baseModalProps.open) {
      setPayload('');
    }
  }, [baseModalProps.open]);

  const handleCreate = () => {
    setRuleTrafficRows(prevState => {
      const newState = prevState.concat({ index: ruleTrafficRows.length, valueKeyword: '', valueSetting: '' });
      return newState;
    });
  };

  const handleDelete = (indexToRemove: number) => {
    const newData = ruleTrafficRows.filter((_, index) => index != indexToRemove);
    setRuleTrafficRows(newData);
  };

  const handleChange = (index: number, value: string, propName: keyof ruleTrafficRowData) => {
    const newRows = ruleTrafficRows.map((row, i) => (i === index ? { ...row, [propName]: value } : row));
    setRuleTrafficRows(newRows);
  };

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={enumType === 'editRule' ? IconEditRule : IconAddNewRule} />
          {header}
        </>
      )}
      {...baseModalProps}
    >
      <div className="edit-rule-modal">
        <div className="protocol-container">
          {enumType === 'addRule' && (
              <>
                <div className="add-rule-column">
                  <p className="text-title">Standard stateful rules</p>
                  <p className="text-detail">
                    Add the stateful rules that you need in your rule group. Each rule that you add is listed in the Rules
                    table below.
                  </p>
                </div>
              </>
            )}

          <div className="column">
            <p className="text-title">Protocol</p>
            <p className="text-detail">Transport protocols to inspect for</p>
            <DropdownAtom
              id={'edit-rule-modal-protocol-dropdown'}
              placeholder={'IP-set reference'}
              data={protocolDummy}
              value={{
                id: 1,
                name: '',
                description: '',
                value: '',
              }}
              handleClick={() => {}}
            />
          </div>
        </div>

        <div className="source-container">

          <div className="column">
            <p className="text-title">Source</p>
            <p className="text-detail">The source IP addresses and address ranges to inspect fo</p>
            <InputAtom
              type={'text'}
              value={sourceInputValue}
              onChangeValue={value => setSourceInputValue(value)}
              noClear={true}
              disabled={false}
            />
            <div className="textarea-container">
              <textarea value={sourceTextareaValue} onChange={event => setSourceTextareaValue(event.target.value)} />
            </div>
            <p className="note">You can provide single addresses and CIDR blocks</p>
          </div>

          <div className="column">
            <p className="text-title">Source port</p>
            <p className="text-detail">The source port or port range to inspect for.</p>
            <DropdownAtom
              id={'edit-rule-modal-source-port-dropdown'}
              data={sourcePortDummy}
              value={{
                id: 1,
                name: '',
                description: '',
                value: '',
              }}
              handleClick={() => {}}
            />
            <div className="textarea-container">
              <textarea
                value={sourcePortTextareaValue}
                onChange={event => setSourcePortTextareaValue(event.target.value)}
              />
            </div>
            <p className="note">Supported ports are :34354</p>
          </div>
        </div>
        <div className="destination-container">
          <div className="column">
            <p className="text-title">Destination</p>
            <p className="text-detail">The source IP addresses and address ranges to inspect fo</p>

            <InputAtom
              type={'text'}
              value={destinationInputValue}
              noClear={true}
              onChangeValue={value => setDestinationInputValue(value)}
              disabled={false}
            />
            <div className="textarea-container">
              <textarea
                value={destinationTextareaValue}
                onChange={event => setDestinationTextareaValue(event.target.value)}
              />
            </div>
            <p className="note">You can provide single addresses and CIDR blocks</p>
          </div>
          <div className="column">
            <p className="text-title">Destination port</p>
            <p className="text-detail">The destination port or port range to inspect for.</p>

            <DropdownAtom
              id={'edit-rule-modal-destination-port-dropdown'}
              data={destinationPortDummy}
              value={{
                id: 1,
                name: '',
                description: '',
                value: '',
              }}
              handleClick={() => {}}
            />
            <div className="textarea-container">
              <textarea
                value={destinationPortTextareaValue}
                onChange={event => setDestinationPortTextareaValue(event.target.value)}
              />
            </div>
            <p className="note">Supported ports are : 54546</p>
          </div>
        </div>
        <div className="traffic-direction-container">
          <p className="text-title">Traffic direction</p>
          <p className="text-detail">
            The traffic direction to inspect for. Network Firewall doesn’t automatically add the direction key word
            to_server, and will inspect all the packets in the flow, irrespective of the flow state.
          </p>
          <div className="traffic-direction-content">
            <div className="row-1">
              <div className="radio-content">
                <RadioAtom
                  label={'Forward'}
                  description={
                    'Inspect traffic that flows forward, from the specified source to the specified destination.'
                  }
                  value={'forward'}
                  name={''}
                  checked={trafficValue}
                  onChange={() => {
                    setTrafficValue('forward');
                  }}
                />

                <RadioAtom
                  label={'Any'}
                  description={'Inspect traffic from any direction.'}
                  value={'any'}
                  name={''}
                  checked={trafficValue}
                  onChange={() => {
                    setTrafficValue('any');
                  }}
                />
              </div>
              <div className="action-radio-content">
                <p className="text-sub-title">Action</p>
                <p className="text-description">
                  The action that you want Network Firewall to take when a packet matches the rule settings.
                </p>
                <RadioAtom
                  label={'Pass'}
                  value={'pass'}
                  name={''}
                  checked={actionTrafficValue}
                  onChange={() => {
                    setActionTrafficValue('pass');
                  }}
                />

                <RadioAtom
                  label={'Drop'}
                  value={'drop'}
                  name={''}
                  checked={actionTrafficValue}
                  onChange={() => {
                    setActionTrafficValue('drop');
                  }}
                />

                <RadioAtom
                  label={'Alert'}
                  value={'alert'}
                  name={''}
                  checked={actionTrafficValue}
                  onChange={() => {
                    setActionTrafficValue('alert');
                  }}
                />
                <div className="reject-radio">
                  <RadioAtom label={'Reject'} value={'reject'} name={''} checked={''} onChange={() => {}} />
                </div>
              </div>
            </div>

            <div className="row-2">
              <p className="text-sub-title">Rule options - optional</p>
              <p className="text-description">No rule options associated with this rule.</p>
              <div className="rule-options-container">
                <div className="content">
                  <div className="header-container">
                    <div className="column">
                      <p className="text-title">Keyword</p>
                      <p className="text-description">
                        You must include a Sid (signature ID), and can optionally include other Suricate keywords.
                      </p>
                    </div>
                    <div className="column">
                      <p className="text-title">Settings - optional</p>
                      <p className="text-description">The settings of the Suricate compatible rule option.</p>
                    </div>
                  </div>

                  {ruleTrafficRows && (
                    <>
                      {ruleTrafficRows.map((row, index) => (
                        <div className="input-container" key={`edit-rule-traffic-new-row-${index}`}>
                          <div className="rule-traffic-row-input">
                            <div className="column">
                              <InputAtom
                                type={'text'}
                                value={row.valueKeyword}
                                noClear={true}
                                disabled={false}
                                onChangeValue={(value: string) => handleChange(index, value, "valueKeyword")}
                              />
                              <p className="note">Enter one keyword</p>
                            </div>
                            <div className="column">
                              <div className="textarea-container">
                                <textarea
                                  value={row.valueSetting}
                                  onChange={event => handleChange(index, event.target.value , "valueSetting")}
                                />
                              </div>
                              <p className="note">Enter one value per line.</p>
                            </div>
                          </div>
                          <button className="btn remove-btn " onClick={() => handleDelete(index)}>
                            Remove
                          </button>
                        </div>
                      ))}
                    </>
                  )}

                </div>
              </div>
              <button className="btn add-btn" onClick={handleCreate}>
                Add rule options
              </button>
            </div>
          </div>
        </div>
        <div className="button-group">
          <button onClick={baseModalProps.onClose}>Cancel</button>
          <button className="delete-btn" onClick={() => {}}>
            Save
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default EditRuleModal;
