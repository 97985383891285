import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsDescribeReplaceRootVolumeTaskType } from 'graphql/types/AwsDescribeReplaceRootVolumeTask';

export interface IAwsDescribeReplaceRootVolumeTasksVariables {
  cloudId: number;
  region: string;
  request: {
    filters: {
      name: string;
      values: Array<string>;
    };
  };
}

export interface IAwsDescribeReplaceRootVolumeTasksResponseData {
  getAwsDescribeReplaceRootVolumeTasks: IGqlResponseNewData<AwsDescribeReplaceRootVolumeTaskType>;
}

const lazyGetAwsDescribeReplaceRootVolumeTasks = () =>
  useLazyQuery<IAwsDescribeReplaceRootVolumeTasksResponseData, IAwsDescribeReplaceRootVolumeTasksVariables>(query, {
    fetchPolicy: 'no-cache',
  });
export default lazyGetAwsDescribeReplaceRootVolumeTasks;
