import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsGenerateServiceLastAccessedDetailsResponseEntry } from 'graphql/types/AwsGenerateServiceLastAccessedDetailsResponse';

export interface IAwsGenerateServiceLastAccessedDetailsVariables {
  cloudId: number;
  region: string;
  request: {
    arn: string;
  };
}

export interface IAwsGenerateServiceLastAccessedDetailsResponseData {
  getAwsGenerateServiceLastAccessedDetails: AwsGenerateServiceLastAccessedDetailsResponseEntry;
}

const lazyGetAwsGenerateServiceLastAccessedDetails = () =>
  useLazyQuery<IAwsGenerateServiceLastAccessedDetailsResponseData, IAwsGenerateServiceLastAccessedDetailsVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsGenerateServiceLastAccessedDetails;
