import { useEffect, useMemo, useRef, useState } from 'react';
import './index.scss';
import ProjectPreference from './ProjectPreference';
import Member from '../SettingModal/Member';
import { ProjectInfo } from 'graphql/types/ProjectInfo';

const MENU = [
  {
    label: '프로젝트',
    value: 'PROJECT',
    icon: 'project-icon'
  },
  {
    label: '구성원',
    value: 'MEMBERS',
    icon: 'member-icon'
  }
];

export interface IProjectSettingModalProps {
  /** 모달이 켜지는 조건 */
  projectSettingOpen: boolean;
  /** 모달이 꺼지는 함수 */
  onClose: () => void;
  /** className을 통한 외부 css 커스텀 @default 'default' */
  className?: string;
  /** 모달 내 우상단 닫기아이콘 보이기 유무 @default true */
  closeIcon?: boolean;
  /** 모달 바깥쪽 클릭을 통한 모달종료 @default true */
  closeOnClickBg?: boolean;
  /** 모달 애니메이션 재생시간 100ms단위, 설정범위: 양의정수 1~10 @default 3 */
  duration?: number;
  /** 모달 외부 장막의 opacity값 0.1단위, 설정범위: 양의정수 1~10 @default 3 */
  backgroundOpacity?: number;
  /** 기존에 켜져있는 모달의 중첩(레이어드)모달로써 사용할 경우 border-radius값 조정 필요 */
  borderRadius?: number;
  /* 프로젝트 정보 */
  projectInfo: ProjectInfo;
}

let timer:NodeJS.Timeout;

const ProjectSettingModal = ({
  projectSettingOpen,
  onClose,
  className,
  closeIcon = true,
  closeOnClickBg = true,
  duration = 3,
  backgroundOpacity = 3,
  borderRadius,
  projectInfo
}: IProjectSettingModalProps) => {

  const modalRef = useRef<HTMLDivElement>(null);

  /** 모달 고유 ID */
  const [id] = useState(Date.now());
  const [selected, setSelected] = useState({
    label: '',
    value: ''
  });

  useEffect(() => {
    if (projectSettingOpen) {
      document.body.setAttribute(`modal-id-${id}`, 'true');
      modalRef.current?.classList.remove('out', 'active');
      modalRef.current?.classList.add('active');
      
      setSelected({
        label: '프로젝트',
        value: 'PROJECT'
      });

      clearTimeout(timer);
    } else {
      modalRef.current?.classList.add('out');
      document.body.removeAttribute(`modal-id-${id}`);
      
      timer = setTimeout(() => {
        setSelected({
          label: '',
          value: ''
        });
      }, 3000);
    }
  }, [projectSettingOpen]);

  const renderComponent = useMemo(() => {
    switch (selected.value) {
    default:  
      return <ProjectPreference 
      projectInfo={projectInfo}
      projectSettingOpen={projectSettingOpen}
      onClose={onClose}
    />;

    case 'MEMBERS':
      return <Member 
        projectInfo={projectInfo}
      />;
    }
  }, [selected, onClose]);

  return (
    <div
      ref={modalRef}
      className="project-modal-container modal-duration-5"
      data-duration={duration}
      data-bg-opacity={backgroundOpacity}
      data-border-radius={borderRadius}
    >
      <div 
        className="modal-background" 
        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          if (!closeOnClickBg) return;
          if (e.target === e.currentTarget) onClose && onClose();
        }}
      >
        <div className={`project-modal ${className} flex`}>
          <div className="col-left">
            <h5>설정</h5>
            {
              MENU.map((val, idx) => (
                <div 
                  key={idx}
                  className={`flex a-center ${selected.value === val.value && 'active'}`}
                  onClick={() => setSelected({
                    label: val.label,
                    value: val.value
                  })}
                >
                  <div className={`icon ${val.icon}`} />
                  <p>{val.label}</p>
                </div>
              ))
            }
          </div>
          <div className={`col-right ${selected.value === 'MEMBERS' && 'theme'}`}>
            <div className="modal-head flex j-between a-center">
              <div className="modal-title flex a-center">{selected.label}</div>
              { closeIcon && <button className="close-btn" onClick={onClose} /> }
            </div>
            <div className="modal-content">
              {renderComponent}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectSettingModal;
