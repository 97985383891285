import {gql} from '@apollo/client';

const query = gql`
query getAwsTags($request: AwsGetTagsRequest, $cloudId: Long, $region: String) {
  getAwsTags(getTagsRequest: $request, cloudId: $cloudId, region: $region) {
    result
    data {
      ... on AwsGetTagsResponse {
        arn
        tags
      }
    }
  }
}`;
export default query;