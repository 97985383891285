import { useLazyQuery } from '@apollo/client';
import query from './query';
import { Paging } from 'graphql/types/Paging';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsDescribeImageResponse, AwsImageType } from 'graphql/types/AwsImage';

export interface IAwsDescribeImagesVariables {
  cloudId: number;
  region: string;
  request: {
    executableUsers?: string[];
    filters?: {
      name: string;
      values: string[];
    }[];
    imageIds?: string[];
    owners?: string[];
    includeDeprecated?: boolean;
    includeDisabled?: boolean;
    dryRun?: boolean;
    maxResults?: number;
    nextToken?: string;
  }
}

export interface IAwsAwsDescribeImageResponseData {
  getAwsDescribeImages: IGqlResponseNewData<AwsDescribeImageResponse>;
}

const lazyGetAwsDescribeImages = () => useLazyQuery<IAwsAwsDescribeImageResponseData, IAwsDescribeImagesVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsDescribeImages;