import { useLazyQuery } from '@apollo/client';
import query from './query';
import { AwsGetGroupResponse } from 'graphql/types/AwsGroup';

export interface IGetAwsGroupVariables {
  cloudId: number;
  region: string;
  request: {
    groupName?: string;
    group?: string;
  }
}

export interface IAwsGroupResponseData {
  getAwsGroup: AwsGetGroupResponse;
}

const lazyGetAwsGroup = () => useLazyQuery<IAwsGroupResponseData, IGetAwsGroupVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsGroup;