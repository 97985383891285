import React from 'react';
import PropTypes from 'prop-types';
import { InputPropsType } from './types';
import InputAtom from 'components/v2/atoms/InputAtom';
import Icon from 'components/v2/atoms/Icon';
import RequiredIcon from 'assets/svgs/v2/ico_require.svg';

const Input = (props: InputPropsType) => {
  const {
    title,
    description,
    topDescription,
    bottomDescription,
    placeholder,
    value,
    onValueChanged,
    isRequired,
    disabled,
    isVertical,
    error,
  } = props;

  if (isVertical) {
    return (
      <div id="input-container">
        <div className="left-column-content">
          <p className="title">{title}</p>
          {isRequired && <img src={RequiredIcon} width={4} height={4} />}
        </div>
        <InputAtom
          className="value"
          required
          value={value}
          onChangeValue={onValueChanged}
          placeholder={placeholder}
          disabled={disabled}
          error={error}
        />
      </div>
    );
  }

  return (
    <tbody id="input-container">
      <tr className="row-content">
        <td className="left-column-content" />
        <td>
          <p className="description">{topDescription}</p>
        </td>
      </tr>
      <tr className="row-content">
        <td className="left-column-content">
          <p className="title">{title}</p>
          {isRequired && <img src={RequiredIcon} width={4} height={4} />}
        </td>
        <InputAtom
          className="value"
          required
          value={value}
          onChangeValue={onValueChanged}
          placeholder={placeholder}
          disabled={disabled}
          error={error}
        />
      </tr>
      <tr className="row-content">
        <td className="left-column-content" />
        <td>
          <p className="description">{bottomDescription}</p>
        </td>
      </tr>
    </tbody>
  );
};

export default Input;
