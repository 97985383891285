import { useLazyQuery } from '@apollo/client';
import query from './query';
import { Paging } from 'graphql/types/Paging';
import { AwsDescribeSecretResponse } from 'graphql/types/AwsDescribeSecret';

export interface IAwsDescribeSecretVariables {
  cloudId: number;
  region: string;
  request: {
    secretId: string;
  }
}

export interface IAwsDescribeSecretResponseData {
  getAwsDescribeSecret: AwsDescribeSecretResponse;
}

const lazyGetAwsDescribeSecret = () => useLazyQuery<IAwsDescribeSecretResponseData, IAwsDescribeSecretVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsDescribeSecret;