import React, { Fragment, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { ImageSummaryPropsType } from '../types';
import { SUMMARY_TAB_LIST, detailTabKeys, detailTabTextDes } from './Common/Constants';
import { IMgdDetailKeyValueProps, IMgdTabProps, TextTypeEnum } from 'layouts/v3/MgdLayout';
import DetailTab from '../DetailTab';
import './ImageSummary.scss';
import { AwsBlockDeviceMappingType } from 'graphql/types/AwsImage';
import Permission from './components/Permission';
import Storage from './components/Storage';
import { ColumnType, RowType } from '@Types/v2/Table';
import TagTab from '../TagTab';

const ImageSummary = (props: ImageSummaryPropsType) => {
  const { detailData, cloudId, region } = props;

  // State
  const [apiIsLoading, setApiIsLoading] = useState<boolean>(false);
  const [summaryTabSelected, setSummaryTabSelected] = useState<IMgdTabProps>(SUMMARY_TAB_LIST[0]);

  const imageId = useMemo((): string => {
    return detailData?.imageId ?? '';
  }, [detailData]);

  const getBlockDeviceMapping = useCallback((blockDeviceMappings: AwsBlockDeviceMappingType[]): string[] => {
    const result = blockDeviceMappings.map(block => {
      const ebs = block?.ebs;
      if (!!ebs) {
        const { snapshotId, volumeSize, deleteOnTermination, volumeType } = ebs;
        return `${block.deviceName}=${snapshotId}:${volumeSize}:${deleteOnTermination}:${volumeType}`;
      }
      return '';
    });
    return result;
  }, []);

  const detailTabContentData = useMemo((): IMgdDetailKeyValueProps[] => {
    const detailTabData: IMgdDetailKeyValueProps[] = [];
    const detailTabDataOrdered: IMgdDetailKeyValueProps[] = [];
    if (detailData) {
      Object.entries(detailData).map(([key, value]) => {
        if (detailTabKeys.indexOf(key) > -1) {
          const textDes = detailTabTextDes.find(text => text.id == key);
          let desValue: string | string[] = '-';

          if (typeof value === 'string') {
            desValue = value.toString();

            if (key === detailTabKeys[0]) desValue += ` (${detailData.name})`;
          }

          if (key === detailTabKeys[13]) {
            desValue = detailData?.stateReason?.message ?? '-';
          }

          if (key === detailTabKeys[17]) {
            desValue = detailData?.productCodes?.[0].productCodeId ?? '-';
          }

          if (key === detailTabKeys[21]) {
            desValue = getBlockDeviceMapping(detailData.blockDeviceMappings).filter(item => item !== '');
          }

          // TODO: deregistrationProtection and lastLaunchTime

          if (textDes) {
            detailTabData.push({
              id: key,
              type: textDes.type as TextTypeEnum,
              title: textDes.value,
              description: desValue,
            });
          }
        }
      });

      // order data
      detailTabKeys.map(key => {
        const dataByKey = detailTabData.find(data => data.id == key);
        if (dataByKey) detailTabDataOrdered.push(dataByKey);
      });
    }
    return detailTabDataOrdered;
  }, [detailData]);

  const tagColumns = useMemo((): ColumnType[] => {
    return [
      { label: 'Key', field: 'key', sort: true },
      { label: 'Value', field: 'value', sort: true },
    ];
  }, []);

  const tagRows = useMemo((): RowType[] => {
    return detailData?.tags ?? [];
  }, [detailData]);

  const summaryContentNode = useMemo((): ReactNode => {
    switch (summaryTabSelected) {
      case SUMMARY_TAB_LIST[0]:
        return <DetailTab title={'Description'} data={detailTabContentData} isApiLoading={apiIsLoading} />;
      case SUMMARY_TAB_LIST[1]:
        return <Permission imageId={imageId} cloudId={cloudId} region={region} />;
      case SUMMARY_TAB_LIST[2]:
        return <Storage data={detailData} />;
      case SUMMARY_TAB_LIST[3]:
        return <TagTab title={'Tags'} rows={tagRows} columns={tagColumns} />;
      default:
        break;
    }
  }, [
    summaryTabSelected,
    detailTabContentData,
    apiIsLoading,
    imageId,
    detailData,
    tagRows,
    tagColumns,
    cloudId,
    region,
  ]);

  useEffect(() => {
    setSummaryTabSelected(SUMMARY_TAB_LIST[0]);
  }, [detailData]);

  return (
    <Fragment>
      {!detailData ? (
        <div className="detail-data">
          <p className="empty-page">Select a item</p>
        </div>
      ) : (
        <div className="box-summary">
          <div className="box-summary-id">{imageId}</div>

          <div className="detail-tab flex a-center">
            {SUMMARY_TAB_LIST.map(tab => {
              return (
                <button
                  className={`detail-tab-items ${summaryTabSelected?.id === tab.id && 'active'}`}
                  key={tab.id}
                  data-tab={tab.id}
                  onClick={e => setSummaryTabSelected(tab)}
                >
                  <p>{tab.name}</p>
                </button>
              );
            })}
          </div>

          <div className="content-tab">{summaryContentNode}</div>
        </div>
      )}
    </Fragment>
  );
};

export default ImageSummary;
