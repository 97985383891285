import { useLazyQuery } from '@apollo/client';
import query from './query';
import { Paging } from 'graphql/types/Paging';
import { AwsResourceTagMapping } from 'graphql/types/AwsResources';
import { IGqlResponseNewData } from 'graphql/types';

export interface IAwsAllResourcesPageVariables {
  cloudId: number;
  region: string;
  request: {
    nextToken?: string;
    resourceARNList: string[];
    filters?: {
      name: string;
      values: string[];
    };
    pageInfo?: Paging;
  };
}

export interface IAwsAllResourcesResponseData {
  getAwsAllResources: IGqlResponseNewData<AwsResourceTagMapping>;
}

const lazyGetAwsAllResources = () =>
  useLazyQuery<IAwsAllResourcesResponseData, IAwsAllResourcesPageVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsAllResources;
