import { gql } from '@apollo/client';

const query = gql`
  query getAwsListGroupsIAM($request: AwsListGroupsIAMRequest, $cloudId: Long, $region: String) {
    getAwsListGroupsIAM(listGroupsIAMRequest: $request, cloudId: $cloudId, region: $region) {
      result
      nextToken
      data {
        ... on AwsListGroupsIAMResponse {
          groups {
            path
            groupName
            groupId
            arn
            createDate
          }
          isTruncated
          marker
        }
      }
    }
  }
`;
export default query;
