import { useLazyQuery } from '@apollo/client';
import query from './query';
import { AwsDescribeSecurityGroupsPaginatorResponse, AwsSecurityGroupType } from 'graphql/types/AwsSecurityGroup';
import { Paging } from 'graphql/types/Paging';
import { IGqlResponseNewData } from 'graphql/types';

export interface IAwsSecurityGroupsPageVariables {
  cloudId: number;
  region: string;
  request: {
    maxResults: number;
    nextToken?: string;
    routeTableIds?: string[];
    dryRun?: boolean;
    filters?: {
      name: string;
      values: string[];
    }
    pageInfo?: Paging;
  }
}

export interface IAwsSecurityGroupsResponseData {
  getAwsAllSecurityGroups: IGqlResponseNewData<AwsSecurityGroupType>;
}

const lazyGetAllAwsSecurityGroups = () => useLazyQuery<IAwsSecurityGroupsResponseData, IAwsSecurityGroupsPageVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAllAwsSecurityGroups;