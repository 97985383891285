import { IMgdTabProps } from "layouts/v3/MgdLayout";

export const FirewallStatusMapping: {[key: string]: {text: string, style: string}} = {
  'READY': {text: 'Ready', style: 'text-green-01'},
  'PROVISIONING': {text: 'Provisioning', style: 'text-green-01'},
  'DELETING': {text: 'Deleting', style: 'text-red'}
};

export const FirewallConfigurationSyncStateMapping: {[key: string]: {text: string, style: string}} = {
  'IN_SYNC': {text: 'In sync', style: 'text-green-01'},
  'PENDING': {text: 'Pending', style: 'text-green-01'},
  'CAPACITY_CONSTRAINED': {text: 'Capacity constrained', style: 'text-green-01'}
};

export const mainTabs: IMgdTabProps[] = [
  {id: 'firewall',name: 'Firewall',title: 'Firewalls'},
  {id: 'firewallpolicy',name: 'Firewall Policy',title: 'Firewall policies'},
  {id: 'rulegroup',name: 'Rule group',title: 'Firewall rule groups'},
  {id: 'awsrulegroup',name: 'AWS rule group',title: 'AWS Managed rule groups'},
  {id: 'tlsinspection',name: 'TLS inspection',title: 'TLS inspection configurations'},
  {id: 'resourcegroup',name: 'Resource group',title: 'Resource group'}
];

export const detailFirewallTabs: IMgdTabProps[] = [
  {id: 'detail', name: 'Firewall details', title: 'Firewall details'},
  {id: 'policy', name: 'Firewall policy settings', title: 'Firewall policy settings'},
  {id: 'monitoring', name: 'Monitoring', title: 'Monitoring' }
];

export const detailFirewallPolicy:IMgdTabProps[] = [
  {id: 'networkFirewallRuleGroups', name: 'Network firewall rule groups', title: 'Network firewall rule groups'},
  {id: 'tlsInspectionConfiguration', name: 'TLS inspection configuration', title: 'TLS inspection configuration'},
  {id: 'details', name: 'Details', title: 'Details'}
];

export const detailFirewallRuleGroup:IMgdTabProps[] = [];

export const detailAWSFirewallRuleGroup:IMgdTabProps[] = [];

export const detailTlsInspectionConfiguration:IMgdTabProps[] = [];

export const detailFirewallResourceGroups:IMgdTabProps[] = [];

export const FIREWALL_COMMOMVALUE_MAPPING: {[key: string]: string} = {
  'ACTIVE': 'Active',
  'STRICT_ORDER': 'Strict order',
  'STATEFUL': 'Stateful',
  'STATELESS': 'Stateless',
  'ec2': 'EC2',
  'instance': 'Instance',
};

export const FIREWALL_RULES_PROTOCOL_MAPPING: {[key: string]: {text: string, protocol: string}} = {
  '-': {text: '-', protocol: '-'},
  '-1': {text: 'All', protocol: '-1'},
  '0':  {text: 'HOPOPT', protocol: '0'},
  '1':  {text: 'ICMP', protocol: '1'},
  '2':  {text: 'IGMP', protocol: '2'},
  '3':  {text: 'GGP', protocol: '3'},
  '4':  {text: 'IPv4', protocol: '4'},
  '5':  {text: 'ST', protocol: '5'},
  '6':  {text: 'TCP', protocol: '6'},
  '7':  {text: 'CBT', protocol: '7'},
  '8':  {text: 'EGP', protocol: '8'},
  '9':  {text: 'IGP', protocol: '9'},
  '10': {text: 'BBN-RCC-MON', protocol: '10'},
  '11': {text: 'NVP-II', protocol: '11'},
  '12': {text: 'PUP', protocol: '12'},
  '13': {text: 'ARGUS', protocol: '13'},
  '14': {text: 'EMCON', protocol: '14'},
  '15': {text: 'XNET', protocol: '15'},
  '16': {text: 'CHAOS', protocol: '16'},
  '17': {text: 'UDP', protocol: '17'},
  '18': {text: 'MUX', protocol: '18'},
  '19': {text: 'DCN-MEAS', protocol: '19'},
  '20': {text: 'HMP', protocol: '20'},
  '21': {text: 'PRM', protocol: '21'},
  '22': {text: 'XNS-IDP', protocol: '22'},
  '23': {text: 'TRUNK-1', protocol: '23'},
  '24': {text: 'TRUNK-2', protocol: '24'},
  '25': {text: 'LEAF-1', protocol: '25'},
  '26': {text: 'LEAF-2', protocol: '26'},
  '27': {text: 'RDP', protocol: '27'},
  '28': {text: 'IRTP', protocol: '28'},
  '29': {text: 'ISO-TP4', protocol: '29'},
  '30': {text: 'NETBLT', protocol: '30'},
  '31': {text: 'MFE-NSP', protocol: '31'},
  '32': {text: 'MERIT-INP', protocol: '32'},
  '33': {text: 'DCCP', protocol: '33'},
  '34': {text: '3PC', protocol: '34'},
  '35': {text: 'IDPR', protocol: '35'},
  '36': {text: 'XTP', protocol: '36'},
  '37': {text: 'DDP', protocol: '37'},
  '38': {text: 'IDPR-CMTP', protocol: '38'},
  '39': {text: 'TP++', protocol: '39'},
  '40': {text: 'IL', protocol: '40'},
  '41': {text: 'IPv6', protocol: '41'},
  '42': {text: 'SDRP', protocol: '42'},
  '43': {text: 'IPv6-Route', protocol: '43'},
  '44': {text: 'IPv6-Frag', protocol: '44'},
  '45': {text: 'IDRP', protocol: '45'},
  '46': {text: 'RSVP', protocol: '46'},
  '47': {text: 'GRE', protocol: '47'},
  '48': {text: 'DSR', protocol: '48'},
  '49': {text: 'BNA', protocol: '49'},
  '50': {text: 'ESP', protocol: '50'},
  '51': {text: 'AH', protocol: '51'},
  '52': {text: 'I-NLSP', protocol: '52'},
  '53': {text: 'SWIPE', protocol: '53'},
  '54': {text: 'NARP', protocol: '54'},
  '55': {text: 'Min-IPv4', protocol: '55'},
  '56': {text: 'TLSP', protocol: '56'},
  '57': {text: 'SKIP', protocol: '57'},
  '58': {text: 'IPv6-ICMP', protocol: '58'},
  '59': {text: 'IPv6-NoNxt', protocol: '59'},
  '60': {text: 'IPv6-Opts', protocol: '60'},
  '61': {text: '', protocol: '61'},
  '62': {text: 'CFTP', protocol: '62'},
  '63': {text: '', protocol: '63'},
  '64': {text: 'SAT-EXPAK', protocol: '64'},
  '65': {text: 'KRYPTOLAN', protocol: '65'},
  '66': {text: 'RVD', protocol: '66'},
  '67': {text: 'IPPC', protocol: '67'},
  '68': {text: '', protocol: '68'},
  '69': {text: 'SAT-MON', protocol: '69'},
  '70': {text: 'VISA', protocol: '70'},
  '71': {text: 'IPCV', protocol: '71'},
  '72': {text: 'CPNX', protocol: '72'},
  '73': {text: 'CPHB', protocol: '73'},
  '74': {text: 'WSN', protocol: '74'},
  '75': {text: 'PVP', protocol: '75'},
  '76': {text: 'BR-SAT-MON', protocol: '76'},
  '77': {text: 'SUN-ND', protocol: '77'},
  '78': {text: 'WB-MON', protocol: '78'},
  '79': {text: 'WB-EXPAK', protocol: '79'},
  '80': {text: 'ISO-IP', protocol: '80'},
  '81': {text: 'VMTP', protocol: '81'},
  '82': {text: 'SECURE-VMTP', protocol: '82'},
  '83': {text: 'VINES', protocol: '83'},
  '84': {text: 'IPTM', protocol: '84'},
  '85': {text: 'NSFNET-IGP', protocol: '85'},
  '86': {text: 'DGP', protocol: '86'},
  '87': {text: 'TCF', protocol: '87'},
  '88': {text: 'EIGRP', protocol: '88'},
  '89': {text: 'OSPFIGP', protocol: '89'},
  '90': {text: 'Sprite-RPC', protocol: '90'},
  '91': {text: 'LARP', protocol: '91'},
  '92': {text: 'MTP', protocol: '92'},
  '93': {text: 'AX.25', protocol: '93'},
  '94': {text: 'IPIP', protocol: '94'},
  '95': {text: 'MICP', protocol: '95'},
  '96': {text: 'SCC-SP', protocol: '96'},
  '97': {text: 'ETHERIP', protocol: '97'},
  '98': {text: 'ENCAP', protocol: '98'},
  '99': {text: '', protocol: '99'},
  '100': {text: 'GMTP', protocol: '100'},
  '101': {text: 'IFMP', protocol: '101'},
  '102': {text: 'PNNI', protocol: '102'},
  '103': {text: 'PIM', protocol: '103'},
  '104': {text: 'ARIS', protocol: '104'},
  '105': {text: 'SCPS', protocol: '105'},
  '106': {text: 'QNX', protocol: '106'},
  '107': {text: 'A/N', protocol: '107'},
  '108': {text: 'IPComp', protocol: '108'},
  '109': {text: 'SNP', protocol: '109'},
  '110': {text: 'Compaq-Peer', protocol: '110'},
  '111': {text: 'IPX-in-IP', protocol: '111'},
  '112': {text: 'VRRP', protocol: '112'},
  '113': {text: 'PGM', protocol: '113'},
  '114': {text: 'Any 0-hop text', protocol: '114'},
  '115': {text: 'L2TP', protocol: '115'},
  '116': {text: 'DDX', protocol: '116'},
  '117': {text: 'IATP', protocol: '117'},
  '118': {text: 'STP', protocol: '118'},
  '119': {text: 'SRP', protocol: '119'},
  '120': {text: 'UTI', protocol: '120'},
  '121': {text: 'SMP', protocol: '121'},
  '122': {text: 'SM', protocol: '122'},
  '123': {text: 'PTP', protocol: '123'},
  '124': {text: 'ISIS over IPv4', protocol: '124'},
  '125': {text: 'FIRE', protocol: '125'},
  '126': {text: 'CRTP', protocol: '126'},
  '127': {text: 'CRUDP', protocol: '127'},
  '128': {text: 'SSCOPMCE', protocol: '128'},
  '129': {text: 'IPLT', protocol: '129'},
  '130': {text: 'SPS', protocol: '130'},
  '131': {text: 'PIPE', protocol: '131'},
  '132': {text: 'SCTP', protocol: '132'},
  '133': {text: 'FC', protocol: '133'},
  '134': {text: 'RSVP-E2E-IGNORE', protocol: '134'},
  '135': {text: 'Mobility Header', protocol: '135'},
  '136': {text: 'UDPLite', protocol: '136'},
  '137': {text: 'MPLS-in-IP', protocol: '137'},
  '138': {text: 'manet', protocol: '138'},
  '139': {text: 'HIP', protocol: '139'},
  '140': {text: 'Shim6', protocol: '140'},
  '141': {text: 'WESP', protocol: '141'},
  '142': {text: 'ROHC', protocol: '142'}
};
