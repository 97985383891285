import { useLazyQuery } from '@apollo/client';
import query from './query';

export interface IAwsListEntitiesForPolicyVariables {
  cloudId: number;
  region: string;
  request: {
    policyArn: string;
  };
}

export interface IAwsListEntitiesForPolicyResponseData {
  getAwsListEntitiesForPolicy: any;
}

const lazyGetAwsListEntitiesForPolicy = () =>
  useLazyQuery<IAwsListEntitiesForPolicyResponseData, IAwsListEntitiesForPolicyVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsListEntitiesForPolicy;
