import { gql } from '@apollo/client';

const query = gql`
  query getAwsDescribeInstanceStatus($request: AwsDescribeInstanceStatusRequest, $cloudId: Long, $region: String) {
    getAwsDescribeInstanceStatus(describeInstanceStatusRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsDescribeInstanceStatusResponse {
          instanceStatuses {
            availabilityZone
            outpostArn
            events {
              instanceEventId
              code
              description
              notAfter
              notBefore
              notBeforeDeadline
            }
            instanceId
            instanceState {
              code
              name
            }
            instanceStatus {
              details {
                impairedSince
                name
                status
              }
              status
            }
            systemStatus {
              details {
                impairedSince
                name
                status
              }
              status
            }
          }
          nextToken
        }
      }
    }
  }
`;
export default query;
