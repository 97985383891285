import { useEffect, useMemo, useState } from 'react';
import './index.scss';

import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import Table from 'components/v2/dataDisplay/Table';
import TablePagination from 'components/v2/dataDisplay/TablePagination';

import { TABLE_OFFSET } from 'utils/DummyData/Dropdown';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import { useOutletContext } from 'react-router-dom';
import LabelInput from 'components/v2/LabelInput';
import Labeled from 'components/v2/atoms/Labeled';
import lazyGetMyHistEventByParam from 'graphql/queries/getMyHistEventByParam';
import { ProjectInfo } from 'graphql/types/ProjectInfo';
import { HistEventByParamType } from 'graphql/types/HistEventByParam';
import SelectCalendarCard from 'components/v2/CalendarCard';
import EventHistoryDetail from '../components/EventHistoryDetail';
import { lazyGetEnumTypeCode } from 'graphql/queries/getEnumTypeCode';
import { dateFormatter, enumFormatter } from 'utils/Formatter';
import CurrentStatusCard from '../components/CurrentStatusCard';
import IsLoading from 'components/v2/atoms/IsLoading';
import { useMutation } from 'react-query';
import apis from 'apis/v2';
import { useAuth } from 'contexts/AuthProvider';
import getProjectInfoByOrgan from 'graphql/queries/getProjectInfoByOrgan';
import lazyGetProjectCloudByProjectId from 'graphql/queries/getProjectCloudByProjectId';
import { handleFormatText } from 'utils/Common';

/**
 * <>이벤트 이력</>
 */
const EventHistory = () => {
  const { role } = useAuth();
  const { projectList, organizationId } = useOutletContext<{ 
    projectList: ProjectInfo[],
    organizationId: number | null;
  }>();

  const [alarmStatusCodeList, setAlarmStatusCodeList] = useState<DropdownListDataType[]>([]);
  const [eventData, setEventData] = useState<HistEventByParamType[]>([]);
  const [readyToSearch, setReadyToSearch] = useState({ 
    startTime: '', /* 시작 일자 선택 */
    endTime: '', /* 종료 일자 선택 */
    project: -1, /* 프로젝트 */
    histEventStatus: 'all00000',
    account: -1, /* 계정 */
    name: '' /* 검색어 */
  });
  const [search, setSearch] = useState({
    startTime: '', 
    endTime: '', 
    project: -1, 
    histEventStatus: 'all00000', 
    account: -1,
    name: ''
  });
  const [total, setTotal] = useState({
    totalElement: 0,
    totalPage: 0
  })
  const [tableRows, setTableRows] = useState<RowType[] >([]); /* HistEventByParamType[] */
  const [tablePagination, setTablePagination] = useState({
    limit: 10,
    target: 'createdAt',
    direction: OrderDirection.DES,
    currentPage: 1
  });
  const [selected, setSelected] = useState<string>('');
  const [accountList, setAccountList] = useState<{name: string; value:number}[]>([]);
  const [pjList, setPjList] = useState<{name: string; value: number}[]>([]);

  /* 발송상태 */
  const [getEnumTypeCode] = lazyGetEnumTypeCode();
  /* 이벤트 이력 rows 조회 */
  const [lazyGetHistEventList, { loading: loadingGetHistEventList }] = lazyGetMyHistEventByParam();
  /* 조직id에 따른 pjlist 가져오기 for lg운영자 */
  const [getProjectListByOrganId] = getProjectInfoByOrgan();
  /* 프로젝트id로 연계클라우드 계정조회 */
  const [getProjectCloudByProjectId] = lazyGetProjectCloudByProjectId();

  const COLUMNS:ColumnType[] = useMemo(() => {
    return [
      {
        label: '발생 일시',
        field: 'createdAt',
        sort: true,
        renderCell: (row:RowType) => <>{dateFormatter(row.createdAt, 'datetime')}</>
      },
      {
        label: '클라우드',
        field: 'cloudKind',
        renderCell: (row: RowType) => <>{handleFormatText(row.cloudKind ? row.cloudKind : '-')}</>,
      },
      {
        label: '프로젝트',
        field: 'projectName', 
        sort: true
      },
      {
        label: '이벤트 이름',
        field: 'eventName',
        renderCell: (row:RowType) => <>{handleFormatText(!row.eventName ? '-' : row.eventName)}</>,
        sort: true
      },
      {
        label: '중요도',
        field: 'prioryCode',
        renderCell: (row:RowType) => <>{!row.prioryCode ? '-' : <CurrentStatusCard status={row.prioryCode} />}</>,
      },
      {
        label: '알림 발송',
        field: 'alarmStatus',
        renderCell: (row:RowType) => <CurrentStatusCard status={row.alarmStatus} />,
        sort: true
      },
      {
        label: '이벤트 상태',
        field: 'histEventStatus',
        renderCell: (row:RowType) => <>{enumFormatter(row.histEventStatus)}</>,
        sort: true,
      }
    ];
  }, []);

  const getHistData = useMemo(() => {
    return {
      reqData: {
        startDateTime: search.startTime ? search.startTime : undefined,
        endDateTime: search.endTime ? search.endTime : undefined, 
        projectId: search.project === -1 ? undefined : search.project,
        alarmStatus: search.histEventStatus === 'all00000' ? undefined : search.histEventStatus,
        cloudIndfold : search.account === -1 ? undefined : search.account,
        detEventName: search.name === ''  ? undefined : search.name,
        organId: organizationId,
        pageInfo: {
          page: tablePagination.currentPage - 1,
          size: tablePagination.limit,
          orders: [{
            target: tablePagination.target === 'projectName' ? 'projectId' : tablePagination.target === 'eventName' ? 'detEventName' :  tablePagination.target,
            direction: tablePagination.direction
          }]
        }
      }
    };}, [ pjList, tablePagination, search]);

    const getPjCloudByPjId = (pjId: number) => {
      getProjectCloudByProjectId({ variables: { projectId: pjId } }).then(({ data }) => {
        if (data) {
          setAccountList(data.getProjectCloudByProjectId.data.map(val => ({
            name: val.name,
            value: val.cloudId
          })));
        }
      })
    }

    const getHistEventList = () => {
      lazyGetHistEventList({ variables: getHistData }).then(({ data }) => {
        if(data) {
          if (data.getHistEventPageByParam.content) {
            const newRowArr = data.getHistEventPageByParam.content.map(val => (
              {
                id: val.histEvent.id, /* id */
                createdAt: val.histEvent.createdAt, /* 발행일시 */
                cloudKind: val.cloud?.name, /* 클라우드 이름 */
                projectName: val.histEvent.projectName, /* 프로젝트 */
                eventName: val.histEvent?.detEventName, /* 이벤트이름 */
                prioryCode: val.eventSetting?.priorityCode, /* 중요도 */
                alarmStatus: val.histEvent.alarmStatus, /* 알림발송 */
                histEventStatus: val.histEvent.histEventStatus /* 이벤트 상태 */
              }
            ));
            
            setEventData(data.getHistEventPageByParam.content); /* 오리지널 이벤트 데이터 */
            setTableRows(newRowArr); /* 테이블 노출용 */
            setTotal({
              totalElement: data.getHistEventPageByParam.totalElements,
              totalPage: data.getHistEventPageByParam.totalPages
            });
          }
        }
      }); 
    }

  useEffect(() => { 
    getHistEventList();
  }, [getHistData]);

  useEffect(() => {
    getPjCloudByPjId(9999); 
    getEnumTypeCode({ variables: {text: 'AlarmStatusCode'} }).then(({ data }) => {
      if (data) {
        if (data.getEnumTypeCode.data) {
          setAlarmStatusCodeList(data.getEnumTypeCode.data.map(val => ({
            name: val.name, 
            value: val.value
          })))
        }
      }
    })

    if (role === 'sy_admin') {
      getProjectListByOrganId({variables: { organId: Number(organizationId)}}).then(({ data }) => {
        if (data) {
          setPjList(data.getProjectInfoByOrganId.data.map(val => ({
            name: val.name,
            value: val.projectId
          })))
        }
      })
    } else {
      setPjList(projectList.map(val => ({
        name: val.name,
        value: val.projectId
      })))
    }
  }, [role, organizationId, projectList]);

  return (
    <div id="event-history-page">
      <div className="header flex j-between a-center">
        <div className="flex j-start a-center">
          이벤트 이력
        </div>
      </div>
      <div className="search flex j-start">
        <form 
          className="flex"
          onSubmit={(e) => { 
            e.preventDefault();
            setSearch({ ...readyToSearch });
            setTablePagination(prev => ({
              ...prev,
              currentPage: 1
            }));
          }}
        >
          <div className="condition-wrap">
          {/* left 검색조건 영역 */}
          <div className="flex j-start">
              <Labeled 
                title="검색기간"
              >
                <SelectCalendarCard 
                  id="startTime"
                  placeholder="시작 일시 선택"
                  originValue={readyToSearch.startTime}
                  updateDateAndTime={(key: string, value: string) => {
                    setReadyToSearch(prev => ({
                      ...prev,
                      [key]: value
                    }));
                  }}
                />
              </Labeled>
              <Labeled 
                title="  "
              >
                <SelectCalendarCard 
                  id="endTime"
                  placeholder="종료 일시 선택"
                  originValue={readyToSearch.endTime}
                  updateDateAndTime={(key: string, value: string) => {

                    setReadyToSearch(prev => ({
                      ...prev,
                      [key]: value
                    }));
                  }}
                />
              </Labeled>
              <Labeled 
                title="프로젝트"
              >
                <DropdownAtom 
                  id="select-project"
                  data={[
                    {
                      name: '전체',
                      value: -1
                    },
                    ...pjList
                  ]} 
                  value={{
                    name: readyToSearch.project === -1 
                      ? '전체' 
                      : (pjList.find(val => val.value === readyToSearch.project)?.name 
                        ? pjList.find(val => val.value === readyToSearch.project)?.name 
                        : ''),
                    value: readyToSearch.project
                  }} 
                  handleClick={(val:DropdownListDataType) => {
                    setReadyToSearch(prev => ({
                      ...prev,
                      project: Number(val.value),
                      account: -1
                    }));

                    if (Number(val.value) === -1) {
                      getPjCloudByPjId(9999);
                    } else {
                      getPjCloudByPjId(Number(val.value));
                    }
                  }
                } 
                />
              </Labeled>
            </div>
            <div className="flex">
              <Labeled 
                title="발송상태"
              >
                <DropdownAtom 
                  id="send-status-dropdown"
                  data={[
                      { name: enumFormatter('all00000'), value: 'all00000' },
                      ...alarmStatusCodeList.map(val => ({ name: enumFormatter(String(val.value)), value: val.value }))
                  ]} 
                  value={{
                    name: enumFormatter(readyToSearch.histEventStatus),
                    value: readyToSearch.histEventStatus
                  }} 
                  handleClick={val => {
                    setReadyToSearch((prev) => ({
                      ...prev,
                      histEventStatus: String(val.value)
                    }));
                  }} 
                />
              </Labeled>
              <Labeled 
                title="계정"
              >
                <DropdownAtom
                  id="account-dropdown" 
                  placeholder='계정'
                  disabled={readyToSearch.project === -1}
                  data={[ 
                    {
                      name: '전체',
                      value: -1
                    },
                    ...accountList
                  ]} 
                  value={{
                    name: readyToSearch.account === -1 
                    ? '전체' 
                    : (accountList.find(val => val.value === readyToSearch.account)?.name 
                    ? accountList.find(val => val.value === readyToSearch.account)?.name
                    : '' ),
                    value: readyToSearch.account
                  }} 
                  handleClick={val => {
                    setReadyToSearch((prev) => ({
                      ...prev,
                      account: Number(val.value)
                    }));
                  }} 
                />
              </Labeled>
              <LabelInput 
                title="이벤트 이름"
                placeholder="이벤트 이름 검색"
                value={readyToSearch.name}
                onChangeValue={(str) => setReadyToSearch(prev => ({
                  ...prev,
                  name: str
                }))}
              />
            </div>
          </div>
          {/* right 검색버튼 */}
          <div 
            className="btns"
            onClick={() => {
              setSearch({ ...readyToSearch })
            }}
          >
            <button className="big-main-btn">
              검색
            </button>
          </div>
        </form>
      </div>

      <div className='content-wrap'>
        <div className="content">
          {
            tableRows.length === 0 && total.totalElement !== 0
              ? <div className="content-head-wrap empty flex col j-center a-center">
                <div className="search-icon" />
                <h5>검색 결과를 찾을 수 없습니다.</h5>
                <p>내용을 변경하여 다시 시도해보세요.</p>
              </div> 
              : <> {
                !loadingGetHistEventList && tableRows.length === 0 && total.totalElement === 0
                  ? <div className="empty-event-rows flex col j-center a-center">
                    <div className="event-calendar-icon" />
                    <h5>이벤트가 없습니다.</h5>
                    <p>LG운영자만 생성할 수 있습니다.</p>
                  </div>
                  : <>
                    <div className="content-head-wrap flex j-between">
                      <div className="head-title flex a-center">
                        <div className="event-calendar-icon" /> 전체 {total.totalElement}
                      </div>
                      <div className="head-btns flex a-center">
                        <DropdownAtom
                          id="offset-limit"
                          data={TABLE_OFFSET}
                          value={{
                            name: TABLE_OFFSET.find(data => data.value === tablePagination.limit)?.name,
                            value: Number(tablePagination.limit)
                          }}
                          handleClick={(val) => {
                            setTablePagination(prev => ({
                              ...prev,
                              limit: Number(val.value),
                              currentPage: 1
                            }))
                          }}
                        />
                      </div>
                    </div><div className="data-grid-wrap">
                      <Table
                        rows={tableRows}
                        columns={COLUMNS}
                        reportSelected={(id) => setSelected(id)}
                        sortOption={{
                          target: tablePagination.target,
                          direction: tablePagination.direction,
                          onChangeSort: (target: string, dir: OrderDirection) => {
                            setTablePagination(prev => ({
                              ...prev,
                              target: target,
                              direction: dir
                            }));
                        }}}
                      />
                      <div className="pagination-wrapper flex a-center">
                        <TablePagination
                          currentPage={tablePagination.currentPage}
                          updateCurrentPage={(page) => setTablePagination(prev => ({
                            ...prev,
                            currentPage: page
                          }))}
                          totalPage={total.totalPage}
                        />
                      </div>
                    </div>
                  </>
              } </>
          }
        </div>
        { loadingGetHistEventList && <IsLoading dimmed={eventData.length !== 0 } /> }
      </div>
      {
        selected !== '' && 
      <EventHistoryDetail 
        data={eventData.find(val => val.histEvent.id === Number(selected)) as HistEventByParamType} 
        getOffPage={() => setSelected('')}
        onCompleteSuccess={() => getHistEventList()}
        relObjectType="event_ri"
      />
      }
    </div>
  );
};

export default EventHistory;
