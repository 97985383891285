import { useMemo } from 'react';
import DetailTabTargetGroup from '../Common/components/DetailTabTargetGroup';
import { IMgdDetailKeyValueProps, TextTypeEnum } from 'layouts/v3/MgdLayout';
import { HealthCheckPropsType } from '../types';

const HealthChecks = (props: HealthCheckPropsType) => {
  const { targetGroup } = props;

  const detailsHealthChecksData = useMemo((): IMgdDetailKeyValueProps[] => {

    return [
      {
        title: 'Protocol',
        description: targetGroup?.healthCheckProtocol,
        type: TextTypeEnum.NORMAL,
      },
      {
        title: 'Path',
        description: targetGroup?.healthCheckPath,
        type: TextTypeEnum.NORMAL,
      },
      {
        title: 'Port',
        description: targetGroup?.healthCheckPort,
        type: TextTypeEnum.NORMAL,
      },
      {
        title: 'Health threshold',
        description: `${targetGroup?.healthyThresholdCount} consecutive health check successes`,
        type: TextTypeEnum.NORMAL,
      },
      {
        title: 'Unhealthy threshold',
        description: `${targetGroup?.unhealthyThresholdCount} consectutive health check failures`,
        type: TextTypeEnum.NORMAL,
      },
      {
        title: 'Timeout',
        description: `${targetGroup?.healthCheckTimeoutSeconds} second`,
        type: TextTypeEnum.NORMAL,
      },
      {
        title: 'Interval',
        description: `${targetGroup?.healthCheckIntervalSeconds} second`,
        type: TextTypeEnum.NORMAL,
      },
      {
        title: 'Success codes',
        description: targetGroup?.matcher?.httpCode,
        type: TextTypeEnum.NORMAL,
      },
    ];
  }, [targetGroup]);

  return (
    <DetailTabTargetGroup
      title="Health check setting"
      data={detailsHealthChecksData}
      handleLinkClick={() => {}}
    />
  );
}

export default HealthChecks;
