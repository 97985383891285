import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import IconDeleteRed from 'assets/svgs/v3/ico_red_delete.svg';
import IconWarning from 'assets/svgs/v3/ico_warning.svg';
import Icon from 'components/v2/atoms/Icon';
import './DeleteResponseBody.scss';

interface DeleteResponseBodyModalPropsType extends IBaseModalProps {
  resourceName: string;
  onDelete: () => void;
}

const DeleteResponseBodyModal = (props: DeleteResponseBodyModalPropsType) => {
  const { resourceName, onDelete, ...baseModalProps } = props;

  return (
    <BaseModal
      {...baseModalProps}
      title={() => (
        <>
          <Icon width={32} height={32} src={IconDeleteRed} />
          <div className="title-header-modal">{`Delete ${resourceName}`}</div>
        </>
      )}
    >
      <div className="delete-response-body-modal">
        <div className={'content-container flex col'}>
          <div className="header">
            <div className="delete-warning">
              <Icon width={32} height={32} src={IconWarning} />
              <p>Are you sure you want to delete the custom response body {resourceName}?</p>
            </div>
            <div className="delete-info">
              This will delete the custom response body. Deleting the custom response body cannot be undone.
            </div>
          </div>
        </div>
        <div className="button-group">
          <button className="big-sub-btn" onClick={baseModalProps.onClose}>
            Cancel
          </button>
          <button className="big-main-btn" onClick={onDelete}>
            Delete
          </button>
        </div>
      </div>
    </BaseModal>
  );
};
export default DeleteResponseBodyModal;
