import { gql } from '@apollo/client';

const query = gql`
query getAwsIPSet($request: AwsGetIpSetRequest, $cloudId: Long, $region: String) {
  getAwsIPSet(
    decribeGetIpSetRequest: $request
    cloudId: $cloudId
    region: $region
  ) {
    result
    data {
      ... on AwsGetIpSetResponse {
        ipSet {
          name
          id
          arn
          description
          ipAddressVersion
          addresses
        }
        lockToken
      }
    }
  }
}`;
export default query;