export const INSTANCE_SUMMARY_TAB_LIST = [
  { id: 'detail', name: 'Detail', title: 'Detail' },
  { id: 'security', name: 'Security', title: 'Security' },
  { id: 'networking', name: 'Networking', title: 'Networking' },
  { id: 'storage', name: 'Storage', title: 'Storage' },
  { id: 'tags', name: 'Tags', title: 'Tags' },
];

export const INBOUND_RULE_TABLE_COLUMNS = [
  { field: 'name', label: 'Name', sort: true },
  { field: 'securityGroupRuleId', label: 'Security group rule ID', sort: true },
  { field: 'portRange', label: 'Port range', sort: true },
  { field: 'protocol', label: 'Protocol', sort: true },
  { field: 'source', label: 'Source', sort: true },
  { field: 'securityGroups', label: 'Security groups', sort: true },
  { field: 'description', label: 'Description', sort: true },
];

export const NETWORK_INTERFACE_TABLE_COLUMNS = [
  { field: 'interfaceId', label: 'Interface ID', sort: true },
  { field: 'description', label: 'Description', sort: true },
  { field: 'ipv4Prefixes', label: 'IPv4 Prefixes', sort: true },
  { field: 'ipv6Prefixes', label: 'IPv6 Prefixes', sort: true },
  { field: 'publicIpv4Address', label: 'Public IPv4 address', sort: true },
  { field: 'privateIpv4Address', label: 'Private IPv4 address', sort: true },
  { field: 'ipv6Addresses', label: 'IPv6 addresses', sort: true },
  { field: 'primaryIpv6Address', label: 'Primary IPv6 address', sort: true },
  { field: 'attachmentTime', label: 'Attachment time', sort: true },
  { field: 'interfaceOwner', label: 'Interface owner', sort: true },
  { field: 'attachmentStatus', label: 'Attachment status', sort: true },
  { field: 'vpcId', label: 'VPC ID', sort: true },
  { field: 'subnetId', label: 'Subnet ID', sort: true },
  { field: 'deleteOnTerminate', label: 'Delete on terminate', sort: true },
  { field: 'sourceDestinationCheck', label: 'Source/destination check', sort: true },
  { field: 'securityGroups', label: 'Security groups', sort: true },
  { field: 'interfaceType', label: 'Interface type', sort: true },
  { field: 'enaExpress', label: 'ENA express', sort: true },
  { field: 'enaExpressUdp', label: 'ENA express UDP', sort: true },
];

export const ELASTIC_IP_ADDRESSES_TABLE_COLUMNS = [
  { field: 'name', label: 'Name', sort: true },
  { field: 'allocatedIpv4Address', label: 'Allocated IPv4 address', sort: true },
  { field: 'type', label: 'Type', sort: true },
  { field: 'addressPool', label: 'Address pool', sort: true },
  { field: 'allocationId', label: 'Allocation ID', sort: true },
];

export const BLOCK_DEVICES_TABLE_COLUMNS = [
  { field: 'volumnId', label: 'Volume ID', sort: true },
  { field: 'deviceName', label: 'Device name', sort: true },
  { field: 'volumnSize', label: 'Volume size (GIB)', sort: true },
  { field: 'attachmentStatus', label: 'Attachment status', sort: true },
  { field: 'attachmentTime', label: 'Attachment time', sort: true },
  { field: 'encrypted', label: 'Encrypted', sort: true },
  { field: 'kmsKeyId', label: 'KMS Key ID', sort: true },
  { field: 'deleteOnTermination', label: 'Delete on termination', sort: true },
];

export const RECENT_ROOT_VOLUME_REPLACEMENT_TASK_TABLE_COLUMNS = [
  { field: 'taskId', label: 'Task ID', sort: true },
  { field: 'taskState', label: 'Task state', sort: true },
  { field: 'startTime', label: 'Start time', sort: true },
  { field: 'completionTime', label: 'Completion time', sort: true },
];

export const TAG_TABLE_COLUMNS = [
  { field: 'key', label: 'Key', sort: true },
  { field: 'value', label: 'Value', sort: true },
];
