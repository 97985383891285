import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdLayout';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import { variableCombineNextToken } from 'pages/v2/Organ/Management/Utils';
import Table from 'components/v2/dataDisplay/Table';
import lazyGetAwsDescribeSpotFleetRequestHistory, {
  IAwsDescribeSpotFleetRequestHistoryVariables,
} from 'graphql/queries/getAwsDescribeSpotFleetRequestHistory';
import { HistoryFleetInstacePropsType } from '../../../types';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import moment from 'moment';
import './style.scss';
import { handleFormatText } from 'utils/Common';

const History = (props: HistoryFleetInstacePropsType) => {
  const { cloudId, region, currentSpotFleetInstance } = props;
  const [getAwsDescribeSpotFleetRequestHistory, { loading: awsDescribeSpotRequestLoading }] =
    lazyGetAwsDescribeSpotFleetRequestHistory();
  const [mainTblRows, setMainTblRows] = useState<RowType[]>([]);
  const [mainNextToken, setMainNextToken] = useState<string>('');
  const [tblTotal, setTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [tablePagination, setTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  const columns = useMemo((): ColumnType[] => {
    return [
      {
        label: 'Timestamp',
        field: 'timestamp',
        sort: true,
        renderCell: (row: RowType) =>
          row?.timestamp ? (
            <Fragment>
              <div>{moment(row.timestamp).format('DD/MM/YYYY')}</div>

              <div>{moment(row.timestamp).format('hh:mm:ss a')}</div>
            </Fragment>
          ) : (
            <></>
          ),
      },
      {
        label: 'Event type',
        field: 'eventType',
        sort: true,
      },
      {
        label: 'Status',
        field: 'status',
        sort: true,
      },
      {
        label: 'Description',
        field: 'description',
        sort: true,
      },
      {
        label: 'Instance ID',
        field: 'spotInstanceRequestId',
        sort: true,
        renderCell: (row: RowType) => (
          <div
            className="link"
            onClick={e => {
              e.stopPropagation();
            }}
          >
            {handleFormatText(row?.instanceId)}
          </div>
        ),
      },
    ];
  }, []);

  const awsDescribeVariable = useMemo((): IAwsDescribeSpotFleetRequestHistoryVariables => {
    return {
      cloudId,
      region,
      request: {
        maxResults: tablePagination.limit,
        spotFleetRequestId: currentSpotFleetInstance?.spotFleetRequestId,
        startTime: currentSpotFleetInstance?.createTime,
      },
    };
  }, [cloudId, region, currentSpotFleetInstance, tablePagination]);

  const mainRowsCurrentPage = useMemo((): RowType[] => {
    if (tablePagination.currentPage > tblTotal.totalPage && !!mainNextToken) {
      fetchDescribeSpotFleetRequestHistory(mainNextToken);
    }
    const startIndex = (tablePagination.currentPage - 1) * tablePagination.itemPerPage;
    const endIndex = startIndex + tablePagination.itemPerPage;

    setTblTotal({
      totalPage: Math.ceil(mainTblRows.length / tablePagination.itemPerPage),
      totalElement: mainTblRows.length,
    });

    return orderAlphabetical(mainTblRows, tablePagination.target, tablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [mainTblRows, tablePagination, mainNextToken]);

  const fetchDescribeSpotFleetRequestHistory = useCallback(
    (nextToken?: string, isInitial?: boolean) => {
      const requestVariable: IAwsDescribeSpotFleetRequestHistoryVariables = {
        cloudId,
        region,
        request: {
          maxResults: tablePagination.limit,
          spotFleetRequestId: currentSpotFleetInstance?.spotFleetRequestId,
          startTime: currentSpotFleetInstance?.createTime,
        },
      };
      getAwsDescribeSpotFleetRequestHistory({variables: variableCombineNextToken(requestVariable, nextToken)}).then(({data: awsDescribeSpotFleetRequestHistoryResponse}) => {
        const awsDescribeSpotFleetRequestHistory = awsDescribeSpotFleetRequestHistoryResponse?.getAwsDescribeSpotFleetRequestHistory?.data?.[0];
        if (awsDescribeSpotFleetRequestHistory?.historyRecords && awsDescribeSpotFleetRequestHistory.historyRecords.length > 0) {
          setMainNextToken(awsDescribeSpotFleetRequestHistory?.nextToken);
          const historyRows: RowType[] = [];
          awsDescribeSpotFleetRequestHistory.historyRecords.map((history) => {
            const evenInformationJSON = JSON.parse(history?.eventInformation.toString());
            historyRows.push({
              ...history,
              id: history?.timestamp,
              status: evenInformationJSON['eventSubType'],
              description: evenInformationJSON['eventDescription'],
              instanceId: evenInformationJSON['instanceId'],
            });
          });

          let histories: RowType[] = [];
          if (isInitial) {
            histories = historyRows;
          } else {
            histories = [...mainTblRows, ...historyRows];
          }

          setMainTblRows(histories);
          setTblTotal({
            totalPage: Math.ceil(histories.length / tablePagination.itemPerPage),
            totalElement: histories.length,
          });
        }
      });
    },
    [cloudId, region, currentSpotFleetInstance, tablePagination],
  );

  useEffect(() => {
    fetchDescribeSpotFleetRequestHistory('', true);
  }, [awsDescribeVariable]);

  const updateTablePagination = (key: string, value: number | string | OrderDirection) => {
    setTablePagination(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <div className="detail-info">
      <div className="detail-info-title">
        <p>History</p>
      </div>
      {awsDescribeSpotRequestLoading ? (
        <div className="progress-container">
          <div className="progress-gif" />
          Loading ...
        </div>
      ) : (
        <div className="detail-info-content">
          {!mainTblRows.length && !awsDescribeSpotRequestLoading ? (
            <div className="data-grid-wrap">
              <p className="empty-row">Empty</p>
            </div>
          ) : (
            <div className="data-grid-wrap">
              <Table
                rows={mainTblRows}
                columns={columns}
                sortOption={{
                  target: tablePagination.target,
                  direction: tablePagination.direction,
                  onChangeSort: (target: string, dir: OrderDirection) => {
                    updateTablePagination('target', target);
                    updateTablePagination('direction', dir);
                  },
                }}
                isLoading={awsDescribeSpotRequestLoading}
                horizontalScrollable={true}
              />
              {mainRowsCurrentPage && mainRowsCurrentPage.length > 0 && !awsDescribeSpotRequestLoading && (
                <div className="pagination-wrapper flex a-center">
                  <p className="flex a-center">
                    Total <span>{tblTotal.totalElement}</span>
                  </p>
                  <TableManagePagination
                    ableFetchMore={!!mainNextToken}
                    currentPage={tablePagination.currentPage}
                    updateCurrentPage={page =>
                      setTablePagination(prev => ({
                        ...prev,
                        ['currentPage']: page,
                      }))
                    }
                    totalPage={tblTotal.totalPage}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default History;
