import { useLazyQuery } from '@apollo/client';
import query from './query';
import { AwsGetGroupIAMResponseEntry } from 'graphql/types/AwsGetGroupIAM';

export interface IAwsGroupIAMVariables {
  cloudId: number;
  region: string;
  request: {
    groupName?: string;
    group?: string;
  }
}

export interface IAwsGroupIAMResponseData {
  getAwsGroupIAM: AwsGetGroupIAMResponseEntry;
}

const lazyGetAwsGroupIAM = () => useLazyQuery<IAwsGroupIAMResponseData, IAwsGroupIAMVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsGroupIAM;