import React from 'react';
import { InputGroupPropsType } from './types';

const InputGroup = (props: InputGroupPropsType) => {
  const { children } = props;

  return <div id="input-group-container">{children}</div>;
};

export default InputGroup;
