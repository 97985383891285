import { gql } from '@apollo/client';

const query = gql`
query getAwsListKeyRotations($request: AwsListKeyRotationsRequest, $cloudId: Long, $region: String) {
  getAwsListKeyRotations(
    listKeyRotationsRequest: $request
    cloudId: $cloudId
    region: $region
  ) {
    result
    data {
      ... on AwsListKeyRotationsResponse {
        rotations {
          keyId
          rotationDate
          rotationType
        }
        nextMarker
        truncated
      }
    }
  }
}`;
export default query;