import React from 'react';
import DetailTab from "pages/v2/Organ/Management/EC2/components/DetailTab";
import {IMgdDetailKeyValueProps, TextTypeEnum} from "layouts/v3/MgdLayout";

function StatusChecks({data, volumeAttribute, isLoading}: any) {
  const {
    availabilityZone,
    volumeStatus
  } = data || {}

  const detailsData: IMgdDetailKeyValueProps[] = [
    {
      title: 'Volume status',
      description: volumeStatus?.volumeStatus?.status ? 'Okay' : '...',
    },
    {
      title: 'Availability zone',
      description: availabilityZone,
      type: TextTypeEnum.COPY
    },
    {
      title: 'I/O status',
      description: volumeStatus?.details?.name === 'IO_ENABLED' && volumeStatus?.volumeStatus?.status ? 'enabled' : '...',
    },
    {
      title: 'I/O performance',
      description: volumeStatus?.details?.name === 'IO_PERFORMANCE' ? '' : '',
      type: TextTypeEnum.COPY
    },
    {
      title: 'Auto-enabled',
      description: volumeAttribute?.autoEnableIO ? 'enabled' : '...',
    },
  ]

  return (
    <DetailTab title='Status checks' data={detailsData} isApiLoading={isLoading} />
  );
}

export default StatusChecks;