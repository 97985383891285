import { useState, useMemo, useEffect, useCallback } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import IconStateless from 'assets/svgs/v3/ico_stateless_actions.svg';
import IconStateful from 'assets/svgs/v3/ico_stateful_actions.svg';
import Icon from 'components/v2/atoms/Icon';
import './index.scss';
import ResetIcon from 'assets/svgs/v3/ico_reset.svg';
import Table from 'components/v2/dataDisplay/Table';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import { IMgdTablePaginationProps } from 'layouts/v3/MgdLayout';
import TableCheckbox from 'components/v2/dataDisplay/TableCheckbox';

interface IEditPriorityProps extends IBaseModalProps {
  header: string;
  subHeader?: string;
  isStateless?: boolean;
  rows: RowType[];
  columns: ColumnType[];
  btnTitle?: string;
  note?: string;
  onChangeValue?: (index: number, value: string) => void;
}

const EditPriority = ({
  header,
  subHeader,
  isStateless = false,
  rows,
  columns,
  btnTitle,
  note,
  onChangeValue,
  ...baseModalProps
}: IEditPriorityProps) => {

  // TODO: Order by prioty or NOT
  const [handleRows, setHandleRows] = useState<RowType[]>(rows);
  const [selectedIndex, setSelectedIndex] = useState<string>('');
  const [checkedList, setCheckedList] = useState<string[]>([]);

  const moveUp = useCallback(() => {
    const selectRowIndex = handleRows.findIndex((row) => row.id === selectedIndex);
    if (selectedIndex && selectRowIndex > 0) {
      const updatedRows = [...handleRows];
      const temp = updatedRows[selectRowIndex];
      updatedRows[selectRowIndex] = updatedRows[selectRowIndex - 1];
      updatedRows[selectRowIndex - 1] = temp;
      setHandleRows(updatedRows);
    }
  }, [selectedIndex, handleRows]);

  const moveDown = useCallback(() => {
    const selectRowIndex = handleRows.findIndex((row) => row.id === selectedIndex);
    if (selectedIndex && (selectRowIndex < handleRows.length - 1)) {
      const updatedRows = [...handleRows];
      const temp = updatedRows[selectRowIndex];
      updatedRows[selectRowIndex] = updatedRows[selectRowIndex + 1];
      updatedRows[selectRowIndex + 1] = temp;
      setHandleRows(updatedRows);
    }
  }, [selectedIndex, handleRows]);

  const renderBody = useMemo(() => {
    return (
      <>
        {handleRows.map((row, rowIdx) => (
          <tr
            key={`row-${rowIdx}`}
            onClick={() => {
              if (selectedIndex) {
                setCheckedList([]);
                setSelectedIndex('');
              } else {
                setCheckedList([String(row.id)]);
                setSelectedIndex(String(row.id));
              }
            }}
          >
            <td onClick={(e) => e.stopPropagation()}>
              <TableCheckbox
                defaultValue={checkedList.includes(String(row.id))}
                onChange={() => {
                  if (checkedList.includes(String(row.id))) {
                    const left = checkedList.filter(li => li !== String(row.id));
                    setCheckedList(left);
                  } else {
                    setCheckedList([...checkedList, String(row.id)]);
                  }
                }}
              />
            </td>
            {
              columns.map((col, idx) => (
                <td key={`row-${rowIdx}-col-${idx}`}>
                  <div>
                    {col.field == 'priority' ? <>{rowIdx + 1}</> : <>{(row as { [key: string]: any })[col.field]}</>}
                  </div>
                </td>
              ))
            }
          </tr>
        ))}
      </>
    );
  }, [handleRows, checkedList]);

  return (
    <BaseModal
      title={() => <><Icon width={32} height={32} src={isStateless ? IconStateless : IconStateful} />{header}</>}
      {...baseModalProps}>
      <div className="unmanaged-state-modal">
        <p className="note">{note}</p>
        <div className="unmanaged-state">

          <div className="row-1">
            <p className="text-title">{`${isStateless ? 'Stateless' : 'Stateful'}`} rule group</p>
            <div className="btn-group-edit">
              <button className="btn-up" onClick={moveUp}> Move Up</button>
              <button className="btn-down" onClick={moveDown}>Move Down</button>
            </div>
          </div>

          <div className="row-2 data-table">
            <table>
              <tbody>
                <tr className="horizontal-header">
                  <th style={{ width: '87px', maxWidth: '87px' }}>
                      <TableCheckbox
                        defaultValue={checkedList.length > 0 && checkedList.length === rows.length}
                        onChange={() => {}}
                      />
                    </th>
                  {columns.map((col, idx) => (
                    <th
                      key={`col-${idx}`}
                      {...(col.width && { style: { width: col.width + 'px' } })}
                    >
                      <div>
                        {
                          col.label
                          ? col.label
                          : col.field
                        }
                      </div>
                    </th>))}
                </tr>
                {renderBody}
              </tbody>
            </table>
          </div>
        </div>

        <div className={"button-group"}>
          <button onClick={baseModalProps.onClose}>Cancel</button>
          <button className="btn-save" onClick={() => { }}>Save</button>
        </div>
      </div>
    </BaseModal>
  );
};

export default EditPriority;