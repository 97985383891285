import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsDescribeSpotFleetRequestHistoryResponse } from 'graphql/types/AwsDescribeSpotFleetRequestHistoryResponse';

export interface IAwsDescribeSpotFleetRequestHistoryVariables {
  cloudId: number;
  region: string;
  request: {
    dryRun?: boolean;
    eventType?: string;
    maxResults: number;
    nextToken?: string;
    spotFleetRequestId: string;
    startTime: string;
  };
}

export interface IAwsDescribeSpotFleetRequestHistoryResponseData {
  getAwsDescribeSpotFleetRequestHistory: IGqlResponseNewData<AwsDescribeSpotFleetRequestHistoryResponse>;
}

const lazyGetAwsDescribeSpotFleetRequestHistory = () =>
  useLazyQuery<IAwsDescribeSpotFleetRequestHistoryResponseData, IAwsDescribeSpotFleetRequestHistoryVariables>(query, {
    fetchPolicy: 'no-cache',
  });
export default lazyGetAwsDescribeSpotFleetRequestHistory;
