import { useLazyQuery } from '@apollo/client';
import query from './query';
import { AwsDescribeVpnGatewaysResponse } from 'graphql/types/AwsVpnGateways';

export interface IAwsAwsVpnGatewaysVariables {
  cloudId: number;
  region: string;
  request: {
    vpnGatewayIds?: string[];
    dryRun?: boolean;
    filters?: {
      name: string;
      values: string[];
    }
  }
}

export interface IAwsAwsVpnGatewaysVariablesResponseData {
  getAwsVpnGateways: AwsDescribeVpnGatewaysResponse;
}

const lazyGetAwsVpnGateways = () => useLazyQuery<IAwsAwsVpnGatewaysVariablesResponseData, IAwsAwsVpnGatewaysVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsVpnGateways;