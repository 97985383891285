import { gql } from '@apollo/client';

const query = gql`
query getAwsAllNatGateways($request: AwsDescribeNatGatewaysRequest, $cloudId: Long, $region: String) {
  getAwsAllNatGateways(
    describeNatGatewaysRequest: $request
    cloudId: $cloudId
    region: $region
  ) {
    result
    data {
      ... on AwsNatGateway {
        createTime
        deleteTime
        failureCode
        failureMessage
        natGatewayAddresses {
          allocationId
          networkInterfaceId
          privateIp
          publicIp
          associationId
          isPrimary
          failureMessage
          status
        }
        natGatewayId
        provisionedBandwidth {
          provisionTime
          provisioned
          requestTime
          requested
          status
        }
        state
        subnetId
        vpcId
        tags {
          key
          value
        }
        connectivityType
      }
    }
  }
}
`;
export default query;