import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import BaseModal from 'components/v2/modals/BaseModal';
import './styles.scss';
import { SectionTitle } from '../WebACL/CreateWebACL/components';
import SectionItem from '../WebACL/CreateWebACL/components/SectionItem';

type ManagedRuleModalPropsType = {
  isVisible: boolean;
  onClose: () => void;
};

const ManagedRuleModal = (props: ManagedRuleModalPropsType) => {
  const { isVisible, onClose } = props;

  return (
    <BaseModal
      open={isVisible}
      onClose={onClose}
      className="managed-rule-modal"
    >
      <SectionTitle
        title="Add managed rule groups"
        description="Managed rule groups are created and maintained for you by AWS and AWS Marketplace series. Any fees that a managed rule group provider charges for using a managed rule group are in addition to the standard service charges for AWS WAF. AWS WAF Pricing >"
      />

      <SectionItem.Collapsible title='AWS managed rule groups'>
        
      </SectionItem.Collapsible>
    </BaseModal>
  );
};

export default ManagedRuleModal;
