import React from 'react';
import './index.scss';
import {IMgdTabProps, TextTypeEnum} from "layouts/v3/MgdLayout";
import CopyableText from "components/v3/CopyableText";

interface ContentType {
  title?: string;
  description?: string;
  type?: TextTypeEnum.COPY | TextTypeEnum.LINK | TextTypeEnum.NORMAL;
  isBorder?: boolean;
  button?: {
    title: string;
    action: () => void;
  };
  action?: {
    name: string;
    value: string;
  };
}

export interface DetailMultiColumProps {
  title: string;
  subTitle?: string;
  contents?: ContentType[];
}

interface headerPropsType {
  title?: string;
  subTitle?: string;
  // isDetailTab?: boolean;
  actionGroup?: {
    title?: string;
    onClick?: () => void;
  }[];
}

const RenderHeader = (props: headerPropsType) => {
  const {title, subTitle, actionGroup} = props;

  return (
    <div className='header-container'>
      <div className='group-title'>
        <div className='title'>{title}</div>
        <div className='sub-title'>{subTitle}</div>
      </div>
      {(actionGroup && actionGroup.length > 0) &&
        <>
          {actionGroup?.map((button, index) => {
            return <button key={`detail-multi-column-button-group-${index}`} className="button" onClick={button.onClick}>{button.title}</button>;
          })}
        </>
      }
    </div>
  )
}

interface contentPropsType {
  contents?: ContentType[];
  handleClick?: (currTab: IMgdTabProps, data: any) => void;
  currentTab: IMgdTabProps;
}

const Content = (props: contentPropsType) => {
  const {contents, handleClick, currentTab} = props;

  return (
    <>
      {contents &&
        <div className='content-container'>
          {
            contents.length ?
              contents.map((content, index) => {
                return (
                  <div key={index}
                      className={`content-item-container${index === contents.length - 1 ? ' last-item' : ''}`}
                  >
                    <p className="title">{content.title}</p>
                    {
                      content.type === TextTypeEnum.COPY &&
                      <div className={`description link${content.isBorder ? ' bordered' : ''}`}>
                        {
                          content.description && content.description !== '-' ?
                          <CopyableText text={content.description} /> : <p>-</p>
                        }
                      </div>
                    }
                    {
                      content.type === TextTypeEnum.LINK &&
                      <p className='description link'
                        onClick={() => {
                          handleClick && handleClick(currentTab, content.action)
                        }}
                      >{content.description}</p>
                    }
                    {
                      (content.type == undefined || content.type === TextTypeEnum.NORMAL) &&
                      <p className='description'>{content.description}</p>
                    }
                  </div>
                )
              }) :
              <div className='empty-row'>Empty</div>
          }
          {
            contents.length && contents[0].button &&
            <button className='button'><p>Copy ARN</p></button>
          }
        </div>
      }
    </>
  )
}

interface ColumnLayoutProps {
  data: DetailMultiColumProps[];
  handleHDetailHyperlinkMainClick?: (currTab: IMgdTabProps, data: any) => void;
  currentTab: IMgdTabProps;
  // isDetailTab?: boolean;
  actionGroup?: {
    title?: string;
    onClick?: () => void;
  }[];
}

const OneColumnLayout = (props: ColumnLayoutProps) => {
  const {data, handleHDetailHyperlinkMainClick, currentTab, actionGroup} = props;
  const headerProps = {
    title: data ? data[0].title : '',
    subTitle: data ? data[0].subTitle : '',
    actionGroup: actionGroup
  }

  const contentProps = data ? data[0].contents : [];

  return <>
    {
      RenderHeader(headerProps)
    }
    {
      <Content contents={contentProps} handleClick={handleHDetailHyperlinkMainClick} currentTab={currentTab}/>
    }
  </>
}

const MultiColumnsLayout = (props: ColumnLayoutProps) => {
  const {data, handleHDetailHyperlinkMainClick, currentTab, actionGroup} = props;
  return (
    <div className='multi-columns-wrapper'>
      {
        data?.map((item, index) => {
          return (
            <div key={index}
                 className='detail-item-block-container'
            >
              {
                RenderHeader({title: item.title, subTitle: item.subTitle, actionGroup: actionGroup})
              }
              {
                <Content contents={item.contents} handleClick={handleHDetailHyperlinkMainClick}
                         currentTab={currentTab}/>
              }
            </div>
          )
        })
      }
    </div>
  )
}

interface MultiColumnProps {
  data: DetailMultiColumProps[];
  handleHDetailHyperlinkMainClick?: (currTab: IMgdTabProps, data: any) => void;
  currentTab: IMgdTabProps;
  actionGroup?: {
    title?: string;
    onClick?: () => void;
  }[];
  style?: any;
}

const DetailMultiColumn = (props: MultiColumnProps) => {
  const {data, handleHDetailHyperlinkMainClick, currentTab, style, actionGroup} = props;

  return (
    <div className="detail-block-container" style={style}>
      {
        data?.length === 1 ?
          <OneColumnLayout data={data} handleHDetailHyperlinkMainClick={handleHDetailHyperlinkMainClick}
                           currentTab={currentTab} actionGroup={actionGroup}/>
          :
          <MultiColumnsLayout data={data} handleHDetailHyperlinkMainClick={handleHDetailHyperlinkMainClick}
                              currentTab={currentTab} actionGroup={actionGroup}/>
      }
    </div>
  )
}

export default DetailMultiColumn;