import React from 'react';
import { DetailPropsType } from '../types';
import PageDetailTitle from '../../PageDetailTitle';
import Description from '../Tabs/Description';
import InstanceSummary from '../InstanceSummary';

const Detail = (props: DetailPropsType) => {
  const { instance, onBackButtonClicked, cloudId, region, onDeleteButtonClicked } = props;

  return (
    <div className="ec2-detail scroll">
      <PageDetailTitle title={instance?.instanceId} pageBackClick={onBackButtonClicked} isDelete onDelete={onDeleteButtonClicked}/>
      <Description instance={instance} isSummaryOnly />
      <InstanceSummary instance={instance} cloudId={cloudId} region={region} isSummaryHidden />
    </div>
  );
};

export default Detail;
