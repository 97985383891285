import { useCallback, useEffect, useMemo, useState } from 'react';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import MgdLayout, {
  IMgdTabProps,
  IMgdTablePaginationProps,
  IMgdTotalPageProps,
  MgdLayoutTypeEnum,
} from 'layouts/v3/MgdLayout';
import { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import { lazyGetEnumTypeCode } from 'graphql/queries/getEnumTypeCode';
import lazyGetAwsListWebACLs from 'graphql/queries/getAwsListWebACLs';
import Icon from 'components/v2/atoms/Icon';
import IconDeleteRed from 'assets/svgs/v3/ico_red_delete.svg';
import DeleteWebAclModal from './WebACL/DeleteWebACLModal';
import deleteWebACL from 'graphql/mutations/deleteWebACL';
import { ErrorCode } from '@Types/error';
import { useSnackbar } from 'hooks/useSnackbar';
import { CLOUDFRONT_OPTION, CLOUDFRONT_REGION, MgdWafPageEnum, WafScopeEnum, wafTabs } from './Commons/Constant';
import CreateWebAcl from './WebACL/CreateWebACL';
import WebAclDetail from './WebACL/WebACLDetail';
import lazyGetAwsListIPSets from 'graphql/queries/getAwsListIPSets';
import IPSetDetail from './IPSet/IPSetDetail';
import lazyGetAwsListRegexPatternSets from 'graphql/queries/getAwsListRegexPatternSets';
import RegexPatternSetDetail from './RegexPattern/RegexPatternDetail';
import lazyGetAwsListRuleGroup from 'graphql/queries/getAwsListRuleGroup';
import RuleGroupDetail from './RuleGroup/RuleGroupDetail';
import { useOutletContext } from 'react-router-dom';
import { variableCombineNextToken } from '../Utils';
import { orderAlphabetical } from '../Utils/Sorting';
import CustomRuleModal from 'pages/v2/Organ/Management/WAF/CustomRuleModal';
import { AwsDistributionSummaryType } from 'graphql/types/AwsListDistributionsByWebACLId';
import Review from './WebACL/Review';
import { handleFormatText } from 'utils/Common';
import CreateRegexPattern from './RegexPattern/CreateRegexPattern';
import DeleteRegexPatternSetModal from './RegexPattern/DeleteRegexPatternSet';
import deleteRegexPatternSetMutation, {
  IDeleteRegexPatternSetVariables,
} from 'graphql/mutations/deleteRegexPatternSet';
import { useToast } from "hooks/v2/useToast";
import { AwsRegexPatternSets } from 'graphql/types/AwsListRegexPatternSets';
import DeleteRuleGroupModal from './RuleGroup/DeleteRuleGroup';
import deleteRuleGroupMutation, { IDeleteRuleGroupVariables } from 'graphql/mutations/deleteRuleGroup';
import { AwsRuleGroupSummary } from 'graphql/types/AwsListRuleGroup';
import CreateIPSet from './IPSet/CreateIPSet';

interface IWAFGridProps {
  projectId: number;
  cloudName: string;
  regionCode: string;
  regions: string[];
  pageBackClick: () => void;
  recentRelatedClouds: DropdownListDataType[];
  relatedCloudSelected: DropdownListDataType;
  recentRelatedCloudOnChange: (option: DropdownListDataType) => void;
}

interface DeleteResourceModalPropsType {
  deleteWebAcl: boolean;
  deleteIPSet: boolean;
  deleteRegexPatternSet: boolean;
  deleteRuleGroup: boolean;
}

const Waf = ({
  projectId,
  cloudName,
  regionCode,
  regions,
  pageBackClick,
  recentRelatedClouds,
  relatedCloudSelected,
  recentRelatedCloudOnChange,
}: IWAFGridProps) => {
  const { defaultRegionList, updateDefaultRegionList } = useOutletContext<{
    defaultRegionList: { projectId: number; cloudId: number; name: string; value: string }[];
    updateDefaultRegionList: (projectId: number, cloudId: number, name: string, value: string) => void;
  }>();

  const hasDefaultRegion = useMemo(() => {
    return defaultRegionList.find(r => r.projectId === projectId && r.cloudId === relatedCloudSelected.value);
  }, [relatedCloudSelected]);

  const [isShowing, setIsShowing] = useState<MgdWafPageEnum>(MgdWafPageEnum.MGD_LIST);
  const [search, setSearch] = useState('');

  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [mainTblColumns, setMainTblColumns] = useState<ColumnType[]>([]);
  const [mainTblRows, setMainTblRows] = useState<RowType[]>([]);
  const [searchData, setSearchData] = useState<RowType[]>([]);
  const [mainTablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });
  const [mainNextToken, setMainNextToken] = useState<string>('');
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [mainSelected, setMainSelected] = useState<string>('');
  const [regionList, setRegionList] = useState<DropdownListDataType[]>([]);
  const [selectedRegion, setSelectedRegion] = useState<DropdownListDataType>({
    name: hasDefaultRegion ? hasDefaultRegion.name : CLOUDFRONT_OPTION.name,
    value: hasDefaultRegion ? hasDefaultRegion.name : CLOUDFRONT_OPTION.value,
  });
  const [mainTabSelected, setMainTabSelected] = useState<IMgdTabProps>(wafTabs[0]);
  const [isOpenDeleteResourceModal, setIsOpenDeleteResourceModal] = useState<DeleteResourceModalPropsType>({
    deleteWebAcl: false,
    deleteIPSet: false,
    deleteRegexPatternSet: false,
    deleteRuleGroup: false,
  });
  const [mainApiIsLoading, setMainApiIsLoading] = useState<boolean>(false);
  const [creationData, setCreationData] = useState<any>(null);
  const [isOpenCreateCustomRule, setIsOpenCreateCustomRule] = useState(false);

  const [getAwsListWebACLs] = lazyGetAwsListWebACLs();
  const [getAwsListIPSets] = lazyGetAwsListIPSets();
  const [getAwsListRegexPatternSets] = lazyGetAwsListRegexPatternSets();
  const [getAwsListRuleGroup] = lazyGetAwsListRuleGroup();
  const [deleteRegexPatternSet] = deleteRegexPatternSetMutation();
  const [deleteRuleGroup] = deleteRuleGroupMutation();

  const [getEnumTypeCode] = lazyGetEnumTypeCode();

  const [delWebAcl] = deleteWebACL();

  const deleteBtnDisabled = useMemo((): boolean => {
    return mainSelected === '' || checkedList.length !== 1;
  }, [mainSelected, checkedList]);

  const onSearchKeyDownHandler = (e: any) => {
    if (e.key === 'Enter') {
      if (search != '') {
        const rearchResult = searchData.filter(row => row.name.includes(search));
        setMainTblRows(rearchResult);
        setMainTblTotal({
          totalPage: Math.ceil(rearchResult.length / mainTablePagination.itemPerPage),
          totalElement: rearchResult.length,
        });
      } else {
        setMainTblRows(searchData);
        setMainTblTotal({
          totalPage: Math.ceil(searchData.length / mainTablePagination.itemPerPage),
          totalElement: searchData.length,
        });
      }
    }
  };

  const getVariableData = useMemo(
    () => ({
      cloudId: relatedCloudSelected.value,
      region: selectedRegion.value !== 'CloudFront' ? String(selectedRegion.value) : 'us-east-1',
      request: {
        limit: 100,
        scope: selectedRegion.value !== 'CloudFront' ? 'REGIONAL' : 'CLOUDFRONT',
      },
    }),
    [selectedRegion, relatedCloudSelected],
  );

  const getWebACLsPageRows = useCallback(
    (nextToken?: string) => {
      if (getVariableData.region) {
        setMainApiIsLoading(true);
        getAwsListWebACLs({ variables: variableCombineNextToken(getVariableData, nextToken) }).then(({ data }) => {
          if (data && data.getAwsListWebACLs) {
            setMainTblRows(data.getAwsListWebACLs.data?.[0]?.webACLs);
            setSearchData(data.getAwsListWebACLs.data?.[0]?.webACLs);
            setMainTblTotal({
              totalPage: Math.ceil(data.getAwsListWebACLs.data?.[0].webACLs.length / mainTablePagination.limit),
              totalElement: data.getAwsListWebACLs.data?.[0].webACLs.length,
            });
            setMainApiIsLoading(false);
          }
        });
      }
    },
    [selectedRegion, mainTablePagination],
  );

  const getIPSetsPageRows = useCallback(
    (nextToken?: string) => {
      if (getVariableData.region) {
        setMainApiIsLoading(true);
        getAwsListIPSets({ variables: variableCombineNextToken(getVariableData, nextToken) }).then(({ data }) => {
          if (data && data.getAwsListIPSets) {
            setMainTblRows(data.getAwsListIPSets.data?.[0]?.ipSets);
            setSearchData(data.getAwsListIPSets.data?.[0]?.ipSets);
            setMainTblTotal({
              totalPage: Math.ceil(data.getAwsListIPSets.data?.[0]?.ipSets.length / mainTablePagination.limit),
              totalElement: data.getAwsListIPSets.data?.[0]?.ipSets.length,
            });
            setMainApiIsLoading(false);
          }
        });
      }
    },
    [selectedRegion, mainTablePagination],
  );

  const getRegexPatternSetsPageRows = useCallback(
    (nextToken?: string) => {
      if (getVariableData.region) {
        setMainApiIsLoading(true);
        getAwsListRegexPatternSets({ variables: variableCombineNextToken(getVariableData, nextToken) }).then(
          ({ data }) => {
            if (data && data.getAwsListRegexPatternSets) {
              setMainTblRows(data.getAwsListRegexPatternSets.data?.[0]?.regexPatternSets);
              setSearchData(data.getAwsListRegexPatternSets.data?.[0]?.regexPatternSets);
              setMainTblTotal({
                totalPage: Math.ceil(
                  data.getAwsListRegexPatternSets.data?.[0]?.regexPatternSets.length / mainTablePagination.limit,
                ),
                totalElement: data.getAwsListRegexPatternSets.data?.[0]?.regexPatternSets.length,
              });
              setMainApiIsLoading(false);
            }
          },
        );
      }
    },
    [selectedRegion, mainTablePagination],
  );

  const getRuleGroupsPageRows = useCallback(
    (nextToken?: string) => {
      if (getVariableData.region) {
        setMainApiIsLoading(true);
        getAwsListRuleGroup({ variables: variableCombineNextToken(getVariableData, nextToken) }).then(({ data }) => {
          if (data && data.getAwsListRuleGroup) {
            setMainTblRows(data.getAwsListRuleGroup.data?.[0]?.ruleGroups);
            setSearchData(data.getAwsListRuleGroup.data?.[0]?.ruleGroups);
            setMainTblTotal({
              totalPage: Math.ceil(data.getAwsListRuleGroup.data?.[0]?.ruleGroups.length / mainTablePagination.limit),
              totalElement: data.getAwsListRuleGroup.data?.[0]?.ruleGroups.length,
            });
            setMainApiIsLoading(false);
          }
        });
      }
    },
    [selectedRegion, mainTablePagination],
  );

  useEffect(() => {
    switch (mainTabSelected) {
      case wafTabs[0]:
        getWebACLsPageRows();
        break;
      case wafTabs[1]:
        getIPSetsPageRows();
        break;
      case wafTabs[2]:
        getRegexPatternSetsPageRows();
        break;
      case wafTabs[3]:
        getRuleGroupsPageRows();
        break;
      default:
        break;
    }
  }, [getVariableData]);

  const pinButtonClickHandle = useCallback(() => {
    updateDefaultRegionList(
      projectId,
      Number(relatedCloudSelected.value),
      selectedRegion.name || '',
      selectedRegion.value.toString(),
    );
  }, [relatedCloudSelected]);

  useEffect(() => {
    if (isShowing) {
      getEnumTypeCode({
        variables: { text: regionCode || 'AwsRegionCode' },
      }).then(res => {
        if (res.data) {
          const regionsRes: DropdownListDataType[] = res.data.getEnumTypeCode.data
            .filter(val => regions.includes(val.value))
            .map(val => ({
              ...val,
              name: val.value,
            }));
          const regionsList = [
            {
              name: 'Global(CloudFront)',
              value: 'CloudFront',
            },
            ...regionsRes,
          ];
          setRegionList(regionsList);

          const hasDefaultRegion = defaultRegionList.find(r => {
            return r.projectId === projectId && r.cloudId === relatedCloudSelected.value;
          });

          if (!!hasDefaultRegion) {
            setSelectedRegion({
              name: hasDefaultRegion.name,
              value: hasDefaultRegion.value,
            });
            return;
          }

          setSelectedRegion({
            name: selectedRegion?.name,
            value: selectedRegion?.value,
          });
        }
      });
    }
  }, [isShowing, defaultRegionList, relatedCloudSelected]);

  const mainTabClickHandler = useCallback(
    (tab: IMgdTabProps) => {
      //load API
      //reset data
      if (tab.id != mainTabSelected.id) {
        setMainSelected('');
        setSearch('');
      }
      setMainTabSelected(wafTabs.find(tabItem => tabItem.id === tab.id) || wafTabs[0]);
    },
    [mainTabSelected],
  );

  useEffect(() => {
    let mainColumns: ColumnType[] = [];
    switch (mainTabSelected) {
      case wafTabs[0]:
        // VPC tab
        mainColumns = [
          {
            label: 'Name',
            field: 'name',
            sort: true,
            renderCell: (row: RowType) => (
              <>
                {
                  <div
                    className="link"
                    onClick={() => {
                      setIsShowing(MgdWafPageEnum.MGD_WEB_DETAIL);
                    }}
                  >
                    {handleFormatText(row?.name)}
                  </div>
                }
              </>
            ),
          },
          { label: 'Description', field: 'description', sort: true },
          { label: 'ID', field: 'id', sort: true },
        ];
        getWebACLsPageRows();
        break;
      case wafTabs[1]:
        mainColumns = [
          {
            label: 'Name',
            field: 'name',
            sort: true,
            renderCell: (row: RowType) => (
              <>
                {
                  <div
                    className="link"
                    onClick={() => {
                      setIsShowing(MgdWafPageEnum.MGD_IP_DETAIL);
                    }}
                  >
                    {handleFormatText(row?.name)}
                  </div>
                }
              </>
            ),
          },
          { label: 'Description', field: 'description', sort: true },
          { label: 'ID', field: 'id', sort: true },
        ];
        getIPSetsPageRows();
        break;
      case wafTabs[2]:
        mainColumns = [
          {
            label: 'Name',
            field: 'name',
            sort: true,
            renderCell: (row: RowType) => (
              <>
                {
                  <div
                    className="link"
                    onClick={() => {
                      setIsShowing(MgdWafPageEnum.MGD_REGEX_PATTERN_DETAIL);
                    }}
                  >
                    {handleFormatText(row?.name)}
                  </div>
                }
              </>
            ),
          },
          { label: 'Description', field: 'description', sort: true },
          { label: 'ID', field: 'id', sort: true },
        ];
        getRegexPatternSetsPageRows();
        break;
      case wafTabs[3]:
        mainColumns = [
          {
            label: 'Name',
            field: 'name',
            sort: true,
            renderCell: (row: RowType) => (
              <>
                {
                  <div
                    className="link"
                    onClick={() => {
                      setIsShowing(MgdWafPageEnum.MGD_RULE_GROUP_DETAIL);
                    }}
                  >
                    {handleFormatText(row?.name)}
                  </div>
                }
              </>
            ),
          },
          { label: 'Description', field: 'description', sort: true },
          { label: 'ID', field: 'id', sort: true },
        ];
        getRuleGroupsPageRows();
        break;
      default:
        break;
    }
    setMainTblColumns(mainColumns);
  }, [mainTabSelected, selectedRegion]);

  const fetchMoreMainRowsHandler = useCallback(
    (nextToken: string) => {
      switch (mainTabSelected) {
        case wafTabs[0]:
          getWebACLsPageRows(nextToken);
          break;
        case wafTabs[1]:
          getIPSetsPageRows(nextToken);
          break;
        case wafTabs[2]:
          getRegexPatternSetsPageRows(nextToken);
          break;
        case wafTabs[3]:
          getRuleGroupsPageRows(nextToken);
          break;
        default:
          break;
      }
    },
    [mainTabSelected],
  );

  const mainRowsCurrentPage = useMemo(() => {
    if (mainTablePagination.currentPage > mainTblTotal.totalPage && !!mainNextToken) {
      fetchMoreMainRowsHandler(mainNextToken);
    }
    const startIndex = (mainTablePagination.currentPage - 1) * mainTablePagination.itemPerPage;
    const endIndex = startIndex + mainTablePagination.itemPerPage;

    return orderAlphabetical(mainTblRows, mainTablePagination.target, mainTablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [mainTblRows, mainTablePagination]);

  const handleCreateBtnClick = useCallback(() => {
    switch (mainTabSelected) {
      case wafTabs[0]: {
        setIsShowing(MgdWafPageEnum.MGD_CREATE);
        break;
      }
      case wafTabs[1]: {
        setIsShowing(MgdWafPageEnum.MGD_CREATE_IP);
        break;
      }
      case wafTabs[2]: {
        setIsShowing(MgdWafPageEnum.MGD_CREATE_REGEX_PATTERN);
        break;
      }
      case wafTabs[3]:
        setIsOpenCreateCustomRule(true);
        break;

      default:
        break;
    }
  }, [mainTabSelected]);

  const [addResourceModalVisible, setAddResourceModalVisible] = useState(false);
  const [awsResources, setAwsResources] = useState<Array<AwsDistributionSummaryType>>([]);

  const handleAddAwsResource = useCallback((resources: Array<AwsDistributionSummaryType>) => {
    setAwsResources(resources);
  }, []);

  const handleDeleteModalOpenOrClose = useCallback(() => {
    if (mainTabSelected === wafTabs[0]) {
      setIsOpenDeleteResourceModal(prev => ({
        ...prev,
        deleteWebAcl: !prev.deleteWebAcl,
      }));
    }
    if (mainTabSelected === wafTabs[1]) {
      setIsOpenDeleteResourceModal(prev => ({
        ...prev,
        deleteIPSet: !prev.deleteIPSet,
      }));
    }
    if (mainTabSelected === wafTabs[2]) {
      setIsOpenDeleteResourceModal(prev => ({
        ...prev,
        deleteRegexPatternSet: !prev.deleteRegexPatternSet,
      }));
    }
    if (mainTabSelected === wafTabs[3]) {
      setIsOpenDeleteResourceModal(prev => ({
        ...prev,
        deleteRuleGroup: !prev.deleteRuleGroup,
      }));
    }
  }, [mainTabSelected]);

  const scope = useMemo((): string => {
    return selectedRegion.value === CLOUDFRONT_OPTION.value ? WafScopeEnum.CLOUDFRONT : WafScopeEnum.REGIONAL;
  }, [selectedRegion]);

  const regionValue = useMemo((): string => {
    return selectedRegion.value === CLOUDFRONT_OPTION.value ? CLOUDFRONT_REGION : selectedRegion.value.toString();
  }, [selectedRegion]);

  const handleDeleteRegexPatternSet = useCallback(() => {
    const currentRegexPattern: AwsRegexPatternSets = mainTblRows.find(row => row.id === mainSelected) as AwsRegexPatternSets;
    if (currentRegexPattern) {
      const reqVariable: IDeleteRegexPatternSetVariables = {
        cloudId: Number(relatedCloudSelected.value),
        region: regionValue,
        reqData: {
          name: currentRegexPattern?.name,
          scope: scope,
          id: currentRegexPattern?.id?.toString(),
          lockToken: currentRegexPattern?.lockToken,
        },
      };
      deleteRegexPatternSet({ variables: reqVariable }).then(({ data: deleteResponse }) => {
        if (deleteResponse?.deleteRegexPatternSet?.data === null) {
          useToast(ErrorCode.SUCCESS, 'Delete regex pattern set successful.');
          getRegexPatternSetsPageRows();
          handleDeleteModalOpenOrClose();
        } else {
          useToast(ErrorCode.UNKNOWN, 'Delete regex pattern set failed.');
        }
      });
    }
  }, [relatedCloudSelected, scope, regionValue, mainTblRows, mainSelected, getRegexPatternSetsPageRows]);

  const handleDeleteRuleGroup = useCallback(() => {
    const currentRuleGroup: AwsRuleGroupSummary = mainTblRows.find(row => row.id === mainSelected) as AwsRuleGroupSummary;
    if (currentRuleGroup) {
      const reqVariable: IDeleteRuleGroupVariables = {
        cloudId: Number(relatedCloudSelected.value),
        region: regionValue,
        reqData: {
          name: currentRuleGroup?.name,
          scope: scope,
          id: currentRuleGroup?.id?.toString(),
          lockToken: currentRuleGroup?.lockToken,
        },
      };
      deleteRuleGroup({variables: reqVariable}).then(({data: deleteResponse}) => {
        if (deleteResponse?.deleteRuleGroup?.data === null) {
          useToast(ErrorCode.SUCCESS, 'Delete rule group successful.');
          getRuleGroupsPageRows();
          handleDeleteModalOpenOrClose();
        } else {
          useToast(ErrorCode.UNKNOWN, 'Delete rule group failed.');
        }
      });
    }
  }, [relatedCloudSelected, scope, regionValue, mainTblRows, mainSelected, getRuleGroupsPageRows]);

  const renderShowing = useMemo(() => {
    switch (isShowing) {
      default:
        return (
          <MgdLayout
            layoutType={MgdLayoutTypeEnum.MGD_LIST_SUMMARY}
            tablePagination={mainTablePagination}
            updateTablePagination={(key: string, value: number | string | OrderDirection) =>
              setMainTablePagination(prev => ({
                ...prev,
                [key]: value,
              }))
            }
            recentRelatedClouds={recentRelatedClouds}
            relatedCloudSelected={relatedCloudSelected}
            recentRelatedCloudOnChange={relatedCloud => recentRelatedCloudOnChange(relatedCloud)}
            total={mainTblTotal}
            rows={mainRowsCurrentPage}
            columns={mainTblColumns}
            currentReport={''}
            checkedList={checkedList}
            reportCheckedList={(list: string[]) => {
              setCheckedList(list);
                if (list.length > 0) {
                  setMainSelected(list[list.length - 1]);
                }
            }}
            reportSelected={(id: string) => {
              setMainSelected(id);
              setCheckedList([id]);
            }}
            pageTitle={cloudName}
            hasFavorite={true}
            headerDropList={regionList}
            headerDropValue={selectedRegion}
            headerDropOnchange={val => {
              setSelectedRegion(() => ({
                name: val.name ? val.name : '',
                value: String(val.value),
              }));
            }}
            pageBackClick={pageBackClick}
            isPined={
              !!defaultRegionList.find(r => {
                return r.projectId === projectId && r.cloudId === relatedCloudSelected.value;
              })
            }
            pinBtnActHandle={pinButtonClickHandle}
            pinBtnDisabled={selectedRegion.value === 'CloudFront'}
            searchValue={search}
            searchInputOnchange={str => setSearch(str)}
            onSearchKeyDown={(event: any) => onSearchKeyDownHandler(event)}
            mainCreateBtnVisible={true}
            mainCreateBtnClicked={handleCreateBtnClick}
            mainDelBtnVisible={true}
            mainDelBtnDisabled={deleteBtnDisabled}
            mainDelBtnClicked={handleDeleteModalOpenOrClose}
            mainTabList={wafTabs}
            mainTabSelected={mainTabSelected}
            mainTabItemClick={mainTabClickHandler}
            mainTitle={mainTabSelected.title}
            mainIsLoading={mainApiIsLoading}
          />
        );
      case MgdWafPageEnum.MGD_CREATE:
        return (
          <CreateWebAcl
            regions={regions}
            onBackButtonClicked={() => setIsShowing(MgdWafPageEnum.MGD_LIST)}
            onAddResourceButtonClicked={() => setAddResourceModalVisible(true)}
            cloudId={Number(relatedCloudSelected.value)}
            region={selectedRegion.value.toString()}
            selectedRegion={selectedRegion}
            awsResources={awsResources}
            onReview={(data: any) => {
              setIsShowing(MgdWafPageEnum.MGD_CREATE_PREVIEW);
              setCreationData(data);
            }}
          />
        );
      case MgdWafPageEnum.MGD_WEB_DETAIL:
        return (
          <WebAclDetail
            cloudId={Number(relatedCloudSelected.value)}
            region={String(selectedRegion.value)}
            id={mainSelected}
            name={mainTblRows.find(r => r.id === mainSelected)?.name}
            pageBackClick={() => {
              setIsShowing(MgdWafPageEnum.MGD_LIST);
            }}
          />
        );
      case MgdWafPageEnum.MGD_CREATE_IP:
        return (
          <CreateIPSet
            cloudId={Number(relatedCloudSelected.value)}
            region={String(selectedRegion.value)}
            onBackButtonClicked={() => {
              getIPSetsPageRows();
              setIsShowing(MgdWafPageEnum.MGD_LIST);
            }}
          />
        );
      case MgdWafPageEnum.MGD_IP_DETAIL:
        return (
          <IPSetDetail
            cloudId={Number(relatedCloudSelected.value)}
            region={String(selectedRegion.value)}
            id={mainSelected}
            name={mainTblRows.find(r => r.id === mainSelected)?.name}
            pageBackClick={() => {
              setIsShowing(MgdWafPageEnum.MGD_LIST);
            }}
          />
        );
      case MgdWafPageEnum.MGD_CREATE_REGEX_PATTERN:
        return (
          <CreateRegexPattern
            cloudId={Number(relatedCloudSelected.value)}
            region={String(selectedRegion.value)}
            onBackButtonClicked={() => {
              getRegexPatternSetsPageRows();
              setIsShowing(MgdWafPageEnum.MGD_LIST);
            }}
          />
        );
      case MgdWafPageEnum.MGD_REGEX_PATTERN_DETAIL:
        return (
          <RegexPatternSetDetail
            cloudId={Number(relatedCloudSelected.value)}
            region={String(selectedRegion.value)}
            currentRegexPattern={mainTblRows.find(r => r.id === mainSelected) as AwsRegexPatternSets}
            pageBackClick={() => {
              setIsShowing(MgdWafPageEnum.MGD_LIST);
            }}
          />
        );
      case MgdWafPageEnum.MGD_RULE_GROUP_DETAIL:
        return (
          <RuleGroupDetail
            cloudId={Number(relatedCloudSelected.value)}
            region={String(selectedRegion.value)}
            currentRuleGroup={mainTblRows.find(r => r.id === mainSelected) as AwsRuleGroupSummary}
            pageBackClick={() => {
              setIsShowing(MgdWafPageEnum.MGD_LIST);
            }}
          />
        );

      case MgdWafPageEnum.MGD_CREATE_PREVIEW:
        return <Review onBackButtonClicked={pageBackClick} creationData={creationData} />;
    }
  }, [
    mainTablePagination,
    isShowing,
    mainTblRows,
    mainTblTotal,
    checkedList,
    mainSelected,
    selectedRegion,
    regionList,
    search,
    mainApiIsLoading,
    awsResources,
    deleteBtnDisabled,
    recentRelatedClouds,
    relatedCloudSelected,
  ]);

  return (
    <div className="category" style={{ height: '100%' }}>
      {renderShowing}
      <DeleteWebAclModal
        open={isOpenDeleteResourceModal.deleteWebAcl}
        name={mainTblRows.find(r => r.id === checkedList[0])?.name}
        title={() => (
          <>
            <Icon width={32} height={32} src={IconDeleteRed} />
            Delete {mainTblRows.find(r => r.id === checkedList[0])?.name}?
          </>
        )}
        onClose={handleDeleteModalOpenOrClose}
        onDelete={() => {
          const delRow = mainTblRows.find(r => r.id === checkedList[0]);
          if (delRow) {
            delWebAcl({
              variables: {
                cloudId: 1,
                region: String(selectedRegion.value),
                request: {
                  id: String(delRow.id),
                  name: String(delRow.name),
                  lockToken: String(delRow.lockToken),
                  scope: selectedRegion.value !== 'CloudFront' ? 'REGIONAL' : 'CLOUDFRONT',
                },
              },
            }).then(({ data }) => {
              if (data) {
                if (data.deleteWebACL.result === ErrorCode.SUCCESS) {
                  useSnackbar(data.deleteWebACL.result);
                  setIsOpenDeleteResourceModal(prev => ({
                    ...prev,
                    deleteWebAcl: false,
                  }));
                  getWebACLsPageRows();
                } else {
                  useSnackbar(data.deleteWebACL.result);
                }
              } else {
                useSnackbar(ErrorCode.UNKNOWN);
              }
            });
          } else {
            useSnackbar(ErrorCode.UNKNOWN);
          }
        }}
      />
      <DeleteRegexPatternSetModal
        resourceName={mainTblRows.find(r => r.id === checkedList[0])?.name}
        onClose={handleDeleteModalOpenOrClose}
        open={isOpenDeleteResourceModal.deleteRegexPatternSet}
        onDelete={handleDeleteRegexPatternSet}
      />
      <DeleteRuleGroupModal
        resourceName={mainTblRows.find(r => r.id === mainSelected)?.name}
        onClose={handleDeleteModalOpenOrClose}
        open={isOpenDeleteResourceModal.deleteRuleGroup}
        onDelete={handleDeleteRuleGroup}
      />
      {isOpenCreateCustomRule ? (
        <CustomRuleModal
          isOpen={isOpenCreateCustomRule}
          onClose={() => setIsOpenCreateCustomRule(false)}
          cloudId={Number(relatedCloudSelected.value)}
          selectedRegion={selectedRegion}
          onAddRule={() => {}}
        />
      ) : null}
    </div>
  );
};
export default Waf;
