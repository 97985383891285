import {DropdownListDataType} from "components/v2/atoms/DropdownAtom";

export const ebsVolumeFilterDropdown: DropdownListDataType[] = [
  {id: 1, name: 'Volume ID', value: 'volume-id'},
  {id: 2, name: 'Name', value: 'tag:Name'},
  {id: 3, name: 'Volume state', value: 'status'},
  {id: 4, name: 'Type', value: 'volume-type'},
]

export const ebsVolumeTab = [
  { id: 'details', name: 'Details', title: 'Details'},
  { id: 'statusChecks', name: 'Status checks', title: 'Status checks' },
  { id: 'tags', name: 'Tags', title: 'Tags' },
];
