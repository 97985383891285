import { RowType } from '@Types/v2/Table';
import Table from 'components/v2/dataDisplay/Table';
import { useMemo, useState } from 'react';
import { CUSTOME_RESPONSE_BODY_COLUMNS } from '../../../Commons/Constant';

interface ICustomResponseProps {
  customResponseBodyRows: RowType[];
  onSelectedCustomReponseBody: (rowId: string) => void;
  onEditResponseBody: () => void;
  onDeleteResponseBody: () => void;
  onCreateResponseBody: () => void;
}

const CustomResponse = (props: ICustomResponseProps) => {
  const { customResponseBodyRows, onSelectedCustomReponseBody, onEditResponseBody, onDeleteResponseBody, onCreateResponseBody } = props;
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [selected, setSelected] = useState<string>('');

  const updateBtnDisabled = useMemo((): boolean => {
    return selected === '' || checkedList.length !== 1;
  }, [selected, checkedList]);

  return (
    <div className="resize-container vertical">
      <div className="details">
        <div className="row-3">
          <div className="title flex j-between a-center">
            <div>
              <p>Custom response bodies</p>
              <p>
                Custom response bodies are plain text, JSON, or HTML content that you use to customize a response to
                blocked requests. You can use a response body anywhere in the rule group where you create it.
              </p>
            </div>
            <div className="flex action a-center">
              <button className="edit-btn" onClick={() => onEditResponseBody()} disabled={updateBtnDisabled}>
                Edit
              </button>
              <button className="action-btn" onClick={onCreateResponseBody}>
                Create custom response body
              </button>
              <button className="action-btn" onClick={() => onDeleteResponseBody()} disabled={updateBtnDisabled}>
                Delete
              </button>
            </div>
          </div>
        </div>
        {customResponseBodyRows.length == 0 ? (
          <div className="data-grid-wrap">
            <p className="empty-row">Empty</p>
          </div>
        ) : (
          <div className="data-grid-wrap">
            <Table
              rows={customResponseBodyRows}
              columns={CUSTOME_RESPONSE_BODY_COLUMNS}
              reportCheckedList={(list: string[]) => {
                setCheckedList(list);
                if (list.length > 0) {
                  setSelected(list[list.length - 1]);
                  onSelectedCustomReponseBody(list[list.length - 1]);
                }
              }}
              reportSelected={(id: string) => {
                setSelected(id);
                setCheckedList([id]);
                onSelectedCustomReponseBody(id);
              }}
              horizontalScrollable={true}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default CustomResponse;
