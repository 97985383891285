import { useLazyQuery } from '@apollo/client';
import query from './query';
import { Paging } from 'graphql/types/Paging';
import { AwsSecretListEntryType } from 'graphql/types/AwsAllListSecrets';
import { IGqlResponseNewData } from 'graphql/types';

export interface IAwsAllListSecretsVariables {
  cloudId: number;
  region: string;
  request: {
    includePlannedDeletion?: boolean;
    maxResults?: number;
    nextToken?: string;
    filters?: {
      name: string;
      values: string[];
    };
    sortOrder?: string;
    pageInfo?: Paging;
  };
}

export interface IAwsAllListSecretsResponseData {
  getAwsAllListSecrets: IGqlResponseNewData<AwsSecretListEntryType>;
}

const lazyGetAwsAllListSecretsPage = () =>
  useLazyQuery<IAwsAllListSecretsResponseData, IAwsAllListSecretsVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsAllListSecretsPage;
