import { PropsWithChildren, useRef } from 'react';
import './index.scss';
import { Tooltip, TooltipRefProps } from 'react-tooltip';
import CloseIcon from 'assets/svgs/v2/ico_close.svg';
import Icon from 'components/v2/atoms/Icon';

interface IMgdTooltipProps {
  id?: string;
  title: string;
  description?: string
}

const MgdTooltip = ({
  id,
  title,
  description,
  children
}:PropsWithChildren<IMgdTooltipProps>) => {
  const tooltipRef = useRef<TooltipRefProps>(null);

  return (
    <div id={`my-tooltip-${id}`}>
      <p className='link'>{title}</p>
      <Tooltip
        anchorSelect={`#my-tooltip-${id}`}
        place={'bottom-start'}
        clickable
        openOnClick={true}
        className='mgd-tooltip-container'
        variant={'info'}
        noArrow={true}
        globalCloseEvents={{
          clickOutsideAnchor: true
        }}
        ref={tooltipRef}
      >
        {/* {description && 
        <div className='mgd-tooltip'>{description}</div>
        } */}
        
        <div className='mgd-tooltip'>
          {description && 
            <div className='description'>{description}</div>
          }
          {children}
        </div>
        <Icon className="close-btn" width={10} height={10} src={CloseIcon} onClick={() => tooltipRef.current?.close()}/>
      </Tooltip>
    </div>
  );
};

export default MgdTooltip;
