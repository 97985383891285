import React from 'react';
import { AddTLSInspectionConfigurationStepPropsType } from '../types';
import { FIREWALL_POLICY_CREATION } from '../configs';
import CheckboxAtom from 'components/v2/atoms/CheckboxAtom';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import 'index.scss';

const AddTLSInspectionConfigurationStep = (props: AddTLSInspectionConfigurationStepPropsType) => {
  const { onValueChanged, tlsInspectionConfiguration, onRemoveButtonClicked } = props;
  return (
    <div>
      <div className="rule-group-container">
        <div className="title-group">
          <p className="title">{FIREWALL_POLICY_CREATION.ADD_TLS_INSPECTION_CONFIGURATION_TITLE}</p>
          <p className="sub-title">{FIREWALL_POLICY_CREATION.ADD_TLS_INSPECTION_CONFIGURATION_DESCRIPTION}</p>
        </div>

        <div className="rule-group-details-container">
          <div
            className="rule-group-detail-title"
            style={{ gap: 8, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
          >
            {FIREWALL_POLICY_CREATION.TLS_INSPECTION_CONFIGURATIONS_TITLE}
            <span className="rule-group-detail-sub-title">
              {FIREWALL_POLICY_CREATION.TLS_INSPECTION_CONFIGURATIONS_DESCRIPTION}
            </span>
          </div>

          <div
            className="rule-group-detail-title"
            style={{ gap: 8, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
          >
            <span className="rule-group-detail-sub-title">
              {FIREWALL_POLICY_CREATION.TLS_INSPECTION_CONFIGURATIONS_DROPDOWN_LABEL}
            </span>

            <div style={{ width: '50%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
              <DropdownAtom
                id="msg-use"
                placeholder={FIREWALL_POLICY_CREATION.TLS_INSPECTION_CONFIGURATIONS_DROPDOWN_PLACEHOLDER}
                data={[]}
                value={{ name: '', value: '' }}
                handleClick={val => onValueChanged('statefulRuleDropAction', val.value)}
              />
              <button className="button" style={{ marginLeft: 16 }} onClick={onRemoveButtonClicked}>
                {FIREWALL_POLICY_CREATION.TLS_INSPECTION_CONFIGURATIONS_REMOVE_BUTTON}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTLSInspectionConfigurationStep;
