import { useCallback, useEffect, useMemo, useState } from 'react';
import { AreaInput, Input, PageTitle, RadioButtonGroup } from '../../WebACL/CreateWebACL/components';
import Button, { ButtonTypeEnum } from '../../WebACL/CreateWebACL/components/Button';
import InputGroup from '../../WebACL/CreateWebACL/components/InputGroup';
import { DropdownListDataType } from 'components/Dropdown/types';
import Dropdown from 'components/Dropdown';
import { lazyGetEnumTypeCode } from 'graphql/queries/getEnumTypeCode';
import { regionCodeFormatter } from 'utils/Formatter';
import { CLOUDFRONT_OPTION, CLOUDFRONT_REGION, IP_VERSION_DATA, WafScopeEnum } from '../../Commons/Constant';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';
import './index.scss';
import { RadioButtonType } from '../../WebACL/CreateWebACL/components/types';
import Radio from '../../WebACL/CreateWebACL/components/Radio';
import createAwsIPSetMutation, { ICreateIPSetVariables } from 'graphql/mutations/createIPSet';

interface ICreateIPSet {
  cloudId: number;
  region: string;
  onBackButtonClicked: () => void;
}

const CreateIPSet = (props: ICreateIPSet) => {
  const { onBackButtonClicked, cloudId, region } = props;
  // API
  const [getEnumTypeCode] = lazyGetEnumTypeCode();
  const [createAwsIPSet] = createAwsIPSetMutation();
  // State
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [iPAddress, setIPAddress] = useState<string>('');
  const [regionList, setRegionList] = useState<Array<{ name: string; value: string }>>([]);
  const [regionSelected, setRegionSelected] = useState<DropdownListDataType>(regionList[0]);
  const [iPVersionRadioData, setIPVersionRadioData] = useState<RadioButtonType[]>(IP_VERSION_DATA);
  const [iPVersion, setIPVersion] = useState<string>(IP_VERSION_DATA[0].value);
  const [nameValid, setNameValid] = useState<boolean>(false);
  const [regionValid, setRegionValid] = useState<boolean>(false);
  const [iPAddressValid, setIPAddressValid] = useState<boolean>(false);

  const iPAddressPlaceHolder = useMemo((): string => {
    return iPVersion === IP_VERSION_DATA[0].value ? '10.0.0.0/32' : '2001:db8:a0b:12f0::1/128';
  }, [iPVersion]);

  const getRegionList = useCallback(() => {
    getEnumTypeCode({ variables: { text: 'AwsRegionCode' } }).then(res => {
      const codes =
        res?.data?.getEnumTypeCode?.data?.map(code => ({
          name: regionCodeFormatter(code?.value) || code?.name,
          value: code?.value,
        })) ?? [];
      codes.unshift({ name: CLOUDFRONT_OPTION.name, value: CLOUDFRONT_OPTION.value });
      setRegionList(codes);
      setRegionSelected(codes.find(code => code.value === region) ?? codes[0]);
    });
  }, [getEnumTypeCode, region]);

  useEffect(() => {
    getRegionList();
  }, [getRegionList]);

  const onCreateButtonClicked = useCallback(() => {
    setNameValid(name === '');
    setRegionValid(!regionSelected);
    setIPAddressValid(iPAddress.trim() === '')
    if (name !== '' || !!regionSelected || iPVersion != '' || iPAddress.trim() !== '') {
      const scope = regionSelected.value === CLOUDFRONT_OPTION.value ? WafScopeEnum.CLOUDFRONT : WafScopeEnum.REGIONAL;
      const regionValue =
        regionSelected.value === CLOUDFRONT_OPTION.value ? CLOUDFRONT_REGION : regionSelected.value.toString();
      const addressStringList = iPAddress?.split('\n');
      const reqVariable: ICreateIPSetVariables = {
        cloudId: cloudId,
        region: regionValue,
        reqData: {
          name: name,
          scope,
          description: description,
          ipAddressVersion: iPVersion,
          addresses: addressStringList,
        }
      };
      createAwsIPSet({variables: reqVariable}).then(({data: createAwsIPSetResponse}) => {
        const response = createAwsIPSetResponse?.createAwsIPSet?.data?.[0]?.summary
        if (response) {
          useToast(ErrorCode.SUCCESS, 'Create IP set successful.');
          onBackButtonClicked();
        } else {
          useToast(ErrorCode.UNKNOWN, 'Create IP set failed.');
        }
      });
    }
  }, [name, regionSelected, iPVersion, description, iPAddress, cloudId]);

  const buttonGroupNode = useMemo(() => {
    return (
      <div className="button-group">
        <Button label="Cancel" onClick={onBackButtonClicked} />
        <Button label="Create IP set" type={ButtonTypeEnum.PRIMARY} onClick={onCreateButtonClicked} />
      </div>
    );
  }, [onCreateButtonClicked]);

  return (
    <div id="create-ip-set-page-container">
      <PageTitle title={'Create IP Set'} hasBackButton onBackButtonClicked={onBackButtonClicked} />
      <div className="page-container">
        <div className="title-container">
          <p>IP set details</p>
        </div>
        <InputGroup>
          <Input
            title={'IP set name'}
            placeholder="IP set name"
            bottomDescription="The name must have 1 - 128 characters, Valid characters: A-Z, a-z, 0-9, -(hypehn), and _ (underscore)"
            value={name}
            onValueChanged={setName}
            isRequired
            error={nameValid}
          />
          <Input
            title={'Description'}
            placeholder="Enter description"
            bottomDescription="The description can have 1-256 characters."
            value={description}
            onValueChanged={setDescription}
          />
          <Dropdown
            title={'Region'}
            isRequired
            data={regionList}
            id="region"
            handleClick={setRegionSelected}
            value={regionSelected}
            placeholder="Choose region"
            error={regionValid}
          />
          <Radio
            title={'IP version'}
            isRequired
            data={iPVersionRadioData}
            value={iPVersion}
            onValueChanged={setIPVersion}
          />
          <AreaInput
            title={'IP addresses'}
            placeholder={iPAddressPlaceHolder}
            bottomDescription="Enter one IP address per line in CIDR format."
            isRequired
            error={iPAddressValid}
            value={iPAddress}
            onValueChanged={setIPAddress}
          />
        </InputGroup>
        {buttonGroupNode}
      </div>
    </div>
  );
};

export default CreateIPSet;

