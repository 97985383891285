/** 영어, 숫자만,점, 밑줄, 수평선 사용하여 ~글자 이상, 최대 ~글자 */
export const idRegex = /^[a-zA-Z0-9._-]*$/;
/** 숫자, 영문자, 특수문자 조합의 8글자 이상, 최대 12글자 */
export const pwRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/;
export const emailRegex = /^[a-zA-Z0-9+-\\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
/** 연락처 */
export const onlyNumRegex = /^[0-9]*$/;
/** 사용자 이름 : 영어 대소문자, 숫자, 한글, 띄어쓰기 가능. 2글자 이상, 최대 30자 */
export const nameRegex = /^[a-zA-Z0-9가-힣\s]{2,}$/;
/* 숫자 외 불가 */
export const numberRegex = /[^0-9]/g;
/* 다른 숫자와 알파벳은 허용되지 않습니다 */
export const alphabetNumberRegex = /[^a-zA-Z0-9]/g;
/* 특수문자 replace처리를 위해 */
export const koEnNumSpRegex = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi;