import { gql } from '@apollo/client';

const query = gql`
  query getAwsPolicyVersion($request: AwsGetPolicyVersionRequest, $cloudId: Long, $region: String) {
    getAwsPolicyVersion(getPolicyVersionRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsGetPolicyVersionResponse {
          policyVersion {
            document
            versionId
            isDefaultVersion
            createDate
          }
        }
      }
    }
  }
`;
export default query;
