import { RowType } from '@Types/v2/Table';
import MgdTooltip from 'components/v2/MgdTooltip';
import { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import { IMgdTabProps } from 'layouts/v3/MgdTabLayout/types';
import { Fragment } from 'react';
import { handleFormatText } from 'utils/Common';

export const IAM_TAB_LIST: Array<IMgdTabProps> = [
  { id: 'userGroups', name: 'User groups', title: 'User groups' },
  { id: 'user', name: 'User', title: 'User' },
  { id: 'role', name: 'Role', title: 'Role' },
  { id: 'policy', name: 'Policy', title: 'Policy' },
];

export const INSTANCE_COLUMN_LIST = [
  {
    field: 'name',
    label: 'Name',
    sort: true,
  },
  {
    field: 'instanceId',
    label: 'Instance ID',
    sort: true,
    renderCell: (row: RowType) => (
      <p
        className="description link"
        onClick={e => {
          e.stopPropagation();
          row.onInstanceIdClicked(row.id);
        }}
      >
        {handleFormatText(row.instanceId)}
      </p>
    ),
  },
  {
    field: 'instanceState',
    label: 'Instance State',
    sort: true,
  },
  {
    field: 'instanceType',
    label: 'Instance Type',
    sort: true,
  },
  {
    field: 'statusCheck',
    label: 'Status Check',
    sort: true,
  },
  {
    field: 'availableZone',
    label: 'Available Zone',
    sort: true,
  },
  {
    field: 'publicIpv4Dns',
    label: 'Public IPv4 DNS',
    sort: true,
  },
  {
    field: 'publicIpv4Address',
    label: 'Public IPv4 address',
    sort: true,
  },
  {
    field: 'elasticIp',
    label: 'Elastic IP',
    sort: true,
  },
  {
    field: 'ipv6Ips',
    label: 'IPv6 IPs',
    sort: true,
  },
  {
    field: 'monitoring',
    label: 'Monitoring',
    sort: true,
  },
  {
    field: 'securityGroupName',
    label: 'Security group name',
    sort: true,
  },
  {
    field: 'keyName',
    label: 'Key name',
    sort: true,
  },
  {
    field: 'launchTime',
    label: 'Launch time',
    sort: true,
  },
  {
    field: 'platform',
    label: 'Platform',
    sort: true,
  },
];

export const INSTANCE_SEARCH_TYPES: DropdownListDataType[] = [
  { id: 1, name: 'Instance ID', value: 'instance-id' },
  { id: 2, name: 'Name', value: 'tag:Name' },
  { id: 3, name: 'Instance state', value: 'instance-state-name' },
  { id: 4, name: 'Instance type', value: 'instance-type' },
  { id: 5, name: 'Availability Zone', value: 'availability-zone' },
  { id: 6, name: 'Public IPv4 DNS', value: 'dns-name' },
  { id: 7, name: 'VPC ID', value: 'vpc-id' },
  { id: 8, name: 'Subnet IDs', value: 'subnet-id' },
  { id: 9, name: 'Image ID', value: 'image-id' },
  { id: 10, name: 'Volume ID', value: 'block-device-mapping.volume-id' },
];

export const LOAD_BALANCER_SEARCH_TYPES: DropdownListDataType[] = [
  { id: 1, name: 'Name', value: 'tag:Name' },
  { id: 2, name: 'DNS name', value: 'dns-name' },
  { id: 3, name: 'Availability Zones', value: 'availability-zone' },
  { id: 4, name: 'State', value: 'state-name' },
  { id: 5, name: 'VPC ID', value: 'vpc-id' },
];

export const LOAD_BALANCE_COLUMN_LIST = [
  {
    field: 'name',
    label: 'Name',
    sort: true,
    renderCell: (row: RowType) => (
      <p
        className="description link"
        onClick={e => {
          e.stopPropagation();
          row.onLoadBalancerNameClicked(row.id);
        }}
      >
        {handleFormatText(row.name)}
      </p>
    ),
  },
  {
    field: 'dnsName',
    label: 'DNS name',
    sort: true,
  },
  {
    field: 'state',
    label: 'State',
    sort: true,
  },
  {
    field: 'vpcId',
    label: 'VPC ID',
    sort: true,
  },
  {
    field: 'availabilityZones',
    label: 'Availability zones',
    sort: true,
    renderCell: (row: RowType) => (
      <Fragment>
        {row?.availabilityZones?.length < 2 ? (
          <p>{row?.availabilityZones || '-'}</p>
        ) : (
          <div id={`my-tooltip-${row?.id}`}>
            <MgdTooltip
              id={row?.id as string}
              title={`${row?.availabilityZones?.length} Availability zones`}
              description={'Enabled Availability Zones'}
            >
              {row?.availabilityZones?.length > 1 &&
                row?.availabilityZones.map((az: any, index: number) => {
                  return <p key={`my-tooltip-item-${index}`}>{handleFormatText(az)}</p>;
                })}
            </MgdTooltip>
          </div>
        )}
      </Fragment>
    ),
  },
  {
    field: 'type',
    label: 'Type',
    sort: true,
  },
  {
    field: 'dateCreated',
    label: 'Date created',
    sort: true,
  },
];
