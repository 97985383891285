import { useState, useEffect } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import IconPolicyVariables from 'assets/svgs/v3/ico_policy_variables.svg';
import Icon from 'components/v2/atoms/Icon';
import './index.scss';

interface IEditPolicyVariablesProps extends IBaseModalProps {
  header: string;
  onChangeValue?: (index: number, value: string) => void;
}

const EditPolicyVariablesModal = ({
  header,
  onChangeValue,
  ...baseModalProps
}: IEditPolicyVariablesProps) => {

  const [cidrValue, setCidrValue] = useState<string>('');

  useEffect(() => {
    if (baseModalProps.open) {
      setCidrValue('');
    }
  }, [baseModalProps.open]);

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={IconPolicyVariables} />
          {header}
        </>
      )}
      {...baseModalProps}>

      <div className="policy-variables-modal">
        <div className="policy-variables">
          <p className="text-title">HOME_NET variables override values-optional</p>
          <textarea
            className="input-textarea"
            value={cidrValue}
            onChange={e => {
              setCidrValue(e.target.value);
            }}/>
          <p className="text-description">Enter 1 CIDR per line.</p>
        </div>

        <div className="button-group">
          <button onClick={baseModalProps.onClose}>Cancel</button>
          <button className="delete-btn" onClick={() => {}}>
            Save
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default EditPolicyVariablesModal;
