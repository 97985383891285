export const variableCombineNextToken = (variable: any, nextToken?: string) => {
  if (nextToken) {
    return {
      ...variable,
      request: {
        ...variable.request,
        nextToken: nextToken,
      },
    };
  }
  return variable;
};
