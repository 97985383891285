import { gql } from '@apollo/client';

const query = gql`query getAllAccounts($reqData: ReqGetAssumeRoleCFT) {
  getAllAccounts(reqData: $reqData) {
    result
    data {
      __typename
      ... on AwsAccount {
        id
        arn
        email
        name
        status
        joinedMethod
      }
    }
  }
}`;
export default query;