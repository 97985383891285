import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsListDistributionsResponseType } from 'graphql/types/AwsListDistributions';

export interface IGetAwsListDistributionsVariables {
  cloudId: number;
  region: string;
  request: {
    maxItems?: number;
  };
}

export interface IAwsListDistributionsResponseData {
  getAwsListDistributions: IGqlResponseNewData<AwsListDistributionsResponseType>;
}

const lazyGetAwsListDistributions = () =>
  useLazyQuery<IAwsListDistributionsResponseData, IGetAwsListDistributionsVariables>(query, {
    fetchPolicy: 'no-cache',
  });
export default lazyGetAwsListDistributions;
