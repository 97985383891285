import React, { useMemo, useState } from 'react';
import { InstanceSummaryPropsType } from '../types';
import { INSTANCE_SUMMARY_TAB_LIST } from './configs';
import Description from './Tabs/Description';
import Security from './Tabs/Security';
import Networking from './Tabs/Networking';
import Storage from './Tabs/Storage';
import Tags from './Tabs/Tags';
import './InstanceSummary.scss';

const InstanceSummary = (props: InstanceSummaryPropsType) => {
  const { instance, cloudId, region, isSummaryHidden, projectId } = props;
  const [tabSelected, setTabSelected] = useState(INSTANCE_SUMMARY_TAB_LIST[0]);

  const title = useMemo(() => {
    return instance?.tags?.find(tag => tag.key === 'Name')?.value ?? '';
  }, [instance]);

  const tabNode = useMemo(() => {
    switch (tabSelected.id) {
      case INSTANCE_SUMMARY_TAB_LIST[0].id:
        return <Description instance={instance} isSummaryHidden={isSummaryHidden} projectId={projectId} />;

      case INSTANCE_SUMMARY_TAB_LIST[1].id:
        return <Security instance={instance} cloudId={cloudId} region={region} />;

      case INSTANCE_SUMMARY_TAB_LIST[2].id:
        return <Networking instance={instance} cloudId={cloudId} region={region} />;

      case INSTANCE_SUMMARY_TAB_LIST[3].id:
        return <Storage instance={instance} cloudId={cloudId} region={region} />;

      case INSTANCE_SUMMARY_TAB_LIST[4].id:
        return <Tags instance={instance} />;

      default:
        return null;
    }
  }, [tabSelected, instance, cloudId, region]);

  if (!instance) {
    return (
      <div className="detail-data">
        <p className="empty-page">Select a item</p>
      </div>
    );
  }

  return (
    <div className="box-summary">
      {!isSummaryHidden && <div className="box-summary-id">{title}</div>}

      <div className="detail-tab flex a-center">
        {INSTANCE_SUMMARY_TAB_LIST.map(tab => {
          return (
            <button
              className={`detail-tab-items ${tabSelected?.id === tab.id && 'active'}`}
              key={tab.id}
              data-tab={tab.id}
              onClick={e => setTabSelected(tab)}
            >
              <p>{tab.name}</p>
            </button>
          );
        })}
      </div>

      <div className="content-tab">{tabNode}</div>
    </div>
  );
};

export default InstanceSummary;
