import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsWebAcLResponse } from 'graphql/types/AwsWebACL';

export interface IAwsWebAclVariables {
  cloudId: number;
  region: string;
  request: {
    name: string;
    id: string;
    scope: string;
  };
}

export interface IAwsWebAcLResponseData {
  getAwsWebACL: IGqlResponseNewData<AwsWebAcLResponse>;
}

const lazyGetWebAcl = () =>
  useLazyQuery<IAwsWebAcLResponseData, IAwsWebAclVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetWebAcl;
