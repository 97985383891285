import React, {ChangeEvent, useRef, useState} from 'react';
import '../FirewallCreation/index.scss';
import NetworkTitle from "components/v3/NetworkTitle";
import InputAtom from 'components/v2/atoms/InputAtom';
import RequiredIcon from 'assets/svgs/v2/ico_require.svg';
import CheckboxAtom from 'components/v2/atoms/CheckboxAtom';
import SearchIcon from "assets/svgs/v3/ico_search.svg";
import ClearIcon from "assets/svgs/v2/ico_input_clear.svg";
import Table from "components/v2/dataDisplay/Table";
import {DropdownListDataType} from "components/v2/atoms/DropdownAtom";
import TablePagination from "components/v2/dataDisplay/TablePagination";
import {OrderDirection, RowType} from "@Types/v2/Table";
import {CustomDropdown} from "pages/v2/Organ/Management/Firewall/FirewallCreation";
import '../../../../../../../src/components/v2/atoms/AutocompleteInputAtom/index.scss';
import ArrowIcon from 'assets/svgs/v2/ico_dropdown_arrow.svg';
import CloseIcon from 'assets/svgs/v3/ico_close.svg';
import {IMgdTablePaginationProps} from "layouts/v3/MgdLayout";

interface IRuleGroupDetails {
  name: string;
  description?: string;
  capacity?: string;
}

interface IIpsetVariable {
  name: string;
  value: string;
}

interface IIpSetReferenceVariable {
  name?: string;
  resourceId?: string;
  cidr?: string;
}

const ruleGroupFormatData: DropdownListDataType[] = [
  {
    id: 1,
    name: 'Standard stateful rule',
    description: 'Specify the source and destination IP addresses and ports, protocol, and other rule options.',
    value: 'standard-stateful-rule'
  },
  {
    id: 2,
    name: 'Domain list',
    description: 'Specify a list of domain names and the action to take for traffic that tries to access one of the domains.',
    value: 'domain-list'
  },
  {
    id: 3,
    name: 'Suricata compatible rule string',
    description: 'Provide advanced firewall rules using Suricata rule syntax. Suricata is an open source threat detection engine that includes a standard rule-based language.',
    value: 'suricata-compatible-rule-string'
  }
]

const ruleRowsData: RowType[] = [
  {
    id: 1,
    selection: '',
    isCheck: true,
    protocol: 'TCP',
    source: 'ANY',
    destination: 'ANY',
    sourcePort: 'ANY',
    destinationPort: 'ANY',
    direction: 'Forward',
    action: 'Pass'
  },
  {
    id: 2,
    selection: '',
    isCheck: false,
    protocol: 'TCP',
    source: 'ANY',
    destination: 'ANY',
    sourcePort: 'ANY',
    destinationPort: 'ANY',
    direction: 'Forward',
    action: 'Pass'
  }
]

const ruleRowsDataDomainList: RowType[] = [
  {
    id: 1,
    priority: 1,
    protocol: 'TCP/CPT',
    source: 'ANY',
    destination: 'ANY',
    sourcePort: 'ANY',
    destinationPort: 'ANY',
  },
  {
    id: 2,
    priority: 2,
    protocol: 'ALL/CPT',
    source: 'ANY',
    destination: 'ANY',
    sourcePort: 'ANY',
    destinationPort: 'ANY',
  }
]

const listProtocols: DropdownListDataType[] = [
  {
    id: 1,
    name: 'All',
    value: 'All',
    description: 'All protocols'
  },
  {
    id: 2,
    name: 'HOPOPT',
    value: 'HOPOPT',
    description: 'Protocol: 0'
  },
  {
    id: 3,
    name: 'ICMP',
    value: 'ICMP',
    description: 'Protocol: 1'
  },
  {
    id: 4,
    name: 'IGMP',
    value: 'IGMP',
    description: 'Protocol: 2'
  },
  {
    id: 5,
    name: 'GGP',
    value: 'GGP',
    description: 'Protocol: 3'
  },
  {
    id: 6,
    name: 'IP-in-IP',
    value: 'IP-in-IP',
    description: 'Protocol: 4'
  },
  {
    id: 7,
    name: 'ST',
    value: 'ST',
    description: 'Protocol: 5'
  },
  {
    id: 8,
    name: 'TCP',
    value: 'TCP',
    description: 'Protocol: 6'
  },
  {
    id: 9,
    name: 'CBT',
    value: 'CBT',
    description: 'Protocol: 7'
  },
  {
    id: 10,
    name: 'EGP',
    value: 'EGP',
    description: 'Protocol: 8'
  },
  {
    id: 11,
    name: 'IGP',
    value: 'IGP',
    description: 'Protocol: 9'
  },
  {
    id: 12,
    name: 'BBN-RCC-MON',
    value: 'BBN-RCC-MON',
    description: 'Protocol: 10'
  },
  {
    id: 13,
    name: 'NVP-II',
    value: 'NVP-II',
    description: 'Protocol: 11'
  },
  {
    id: 14,
    name: 'PUP',
    value: 'PUP',
    description: 'Protocol: 12'
  },
  {
    id: 15,
    name: 'ARGUS',
    value: 'ARGUS',
    description: 'Protocol: 13'
  },
  {
    id: 16,
    name: 'EMCON',
    value: 'EMCON',
    description: 'Protocol: 14'
  },
  {
    id: 17,
    name: 'XNET',
    value: 'XNET',
    description: 'Protocol: 15'
  },
  {
    id: 18,
    name: 'CHAOS',
    value: 'CHAOS',
    description: 'Protocol: 16'
  },
  {
    id: 19,
    name: 'UDP',
    value: 'UDP',
    description: 'Protocol: 17'
  },
  {
    id: 20,
    name: 'MUX',
    value: 'MUX',
    description: 'Protocol: 18'
  },
  {
    id: 21,
    name: 'DCN-MEAS',
    value: 'DCN-MEAS',
    description: 'Protocol: 19'
  },
  {
    id: 22,
    name: 'HMP',
    value: 'HMP',
    description: 'Protocol: 20'
  },
  {
    id: 23,
    name: 'PRM',
    value: 'PRM',
    description: 'Protocol: 21'
  },
  {
    id: 24,
    name: 'XNS',
    value: 'XNS',
    description: 'Protocol: 22'
  },
  {
    id: 25,
    name: 'TRUNK-1',
    value: 'TRUNK-1',
    description: 'Protocol: 23'
  },
  {
    id: 26,
    name: 'TRUNK-2',
    value: 'TRUNK-2',
    description: 'Protocol: 24'
  },
  {
    id: 27,
    name: 'LEAF-1',
    value: 'LEAF-1',
    description: 'Protocol: 25'
  },
  {
    id: 28,
    name: 'LEAF-2',
    value: 'LEAF-2',
    description: 'Protocol: 26'
  },
  {
    id: 29,
    name: 'RDP',
    value: 'RDP',
    description: 'Protocol: 27'
  },
  {
    id: 30,
    name: 'IRTP',
    value: 'IRTP',
    description: 'Protocol: 28'
  },
  {
    id: 31,
    name: 'ISO-TP4',
    value: 'ISO-TP4',
    description: 'Protocol: 29'
  },
  {
    id: 32,
    name: 'NETBLT',
    value: 'NETBLT',
    description: 'Protocol: 30'
  },
  {
    id: 33,
    name: 'MFE-NSP',
    value: 'MFE-NSP',
    description: 'Protocol: 31'
  },
  {
    id: 34,
    name: 'MERIT-INP',
    value: 'MERIT-INP',
    description: 'Protocol: 32'
  },
  {
    id: 35,
    name: 'DCCP',
    value: 'DCCP',
    description: 'Protocol: 33'
  },
  {
    id: 36,
    name: '3PC',
    value: '3PC',
    description: 'Protocol: 34'
  },
  {
    id: 37,
    name: 'IDPR',
    value: 'IDPR',
    description: 'Protocol: 35'
  },
  {
    id: 38,
    name: 'XTP',
    value: 'XTP',
    description: 'Protocol: 36'
  },
  {
    id: 39,
    name: 'DDP',
    value: 'DDP',
    description: 'Protocol: 37'
  },
  {
    id: 40,
    name: 'IDPR-CMTP',
    value: 'IDPR-CMTP',
    description: 'Protocol: 38'
  },
  {
    id: 41,
    name: 'TP++',
    value: 'TP++',
    description: 'Protocol: 39'
  },
  {
    id: 42,
    name: 'IL',
    value: 'IL',
    description: 'Protocol: 40'
  },
  {
    id: 43,
    name: 'IPv6',
    value: 'IPv6',
    description: 'Protocol: 41'
  },
  {
    id: 44,
    name: 'SDRP',
    value: 'SDRP',
    description: 'Protocol: 42'
  },
  {
    id: 45,
    name: 'IPv6-Route',
    value: 'IPv6-Route',
    description: 'Protocol: 43'
  },
  {
    id: 46,
    name: 'IPv6-Frag',
    value: 'IPv6-Frag',
    description: 'Protocol: 44'
  },
  {
    id: 47,
    name: 'IDRP',
    value: 'IDRP',
    description: 'Protocol: 45'
  },
  {
    id: 48,
    name: 'RSVP',
    value: 'RSVP',
    description: 'Protocol: 46'
  },
  {
    id: 49,
    name: 'GREs',
    value: 'GREs',
    description: 'Protocol: 47'
  },
  {
    id: 50,
    name: 'DSR',
    value: 'DSR',
    description: 'Protocol: 48'
  },
  {
    id: 51,
    name: 'BNA',
    value: 'BNA',
    description: 'Protocol: 49'
  },
  {
    id: 52,
    name: 'DSP',
    value: 'DSP',
    description: 'Protocol: 50'
  },
  {
    id: 53,
    name: 'AH',
    value: 'AH',
    description: 'Protocol: 51'
  },
  {
    id: 54,
    name: 'I-NLSP',
    value: 'I-NLSP',
    description: 'Protocol: 52'
  },
  {
    id: 55,
    name: 'SwIPe',
    value: 'SwIPe',
    description: 'Protocol: 53'
  },
  {
    id: 56,
    name: 'NARP',
    value: 'NARP',
    description: 'Protocol: 54'
  },
  {
    id: 57,
    name: 'MOBILE',
    value: 'MOBILE',
    description: 'Protocol: 55'
  },
  {
    id: 58,
    name: 'TLSP',
    value: 'TLSP',
    description: 'Protocol: 56'
  },
  {
    id: 59,
    name: 'SKIP',
    value: 'SKIP',
    description: 'Protocol: 57'
  },
  {
    id: 60,
    name: 'IPv6-ICMP',
    value: 'IPv6-ICMP',
    description: 'Protocol: 58'
  },
  {
    id: 61,
    name: 'IPv6-NoNxt',
    value: 'IPv6-NoNxt',
    description: 'Protocol: 59'
  },
  {
    id: 62,
    name: 'IPv6-Opts',
    value: 'IPv6-Opts',
    description: 'Protocol: 60'
  },
  {
    id: 63,
    name: 'Any host internal protocol',
    value: 'Any host internal protocol',
    description: 'Protocol: 61'
  },
  {
    id: 64,
    name: 'CFTP',
    value: 'CFTP',
    description: 'Protocol: 62'
  },
  {
    id: 65,
    name: 'Any local network',
    value: 'Any local network',
    description: 'Protocol: 63'
  },
  {
    id: 66,
    name: 'SAT-EXPAK',
    value: 'SAT-EXPAK',
    description: 'Protocol: 64'
  },
  {
    id: 67,
    name: 'KRYPTOLAN',
    value: 'KRYPTOLAN',
    description: 'Protocol: 65'
  },
  {
    id: 68,
    name: 'RVD',
    value: 'RVD',
    description: 'Protocol: 66'
  },
  {
    id: 69,
    name: 'IPPC',
    value: 'IPPC',
    description: 'Protocol: 67'
  },
  {
    id: 70,
    name: 'Any distributed file system',
    value: 'Any distributed file system',
    description: 'Protocol: 68'
  },
  {
    id: 71,
    name: 'SAT-MON',
    value: 'SAT-MON',
    description: 'Protocol: 69'
  },
  {
    id: 72,
    name: 'VISA',
    value: 'VISA',
    description: 'Protocol: 70'
  },
  {
    id: 73,
    name: 'IPCU',
    value: 'IPCU',
    description: 'Protocol: 71'
  },
  {
    id: 74,
    name: 'CPNX',
    value: 'CPNX',
    description: 'Protocol: 72'
  },
  {
    id: 75,
    name: 'CPHB',
    value: 'CPHB',
    description: 'Protocol: 73'
  },
  {
    id: 76,
    name: 'WSN',
    value: 'WSN',
    description: 'Protocol: 74'
  },
  {
    id: 77,
    name: 'PVP',
    value: 'PVP',
    description: 'Protocol: 75'
  },
  {
    id: 78,
    name: 'BR-SAT-MON',
    value: 'BR-SAT-MON',
    description: 'Protocol: 76'
  },
  {
    id: 79,
    name: 'SUN-ND',
    value: 'SUN-ND',
    description: 'Protocol: 77'
  },
  {
    id: 80,
    name: 'WB-MON',
    value: 'WB-MON',
    description: 'Protocol: 78'
  },
  {
    id: 81,
    name: 'WB-EXPAK',
    value: 'WB-EXPAK',
    description: 'Protocol: 79'
  },
  {
    id: 82,
    name: 'ISO-IP',
    value: 'ISO-IP',
    description: 'Protocol: 80'
  },
  {
    id: 83,
    name: 'VMTP',
    value: 'VMTP',
    description: 'Protocol: 81'
  },
  {
    id: 84,
    name: 'SECURE-VMTP',
    value: 'SECURE-VMTP',
    description: 'Protocol: 82'
  },
  {
    id: 85,
    name: 'VINES',
    value: 'VINES',
    description: 'Protocol: 83'
  },
  {
    id: 86,
    name: 'IPTM',
    value: 'IPTM',
    description: 'Protocol: 84'
  },
  {
    id: 87,
    name: 'NSFNET-IGP',
    value: 'NSFNET-IGP',
    description: 'Protocol: 85'
  },
  {
    id: 88,
    name: 'DGP',
    value: 'DGP',
    description: 'Protocol: 86'
  },
  {
    id: 89,
    name: 'TCF',
    value: 'TCF',
    description: 'Protocol: 87'
  },
  {
    id: 90,
    name: 'EIGRP',
    value: 'EIGRP',
    description: 'Protocol: 88'
  },
  {
    id: 91,
    name: 'OSPF',
    value: 'OSPF',
    description: 'Protocol: 89'
  },
  {
    id: 92,
    name: 'Sprite-RPC',
    value: 'Sprite-RPC',
    description: 'Protocol: 90'
  },
  {
    id: 93,
    name: 'LARP',
    value: 'LARP',
    description: 'Protocol: 91'
  },
  {
    id: 94,
    name: 'MTP',
    value: 'MTP',
    description: 'Protocol: 92'
  },
  {
    id: 95,
    name: 'AX.25',
    value: 'AX.25',
    description: 'Protocol: 93'
  },
  {
    id: 96,
    name: 'OS',
    value: 'OS',
    description: 'Protocol: 94'
  },
  {
    id: 97,
    name: 'MICP',
    value: 'MICP',
    description: 'Protocol: 95'
  },
  {
    id: 98,
    name: 'SCC-SP',
    value: 'SCC-SP',
    description: 'Protocol: 96'
  },
  {
    id: 99,
    name: 'ETHERIP',
    value: 'ETHERIP',
    description: 'Protocol: 97'
  },
  {
    id: 100,
    name: 'ENCAP',
    value: 'ENCAP',
    description: 'Protocol: 98'
  },
  {
    id: 101,
    name: 'Any private encryption scheme',
    value: 'Any private encryption scheme',
    description: 'Protocol: 99'
  },
  {
    id: 102,
    name: 'GMTP',
    value: 'GMTP',
    description: 'Protocol: 100'
  },
  {
    id: 103,
    name: 'IFMP',
    value: 'IFMP',
    description: 'Protocol: 101'
  },
  {
    id: 104,
    name: 'PNNI',
    value: 'PNNI',
    description: 'Protocol: 102'
  },
  {
    id: 105,
    name: 'PIM',
    value: 'PIM',
    description: 'Protocol: 103'
  },
  {
    id: 106,
    name: 'ARIS',
    value: 'ARIS',
    description: 'Protocol: 104'
  },
  {
    id: 107,
    name: 'SCPS',
    value: 'SCPS',
    description: 'Protocol: 105'
  },
  {
    id: 108,
    name: 'QNX',
    value: 'QNX',
    description: 'Protocol: 106'
  },
  {
    id: 109,
    name: 'A/N',
    value: 'A/N',
    description: 'Protocol: 107'
  },
  {
    id: 110,
    name: 'IPComp',
    value: 'IPComp',
    description: 'Protocol: 108'
  },
  {
    id: 111,
    name: 'SNP',
    value: 'SNP',
    description: 'Protocol: 109'
  },
  {
    id: 112,
    name: 'Compaq-Peer',
    value: 'Compaq-Peer',
    description: 'Protocol: 110'
  },
  {
    id: 113,
    name: 'IPX-in-IP',
    value: 'IPX-in-IP',
    description: 'Protocol: 111'
  },
  {
    id: 114,
    name: 'VRRP',
    value: 'VRRP',
    description: 'Protocol: 112'
  },
  {
    id: 115,
    name: 'PGM',
    value: 'PGM',
    description: 'Protocol: 113'
  },
  {
    id: 116,
    name: 'Any 0-hop protocol',
    value: 'Any 0-hop protocol',
    description: 'Protocol: 114'
  },
  {
    id: 117,
    name: 'L2TP',
    value: 'L2TP',
    description: 'Protocol: 115'
  },
  {
    id: 118,
    name: 'DDX',
    value: 'DDX',
    description: 'Protocol: 116'
  },
  {
    id: 119,
    name: 'IATP',
    value: 'IATP',
    description: 'Protocol: 117'
  },
  {
    id: 120,
    name: 'STP',
    value: 'STP',
    description: 'Protocol: 118'
  },
  {
    id: 121,
    name: 'SRP',
    value: 'SRP',
    description: 'Protocol: 119'
  },
  {
    id: 122,
    name: 'UTI',
    value: 'UTI',
    description: 'Protocol: 120'
  },
  {
    id: 123,
    name: 'SMP',
    value: 'SMP',
    description: 'Protocol: 121'
  },
  {
    id: 124,
    name: 'SM',
    value: 'SM',
    description: 'Protocol: 122'
  },
  {
    id: 125,
    name: 'PTP',
    value: 'PTP',
    description: 'Protocol: 123'
  },
  {
    id: 126,
    name: 'IS-IS over IPv4',
    value: 'IS-IS over IPv4',
    description: 'Protocol: 124'
  },
  {
    id: 127,
    name: 'FIRE',
    value: 'FIRE',
    description: 'Protocol: 125'
  },
  {
    id: 128,
    name: 'CRTP',
    value: 'CRTP',
    description: 'Protocol: 126'
  },
  {
    id: 129,
    name: 'CRUDP',
    value: 'CRUDP',
    description: 'Protocol: 127'
  },
  {
    id: 130,
    name: 'SSCOPMCE',
    value: 'SSCOPMCE',
    description: 'Protocol: 128'
  },
  {
    id: 131,
    name: 'IPLT',
    value: 'IPLT',
    description: 'Protocol: 129'
  },
  {
    id: 132,
    name: 'SPS',
    value: 'SPS',
    description: 'Protocol: 130'
  },
  {
    id: 133,
    name: 'PIPE',
    value: 'PIPE',
    description: 'Protocol: 131'
  },
  {
    id: 134,
    name: 'SCTP',
    value: 'SCTP',
    description: 'Protocol: 132'
  },
  {
    id: 135,
    name: 'FC',
    value: 'FC',
    description: 'Protocol: 133'
  },
  {
    id: 136,
    name: 'RSVP-E2e-IGNORE',
    value: 'RSVP-E2e-IGNORE',
    description: 'Protocol: 134'
  },
  {
    id: 137,
    name: 'Mobility Header',
    value: 'Mobility Header',
    description: 'Protocol: 135'
  },
  {
    id: 138,
    name: 'UDPLite',
    value: 'UDPLite',
    description: 'Protocol: 136'
  },
  {
    id: 139,
    name: 'MPLS-in-IP',
    value: 'MPLS-in-IP',
    description: 'Protocol: 137'
  },
  {
    id: 140,
    name: 'manet',
    value: 'manet',
    description: 'Protocol: 138'
  },
  {
    id: 141,
    name: 'HIP',
    value: 'HIP',
    description: 'Protocol: 139'
  },
  {
    id: 142,
    name: 'Shim6',
    value: 'Shim6',
    description: 'Protocol: 140'
  },
  {
    id: 143,
    name: 'WESP',
    value: 'WESP',
    description: 'Protocol: 141'
  },
  {
    id: 144,
    name: 'ROHC',
    value: 'WESP',
    description: 'Protocol: 142'
  },
]

const listSourceOptions: DropdownListDataType[] = [
  {
    id: 1,
    name: 'Custom',
    value: 'Custom'
  },
  {
    id: 2,
    name: 'Any IPv4 address',
    value: 'Any IPv4 address'
  },
  {
    id: 3,
    name: 'Any IPv6 address',
    value: 'Any IPv6 address'
  }
]

const listMasksFlags: DropdownListDataType[] = [
  {
    id: 1,
    name: 'ACK',
    value: 'ACK'
  },
  {
    id: 2,
    name: 'SYN',
    value: 'SYN'
  },
  {
    id: 3,
    name: 'FIN',
    value: 'FIN'
  },
  {
    id: 4,
    name: 'URG',
    value: 'URG'
  },
  {
    id: 5,
    name: 'PSH',
    value: 'PSH'
  },
  {
    id: 6,
    name: 'ECE',
    value: 'ECE'
  },
  {
    id: 7,
    name: 'CWR',
    value: 'CWR'
  },
  {
    id: 8,
    name: 'RST',
    value: 'RST'
  }
]

interface MultiSelectAutocompleteProps {
  data: DropdownListDataType[];
  selectedValues: DropdownListDataType[];
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  error?: boolean;
  selectable?: boolean;
  handleClick: (val: DropdownListDataType) => void;
  handleRemove: (val: DropdownListDataType) => void;
}

const CusMultiSelectAutocomplete: React.FC<MultiSelectAutocompleteProps> = ({
                                                                              className,
                                                                              data,
                                                                              placeholder,
                                                                              selectedValues,
                                                                              handleClick,
                                                                              handleRemove,
                                                                              selectable
                                                                            }) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [filteredData, setFilteredData] =
    useState<DropdownListDataType[]>(data);
  const [showData, setShowData] = useState<boolean>(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);

    if (value) {
      const filtered = data.filter(
        suggestion =>
          String(suggestion.value)
            .toLowerCase()
            .includes(value.toLowerCase()) &&
          !selectedValues.includes(suggestion),
      );
      setFilteredData(filtered);
      setShowData(filtered.length > 0);
    } else {
      setShowData(false);
    }
  };

  return (
    <div className="regions">
      <div className={"input-region " + className}>
        <input
          type="text"
          value={inputValue}
          onChange={handleChange}
          placeholder={placeholder}
        />
        <img src={ArrowIcon} width={24} height={24}/>
      </div>

      <div className="dropdown-list">
        {selectedValues.map((value, index) => (
          <div key={index}>
            <span>
              <p style={{
                color: '#515151',
                fontFamily: 'Pretendard',
                fontSize: 13,
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '135%'
              }}>{value.value}</p>
              <p style={{
                color: '#8E8E8E',
                textAlign: 'center',
                fontFamily: 'Pretendard',
                fontSize: 12,
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '135%'
              }}>{value.description}</p>
            </span>
            <button
              onClick={() => {
                handleRemove(value);
                setFilteredData([...filteredData, {value: value.value}]);
              }}
            >
              <img src={CloseIcon} width={24} height={24}/>
            </button>
          </div>
        ))}

        {showData && inputValue && (
          <ul className="no-scrollbar">
            {filteredData.map(data => (
              <li
                key={data.id}
                onClick={() => {
                  handleClick(data);
                  if (!selectedValues.includes(data)) {
                    setInputValue('');
                    setFilteredData(
                      filteredData.filter(item => item.value !== data.value),
                    );
                    setShowData(false);
                  }
                }}
              >
                <span><CheckboxAtom/></span>
                <span>{data.value}</span>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

type CustomRadioProps = {
  checked?: boolean;
  subLabel?: string;
  styleCheckmark?: any;
  label?: string;
  description?: string;
  onClick: () => void;
}
const CustomRadio = (props: CustomRadioProps) => {
  const {checked, styleCheckmark, label, subLabel, description, onClick} = props;

  return (
    <div className="radio-custom-container">
      <div className="radio-atom" onClick={() => {
        onClick()
      }}>
        <input type="radio"
               checked={checked}
               onChange={() => {
                 onClick()
               }}
        />
        <span className="check-mark" style={styleCheckmark}></span>
      </div>
      <div className='label-wrapper'>
        <div className='radio-custom-label'>
          <span>{label}</span>
          {subLabel && <span style={{fontStyle: 'italic'}}>{subLabel}</span>}
        </div>
        <div className='radio-custom-description'>{description}</div>
      </div>
    </div>
  )
}

type RuleGroupCreationProps = {
  pageBackClick: () => void;
}
const RuleGroupCreation = (props: RuleGroupCreationProps) => {
  const {pageBackClick} = props;
  const [ruleGroupType, setRuleGroupType] = useState<'stateful' | 'stateless'>('stateful');
  const [ruleGroupDetails, setRuleGroupDetails] = useState<IRuleGroupDetails>({
    name: '',
    description: '',
    capacity: ''
  });
  const [ruleGroupFormat, setRuleGroupFormat] = useState<DropdownListDataType>(ruleGroupFormatData[1])
  const [ipSetVariables, setIpSetVariables] = useState<IIpsetVariable[]>([{name: 'HOME:NET', value: '10.32.203'}]);
  const [portVariables, setPortVariables] = useState<IIpsetVariable[]>([{name: 'ALLOW_PORTS', value: '22'}]);
  const [ipSetReferenceVariables, setIpSetReferenceVariables] = useState<IIpSetReferenceVariable[]>([{
    name: '',
    resourceId: '',
    cidr: ''
  }]);
  const [suricataRule, setSuricataRule] = useState<string>('ALLOW_PORTS');
  const [isCustomizedEncryption, setIsCustomizedEncryption] = useState<boolean>(false);
  const [searchRuleText, setSearchRuleText] = useState<string>('');
  const [trafficDirection, setTrafficDirection] = useState<'forward' | 'any'>('forward');
  const [action, setAction] = useState<'pass' | 'drop' | 'alert' | 'reject'>('pass');
  const [ruleNumber, setRuleNumber] = useState<number>(1);
  const [ruleRows, setRuleRow] = useState<RowType[]>(ruleRowsData);
  const [currentRulePage, setCurrentRulePage] = useState<number>(1);
  const [ruleEvaluationOrder, setRuleEvaluationOrder] = useState<'strictOrder' | 'actionOrder'>('strictOrder');
  const [domainName, setDomainName] = useState<string>('');
  const [cidrRanges, setCidrRanges] = useState<'default' | 'custom'>('default');
  const [domainListProtocol, setDomainListProtocol] = useState<'http' | 'https'>('http');
  const [domainListAction, setDomainListAction] = useState<'allow' | 'deny'>('allow');
  const [domainListRuleCIDRRanges, setDomainListRuleCIDRRanges] = useState<string>('10.0.0.1/16');
  const [tags, setTags] = useState<{ key: string, value?: string }[]>([{key: 'A', value: 'B'}]);
  const [statelessRulePriority, setStatelessRulePriority] = useState<string>('1');
  const [statelessRuleProtocols, setStatelessRuleProtocols] = useState<DropdownListDataType[]>([listProtocols[0]]);
  const [statelessSource, setStatelessSource] = useState<DropdownListDataType>(listSourceOptions[0]);
  const [statelessDestination, setStatelessDestination] = useState<DropdownListDataType>(listSourceOptions[0]);
  const [statelessListSource, setStatelessListSource] = useState<string>('');
  const [ruleAction, setRuleAction] = useState<'pass' | 'drop' | 'forward'>('pass');
  const [statelessMasks, setStatelessMasks] = useState<DropdownListDataType[]>([]);
  const [statelessFlag, setStatelessFlag] = useState<DropdownListDataType>(listMasksFlags[0]);
  const [ruleRowsDomainList, setRuleRowsDomainList] = useState<RowType[]>(ruleRowsDataDomainList);
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [selected, setSelected] = useState<string>('');
  const [tablePagination, setTablePagination] = useState({
    limit: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  const suricataRuleRef = useRef<HTMLTextAreaElement>(null);
  const searchRuleInputRef = useRef<HTMLInputElement>(null);

  const handleChangeRuleGroupDetails = (name: 'name' | 'description' | 'capacity', value: string) => {
    const newRuleGroupDetails: IRuleGroupDetails = {
      ...ruleGroupDetails,
      [name]: value
    };

    setRuleGroupDetails(newRuleGroupDetails);
  }

  const handleChangeRuleGroupType = (ruleGroupType: 'stateful' | 'stateless') => {
    setRuleGroupType(ruleGroupType);
  }

  const handleSelectRuleGroupFormat = (value: DropdownListDataType) => {
    setRuleGroupFormat(value);
  }

  const handleAddIpSetVariable = () => {
    const newVariables: IIpsetVariable[] = ipSetVariables.concat({name: '', value: ''});
    setIpSetVariables(newVariables);
  }

  const handleRemoveIpSetVariable = (index: number) => {
    const newIpSetVariables = ipSetVariables.slice();
    newIpSetVariables.splice(index, 1);

    setIpSetVariables(newIpSetVariables);
  }

  const handleOnChangeIpSetVariableName = (name: string, index: number) => {
    const newIpSetVariables = ipSetVariables.map((item, i) => {
      if (index === i) {
        return {
          ...item,
          name: name
        }
      } else {
        return item;
      }
    });
    setIpSetVariables(newIpSetVariables);
  }

  const handleOnChangeIpSetVariableValue = (value: string, index: number) => {
    const newIpSetVariables = ipSetVariables.map((item, i) => {
      if (index === i) {
        return {
          ...item,
          value: value
        }
      } else {
        return item;
      }
    });
    setIpSetVariables(newIpSetVariables);
  }

  const handleAddPortVariable = () => {
    const newVariables: IIpsetVariable[] = portVariables.concat({name: '', value: ''});
    setPortVariables(newVariables);
  }

  const handleRemovePortVariable = (index: number) => {
    const newPortVariables = portVariables.slice();
    newPortVariables.splice(index, 1);

    setPortVariables(newPortVariables);
  }

  const handleOnChangePortVariableName = (name: string, index: number) => {
    const newPortVariables = portVariables.map((item, i) => {
      if (index === i) {
        return {
          ...item,
          name: name
        }
      } else {
        return item;
      }
    });
    setPortVariables(newPortVariables);
  }

  const handleOnClickNext = () => {
    // console.log({
    //   ruleGroupDetail: {
    //     name: ruleGroupDetails.name,
    //     description: ruleGroupDetails.description,
    //     capacity: ruleGroupDetails.capacity
    //   },
    //   ruleGroupType: ruleGroupType,
    //   ruleGroupFormat: ruleGroupFormat,
    //   ipSetVariables: ipSetVariables,
    //   portVariables: portVariables
    // })
  }

  const handleOnChangePortVariableValue = (value: string, index: number) => {
    const newPortVariables = portVariables.map((item, i) => {
      if (index === i) {
        return {
          ...item,
          value: value
        }
      } else {
        return item;
      }
    });
    setPortVariables(newPortVariables);
  }

  const handleAddIpSetReferenceVariable = () => {
    const newVariables: IIpSetReferenceVariable[] = ipSetReferenceVariables.concat({
      name: '',
      resourceId: '',
      cidr: ''
    });
    setIpSetReferenceVariables(newVariables);
  }

  const handleRemoveIpSetReferenceVariable = (index: number) => {
    const newIpSetReferenceVariables = ipSetReferenceVariables.slice();
    newIpSetReferenceVariables.splice(index, 1);

    setIpSetReferenceVariables(newIpSetReferenceVariables);
  }

  const handleChangeIpSetReference = (key: string, value: string, index: number) => {
    const result = ipSetReferenceVariables.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          [key]: value
        }
      } else {
        return item;
      }
    });
    setIpSetReferenceVariables(result);
  }

  const handleChangeSuricataRule = (value: string) => {
    setSuricataRule(value);
  }

  const handleCopySuricataRule = () => {
    navigator.clipboard.writeText(suricataRuleRef.current?.value ?? '').then(() => {

    })
  }

  const handleOnChangeSearchRuleText = (value: string) => {
    setSearchRuleText(value);
  }

  const handleClearSearchRuleInput = () => {
    setSearchRuleText('');
    searchRuleInputRef.current?.focus();
  }

  const handleSelectTrafficDirection = (direction: 'forward' | 'any') => {
    setTrafficDirection(direction);
  }

  const handleSelectAction = (action: 'pass' | 'drop' | 'alert' | 'reject') => {
    setAction(action);
  }

  const handleSelectRule = (ruleNumber: number) => {
    setRuleNumber(ruleNumber);
  }

  const handleChangeRuleEvaluationOrder = (ruleOrder: 'strictOrder' | 'actionOrder') => {
    setRuleEvaluationOrder(ruleOrder);
  }

  const handleChangeTag = (field: 'key' | 'value', value: string, index: number) => {
    const newTags = tags.map((item, i) => {
      if (index === i) {
        return {
          ...item,
          [field]: value
        }
      } else {
        return item;
      }
    });
    setTags(newTags);
  }

  const handleRemoveTag = (index: number) => {
    const newTags = tags.slice();
    newTags.splice(index, 1);
    setTags(newTags);
  }

  const handleAddTag = () => {
    const newTags = tags.concat({key: 'A', value: 'B'});
    setTags(newTags);
  }

  const handleSelectProtocol = (val: DropdownListDataType) => {
    const newStatelessRuleProtocols = statelessRuleProtocols.concat(val);
    setStatelessRuleProtocols(newStatelessRuleProtocols);
  }

  const handleRemoveSelectedProtocol = (val: DropdownListDataType) => {
    const index = statelessRuleProtocols.indexOf(val);
    if (index > -1) {
      const newStatelessRuleProtocols = statelessRuleProtocols.slice();
      newStatelessRuleProtocols.splice(index, 1);
      setStatelessRuleProtocols(newStatelessRuleProtocols);
    }
  }

  const handleSelectRuleAction = (val: 'pass' | 'drop' | 'forward') => {
    setRuleAction(val);
  }

  const renderMutualComponent = () => {
    return (
      <>
        <div className='rule-group-container'>
          <div className="title-group">
            <p className='title'>Describe rule group</p>
            <p className='sub-title'>Name and describe your firewall policy so you can easily identify it and
              distinguish it from other resources.</p>
          </div>
          <div className='rule-group-details-container'>
            <div className='rule-group-detail-title'>
              Rule group details
            </div>
            <div className='rule-group-detail-content'>
              <div className='detail-content-row'>
                <div className='detail-content-column-left'>
                  Name
                  <img style={{marginLeft: 5}} src={RequiredIcon} width={4} height={4}/>
                </div>
                <div className='detail-content-column-right'>
                  <p>Enter a name for the rule group that’s unique within your stateful rule groups.</p>
                  <div className='full-width'>
                    <InputAtom value={ruleGroupDetails.name} noClear={true}
                               onChangeValue={(value: string) => handleChangeRuleGroupDetails('name', value)}/>
                  </div>
                  <p> The name must have 1-128 characters. Valid characters:a-z, A-Z, 0-9, and (hyphen), The name
                    can’t start or end with a hyphen, and it can’t contain two consecutive hyphens.</p>
                </div>
              </div>

              <div className='detail-content-row'>
                <div className='detail-content-column-left'>
                  Description
                </div>

                <div className='detail-content-column-right'>
                  <p>This description appears when you view the rule group’s details. It can help you quickly
                    identify what your rule group is used for.</p>
                  <div className='full-width'>
                    <InputAtom value={ruleGroupDetails.description} noClear={true}
                               onChangeValue={(value: string) => handleChangeRuleGroupDetails('description', value)}/>
                  </div>
                  <p>The description can have 0-256 characters.</p>
                </div>
              </div>

              <div className='detail-content-row'>
                <div className='detail-content-column-left'>
                  Capacity
                </div>

                <div className='detail-content-column-right'>
                  <p>The number of rules you expect to have in this rule group during its lifetime. You can’t change
                    capacity after rule group creation, so leave room to grow.</p>
                  <div className='full-width'>
                    <InputAtom value={ruleGroupDetails.capacity} noClear={true}
                               onChangeValue={(value: string) => handleChangeRuleGroupDetails('capacity', value)}/>
                  </div>
                  <p>The capacity must be greater than or equal to 1 and less than 30,000.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='rule-group-container'>
          <div className="title-group">
            <p className='title'>Choose rule group type</p>
            <p className='sub-title'>Network Firewall rule groups are either stateless or stateful. Stateless rule
              groups evaluate packets in isolation, while stateful rule groups evaluate them in the context of their
              traffic flow.</p>
          </div>

          <div className='rule-group-details-container'>
            <div className='rule-group-detail-title'>
              Rule group type
            </div>
            <div className='rule-group-type-content'>
              <div className='type-content-row'>
                <CustomRadio checked={ruleGroupType === 'stateful'}
                             label='Stateful rule group'
                             description='Use stateful rule groups to inspect packets within the context of the traffic flow.'
                             onClick={() => {
                               handleChangeRuleGroupType('stateful')
                             }}
                />
              </div>
              <div className='type-content-row'>
                <CustomRadio checked={ruleGroupType === 'stateless'}
                             label='Stateless rule group'
                             description='Use stateless rule groups to inspect packets on their own, without the context of the traffic flow.'
                             onClick={() => {
                               handleChangeRuleGroupType('stateless')
                             }}
                />
              </div>
              {
                ruleGroupType === 'stateful' &&
                  <>
                      <div className='rule-group-format-container'>
                          <div className='title-font-weight-500'>Rule group format</div>
                          <div style={{width: '50%'}}>
                              <CustomDropdown
                                  id='rule-group-format-dropdown'
                                  style={{height: 40}}
                                  data={ruleGroupFormatData}
                                  value={ruleGroupFormat}
                                  handleClick={(value: DropdownListDataType) => {
                                    handleSelectRuleGroupFormat(value)
                                  }}
                              />
                          </div>
                      </div>
                      <div className='rule-group-format-container'>
                          <div className='title-font-weight-500'>Rule evaluation order</div>
                          <div className='rule-group-type-content'>
                              <div className='type-content-row'>
                                  <CustomRadio checked={ruleEvaluationOrder === 'strictOrder'}
                                               label='Strict order - '
                                               subLabel='recommended'
                                               description='Rules are processed in the order that you define, starting with the first rule.'
                                               onClick={() => {
                                                 handleChangeRuleEvaluationOrder('strictOrder')
                                               }}
                                  />
                              </div>

                              <div className='type-content-row'>
                                  <CustomRadio checked={ruleEvaluationOrder === 'actionOrder'}
                                               label='Action order'
                                               description='Rules with a pass action are processed first, followed by drop, reject, and alert actions. This option was previously names Default order.'
                                               onClick={() => {
                                                 handleChangeRuleEvaluationOrder('actionOrder')
                                               }}
                                  />
                              </div>
                          </div>
                      </div>
                  </>
              }
            </div>
          </div>
        </div>
      </>
    )
  }

  const renderSuricataCompatibleRule = () => {

    return (
      <>
        {
          ruleGroupType === 'stateful' &&
            <>
                <div className='rule-group-container'>
                    <div className="title-group">
                        <p className='title'>Configure rules</p>
                        <p className='sub-title'>An AWS Network Firewall rule group is a reusable set of criteria
                            for inspecting
                            and handling network traffic.</p>
                    </div>

                    <div className='rule-group-details-container'>
                        <div className='rule-group-detail-title'>
                            Rule variables-optional
                            <div className='rule-group-detail-sub-title'>Define IP sets and ports in variables.
                                These variables
                                can be used within this rule group for standard stateful rules and Sumatra
                                compatible rule
                                strings.</div>
                        </div>
                        <div className='rule-group-detail-content' style={{gap: 16}}>
                            <div className='title'>IP set Variables</div>
                            <div style={{width: '100%'}}>
                                <div className='config-rule-row'>
                                    <div className='config-rule-column'>
                                        <div className='config-rule-label'>Variable name</div>
                                    </div>
                                    <div className='config-rule-column'>
                                        <div className='config-rule-label'>Values</div>
                                    </div>
                                    <div style={{marginLeft: 6, opacity: 0, pointerEvents: 'none'}}>
                                        <button className="button"
                                                style={{margin: 0}}
                                                onClick={() => {
                                                }}
                                        >
                                            Remove
                                        </button>
                                    </div>
                                </div>
                              {
                                ipSetVariables.map((item, index) => {
                                  return (
                                    <div key={index} className='config-rule-row'>
                                      <div className='config-rule-column'>
                                        <div className='config-rule-textarea'>
                                <textarea value={item.name} onChange={(event) => {
                                  handleOnChangeIpSetVariableName(event.target.value, index)
                                }}/>
                                        </div>
                                      </div>
                                      <div className='config-rule-column'>
                                        <div className='config-rule-textarea'>
                                <textarea value={item.value} onChange={(event) => {
                                  handleOnChangeIpSetVariableValue(event.target.value, index)
                                }}/>
                                        </div>
                                      </div>
                                      <div style={{marginLeft: 6}}>
                                        <button className="button"
                                                style={{margin: 0}}
                                                onClick={() => {
                                                  handleRemoveIpSetVariable(index)
                                                }}
                                        >
                                          Remove
                                        </button>
                                      </div>
                                    </div>
                                  )
                                })
                              }
                                <div className='config-rule-row'>
                                    <div className='config-rule-column'>
                                        <div className='config-rule-description'>Enter one variable name</div>
                                    </div>
                                    <div className='config-rule-column'>
                                        <div className='config-rule-description'>Enter one value per line for the
                                            website
                                        </div>
                                    </div>
                                    <div style={{marginLeft: 6, opacity: 0, pointerEvents: 'none'}}>
                                        <button className="button"
                                                style={{margin: 0}}
                                                onClick={() => {
                                                }}
                                        >
                                            Remove
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <button className="button next-button"
                                    style={{margin: 0}}
                                    onClick={() => {
                                      handleAddIpSetVariable()
                                    }}
                            >
                                Add another IP set variable
                            </button>
                            <div className='title'>Port variables</div>
                            <div style={{width: '100%'}}>
                                <div className='config-rule-row'>
                                    <div className='config-rule-column'>
                                        <div className='config-rule-label'>Variable name</div>
                                    </div>
                                    <div className='config-rule-column'>
                                        <div className='config-rule-label'>Values</div>
                                    </div>
                                    <div style={{marginLeft: 6, opacity: 0, pointerEvents: 'none'}}>
                                        <button className="button"
                                                style={{margin: 0}}
                                                onClick={() => {
                                                }}
                                        >
                                            Remove
                                        </button>
                                    </div>
                                </div>
                              {
                                portVariables.map((item, index) => {
                                  return (
                                    <div key={index} className='config-rule-row'>
                                      <div className='config-rule-column'>
                                        <div className='config-rule-textarea'>
                                <textarea value={item.name} onChange={(event) => {
                                  handleOnChangePortVariableName(event.target.value, index)
                                }}/>
                                        </div>
                                      </div>
                                      <div className='config-rule-column'>
                                        <div className='config-rule-textarea'>
                                <textarea value={item.value} onChange={(event) => {
                                  handleOnChangePortVariableValue(event.target.value, index)
                                }}/>
                                        </div>
                                      </div>
                                      <div style={{marginLeft: 6}}>
                                        <button className="button"
                                                style={{margin: 0}}
                                                onClick={() => {
                                                  handleRemovePortVariable(index)
                                                }}
                                        >
                                          Remove
                                        </button>
                                      </div>
                                    </div>
                                  )
                                })
                              }
                                <div className='config-rule-row'>
                                    <div className='config-rule-column'>
                                        <div className='config-rule-description'>Enter one variable name</div>
                                    </div>
                                    <div className='config-rule-column'>
                                        <div className='config-rule-description'>Enter one value per line for the
                                            website
                                        </div>
                                    </div>
                                    <div style={{marginLeft: 6, opacity: 0, pointerEvents: 'none'}}>
                                        <button className="button"
                                                style={{margin: 0}}
                                                onClick={() => {
                                                }}
                                        >
                                            Remove
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <button className="button next-button"
                                    style={{margin: 0}}
                                    onClick={() => {
                                      handleAddPortVariable()
                                    }}
                            >
                                Add another ports variables
                            </button>
                        </div>
                    </div>
                </div>

                <div className='rule-group-container'>
                    <div className="title-group">
                        <p className='title'>IP set references - optional</p>
                        <p className='sub-title'>An IP reference is a variable used in your rules that refers to a
                            resource
                            associated with a list of IPs or CDRs.</p>
                    </div>

                    <div className='rule-group-details-container'>
                        <div className='config-rule-row'>
                            <div className='rule-group-detail-content' style={{gap: 16}}>
                                <div className='config-rule-row'>
                                    <div className='config-rule-column'>
                                        <div className='config-rule-label'>
                                            Variable name
                                        </div>
                                    </div>
                                    <div className='config-rule-column'>
                                        <div className='config-rule-label'>
                                            Resource ID
                                        </div>
                                    </div>
                                    <div className='config-rule-column'>
                                        <div className='config-rule-label'>
                                            CIDRs
                                        </div>
                                    </div>
                                    <div style={{marginLeft: 6, opacity: 0, pointerEvents: 'none'}}>
                                        <button className="button"
                                                style={{margin: 0}}
                                                onClick={() => {
                                                }}
                                        >
                                            Remove
                                        </button>
                                    </div>
                                </div>
                              {
                                ipSetReferenceVariables.map((item, index) => {
                                  return (
                                    <div key={index} className='config-rule-row'>
                                      <div className='config-rule-column'>
                                        <InputAtom placeholder='IP-set reference' noClear={true} value={item.name}
                                                   onChangeValue={(value) => {
                                                     handleChangeIpSetReference('name', value, index)
                                                   }}/>
                                      </div>
                                      <div className='config-rule-column'>
                                        <CustomDropdown
                                          id={`resource-id-${index}`}
                                          style={{height: 48}}
                                          placeholder='Choose an IP set'
                                          data={[]} value={{value: ''}}
                                          handleClick={() => {
                                          }}/>
                                      </div>
                                      <div className='config-rule-column'>
                                        <InputAtom/>
                                      </div>
                                      <button className="button"
                                              style={{margin: 0}}
                                              onClick={() => {
                                                handleRemoveIpSetReferenceVariable(index)
                                              }}
                                      >
                                        Remove
                                      </button>
                                    </div>
                                  )
                                })
                              }
                                <button className="button next-button"
                                        style={{margin: 0}}
                                        onClick={() => {
                                          handleAddIpSetReferenceVariable()
                                        }}
                                >
                                    Add IP set reference
                                </button>
                                <div className='ip-set-description'>You can add as many as 4 more IP set
                                    references.
                                </div>
                            </div>
                            <div className='rule-group-detail-title' style={{borderTop: '1px solid #E0E0E0'}}>
                                CIDR usage
                                <div className='rule-group-detail-sub-title'>The total number of CIDRs used by the
                                    UP set
                                    references across the firewall.</div>
                            </div>
                            <div className='rule-group-detail-content'>
                                0/1,000,000
                            </div>
                        </div>
                    </div>
                </div>

                <div className='rule-group-container'>
                    <div className="title-group">
                        <p className='title'>Suricata compatible rule string</p>
                        <p className='sub-title'>Suricata is an open source network IPs that include a standard
                            rule- based
                            language to traffic inspection.</p>
                    </div>

                    <div className='rule-group-details-container'>
                        <div className='rule-group-detail-content'>
                            <div className='config-rule-row'>
                                <div className='config-rule-column'>
                                    <div className='config-rule-label'>Suricata compatible rule string</div>
                                </div>
                            </div>
                            <div className='config-rule-row' style={{justifyContent: 'flex-start'}}>
                                <div className='config-rule-column' style={{flex: 'none', width: '50%'}}>
                                    <div className='config-rule-textarea'>
                        <textarea value={suricataRule}
                                  ref={suricataRuleRef}
                                  onChange={(event) => {
                                    handleChangeSuricataRule(event.target.value)
                                  }}
                        />
                                    </div>
                                </div>
                                <div style={{marginLeft: 6}}>
                                    <button className="button"
                                            style={{margin: 0}}
                                            onClick={() => {
                                              handleCopySuricataRule()
                                            }}
                                    >
                                        Copy rules
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        }
      </>
    )
  }

  const handleSelectMask = (val: DropdownListDataType) => {
    const newStatelessMasks = statelessMasks.concat(val);
    setStatelessMasks(newStatelessMasks);
  }

  const handleRemoveSelectedMask = (val: DropdownListDataType) => {
    const index = statelessMasks.indexOf(val);
    if (index > -1) {
      const newStatelessMasks = statelessMasks.slice();
      newStatelessMasks.splice(index, 1);
      setStatelessMasks(newStatelessMasks);
    }
  }

  const handleAddRuleDomainList = () => {
    const protocol = statelessRuleProtocols.reduce((result, current) => {
      result += result ? `/${current.value}` : current.value;
      return result;
    }, "")

    const data = {
      priority: statelessRulePriority,
      protocol: protocol,
      source: statelessSource?.value,
      destination: statelessDestination?.value,
      sourcePort: 'ANY',
      destinationPort: 'ANY',
    }

    const arr = [...ruleRowsDomainList];
    arr.push(data);
    setRuleRowsDomainList(arr);
  }



  const renderDomainList = () => {
    return (
      <>
        {
          ruleGroupType === 'stateful' &&
            <>
                <div className='rule-group-container'>
                    <div className="title-group">
                        <p className='title'>Configure rules</p>
                        <p className='sub-title'>An AWS Network Firewall rule group is a reusable set of criteria
                            for inspecting
                            and handling network traffic.</p>
                    </div>

                    <div className='rule-group-details-container'>
                        <>
                            <div className='rule-group-detail-title'>
                                Domain list rule
                                <div className='rule-group-detail-sub-title'>Allow or deny traffic based on the domain
                                    name
                                    list.</div>
                            </div>
                            <div className='rule-group-detail-title' style={{
                              borderBottom: 'none',
                              padding: '8px 8px 0px 16px',
                              display: 'flex',
                              flexDirection: 'column',
                              gap: 4
                            }}>
                                Domain names
                                <div className='rule-group-detail-sub-title'>Use the domain names you want to inspect
                                    and
                                    either allow or deny.</div>
                            </div>
                            <div className='rule-group-detail-content' style={{gap: 16, padding: '8px 16px'}}>
                            <textarea
                                placeholder='Enter domain name spruce (ie. Example.com)'
                                value={domainName}
                                onChange={(event) => {
                                  setDomainName(event.target.value)
                                }}
                            />
                            </div>
                            <div className='rule-group-detail-title' style={{
                              borderBottom: 'none',
                              padding: '0px 8px 8px 16px',
                              display: 'flex',
                              flexDirection: 'column',
                              gap: 4
                            }}>
                                <div className='rule-group-detail-sub-title'>Enter one domain name per line
                                </div>
                            </div>
                        </>
                        <>
                            <div className='rule-group-detail-title' style={{
                              borderBottom: 'none',
                              padding: '8px 8px 0px 16px',
                              display: 'flex',
                              flexDirection: 'column',
                              gap: 4
                            }}>
                                CIDR ranges
                                <div className='rule-group-detail-sub-title'>The source traffic CIDE names to
                                    inspect.</div>
                            </div>
                            <div className='rule-group-type-content'>
                                <div className='type-content-row'>
                                    <CustomRadio checked={cidrRanges === 'default'}
                                                 label='Default'
                                                 description='Use the CIDR range of the VPC where Network Firewall is deployed.'
                                                 onClick={() => {
                                                   setCidrRanges('default')
                                                 }}
                                    />
                                </div>
                                <div className='type-content-row'>
                                    <CustomRadio checked={cidrRanges === 'custom'}
                                                 label='Custom'
                                                 description='Set your own list of CIDR ranges.'
                                                 onClick={() => {
                                                   setCidrRanges('custom')
                                                 }}
                                    />
                                </div>
                            </div>
                        </>
                      {
                        cidrRanges === 'default' &&
                          <>
                              <>
                                  <div className='rule-group-detail-title' style={{
                                    borderBottom: 'none',
                                    padding: '8px 8px 0px 16px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 4
                                  }}>
                                      Protocols
                                      <div className='rule-group-detail-sub-title'>The protocols to inspect.</div>
                                  </div>
                                  <div className='rule-group-detail-content' style={{gap: 16}}>
                                      <div style={{display: 'flex', gap: 8, alignItems: 'center'}}>
                                          <CheckboxAtom checked={domainListProtocol === 'http'} onchange={() => {
                                            setDomainListProtocol('http')
                                          }}/>
                                          <p className='text-content'>HTTP</p>
                                      </div>
                                      <div style={{display: 'flex', gap: 8, alignItems: 'center'}}>
                                          <CheckboxAtom checked={domainListProtocol === 'https'} onchange={() => {
                                            setDomainListProtocol('https')
                                          }}/>
                                          <p className='text-content'>HTTPS</p>
                                      </div>
                                  </div>
                              </>
                              <>
                                  <div className='rule-group-detail-title' style={{
                                    borderBottom: 'none',
                                    padding: '8px 8px 0px 16px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 4
                                  }}>
                                      Action
                                      <div className='rule-group-detail-sub-title'>Action to take when request matches
                                          the domain names in the group.</div>
                                  </div>
                                  <div className='rule-group-detail-content' style={{gap: 16}}>
                                      <div className='rule-group-type-content' style={{padding: 0}}>
                                          <div className='type-content-row'>
                                              <CustomRadio checked={domainListAction === 'allow'}
                                                           label='Allow'
                                                           onClick={() => {
                                                             setDomainListAction('allow')
                                                           }}
                                              />
                                          </div>
                                      </div>
                                      <div className='rule-group-type-content' style={{padding: 0}}>
                                          <div className='type-content-row'>
                                              <CustomRadio checked={domainListAction === 'deny'}
                                                           label='Deny'
                                                           onClick={() => {
                                                             setDomainListAction('deny')
                                                           }}
                                              />
                                          </div>
                                      </div>
                                  </div>
                              </>
                          </>
                      }
                      {
                        cidrRanges === 'custom' &&
                          <>
                              <div className='rule-group-detail-title' style={{
                                borderBottom: 'none',
                                padding: '8px 8px 0px 16px',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 4
                              }}>
                                  CIDR ranges
                                  <div className='rule-group-detail-sub-title'>List the CIDR ranges of the source IPs
                                      you want to take action on.</div>
                              </div>
                              <div className='rule-group-detail-content' style={{gap: 16, padding: '8px 16px'}}>
                            <textarea
                                value={domainListRuleCIDRRanges}
                                onChange={(event) => {
                                  setDomainListRuleCIDRRanges(event.target.value)
                                }}
                            />
                              </div>
                              <div className='rule-group-detail-title' style={{
                                borderBottom: 'none',
                                padding: '0px 8px 8px 16px',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 4
                              }}>
                                  <div className='rule-group-detail-sub-title'>Enter one CIDR range per line.</div>
                              </div>
                          </>
                      }
                    </div>

                    <div className='rule-group-details-container' style={{flex: 1, marginTop: 12}}>
                        <div className='rule-group-detail-title'>
                            Stateless rule
                            <div className='rule-group-detail-sub-title'>Add the stateless rule that you need in
                                your rule group. Each rule that you add is listed in the Rules table
                                below.</div>
                        </div>
                        <>
                            <div className='rule-group-detail-title' style={{
                              borderBottom: 'none',
                              padding: '8px 8px 0px 16px',
                              display: 'flex',
                              flexDirection: 'column',
                              gap: 4
                            }}>
                                Priority
                                <div className='rule-group-detail-sub-title'>Rule with lower priority are evaluated
                                    first.
                                    Each rule within a rule group must have a unique priority setting.</div>
                            </div>
                            <div className='rule-group-detail-content' style={{gap: 16, padding: '8px 16px'}}>
                                <div style={{width: '50%'}}>
                                    <InputAtom noClear={true} value={statelessRulePriority} onChangeValue={(value) => {
                                      setStatelessRulePriority(value)
                                    }}/>
                                </div>
                            </div>
                        </>
                        <>
                            <div className='rule-group-detail-title' style={{
                              borderBottom: 'none',
                              padding: '8px 8px 0px 16px',
                              display: 'flex',
                              flexDirection: 'column',
                              gap: 4
                            }}>
                                Protocol
                                <div className='rule-group-detail-sub-title'>Transport protocols to inspect for</div>
                            </div>
                            <div className='rule-group-detail-content' style={{gap: 16, padding: '8px 16px'}}>
                                <div style={{width: '50%'}}>
                                    <CusMultiSelectAutocomplete
                                        placeholder='Choose options'
                                        data={listProtocols}
                                        selectedValues={statelessRuleProtocols}
                                        handleClick={(val: DropdownListDataType) => {
                                          handleSelectProtocol(val)
                                        }}

                                        handleRemove={(val: DropdownListDataType) => {
                                          handleRemoveSelectedProtocol(val)
                                        }}/>
                                </div>
                            </div>
                            <div className='rule-group-detail-content' style={{gap: 8, padding: '8px 16px'}}>
                                <div className='config-rule-row'>
                                    <div className='config-rule-column'>
                                        <div className='rule-group-detail-title'
                                             style={{padding: 0, borderBottom: 'none'}}>
                                            Source
                                            <div className='rule-group-detail-sub-title'>The source IP addresses and
                                                address ranges to inspect for. You can provide single addresses and CIDR
                                                blocks.</div>
                                        </div>
                                    </div>
                                    <div className='config-rule-column'>
                                        <div className='rule-group-detail-title'
                                             style={{padding: 0, borderBottom: 'none'}}>
                                            Source port range
                                            <div className='rule-group-detail-sub-title'>The source ports and port
                                                ranges to inspect for. This only applies to TCP and UDP protocols.</div>
                                        </div>
                                    </div>
                                </div>

                                <div className='config-rule-row'>
                                    <div className='config-rule-column'>
                                        <CustomDropdown id='stateless-rule-source'
                                                        data={listSourceOptions}
                                                        value={statelessSource}
                                                        handleClick={(val) => {
                                                          setStatelessSource(val)
                                                        }}
                                                        style={{height: 40}}
                                        />
                                    </div>
                                    <div className='config-rule-column'>
                                        <CustomDropdown id='stateless-rule-source-port-range'
                                                        data={[]} value={{value: 'Any port'}}
                                                        handleClick={() => {
                                                        }}
                                                        disabled={true}
                                                        style={{height: 40}}
                                        />
                                    </div>
                                </div>
                                <div className='config-rule-row'>
                                    <div className='config-rule-column'>
                                        <div className='config-rule-textarea'>
                              <textarea value={statelessListSource} onChange={(event) => {
                                setStatelessListSource(event.target.value)
                              }}/>
                                        </div>
                                    </div>
                                    <div className='config-rule-column'>
                                        <div className='config-rule-textarea'>
                              <textarea value='' onChange={(event) => {
                                // handleOnChangePortVariableValue(event.target.value, index)
                              }}/>
                                        </div>
                                    </div>
                                </div>
                                <div className='config-rule-row'>
                                    <div className='config-rule-column'>
                                        <div className='config-rule-description'>Enter one value per line and use either
                                            IPv4 or IPv6 values but not both together.
                                        </div>
                                    </div>
                                    <div className='config-rule-column'>
                                        <div className='config-rule-description'>Allowed port ranges are 0-66365. Enter
                                            one port range per line.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='rule-group-detail-content' style={{gap: 8, padding: '8px 16px'}}>
                                <div className='config-rule-row'>
                                    <div className='config-rule-column'>
                                        <div className='rule-group-detail-title'
                                             style={{padding: 0, borderBottom: 'none'}}>
                                            Destination
                                            <div className='rule-group-detail-sub-title'>The destination IP addresses
                                                and address ranges to inspect for. You can provide single addresses and
                                                CIDR blocks.</div>
                                        </div>
                                    </div>
                                    <div className='config-rule-column'>
                                        <div className='rule-group-detail-title'
                                             style={{padding: 0, borderBottom: 'none'}}>
                                            Destination port range
                                            <div className='rule-group-detail-sub-title'>The description ports and port
                                                ranges to inspect for. This only applies to TCP and UDP protocols</div>
                                        </div>
                                    </div>
                                </div>

                                <div className='config-rule-row'>
                                    <div className='config-rule-column'>
                                        <CustomDropdown id='stateless-rule-destination'
                                                        data={listSourceOptions}
                                                        value={statelessDestination}
                                                        handleClick={(val) => {
                                                          setStatelessDestination(val)
                                                        }}
                                                        style={{height: 40}}
                                        />
                                    </div>
                                    <div className='config-rule-column'>
                                        <CustomDropdown id='stateless-rule-destination-port-range'
                                                        data={[]} value={{value: ''}}
                                                        handleClick={() => {
                                                        }}
                                                        disabled={true}
                                                        style={{height: 40}}
                                        />
                                    </div>
                                </div>
                                <div className='config-rule-row'>
                                    <div className='config-rule-column'>
                                        <div className='config-rule-textarea'>
                              <textarea value='' onChange={(event) => {
                                // handleOnChangePortVariableName(event.target.value, index)
                              }}/>
                                        </div>
                                    </div>
                                    <div className='config-rule-column'>
                                        <div className='config-rule-textarea'>
                              <textarea value='' onChange={(event) => {
                                // handleOnChangePortVariableValue(event.target.value, index)
                              }}/>
                                        </div>
                                    </div>
                                </div>
                                <div className='config-rule-row'>
                                    <div className='config-rule-column'>
                                        <div className='config-rule-description'>Enter one value per line and use either
                                            Enter one value per line and use either IPv4 or IPv6 values but not both
                                            together.
                                        </div>
                                    </div>
                                    <div className='config-rule-column'>
                                        <div className='config-rule-description'>Allowed port ranges are 0-66365. Enter
                                            one port range per line.
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div style={{display: 'flex', flexDirection: 'column', gap: 10, padding: '10px 16px'}}>
                                <div className='rule-group-detail-title'
                                     style={{borderBottom: 'none', padding: 0}}>
                                    Rule action
                                    <div className='rule-group-detail-sub-title'>Choose how you want the firewall to
                                        handle packets that match the rule criteria.</div>
                                </div>
                                <div className='rule-group-type-content' style={{gap: 8, padding: 0}}>
                                    <div className='type-content-row'>
                                        <CustomRadio checked={ruleAction === 'pass'}
                                                     label='Pass'
                                                     description='Discontinue all inspection of the packet and permit it to go to intended destination.'
                                                     onClick={() => {
                                                       handleSelectRuleAction('pass')
                                                     }}
                                        />
                                    </div>
                                    <div className='type-content-row'>
                                        <CustomRadio checked={ruleAction === 'drop'}
                                                     label='Drop'
                                                     description='Discontinue all inspection of the packet and block it from going to intended destination.'
                                                     onClick={() => {
                                                       handleSelectRuleAction('drop')
                                                     }}
                                        />
                                    </div>
                                    <div className='type-content-row'>
                                        <CustomRadio checked={ruleAction === 'forward'}
                                                     label='Forward to stateful rule groups'
                                                     description='Discontinue stateless inspection of packet and forward it to the stateful rule engine for inspection.'
                                                     onClick={() => {
                                                       handleSelectRuleAction('forward')
                                                     }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    </div>
                </div>

                <div className='rule-group-container'>
                    <div className="title-group">
                        <p className='title'>TCP flags-optional</p>
                        <p className='sub-title'>The TCP flags and masks to inspect for. If not specified, this matches
                            with any settings. This setting is only used for TCP protocol.</p>
                    </div>
                    <div className='rule-group-details-container' style={{flex: 1, marginTop: 12}}>
                        <>
                            <div className='rule-group-detail-title' style={{
                              borderBottom: 'none',
                              padding: '8px 8px 0px 16px',
                              display: 'flex',
                              flexDirection: 'column',
                              gap: 4
                            }}>
                                Mask-optional
                                <div className='rule-group-detail-sub-title'>The set of flags to consider in the
                                    inspection. To inspect all available flags, don’t select an option.</div>
                            </div>
                            <div className='rule-group-detail-content' style={{gap: 16, padding: '8px 16px'}}>
                                <div style={{width: '50%'}}>
                                    <CusMultiSelectAutocomplete
                                        placeholder='Choose options'
                                        data={listMasksFlags}
                                        selectedValues={statelessMasks}
                                        handleClick={(val: DropdownListDataType) => {
                                          handleSelectMask(val)
                                        }}

                                        handleRemove={(val: DropdownListDataType) => {
                                          handleRemoveSelectedMask(val)
                                        }}
                                    />
                                </div>
                            </div>
                        </>
                        <>
                            <div className='rule-group-detail-title' style={{
                              borderBottom: 'none',
                              padding: '8px 8px 0px 16px',
                              display: 'flex',
                              flexDirection: 'column',
                              gap: 4
                            }}>
                                Flags-optional
                                <div className='rule-group-detail-sub-title'>Used in conjunction with the Masks setting
                                    to define the flags that must be set and flags that must not be set in order for the
                                    packet to match. This setting can only specify values that are also specified in the
                                    Masks setting.</div>
                            </div>
                            <div className='rule-group-detail-content' style={{gap: 16, padding: '8px 16px'}}>
                                <div style={{width: '50%'}}>
                                    <CustomDropdown id='stateless-flag-dropdown'
                                                    data={listMasksFlags}
                                                    value={statelessFlag}
                                                    handleClick={(val) => {
                                                      setStatelessFlag(val)
                                                    }}
                                                    style={{height: 40}}
                                    />
                                </div>
                            </div>
                        </>
                        <div className='rule-group-detail-content' style={{padding: '8px 16px'}}>
                            <button className="button next-button"
                                    style={{margin: 0}}
                                    onClick={() => {
                                      handleAddRuleDomainList()
                                    }}
                            >
                                Add rule
                            </button>
                        </div>
                    </div>
                </div>

                <div className='rule-group-container'>
                    <div className="title-group">
                        <p className='title'>Rules</p>
                    </div>
                    <div className='rule-group-details-container'>
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: "center"}}>
                            <div className='rule-group-detail-title' style={{borderBottom: 0}}>Rules</div>
                            <div className="button-group">
                                <button className="button"
                                        style={{margin: 0}}
                                        onClick={() => {
                                        }}
                                >
                                    Analyze
                                </button>

                                <button className="button disable-btn"
                                        style={{margin: 0}}
                                        onClick={() => {
                                        }}
                                        disabled
                                >
                                    Delete
                                </button>
                            </div>
                        </div>
                        <div className='detail-table'
                             style={{
                               margin: 0,
                               border: 'none',
                               borderTop: '1px solid #E0E0E0'
                             }}
                        >
                            <Table
                                columns={[
                                  {label: 'Priority', field: 'priority', sort: true},
                                  {label: 'Protocol', field: 'protocol', sort: true},
                                  {label: 'Source', field: 'source', sort: true},
                                  {label: 'Destination', field: 'destination', sort: true},
                                  {label: 'Source port range', field: 'sourcePort', sort: true},
                                  {label: 'Destination port range', field: 'destinationPort', sort: true},
                                ]}
                                rows={ruleRowsDomainList}
                                reportCheckedList={(list: string[]) => {
                                  setCheckedList(list);
                                }}
                                reportSelected={(id: string) => {
                                  setSelected(id);
                                }}
                                sortOption={{
                                  target: tablePagination.target,
                                  direction: tablePagination.direction,
                                  onChangeSort: (target: string, dir: OrderDirection) => {
                                    setTablePagination(prev => ({
                                      ...prev,
                                      target: target,
                                      direction: dir,
                                    }));
                                  },
                                }}
                            />

                            <div className="pagination-wrapper flex a-center">
                                <p className="flex a-center text-paging">
                                    Total <span>{ruleRowsDomainList.length}</span>
                                </p>

                                <TablePagination
                                    currentPage={currentRulePage}
                                    updateCurrentPage={(pageNumber) => {
                                      setCurrentRulePage(pageNumber)
                                    }}
                                    totalPage={ruleRowsDomainList.length / 5 + 1} // item per page is 5
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        }
      </>
    )
  }

  const renderStatefulRule = () => {
    return (
      <>
        {
          ruleGroupType === 'stateful' &&
            <>
                <div className='rule-group-container'>
                    <div className="title-group">
                        <p className='title'>Configure rules</p>
                        <p className='sub-title'>An AWS Network Firewall rule group is a reusable set of criteria
                            for inspecting
                            and handling network traffic.</p>
                    </div>

                    <div className='rule-group-details-container'>
                        <div className='rule-group-detail-title'>
                            Rule variables-optional
                            <div className='rule-group-detail-sub-title'>Define IP sets and ports in variables.
                                These variables
                                can be used within this rule group for standard stateful rules and Sumatra
                                compatible rule
                                strings.</div>
                        </div>
                        <div className='rule-group-detail-content' style={{gap: 16}}>
                            <div className='title'>IP set Variables</div>
                            <div style={{width: '100%'}}>
                                <div className='config-rule-row'>
                                    <div className='config-rule-column'>
                                        <div className='config-rule-label'>Variable name</div>
                                    </div>
                                    <div className='config-rule-column'>
                                        <div className='config-rule-label'>Values</div>
                                    </div>
                                    <div style={{marginLeft: 6, opacity: 0, pointerEvents: 'none'}}>
                                        <button className="button"
                                                style={{margin: 0}}
                                                onClick={() => {
                                                }}
                                        >
                                            Remove
                                        </button>
                                    </div>
                                </div>
                              {
                                ipSetVariables.map((item, index) => {
                                  return (
                                    <div key={index} className='config-rule-row'>
                                      <div className='config-rule-column'>
                                        <div className='config-rule-textarea'>
                              <textarea value={item.name} onChange={(event) => {
                                handleOnChangeIpSetVariableName(event.target.value, index)
                              }}/>
                                        </div>
                                      </div>
                                      <div className='config-rule-column'>
                                        <div className='config-rule-textarea'>
                              <textarea value={item.value} onChange={(event) => {
                                handleOnChangeIpSetVariableValue(event.target.value, index)
                              }}/>
                                        </div>
                                      </div>
                                      <div style={{marginLeft: 6}}>
                                        <button className="button"
                                                style={{margin: 0}}
                                                onClick={() => {
                                                  handleRemoveIpSetVariable(index)
                                                }}
                                        >
                                          Remove
                                        </button>
                                      </div>
                                    </div>
                                  )
                                })
                              }
                                <div className='config-rule-row'>
                                    <div className='config-rule-column'>
                                        <div className='config-rule-description'>Enter one variable name</div>
                                    </div>
                                    <div className='config-rule-column'>
                                        <div className='config-rule-description'>Enter one value per line for the
                                            website
                                        </div>
                                    </div>
                                    <div style={{marginLeft: 6, opacity: 0, pointerEvents: 'none'}}>
                                        <button className="button"
                                                style={{margin: 0}}
                                                onClick={() => {
                                                }}
                                        >
                                            Remove
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <button className="button next-button"
                                    style={{margin: 0}}
                                    onClick={() => {
                                      handleAddIpSetVariable()
                                    }}
                            >
                                Add another IP set variable
                            </button>
                            <div className='title'>Port variables</div>
                            <div style={{width: '100%'}}>
                                <div className='config-rule-row'>
                                    <div className='config-rule-column'>
                                        <div className='config-rule-label'>Variable name</div>
                                    </div>
                                    <div className='config-rule-column'>
                                        <div className='config-rule-label'>Values</div>
                                    </div>
                                    <div style={{marginLeft: 6, opacity: 0, pointerEvents: 'none'}}>
                                        <button className="button"
                                                style={{margin: 0}}
                                                onClick={() => {
                                                }}
                                        >
                                            Remove
                                        </button>
                                    </div>
                                </div>
                              {
                                portVariables.map((item, index) => {
                                  return (
                                    <div key={index} className='config-rule-row'>
                                      <div className='config-rule-column'>
                                        <div className='config-rule-textarea'>
                              <textarea value={item.name} onChange={(event) => {
                                handleOnChangePortVariableName(event.target.value, index)
                              }}/>
                                        </div>
                                      </div>
                                      <div className='config-rule-column'>
                                        <div className='config-rule-textarea'>
                              <textarea value={item.value} onChange={(event) => {
                                handleOnChangePortVariableValue(event.target.value, index)
                              }}/>
                                        </div>
                                      </div>
                                      <div style={{marginLeft: 6}}>
                                        <button className="button"
                                                style={{margin: 0}}
                                                onClick={() => {
                                                  handleRemovePortVariable(index)
                                                }}
                                        >
                                          Remove
                                        </button>
                                      </div>
                                    </div>
                                  )
                                })
                              }
                                <div className='config-rule-row'>
                                    <div className='config-rule-column'>
                                        <div className='config-rule-description'>Enter one variable name</div>
                                    </div>
                                    <div className='config-rule-column'>
                                        <div className='config-rule-description'>Enter one value per line for the
                                            website
                                        </div>
                                    </div>
                                    <div style={{marginLeft: 6, opacity: 0, pointerEvents: 'none'}}>
                                        <button className="button"
                                                style={{margin: 0}}
                                                onClick={() => {
                                                }}
                                        >
                                            Remove
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <button className="button next-button"
                                    style={{margin: 0}}
                                    onClick={() => {
                                      handleAddPortVariable()
                                    }}
                            >
                                Add another ports variables
                            </button>
                        </div>
                    </div>
                </div>

                <div className='rule-group-container'>
                    <div className="title-group">
                        <p className='title'>IP set references - optional</p>
                        <p className='sub-title'>An IP reference is a variable used in your rules that refers to a
                            resource
                            associated with a list of IPs or CDRs.</p>
                    </div>

                    <div className='rule-group-details-container'>
                        <div className='config-rule-row'>
                            <div className='rule-group-detail-content' style={{gap: 16}}>
                                <div className='config-rule-row'>
                                    <div className='config-rule-column'>
                                        <div className='config-rule-label'>
                                            Variable name
                                        </div>
                                    </div>
                                    <div className='config-rule-column'>
                                        <div className='config-rule-label'>
                                            Resource ID
                                        </div>
                                    </div>
                                    <div className='config-rule-column'>
                                        <div className='config-rule-label'>
                                            CIDRs
                                        </div>
                                    </div>
                                    <div style={{marginLeft: 6, opacity: 0, pointerEvents: 'none'}}>
                                        <button className="button"
                                                style={{margin: 0}}
                                                onClick={() => {
                                                }}
                                        >
                                            Remove
                                        </button>
                                    </div>
                                </div>
                              {
                                ipSetReferenceVariables.map((item, index) => {
                                  return (
                                    <div key={index} className='config-rule-row'>
                                      <div className='config-rule-column'>
                                        <InputAtom noClear={true} value={item.name} onChangeValue={(value) => {
                                          handleChangeIpSetReference('name', value, index)
                                        }}/>
                                      </div>
                                      <div className='config-rule-column'>
                                        <CustomDropdown
                                          id={'resource-id'}
                                          style={{height: 48}}
                                          data={[]}
                                          value={{value: ''}}
                                          handleClick={() => {
                                          }}/>
                                      </div>
                                      <div className='config-rule-column'>
                                        <InputAtom/>
                                      </div>
                                      <button className="button"
                                              style={{margin: 0}}
                                              onClick={() => {
                                                handleRemoveIpSetReferenceVariable(index)
                                              }}
                                      >
                                        Remove
                                      </button>
                                    </div>
                                  )
                                })
                              }
                                <button className="button next-button"
                                        style={{margin: 0}}
                                        onClick={() => {
                                          handleAddIpSetReferenceVariable()
                                        }}
                                >
                                    Add IP set reference
                                </button>
                                <div className='ip-set-description'>You can add as many as 4 more IP set
                                    references.
                                </div>
                            </div>
                            <div className='rule-group-detail-title' style={{borderTop: '1px solid #E0E0E0'}}>
                                CIDR usage
                                <div className='rule-group-detail-sub-title'>The total number of CIDRs used by the
                                    UP set
                                    references across the firewall.</div>
                            </div>
                            <div className='rule-group-detail-content'>
                                0/1,000,000
                            </div>
                        </div>
                    </div>
                </div>

                <div className='rule-group-container'>
                    <div className="title-group">
                        <p className='title'>Standard stateful rules</p>
                        <p className='sub-title'>Add the stateful rules that you need to your rule group. Each rule
                            that
                            you add is listed in the Rules table below.</p>
                    </div>

                    <div className='rule-group-details-container'>
                        <div style={{display: 'flex', gap: 10, padding: '10px 16px'}}>
                            <div className='rule-group-details-container' style={{flex: 1, border: 'none'}}>
                                <div className='rule-group-detail-title'
                                     style={{borderBottom: 'none', padding: "0px 0px 4px 0px"}}>
                                    Protocol
                                    <div className='rule-group-detail-sub-title'>Transport protocols to inspect
                                        for.</div>
                                </div>
                                <div className='rule-group-detail-content' style={{padding: 0}}>
                                    <CustomDropdown
                                        id='protocol-stateful-rules'
                                        placeholder='IP-set reference'
                                        style={{height: 40}}
                                        data={[]} value={{value: ''}}
                                        handleClick={() => {
                                        }}/>
                                </div>
                            </div>
                            <div className='rule-group-details-container'
                                 style={{flex: 1, border: 'none', opacity: 0, pointerEvents: 'none'}}>
                                <div className='rule-group-detail-title'
                                     style={{borderBottom: 'none', padding: "0px 0px 4px 0px"}}>
                                    Protocol
                                    <div className='rule-group-detail-sub-title'>Transport protocols to inspect
                                        for.</div>
                                </div>
                                <div className='rule-group-detail-content' style={{padding: 0}}>
                                    <CustomDropdown
                                        id='protocol-stateful-rules-dummy'
                                        style={{height: 40}}
                                        data={[]}
                                        value={{value: ''}}
                                        handleClick={() => {
                                        }}/>
                                </div>
                            </div>
                        </div>

                        <div style={{display: 'flex', gap: 10, padding: '10px 16px'}}>
                            <div className='rule-group-details-container' style={{flex: 1, border: 'none'}}>
                                <div style={{display: 'flex', flexDirection: 'column', gap: 6}}>
                                    <div className='rule-group-detail-title'
                                         style={{borderBottom: 'none', padding: 0}}>
                                        Source
                                        <div className='rule-group-detail-sub-title'>The source IP addresses and
                                            address ranges to
                                            inspect for.</div>
                                    </div>
                                    <div className='rule-group-detail-content' style={{padding: 0}}>
                                        <CustomDropdown
                                            id='source-stateful-rules'
                                            style={{height: 40}}
                                            data={[]}
                                            value={{value: ''}}
                                            handleClick={() => {
                                            }}/>
                                    </div>
                                    <div className='rule-group-detail-content' style={{padding: 0}}>
                              <textarea value='' onChange={(event) => {
                              }}/>
                                    </div>
                                    <div className='rule-group-detail-title'
                                         style={{borderBottom: 'none', padding: 0}}>
                                        <div className='rule-group-detail-sub-title'>You can provide single
                                            addresses and CIDR
                                            blocks
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='rule-group-details-container' style={{flex: 1, border: 'none'}}>
                                <div style={{display: 'flex', flexDirection: 'column', gap: 6}}>
                                    <div className='rule-group-detail-title'
                                         style={{borderBottom: 'none', padding: 0}}>
                                        Source port
                                        <div className='rule-group-detail-sub-title'>The source port or port range
                                            to inspect
                                            for.</div>
                                    </div>
                                    <div className='rule-group-detail-content' style={{padding: 0}}>
                                        <CustomDropdown
                                            id='source-port-stateful-rules'
                                            style={{height: 40}}
                                            data={[]}
                                            value={{value: ''}}
                                            handleClick={() => {
                                            }}/>
                                    </div>
                                    <div className='rule-group-detail-content' style={{padding: 0}}>
                              <textarea value='' onChange={(event) => {
                              }}/>
                                    </div>
                                    <div className='rule-group-detail-title'
                                         style={{borderBottom: 'none', padding: 0}}>
                                        <div className='rule-group-detail-sub-title'>Supported ports are :34354
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{display: 'flex', gap: 10, padding: '10px 16px'}}>
                            <div className='rule-group-details-container' style={{flex: 1, border: 'none'}}>
                                <div style={{display: 'flex', flexDirection: 'column', gap: 6}}>
                                    <div className='rule-group-detail-title'
                                         style={{borderBottom: 'none', padding: 0}}>
                                        Destination
                                        <div className='rule-group-detail-sub-title'>
                                            The destination IP addresses and address ranges to inspect for
                                        </div>
                                    </div>
                                    <div className='rule-group-detail-content' style={{padding: 0}}>
                                        <CustomDropdown
                                            id='destination-stateful-rules'
                                            style={{height: 40}}
                                            data={[]}
                                            value={{value: ''}}
                                            handleClick={() => {
                                            }}/>
                                    </div>
                                    <div className='rule-group-detail-content' style={{padding: 0}}>
                              <textarea value='' onChange={(event) => {
                              }}/>
                                    </div>
                                    <div className='rule-group-detail-title'
                                         style={{borderBottom: 'none', padding: 0}}>
                                        <div className='rule-group-detail-sub-title'>You can provide single
                                            addresses and CIDR
                                            blocks
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='rule-group-details-container' style={{flex: 1, border: 'none'}}>
                                <div style={{display: 'flex', flexDirection: 'column', gap: 6}}>
                                    <div className='rule-group-detail-title'
                                         style={{borderBottom: 'none', padding: 0}}>
                                        Destination port
                                        <div className='rule-group-detail-sub-title'>The destination port or port
                                            range to inspect
                                            for.</div>
                                    </div>
                                    <div className='rule-group-detail-content' style={{padding: 0}}>
                                        <CustomDropdown
                                            id='destination-port-stateful-rules'
                                            style={{height: 40}}
                                            data={[]}
                                            value={{value: ''}}
                                            handleClick={() => {
                                            }}/>
                                    </div>
                                    <div className='rule-group-detail-content' style={{padding: 0}}>
                              <textarea value='' onChange={(event) => {
                              }}/>
                                    </div>
                                    <div className='rule-group-detail-title'
                                         style={{borderBottom: 'none', padding: 0}}>
                                        <div className='rule-group-detail-sub-title'>Supported ports are : 54546
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{display: 'flex', flexDirection: 'column', gap: 10, padding: '10px 16px'}}>
                            <div className='rule-group-detail-title'
                                 style={{borderBottom: 'none', padding: 0}}>
                                Traffic direction
                                <div className='rule-group-detail-sub-title'>The traffic direction to inspect for.
                                    Network
                                    Firewall doesn’t automatically add the direction key word to_server, and will
                                    inspect all
                                    the packets in the flow, irrespective of the flow state.</div>
                            </div>

                            <div className='rule-group-type-content' style={{padding: 0}}>
                                <div className='type-content-row'>
                                    <CustomRadio checked={trafficDirection === 'forward'}
                                                 label='Forward'
                                                 description='Inspect traffic that flows forward, from the specified source to the specified destination.'
                                                 onClick={() => {
                                                   handleSelectTrafficDirection('forward')
                                                 }}
                                    />
                                </div>
                                <div className='type-content-row'>
                                    <CustomRadio checked={trafficDirection === 'any'}
                                                 label='Any'
                                                 description='Inspect traffic from any direction.'
                                                 onClick={() => {
                                                   handleSelectTrafficDirection('any')
                                                 }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div style={{display: 'flex', flexDirection: 'column', gap: 10, padding: '10px 16px'}}>
                            <div className='rule-group-detail-title'
                                 style={{borderBottom: 'none', padding: 0}}>
                                Action
                                <div className='rule-group-detail-sub-title'>The action that you want Network
                                    Firewall to take
                                    when a packet matches the rule settings.</div>
                            </div>
                            <div className='rule-group-type-content' style={{padding: 0}}>
                                <div className='type-content-row'>
                                    <CustomRadio checked={action === 'pass'}
                                                 label='Pass'
                                                 description=''
                                                 onClick={() => {
                                                   handleSelectAction('pass')
                                                 }}
                                    />
                                </div>
                                <div className='type-content-row'>
                                    <CustomRadio checked={action === 'drop'}
                                                 label='Drop'
                                                 description=''
                                                 onClick={() => {
                                                   handleSelectAction('drop')
                                                 }}
                                    />
                                </div>
                                <div className='type-content-row'>
                                    <CustomRadio checked={action === 'alert'}
                                                 label='Alert'
                                                 description=''
                                                 onClick={() => {
                                                   handleSelectAction('alert')
                                                 }}
                                    />
                                </div>
                                <div className='type-content-row'>
                                    <CustomRadio checked={action === 'reject'}
                                                 label='Reject '
                                                 description=''
                                                 onClick={() => {
                                                   handleSelectAction('reject')
                                                 }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div style={{display: 'flex', flexDirection: 'column', gap: 10, padding: '10px 16px'}}>
                            <div className='rule-group-detail-title'
                                 style={{borderBottom: 'none', padding: 0}}>
                                Rule options - optional
                                <div className='rule-group-detail-sub-title'>No rule options associated with this
                                    rule.</div>
                            </div>

                        </div>

                        <div style={{padding: '10px 16px'}}>
                            <button className="button next-button"
                                    style={{margin: 0}}
                                    onClick={() => {
                                    }}
                            >
                                Add rule
                            </button>
                        </div>
                    </div>
                </div>

                <div className='rule-group-container'>
                    <div className="title-group">
                        <p className='title'>Rules</p>
                    </div>

                    <div className='rule-group-details-container'>
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: "center"}}>
                            <div className='rule-group-detail-title' style={{borderBottom: 0}}>Rules</div>
                            <div className="button-group">
                                <div
                                    style={{
                                      display: 'flex',
                                      border: '1px solid #E0E0E0',
                                      height: 28,
                                      gap: 8,
                                      borderRadius: 3
                                    }}>
                                    <div><img style={{paddingRight: 6, paddingLeft: 13, marginTop: 6}}
                                              src={SearchIcon}/></div>
                                    <input ref={searchRuleInputRef} value={searchRuleText}
                                           onChange={(event) => handleOnChangeSearchRuleText(event.target.value)}/>
                                    <img src={ClearIcon} style={{cursor: 'pointer'}} onClick={() => {
                                      handleClearSearchRuleInput()
                                    }}/>
                                </div>
                                <button className="button"
                                        style={{margin: 0}}
                                        onClick={() => {
                                        }}
                                >
                                    Move up
                                </button>
                                <button className="button"
                                        style={{margin: 0}}
                                        onClick={() => {
                                        }}
                                >
                                    Move down
                                </button>
                                <button className="button"
                                        style={{margin: 0}}
                                        onClick={() => {
                                        }}
                                >
                                    Delete
                                </button>
                            </div>
                        </div>
                        <div className='detail-table'
                             style={{
                               margin: 0,
                               border: 'none',
                               borderTop: '1px solid #E0E0E0'
                             }}
                        >
                            <Table
                                columns={[
                                  {
                                    label: '', field: '', sort: false, width: 40,
                                    renderCell: (row) =>
                                      <CustomRadio
                                        checked={row.id === ruleNumber}
                                        styleCheckmark={{top: '-14px'}}
                                        onClick={() => {
                                          handleSelectRule(row.id as number)
                                        }}
                                      />
                                  },
                                  {label: 'Protocol', field: 'protocol', sort: true},
                                  {label: 'Source', field: 'source', sort: true},
                                  {label: 'Destination', field: 'destination', sort: true},
                                  {label: 'Source port', field: 'sourcePort', sort: true},
                                  {label: 'Destination port', field: 'destinationPort', sort: true},
                                  {label: 'Direction', field: 'direction', sort: true},
                                  {label: 'Action', field: 'action', sort: true},
                                ]}
                                rows={ruleRows}
                            />

                            <div className="pagination-wrapper flex a-center">
                                <p className="flex a-center text-paging">
                                    Total <span>{ruleRows.length}</span>
                                </p>

                                <TablePagination
                                    currentPage={currentRulePage}
                                    updateCurrentPage={(pageNumber) => {
                                      setCurrentRulePage(pageNumber)
                                    }}
                                    totalPage={ruleRows.length / 5 + 1} // item per page is 5
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='rule-group-container'>
                    <div className="title-group">
                        <p className='title'>Configure advanced settings-optional</p>
                        <p className='sub-title'>Configure a customer managed AWS Key Management service (KMS) key
                            to
                            encrypt
                            and decrypt your resources.</p>
                    </div>

                    <div className='rule-group-details-container'>
                        <div className='rule-group-detail-title'>
                            Customer managed key
                            <div className='rule-group-detail-sub-title'>You can use a customer managed key in AWS
                                Key
                                Management
                                Service (KMS) to encrypt your data at rest. If you don’t configure a customer
                                managed key,
                                Network
                                Firewall encrypt your data using AWS managed key.</div>
                        </div>
                        <div className='rule-group-detail-title' style={{borderBottom: 'none'}}>
                            <div className='rule-group-detail-sub-title'>Your data is encrypted by default with a
                                key that
                                AWS owns and manages for you. To choose a different key, customize your encryption
                                settings.
                            </div>
                        </div>
                        <div className='rule-group-detail-content' style={{padding: '0px 16px 16px 16px'}}>
                            <div style={{display: 'flex', gap: 8, alignItems: 'center'}}>
                                <CheckboxAtom checked={isCustomizedEncryption} onchange={() => {
                                  setIsCustomizedEncryption(!isCustomizedEncryption)
                                }}/>
                                <p className='text-content'>Customize encryption settings (advanced)</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='rule-group-container'>
                    <div className="title-group">
                        <p className='title'>Add tags- optional</p>
                        <p className='sub-title'>A tag is a label that you assign to an AWS resource. You can use
                            tags to
                            search
                            and filter your resources or track your AWS costs.</p>
                    </div>

                    <div className='rule-group-details-container'>
                        <div className='rule-group-type-content'>
                            <div style={{display: 'flex', flexDirection: 'column', gap: 8, width: '100%'}}>
                                <div className='rule-group-detail-title'
                                     style={{borderBottom: 'none', padding: 0}}>
                                    Add tags-optional
                                </div>
                                <div className='rule-group-detail-content' style={{gap: 4, padding: 0}}>
                                    <div className='config-rule-row'>
                                        <div className='config-rule-column'>
                                            <div className='firewall-detail-sub-title'>
                                                Key
                                            </div>
                                        </div>
                                        <div className='config-rule-column'>
                                            <div className='firewall-detail-sub-title'>
                                                Value - optional
                                            </div>
                                        </div>
                                        <div style={{marginLeft: 6, opacity: 0, pointerEvents: 'none'}}>
                                            <button className="button"
                                                    style={{margin: 0}}
                                                    onClick={() => {
                                                    }}
                                            >
                                                Remove tag
                                            </button>
                                        </div>
                                    </div>
                                  {
                                    tags.map((item, index) => {
                                      return (
                                        <div key={index} className='config-rule-row'>
                                          <div className='config-rule-column'>
                                            <InputAtom value={item.key} onChangeValue={(value) => {
                                              handleChangeTag('key', value, index)
                                            }}/>
                                          </div>
                                          <div className='config-rule-column'>
                                            <InputAtom value={item.value} onChangeValue={(value) => {
                                              handleChangeTag('value', value, index)
                                            }}/>
                                          </div>
                                          <button className="button"
                                                  style={{margin: 0}}
                                                  onClick={() => {
                                                    handleRemoveTag(index)
                                                  }}
                                          >
                                            Remove tag
                                          </button>
                                        </div>
                                      )
                                    })
                                  }
                                </div>
                                <div>
                                    <button className="button next-button"
                                            style={{margin: 0}}
                                            onClick={() => {
                                              handleAddTag()
                                            }}
                                    >
                                        Add tags
                                    </button>
                                </div>
                                <div className='rule-group-detail-title'
                                     style={{borderBottom: 'none', padding: 0}}>
                                    <div className='rule-group-detail-sub-title'>You can add up to 50 more tags.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        }
      </>
    )
  }

  return (
    <div id="mgd-list-layout">
      <div className="row-1 flex j-between a-center">
        <div className="flex j-start a-center" id="title">
          <NetworkTitle pageTitle={'Create rule group'} id={321} name={'name'} hasPrefixIcon={false}
                        hasFavorite={false} pageBackClick={pageBackClick}/>
        </div>
      </div>

      <div className="one-page" style={{height: 'calc(100% - 70px)', overflowY: 'auto'}}>
        <div className="flex j-between a-center">
          <div className='firewall-rule-group'>
            {
              renderMutualComponent()
            }
            {
              ruleGroupFormat.id === 3 &&
              renderSuricataCompatibleRule()
            }
            {
              ruleGroupFormat.id === 2 &&
              renderDomainList()
            }
            {
              ruleGroupFormat.id === 1 &&
              renderStatefulRule()
            }

            <div className='firewall-rule-group-bottom'>
              <button className="button"
                      style={{margin: 0}}
                      onClick={() => {
                        pageBackClick();
                      }}
              >
                Cancel
              </button>
              <button className="button next-button"
                      style={{marginLeft: 8}}
                      onClick={() => {
                        handleOnClickNext()
                      }}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RuleGroupCreation;