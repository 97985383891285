import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsDescribeRulesResponse } from 'graphql/types/AwsDescribeRulesResponse';

export interface IGetAwsDescribeRulesVariables {
  cloudId: number;
  region: string;
  request: {
    listenerArn: string;
    ruleArns?: string[];
    marker?: string;
    pageSize?: number;
  }
}

export interface IAwsDescribeRulesResponseData {
  getAwsDescribeRules: IGqlResponseNewData<AwsDescribeRulesResponse>;
}

const lazyGetAwsDescribeRules = () => useLazyQuery<IAwsDescribeRulesResponseData, IGetAwsDescribeRulesVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsDescribeRules;