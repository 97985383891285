import { gql } from '@apollo/client';

const query = gql`
  query getAwsAllSecurityGroups($request: AwsDescribeSecurityGroupsRequest, $cloudId: Long, $region: String) {
    getAwsAllSecurityGroups(describeSecurityGroupsRequest: $request, cloudId: $cloudId, region: $region) {
      result
      nextToken
      data {
        ... on AwsSecurityGroup {
          description
          groupName
          ipPermissions {
            fromPort
            ipProtocol
            ipRanges {
              cidrIp
              description
            }
            ipv6Ranges {
              cidrIpv6
              description
            }
            prefixListIds {
              prefixListId
              description
            }
            toPort
          }
          ownerId
          groupId
          ipPermissionsEgress {
            fromPort
            ipProtocol
            ipRanges {
              cidrIp
              description
            }
            ipv6Ranges {
              cidrIpv6
              description
            }
            prefixListIds {
              prefixListId
              description
            }
            toPort
          }
          tags {
            key
            value
          }
          vpcId
        }
      }
    }
  }
`;
export default query;
