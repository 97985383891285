export const RESOURCE_TYPE_RADIO_LIST = [
  { id: 'CloudFront', name: 'CloudFront', label: 'Amazon CLoudFront distributions', value: 'CloudFront' },
  {
    id: 'regional',
    name: 'regional',
    label:
      'Regional resources (Application Load Balancers, Amazon API Gateway REST APIs, Amazon App Runner services, AWS AppSync GraphQL APIs, Amazon Cognito user pools and AWS Verified Access instances)',
    value: 'regional',
  },
];
