import { useLazyQuery } from '@apollo/client';
import query from './query';
import { Paging } from 'graphql/types/Paging';
import { AwsResourceIdentifier } from 'graphql/types/AwsListGroupResources';
import { IGqlResponseNewData } from 'graphql/types';

export interface IAwsAllListGroupResourcesPageVariables {
  cloudId: number;
  region: string;
  request: {
    maxResults: number;
    group: string;
    nextToken?: string;
    filters?: {
      name: string;
      values: string[];
    };
    pageInfo?: Paging;
  };
}

export interface IAwsAllListGroupResourcesResponseData {
  getAwsAllListGroupResources: IGqlResponseNewData<AwsResourceIdentifier>;
}

const lazyGetAwsAllListGroupResources = () =>
  useLazyQuery<IAwsAllListGroupResourcesResponseData, IAwsAllListGroupResourcesPageVariables>(query, {
    fetchPolicy: 'no-cache',
  });
export default lazyGetAwsAllListGroupResources;
