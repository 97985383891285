import { OrderDirection, RowType } from '@Types/v2/Table';

export const orderAlphabetical = (array: RowType[], key: string, direction: OrderDirection) => {
  return array.sort((a, b) => {
    if (a[key] < b[key]) {
      return direction === OrderDirection.ASC ? -1 : 1;
    }
    if (a[key] > b[key]) {
      return direction === OrderDirection.ASC ? 1 : -1;
    }
    return 0;
  });
};
