import { useState, useMemo, useEffect, useCallback } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import IconStateless from 'assets/svgs/v3/ico_stateless_actions.svg';
import IconStateful from 'assets/svgs/v3/ico_stateful_actions.svg';
import Icon from 'components/v2/atoms/Icon';
import './index.scss';
import ResetIcon from 'assets/svgs/v3/ico_reset.svg';
import Table from 'components/v2/dataDisplay/Table';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import { IMgdTablePaginationProps } from 'layouts/v3/MgdLayout';

interface IUnmanagedStateProps extends IBaseModalProps {
  header: string;
  subHeader?: string;
  icon?: string;
  row: RowType[];
  columns: ColumnType[];
  btnTitle?: string;
  note?: string;
  onChangeValue?: (index: number, value: string) => void;
}

const UnmanagedStateModal = ({
  header,
  subHeader,
  icon,
  row,
  columns,
  btnTitle,
  note,
  onChangeValue,
  ...baseModalProps
}: IUnmanagedStateProps) => {

  const [mainTablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 10,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1
  });

  return (
    <BaseModal
      title={() => <><Icon width={32} height={32} src={!!icon ? IconStateful : IconStateless} />{header}</>}
      {...baseModalProps}>
      <div className="unmanaged-state-modal">
        <p className="note">{note}</p>
        <div className="unmanaged-state">

          <div className="row-1">
            <p className="text-title">Stateless rule group</p>
            <button className="reset-btn"><img src={ResetIcon} width={24} height={24} /></button>
          </div>

          <div className="row-2">
            <Table
              rows={row}
              columns={columns}
              className={'table-state-rule'}
              reportCheckedList={(list: string[]) => {
              }}
              reportSelected={(id: string) => {}}
              sortOption={{
                target: mainTablePagination.target,
                direction: mainTablePagination.direction,
                onChangeSort: () => { },
              }}
              horizontalScrollable={true}
            />
          </div>
        </div>

        <div className={"button-group"}>
          <button onClick={baseModalProps.onClose}>Cancel</button>
          <button className="state-btn" onClick={() => { }}>
            {btnTitle}
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default UnmanagedStateModal;
