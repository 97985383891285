import { useState, useMemo, useEffect, useCallback } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import IconManageTlsConfiguration from 'assets/svgs/v3/ico_manage_tls.svg';
import Icon from 'components/v2/atoms/Icon';
import './index.scss';
import DropdownAtom, {
  DropdownListDataType,
} from 'components/v2/atoms/DropdownAtom';

interface IManageTLSConfigurationProps extends IBaseModalProps {
  header: string;
  onChangeValue?: (index: number, value: string) => void;
}

const dropdownDummyData: DropdownListDataType[] = [
  {
    id: 0,
    name: 'Ayless-test-tls-inspection-config-1',
    value: 'config-1',
    description: 'Last updated. April22, 2024, 17:05 ( UTC+09:00)',
  },
  {
    id: 1,
    name: 'Ayless-test-tls-inspection-config-2',
    value: 'config-2',
    description: 'Last updated. April22, 2024, 17:05 ( UTC+09:00)',
  },
  {
    id: 2,
    name: 'Ayless-test-tls-inspection-config-3',
    value: 'config-3',
    description: 'Last updated. April22, 2024, 17:05 ( UTC+09:00)',
  },
];

const ManageTLSConfigurationModal = ({
  header,
  onChangeValue,
  ...baseModalProps
}: IManageTLSConfigurationProps) => {
  const [dropdownValue, setDropdownValue] = useState<DropdownListDataType>(dropdownDummyData[0]);
  const [isShowDropdown, setIsShowDropdown] = useState<boolean>(true);

  useEffect(() => {
    if (baseModalProps.open) {
      setDropdownValue(dropdownDummyData[0]);
      setIsShowDropdown(true);
    }
  }, [baseModalProps.open]);

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={IconManageTlsConfiguration} />
          {header}
        </>
      )}
      {...baseModalProps}>

      <div className="tls-configuration-modal">
        <div className="tls-configuration">
          <div className="tls-container">
            <p className="text-description">
              Choose a TLS inspection configuration to add to your policy. If you haven’t yet created a TLS inspection, then 
              <a className="link"> create a TLS inspection configuration.</a>
            </p>
          </div>

          <div className="tls-configuration-detail">
            <p className="text-title">TLS inspection configuration</p>
            {isShowDropdown && (
              <div className="tls-container-dropdown">
                <DropdownAtom
                  id={'tls-configuration-modal'}
                  className={'tls-dropdown'}
                  data={dropdownDummyData}
                  value={dropdownValue}
                  handleClick={(val) => {setDropdownValue(val)}}
                />
                <button className="remove-btn" onClick={() => {setIsShowDropdown(false)}}>
                  Remove
                </button>
              </div>
            )}

            <p className="text-note">
              You can add 1 TLS inspection configuration.
            </p>
          </div>
        </div>

        <div className="button-group ">
          <button onClick={baseModalProps.onClose}>Cancel</button>
          <button className="delete-btn" onClick={() => {}}>
            Save
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default ManageTLSConfigurationModal;
