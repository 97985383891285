import { useLazyQuery } from '@apollo/client';
import query from './query';
import { AwsAccount } from 'graphql/types/AwsAccount';
import { IGqlResponseData } from 'graphql/types';

export interface IGetAllAccountVariables {
  reqData: {
    externalId: string;
    roleArn: string;
  }
}

export interface IGetAllAccountResponseData {
  getAllAccounts: IGqlResponseData<AwsAccount[]>;
}

const lazyGetAllAccount = () => useLazyQuery<IGetAllAccountResponseData, IGetAllAccountVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAllAccount;