import { useLazyQuery } from '@apollo/client';
import query from './query';
import { AwsListKeyRotationsEntry } from 'graphql/types/AwsListKeyRotations';

export interface IAwsListKeyRotationsVariables {
  cloudId: number;
  region: string;
  request: {
    keyId: string;
  }
}

export interface IAwsListKeyRotationsResponseData {
  getAwsListKeyRotations: AwsListKeyRotationsEntry;
}

const lazyGetAwsListKeyRotations = () => useLazyQuery<IAwsListKeyRotationsResponseData, IAwsListKeyRotationsVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsListKeyRotations;