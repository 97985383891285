import { useState, useEffect } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import InputAtom from 'components/v2/atoms/InputAtom';
import IconEditFirewallDetail from 'assets/svgs/v3/ico_edit_firewall_detail.svg';
import IconEditRuleGroup from 'assets/svgs/v3/ico_policy_variables.svg';
import Icon from 'components/v2/atoms/Icon';
import './index.scss';
import Labeled from 'components/v2/atoms/Labeled';

interface IEditFirewallDetailProps extends IBaseModalProps {
  enumType: 'EditRuleGroup' | 'EditFireWallDetail';
  header: string;
  valueName?: string;
  placeholderDescription?: string;
  onChangeValue?: (index: number, value: string) => void;
}

const EditFirewallDetailModal = ({
  enumType,
  header,
  valueName,
  placeholderDescription,
  onChangeValue,
  ...baseModalProps
}: IEditFirewallDetailProps) => {
  const [payload, setPayload] = useState<string>('');

  useEffect(() => {
    if (baseModalProps.open) {
      setPayload('');
    }
  }, [baseModalProps.open]);

  return (
    <BaseModal
      title={() => (
        <>
          <Icon
            width={32}
            height={32}
            src={enumType === 'EditFireWallDetail' ? IconEditFirewallDetail : IconEditRuleGroup}
          />
          {header}
        </>
      )}
      {...baseModalProps}
    >
      <div className="edit-firewall-detail-modal">
        <div className="edit-firewall-name">
          {enumType === 'EditFireWallDetail' ? <p>Name</p> : <Labeled title={'Name'} required></Labeled>}
          <InputAtom type={'text'} value={valueName} noClear={true} disabled={true} />
        </div>
        <div className="edit-firewall-description">
          <p>Description</p>
          <InputAtom
            type={'text'}
            placeholder={placeholderDescription}
            value={payload}
            noClear={true}
            hasPrefixIcon={true}
            onChangeValue={
              enumType === 'EditFireWallDetail'
                ? val => setPayload(val)
                : val => {
                    if (val.length <= 256) setPayload(val);
                  }
            }
          />
        </div>
        <div className="button-group">
          <button onClick={baseModalProps.onClose}>Cancel</button>
          <button className="delete-btn" onClick={() => {}}>
            Save
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default EditFirewallDetailModal;
