import { gql } from '@apollo/client';

const query = gql`
query getAwsFirewall($request: AwsDescribeFirewallRequest, $cloudId: Long, $region: String) {
  getAwsFirewall(describeFirewallRequest: $request, cloudId: $cloudId, region: $region) {
    result
     data{
      ... on AwsDescribeFirewallResponse {
        updateToken
        firewall {
          firewallName
          firewallArn
          firewallPolicyArn
          vpcId
          subnetMappings {
            subnetId
            ipAddressType
          }
          deleteProtection
          subnetChangeProtection
          firewallPolicyChangeProtection
          description
          firewallId
          tags {
            key
            value
          }
          encryptionConfiguration {
            keyId
            type
          }
        }
        firewallStatus {
          status
          configurationSyncStateSummary
          syncStates
          capacityUsageSummary {
            cidRs {
              availableCIDRCount
              utilizedCIDRCount
              ipSetReferences
            }
          }
        }
        }
      }
    }
  }
`;
export default query;