import { gql } from '@apollo/client';

const query = gql`
query getAwsRuleGroup($request: AwsGetRuleGroupRequest, $cloudId: Long, $region: String) {
  getAwsRuleGroup(RuleGroupRequest: $request, cloudId: $cloudId, region: $region) {
    result
    data {
      ... on AwsGetRuleGroupResponse {
        ruleGroup {
          name
          id
          capacity
          arn
          description
          visibilityConfig {
            sampledRequestsEnabled
            cloudWatchMetricsEnabled
            metricName
          }
          rules {
            name
            priority
            statement
            action {
              block {
                customResponse {
                  responseCode
                  customResponseBodyKey
                  responseHeaders {
                    name
                    value
                  }
                }
              }
              allow {
                customRequestHandling {
                  insertHeaders {
                    name
                    value
                  }
                }
              }
              count {
                customRequestHandling {
                  insertHeaders {
                    name
                    value
                  }
                }
              }
              captcha {
                customRequestHandling {
                  insertHeaders {
                    name
                    value
                  }
                }
              }
              challenge {
                customRequestHandling {
                  insertHeaders {
                    name
                    value
                  }
                }
              }
            }
            overrideAction {
              count {
                customRequestHandling {
                  insertHeaders {
                    name
                    value
                  }
                }
              }
              none
            }
            ruleLabels {
              name
            }
            visibilityConfig {
              sampledRequestsEnabled
              cloudWatchMetricsEnabled
              metricName
            }
            captchaConfig {
              immunityTimeProperty {
                immunityTime
              }
            }
            challengeConfig {
              immunityTimeProperty {
                immunityTime
              }
            }
          }
          labelNamespace
          customResponseBodies
          availableLabels {
            name
          }
          consumedLabels {
            name
          }
        }
        lockToken
      }
    }
  }
}
`;
export default query;