import { useLazyQuery } from '@apollo/client';
import query from './query';

export interface IAwsLoginProfileVariables {
  cloudId: number;
  region: string;
  request: {
    userName?: string;
  };
}

export interface IAwsLoginProfileResponseData {
  getAwsLoginProfile: any;
}

const lazyGetAwsLoginProfile = () =>
  useLazyQuery<IAwsLoginProfileResponseData, IAwsLoginProfileVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsLoginProfile;
