import { gql } from '@apollo/client';

const query = gql`
  query getAwsGroupPolicy($request: AwsGetGroupPolicyRequest, $cloudId: Long, $region: String) {
    getAwsGroupPolicy(getGroupPolicyRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsGetGroupPolicyResponse {
          groupName
          policyName
          policyDocument
        }
      }
    }
  }
`;
export default query;
