import {
  NETWORK_RULE_CUSTOM_ICMP_PORT_DROPDOWN,
  NETWORK_RULE_CUSTOM_ICMP_PORT_PAR_PRO_DROPDOWN,
  NETWORK_RULE_CUSTOM_ICMP_PORT_RED_MES_DROPDOWN,
  NETWORK_RULES_ICMP_CODE_MAPPING,
  NETWORK_RULES_ICMP_CODE_PAR_PRO_MAPPING,
  NETWORK_RULES_ICMP_CODE_RED_MES_MAPPING,
  NETWORK_RULES_ICMP_TYPE_MAPPING,
  NETWORK_RULES_TYPE_MAPPING,
  RULE_CUSTOM_ICPM_PROTOCOL_DROPDOWN,
  RULE_PROTOCOL_DROPDOWN,
  SECURITY_GROUP_RULES_PROTOCOL_MAPPING,
  SECURITY_GROUP_RULES_TYPE_MAPPING
} from "../Constant";
import {DropdownListDataType} from "components/v2/atoms/DropdownAtom";

export const getNetworkPortRangeValue = (portRange: {from: string, to: string}, icmpTypeCode: {code: string, type: string}) => {
  if (portRange) {
    if (portRange.from == portRange.to) {
      return portRange.to;
    } else {
      return 'All';
    }
  }
  if (icmpTypeCode) {
    const imcpTypeMapping = NETWORK_RULES_ICMP_TYPE_MAPPING[icmpTypeCode.type];
    const imcpCodeMapping = NETWORK_RULES_ICMP_CODE_MAPPING[icmpTypeCode.code];
    
    let icmpValue;
    if (imcpTypeMapping.icmpType == 'All' && imcpCodeMapping.icmpCode == 'All') {
      return 'All';
    } else {
      if (imcpTypeMapping.icmpType != 'All') {
        icmpValue = imcpTypeMapping.icmpType;
      }
      if (imcpCodeMapping.icmpCode != 'All') {
        icmpValue += ' / ' + imcpCodeMapping.icmpCode;
      }
      return icmpValue;
    }
  }
  return 'All';
};

export const getNetworkTypeValue = (portRange: {from: string, to: string}, icmpTypeCode: {code: string, type: string}, hasIpv6: boolean, protocolCode: string) => {
  let networkType;
  if (protocolCode == '-1') {
    networkType = NETWORK_RULES_TYPE_MAPPING['all_traffic'];
  }
  if (protocolCode == '1' || protocolCode == 'icmp') {
    if (icmpTypeCode.code == '-1' && icmpTypeCode.type == '-1'){
      networkType = NETWORK_RULES_TYPE_MAPPING['all_icmp_ipv4'];
    }
  }
  if (protocolCode == '58' || protocolCode == 'icmpv6') {
    if (icmpTypeCode.code == '-1' && icmpTypeCode.type == '-1'){
      networkType = NETWORK_RULES_TYPE_MAPPING['all_icmp_ipv6'];
    }
  }
  if (protocolCode == '6' || protocolCode == 'tcp') {
    if (portRange.from == '0' && portRange.to == '65535') {
      networkType = NETWORK_RULES_TYPE_MAPPING['all_tcp'];
    } else if (portRange.from != portRange.to
        || (portRange.from == portRange.to && !NETWORK_RULES_TYPE_MAPPING[portRange.from])){
      networkType = NETWORK_RULES_TYPE_MAPPING['custom_tcp'];
    }
  }
  if (protocolCode == '17' || protocolCode == 'udp') {
    if (portRange.from == '0' && portRange.to == '65535') {
      networkType = NETWORK_RULES_TYPE_MAPPING['all_udp'];
    } else if (portRange.from != portRange.to
        || (portRange.from == portRange.to && !NETWORK_RULES_TYPE_MAPPING[portRange.from])) {
      networkType = NETWORK_RULES_TYPE_MAPPING['custom_udp'];
    }
  }
  
  if (!networkType && portRange) {
    if (portRange.from == portRange.to) {
      if (portRange.from == '53') {
        if (protocolCode == '6') {
          networkType = NETWORK_RULES_TYPE_MAPPING['53_tcp'];
        }
        networkType = NETWORK_RULES_TYPE_MAPPING['53_udp'];
      }
      networkType = NETWORK_RULES_TYPE_MAPPING[portRange.to];
    }
  }

  if (!networkType) {
    if (hasIpv6 && !networkType) {
      networkType = NETWORK_RULES_TYPE_MAPPING['custom_icmp_ipv6'];
    } else {
      networkType = NETWORK_RULES_TYPE_MAPPING['custom_icmp_ipv4'];
    }
  }
  if (!networkType && protocolCode != '-1') {
    networkType = NETWORK_RULES_TYPE_MAPPING['custom_protocol'];
  }
  return networkType?.type;
}

export const getSecurityGroupPortRange = (from: number, to: number) => {
  let portRange;
  if (from == 0 && to == 65535) {
    portRange = '0 - 65535';
  }
  if(from == to && from != 0) {
    portRange = from;
    if(from == -1) {
      portRange = 'All';
    }
  }
  if(from == 3) {
    portRange = NETWORK_RULES_ICMP_CODE_MAPPING[to].icmpCode;
  }
  if(from == 5) {
    portRange = NETWORK_RULES_ICMP_CODE_RED_MES_MAPPING[to].icmpCode;
  }
  if(from == 12) {
    portRange = NETWORK_RULES_ICMP_CODE_PAR_PRO_MAPPING[to].icmpCode;
  }
  if(from == to && from == 0) {
    portRange = '-';
  }
  if(!portRange) {
    portRange = 'N/A';
  }

  return portRange;
}

export const getSecurityGroupRuleProtocolValue = (type: string, fromPort: number) => {
  let securityGroupRuleProtocol;
  if (type == 'custom_tcp' || type == 'all_tcp'  || type == '22' || type == '25' || type == '53_tcp') {
    securityGroupRuleProtocol = 'TCP';
  }
  if (type == 'custom_udp' || type == 'all_udp' || type == '53_udp') {
    securityGroupRuleProtocol = 'UDP';
  }
  if (type == 'all_icmp_ipv4') {
    securityGroupRuleProtocol = 'ICMP';
  }
  if (type == 'all_icmp_ipv6') {
    securityGroupRuleProtocol = 'IPv6 ICMP';
  }
  if (type == 'all_traffic') {
    securityGroupRuleProtocol = 'All';
  }
  if (type == 'custom_icmp_ipv4' ) {
    securityGroupRuleProtocol = SECURITY_GROUP_RULES_PROTOCOL_MAPPING[fromPort].protocol;
  }
  if (type == 'custom_protocol' ) {
    if(fromPort == -1){
      securityGroupRuleProtocol = 'All (-1)';
    }
    if(fromPort == 1){
      securityGroupRuleProtocol = 'ICMP (1)';
    }
    if(fromPort == 6){
      securityGroupRuleProtocol = 'TCP (6)';
    }
    if(fromPort == 17){
      securityGroupRuleProtocol = 'UDP (17)';
    }
  }
  if(!securityGroupRuleProtocol){
    securityGroupRuleProtocol = `${SECURITY_GROUP_RULES_TYPE_MAPPING[type].type} (${fromPort})`; 
  }
  return securityGroupRuleProtocol;
}

export const getSecurityGroupRuleTypeValue = (fromPort: number, toPort: number, ipProtocol: string) => {
  let securityGroupRuleType;
  if (ipProtocol == '-1' && fromPort == -1 && toPort == -1 ) {
    securityGroupRuleType = SECURITY_GROUP_RULES_TYPE_MAPPING['all_traffic'];
  }
  if (ipProtocol == 'tcp') {
    if (fromPort == 0 && toPort == 65535) {
      securityGroupRuleType = SECURITY_GROUP_RULES_TYPE_MAPPING['all_tcp'];
    } else if (fromPort != toPort || (fromPort == toPort && !SECURITY_GROUP_RULES_TYPE_MAPPING[fromPort])){
      securityGroupRuleType = SECURITY_GROUP_RULES_TYPE_MAPPING['custom_tcp'];
    }
    
  }
  if (ipProtocol == 'udp') {
    if (fromPort == 0 && toPort == 65535) {
      securityGroupRuleType = SECURITY_GROUP_RULES_TYPE_MAPPING['all_udp'];
    } else if (fromPort != toPort || (fromPort == toPort && !SECURITY_GROUP_RULES_TYPE_MAPPING[fromPort])){
      securityGroupRuleType = SECURITY_GROUP_RULES_TYPE_MAPPING['custom_udp'];
    }
  }
  if (ipProtocol == 'icmp') {
    if (fromPort == 0 && toPort == 0){
      securityGroupRuleType = SECURITY_GROUP_RULES_TYPE_MAPPING['custom_protocol'];
    }
    if (fromPort == -1 && toPort == -1){
      securityGroupRuleType = SECURITY_GROUP_RULES_TYPE_MAPPING['all_icmp_ipv4'];
    } else if(NETWORK_RULES_ICMP_TYPE_MAPPING[fromPort] && NETWORK_RULES_ICMP_CODE_MAPPING[toPort]){
      securityGroupRuleType = SECURITY_GROUP_RULES_TYPE_MAPPING['custom_icmp_ipv4'];
    }
  }
  if (ipProtocol == 'icmpv6') {
    if (fromPort == -1 && toPort == -1){
      securityGroupRuleType = SECURITY_GROUP_RULES_TYPE_MAPPING['all_icmp_ipv6'];
    }
  }
  if (!securityGroupRuleType && fromPort == toPort) {
    if (fromPort == 53) {
      if (ipProtocol == 'tcp') {
        securityGroupRuleType = SECURITY_GROUP_RULES_TYPE_MAPPING['53_tcp'];
      }
      securityGroupRuleType = SECURITY_GROUP_RULES_TYPE_MAPPING['53_udp'];
    }
    securityGroupRuleType = SECURITY_GROUP_RULES_TYPE_MAPPING[fromPort];
  }
  const protocol = getSecurityGroupRuleProtocolValue(securityGroupRuleType?.key || '', fromPort);
  const portRange = getSecurityGroupPortRange(fromPort, toPort);
  return {
    type: securityGroupRuleType?.type,
    protocol: protocol,
    portRange: portRange
  };
}

export const getProtocolByType = (type: string, protocol: string, portRange: string) => {
  if (type === 'all_traffic' || type === 'custom_icmp_ipv4' || type == 'custom_icmp_ipv6') {
    protocol = '-1';
  } else if (type === 'custom_tcp' || type === 'all_tcp' || type === '22' || type === '23' || type === '25' || type === '42' || type === '53_tcp') {
    protocol = '6';
  } else if (type === 'custom_udp' || type === 'all_udp' || type === '53_udp') {
    protocol = '17';
  } else if (type === 'all_icmp_ipv4') {
    protocol = '1';
  } else if (type === 'all_icmp_ipv6') {
    protocol = '58';
  }
  return {
    protocol: protocol,
    portRange: getPortRangeByTypeAndProtocol(type, protocol, portRange)
  };
}

export const getPortRangeByTypeAndProtocol = (type: string, protocol: string, portRange: string) => {
  if (type === 'all_traffic' || type === 'custom_protocol' || type === 'all_tcp' || type === 'all_udp' || type === 'all_icmp_ipv4' || type === 'all_icmp_ipv4') {
    portRange = 'All';
  } else if (type === 'custom_tcp' || type === 'custom_udp') {
    portRange = '0';
  } else if (type === 'custom_icmp_ipv4' || type === 'custom_icmp_ipv6') {
    if (protocol === '11') {
      portRange = 'All';
    } else if (protocol === '3' || protocol === '5' || protocol === '12') {
      portRange = '-1';
    } else {
      portRange = 'N/A';
    }
  } else if (type === '53_tcp' || type === '53_udp') {
    portRange = '53';
  } else {
    portRange = type;
  }
  return portRange;
}

export const disabledByType = (type: string) => {
  let disabled = true;
  if (type === 'custom_protocol' || type === 'custom_icmp_ipv4' || type == 'custom_icmp_ipv6') {
    disabled = false;
  }
  return disabled;
}

export const getProtocolDataByType = (type: string) => {
  let data = RULE_PROTOCOL_DROPDOWN;
  if (type === 'custom_icmp_ipv4' || type == 'custom_icmp_ipv6') {
    data = RULE_CUSTOM_ICPM_PROTOCOL_DROPDOWN;
  }
  return data;
}

export const disabledByTypeAndProtocol = (type: string, protocol: string) => {
  let disabled = true;
  if (type === 'custom_tcp' || type === 'custom_udp') {
    disabled = false;
  }
  if (type === 'custom_icmp_ipv4' || type == 'custom_icmp_ipv6') {
    if (protocol === '3' || protocol === '5' || protocol === '12') {
      disabled = false;
    }
  }
  return disabled;
}

export const getPortRangeDataByType = (type: string, protocol: string) => {
  let data: DropdownListDataType[] = [{
    name: 'N/A', value: 'N/A'
  }];
  if (type === 'custom_icmp_ipv4' || type == 'custom_icmp_ipv6') {
    if (protocol === '3') {
      data = NETWORK_RULE_CUSTOM_ICMP_PORT_DROPDOWN;
    }
    if (protocol === '5') {
      data = NETWORK_RULE_CUSTOM_ICMP_PORT_RED_MES_DROPDOWN;
    }
    if (protocol === '12') {
      data = NETWORK_RULE_CUSTOM_ICMP_PORT_PAR_PRO_DROPDOWN;
    }
  }
  return data;
}