import { gql } from '@apollo/client';

const query = gql`
  query getAwsAlllistFirewallPolicies($request: AwsListFirewallPoliciesRequest, $cloudId: Long, $region: String) {
    getAwsAlllistFirewallPolicies(listFirewallPoliciesRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsFirewallPolicyMetadata {
          name
          arn
        }
      }
    }
  }
`;
export default query;
