import { useEffect } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import Icon from 'components/v2/atoms/Icon';
import IconEditDefaultWebAcl from 'assets/svgs/v3/ico_policy_variables.svg';
import './index.scss';

interface IEditDefaultWebAclActionModalProps extends IBaseModalProps {
  header: string;
}

const EditDefaultWebAclActionModal = ({ header, ...baseModalProps }: IEditDefaultWebAclActionModalProps) => {
  useEffect(() => {
    if (baseModalProps.open) {
    }
  }, [baseModalProps.open]);

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={IconEditDefaultWebAcl} />
          <div className="text-title-modal">{header}</div>
        </>
      )}
      className={'modal-edit-default-web-acl-action'}
      {...baseModalProps}
    >
      <div className="edit-web-acl-action-content"></div>
    </BaseModal>
  );
};

export default EditDefaultWebAclActionModal;
