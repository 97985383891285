import { useEffect, useState } from 'react';
import SelectBox, { SelectDataType } from 'components/UI/SelectBox';
import './index.scss';
import Button from 'components/UI/Button';
import addProject from 'graphql/mutations/addProject';
import { ErrorCode } from '@Types/error';
import { useSnackbar } from 'hooks/useSnackbar';
import getEnumTypeCode from 'graphql/queries/getEnumTypeCode';

interface ICreateProjectModalProps{
    onClose: () => void;
    organ: { id: number, name: string };
}
const CreateProjectModal = ({ onClose, organ }: ICreateProjectModalProps) => {
  const [createData, setCreateData] = useState<{
    projectName: string;
    incomeKey: string;
    incomeType: SelectDataType,
    incomeValue: string;
    monitorYn: boolean;
    msspYn: boolean;
  }>({
    projectName: '',
    incomeKey: '',
    incomeType: { name: '', value: '' },
    incomeValue: '',
    monitorYn: false,
    msspYn: false
  });

  const { data: ThirdPartyTypeList } = getEnumTypeCode({
    text: 'ThirdPartyCode'
  });
  const [createProject] = addProject();

  const handleCreate = () => {
    const date = new Date;
    const now = date.toISOString();
    
    createProject({
      variables: {
        reqData: {
          organId: organ.id,
          name: createData.projectName,
          thirdPartTypeCode: createData.incomeType.value.toString(),
          attrKey1: 'data_attr',
          attrValue1: createData.incomeValue,
          monitorYn: createData.monitorYn,
          msspYn: createData.msspYn
        }
      }
    }).then(({ data }) => {
      if (data) {
        if (data.addProject.result === ErrorCode.SUCCESS) {
          useSnackbar(data.addProject.result);
          onClose();
        } else {
          useSnackbar(data.addProject.result);
        }
      } else {
        useSnackbar(ErrorCode.UNKNOWN);
      }
    });
  };

  useEffect(() => {
    setCreateData({
      projectName: '',
      incomeKey: '',
      incomeType: { name: '', value: '' },
      incomeValue: '',
      monitorYn: false,
      msspYn: false
    });
  },[open]);
  
  return (
    <div className="modal-bg" onClick={onClose}>
      <div className="cpmodal-wrap" onClick={e => e.stopPropagation()}>
        <div className="header-wrap">
          <span/>
          <p>
            프로젝트 생성
          </p>
        </div>
        <div className="contents-wrap cp">
          <p className="title">프로젝트 정보를 만들어 생성합니다.</p>
          <div className="row">
            <p className="row-title">소속 조직</p>
            <p>{organ.name}</p>
          </div>
          <div className="row">
            <p className="row-title">프로젝트 이름</p>
            <input type="text" className="input" value={createData.projectName} onChange={e => setCreateData(prev => ({ ...prev, projectName: e.target.value }))}/>
          </div>
          <div className="row">
            <p className="row-title">데이터 유입</p>
            <SelectBox 
              id="createprojincome"
              data={ThirdPartyTypeList?.getEnumTypeCode.data || []} 
              handleClick={(val) => setCreateData(prev => ({ ...prev, incomeType: val }))} 
              value={createData.incomeType}/>
          </div>
          <div className="row">
            <p className="row-title">데이터 유입 속성</p>
            <textarea className="input textarea" value={createData.incomeValue} onChange={e => setCreateData(prev => ({ ...prev, incomeValue: e.target.value }))}/>
          </div>
          <div className="modal-btn-wrap">
            <Button disabled={createData.projectName === '' || createData.incomeValue === '' || createData.incomeType.value === ''} text={'생성'} handleClick={handleCreate}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateProjectModal;