import { useLazyQuery } from '@apollo/client';
import query from './query';
import { Paging } from 'graphql/types/Paging';
import { IGqlResponseNewData } from 'graphql/types';

export interface IAwsAllDescribeVolumesVariables {
  cloudId: number;
  region: string;
  request: {
    maxResults: number;
    nextToken?: string;
    vpcIds?: string[];
    dryRun?: boolean;
    filters?: {
      name: string;
      values: string[];
    };
    pageInfo?: Paging;
  };
}

export interface IAwsAllDescribeVolumesResponseData {
  getAwsAllDescribeVolumes: IGqlResponseNewData<any>;
}

const lazyGetAwsAllDescribeVolumes = () =>
  useLazyQuery<IAwsAllDescribeVolumesResponseData, IAwsAllDescribeVolumesVariables>(query, {
    fetchPolicy: 'no-cache',
  });
export default lazyGetAwsAllDescribeVolumes;
