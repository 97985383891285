import { gql } from '@apollo/client';

const query = gql`
query getAwsAddresses($request: AwsDescribeAddressesRequest, $cloudId: Long, $region: String) {
  getAwsAddresses(describeAddressesRequest: $request, cloudId: $cloudId, region: $region) {
    result
    data {
      ... on AwsAddress {
          instanceId
        publicIp
        allocationId
        associationId
        domain
        networkInterfaceId
        networkInterfaceOwnerId
        privateIpAddress
        tags {
          key
          value
        }
        publicIpv4Pool
        networkBorderGroup
        customerOwnedIp
        customerOwnedIpv4Pool
        carrierIp
        }
      }
    }
  }
`;
export default query;