import { gql } from '@apollo/client';

const query = gql`
  query getAwsServiceLastAccessedDetailsWithEntities(
    $request: AwsGetServiceLastAccessedDetailsWithEntitiesRequest
    $cloudId: Long
    $region: String
  ) {
    getAwsServiceLastAccessedDetailsWithEntities(
      getServiceLastAccessedDetailsWithEntitiesRequest: $request
      cloudId: $cloudId
      region: $region
    ) {
      result
      data {
        ... on AwsGetServiceLastAccessedDetailsWithEntitiesResponse {
          jobStatus
          jobCreationDate
          jobCompletionDate
          entityDetailsList {
            entityInfo {
              arn
              name
              type
              id
              path
            }
            lastAuthenticated
          }
          isTruncated
          marker
          error {
            message
            code
          }
        }
      }
    }
  }
`;
export default query;
