import { useLazyQuery } from '@apollo/client';
import query from './query';
import { AwsRuleGroupMetadataResponse } from 'graphql/types/AwsRuleGroup';
import { IGqlResponseNewData } from 'graphql/types';

export interface IGetAwsRuleGroupMetadataVariables {
  cloudId: number;
  region: string;
  request: {
    ruleGroupName?: string;
    ruleGroupArn?: string;
    type?: string;
  };
}

export interface IAwsRuleGroupMetadataResponseData {
  getAwsRuleGroupMetadata: IGqlResponseNewData<AwsRuleGroupMetadataResponse>;
}

const lazyGetAwsRuleGroupMetadata = () =>
  useLazyQuery<IAwsRuleGroupMetadataResponseData, IGetAwsRuleGroupMetadataVariables>(query, {
    fetchPolicy: 'no-cache',
  });
export default lazyGetAwsRuleGroupMetadata;
