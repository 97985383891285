import { useLazyQuery } from '@apollo/client';
import query from './query';
import { AwsListGroupPoliciesResponseEntry } from 'graphql/types/AwsListGroupPolicies';

export interface IGetAwsListGroupPoliciesVariables {
  cloudId: number;
  region: string;
  request: {
    groupName?: string;
    maxItems?: number;
  }
}

export interface IAwsListGroupPoliciesResponseData {
  getAwsListGroupPolicies: AwsListGroupPoliciesResponseEntry;
}

const lazyGetAwsListGroupPolicies = () => useLazyQuery<IAwsListGroupPoliciesResponseData, IGetAwsListGroupPoliciesVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsListGroupPolicies