import { gql } from '@apollo/client';

const query = gql`
query getAwsDescribeImageAttribute($request: AwsDescribeImageAttributeRequest, $cloudId: Long, $region: String) {
  getAwsDescribeImageAttribute(describeImageAttributeRequest: $request, cloudId: $cloudId, region: $region) {
    result
    nextToken
     data{
      ... on AwsDescribeImageAttributeResponse {
       blockDeviceMappings {
        deviceName
        virtualName
        ebs {
          deleteOnTermination
          iops
          snapshotId
          volumeSize
          volumeType
          kmsKeyId
          throughput
          outpostArn
          encrypted
        }
        noDevice
      }
        imageId
        launchPermissions {
          group
          userId
          organizationArn
          organizationalUnitArn
        }
        productCodes {
          productCodeId
          productCodeType
        }
        description {
          value
        }
        kernelId {
          value
        }
        ramdiskId {
          value
        }
        sriovNetSupport {
          value
        }
        bootMode {
          value
        }
        tpmSupport {
          value
        }
        uefiData {
          value
        }
        lastLaunchedTime {
          value
        }
        imdsSupport {
          value
        }
        }
      }
    }
  }`;
export default query;