import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsSnapshotType } from 'graphql/types/AwsDescribeSnapshots';

export interface IAwsDescribeSnapshotsVariables {
  cloudId: number;
  region: string;
  request: {
    filters?: {
      name: string;
      values: string[];
    }[];
    maxResults?: number;
    nextToken?: string;
    ownerIds?: string[];
    restorableByUserIds?: string[];
    snapshotIds?: string[];
    dryRun?: boolean;
  }
}

export interface IAwsAwsDescribeSnapshotsResponseData {
  getAwsAllDescribeSnapshots: IGqlResponseNewData<AwsSnapshotType>;
}

const lazyGetAwsAllDescribeSnapshots = () => useLazyQuery<IAwsAwsDescribeSnapshotsResponseData, IAwsDescribeSnapshotsVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsAllDescribeSnapshots;