import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import './NetworkMapping.scss';
import { AwsLoadBalancer } from 'graphql/types/AwsLoadBalancer';
import DetailTable from '../../DetailTable';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import lazyGetAwsSecurityGroups, { IGetAwsSecurityGroupsVariables } from 'graphql/queries/getAwsSecurityGroups';
import { handleFormatText } from 'utils/Common';

export type SecurityPropsType = {
  cloudId: number;
  region: string;
  data?: AwsLoadBalancer;
};

const Security = (props: SecurityPropsType) => {
  const { data, cloudId, region } = props;
  const [getAwsSecurityGroups, { loading: awsSecurityGroupsLoading }] = lazyGetAwsSecurityGroups();

  const [tablePagination, setTablePagination] = useState({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });
  const [rows, setRows] = useState<RowType[]>([]);

  const handleHyperLinkClick = useCallback((row: RowType) => {}, []);

  const columns = useMemo((): ColumnType[] => {
    return [
      {
        label: 'Security group ID',
        field: 'groupId',
        sort: true,
        renderCell: (row: RowType) => (
          <p className="link" onClick={() => {}}>
            {handleFormatText(row.groupId)}
          </p>
        ),
      },
      { label: 'Name', field: 'groupName', sort: true },
      { label: 'Description', field: 'description', sort: true },
    ];
  }, []);

  const apiIsLoading = useMemo(() => {
    return awsSecurityGroupsLoading;
  }, [awsSecurityGroupsLoading]);

  const vpcId = useMemo(() => {
    return data?.vpcId || '-';
  }, [data]);

  const fetchAwsSecurityGroups = useCallback(() => {
    const sgVariable: IGetAwsSecurityGroupsVariables = {
      cloudId: cloudId,
      region: region,
      request: {
        maxResults: 100,
        filters: {
          name: 'vpc-id',
          values: [vpcId],
        },
      },
    };

    getAwsSecurityGroups({ variables: sgVariable }).then(({ data: awsSecurityGroups }) => {
      const awsSecurityGroupData = awsSecurityGroups?.getAwsSecurityGroups?.data?.[0]?.securityGroups;
      if (awsSecurityGroupData) {
        const securityGroupRows: RowType[] = [];
        awsSecurityGroupData.map(sg => {
          
          securityGroupRows.push({
            ...sg,
            id: sg.groupId,
          });
        });
        setRows(securityGroupRows);
      }
    });
  }, [cloudId, region, vpcId]);

  const currentRowPage = useMemo((): RowType[] => {
    return orderAlphabetical(rows, tablePagination.target, tablePagination.direction);
  }, [rows, tablePagination]);

  useEffect(() => {
    fetchAwsSecurityGroups();
  }, []);

  return (
    <Fragment>
      {apiIsLoading ? (
        <div className="storage-info">
          <div className="progress-container">
            <div className="progress-gif" />
            Loading ...
          </div>
        </div>
      ) : (
        <Fragment>
          <div className="listeners-info">
            <div className="table-container">
              <DetailTable
                title={'Security groups'}
                description={
                  'A security group is a set of firewall rules that control the traffic to your load balancer.'
                }
                data={currentRowPage}
                columns={columns}
                hasPagination={false}
              />
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default Security;
