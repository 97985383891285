import './index.scss';
import LinkIcon from 'assets/svgs/v3/ico_link.svg';
import ChatBotLogoIcon from 'assets/svgs/v3/ico_chatbot_logo.png';

import Icon from 'components/v2/atoms/Icon';
import Message from './Message';

interface IMessageProps {
  key: string,
  isLeft: boolean,
  message: string,
  liked: boolean,
  disLiked: boolean,
  dateTime: string,
  hasAction: boolean,
}

interface IChatBotProps {
  updateChatBotPanel: (value: string) => void;
}

const ChatBot = ({updateChatBotPanel}: IChatBotProps) => {

  const messages = [
    {
      key: '1',
      isLeft: true,
      message: "Roles of the Cyber Security Professional",
      liked: false,
      disLiked: false,
      dateTime: "13:00",
      hasAction: false,
    },
    {
      key: '2',
      isLeft: false,
      message: "At a mile-high level, cybersecurity professionals are responsible for protecting IT infrastructure, edge devices, networks, and data.",
      liked: false,
      disLiked: false,
      dateTime: "13:00",
      hasAction: true,
    }
  ];

  return (
    <div id="chatbot-page">
      {/* header chatbot */}
      <div className="header flex j-between a-center">
        <Icon width={14} height={16} src={ChatBotLogoIcon} />
        <button className="close-btn" onClick={() => updateChatBotPanel('false')} />
      </div>

      {/* message content */}
      <div className="message-content">
        {/* separate date */}
        <div className="separate-date">
            <p className="date-time-text">2024.02.02</p>
        </div>

        {messages.map((message) => {
          return (
            <Message {...message} />
          );
        })}
      </div>

      {/* footer chatbot */}
      <div className="footer">
        <div className="input-box">
          <input type="text" className="message-text" placeholder="Ask me everything"></input>
          <button className="send-btn" onClick={() => {}} />
        </div>

        <div className="link">
          <Icon width={18} height={18} src={LinkIcon} />
          <p className="message-text">Curidy website Link</p>
        </div>

        <div className="link">
          <Icon width={18} height={18} src={LinkIcon} />
          <p className="message-text">Tooltips of Curidy </p>
        </div>
      </div>

    </div>
  );
};

export default ChatBot;
