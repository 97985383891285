import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import IconDeleteRed from 'assets/svgs/v3/ico_red_delete.svg';
import IconWarning from 'assets/svgs/v3/ico_warning.svg';
import { useEffect, useMemo, useState } from 'react';
import Icon from 'components/v2/atoms/Icon';
import LabelInput from 'components/v2/LabelInput';
import './DeleteRule.scss';

interface DeleteRuleModalPropsType extends IBaseModalProps {
  resourceName: string;
  onDelete: () => void;
  subTitle?: string;
  description?: string;
}

const DeleteRuleModal = (props: DeleteRuleModalPropsType) => {
  const { resourceName, onDelete, subTitle, description, ...baseModalProps } = props;
  const [payload, setPayload] = useState<string>('');

  const ableDelete = useMemo(() => {
    if (payload !== 'delete') {
      return true;
    } else return false;
  }, [payload]);

  useEffect(() => {
    if (baseModalProps.open) {
      setPayload('');
    }
  }, [baseModalProps.open]);

  return (
    <BaseModal
      {...baseModalProps}
      title={() => (
        <>
          <Icon width={32} height={32} src={IconDeleteRed} />
          <div className="title-header-modal">{`Delete ${resourceName}`}</div>
        </>
      )}
    >
      <div className="delete-rule-modal">
        <div className={'content-container flex col'}>
          <div className="header">
            <div className="delete-warning">
              <Icon width={32} height={32} src={IconWarning} />
              {!!subTitle ? <p>{subTitle}</p> : <p>Are you sure you want to remove {resourceName}?</p>}
            </div>
            <div className="delete-info">
              {!!description ? description : 'This will remove the selected rules from the rule group.'}
            </div>
          </div>
          <LabelInput
            title="To confirm deletions, write “delete” in this field."
            placeholder="delete"
            value={payload}
            onChangeValue={val => setPayload(val)}
          />
        </div>
        <div className="button-group">
          <button className="big-sub-btn" onClick={baseModalProps.onClose}>
            Cancel
          </button>
          <button className="big-main-btn" disabled={ableDelete} onClick={onDelete}>
            Delete
          </button>
        </div>
      </div>
    </BaseModal>
  );
};
export default DeleteRuleModal;
