import { useLazyQuery } from '@apollo/client';
import query from './query';
import { AwsVpcType } from 'graphql/types/AwsVpc';
import { IGqlResponseNewData } from 'graphql/types';

export interface IAllAwsVpcVariables {
  cloudId: number;
  region: string;
  request: {
    maxResults: number;
    nextToken?: string;
    vpcIds?: string[];
    dryRun?: boolean;
    filters?: {
      name: string;
      values: string[];
    }
  }
}

export interface IAllAwsVpcResponseData {
  getAwsAllVPC: IGqlResponseNewData<AwsVpcType>;
}

const lazyGetAllAwsVpc = () => useLazyQuery<IAllAwsVpcResponseData, IAllAwsVpcVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAllAwsVpc;