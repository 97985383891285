import React, { Fragment, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { IMgdDetailKeyValueProps, IMgdTabProps, TextTypeEnum } from 'layouts/v3/MgdLayout';
import './EBSSnapshotDetail.scss';
import { ColumnType, RowType } from '@Types/v2/Table';
import { EBSSnapshotDetailDetailPropsType } from '../types';
import { DETAIL_TAB_LIST, SNAPSHOT_STATE_ENUM_MAPPING, SUMMARY_TAB_LIST, detailTabKeys, detailTabTextDes } from './Common/Constants';
import TagTab from '../TagTab';
import PageDetailTitle from '../PageDetailTitle';
import DetailTab from '../DetailTab';
import lazyGetAwsDescribeSnapshotAttribute, { IAwsDescribeSnapshotAttributeVariables } from 'graphql/queries/getAwsDescribeSnapshotAttribute';
import SnapshotSetting from './SnapshotSetting';

const EBSSnapshotDetail = (props: EBSSnapshotDetailDetailPropsType) => {
  const { detailData, cloudId, region, pageBackClick } = props;
  
  // API
  const [getAwsDescribeSnapshotAttribute] = lazyGetAwsDescribeSnapshotAttribute();
  // TODO: DescribeFastSnapshotRestoresRequest

  // State
  const [apiIsLoading, setApiIsLoading] = useState<boolean>(false);
  const [productCode, setProductCode] = useState<string>('-');
  const [detailTabSelected, setDetailTabSelected] = useState<IMgdTabProps>(DETAIL_TAB_LIST[0]);

  const snapshotId = useMemo((): string => {
    return detailData?.snapshotId ?? '';
  }, [detailData]);

  const awsDesSnapshotAttribute = useMemo((): IAwsDescribeSnapshotAttributeVariables => {
    return {
      cloudId: cloudId,
      region: region,
      request: {
        attribute: 'productCodes',
        snapshotId: detailData?.snapshotId ?? '',
      },
    };
  }, [detailData, cloudId, region]);

  const fetchSnapshotAttribute = useCallback(async () => {
    return getAwsDescribeSnapshotAttribute({ variables: awsDesSnapshotAttribute }).then(
      ({ data: awsDescribeSnapshotAttributeRes }) => {
        const snapshotAttribute = awsDescribeSnapshotAttributeRes?.getAwsDescribeSnapshotAttribute?.data?.[0];
        const productCode = snapshotAttribute?.productCodes?.[0];
        let productCodeValue = '-';
        if (productCode) {
          productCodeValue = `${productCode.productCodeId} (${productCode.productCodeId})`;
        }
        return productCodeValue;
      },
    );
  }, [awsDesSnapshotAttribute]);

  const fetchDetailData = useCallback(async () => {
    setApiIsLoading(true);
    const [productCodevalue] = await Promise.all([fetchSnapshotAttribute()]);
    setApiIsLoading(false);
    setProductCode(productCodevalue);
  }, []);

  const detailTabContentData = useMemo((): IMgdDetailKeyValueProps[] => {
    const detailTabData: IMgdDetailKeyValueProps[] = [];
    const detailTabDataOrdered: IMgdDetailKeyValueProps[] = [];
    if (detailData) {
      Object.entries(detailData).map(([key, value]) => {
        if (detailTabKeys.indexOf(key) > -1) {
          const textDes = detailTabTextDes.find(text => text.id == key);
          let desValue: string | string[] = '-';

          if (typeof value === 'string') {
            desValue = value.toString();
          }

          if (key === detailTabKeys[3]) {
            desValue = SNAPSHOT_STATE_ENUM_MAPPING[desValue].text;
          }

          if (textDes) {
            detailTabData.push({
              id: key,
              type: textDes.type as TextTypeEnum,
              title: textDes.value,
              description: desValue,
            });
          }
        }
      });

      detailTabData.push({
        id: detailTabKeys[7],
        type: detailTabTextDes[7].type as TextTypeEnum,
        title: detailTabTextDes[7].value,
        description: productCode,
      });

      // TODO: Miss fastSnapshotRestore

      // order data
      detailTabKeys.map(key => {
        const dataByKey = detailTabData.find(data => data.id == key);
        if (dataByKey) detailTabDataOrdered.push(dataByKey);
      });
    }
    return detailTabDataOrdered;
  }, [detailData, productCode]);

  const storageTierTabContentData = useMemo((): IMgdDetailKeyValueProps[] => {
    const storageTierTabData: IMgdDetailKeyValueProps[] = [];
    const storageTierTabDataOrdered: IMgdDetailKeyValueProps[] = [];
    setApiIsLoading(true);
    setApiIsLoading(false);
    return storageTierTabDataOrdered;
  }, []);

  const tagColumns = useMemo((): ColumnType[] => {
    return [
      { label: 'Key', field: 'key', sort: true },
      { label: 'Value', field: 'value', sort: true },
    ];
  }, []);

  const tagRows = useMemo((): RowType[] => {
    return detailData?.tags ?? [];
  }, [detailData]);

  const detailContentNode = useMemo((): ReactNode => {
    switch (detailTabSelected) {
      case DETAIL_TAB_LIST[0]:
        return <SnapshotSetting snapshotId={snapshotId} cloudId={cloudId} region={region} />;
      case DETAIL_TAB_LIST[1]:
        return <DetailTab title={'Storage tier'} data={storageTierTabContentData} isApiLoading={apiIsLoading} />;
      case DETAIL_TAB_LIST[2]:
        return <TagTab title={'Tags'} rows={tagRows} columns={tagColumns} />;
      default:
        break;
    }
  }, [
    detailTabSelected,
    detailTabContentData,
    apiIsLoading,
    snapshotId,
    cloudId,
    region,
    storageTierTabContentData,
    tagRows,
    tagColumns,
  ]);

  useEffect(() => {
    fetchDetailData();
    setDetailTabSelected(DETAIL_TAB_LIST[0]);
  }, [detailData]);

  return (
    <div className="ec2-detail">
      <PageDetailTitle title={snapshotId} pageBackClick={pageBackClick} />
      {detailData && (
        <Fragment>
          <DetailTab title={'Description'} data={detailTabContentData} isApiLoading={apiIsLoading} />
          <div className="tab-container">
            <div className="detail-tab flex a-center">
              {DETAIL_TAB_LIST.map(tab => {
                return (
                  <button
                    className={`detail-tab-items ${detailTabSelected?.id === tab.id && 'active'}`}
                    key={tab.id}
                    data-tab={tab.id}
                    onClick={e => setDetailTabSelected(tab)}
                  >
                    <p>{tab.name}</p>
                  </button>
                );
              })}
            </div>

            <div className="content-tab">{detailContentNode}</div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default EBSSnapshotDetail;
