import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsGetServiceLastAccessedDetailsResponseEntry } from 'graphql/types/AwsGetServiceLastAccessedDetailsResponse';

export interface IAwsServiceLastAccessedDetailsVariables {
  cloudId: number;
  region: string;
  request: {
    jobId: string;
  };
}

export interface IAwsServiceLastAccessedDetailsResponseData {
  getAwsServiceLastAccessedDetails: AwsGetServiceLastAccessedDetailsResponseEntry;
}

const lazyGetAwsServiceLastAccessedDetails = () =>
  useLazyQuery<IAwsServiceLastAccessedDetailsResponseData, IAwsServiceLastAccessedDetailsVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsServiceLastAccessedDetails;
