import { gql } from '@apollo/client';

const query = gql`
query getAwsListRegexPatternSets($request: AwsListRegexPatternSetsRequest, $cloudId: Long, $region: String) {
  getAwsListRegexPatternSets(
    decribeListRegexPatternSetsRequest: $request
    cloudId: $cloudId
    region: $region
  ) {
    result
    data {
      ... on AwsListRegexPatternSetsResponse {
        nextMarker
        regexPatternSets {
          name
          id
          description
          lockToken
          arn
        }
      }
    }
  }
}`;
export default query;