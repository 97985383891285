import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from 'graphql/types';
import { AwsLoggingConfigurationResponse } from 'graphql/types/AwsLoggingConfiguration';

export interface IGetAwsLoggingConfigurationVariables {
  cloudId: number;
  region: string;
  request: {
    resourceArn: string;
  }
}

export interface IAwsLoggingConfigurationResponseData {
  getAwsLoggingConfiguration: IGqlResponseData<AwsLoggingConfigurationResponse>;
}

const lazyGetAwsLoggingConfiguration = () => useLazyQuery<IAwsLoggingConfigurationResponseData, IGetAwsLoggingConfigurationVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsLoggingConfiguration;