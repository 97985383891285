import { gql } from '@apollo/client';

const query = gql`
query getAwsDescribeKeyPairs($request: AwsDescribeKeyPairsRequest, $cloudId: Long, $region: String) {
  getAwsDescribeKeyPairs(describeKeyPairsRequest: $request, cloudId: $cloudId, region: $region) {
    result
    data {
      ... on AwsDescribeKeyPairsResponse {
          keyPairs {
            keyPairId
            keyFingerprint
            keyName
            keyType
            tags {
              key
              value
            }
            publicKey
            createTime
          }
        }
      }
    }
  }
`;
export default query;
