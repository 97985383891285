import React, { useMemo, useState } from 'react';
import { ConfigureMetricsPropsType } from '../type';
import { RadioButtonGroup, SectionContainer, SectionTitle, StepContainer } from '../components';
import DetailTable from 'pages/v2/Organ/Management/EC2/components/DetailTable';
import {
  METRICS_COLUMN_LIST,
  REQUEST_SAMPLING_OPTIONS_RADIO_LIST,
  REQUEST_SAMPLING_ACTION_RADIO_LIST,
  RULES_EXCLUSION_COLUMN_LIST,
} from '../configs';
import { ButtonTypeEnum } from '../components/Button';

const ConfigureMetrics = (props: ConfigureMetricsPropsType) => {
  const { title } = props;

  const [requestSamplingOption, setRequestSamplingOption] = useState(REQUEST_SAMPLING_OPTIONS_RADIO_LIST[0]);
  const [requestSamplingAction, setRequestSamplingAction] = useState(REQUEST_SAMPLING_ACTION_RADIO_LIST[0].value);

  const rulesActionButtons = useMemo(() => {
    return [
      {
        id: 'moveUp',
        label: 'Move up',
        type: ButtonTypeEnum.GENERAL,
        onClick: () => {},
        disabled: true,
      },
      {
        id: 'moveDown',
        label: 'Move down',
        type: ButtonTypeEnum.GENERAL,
        onClick: () => {},
        disabled: true,
      },
    ];
  }, []);

  const exlusionNode = useMemo(() => {
    if (requestSamplingOption?.value !== REQUEST_SAMPLING_OPTIONS_RADIO_LIST[2].value) {
      return null;
    }

    return (
      <>
        <SectionContainer>
          <DetailTable
            title="Rules"
            description="Choose the rules that you want to disable request sampling for"
            data={[]}
            columns={RULES_EXCLUSION_COLUMN_LIST}
          />
        </SectionContainer>
        <SectionContainer
          title="Request sampling for web ACL default action"
          description="Request sampling for web ACL default action"
        >
          <SectionTitle title={'Request sampling for web ACL default action'} />
          <RadioButtonGroup
            data={REQUEST_SAMPLING_ACTION_RADIO_LIST}
            value={requestSamplingAction}
            onChangeValue={setRequestSamplingAction}
          />
        </SectionContainer>
      </>
    );
  }, [requestSamplingOption, requestSamplingAction]);

  return (
    <StepContainer title={title}>
      <SectionContainer>
        <DetailTable
          title={'Amazon CloudWatch metrics'}
          description="CloudWatch metric allow you to monitor web requests, web AGLs, and rules."
          columns={METRICS_COLUMN_LIST}
          data={[]}
          rightButtons={rulesActionButtons}
        />
      </SectionContainer>

      <SectionContainer
        title="Request sampling options"
        description="If you disable request sampling, you can’t view requests that match your web ACL rules."
      >
        <SectionTitle title={'Default action'} />
        <RadioButtonGroup
          data={REQUEST_SAMPLING_OPTIONS_RADIO_LIST}
          value={requestSamplingOption.value}
          onChangeValue={value =>
            setRequestSamplingOption(REQUEST_SAMPLING_OPTIONS_RADIO_LIST.find(option => option.value === value)!)
          }
        />
      </SectionContainer>
      {exlusionNode}
    </StepContainer>
  );
};

export default ConfigureMetrics;
