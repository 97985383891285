import { gql } from '@apollo/client';

const query = gql`
query getAwsDescribeRuleGroup($request: AwsDescribeRuleGroupRequest, $cloudId: Long, $region: String) {
  getAwsDescribeRuleGroup(
    describeRuleGroupRequest: $request
    cloudId: $cloudId
    region: $region
  ) {
    result
    data {
      ... on AwsDescribeRuleGroupResponse {
        updateToken
        ruleGroup {
          ruleVariables {
            ipSets
            portSets
          }
          referenceSets {
            ipSetReferences
          }
          rulesSource {
            rulesString
            rulesSourceList {
              targets
              targetTypes
              generatedRulesType
            }
            statefulRules {
              action
              header {
                protocol
                source
                sourcePort
                direction
                destination
                destinationPort
              }
              ruleOptions {
                keyword
                settings
              }
            }
            statelessRulesAndCustomActions {
              statelessRules {
                ruleDefinition {
                  matchAttributes {
                    sources {
                      addressDefinition
                    }
                    destinations {
                      addressDefinition
                    }
                    sourcePorts {
                      fromPort
                      toPort
                    }
                    destinationPorts {
                      fromPort
                      toPort
                    }
                    protocols
                    tcpFlags {
                      flags
                      masks
                    }
                  }
                  actions
                }
                priority
              }
              customActions {
                actionName
                actionDefinition {
                  publishMetricAction {
                    dimensions {
                      value
                    }
                  }
                }
              }
            }
          }
          statefulRuleOptions {
            ruleOrder
          }
        }
        ruleGroupResponse {
          ruleGroupArn
          ruleGroupName
          ruleGroupId
          description
          type
          capacity
          ruleGroupStatus
          tags {
            key
            value
          }
          consumedCapacity
          numberOfAssociations
          encryptionConfiguration {
            keyId
            type
          }
          sourceMetadata {
            keyId
            type
          }
          snsTopic
          lastModifiedTime
          analysisResults {
            identifiedRuleIds
            identifiedType
            analysisDetail
          }
        }
      }
    }
  }
}`;
export default query;