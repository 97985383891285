import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsDescribeLoadBalancerAttributesResponse } from 'graphql/types/AwsDescribeLoadBalancerAttributesResponse';

export interface IGetAwsDescribeLoadBalancerAttributesVariables {
  cloudId: number;
  region: string;
  request: {
    loadBalancerArn: string;
  };
}

export interface IAwsDescribeLoadBalancerAttributesResponseData {
  getAwsDescribeLoadBalancerAttributes: IGqlResponseNewData<AwsDescribeLoadBalancerAttributesResponse>;
}

const lazyGetAwsDescribeLoadBalancerAttributes = () =>
  useLazyQuery<IAwsDescribeLoadBalancerAttributesResponseData, IGetAwsDescribeLoadBalancerAttributesVariables>(query, {
    fetchPolicy: 'no-cache',
  });
export default lazyGetAwsDescribeLoadBalancerAttributes;
