import { REQUEST_TYPE } from 'pages/v2/Organ/Management/EC2/components/SpotRequest/Common/Constant';
import DetailTab from 'pages/v2/Organ/Management/EC2/components/DetailTab';
import { IMgdDetailKeyValueProps, TextTypeEnum } from 'layouts/v3/MgdLayout';
import { getBetweenTwoDate } from 'utils/Common';
import { FleetInstanceDescription } from '../../../types';
import { useMemo } from 'react';

const Description = (props: FleetInstanceDescription) => {
  const { currentSpotFleetInstance } = props;
  const { spotFleetRequestId, spotFleetRequestConfig, createTime, spotFleetRequestState, activityStatus } =
    currentSpotFleetInstance;

  const {
    targetCapacity,
    fulfilledCapacity,
    spotMaxTotalPrice,
    validFrom,
    onDemandFulfilledCapacity,
    iamFleetRole,
    type,
    validUntil,
    allocationStrategy,
    terminateInstancesWithExpiration,
    instanceInterruptionBehavior,
    spotMaintenanceStrategies,
    launchTemplateConfigs,
    loadBalancersConfig,
  } = spotFleetRequestConfig;

  const launchSpecification = spotFleetRequestConfig?.launchSpecifications?.[0];

  const formatCurrency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const detailsData = useMemo((): IMgdDetailKeyValueProps[] => {
    return [
      {
        title: 'Request ID',
        description: spotFleetRequestId,
      },
      {
        title: 'Total target capacity',
        description: `${targetCapacity}(${fulfilledCapacity} fulfilled)`,
      },
      {
        title: 'Subnet',
        description: launchSpecification?.subnetId,
      },
      {
        title: 'Max price',
        description: formatCurrency.format(parseFloat(spotMaxTotalPrice)),
      },
      {
        title: 'Monitoring',
        description: launchSpecification?.monitoring ? 'Yes' : 'No',
      },
      {
        title: 'Request valid from',
        description: getBetweenTwoDate(validFrom),
      },
      {
        title: 'Request type',
        description: REQUEST_TYPE.FLEET,
      },
      {
        title: 'on-Demand capacity',
        description: onDemandFulfilledCapacity,
      },
      {
        title: 'IAM fleet role',
        description: iamFleetRole,
        type: TextTypeEnum.LINK,
      },
      {
        title: 'Persistance',
        description: type,
      },
      {
        title: 'Tenancy',
        description: launchSpecification?.placement?.tenancy,
      },
      {
        title: 'Request valid until',
        description: getBetweenTwoDate(validUntil),
      },
      {
        title: 'Created',
        description: getBetweenTwoDate(createTime),
      },
      {
        title: 'Allocation strategy',
        description: allocationStrategy,
      },
      {
        title: 'Terminate instances at expiration',
        description: terminateInstancesWithExpiration ? 'yes' : 'no',
      },
      {
        title: 'Key pair name',
        description: launchSpecification?.keyName,
        type: TextTypeEnum.LINK,
      },
      {
        title: 'Interruption behavior',
        description: instanceInterruptionBehavior,
      },
      {
        title: 'Capacity rebalance',
        description: spotMaintenanceStrategies?.capacityRebalance ? 'On' : 'Off',
      },
      {
        title: 'State',
        description: spotFleetRequestState,
      },
      {
        title: 'Instance type(s)',
        description: launchTemplateConfigs?.map(config => config?.overrides?.map(override => override?.instanceType)),
      },
      {
        title: 'Instance replacement strategy',
        description: spotMaintenanceStrategies?.capacityRebalance?.replacementStrategy,
      },
      {
        title: 'IAM role',
        description: launchSpecification?.iamInstanceProfile?.arn,
        type: TextTypeEnum.LINK,
      },
      {
        title: 'Classic load balancers',
        description: loadBalancersConfig?.classicLoadBalancersConfig?.classicLoadBalancers?.map(lb => lb?.name),
      },
      {
        title: 'vCPU requirement',
        description: `${launchSpecification?.instanceRequirements?.vCpuCount?.min || 'no'} minimum, ${
          launchSpecification?.instanceRequirements?.vCpuCount?.max || 'no'
        } maximum`,
      },
      {
        title: 'Status',
        description: activityStatus,
      },
      {
        title: 'AMI ID',
        description: launchSpecification?.imageId,
      },
      {
        title: 'Memory requirement(GiB)',
        description: `${launchSpecification?.instanceRequirements?.memoryMiB?.min || 'no'} minimum, ${
          launchSpecification?.instanceRequirements?.memoryMiB?.max || 'no'
        } maximum`,
      },
      {
        title: 'EBS-optimized',
        description: launchSpecification?.ebsOptimized,
      },
      {
        title: 'Target groups',
        description: loadBalancersConfig?.targetGroupsConfig?.targetGroups?.map(tg => tg?.arn),
      },
    ];
  }, [currentSpotFleetInstance]);

  return <DetailTab title="Description" data={detailsData} />;
};

export default Description;
