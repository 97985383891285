import { gql } from '@apollo/client';

const query = gql`
  query getAwsWebACL($request: AwsGetWebAclRequest, $cloudId: Long, $region: String) {
    getAwsWebACL(decribeGetWebAclRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsGetWebAclResponse {
          webACL {
            name
            id
            arn
            defaultAction {
              block {
                customResponse {
                  responseCode
                  customResponseBodyKey
                  responseHeaders {
                    name
                    value
                  }
                }
              }
              allow {
                customRequestHandling {
                  insertHeaders {
                    name
                    value
                  }
                }
              }
            }
            description
            rules {
              name
              priority
              statement
              action {
                block {
                  customResponse {
                    responseCode
                    customResponseBodyKey
                    responseHeaders {
                      name
                      value
                    }
                  }
                }
                allow {
                  customRequestHandling {
                    insertHeaders {
                      name
                      value
                    }
                  }
                }
                count {
                  customRequestHandling {
                    insertHeaders {
                      name
                      value
                    }
                  }
                }
                captcha {
                  customRequestHandling {
                    insertHeaders {
                      name
                      value
                    }
                  }
                }
                challenge {
                  customRequestHandling {
                    insertHeaders {
                      name
                      value
                    }
                  }
                }
              }
              overrideAction {
                count {
                  customRequestHandling {
                    insertHeaders {
                      name
                      value
                    }
                  }
                }
                none
              }
              ruleLabels {
                name
              }
              visibilityConfig {
                sampledRequestsEnabled
                cloudWatchMetricsEnabled
                metricName
              }
              captchaConfig {
                immunityTimeProperty {
                  immunityTime
                }
              }
              challengeConfig {
                immunityTimeProperty {
                  immunityTime
                }
              }
            }
            visibilityConfig {
              sampledRequestsEnabled
              cloudWatchMetricsEnabled
              metricName
            }
            capacity
            preProcessFirewallManagerRuleGroups {
              name
              priority
              firewallManagerStatement {
                managedRuleGroupStatement {
                  vendorName
                  name
                  version
                  excludedRules {
                    name
                  }
                  scopeDownStatement {
                    byteMatchStatement {
                      searchString {
                        SdkBytes
                      }
                      fieldToMatch {
                        singleHeader {
                          name
                        }
                        singleQueryArgument {
                          name
                        }
                        allQueryArguments
                        uriPath
                        queryString
                        body {
                          oversizeHandling
                        }
                        method
                        jsonBody {
                          matchPattern {
                            all
                            includedPaths
                          }
                          matchScope
                          invalidFallbackBehavior
                          oversizeHandling
                        }
                        headers {
                          matchPattern {
                            includedHeaders
                            all
                            excludedHeaders
                          }
                          matchScope
                          invalidFallbackBehavior
                          oversizeHandling
                        }
                        cookies {
                          matchPattern {
                            all
                            includedCookies
                            excludedCookies
                          }
                          matchScope
                          oversizeHandling
                        }
                        headerOrder {
                          oversizeHandling
                        }
                        ja3Fingerprint {
                          fallbackBehavior
                        }
                      }
                      textTransformations {
                        priority
                        type
                      }
                      positionalConstraint
                    }
                  }
                  managedRuleGroupConfigs {
                    loginPath
                    payloadType
                    usernameField {
                      identifier
                    }
                    passwordField {
                      identifier
                    }
                    awsManagedRulesBotControlRuleSet {
                      inspectionLevel
                      enableMachineLearning
                    }
                    awsManagedRulesATPRuleSet {
                      loginPath
                      requestInspection {
                        payloadType
                        usernameField {
                          identifier
                        }
                        passwordField {
                          identifier
                        }
                      }
                      responseInspection {
                        statusCode {
                          successCodes
                          failureCodes
                        }
                        header {
                          name
                          successValues
                          failureValues
                        }
                        bodyContains {
                          successStrings
                          failureStrings
                        }
                        json {
                          identifier
                          successValues
                          failureValues
                        }
                      }
                      enableRegexInPath
                    }
                    awsManagedRulesACFPRuleSet {
                      creationPath
                      registrationPagePath
                      requestInspection {
                        payloadType
                        usernameField {
                          identifier
                        }
                        passwordField {
                          identifier
                        }
                        emailField {
                          identifier
                        }
                        phoneNumberFields {
                          identifier
                        }
                        addressFields {
                          identifier
                        }
                      }
                      responseInspection {
                        statusCode {
                          successCodes
                          failureCodes
                        }
                        header {
                          name
                          successValues
                          failureValues
                        }
                        bodyContains {
                          successStrings
                          failureStrings
                        }
                        json {
                          identifier
                          successValues
                          failureValues
                        }
                      }
                      enableRegexInPath
                    }
                  }
                  ruleActionOverrides {
                    name
                    actionToUse {
                      block {
                        customResponse {
                          responseCode
                          customResponseBodyKey
                          responseHeaders {
                            name
                            value
                          }
                        }
                      }
                      allow {
                        customRequestHandling {
                          insertHeaders {
                            name
                            value
                          }
                        }
                      }
                      count {
                        customRequestHandling {
                          insertHeaders {
                            name
                            value
                          }
                        }
                      }
                      captcha {
                        customRequestHandling {
                          insertHeaders {
                            name
                            value
                          }
                        }
                      }
                      challenge {
                        customRequestHandling {
                          insertHeaders {
                            name
                            value
                          }
                        }
                      }
                    }
                  }
                }
                ruleGroupReferenceStatement {
                  arn
                  excludedRules {
                    name
                  }
                  ruleActionOverrides {
                    name
                    actionToUse {
                      block {
                        customResponse {
                          responseCode
                          customResponseBodyKey
                          responseHeaders {
                            name
                            value
                          }
                        }
                      }
                      allow {
                        customRequestHandling {
                          insertHeaders {
                            name
                            value
                          }
                        }
                      }
                      count {
                        customRequestHandling {
                          insertHeaders {
                            name
                            value
                          }
                        }
                      }
                      captcha {
                        customRequestHandling {
                          insertHeaders {
                            name
                            value
                          }
                        }
                      }
                      challenge {
                        customRequestHandling {
                          insertHeaders {
                            name
                            value
                          }
                        }
                      }
                    }
                  }
                }
              }
              overrideAction {
                count {
                  customRequestHandling {
                    insertHeaders {
                      name
                      value
                    }
                  }
                }
                none
              }
              visibilityConfig {
                sampledRequestsEnabled
                cloudWatchMetricsEnabled
                metricName
              }
            }
            managedByFirewallManager
            labelNamespace
            customResponseBodies
            captchaConfig {
              immunityTimeProperty {
                immunityTime
              }
            }
            challengeConfig {
              immunityTimeProperty {
                immunityTime
              }
            }
            tokenDomains
          }
          lockToken
          applicationIntegrationURL
        }
      }
    }
  }
`;
export default query;
