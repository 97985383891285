import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { ColumnType, RowType } from '@Types/v2/Table';
import './NetworkMapping.scss';
import { AwsLoadBalancer } from 'graphql/types/AwsLoadBalancer';
import TagTab from '../../TagTab';
import lazyGetAwsDescribeTags, { IGetAwsDescribeTagsVariables } from 'graphql/queries/getAwsDescribeTags';

export type TagPropsType = {
  cloudId: number;
  region: string;
  data?: AwsLoadBalancer;
};

const Tags = (props: TagPropsType) => {
  const { data, cloudId, region } = props;
  const [getAwsDescribeTags, { loading: awsDescribeTagsLoading }] = lazyGetAwsDescribeTags();
  const [rows, setRows] = useState<RowType[]>([]);

  const apiIsLoading = useMemo(() => {
    return awsDescribeTagsLoading;
  }, [awsDescribeTagsLoading]);

  const tagColumns = useMemo((): ColumnType[] => {
    return [
      { label: 'Key', field: 'key', sort: true },
      { label: 'Value', field: 'value', sort: true },
    ];
  }, []);

  const fetchAwsDescribeTags = useCallback(() => {
    const tagsVariable: IGetAwsDescribeTagsVariables = {
      cloudId: cloudId,
      region: region,
      request: {
        resourceArns: [data?.loadBalancerArn || ''],
      },
    };

    getAwsDescribeTags({ variables: tagsVariable }).then(({ data: awsDescribeTags }) => {
      const awsDescribeTagData = awsDescribeTags?.getAwsDescribeTags?.data?.[0]?.tagDescriptions?.[0]?.tags;
      if (awsDescribeTagData) {
        setRows(awsDescribeTagData);
      }
    });
  }, [cloudId, region, data]);

  useEffect(() => {
    fetchAwsDescribeTags();
  }, []);

  return (
    <Fragment>
      {apiIsLoading ? (
        <div className="storage-info">
          <div className="progress-container">
            <div className="progress-gif" />
            Loading ...
          </div>
        </div>
      ) : (
        <TagTab title={'Tags'} rows={rows} columns={tagColumns} />
      )}
    </Fragment>
  );
};

export default Tags;
