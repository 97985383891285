import './index.scss';
import { Fragment, useEffect, useMemo, useState } from 'react';
import lazyGetWebAcl from 'graphql/queries/getAwsWebACL';
import NetworkTitle from 'components/v3/NetworkTitle';
import { AwsWebACL } from 'graphql/types/AwsWebACL';
import RuleDetail from './RuleLayout';
import AssociatedResources from './AssociatedResourcesLayout';
import CustomResponse from './CustomResponseLayout';
import Logging from './LoggingLayout';
import { CONTENT_TYPE_MAPPING } from '../../Commons/Constant';
import { ICustomeResponseBody } from '../../RuleGroup/RuleGroupDetail';
import { RowType } from '@Types/v2/Table';
import CustomResponseModal from '../../CustomResponseModal';

interface IWAFDetailProps {
  cloudId: number;
  region: string;
  id: string;
  name: string;
  pageBackClick: () => void;
}

interface ITabProps {
  id: string;
  name: string;
  title: string;
  description?: string;
}

const webAclDetailTabs: ITabProps[] = [
  {
    id: 'rules',
    name: 'Rules',
    title: 'Rules',
  },
  {
    id: 'associatedAWSResources',
    name: 'Associated AWS resources',
    title: 'Associated AWS resources',
  },
  {
    id: 'customResponseBodies',
    name: 'Custom response bodies',
    title: 'Custom response bodies',
  },
  {
    id: 'loggingAndMetrics',
    name: 'Logging and metrics',
    title: 'Logging and metrics',
  },
];

const WebAclDetail = ({ cloudId, id, region, name, pageBackClick }: IWAFDetailProps) => {
  const [webAcl, setWebAcl] = useState<AwsWebACL>();
  const [activeTab, setActiveTab] = useState<any>(webAclDetailTabs[0]);
  const [lockToken, setLockToken] = useState('');
  const [isEditResponseBodyVisible, setEditResponseBodyModalVisisble] = useState<boolean>(false);
  const [customResponseBodyRows, setCustomResponseBodyRows] = useState<ICustomeResponseBody[]>([]);
  const [responseBodyRowSelected, setResponseBodyRowSelected] = useState<string>('');

  const handleActiveTabClick = (e: any) => {
    const tabTarget = e.currentTarget;
    const tab = {
      id: tabTarget.getAttribute('data-tab'),
      title: tabTarget.textContent,
    };
    setActiveTab(tab);
  };

  const [getWebAcl] = lazyGetWebAcl();

  const getWebAclData = useMemo(
    () => ({
      cloudId: cloudId,
      region: region !== 'CloudFront' ? String(region) : 'us-east-1',
      request: {
        id: id,
        name: name,
        scope: region !== 'CloudFront' ? 'REGIONAL' : 'CLOUDFRONT',
      },
    }),
    [cloudId, region, id, name],
  );

  const getWebACLs = () => {
    if (getWebAclData.region) {
      getWebAcl({ variables: getWebAclData }).then(({ data }) => {
        if (data) {
          const webAcl = data.getAwsWebACL.data?.[0]?.webACL;
          if (webAcl) {
            setWebAcl(webAcl);
            setLockToken(data.getAwsWebACL.data?.[0]?.lockToken);
          }
        }
      });
    }
  };

  const responseBodyRows = useMemo((): RowType[] => {
    const customResBodyMapped: ICustomeResponseBody[] = [];
    if (webAcl?.customResponseBodies) {
      Object.entries(webAcl?.customResponseBodies).map(([key, value]) => {
        const customeBody = {
          id: key,
          name: key,
          responseBody: value.content,
          contentType: value.contentType,
          cellLableContentType: CONTENT_TYPE_MAPPING[value.contentType].cellLable,
        };
        customResBodyMapped.push(customeBody);
      });
      setCustomResponseBodyRows(customResBodyMapped);
    }
    return customResBodyMapped;
  }, [webAcl]);

  const responseBodySelected = useMemo((): ICustomeResponseBody => {
    return customResponseBodyRows.find(row => row.id === responseBodyRowSelected)!;
  }, [customResponseBodyRows, responseBodyRowSelected]);

  const renderShowing = useMemo(() => {
    if (webAcl) {
      switch (activeTab.id) {
        default:
          return (
            <RuleDetail
              webAcl={webAcl}
              cloudId={cloudId}
              region={region}
              lockToken={lockToken}
              getWebACLs={getWebACLs}
            />
          );
        case 'associatedAWSResources':
          return <AssociatedResources cloudId={cloudId} region={region} webACLArn={webAcl.arn} />;
        case 'customResponseBodies':
          return (
            <CustomResponse
              customResponseBodyRows={responseBodyRows}
              onSelectedCustomReponseBody={setResponseBodyRowSelected}
              onEditResponseBody={() => setEditResponseBodyModalVisisble(true)}
            />
          );
        case 'loggingAndMetrics':
          return <Logging cloudId={cloudId} region={region} webAcl={webAcl} />;
      }
    }
  }, [activeTab, webAcl]);

  useEffect(() => {
    getWebACLs();
  }, [getWebAclData]);

  return (
    <Fragment>
      <div id="webacl-detail">
      <div className="row-1 flex j-between a-center">
        <div className="flex j-start a-center" id="title">
          <NetworkTitle
            pageTitle={'WAF: ' + name}
            id={321}
            name={'WAF: ' + name}
            hasPrefixIcon={false}
            hasFavorite={false}
            pageBackClick={pageBackClick}
          />
        </div>
      </div>
      <div className="row-2 flex a-center">
        {webAclDetailTabs.map(tab => {
          return (
            <button
              className={`tab-items ${activeTab?.id === tab.id && 'active'}`}
              key={tab.id}
              data-tab={tab.id}
              onClick={e => handleActiveTabClick(e)}
            >
              <p>{tab.name}</p>
            </button>
          );
        })}
      </div>
      {renderShowing}
    </div>
    {isEditResponseBodyVisible ? (
      <CustomResponseModal
        header={`Edit ${responseBodySelected.name}`}
        name={responseBodySelected.name}
        contentType={responseBodySelected.contentType}
        responseBody={responseBodySelected.responseBody}
        open={isEditResponseBodyVisible}
        onClose={() => setEditResponseBodyModalVisisble(false)}
        onSave={() => {}}
      />
    ) : null}
    </Fragment>
  );
};
export default WebAclDetail;
