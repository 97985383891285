import React, { useMemo, useCallback } from 'react';
import { DescriptionPropsType } from '../types';
import DetailTab from '../../DetailTab';
import { TextTypeEnum } from 'layouts/v3/MgdLayout';
import { useNavigate } from 'react-router-dom';
import { ManagementTypeEnum } from 'pages/v2/Organ/Management';

const Description = (props: DescriptionPropsType) => {
  const { instance, isSummaryOnly, isSummaryHidden, projectId } = props;

  const navigate = useNavigate();

  const handleItemClicked = useCallback(
    (screen: string, tabId: string, key: string, value: string) => {
      navigate('/organ/1/manage/network', {
        replace: true,
        state: {
          projectId,
          managementType: screen || ManagementTypeEnum.NETWORK,
          tabId: tabId,
          key: key,
          value: value,
        },
      });
    },
    [projectId],
  );

  const summary = useMemo(() => {
    const {
      instanceId,
      publicIpAddress,
      privateIpAddress,
      ipv6Address,
      state,
      publicDnsName,
      privateDnsName,
      privateDnsNameOptions,
      instanceType,
      vpcId,
      iamInstanceProfile,
      subnetId,
    } = instance ?? {};

    return [
      { title: 'Instance ID', type: TextTypeEnum.COPY, description: instanceId ?? '-' },
      { title: 'Public IPv4 address', description: publicIpAddress ?? '-' },
      { title: 'Private IPv4 address', description: privateIpAddress ?? '-' },
      { title: 'IPv6 address', description: ipv6Address ?? '-' },
      { title: 'Instance state', description: state?.name ?? '-' },
      { title: 'Public IPv4 DNS', description: publicDnsName ?? '-' },
      { title: 'Hostname type', description: privateDnsNameOptions?.hostnameType ?? '-' },
      { title: 'Private IP DNS name(IPv4 only)', description: privateDnsName ?? '-' },
      {
        title: 'Answer private resource DNS name',
        description: privateDnsNameOptions?.enableResourceNameDnsARecord?.toString() ?? '-', // TODO:
      },
      { title: 'Instance type', type: TextTypeEnum.LINK, description: instanceType ?? '-' },
      { title: 'Elastic IP addresses', description: '-' },
      {
        title: 'VPC ID',
        type: TextTypeEnum.COPY_LINK,
        description: vpcId ?? '-',
        handleItemClick: () => handleItemClicked(ManagementTypeEnum.NETWORK, 'vpc', 'vpc-id', vpcId ?? ''),
      },
      { title: 'Iam Role', type: TextTypeEnum.COPY_LINK, description: iamInstanceProfile?.arn ?? '-' }, // TODO:
      {
        title: 'Subnet ID',
        type: TextTypeEnum.COPY_LINK,
        description: subnetId ?? '-',
        handleItemClick: () => handleItemClicked(ManagementTypeEnum.NETWORK, 'subnet', 'subnet-id', subnetId ?? ''),
      },
      { title: 'Auto scaling group name', type: TextTypeEnum.COPY_LINK, description: '-' }, // TODO:
      { title: 'IMDSv2', description: '-' }, // TODO:
    ];
  }, [instance]);

  const detail = useMemo(() => {
    const {
      platformDetails,
      imageId,
      monitoring,
      launchTime,
      maintenanceOptions,
      instanceLifecycle,
      amiLaunchIndex,
      keyName,
      stateReason,
      usageOperation,
      ramdiskId,
      enclaveOptions,
      bootMode,
      currentInstanceBootMode,
      metadataOptions,
      privateDnsNameOptions,
    } = instance ?? {};

    return [
      { title: 'Platform', type: TextTypeEnum.COPY, description: platformDetails ?? '-' },
      { title: 'AMI ID', type: TextTypeEnum.COPY_LINK, description: imageId ?? '-' },
      { title: 'Monitoring', description: monitoring?.state ?? '-' },
      { title: 'Platform details', type: TextTypeEnum.COPY, description: platformDetails ?? '-' },
      { title: 'AMI name', type: TextTypeEnum.COPY, description: '-' }, // TODO: DescribeImagesResponse.images() tag key = Name
      { title: 'Termination protection', description: '-' }, // TODO: DescribeInstanceAttributeResponse.disableApiTermination()
      { title: 'Stop protection', description: '-' }, // TODO: DescribeInstanceAttributeResponse.disableApiStop()
      { title: 'Launch time', type: TextTypeEnum.COPY, description: launchTime ?? '-' },
      { title: 'AMI location', type: TextTypeEnum.COPY, description: '-' }, // TODO: DescribeImagesResponse.images().imageLocation()
      { title: 'Instance auto recovery', description: maintenanceOptions?.autoRecovery ?? '-' },
      { title: 'Lifecycle', description: instanceLifecycle ?? '-' },
      { title: 'Stop-hibernate behavior ', description: '-' }, // TODO: DescribeInstanceAttributeResponse.disableApiStop()
      { title: 'AMI launch index', description: amiLaunchIndex ?? '-' },
      { title: 'Key pair assigned at launch', description: keyName ?? '-' },
      { title: 'State transition reason', description: '-' },
      { title: 'Credit specification', description: '-' },
      { title: 'Kernel ID', description: '-' },
      { title: 'State trasition message', description: stateReason?.message ?? '-' },
      { title: 'Usage operation', description: usageOperation ?? '-' },
      { title: 'RAM disk ID', description: ramdiskId ?? '-' },
      { title: 'Owner', type: TextTypeEnum.COPY, description: '-' }, // TODO:  DescribeInstancesResponse.reservations().ownerId()
      { title: 'Enclaves support', description: enclaveOptions?.enabled ?? '-' },
      { title: 'Boot mode', description: bootMode ?? '-' },
      { title: 'Current instance boot mode', type: TextTypeEnum.COPY, description: currentInstanceBootMode ?? '-' },
      { title: 'Allow tags in instance metadata', description: metadataOptions?.instanceMetadataTags ?? '-' },
      {
        title: 'Use RBN as guest OS hostname',
        type: TextTypeEnum.COPY,
        description: privateDnsNameOptions?.hostnameType ?? '-',
      },
      {
        title: 'Answer RBN DNS hostname IPv4',
        description: privateDnsNameOptions?.enableResourceNameDnsARecord ?? '-',
      },
    ];
  }, [instance]);

  const hostAndPlacementGroup = useMemo(() => {
    const { placement, virtualizationType, cpuOptions } = instance ?? {};
    const { hostId, affinity, groupName, tenancy, groupId, partitionNumber } = placement ?? {};

    return [
      { title: 'Host ID', description: hostId ?? '-' },
      { title: 'Affinity', description: affinity ?? '-' },
      { title: 'Placement group', description: groupName ?? '-' },
      { title: 'Host resource group name', description: '-' }, // TODO: GetGroupResponse.group().name()
      { title: 'Tenancy', type: TextTypeEnum.COPY, description: tenancy ?? '-' },
      { title: 'Placement group ID', description: groupId ?? '-' },
      { title: 'Virtualization type', type: TextTypeEnum.COPY, description: virtualizationType ?? '-' },
      { title: 'Reservation', type: TextTypeEnum.COPY, description: '-' }, // TODO: DescribeInstancesResponse.reservations().reservationId()
      { title: 'Partition number', description: partitionNumber ?? '-' },
      { title: 'Number of vCPUs', description: cpuOptions?.threadsPerCore ?? '-' },
    ];
  }, [instance]);

  const capacityReservation = useMemo(() => {
    const { capacityReservationId, capacityReservationSpecification } = instance ?? {};

    return [
      { title: 'Capacity Reservation ID', description: capacityReservationId ?? '-' },
      {
        title: 'Capacity Reservation setting',
        description: capacityReservationSpecification?.capacityReservationPreference ?? '-',
      },
    ];
  }, [instance]);

  return (
    <>
      {!isSummaryHidden && <DetailTab title={'Instance summary'} data={summary} />}
      {!isSummaryOnly && (
        <>
          <DetailTab title={'Instance detail'} data={detail} />
          <DetailTab title={'Host and placement group'} data={hostAndPlacementGroup} />
          <DetailTab title={'Capacity reservation'} data={capacityReservation} />
        </>
      )}
    </>
  );
};

export default Description;
