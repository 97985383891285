import { gql } from '@apollo/client';

const query = gql`
query getAwsDescribeTags($request: AwsDescribeTagsRequest, $cloudId: Long, $region: String) {
  getAwsDescribeTags(describeTagsRequest: $request, cloudId: $cloudId, region: $region) {
    result
    data {
      ... on AwsDescribeTagsResponse {
          tagDescriptions {
            resourceArn
            tags {
              key
              value
            }
          }
        }
      }
    }
  }
`;
export default query;