import { gql } from '@apollo/client';

const query = gql`
  query getAwsAllListSecrets($request: AwsListSecretsRequest, $cloudId: Long, $region: String) {
    getAwsAllListSecrets(listSecretsRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsSecretListEntry {
          arn
          name
          description
          kmsKeyId
          rotationEnabled
          rotationLambdaARN
          rotationRules {
            automaticallyAfterDays
            duration
            scheduleExpression
          }
          lastRotatedDate
          lastChangedDate
          lastAccessedDate
          deletedDate
          nextRotationDate
          tags {
            key
            value
          }
          secretVersionsToStages
          owningService
          createdDate
          primaryRegion
        }
      }
    }
  }
`;
export default query;
