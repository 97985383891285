import { useLazyQuery } from '@apollo/client';
import query from './query';
import { AwsGetTagsResponse } from 'graphql/types/AwsGetTags';

export interface IAwsGetTagsVariables {
  cloudId: number;
  region: string;
  request: {
    arn: string;
  }
}

export interface IAwsGetTagsResponseData {
  getAwsTags: AwsGetTagsResponse;
}

const lazyGetAwsTags = () => useLazyQuery<IAwsGetTagsResponseData, IAwsGetTagsVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsTags;