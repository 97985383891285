import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import lazyGetAwsDescribeImageAttribute, {
  IAwsDescribeImageAttributeVariables,
} from 'graphql/queries/getAwsDescribeImageAttribute';
import './Permission.scss';

export type PermissionPropsType = {
  cloudId: number;
  region: string;
  imageId: string;
};

const Permission = (props: PermissionPropsType) => {
  const { imageId, cloudId, region } = props;

  // API
  const [getAwsDescribeImageAttribute] = lazyGetAwsDescribeImageAttribute();

  // State
  const [apiIsLoading, setApiIsLoading] = useState<boolean>(false);
  const [imageSharedPermision, setImageSharedPermision] = useState<string>('');

  const requestVariable = useMemo((): IAwsDescribeImageAttributeVariables => {
    return {
      cloudId: cloudId,
      region: region,
      request: {
        imageId: imageId,
        attribute: 'launchPermission',
      },
    };
  }, [region, cloudId, imageId]);

  const getImageSharedPermision = useCallback(() => {
    setApiIsLoading(true);
    getAwsDescribeImageAttribute({ variables: requestVariable }).then(({ data: imageAttributeRes }) => {
      const imageAttributeDate = imageAttributeRes?.getAwsDescribeImageAttribute?.data?.[0];

      if (imageAttributeDate) {
        const permissionGroupAll = imageAttributeDate?.launchPermissions?.find(
          launch => launch.group === 'PermissionGroup.ALL',
        );

        setImageSharedPermision(!!permissionGroupAll ? 'Public' : 'Private');
      } else {
        setImageSharedPermision('-');
      }
      setApiIsLoading(false);
    });
  }, [requestVariable]);

  useEffect(() => {
    getImageSharedPermision();
  }, []);

  return (
    <Fragment>
      {apiIsLoading ? (
        <div className="permission-info">
          <div className="progress-container">
            <div className="progress-gif" />
            Loading ...
          </div>
        </div>
      ) : (
        <Fragment>
          <div className="permission-info">
            <div className="permission">
              <p className="title">Image share permission</p>
              <p className="value">{imageSharedPermision}</p>
            </div>
          </div>

          <div className="shared-account-info">
            <p className="title">Shared accounts</p>
            <div className="shared-container">
              <p className="row">Shared account ID</p>

              <div className="item">No item</div>
            </div>
          </div>

          <div className="shared-organization-info">
            <p className="title">Shared organization /OU ARNs</p>
            <div className="shared-container">
              <p className="row">Shared organization /OU ARNs</p>

              <div className="item">No item</div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default Permission;
