import React, { useCallback, useMemo, useState } from 'react';
import {
  BODY_CONTENT_TO_INSPECT,
  CONTENT_TO_INSPECT,
  CONTENT_TYPE,
  DEFAULT_REQUEST_AGGREGATION_KEY_ITEM,
  HEADERS_MATCH_SCOPE,
  IP_ADDRESS_SELECTOR,
  JA3_FINGERPRINT,
  JSON_MATCH_SCOPE,
  JSON_REQUEST,
  MATCH_SCOPE,
  MISSING_IP_ADDRESS,
  POSITION_INSIDE_HEADER,
  RULE_BUILDER_REQUEST_CONDITION_DATA,
  RULE_EDITOR_SELECTOR,
  RULE_SUB_TYPE_SELECTOR,
  RuleEditorSelectorEnum,
} from 'pages/v2/Organ/Management/WAF/CustomRuleModal/constant';
import VisualEditor from 'pages/v2/Organ/Management/WAF/CustomRuleModal/RuleBuilder/VisualEditor';
import Json from 'pages/v2/Organ/Management/WAF/CustomRuleModal/RuleBuilder/Json';
import { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import _ from 'lodash';
import { AwsRuleGroup } from 'graphql/types/AwsRuleGroup';
import { INSPECTION_AND_RATE_LIMIT, REQUEST_AGGREGATION } from './VisualEditor/RateBased/constant';
import { convertKeysToPascalCase } from 'utils/Common';

type RuleBuilderPropsType = {
  cloudId: number;
  selectedRegion: DropdownListDataType;
  setRule: (rule: any) => void;
  rule: any;
};

const RuleBuilder = (props: RuleBuilderPropsType) => {
  const { cloudId, selectedRegion, setRule, rule } = props;
  const [ruleEditorSelector, setRuleEditorSelector] = useState(RULE_EDITOR_SELECTOR[0].value);

  // Regular
  const [dropdownConditionRequestValue, setDropdownConditionRequestValue] = useState<DropdownListDataType>(
    RULE_BUILDER_REQUEST_CONDITION_DATA[0],
  );
  const [seconds, setSeconds] = useState('300');
  const [isCustomImmunityTime, setIsCustomImmunityTime] = useState(false);

  // RateBased
  const [inspectionAndRateLimit, setInspectionAndRateLimit] = useState(INSPECTION_AND_RATE_LIMIT[0].value);

  const [requestAggregationKeyData, setRequestAggregationKeyData] = useState([
    { ...DEFAULT_REQUEST_AGGREGATION_KEY_ITEM },
  ]);

  const handleChangeRuleValue = useCallback(
    (path: string, value: any) => {
      let newRule: any = { ...rule };
      _.set(newRule, path, value);
      setRule(newRule);
    },
    [rule],
  );

  const jsonRule = useMemo(() => {
    return convertKeysToPascalCase(rule);
  }, [rule]);

  const ruleEditorNode = useMemo(() => {
    switch (ruleEditorSelector) {
      case RuleEditorSelectorEnum.VISUAL:
        return (
          <VisualEditor
            cloudId={cloudId}
            selectedRegion={selectedRegion}
            rule={rule}
            handleChangeRuleValue={handleChangeRuleValue}
            dropdownConditionRequestValue={dropdownConditionRequestValue}
            seconds={seconds}
            setDropdownConditionRequestValue={setDropdownConditionRequestValue}
            setSeconds={setSeconds}
            isCustomImmunityTime={isCustomImmunityTime}
            setIsCustomImmunityTime={setIsCustomImmunityTime}
            inspectionAndRateLimit={inspectionAndRateLimit}
            setInspectionAndRateLimit={setInspectionAndRateLimit}
            requestAggregationKeyData={requestAggregationKeyData}
            setRequestAggregationKeyData={setRequestAggregationKeyData}
          />
        );

      case RuleEditorSelectorEnum.JSON:
        return <Json json={jsonRule || {}} />;

      default:
        return null;
    }
  }, [
    cloudId,
    selectedRegion,
    rule,
    dropdownConditionRequestValue,
    seconds,
    isCustomImmunityTime,
    inspectionAndRateLimit,
    requestAggregationKeyData,
    handleChangeRuleValue,
    ruleEditorSelector,
  ]);

  return (
    <>
      <div className="rule-container-group">
        <p className="rule-container-group-title">Rule group</p>

        <div className="flex">
          <p className="rule-container-group-description">
            You can use the JSON editor for complex statement nesting, for example to nest two OR statements inside an
            AND statement. The visual editor handles one level of nesting. For web ACLs and rule groups with complex
            nesting, the visual editor is disabled.
          </p>

          <div className="rule-container-group-action">
            {RULE_EDITOR_SELECTOR.map(({ id, value, label }) => (
              <button
                key={id}
                className={value === ruleEditorSelector ? 'active' : ''}
                onClick={() => setRuleEditorSelector(value)}
              >
                {label}
              </button>
            ))}
          </div>
        </div>
      </div>

      {ruleEditorNode}
    </>
  );
};

export default RuleBuilder;
