import { gql } from '@apollo/client';

const query = gql`
  query getResourcePageByParam($reqData: ReqGetResourcePage) {
    getResourcePageByParam(reqData: $reqData) {
      result
      first
      last
      number
      numberOfElements
      size
      totalElements
      totalPages
      content {
        ... on Resource {
          id
          relObjectId
          relObjectType
          resFormatCode
          resPath
          useYn
          name
          url
          createdAt
          createdBy
          createdByName
          createdByUserId
          modifiedAt
          modifiedBy
          modifiedByName
          modifiedByUserId
        }
      }
    }
  }
`;
export default query;
