import { useLazyQuery } from '@apollo/client';
import query from './query';
import { AwsGetPublicKeyResponse } from 'graphql/types/AwsPublicKey';

export interface IAwsPublicKeyVariables {
  cloudId: number;
  region: string;
  request: {
    keyId: string;
  }
}

export interface IAwsPublicKeyResponseData {
  getAwsPublicKey: AwsGetPublicKeyResponse;
}

const lazyGetAwsPublicKey = () => useLazyQuery<IAwsPublicKeyResponseData, IAwsPublicKeyVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsPublicKey;