import { useCallback, useEffect, useMemo, useState } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import RadioAtom from 'components/v2/atoms/RadioAtom';
import { AWS_RESOURCE_CLOUDFRONT_TYPE_DATA, AWS_RESOURCE_REGIONAL_TYPE_DATA } from '../../Commons/Constant';
import Table from 'components/v2/dataDisplay/Table';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import lazyGetAwsLoadBalancers from 'graphql/queries/getAwsLoadBalancers';
import LabelInput from 'components/v2/LabelInput';
import { AwsLoadBalancer } from 'graphql/types/AwsLoadBalancer';

import './index.scss';
import lazyGetAwsListDistributions from 'graphql/queries/getAwsListDistributions';
import { AwsDistributionListType, AwsDistributionSummaryType } from 'graphql/types/AwsListDistributionsByWebACLId';

interface ISelectResourcesModalProps extends IBaseModalProps {
  cloudId: number;
  region: string;
  title: () => JSX.Element;
  onAdd: (distributionList: Array<RowType>) => void;
}

const SelectResourcesModal = ({ cloudId, region, title, onAdd, ...baseModalProps }: ISelectResourcesModalProps) => {
  const isRegional = region !== 'cloudFront';

  // API
  const [getAwsLoadBalancers] = lazyGetAwsLoadBalancers();
  const [getAwsListDistributions] = lazyGetAwsListDistributions();

  // State
  const [resourceType, setResourceType] = useState<string>(
    isRegional ? AWS_RESOURCE_REGIONAL_TYPE_DATA[0].value : AWS_RESOURCE_CLOUDFRONT_TYPE_DATA[0].value,
  );
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [rows, setRows] = useState<RowType[]>([]);
  const [valueArn, setValueArn] = useState<string>('');
  const [tablePagination, setTablePagination] = useState({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });
  const [distributions, setDistributions] = useState<Array<AwsDistributionSummaryType>>([]);

  const updateTablePagination = useCallback((key: string, value: any) => {
    setTablePagination(prevState => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  }, []);

  const columns: ColumnType[] = useMemo(() => {
    return [
      {
        label: 'Name',
        field: 'loadBalancerName',
      },
    ];
  }, [rows]);

  const ableAdd = useMemo(() => {
    if (isRegional) {
      if (resourceType === AWS_RESOURCE_REGIONAL_TYPE_DATA[0].value) {
        return !checkedList?.length;
      }

      return !valueArn;
    }

    return !checkedList?.length;
  }, [checkedList, resourceType, valueArn]);

  const getDistributions = useCallback(() => {
    const variables = {
      cloudId,
      region,
      request: {},
    };

    getAwsListDistributions({ variables }).then(res => {
      setDistributions(res?.data?.getAwsListDistributions?.data?.[0]?.distributionList?.items ?? []);
    });
  }, [region, cloudId, getAwsListDistributions]);

  const distributionRows = useMemo(() => {
    return distributions?.map(distribution => {
      const { id, domainName } = distribution;

      return {
        id,
        name: `${id} - ${domainName}`,
      };
    });
  }, [distributions]);

  useEffect(() => {
    if (!baseModalProps.open) {
      return;
    }

    setResourceType(isRegional ? AWS_RESOURCE_REGIONAL_TYPE_DATA[0].value : AWS_RESOURCE_CLOUDFRONT_TYPE_DATA[0].value);
  }, [baseModalProps.open, isRegional]);

  const getAwsLoadBalancerRows = useCallback(() => {
    getAwsLoadBalancers({
      variables: {
        cloudId: cloudId,
        region: region,
        request: {
          pageSize: 100,
        },
      },
    }).then(res => {
      if (res?.data?.getAwsLoadBalancers?.data[0]?.loadBalancers?.length) {
        const arr = res?.data?.getAwsLoadBalancers?.data[0]?.loadBalancers.map((e: AwsLoadBalancer) => ({
          ...e,
          id: (Math.random() + 1).toString(36).substring(2, 8),
        }));

        setRows(arr);
      }
    });
  }, [getAwsLoadBalancers, cloudId, region]);

  useEffect(() => {
    if (!baseModalProps.open) return;

    if (isRegional) {
      if (resourceType === AWS_RESOURCE_REGIONAL_TYPE_DATA[0].value) {
        getAwsLoadBalancerRows();
        setValueArn('');
        return;
      }

      // setRows([]);
      // setCheckedList([]);
      return;
    }

    getDistributions();
  }, [getDistributions, getAwsLoadBalancerRows, isRegional, resourceType, baseModalProps.open]);

  const handleClearState = () => {
    setResourceType(isRegional ? AWS_RESOURCE_REGIONAL_TYPE_DATA[0].value : AWS_RESOURCE_CLOUDFRONT_TYPE_DATA[0].value);
    setCheckedList([]);
    setRows([]);
    setValueArn('');
  };

  const handleOnClose = () => {
    handleClearState();
    baseModalProps?.onClose?.();
  };

  const handleOnAdd = useCallback(() => {
    if (resourceType === 'manuallyArnInput') {
      onAdd(rows?.filter(row => row.loadBalancerArn === valueArn) ?? []);
      
      return;
    }

    onAdd(rows?.filter(row => checkedList.includes(row?.id?.toString() ?? '')) ?? []);
  }, [distributions, checkedList, valueArn, rows]);

  const renderRegional = () => {
    return (
      <>
        <div className="title">
          <p>Resource type</p>

          <p>Select the resource type and then select the resource you want to associate with this web ACL.</p>
        </div>

        {AWS_RESOURCE_REGIONAL_TYPE_DATA.map(resource => {
          return (
            <RadioAtom
              key={resource.id}
              label={resource.label}
              value={resource.value}
              name={resource.name}
              checked={resourceType}
              onChange={setResourceType}
            />
          );
        })}

        {resourceType === AWS_RESOURCE_REGIONAL_TYPE_DATA[0].value ? (
          <div className="resources-table">
            <p>Select the resources you want to associate with the web ACL.</p>
            {rows?.length ? (
              <div className="data-grid-wrap">
                <Table
                  rows={rows}
                  columns={columns}
                  reportCheckedList={(list: string[]) => {
                    setCheckedList(list);
                  }}
                  // reportSelected={() => setCheckedList([])}
                />
              </div>
            ) : (
              <div className="data-grid-wrap">
                <p className="empty-row">Empty</p>
              </div>
            )}
          </div>
        ) : (
          <>
            <div className="title">
              <p>Input ARN to associate with the web ACL</p>

              <p>
                It support ARN of Amazon API Gateway REST API, AWS AppSync GraphQL API, Amazon Cognito user pool, AWS
                Verified Access.
              </p>
            </div>

            <LabelInput
              title=""
              id="arn-id"
              placeholder="eg) arn:aws:xxx"
              value={valueArn}
              onChangeValue={(value: string) => setValueArn(value)}
            />
          </>
        )}
      </>
    );
  };

  const renderCloudFront = () => {
    return (
      <>
        <div className="title">
          <p>Resource type</p>
          <p>Select the resource type and then select the resource you want to associate with this web ACL.</p>
        </div>

        {AWS_RESOURCE_CLOUDFRONT_TYPE_DATA.map(resource => {
          return (
            <RadioAtom
              key={resource.id}
              label={resource.label}
              value={resource.value}
              name={resource.name}
              checked={resourceType}
              onChange={setResourceType}
            />
          );
        })}

        <div className="resources-table">
          <p>Select the resources you want to associate with the web ACL.</p>

          <div className="data-grid-wrap">
            <Table
              rows={distributionRows}
              sortOption={{
                target: tablePagination.target,
                direction: tablePagination.direction,
                onChangeSort: (target: string, dir: OrderDirection) => {
                  updateTablePagination('target', target);
                  updateTablePagination('direction', dir);
                },
              }}
              columns={[
                {
                  label: 'Name',
                  field: 'name',
                  sort: true,
                },
              ]}
              reportCheckedList={(list: string[]) => {
                setCheckedList(list);
              }}
              // reportSelected={() => setCheckedList([])}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <BaseModal {...baseModalProps} title={title} onClose={handleOnClose}>
      <div className="select-resources-modal">
        <div className={`row-1 flex col`}>{isRegional ? renderRegional() : renderCloudFront()}</div>

        <div className="row-2">
          <div className="button-group">
            <button className="big-sub-btn" onClick={handleOnClose}>
              Cancel
            </button>
            <button className="big-main-btn" disabled={ableAdd} onClick={handleOnAdd}>
              Add
            </button>
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default SelectResourcesModal;
