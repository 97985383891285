import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import LoginComp from 'components/LoginComp';
import SignUp from './pages/Auth/SignUp';
import WaitForEmail from './pages/Auth/WaitForEmail';
import FindPassword from './pages/Auth/FindPassword';
import ChangePassword from './pages/Auth/ChangePassword';
import Layouts from './layouts/MainLayout';
import WeeklyReports from './pages/Monitor/WeeklyReport';
import Settings from './pages/Monitor/Event/Settings';
import History from './pages/Monitor/Event/History';
import DefaultInfo from './pages/Projects/DefaultInfo';
import CompanyOperate from './pages/Projects/Operate/CompanyOperate';
import MessageOperate from './pages/Projects/Operate/MessageOperate';
import MonthlyReports from './pages/Monitor/MonthlyReport';
import EmailVerify from 'pages/Auth/EmailVerify';
import ChangePwDone from 'pages/Auth/ChangePwDone';
import Terms from 'pages/Auth/Terms';
import SignUpDone from 'pages/Auth/SignUpDone';
import withSplitting from './withSplitting';
import AuthLayoutv2 from 'layouts/v2/AuthLayout';
import LoginPage from 'pages/v2/Auth/Login';
import Signup from 'pages/v2/Auth/Signup';
import SignupVerify from 'pages/v2/Auth/SignupVerify';
import ResetPw from 'pages/v2/Auth/ResetPw';
import ResetPwVerify from 'pages/v2/Auth/ResetPwVerify';
import OrganLayout from 'layouts/v2/OrganLayout';
import SendEmail from 'pages/v2/Auth/Signup/SendEmail';
import InviteProject from 'pages/v2/InviteProject';
import JoinOrgan from 'pages/v2/JoinOrgan';
import Project from 'pages/v2/Organ/Project';
import Favorite from 'pages/v2/Organ/Favorite';
import WeeklyReport from 'pages/v2/Organ/Report/WeeklyReport';
import MonthlyReport from 'pages/v2/Organ/Report/MonthlyReport';
import EventHistory from 'pages/v2/Organ/Report/EventHistory';
import SelectOrgan from 'pages/v2/SelectOrgan';
import ManageLayout from 'layouts/v2/ManageLayout';
import Event from 'pages/v2/Organ/Manage/Event'
import Analysis from 'pages/v2/Organ/Manage/Analysis';
import Users from 'pages/v2/Organ/Manage/Users';
import CommonInformation from 'pages/v2/Organ/Manage/CommonInformation';
import PaymentLayout from 'layouts/v2/PaymentLayout';
import Plan from 'pages/v2/Payment/Plan';
import CardManagement from 'pages/v2/Payment/CardManagement';
import Inquiry from 'pages/v2/Inquiry';
import Receipt from 'pages/v2/Payment/Receipt';
import Preview from 'pages/v2/Policy';
import Landing from 'pages/v2/Landing';
import PageNotFound from 'pages/v2/PageNotFound';
import EmptyPage from 'pages/v2/Organ/Project/components/Empty';
import VerifyUpdEmailToken from 'pages/v2/Auth/VerifyUpdEmailToken';
import VerifyInvitedMemberToken from 'pages/v2/Auth/VerifyInvitedMemberToken';
import Policy from 'pages/v2/Policy';
import MgdLayout from 'layouts/v3/MgdLayout';
import OrganLayoutv3 from 'layouts/v3/OrganLayoutv3';
import ManagementLayout from 'pages/v2/Organ/Management';

/* layouts */
const AuthLayout = withSplitting(() => import('layouts/AuthLayout'));

 
const App = () => {

  return (
    <Routes>
      {/* <Route path="/" element={<Navigate to="/monitor/weekly-report" />} />
      <Route path="/auth" element={<AuthLayout/>} >
        <Route index element={<Navigate to="/auth/login" />}/>
        <Route path="login" element={<LoginComp/>} />
        <Route path="signup" element={<SignUp/>} />
        <Route path="signupdone" element={<SignUpDone/>} />
        <Route path="serviceTerms" element={<Terms type="Service"/>}/>
        <Route path="secureTerms" element={<Terms type="Security"/>}/>
        <Route path="waitVerify" element={<WaitForEmail/>} />
        <Route path="userVerify" element={<EmailVerify/>} />
        <Route path="findpassword" element={<FindPassword/>} />
        <Route path="resetPw" element={<ChangePassword/>} />
        <Route path="done" element={<ChangePwDone/>} />
      </Route>
      <Route path="/project" element={<Layouts/>} >
        <Route index element={<Navigate to="/project/info" />}/>
        <Route path="info" element={<DefaultInfo/>} />
        <Route path="setting" element={<DefaultInfo isSetting/>} />
        <Route path="oprate-organ" element={<CompanyOperate/>} />
        <Route path="oprate-message" element={<MessageOperate/>} />
      </Route>
      <Route path="/monitor" element={<Layouts/>} >
        <Route index element={<Navigate to="/monitor/weekly-report" />}/>
        <Route path="weekly-report" element={<WeeklyReports/>} />
        <Route path="monthly-report" element={<MonthlyReports/>} />
        <Route path="event-setting" element={<Settings/>} />
        <Route path="event-history" element={<History/>} />
      </Route>
      <Route path="/v2"> */}
      <Route index element={<Landing />} />
      <Route path="terms" element={<Outlet />}> {/* mark: Preview에 props로 값 넣어주기? */}
        <Route index element={<Policy type="Service" />} />
        <Route path="secure" element={< Policy type="Security" />} />
        {/* <Route path="marketing" element={<>마케팅 수신동의</>} />  */}
      </Route>
      <Route path="auth" element={<AuthLayoutv2 />}>
        <Route index element={<LoginPage />} />
        <Route path="signup" element={<Signup />} />
        <Route path="signup-sendemail" element={<SendEmail />} />
        <Route path="userVerify" element={<SignupVerify />} />
        <Route path="reset-pw-sendemail" element={<ResetPw />} />
        <Route path="resetPw" element={<ResetPwVerify />} />
        <Route path="joinProject" element={ <VerifyInvitedMemberToken />} />
        <Route path="reqUpdateEmail" element={<VerifyUpdEmailToken />} />
      </Route>
      <Route path="invite-project" element={<InviteProject />} />
      <Route path="join-organ" element={<JoinOrgan />} />
      <Route path="select-organ" element={<SelectOrgan />} />
      <Route path="organ/:organId" element={<OrganLayoutv3 />}>
        <Route index element={<Favorite />} />
        <Route path="empty-project" element={<EmptyPage />} />
        <Route path="project/:id" element={<Project />} />
        <Route path="weekly-report" element={<WeeklyReport />} />
        <Route path="monthly-report" element={<MonthlyReport />} />
        <Route path="event-history" element={<EventHistory />} />
        <Route path="system" element={<ManageLayout />} >
          <Route path="event" element={ <Event />} />
          <Route path="analysis" element={<Analysis />} />
          <Route path="user" element={<Users />} />
          <Route path="common-information" element={<CommonInformation />} /> 
        </Route>
        {/* <Route path="network" element={<Network />} /> */}
        <Route path="manage">
          <Route path="network" element={<ManagementLayout />} />
          <Route path="waf" element={<ManagementLayout />} />
          <Route path="firewall" element={<ManagementLayout />} />
          <Route path="key-secret" element={<ManagementLayout />} />
          <Route path="instance" element={<ManagementLayout />} />
          <Route path="iam" element={<ManagementLayout />} />
        </Route>
      </Route>
      <Route path="payment" element={<PaymentLayout />}>
        <Route path="plan" element={<Plan />} />
        <Route path="card-management" element={<CardManagement />} />
        <Route path="receipt" element={<Receipt />} />
      </Route>
      <Route path="inquiry" element={<Inquiry />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default App;
