import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsListRegexPatternSetsResponse } from 'graphql/types/AwsListRegexPatternSets';

export interface IGetAwsListRegexPatternSetsVariables {
  cloudId: number;
  region: string;
  request: {
    scope: string;
    limit: number;
  };
}

export interface IAwsListRegexPatternSetsResponseData {
  getAwsListRegexPatternSets: IGqlResponseNewData<AwsListRegexPatternSetsResponse>;
}

const lazyGetAwsListRegexPatternSets = () =>
  useLazyQuery<IAwsListRegexPatternSetsResponseData, IGetAwsListRegexPatternSetsVariables>(query, {
    fetchPolicy: 'no-cache',
  });
export default lazyGetAwsListRegexPatternSets;
