import { gql } from '@apollo/client';

const query = gql`
query getAwsSubnetCidrReservations($request: AwsGetSubnetCidrReservationsRequest, $cloudId: Long, $region: String) {
  getAwsSubnetCidrReservations(getSubnetCidrReservationsRequest: $request, cloudId: $cloudId, region: $region) {
    result
     data{
      ... on AwsGetSubnetCidrReservationsResponse {
						subnetIpv4CidrReservations{
              subnetCidrReservationId
              subnetId
              cidr
              reservationType
              ownerId
              description
              tags{
                key
                value
              }
            }
        subnetIpv6CidrReservations {
          subnetCidrReservationId
              subnetId
              cidr
              reservationType
              ownerId
              description
              tags{
                key
                value
              }
        }
        nextToken
          }
        }
      }
    }
`;
export default query;