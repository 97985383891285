import { Fragment } from 'react';
import { RowType } from '@Types/v2/Table';
import { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import CopyableText from 'components/v3/CopyableText';
import { AwsLoadBalancer } from 'graphql/types/AwsLoadBalancer';
import { ITextDescription } from '../LoadBalancer/config';
import { TextTypeEnum } from 'layouts/v3/MgdLayout';
import { handleFormatText } from 'utils/Common';

export const LB_TARGET_GROUP_SEARCH_TYPES: DropdownListDataType[] = [
  { id: 1, name: 'Name', value: 'targetGroupName' },
  { id: 2, name: 'ARN', value: 'targetGroupArn' },
  { id: 3, name: 'Load balancer', value: 'loadBalancers' },
  { id: 4, name: 'Target type', value: 'targetType' },
  { id: 5, name: 'VPC ID', value: 'vpcId' },
];

export const LB_TARGET_GROUP_COLUMN_LIST = [
  {
    field: 'targetGroupName',
    label: 'Name',
    sort: true,
    renderCell: (row: RowType) => (
      <p
        className="description link"
        onClick={e => {
          e.stopPropagation();
          row.onTargetGroupLBNameClicked(row.id);
        }}
      >
        {handleFormatText(row.targetGroupName)}
      </p>
    ),
  },
  {
    field: 'targetGroupArn',
    label: 'ARN',
    sort: true,
    renderCell: (row: RowType) => (
      <CopyableText value={row.targetGroupArn} text={handleFormatText(row.targetGroupArn)} />
    ),
  },
  {
    field: 'port',
    label: 'Port',
    sort: true,
  },
  {
    field: 'protocol',
    label: 'Protocol',
    sort: true,
  },
  {
    field: 'targetType',
    label: 'Target type',
    sort: true,
  },
  {
    field: 'loadBalancer',
    label: 'Load balancer',
    sort: true,
    renderCell: (row: RowType) => (
      <Fragment>
        {row.loadBalancers.map((loadBalancer: AwsLoadBalancer, index: number) => {
          return <p key={`lb-target-group-list-load-balancer${index}`}>{handleFormatText(loadBalancer.loadBalancerName)}</p>
        })}
      </Fragment>
    ),
  },
  {
    field: 'vpcId',
    label: 'VPC ID',
    sort: true,
  },
];

export const TARGET_GROUP_TAB_LIST = [
  { id: 'details', name: 'Details', title: 'Details' },
  { id: 'targets', name: 'Targets', title: 'targets' },
  { id: 'healthChecks', name: 'Health checks', title: 'Health checks' },
  { id: 'attributes', name: 'Attributes', title: 'Attributes' },
  { id: 'tags', name: 'Tags', title: 'Tags' },
];

export const TARGET_GROUP_DETAIL_TAB_LIST = [
  { id: 'targets', name: 'Targets', title: 'targets' },
  { id: 'healthChecks', name: 'Health checks', title: 'Health checks' },
  { id: 'attributes', name: 'Attributes', title: 'Attributes' },
  { id: 'tags', name: 'Tags', title: 'Tags' },
];

export const TARGETS_TABLE_COLUMNS = [
  { field: 'id', label: 'Instance ID', sort: true },
  { field: 'instanceName', label: 'Name', sort: true },
  { field: 'port', label: 'Port', sort: true },
  { field: 'zone', label: 'Zone', sort: true },
  { field: 'healthStatus', label: 'Health Status', sort: true },
  { field: 'healthStatusDetail', label: 'Health Status Details', sort: true },
  { field: 'launchTime', label: 'Launch Time', sort: true },
];

export const TAGS_TABLE_COLUMNS = [
  { label: 'Key', field: 'key', sort: true },
  { label: 'Value', field: 'value', sort: true },
];

export const targetDeregistrationManagementKeys = [
  'deregistration_delay.timeout_seconds',
];

export const targetDeregistrationManagementTextDes: ITextDescription[] = [
  { id: 'deregistration_delay.timeout_seconds', value: 'Deregistration delay (draining interval)', type: TextTypeEnum.NORMAL },
];

export const trafficConfigKeys = [
  'load_balancing.algorithm.type',
  'slow_start.duration_seconds',
];

export const trafficConfigTextDes: ITextDescription[] = [
  { id: 'load_balancing.algorithm.type', value: 'Load balancing algorithm', type: TextTypeEnum.NORMAL },
  { id: 'slow_start.duration_seconds', value: 'Slow start duration', type: TextTypeEnum.NORMAL },
];

export const targetSelectionConfigurationKeys = [
  'stickiness.enabled',
  'load_balancing.cross_zone.enabled',
];

export const targetSelectionConfigurationTextDes: ITextDescription[] = [
  { id: 'stickiness.enabled', value: 'Stickiness', type: TextTypeEnum.NORMAL },
  { id: 'load_balancing.cross_zone.enabled', value: 'Cross-zone load balancing', type: TextTypeEnum.NORMAL },
];

export const targetGroupHealthRequirementKeys = [
  'target_group_health.dns_failover.minimum_healthy_targets.count',
  'target_group_health.dns_failover.minimum_healthy_targets.percentage',
  'target_group_health.unhealthy_state_routing.minimum_healthy_targets.count',
  'target_group_health.unhealthy_state_routing.minimum_healthy_targets.percentage',
];

export const targetGroupHealthRequirementTextDes: ITextDescription[] = [
  { id: 'target_group_health.dns_failover.minimum_healthy_targets.count', value: 'DNS-health state requirements ', type: TextTypeEnum.NORMAL },
  { id: 'target_group_health.dns_failover.minimum_healthy_targets.percentage', value: 'DNS-health state requirements ', type: TextTypeEnum.NORMAL },
  { id: 'target_group_health.unhealthy_state_routing.minimum_healthy_targets.count', value: 'Routing-healthy state requirements', type: TextTypeEnum.NORMAL },
  { id: 'target_group_health.unhealthy_state_routing.minimum_healthy_targets.percentage', value: 'Routing-healthy state requirements', type: TextTypeEnum.NORMAL },
];
