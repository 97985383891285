import React from 'react';
import InputAtom from 'components/v2/atoms/InputAtom';

type AnotherLabelPropsType = {
  anotherLabelList: [];
  setAnotherLabelList: (val: []) => void;
};

function AnotherLabel({ anotherLabelList, setAnotherLabelList }: AnotherLabelPropsType) {
  const handleAddNewAnotherLabel = () => {
    const anotherLabel = {
      id: (Math.random() + 1).toString(36).substring(2, 8),
      value: '',
    };

    const arr: any = [...anotherLabelList];
    arr.push(anotherLabel);
    setAnotherLabelList(arr);
  };

  const handleChangeAnotherLabel = (id: string, value: string) => {
    const arr: any = [...anotherLabelList];

    const index = arr.findIndex((e: { id: string }) => e.id === id);

    if (index < 0) return;

    arr[index] = {
      ...arr[index],
      value: value,
    };

    setAnotherLabelList(arr);
  };

  const handleRemoveAnotherLabel = (id: string) => {
    const arr: any = [...anotherLabelList].filter((e: { id: string }) => e.id !== id);

    setAnotherLabelList(arr);
  };

  return (
    <div>
      <div className="list-container">
        {anotherLabelList?.length
          ? anotherLabelList.map(({ id, value }: { id: string; key: string; value: string }) => (
              <div key={id} className="item-container mw-50">
                <InputAtom
                  placeholder="Namespace:name."
                  onChangeValue={val => handleChangeAnotherLabel(id, val)}
                  value={value}
                  noClear
                />

                <button className="btn-default" onClick={() => handleRemoveAnotherLabel(id)}>
                  Remove
                </button>
              </div>
            ))
          : null}
      </div>

      <button className="btn-primary space-8" onClick={handleAddNewAnotherLabel}>
        Add another label
      </button>
    </div>
  );
}

export default AnotherLabel;
