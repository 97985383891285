import React from 'react';
import InputAtom from 'components/v2/atoms/InputAtom';
import DropdownContainer from '../../../components/DropdownContainer';
import { EVALUATION_WINDOW } from './constant';
import { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import HeaderFieldName from '../../../IpSet/HeaderFieldName';
import PositionInsideHeader from '../../../IpSet/PositionInsideHeader';
import MissingIpAddress from '../../../IpSet/MissingIpAddress';

type IpAddressInHeaderPropsType = {
  headerFieldName: string;
  setHeaderFieldName: (val: string) => void;
  positionInsideHeader: DropdownListDataType;
  setPositionInsideHeader: (val: DropdownListDataType) => void;
  missingIpAddress: string;
  setMissingIpAddress: (val: string) => void;
};

function IpAddressInHeader({
  headerFieldName,
  setHeaderFieldName,
  positionInsideHeader,
  setPositionInsideHeader,
  missingIpAddress,
  setMissingIpAddress,
}: IpAddressInHeaderPropsType) {
  return (
    <>
      <HeaderFieldName headerFieldName={headerFieldName} setHeaderFieldName={val => setHeaderFieldName(val)} />

      <DropdownContainer
        dropdownClassName="mw-50"
        title="Position inside header"
        description="AWS WAF uses the first IP address in the header. The header can contain IP addresses of the original client and of any proxies. For example, the header value could be ’10.1.1.1, 127.0.0.0, 10.10.10.10’ where the first IP address identifies the original client and the rest identify proxies that the request went through."
        options={[{ name: 'First IP address', value: 'First IP address' }]}
        value={positionInsideHeader}
        onChange={setPositionInsideHeader}
        disabled
      />

      <MissingIpAddress missingIpAddress={missingIpAddress} setMissingIpAddress={val => setMissingIpAddress(val)} />
    </>
  );
}

export default IpAddressInHeader;
