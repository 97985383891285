import { useEffect, useState } from 'react';
import { AwsWebACL } from 'graphql/types/AwsWebACL';
import lazyGetAwsLoggingConfiguration from 'graphql/queries/getAwsLoggingConfiguration';
import {  OrderDirection } from '@Types/v2/Table';
import { AwsArnData } from '../../../Commons/Constant';
import EnableLoggingModal from '../../../EnableLoggingModal';
import EditSampledRequestsModal from '../../../EditSampledRequestsModal';
import EditCloudworkMetricsModal from '../../../EditCloudworkMetricsModal';

interface ILoggingProps {
  cloudId: number;
  region: string;
  webAcl: AwsWebACL;
}

const Logging = ({ cloudId, region, webAcl }: ILoggingProps) => {
  const [loggingConfig, setLoggingConfig] = useState<AwsArnData[]>([]);
  const [isEnableLoggingOpened, setIsEnableLoggingOpened] = useState<boolean>(false);
  const [isEditSampleRequestOpened, setIsEditSampleRequestOpened] = useState<boolean>(false);
  const [isEditCloudworkMetricsOpened, setIsEditCloudworkMetricsOpened] = useState<boolean>(false);

  const [getAwsLoggingConfiguration] = lazyGetAwsLoggingConfiguration();

  const getLoggingConfig = () => {
    getAwsLoggingConfiguration({
      variables: {
        cloudId: cloudId,
        region: region,
        request: {
          resourceArn: webAcl.arn,
        },
      },
    }).then(({ data }) => {
      if (data && data.getAwsLoggingConfiguration) {
        if (data.getAwsLoggingConfiguration.data.loggingConfiguration) {
          let logDestinationConfigs: AwsArnData[] = [];
          const loggingConfiguration = data.getAwsLoggingConfiguration.data.loggingConfiguration;
          loggingConfiguration.logDestinationConfigs.map(log => {
            const logData = extractArnsLoggingData(log);
            logDestinationConfigs.push(logData);
          });
          setLoggingConfig(logDestinationConfigs);
        }
      }
    });
  };

  useEffect(() => {
    getLoggingConfig();
  }, [webAcl.arn]);

  const extractArnsLoggingData = (arn: string) => {
    // Split the ARN string
    const arnComponents = arn.split(':');

    // Extract specific parts
    const service = arnComponents[2];
    const region = arnComponents[3];
    const accountId = arnComponents[4];
    const loggingName = arnComponents[arnComponents.length - 1];

    const arnData: AwsArnData = {
      id: loggingName,
      service: service,
      region: region,
      accountId: accountId,
      resourceType: '',
      resourceName: loggingName,
    };
    return arnData;
  };

  return (
    <>
      <div className="resize-container vertical">
        <div className="details">
          <div className="row-3">
            <div className="title border-bottom flex j-between a-center">
              <div>
                <p>Logging</p>
                <p>Enable, edit, and disable web ACL traffic logging.</p>
              </div>
              <div className="flex action a-center">
                <button className="action-btn" onClick={() => setIsEnableLoggingOpened(true)} disabled={false}>
                  Enable
                </button>
                <button className="edit-btn" onClick={() => {}} disabled={true}>
                  Edit
                </button>
                <button className="action-btn" onClick={() => {}} disabled={true}>
                  Disable
                </button>
              </div>
            </div>
            <div className="content flex">
              <div className="logging-config">
                <p>Logging (Configure your logging destination and settings)</p>
                <p>{loggingConfig ? 'Enabled' : 'Disabled'}</p>
              </div>
              <div className="logging-config">
                <p>
                  {loggingConfig[0]?.service === 's3'
                    ? 'Amazon S3 bucket'
                    : loggingConfig[0]?.service === 'logs'
                    ? 'Amazon CloudWatch Logs log group'
                    : 'Amazon Data Firehose stream'}
                </p>
                {loggingConfig?.map(log => {
                  return <p>{log.resourceName}</p>;
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="details">
          <div className="row-3">
            <div className="title border-bottom flex j-between a-center">
              <div>
                <p>Sampled request</p>
                <p>
                  If you enable sampled requests, AWS WAF stores samples from the last 3 hours of requests that match
                  the web ACL rules. Sampled requests are free of charge
                </p>
              </div>
              <div className="flex action a-center">
                <button className="edit-btn" onClick={() => setIsEditSampleRequestOpened(true)} disabled={false}>
                  Edit
                </button>
              </div>
            </div>
            <div className="content flex">
              <div className="logging-config">
                <p>Sample requests for web ACL default actions</p>
                <p>{webAcl.visibilityConfig.sampledRequestsEnabled ? 'Enabled' : 'Disabled'}</p>
              </div>
              <div className="logging-config">
                <p>Sampled requests</p>
                {webAcl.rules.map((rule, index) => {
                  return (
                    <div className="sample-data flex a-center" key={`sample-config-${index}`}>
                      <span>{rule.name}</span>
                      <span>{rule.visibilityConfig.sampledRequestsEnabled ? 'Enabled' : 'Disabled'}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="details">
          <div className="row-3">
            <div className="title flex j-between a-center">
              <div>
                <p>CloudWatch metrics ({webAcl.rules?.length || 0})</p>
              </div>
              <div className="flex action a-center">
                <button className="edit-btn" onClick={() => setIsEditCloudworkMetricsOpened(true)} disabled={false}>
                  Edit
                </button>
              </div>
            </div>
          </div>
          <div className="content">
            {!webAcl.rules ? (
              <div className="data-grid-wrap">
                <p className="empty-row">Empty</p>
              </div>
            ) : (
              <div className="data-grid-wrap">
                <div className="data-table">
                  <table>
                    <thead>
                      <tr className="header">
                        <th style={{ paddingLeft: '32px' }}>Rule name</th>
                        <th>Cloudwatch metric name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {webAcl.rules.map(rule => (
                        <>
                          <tr>
                            <td style={{ paddingLeft: '32px' }}>
                              <div>{rule.name}</div>
                            </td>
                            <td style={{ paddingLeft: '32px' }}>
                              <div>{rule.visibilityConfig?.metricName}</div>
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <EnableLoggingModal
        header={'Enable logging'}
        dataDropdown={[
          { id: 0, name: 'apnortheast-2ea', value: 'apnortheast-2ea', description: '2 firewall subnets' },
          { id: 1, name: 'apnortheast-2ec', value: 'apnortheast-2ec', description: '2 firewall subnets' },
          { id: 2, name: 'apnortheast-3ea', value: 'apnortheast-3ea', description: '2 firewall subnets' },
          { id: 3, name: 'apnortheast-3ec', value: 'apnortheast-3ec', description: '2 firewall subnets' },
        ]}
        open={isEnableLoggingOpened}
        onClose={() => setIsEnableLoggingOpened(false)}
      />

      <EditSampledRequestsModal
        header={'Edit sampled requests'}
        columns={[
          {
            label: 'Name',
            field: 'name',
            sort: true,
          },
        ]}
        rows={[
          {
            id: 1,
            name: 'nemo-dev-vpc-routetableap-north-1',
          },
          {
            id: 2,
            name: 'nemo-dev-vpc-routetableap-north-2',
          },
        ]}
        open={isEditSampleRequestOpened}
        onClose={() => setIsEditSampleRequestOpened(false)}
      />

<EditCloudworkMetricsModal
        header={'Edit cloudwork metrics'}
        currentRows={[
          {
            id: 1,
            rules: 'nemo-dev-vpc-routetableap-north-a',
            cloudmetricName: 'nemo-dev-vpc-routetableap-north-b',
          },
          {
            id: 2,
            rules: 'nemo-dev-vpc-routetableap-north-b',
            cloudmetricName: 'nemo-dev-vpc-routetableap-north-a',
          },
        ]}
        currentColumns={[
          {
            label: 'Rules',
            field: 'rules',
            sort: true,
          },
          {
            label: 'Cloudwatch metric name',
            field: 'cloudmetricName',
            sort: true,
          },
        ]}
        reportCheckedList={(list: string[]) => {
          //setCheckedList(list);
        }}
        reportSelected={(id: string) => {
          //setSelected(id);
        }}
        sortOption={{
          target: '',
          direction: OrderDirection.DES,
          onChangeSort: (target: string, dir: OrderDirection) => {},
        }}
        open={isEditCloudworkMetricsOpened}
        onClose={() => setIsEditCloudworkMetricsOpened(false)}
      />
    </>
  );
};
export default Logging;

function getLoggingConfig() {
  throw new Error('Function not implemented.');
}
