export const RULES_COLUMN_LIST = [
  { field: 'name', label: 'Name', sort: true },
  { field: 'resourceType', label: 'Resource type', sort: true },
  { field: 'region', label: 'Region', sort: true },
];

export const TOKEN_DOMAIN_COLUMN_LIST = [
  { field: 'name', label: 'Name', sort: true },
  { field: 'capacity', label: 'Capacity', sort: true },
  { field: 'action', label: 'Action', sort: true },
];

export const METRICS_COLUMN_LIST = [
  { field: 'rules', label: 'Rules', sort: true },
  { field: 'metrics', label: 'CloudWatch metric name', sort: true },
];
