import { useEffect, useState } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import InputAtom from 'components/v2/atoms/InputAtom';
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import './index.scss';
import { RULE_TYPE_DROPDOWN } from '../Common/Constant';
import Icon from 'components/v2/atoms/Icon';
import InboundIcon from 'assets/svgs/v3/ico_inbound.svg'
import OutboundIcon from 'assets/svgs/v3/ico_outbound.svg'
import {
  disabledByType, disabledByTypeAndProtocol,
  getPortRangeByTypeAndProtocol, getPortRangeDataByType,
  getProtocolByType, getProtocolDataByType
} from "pages/v2/Organ/Management/Network/Common/Function/Network";

interface IEditNetworkRuleModalProps extends IBaseModalProps {
  header: string;
  isInbound: boolean;
  currentData: RuleRowData[];
  onSave: (data: RuleRowData[]) => void;
}

export interface RuleRowData {
  ruleNumber: string;
  type: string;
  protocol:string;
  portRange: string;
  source: string;
  action: string
}

const actionList: DropdownListDataType[] = [
  {name: 'Allow', value: 'allow'},
  {name: 'Deny', value: 'deny'},
]

interface IEditNetworkRuleTable {
  rows: RuleRowData[];
  handleChange: (index: number, value: string, propName: keyof RuleRowData) => void;
  disabledByType: (type: string) => boolean;
  getProtocolDataByType: (type: string) => DropdownListDataType[];
  disabledByTypeAndProtocol: (type: string, protocol: string) => boolean;
  getPortRangeDataByType: (type: string, protocol: string) => DropdownListDataType[];
  handleDelete: (indexToRemove: number) => void;
  handleCreate: () => void;
  btnName?: string;
}
export const NetworkRuleTable = (
  {
    rows,
    handleChange,
    disabledByType,
    getProtocolDataByType,
    disabledByTypeAndProtocol,
    getPortRangeDataByType,
    handleDelete,
    handleCreate,
    btnName
  }: IEditNetworkRuleTable) => {
  return (
    <table className="table-rules">
      <thead className="table-header">
      <tr>
        <th>Rule number</th>
        <th>Type</th>
        <th>Protocol</th>
        <th>Port range</th>
        <th>Source</th>
        <th>Allow/Deny</th>
      </tr>
      </thead>

      <tbody className="table-body">
      {
        rows.map((row, index) => {
          return (
            <tr key={index}>
              <td>
                <InputAtom
                  value={row.ruleNumber}
                  noClear={true}
                  onChangeValue={(value: string) => handleChange(index, value, "ruleNumber")}
                />
              </td>
              <td>
                <DropdownAtom
                  id={'type-rules' + `${index}`}
                  data={RULE_TYPE_DROPDOWN}
                  value={{
                    name: RULE_TYPE_DROPDOWN.find(val => val.value === row.type)?.name || '',
                    value: row.type
                  }}
                  handleClick={(val) => handleChange(index, val.value as string, "type")}/>
              </td>
              <td>
                <DropdownAtom
                  id={'protocol-rules' + `${index}`}
                  disabled={disabledByType(row.type)}
                  data={getProtocolDataByType(row.type)}
                  value={{
                    name: getProtocolDataByType(row.type).find(val => val.value === row.protocol)?.name || '',
                    value: row.protocol
                  }}
                  handleClick={(val) => handleChange(index, val.value as string, "protocol")}/>
              </td>
              <td>
                {(row.type === 'custom_icmp_ipv4' || row.type === 'custom_icmp_ipv6') && row.protocol !== '11' ?
                  <DropdownAtom
                    id={'port-range-rules' + `${index}`}
                    disabled={disabledByTypeAndProtocol(row.type, row.protocol)}
                    data={getPortRangeDataByType(row.type, row.protocol)}
                    value={{
                      name: getPortRangeDataByType(row.type, row.protocol).find(val => val.value === row.portRange)?.name || '',
                      value: row.portRange
                    }}
                    handleClick={(val) => handleChange(index, val.value as string, "portRange")}
                  />
                  :
                  <InputAtom
                    value={row.portRange}
                    disabled={disabledByTypeAndProtocol(row.type, row.protocol)}
                    noClear={true}
                    onChangeValue={(value: string) => handleChange(index, value, "portRange")}
                  />
                }
              </td>
              <td>
                <InputAtom
                  value={row.source}
                  noClear={true}
                  onChangeValue={(value: string) => handleChange(index, value, "source")}
                />
              </td>
              <td>
                <DropdownAtom
                  id={'action-rules' + `${index}`}
                  data={actionList}
                  value={{
                    name: actionList.find(val => val.value === row.action)?.name
                      ? actionList.find(val => val.value === row.action)?.name : '',
                    value: row.action
                  }}
                  handleClick={(val: any) => handleChange(index, val.value, "action")}/>
              </td>
              <td>
                <button onClick={() => handleDelete(index)}>{`${btnName ? btnName : 'Remove'}`}</button>
              </td>
            </tr>
          )
        })
      }
      <tr className="row-default-rules">
        <td>
          <InputAtom
            value={'*'}
            disabled={true}
          />
        </td>
        <td>
          <DropdownAtom
            id={'type-rules'}
            disabled={true}
            placeholder={'All traffic'}
            data={[]}
            value={{
              name: '',
              value: ''
            }}
            handleClick={() => {
            }}
          />
        </td>
        <td>
          <DropdownAtom
            id={'protocol-rules'}
            disabled={true}
            placeholder={'All'}
            data={[]}
            value={{
              name: '',
              value: ''
            }}
            handleClick={() => {
            }}
          />
        </td>
        <td>
          <InputAtom
            disabled={true}
            value={'All'}
          />
        </td>
        <td>
          <InputAtom
            disabled={true}
            value={'0.0.0.0/0'}
          />
        </td>
        <td>
          <DropdownAtom
            id={'action-rules'}
            disabled={true}
            placeholder={'Deny'}
            data={[]}
            value={{
              name: '',
              value: ''
            }}
            handleClick={() => {
            }}
          />
        </td>
      </tr>
      <tr>
        <td>
          <button className="add-button" onClick={() => {
            handleCreate()
          }}>Add new tag
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  )
}

const EditNetworkRuleModal = ({
                                header,
                                isInbound,
                                currentData,
                                onSave,
                                ...baseModalProps
                              }: IEditNetworkRuleModalProps) => {
  const [rows, setRows] = useState<RuleRowData[]>([]);

  useEffect(() => {
    if (baseModalProps.open) {
      setRows(currentData);
    }
  }, [baseModalProps.open])

  const handleCreate = () => {
    setRows((prevState) => {
      return prevState.concat({
        ruleNumber: '',
        type: 'custom_tcp',
        protocol: '6',
        portRange: '0',
        source: '0.0.0.0/0',
        action: 'allow'
      });
      ;
    })
  };

  const handleDelete = (indexToRemove: number) => {
    const newData = rows.filter((_, index) => index != indexToRemove)
    setRows(newData);
  }

  const handleChange = (index: number, value: string, propName: keyof RuleRowData) => {
    const newRows = rows.map((row, i) => {
        if (i == index) {
          const newRow = {...row, [propName]: value}
          if (propName === 'type') {
            const newValue = getProtocolByType(value, row.protocol, row.portRange);
            newRow.protocol = newValue.protocol;
            newRow.portRange = newValue.portRange;
          }
          if (propName === 'protocol') {
            newRow.portRange = getPortRangeByTypeAndProtocol(row.type, value, row.portRange);
          }
          return newRow;
        } else {
          return row;
        }
      }
    );
    setRows(newRows);
  };

  return (
    <BaseModal
      title={() => <><Icon width={32} height={32} src={isInbound ? InboundIcon : OutboundIcon}/>{header}</>}
      {...baseModalProps} >
      <div className="edit-inbound-rules-model">
        <NetworkRuleTable
          rows={rows}
          handleChange={(index: number, value: string, propName: keyof RuleRowData) => {handleChange(index, value, "ruleNumber")}}
          disabledByType={(type: string) => disabledByType(type)}
          getProtocolDataByType={(type: string) => getProtocolDataByType(type)}
          disabledByTypeAndProtocol={(type: string, protocol: string) => disabledByTypeAndProtocol(type, protocol)}
          getPortRangeDataByType={(type: string, protocol: string) => getPortRangeDataByType(type, protocol)}
          handleDelete={(indexToRemove: number) => {handleDelete(indexToRemove)}}
          handleCreate={() => {handleCreate()}}
        />

        <div className="button-group">
          <button onClick={baseModalProps.onClose}>Cancel</button>
          <button className="save-btn" onClick={() => onSave(rows)}>Save changes</button>
        </div>
      </div>
    </BaseModal>
  );
};

export default EditNetworkRuleModal;
