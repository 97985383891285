export enum MgdTabLayoutTypeEnum {
  MGD_LIST = 'MGD_LIST',
  MGD_LIST_SUMMARY = 'MGD_LIST_SUMMARY',
  MGD_DETAIL = 'MGD_DETAIL',
  MGD_LIST_MORE = 'MGD_LIST_MORE',
  MGD_LIST_MORE_SUMMARY = 'MGD_LIST_MORE_SUMMARY',
}

export enum TextTypeEnum {
  COPY = 'C',
  LINK = 'L',
  STATUS = 'S',
  NORMAL = 'N',
  YELLOW = 'Y',
  RED = 'R',
  GREEN = 'G'
}

export enum DetailTypeEnum {
  DETAIL = 'DETAIL',
  TABLE = 'TABLE',
  MULTICOLUMN = 'MULTICOLUMN',
  JSONVIEWER = 'JSONVIEWER'
}