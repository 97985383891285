import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsListRuleGroupResponse } from 'graphql/types/AwsListRuleGroup';

export interface IGetAwsListRuleGroupVariables {
  cloudId: number;
  region: string;
  request: {
    scope: string;
    limit: number;
  };
}

export interface IAwsListRuleGroupResponseData {
  getAwsListRuleGroup: IGqlResponseNewData<AwsListRuleGroupResponse>;
}

const lazyGetAwsListRuleGroup = () =>
  useLazyQuery<IAwsListRuleGroupResponseData, IGetAwsListRuleGroupVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsListRuleGroup;
