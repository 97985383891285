import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';

type DropdownContainerPropsType = {
  dropdownClassName?: string;
  title: string;
  description?: string;
  options: DropdownListDataType[];
  value: DropdownListDataType;
  onChange: (val: DropdownListDataType) => void;
  placeholder?: string;
  disabled?: boolean
};

function DropdownContainer({ dropdownClassName, title, description, options, value, onChange, placeholder, disabled }: DropdownContainerPropsType) {
  return (
    <div>
      <p className="rule-container-group-content-label">{title}</p>

      <p className="rule-container-group-content-description">{description}</p>

      <DropdownAtom
      className={dropdownClassName}
        id={(Math.random() + 1).toString(36).substring(2, 8)}
        data={options}
        placeholder={placeholder}
        value={value}
        handleClick={onChange}
        disabled={disabled}
      />
    </div>
  );
}

export default DropdownContainer;
