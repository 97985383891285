import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsListPoliciesGrantingServiceAccessResponseEntry } from 'graphql/types/AwsListPoliciesGrantingServiceAccessResponse';

export interface IAwsListPoliciesGrantingServiceAccessVariables {
  cloudId: number;
  region: string;
  request: {
    arn: string;
    serviceNamespaces?: string;
  };
}

export interface IAwsListPoliciesGrantingServiceAccessResponseData {
  getAwsListPoliciesGrantingServiceAccess: AwsListPoliciesGrantingServiceAccessResponseEntry;
}

const lazyGetAwsListPoliciesGrantingServiceAccess = () =>
  useLazyQuery<IAwsListPoliciesGrantingServiceAccessResponseData, IAwsListPoliciesGrantingServiceAccessVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsListPoliciesGrantingServiceAccess;
