import { gql } from '@apollo/client';

const query = gql`
query getAwsNetworkInterfaces($request: AwsDescribeNetworkInterfacesRequest, $cloudId: Long, $region: String) {
  getAwsNetworkInterfaces(describeNetworkInterfacesRequest: $request, cloudId: $cloudId, region: $region) {
    result
     data{
      ... on AwsDescribeNetworkInterfacesResponse {
            networkInterfaces {
              association {
                allocationId
                associationId
                publicDnsName
                ipOwnerId
                publicIp
                customerOwnedIp
                carrierIp
              }
              attachment {
                attachTime
                attachmentId
                deleteOnTermination
                deviceIndex
                networkCardIndex
                instanceId
                instanceOwnerId
                status
                enaSrdSpecification {
                  enaSrdEnabled
                  enaSrdUdpSpecification {
                    enaSrdUdpEnabled
                  }
                }
              }
              availabilityZone
              connectionTrackingConfiguration {
                tcpEstablishedTimeout
                udpStreamTimeout
                udpTimeout
              }
              description
              groups {
                groupName
                groupId
              }
              interfaceType
              ipv6Addresses {
                ipv6Address
                isPrimaryIpv6
              }
              macAddress
              networkInterfaceId
              outpostArn
              ownerIdb
              privateDnsName
              privateIpAddress
              privateIpAddresses {
                association {
                  allocationId
                  associationId
                  ipOwnerId
                  publicDnsName
                  publicIp
                  customerOwnedIp
                  carrierIp
                }
                primary
                privateDnsName
                privateIpAddress
              }
              ipv6Prefixes {
                ipv6Prefix
              }
              ipv4Prefixes {
                ipv4Prefix
              }
              requesterId
              requesterManaged
              sourceDestCheck
              status
              subnetId
              tagSet {
                key
                value
              }
              vpcId
              denyAllIgwTraffic
              ipv6Native
              ipv6Address
            }
        nextToken
          }
        }
      }
    }
`;
export default query;
