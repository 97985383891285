import { gql } from '@apollo/client';

const query = gql`
  query getAwsRuleGroupMetadata($request: AwsDescribeRuleGroupMetadataRequest, $cloudId: Long, $region: String) {
    getAwsRuleGroupMetadata(describeRuleGroupMetadataRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsDescribeRuleGroupMetadataResponse {
          ruleGroupArn
          ruleGroupName
          description
          type
          capacity
          statefulRuleOptions {
            ruleOrder
          }
          lastModifiedTime
        }
      }
    }
  }
`;
export default query;
