import React, { useState, useCallback } from 'react';
import './index.scss';
import { type Content, Mode } from 'vanilla-jsoneditor';
import JSONEditor from 'components/JSONEditor';

type JsonPropsType = {
  json: Object;
};

const Json = (props: JsonPropsType) => {
  const { json } = props;

  const [responseBodyTextareaValue, setResponseBodyTextareaValue] = useState('');
  const [jsonEditorContent, setJsonEditorContent] = useState<Content>({
    json,
  });

  const onValidate = useCallback(() => {}, []);

  return (
    <>
      <div className="rule-container-group rule-container-group-border rule-container-group-json">
        <div className="rule-container-group-title">
          <p>JSON</p>

          <button className="btn-default" onClick={onValidate}>
            Validate
          </button>
        </div>

        <JSONEditor content={jsonEditorContent} onChange={setJsonEditorContent} />
      </div>
    </>
  );
};

export default Json;
