import { useState, useEffect } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import InputAtom from 'components/v2/atoms/InputAtom';
import IconEditVPCAndSubnets from 'assets/svgs/v3/ico_edit_vpc_subnets.svg';
import Icon from 'components/v2/atoms/Icon';
import './index.scss';
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';

interface IEditVPCAndSubnetsProps extends IBaseModalProps {
  vpcId: string;
  currentData: RowSubnetData[];
  onChangeValue?: () => void;
}

export interface RowSubnetData {
  availabilityZoneId: string;
  subnetId: string;
  ipAdressType: string;
}

const IpAddressTypeList: DropdownListDataType[] = [
  {id: 0, name: 'IPv4', value: 'IPv4'},
  {id: 1, name: 'Dualstack', value: 'Dualstack'},
  {id: 2, name: 'IPv6', value: 'IPv6'},
]

const DummyZoneList: DropdownListDataType[] = [
  {id: 0, name: 'apnortheast-2ea', value: 'apnortheast-2ea', description: '2 firewall subnets'},
  {id: 1, name: 'apnortheast-2ec', value: 'apnortheast-2ec', description: '2 firewall subnets'},
]

const DummySubnetList: DropdownListDataType[] = [
  {id: 0, name: 'aylee-test-subnet', value: 'subnet-0e966cfa5435c42ed'},
  {id: 1, name: 'nemo-prod-subnet-public-ap-northeast-2d', value: 'subnet-0f8b85f1ec02c9050'},
]

const EditVPCAndSubnetsModal = ({
  vpcId,
  currentData,
  onChangeValue,
  ...baseModalProps
}: IEditVPCAndSubnetsProps) => {
  const [rows, setRows] = useState<RowSubnetData[]>(currentData);

  const handleCreate = () => {
    setRows((prevState) => {
      const newState = prevState.concat({
        availabilityZoneId: '',
        subnetId: '',
        ipAdressType: 'IPv4'
      });
      return newState;
    })
  };

  const handleDelete = (indexToRemove: number) => {
    const newData = rows.filter((_, index) => index != indexToRemove)
    setRows(newData);
  }

  const handleChange = (index: number, value: string, propName: keyof RowSubnetData) => {
    const newRows = rows.map((row, i) =>
      i === index ? { ...row, [propName]: value } : row
    );
    setRows(newRows);
  };

  const handleDeleteSelected = (index: number, propName: keyof RowSubnetData) => {
    const newRows = rows.map((row, i) =>
      i === index ? ({ ...row, [propName]: [] })  : row
    );
    setRows(newRows);
  };

  return (
    <BaseModal
      title={() => <><Icon width={32} height={32} src={IconEditVPCAndSubnets} /> Edit VPC and subnets</>}
      {...baseModalProps}>
      <div className="edit-vpc-subnets-modal">
        <div className='vpc-id'>
          <p>Vpc</p>
          <InputAtom value={vpcId} disabled={true}/>
        </div>
        <div className='firewall-subnet'>
          <p className='title'>Firewall subnets</p>
          <p className='description'>Each subnet must have one available IP address.<br/>You can't change the subnet's IP address type after creation.</p>
        </div>
        <table className="table">
            <thead className="table-header">
              <tr>
                <th>Availability zone</th>
                <th>Subnet</th>
                <th>IP address type</th>
              </tr>
            </thead>
          <tbody className="table-body">
          {rows.map((row, index) => (
            <tr key={index}>
              <td>
                <DropdownAtom
                  id={'availability-zone' + `${index}`}
                  data={DummyZoneList}
                  placeholder={'Choose an available zone'}
                  value={{
                    name: DummyZoneList.find((item) => item.value === row.availabilityZoneId)?.name,
                    value: row.availabilityZoneId
                  }}
                  handleClick={(val) => handleChange(index, val.value as string, "availabilityZoneId")}
                />
              </td>
              <td>
                <DropdownAtom
                  id={'subnet' + `${index}`}
                  data={DummySubnetList}
                  placeholder={'Choose a subnet'}
                  value={{
                    name: DummySubnetList.find((item) => item.value === row.subnetId)?.name,
                    value: row.subnetId
                  }}
                  handleClick={(val) => handleChange(index, val.value as string, "subnetId")}
                />
              </td>
              <td>
                <DropdownAtom
                  id={'ip-address-type' + `${index}`}
                  data={IpAddressTypeList}
                  placeholder={'Choose an IP address'}
                  value={{
                    name: IpAddressTypeList.find((item) => item.value === row.ipAdressType)?.name,
                    value: row.ipAdressType
                  }}
                  handleClick={(val) => handleChange(index, val.value as string, "ipAdressType")}
                />
              </td>
              <td >
                <button onClick={() => {handleDelete(index)}}>Remove subnet</button>
              </td>
            </tr>
          ))}
          <tr >
            <td>
              <button className="add-button" onClick={handleCreate}>Add new subnet</button>
            </td>
          </tr>
          </tbody>
        </table>
        <div className="button-group">
          <button onClick={baseModalProps.onClose}
          >Cancel</button>
          <button className="save-btn"
            onClick={() => {}}
          >Save changes</button>
        </div>
      </div>
    </BaseModal>
  );
};

export default EditVPCAndSubnetsModal;
