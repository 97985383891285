import { gql } from '@apollo/client';

const query = gql`
query getAwsDescribeListeners($request: AwsDescribeListenersRequest, $cloudId: Long, $region: String) {
  getAwsDescribeListeners(describeListenersRequest: $request, cloudId: $cloudId, region: $region) {
    result
    data {
      ... on AwsDescribeListenersResponse {
          listeners {
            listenerArn
            loadBalancerArn
            port
            certificates {
              certificateArn
              isDefault
            }
            protocol
            sslPolicy
            defaultActions {
              type
              targetGroupArn
              authenticateOidcConfig {
                issuer
                authorizationEndpoint
                tokenEndpoint
                userInfoEndpoint
                clientId
                clientSecret
                sessionCookieName
                scope
                sessionTimeout
                authenticationRequestExtraParams
                onUnauthenticatedRequest
                useExistingClientSecret
              }
              forwardConfig {
                targetGroups {
                  targetGroupArn
                  weight
                }
                targetGroupStickinessConfig {
                  enabled
                  durationSeconds
                }
              }
              authenticateCognitoConfig {
                userPoolArn
                userPoolClientId
                userPoolDomain
                sessionCookieName
                scope
                sessionTimeout
                authenticationRequestExtraParams
                onUnauthenticatedRequest
              }
              order
              redirectConfig {
                protocol
                port
                host
                path
                query
                statusCode
              }
              fixedResponseConfig {
                messageBody
                statusCode
                contentType
              }
            }
            alpnPolicy
            mutualAuthentication {
              mode
              trustStoreArn
              ignoreClientCertificateExpiry
            }
          }
        nextMarker
        }
      }
    }
  }
`;
export default query;