import React from 'react';
import { BottomActionButtonGroupPropsType } from '../types';

const BottomActionButtonGroup = (props: BottomActionButtonGroupPropsType) => {
  const { onNextButtonClicked, onPreviousButtonClicked, onCancelButtonClicked, previousButtonVisible, errorMessage } =
    props;

  return (
    <div className="firewall-rule-group-bottom">
      {errorMessage && (
        <p
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 8,
            padding: 8,
            color: '#FF3A3A',
            fontSize: 16,
          }}
        >
          {errorMessage}
        </p>
      )}
      <button className="button" style={{ margin: 0 }} onClick={onCancelButtonClicked}>
        Cancel
      </button>
      {previousButtonVisible && (
        <button className="button" style={{ marginLeft: 8 }} onClick={onPreviousButtonClicked}>
          Previous
        </button>
      )}
      <button className="button next-button" style={{ marginLeft: 8 }} onClick={onNextButtonClicked}>
        Next
      </button>
    </div>
  );
};

export default BottomActionButtonGroup;
