import { gql } from '@apollo/client';

const query = gql`
  query getAwsDescribeReplaceRootVolumeTasks(
    $request: AwsDescribeReplaceRootVolumeTasksRequest
    $cloudId: Long
    $region: String
  ) {
    getAwsDescribeReplaceRootVolumeTasks(
      describeReplaceRootVolumeTasksRequest: $request
      cloudId: $cloudId
      region: $region
    ) {
      result
      data {
        ... on AwsDescribeReplaceRootVolumeTasksResponse {
          replaceRootVolumeTasks {
            replaceRootVolumeTaskId
            instanceId
            taskState
            startTime
            completeTime
            tags {
              key
              value
            }
            imageId
            snapshotId
            deleteReplacedRootVolume
          }
          nextToken
        }
      }
    }
  }
`;
export default query;
