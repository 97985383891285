import {REQUEST_TYPE} from "pages/v2/Organ/Management/EC2/components/SpotRequest/Common/Constant";
import DetailTab from "pages/v2/Organ/Management/EC2/components/DetailTab";
import {IMgdDetailKeyValueProps, TextTypeEnum} from "layouts/v3/MgdLayout";
import { getBetweenTwoDate } from "utils/Common";
import { DescriptionPropsType } from "../../../types";

const Description = (props: DescriptionPropsType) => {
  const {currentSpotInstance} = props;
  const {
    spotInstanceRequestId,
    status,
    spotPrice,
    launchSpecification,
    productDescription,
    launchedAvailabilityZone,
    type,
    instanceId,
    launchGroup,
    createTime,
    validFrom,
    instanceInterruptionBehavior,
    validUntil,
    state,
  } = currentSpotInstance
  

  const formatCurrency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })

  const detailsData: IMgdDetailKeyValueProps[] = [
    {
      title: 'Request ID',
      description: spotInstanceRequestId,
    },
    {
      title: 'Status',
      description: status?.code,
    },
    {
      title: 'Subnet',
      description: launchSpecification?.networkInterfaces?.map((subnet) => subnet.subnetId),
    },
    {
      title: 'Max price',
      description: formatCurrency.format(parseFloat(spotPrice)),
    },
    {
      title: 'EBS-optimized',
      description: launchSpecification?.ebsOptimized,
    },
    {
      title: 'Product description',
      description: productDescription,
    },
    {
      title: 'Request type',
      description: REQUEST_TYPE.INSTANCE,
    },
    {
      title: 'Monitoring',
      description: launchSpecification?.monitoring?.enabled ? 'yes' : 'no',
    },
    {
      title: 'Availability zone',
      description: launchedAvailabilityZone,
    },
    {
      title: 'Persistence',
      description: type,
    },
    {
      title: 'Instance Id',
      description: instanceId,
      type: TextTypeEnum.LINK
    },
    {
      title: 'Launch group',
      description: launchGroup,
    },
    {
      title: 'Created',
      description: getBetweenTwoDate(createTime),
    },
    {
      title: 'Tenancy',
      description: launchSpecification?.placement?.tenancy,
    },
    {
      title: 'Request valid from',
      description: validFrom,
    },
    {
      title: 'Key pair name',
      description: launchSpecification?.keyName,
    },
    {
      title: 'Interruption behavior',
      description: instanceInterruptionBehavior,
    },
    {
      title: 'Request valid until',
      description: validUntil,
    },
    {
      title: 'State',
      description: state,
    },
    {
      title: 'Instance type(s)',
      description: launchSpecification?.instanceType,
    },
    {
      title: 'Availability zone group',
      description: launchSpecification?.placement?.groupName,
    },
    {
      title: 'IAM role',
      description: launchSpecification?.iamInstanceProfile?.name,
      type: TextTypeEnum.LINK
    },
    {
      title: 'AMI ID',
      description: launchSpecification?.imageId,
    },
  ]

  return (
    <DetailTab title='Description' data={detailsData} />
  );
}

export default Description;