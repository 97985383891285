import { useEffect, useState, useMemo, useCallback } from 'react';
import './index.scss';
import { RESOURCE_TYPE_DATA } from '../../Commons/Constant';
import { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import { lazyGetEnumTypeCode } from 'graphql/queries/getEnumTypeCode';
import { DescribeWebAclStep, PageTitle } from './components';
import { CREATE_WEB_ACL_STEPS } from './configs';
import Button, { ButtonTypeEnum } from './components/Button';
import AddRulesAndRuleGroupsStep from './steps/AddRulesAndRuleGroupsStep';
import { AwsDistributionSummaryType } from 'graphql/types/AwsListDistributionsByWebACLId';
import SetRulePriority from './steps/SetRulePriority';
import ConfigureMetrics from './steps/ConfigureMetrics';

interface ICreateWebAclProps {
  regions: string[];
  onBackButtonClicked: () => void;
  onAddResourceButtonClicked: () => void;
  cloudId: number;
  region: string;
  awsResources: Array<AwsDistributionSummaryType>;
  onReview: (data: any) => void;
  selectedRegion: DropdownListDataType
}

const CreateWebAcl = (props: ICreateWebAclProps) => {
  const { onBackButtonClicked, onAddResourceButtonClicked, cloudId, region, awsResources, onReview, selectedRegion } = props;
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);

  const [getEnumTypeCode] = lazyGetEnumTypeCode();

  const [creationData, setCreationData] = useState<any>(null);

  const handleSetCreationData = useCallback((data: any) => {
    setCreationData((prevState: any) => ({
      ...prevState,
      ...data,
    }));
  }, []);

  const stepNode = useMemo(() => {
    switch (currentStepIndex) {
      default:
      case 0: {
        return (
          <DescribeWebAclStep
            title={CREATE_WEB_ACL_STEPS[currentStepIndex].title}
            onAddResourceButtonClicked={onAddResourceButtonClicked}
            cloudId={cloudId}
            region={region}
            resources={awsResources}
            setCreationData={handleSetCreationData}
          />
        );
      }

      case 1: {
        return (
          <AddRulesAndRuleGroupsStep
            title={CREATE_WEB_ACL_STEPS[currentStepIndex].title}
            description={CREATE_WEB_ACL_STEPS[currentStepIndex].description!}
            setCreationData={handleSetCreationData}
            cloudId={cloudId}
            region={creationData.region}
          />
        );
      }

      case 2: {
        return (
          <SetRulePriority
            title={CREATE_WEB_ACL_STEPS[currentStepIndex].title}
            setCreationData={handleSetCreationData}
          />
        );
      }

      case 3: {
        return (
          <ConfigureMetrics
            title={CREATE_WEB_ACL_STEPS[currentStepIndex].title}
            setCreationData={handleSetCreationData}
          />
        );
      }
    }
  }, [currentStepIndex, awsResources]);

  const onNextButtonClicked = useCallback(() => {
    if (currentStepIndex < CREATE_WEB_ACL_STEPS.length - 1) {
      setCurrentStepIndex(currentStepIndex + 1);

      return;
    }

    onReview(creationData);
  }, [currentStepIndex]);

  const onPreviousButtonClicked = useCallback(() => {
    setCurrentStepIndex(currentStepIndex - 1);
  }, [currentStepIndex]);

  const onCancelButtonClicked = useCallback(() => {
    onBackButtonClicked();
  }, [currentStepIndex]);

  const buttonGroupNode = useMemo(() => {
    const isFirstItem = currentStepIndex === 0;

    return (
      <div className="button-group">
        <Button label="Cancel" onClick={onCancelButtonClicked} />
        {!isFirstItem && <Button label="Previous" onClick={onPreviousButtonClicked} />}
        <Button label="Next" type={ButtonTypeEnum.PRIMARY} onClick={onNextButtonClicked} />
      </div>
    );
  }, [currentStepIndex]);

  return (
    <div id="create-webacl-page-container">
      <PageTitle title={'Create web ACL'} hasBackButton onBackButtonClicked={onBackButtonClicked} />
      {stepNode}
      {buttonGroupNode}
    </div>
  );
};

export default CreateWebAcl;
