import { gql } from '@apollo/client';

const query = gql`
query getAwsLoggingConfiguration($request: AwsGetLoggingConfigurationRequest, $cloudId: Long, $region: String) {
  getAwsLoggingConfiguration(
    loggingConfigurationRequest: $request
    cloudId: $cloudId
    region: $region
  ) {
    result
    data {
      ... on AwsGetLoggingConfigurationResponse {
        loggingConfiguration {
          resourceArn
          logDestinationConfigs
          redactedFields {
            singleHeader {
              name
            }
            singleQueryArgument {
              name
            }
            allQueryArguments
            uriPath
            queryString
            body {
              oversizeHandling
            }
            method
            jsonBody {
              matchPattern {
                all
                includedPaths
              }
              matchScope
              invalidFallbackBehavior
              oversizeHandling
            }
            headers {
              matchPattern {
                all
                includedHeaders
                excludedHeaders
              }
              matchScope
              invalidFallbackBehavior
              oversizeHandling
            }
            cookies {
              matchPattern {
                all
                includedCookies
                excludedCookies
              }
              matchScope
              oversizeHandling
            }
            headerOrder {
              oversizeHandling
            }
            ja3Fingerprint {
              fallbackBehavior
            }
          }
          managedByFirewallManager
          loggingFilter {
            filters {
              name
              values
            }
            defaultBehavior
          }
        }
      }
    }
  }
}
`;
export default query;