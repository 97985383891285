import { useLazyQuery } from '@apollo/client';
import query from './query';
import { Paging } from 'graphql/types/Paging';
import { AwsAlias } from 'graphql/types/AwsAliases';
import { IGqlResponseNewData } from 'graphql/types';

export interface IAwsAllListAliasesVariables {
  cloudId: number;
  region: string;
  request: {
    keyId?: string;
    limit?: number;
    marker?: string;
    pageInfo?: Paging;
  };
}

export interface IAwsAllListAliasesResponseData {
  getAwsAllListAliases: IGqlResponseNewData<AwsAlias>;
}

const lazyGetAwsAllListAliasesPage = () =>
  useLazyQuery<IAwsAllListAliasesResponseData, IAwsAllListAliasesVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsAllListAliasesPage;
