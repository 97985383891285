import React, { useCallback, useMemo, useState } from 'react';
import { FirewallPolicyCreationPropsType, TablePaginationConfigType } from './types';
import NetworkTitle from 'components/v3/NetworkTitle';
import DescribeFirewallPolicyStep from './components/DescribeFirewallPolicyStep';
import AddRuleGroupsStep from './components/AddRuleGroupsStep';
import ConfigureAdvancedSettingsStep from './components/ConfigureAdvancedSettingsStep';
import AddTLSInspectionConfigurationStep from './components/AddTLSInspectionConfigurationStep';
import AddTagsStep from './components/AddTagsStep';
import BottomActionButtonGroup from './components/BottomActionButtonGroup';
import { FIREWALL_POLICY_CREATION, MOCK_STATEFUL_RULE_GROUP, MOCK_STATELESS_RULE_GROUP } from './configs';
import { OrderDirection } from '@Types/v2/Table';

const FIREWALL_POLICY_DEFAULT_STATE = {
  name: '',
  description: '',
  streamExceptionPolicy: 'Drop',
  fragmentedPackets: '',
  ruleAction: 'Different',
  ruleActionFullPackets: 'Pass',
  ruleActionFragmentedPackets: 'Pass',
  statefulRuleEvaluationOrder: 'Strict',
  statefulRuleDropAction: 'Established',
  alertOptionAll: false,
  alertOptionEstablished: false,
  statelessRuleGroupCapacityUnitConsumed: '100/2000',
  statefulRuleGroupCapacityUnitConsumed: '200/2000',
  customizeEncryptionSettings: true,
  policyVariables: '',
  tlsInspectionConfiguration: null,
};

const MAXIMUM_STEP_COUNT = 5;

enum DATA_VALIDATION_CONDITION {
  'REQUIRED',
  'MAX-LENGTH',
  'MIN-LENGTH',
}

type DataValidationConditionType = 'REQUIRED' | 'MAX-LENGTH' | 'MIN-LENGTH';

const FirewallPolicyCreation = (props: FirewallPolicyCreationPropsType) => {
  const { onBackButtonClicked } = props;
  const [screenName, setScreenName] = useState('CREATION');
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [firewallPolicy, setFirewallPolicy] = useState(FIREWALL_POLICY_DEFAULT_STATE);
  const [errorMessage, setErrorMessage] = useState('');
  const [statelessRuleGroupTablePaginationConfig, setStatelessRuleGroupTablePaginationConfig] =
    useState<TablePaginationConfigType>({
      limit: 50,
      itemPerPage: 10,
      target: 'modifiedAt',
      direction: OrderDirection.DES,
      currentPage: 1,
      totalPageCount: 1,
      totalItemCount: 0,
    });
  const [statefulRuleGroupTablePaginationConfig, setStatefulRuleGroupTablePaginationConfig] =
    useState<TablePaginationConfigType>({
      limit: 50,
      itemPerPage: 10,
      target: 'modifiedAt',
      direction: OrderDirection.DES,
      currentPage: 1,
      totalPageCount: 1,
      totalItemCount: 0,
    });
  const [selectedStatelessRuleGroups, setSelectedStatelessRuleGroups] = useState<string>('');
  const [checkedStatelessRuleGroups, setCheckedStatelessRuleGroups] = useState<Array<string>>([]);

  const handleValueChanged = useCallback(
    (key: string, value: string) => {
      setFirewallPolicy(prevState => ({
        ...prevState,
        [key]: value,
      }));
      if (errorMessage) {
        setErrorMessage('');
      }
    },
    [setFirewallPolicy, setErrorMessage],
  );

  const statelessRuleGroups = useMemo(() => {
    // TODO: Get data from API
    setStatelessRuleGroupTablePaginationConfig(prevState => ({
      ...prevState,
      totalItemCount: MOCK_STATELESS_RULE_GROUP.length,
      totalPageCount: Math.ceil(MOCK_STATELESS_RULE_GROUP.length / prevState.itemPerPage),
    }));

    return MOCK_STATELESS_RULE_GROUP;
  }, []);

  const statelessRuleGroupRows = useMemo(() => {
    const firstItemIndex =
      (statelessRuleGroupTablePaginationConfig.currentPage - 1) * statelessRuleGroupTablePaginationConfig.itemPerPage;
    const lastItemIndex =
      statelessRuleGroupTablePaginationConfig.currentPage * statelessRuleGroupTablePaginationConfig.itemPerPage;

    return statelessRuleGroups.slice(firstItemIndex, lastItemIndex);
  }, [statelessRuleGroups, statelessRuleGroupTablePaginationConfig]);

  const statefulRuleGroups = useMemo(() => {
    // TODO: Get data from API
    setStatefulRuleGroupTablePaginationConfig(prevState => ({
      ...prevState,
      totalItemCount: MOCK_STATEFUL_RULE_GROUP.length,
      totalPageCount: Math.ceil(MOCK_STATEFUL_RULE_GROUP.length / prevState.itemPerPage),
    }));

    return MOCK_STATEFUL_RULE_GROUP;
  }, []);

  const statefulRuleGroupRows = useMemo(() => {
    const firstItemIndex =
      (statefulRuleGroupTablePaginationConfig.currentPage - 1) * statefulRuleGroupTablePaginationConfig.itemPerPage;
    const lastItemIndex =
      statefulRuleGroupTablePaginationConfig.currentPage * statefulRuleGroupTablePaginationConfig.itemPerPage;

    return statefulRuleGroups.slice(firstItemIndex, lastItemIndex);
  }, [statefulRuleGroups, statefulRuleGroupTablePaginationConfig]);

  const stepNode = useMemo(() => {
    switch (currentStepIndex) {
      case 0: {
        const { name = '', description = '', streamExceptionPolicy = '' } = firewallPolicy;
        return (
          <DescribeFirewallPolicyStep
            onValueChanged={handleValueChanged}
            name={name}
            description={description}
            streamExceptionPolicy={streamExceptionPolicy}
          />
        );
      }

      case 1: {
        const {
          ruleAction = '',
          ruleActionFullPackets = '',
          ruleActionFragmentedPackets = '',
          statefulRuleDropAction,
          statefulRuleEvaluationOrder,
          alertOptionAll,
          alertOptionEstablished,
          statelessRuleGroupCapacityUnitConsumed,
          statefulRuleGroupCapacityUnitConsumed,
        } = firewallPolicy;
        return (
          <AddRuleGroupsStep
            ruleAction={ruleAction}
            ruleActionFullPackets={ruleActionFullPackets}
            ruleActionFragmentedPackets={ruleActionFragmentedPackets}
            onValueChanged={handleValueChanged}
            isSameActionForAll={ruleAction === 'Same'}
            onMoveUpButtonClicked={() => {}}
            onMoveDownButtonClicked={() => {}}
            onDeleteButtonClicked={() => {}}
            onAddStatelessRuleGroupButtonClicked={() => {}}
            statelessRuleGroupList={statelessRuleGroupRows}
            statelessRuleGroupTablePaginationConfig={statelessRuleGroupTablePaginationConfig}
            statefulRuleGroupList={statefulRuleGroupRows}
            statefulRuleGroupTablePaginationConfig={statefulRuleGroupTablePaginationConfig}
            updateStatelessRuleGroupPaginationConfig={(key: string, value: number | string | OrderDirection) =>
              setStatelessRuleGroupTablePaginationConfig(prev => ({
                ...prev,
                [key]: value,
              }))
            }
            updateStatefulRuleGroupPaginationConfig={(key: string, value: number | string | OrderDirection) =>
              setStatefulRuleGroupTablePaginationConfig(prev => ({
                ...prev,
                [key]: value,
              }))
            }
            reportSelected={setSelectedStatelessRuleGroups}
            reportCheckedList={setCheckedStatelessRuleGroups}
            statefulRuleDropAction={statefulRuleDropAction}
            statefulRuleEvaluationOrder={statefulRuleEvaluationOrder}
            alertOptionAll={alertOptionAll}
            alertOptionEstablished={alertOptionEstablished}
            statelessRuleGroupCapacityUnitConsumed={statelessRuleGroupCapacityUnitConsumed}
            statefulRuleGroupCapacityUnitConsumed={statefulRuleGroupCapacityUnitConsumed}
          />
        );
      }

      case 2: {
        const { customizeEncryptionSettings, policyVariables } = firewallPolicy;
        return (
          <ConfigureAdvancedSettingsStep
            customizeEncryptionSettings={customizeEncryptionSettings}
            onValueChanged={handleValueChanged}
            policyVariables={policyVariables}
          />
        );
      }

      case 3: {
        const { tlsInspectionConfiguration } = firewallPolicy;
        return (
          <AddTLSInspectionConfigurationStep
            onValueChanged={handleValueChanged}
            tlsInspectionConfiguration={tlsInspectionConfiguration}
            onRemoveButtonClicked={() => {}}
          />
        );
      }

      case 4: {
        const {} = firewallPolicy;
        return <AddTagsStep onValueChanged={handleValueChanged} onAddButtonClicked={() => {}} />;
      }

      default:
        return <></>;
    }
  }, [
    currentStepIndex,
    firewallPolicy,
    handleValueChanged,
    statefulRuleGroupTablePaginationConfig,
    statelessRuleGroupTablePaginationConfig,
  ]);

  const isDataValid = useCallback((label: string, value: string, condition: DataValidationConditionType): boolean => {
    switch (condition) {
      case 'REQUIRED':
        if (!value) {
          setErrorMessage(`${label} is a required field`);
          return false;
        }
        break;

      default:
        break;
    }

    return true;
  }, []);

  const handleCancelButtonClicked = useCallback(() => {
    onBackButtonClicked();
  }, []);

  const handlePreviousButtonClicked = useCallback(() => {
    if (currentStepIndex > 0) {
      setCurrentStepIndex(currentStepIndex - 1);
    }
    setErrorMessage('');
  }, [currentStepIndex]);

  const handleNextButtonClicked = useCallback(() => {
    if (!isDataValid('Name', firewallPolicy.name, 'REQUIRED')) {
      return;
    }
    if (currentStepIndex < MAXIMUM_STEP_COUNT - 1) {
      setCurrentStepIndex(currentStepIndex + 1);
    }
    setErrorMessage('');
  }, [currentStepIndex, firewallPolicy]);

  return (
    <div id="mgd-list-layout">
      <div className="row-1 flex j-between a-center">
        <div className="flex j-start a-center" id="title">
          <NetworkTitle
            pageTitle={
              screenName === 'CREATION'
                ? FIREWALL_POLICY_CREATION.CREATE_FIREWALL_POLICY_TITLE
                : FIREWALL_POLICY_CREATION.CREATE_FIREWALL_POLICY_REVIEW_TITLE
            }
            id={321}
            name={'name'}
            hasPrefixIcon={false}
            hasFavorite={false}
            pageBackClick={onBackButtonClicked}
          />
        </div>
      </div>

      <div className="one-page" style={{ height: 'calc(100% - 70px)', overflowY: 'auto' }}>
        <div className="flex j-between a-center">
          <div className="firewall-rule-group">
            {screenName === 'CREATION' && stepNode}
            <BottomActionButtonGroup
              onCancelButtonClicked={handleCancelButtonClicked}
              onNextButtonClicked={handleNextButtonClicked}
              onPreviousButtonClicked={handlePreviousButtonClicked}
              previousButtonVisible={currentStepIndex !== 0}
              errorMessage={errorMessage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirewallPolicyCreation;
