import { useLazyQuery } from '@apollo/client';
import { Paging } from 'graphql/types/Paging';
import { ResponsePage } from 'graphql/types/ResponsePage';
import query from './query';
import { ProjectCloudType } from 'graphql/types/ProjectCloud';

export interface IGetCloudInfoPageByParamVariables {
  reqData: {
    organId: number;
    projectId?: number;
    text?: string;
    pageInfo: Paging;
  }
}

export interface IGetCloudInfoPageByParamResponseData {
  getCloudInfoPageByParam: ResponsePage<ProjectCloudType>;
}

const lazyGetCloudInfoPageByParam = () => useLazyQuery<IGetCloudInfoPageByParamResponseData, IGetCloudInfoPageByParamVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetCloudInfoPageByParam;