import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { TAGS_TABLE_COLUMNS, TARGET_GROUP_DETAIL_TAB_LIST, TARGET_GROUP_TAB_LIST } from './config';
import Details from './SummaryTabs/Details';
import Targets from './SummaryTabs/Targets';
import HealthChecks from './SummaryTabs/HealthChecks';
import { LBTargetGroupSummaryPropsType } from '../types';
import Attributes from './SummaryTabs/Attributes';
import TagTab from '../TagTab';
import lazyGetAwsDescribeTags, { IGetAwsDescribeTagsVariables } from 'graphql/queries/getAwsDescribeTags';
import { RowType } from '@Types/v2/Table';

const LBTargetGroupSummary = (props: LBTargetGroupSummaryPropsType) => {
  const { targetGroup, cloudId, region, isSummaryHidden } = props;
  const [getAwsDescribeTags] = lazyGetAwsDescribeTags();
  const [tabSelected, setTabSelected] = useState(
    isSummaryHidden ? TARGET_GROUP_DETAIL_TAB_LIST[0] : TARGET_GROUP_TAB_LIST[0],
  );
  const [tagRows, setTagRows] = useState<RowType[]>([]);

  const tabList = useMemo(() => {
    if (isSummaryHidden) {
      return TARGET_GROUP_DETAIL_TAB_LIST;
    }

    return TARGET_GROUP_TAB_LIST;
  }, [isSummaryHidden]);

  const fetchGetAwsDescribeTags = useCallback(() => {
    if (targetGroup) {
      const variable: IGetAwsDescribeTagsVariables = {
        cloudId: cloudId,
        region: region,
        request: {
          resourceArns: [targetGroup?.targetGroupArn],
        },
      };
      getAwsDescribeTags({ variables: variable }).then(({ data: awsDescribeTagData }) => {
        setTagRows(awsDescribeTagData?.getAwsDescribeTags?.data?.[0]?.tagDescriptions?.[0]?.tags || []);
      });
    }
  }, [targetGroup, cloudId, region]);

  useEffect(() => {
    if (isSummaryHidden) {
      setTabSelected(TARGET_GROUP_DETAIL_TAB_LIST[0]);
    } else {
      setTabSelected(TARGET_GROUP_TAB_LIST[0]);
    }
    fetchGetAwsDescribeTags();
  }, [targetGroup]);

  const renderTab = useMemo(() => {
    if (isSummaryHidden) {
      switch (tabSelected) {
        case tabList[0]:
          return <Targets targetGroup={targetGroup} cloudId={cloudId} region={region} />;
        case tabList[1]:
          return <HealthChecks targetGroup={targetGroup} />;
        case tabList[2]:
          return <Attributes targetGroup={targetGroup} cloudId={cloudId} region={region} />;
        case tabList[3]:
          return <TagTab title={'Tags'} rows={tagRows} columns={TAGS_TABLE_COLUMNS} />;
        default:
          return null;
      }
    }

    switch (tabSelected) {
      case tabList[0]:
        return (
          <Details targetGroup={targetGroup} isSummaryHidden={isSummaryHidden} cloudId={cloudId} region={region} />
        );
      case tabList[1]:
        return <Targets targetGroup={targetGroup} cloudId={cloudId} region={region} />;
      case tabList[2]:
        return <HealthChecks targetGroup={targetGroup} />;
      case tabList[3]:
        return <Attributes targetGroup={targetGroup} cloudId={cloudId} region={region} />;
      case tabList[4]:
        return <TagTab title={'Tags'} rows={tagRows} columns={TAGS_TABLE_COLUMNS} />;
      default:
        return null;
    }
  }, [isSummaryHidden, tabSelected, targetGroup, tabList, cloudId, region, tagRows]);

  if (!targetGroup) {
    return (
      <div className="detail-data">
        <p className="empty-page">Select a item</p>
      </div>
    );
  }

  return (
    <div className="box-summary">
      {!isSummaryHidden && <div className="box-summary-id">Target-group name: {targetGroup.targetGroupName}</div>}
      <div className="detail-tab flex a-center">
        {tabList.map(tab => {
          return (
            <button
              className={`detail-tab-items ${tabSelected?.id === tab.id && 'active'}`}
              key={tab.id}
              data-tab={tab.id}
              onClick={e => setTabSelected(tab)}
            >
              <p>{tab.name}</p>
            </button>
          );
        })}
      </div>
      <div className="content-tab">{renderTab}</div>
    </div>
  );
};

export default LBTargetGroupSummary;
