import './index.scss';
import Table from 'components/v2/dataDisplay/Table';
import { IMgdDetailContentProps, IMgdDetailTableProps, IMgdTablePaginationProps } from '../..';
import { OrderDirection } from '@Types/v2/Table';
import { Fragment, useCallback, useState } from 'react';
import Icon from 'components/v2/atoms/Icon';
import { ActionDropdownListDataType } from '../ActionDropdownAtom';
import ActionDropdownAtom from '../ActionDropdownAtom';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';

const TableDetail = (props: IMgdDetailContentProps) => {
  const { contents, detailTabSelected, isCustomPagination } = props;

  const [customTablePagination, setCustomTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });

  const renderDropDownGroup = useCallback(
    (
      dropdowns:
        | {
            dropList?: ActionDropdownListDataType[];
            dropValue?: ActionDropdownListDataType;
            dropOnchange?: (option: ActionDropdownListDataType) => void;
          }[]
        | undefined,
      parendId: number,
    ) => {
      if (dropdowns && dropdowns?.length > 0) {
        return (
          <>
            {dropdowns.map((dropdown, index) => {
              const { dropList, dropValue, dropOnchange } = dropdown;
              if (dropList && dropValue && dropOnchange) {
                return (
                  <ActionDropdownAtom
                    id={`customize-actions-detail-dropdown-${parendId}-${index}`}
                    key={`table-detail-customize-dropdown-group-${index}`}
                    className={'dropdown'}
                    data={dropList}
                    value={dropValue}
                    handleClick={val => dropOnchange(val)}
                  />
                );
              }
            })}
          </>
        );
      }
      return <></>;
    },
    [],
  );

  const renderHeader = useCallback((content: IMgdDetailTableProps, parentId: number) => {
    const renderButtonGroup = (
      buttons: { icon?: string; title?: string; onClick?: () => void; iconClassName?: string }[] | undefined,
    ) => {
      if (buttons && buttons?.length > 0) {
        return (
          <>
            {buttons.map((button, index) => {
              if (button?.icon || button?.iconClassName) {
                return (
                  <Icon
                    key={`table-detail-button-group-${index}`}
                    className={`button-icon reset-btn ${button?.iconClassName}`}
                    width={16}
                    height={28}
                    src={button?.icon || ''}
                    onClick={button?.onClick}
                  />
                );
              } else {
                return (
                  <button className={'button'} key={`table-detail-button-group-${index}`} onClick={button?.onClick}>
                    <p>{button.title}</p>
                  </button>
                );
              }
            })}
          </>
        );
      }
      return <></>;
    };

    return (
      <>
        {content?.title && (
          <div className="header-container">
            <div className="table-detail-title">
              <p>{content.title}</p>
              {content?.description && <p>{content.description}</p>}
            </div>
            <div className="table-detail-button-group">
              {content?.isShowButtonGroups && renderButtonGroup(content?.buttons)}
              {content?.isShowDropdown && renderDropDownGroup(content?.dropdowns, parentId)}
            </div>
          </div>
        )}
      </>
    );
  }, []);

  return (
    <Fragment>
      {contents?.map((detail, index) => {
        if (
          'rows' in detail &&
          'columns' in detail &&
          'total' in detail &&
          'tablePagination' in detail &&
          'updateTablePagination' in detail
        ) {
          const isShowPagination = () => {
            if (detailTabSelected?.title == 'Tags') return false;
            if (detail?.title?.includes('tags')) return false;
            if (detail?.hasPagination == false) return false;
            return true;
          };
          if (detail.rows.length == 0) {
            return (
              <Fragment key={`table_detail_info_${index}`}>
                {renderHeader(detail, Math.floor(Math.random() * 100))}
                <p key={`table_detail_info_${index}`} className="empty-row">
                  Empty
                </p>
              </Fragment>
            );
          }

          let currentPageRows = detail.rows;

          if (isCustomPagination) {
            const firstRowIndex = customTablePagination.itemPerPage * (customTablePagination.currentPage - 1);
            const lastRowIndex = customTablePagination.itemPerPage * customTablePagination.currentPage - 1;
            currentPageRows = orderAlphabetical(
              detail.rows,
              customTablePagination.target,
              customTablePagination.direction,
            ).filter((_, idx) => {
              return idx >= firstRowIndex && idx <= lastRowIndex;
            });
          }

          let tablePagination = detail.tablePagination;

          if (isCustomPagination) {
            tablePagination = customTablePagination;
          }

          return (
            <Fragment key={`table_detail_info_${index}`}>
              {renderHeader(detail, Math.floor(Math.random() * 100))}
              {detail?.reportCheckedList ? (
                <Table
                  rows={currentPageRows}
                  columns={detail?.columns}
                  reportCheckedList={detail?.reportCheckedList}
                  sortOption={{
                    target: tablePagination?.target || 'modifiedAt',
                    direction: tablePagination?.direction || OrderDirection.DES,
                    onChangeSort: (target: string, direction: OrderDirection) => {
                      if (isCustomPagination) {
                        setCustomTablePagination(prevState => ({
                          ...prevState,
                          target,
                          direction,
                        }));

                        return;
                      }
                      detail.updateTablePagination && detail.updateTablePagination('target', target);
                      detail.updateTablePagination && detail.updateTablePagination('direction', direction);
                    },
                  }}
                />
              ) : (
                <Table
                  rows={currentPageRows}
                  columns={detail.columns}
                  sortOption={{
                    target: tablePagination?.target || 'modifiedAt',
                    direction: tablePagination?.direction || OrderDirection.DES,
                    onChangeSort: (target: string, direction: OrderDirection) => {
                      if (isCustomPagination) {
                        setCustomTablePagination(prevState => ({
                          ...prevState,
                          target,
                          direction,
                        }));

                        return;
                      }
                      detail.updateTablePagination && detail.updateTablePagination('target', target);
                      detail.updateTablePagination && detail.updateTablePagination('direction', direction);
                    },
                  }}
                />
              )}
              {isShowPagination() && (
                <div className="pagination-wrapper flex a-center">
                  <p className="flex a-center text-paging">
                    Total <span>{detail.total?.totalElement}</span>
                  </p>
                  <TableManagePagination
                    ableFetchMore={false}
                    currentPage={tablePagination?.currentPage || 0}
                    updateCurrentPage={currentPage => {
                      if (isCustomPagination) {
                        setCustomTablePagination(prevState => ({
                          ...prevState,
                          currentPage,
                        }));

                        return;
                      }
                      detail.updateTablePagination && detail.updateTablePagination('currentPage', currentPage);
                    }}
                    totalPage={detail.total?.totalPage || 0}
                  />
                </div>
              )}
            </Fragment>
          );
        }
      })}
    </Fragment>
  );
};

export default TableDetail;
