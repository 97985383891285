import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsListDistributionsByWebACLIdType } from 'graphql/types/AwsListDistributionsByWebACLId';

export interface IGetAwsListDistributionsByWebACLIdVariables {
  cloudId: number;
  region: string;
  request: {
    webACLId: string;
  };
}

export interface IAwsListDistributionsByWebACLIdResponseData {
  getAwsListDistributionsByWebACLId: IGqlResponseNewData<AwsListDistributionsByWebACLIdType>;
}

const lazyGetAwsListDistributionsByWebACLId = () =>
  useLazyQuery<IAwsListDistributionsByWebACLIdResponseData, IGetAwsListDistributionsByWebACLIdVariables>(query, {
    fetchPolicy: 'no-cache',
  });
export default lazyGetAwsListDistributionsByWebACLId;
