import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import './SnapshotSetting.scss';

export type SnapshotSettingPropsType = {
  cloudId: number;
  region: string;
  snapshotId: string;
};

const SnapshotSetting = (props: SnapshotSettingPropsType) => {
  const { snapshotId, cloudId, region } = props;

  // API
  // TODO: DescribeLockedSnapshotsRequest API

  // State
  const [apiIsLoading, setApiIsLoading] = useState<boolean>(false);

  const getLockedSnapshot = useCallback(() => {
    setApiIsLoading(true);
    setApiIsLoading(false);
  }, []);

  useEffect(() => {
    getLockedSnapshot();
  }, []);

  return (
    <Fragment>
      {apiIsLoading ? (
        <div className="loading-info">
          <div className="progress-container">
            <div className="progress-gif" />
            Loading ...
          </div>
        </div>
      ) : (
        <Fragment>
          <div className="snapshot-info">
            <p className="title">Snapshot Lock- New</p>
            <div className="content">
              <p className="title">Lock mode</p>
              <p className="value">Not locked</p>
            </div>
          </div>

          <div className="shared-permission">
            <p className="title">Share permissions</p>
            <div className="content">
              <p className="title">Snapshot share permissions</p>
              <p className="value">Private</p>
              <p className="title">The snapshot is shared only with AWS accounts that you specified.</p>
            </div>
          </div>

          <div className="shared-account-info">
            <p className="title">Shared accounts</p>
            <p className="description">The snapshot is shared only with the following AWS accounts.</p>
            <div className="shared-container">
              <p className="row">AWS account ID</p>

              <div className="item">No item</div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default SnapshotSetting;
