import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsDescribeVolumesType } from 'graphql/types/AwsDescribeVolumes';

export interface IAwsDescribeVolumesVariables {
  cloudId: number;
  region: string;
  request: {
    volumeIds: Array<string>;
  };
}

export interface IAwsDescribeVolumesResponseData {
  getAwsDescribeVolumes: IGqlResponseNewData<AwsDescribeVolumesType>;
}

const lazyGetAwsDescribeVolumes = () =>
  useLazyQuery<IAwsDescribeVolumesResponseData, IAwsDescribeVolumesVariables>(query, {
    fetchPolicy: 'no-cache',
  });
export default lazyGetAwsDescribeVolumes;
