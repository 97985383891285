import { useCallback, useEffect, useMemo, useState } from "react";
import { AreaInput, Input, PageTitle } from "../../WebACL/CreateWebACL/components";
import Button, { ButtonTypeEnum } from "../../WebACL/CreateWebACL/components/Button";
import InputGroup from "../../WebACL/CreateWebACL/components/InputGroup";
import { DropdownListDataType } from "components/Dropdown/types";
import Dropdown from "components/Dropdown";
import { lazyGetEnumTypeCode } from "graphql/queries/getEnumTypeCode";
import { regionCodeFormatter } from "utils/Formatter";
import { CLOUDFRONT_OPTION, CLOUDFRONT_REGION, WafScopeEnum } from "../../Commons/Constant";
import './index.scss';
import createRegexPatternSetMutation, { ICreateRegexPatternSetVariables } from "graphql/mutations/createRegexPatternSet";
import { useToast } from "hooks/v2/useToast";
import { ErrorCode } from "@Types/error";

interface ICreateRegexPattern {
  cloudId: number;
  region: string;
  onBackButtonClicked: () => void;
}

const CreateRegexPattern = (props: ICreateRegexPattern) => {
  const { onBackButtonClicked, cloudId, region } = props;
  // API
  const [getEnumTypeCode] = lazyGetEnumTypeCode();
  const [createRegexPatternSet] = createRegexPatternSetMutation();
  // State
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [regularExpression, setRegularExpression] = useState<string>('');
  const [regionList, setRegionList] = useState<Array<{ name: string; value: string }>>([]);
  const [regionSelected, setRegionSelected] = useState<DropdownListDataType>(regionList[0]);
  const [nameValid, setNameValid] = useState<boolean>(false);
  const [regionValid, setRegionValid] = useState<boolean>(false);
  const [regularExpValid, setRegularExpValid] = useState<boolean>(false);

  const getRegionList = useCallback(() => {
    getEnumTypeCode({ variables: { text: 'AwsRegionCode' } }).then(res => {
      const codes =
        res?.data?.getEnumTypeCode?.data?.map(code => ({
          name: regionCodeFormatter(code?.value) || code?.name,
          value: code?.value,
        })) ?? [];
      codes.unshift({ name: CLOUDFRONT_OPTION.name, value: CLOUDFRONT_OPTION.value });
      setRegionList(codes);
      setRegionSelected(codes.find(code => code.value === region) ?? codes[0]);
    });
  }, [getEnumTypeCode, region]);

  useEffect(() => {
    getRegionList();
  }, [getRegionList]);

  const onCreateButtonClicked = useCallback(() => {
    setNameValid(name === '');
    setRegionValid(!regionSelected);
    setRegularExpValid(regularExpression === '');
    if (name !== '' || !!regionSelected || regularExpression !== '') {
      const scope = regionSelected.value === CLOUDFRONT_OPTION.value ? WafScopeEnum.CLOUDFRONT : WafScopeEnum.REGIONAL;
      const regionValue = regionSelected.value === CLOUDFRONT_OPTION.value ? CLOUDFRONT_REGION : regionSelected.value.toString();
      const regexStringList = regularExpression?.split('\n')?.map(string => {return {regexString: string}})
      const reqVariable: ICreateRegexPatternSetVariables = {
        cloudId: cloudId,
        region: regionValue,
        reqData: {
          name: name,
          scope,
          description: description,
          regularExpressionList: regexStringList,
        }
      };
      createRegexPatternSet({variables: reqVariable}).then(({data: createRegexPatternSetResponse}) => {
        const response = createRegexPatternSetResponse?.createAwsRegexPatternSet?.data?.[0]?.summary
        if (response) {
          useToast(ErrorCode.SUCCESS, 'Create regex pattern set successful.');
          onBackButtonClicked();
        } else {
          useToast(ErrorCode.UNKNOWN, 'Create regex pattern set failed.');
        }
      });
    }
  }, [name, regionSelected, regularExpression, cloudId, createRegexPatternSet]);

  const buttonGroupNode = useMemo(() => {

    return (
      <div className="button-group">
        <Button label="Cancel" onClick={onBackButtonClicked} />
        <Button label="Create regex pattern set" type={ButtonTypeEnum.PRIMARY} onClick={onCreateButtonClicked} />
      </div>
    );
  }, [onCreateButtonClicked]);

  return (
    <div id="create-regex-pattern-page-container">
      <PageTitle title={'Create Regex pattern'} hasBackButton onBackButtonClicked={onBackButtonClicked} />
      <div className="page-container">
        <div className="title-container">
          <p>Regex pattern set details</p>
        </div>
        <InputGroup>
          <Input
            title={'Regex pattern set name'}
            placeholder="IP set name"
            bottomDescription="The name must have 1 - 128 characters, Valid characters: A-Z, a-z, 0-9, -(hypehn), and _ (underscore)"
            value={name}
            onValueChanged={setName}
            isRequired
            error={nameValid}
          />
          <Input
            title={'Description'}
            placeholder="Enter description"
            bottomDescription="The description can have 1-256 characters."
            value={description}
            onValueChanged={setDescription}
          />
          <Dropdown
            title={'Region'}
            isRequired
            data={regionList}
            id="region"
            handleClick={setRegionSelected}
            value={regionSelected}
            placeholder="Choose region"
            error={regionValid}
          />
          <AreaInput
            title={'Regular expressions'}
            placeholder="^(http|https):\/\/"
            bottomDescription="Enter one pattern per line."
            isRequired
            error={regularExpValid}
            value={regularExpression}
            onValueChanged={setRegularExpression}
          />
        </InputGroup>
        {buttonGroupNode}
      </div>
    </div>
  );
}

export default CreateRegexPattern;