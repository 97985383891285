import React, { useMemo, useState, useCallback, useEffect } from 'react';
import BaseModal from 'components/v2/modals/BaseModal';
import RadioAtom from 'components/v2/atoms/RadioAtom';
import { RULE_TYPE_DATA, RuleTypeEnum } from 'pages/v2/Organ/Management/WAF/CustomRuleModal/constant';
import IpSet from 'pages/v2/Organ/Management/WAF/CustomRuleModal/IpSet';
import RuleBuilder from 'pages/v2/Organ/Management/WAF/CustomRuleModal/RuleBuilder';
import RuleGroup from 'pages/v2/Organ/Management/WAF/CustomRuleModal/RuleGroup';
import { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';

import './index.scss';
import lazyGetAwsListIPSets from 'graphql/queries/getAwsListIPSets';
import { AwsIPSets } from 'graphql/types/AwsListIPSet';
import Footer from './components/Footer';
import _ from 'lodash';
import { convertKeysToPascalCase } from 'utils/Common';

type CustomRuleModalPropsType = {
  isOpen: boolean;
  onClose: () => void;
  cloudId: number;
  selectedRegion: DropdownListDataType;
  onAddRule: (rule: any) => void;
};

function CustomRuleModal({ isOpen, onClose, cloudId, selectedRegion, onAddRule }: CustomRuleModalPropsType) {
  const [ruleType, setRuleType] = useState(RuleTypeEnum.RULE_BUILDER);
  const [ipSets, setIpSets] = useState<Array<AwsIPSets>>([]);

  const [getAwsListIPSets] = lazyGetAwsListIPSets();

  const getIPSets = useCallback(() => {
    const variables = {
      cloudId: cloudId,
      region: selectedRegion.value !== 'CloudFront' ? String(selectedRegion.value) : 'us-east-1',
      request: {
        limit: 100,
        scope: selectedRegion.value !== 'CloudFront' ? 'REGIONAL' : 'CLOUDFRONT',
      },
    };

    getAwsListIPSets({ variables }).then(res => {
      setIpSets(res?.data?.getAwsListIPSets?.data?.[0]?.ipSets ?? []);
    });
  }, [getAwsListIPSets, selectedRegion, cloudId]);

  useEffect(() => {
    getIPSets();
  }, [getIPSets]);

  const title = () => <div>Rule types</div>;

  const DEFAULT_CUSTOM_RULE = {
    name: '',
    priority: 0,
    statement: {},
    action: {
      block: {},
    },
    visibilityConfig: {
      sampledRequestsEnabled: true,
      cloudWatchMetricsEnabled: true,
      metricName: '',
    },
  };

  const [customRule, setCustomRule] = useState({ ...DEFAULT_CUSTOM_RULE });

  const ruleContentNode = useMemo(() => {
    switch (ruleType) {
      case RuleTypeEnum.IP_SET:
        return <IpSet cloudId={cloudId} selectedRegion={selectedRegion} ipSets={ipSets} />;

      case RuleTypeEnum.RULE_BUILDER:
        return (
          <RuleBuilder cloudId={cloudId} selectedRegion={selectedRegion} rule={customRule} setRule={setCustomRule} />
        );

      case RuleTypeEnum.RULE_GROUP:
        return <RuleGroup cloudId={cloudId} selectedRegion={selectedRegion} />;

      default:
        return null;
    }
  }, [ruleType, ipSets, customRule]);

  const handleSubmitRule = useCallback(() => {
    onAddRule(customRule);
  }, [customRule]);

  return (
    <BaseModal open={isOpen} onClose={onClose} title={title} className="custom-rule-modal">
      <div className="rule-type">
        <p className="rule-type-title">Rule type</p>

        <div className="rule-type-option">
          {RULE_TYPE_DATA.map(({ id, label, value, name, description }) => (
            <RadioAtom
              key={id}
              label={label}
              value={value}
              name={name}
              description={description}
              checked={ruleType}
              onChange={value => setRuleType(value as RuleTypeEnum)}
            />
          ))}
        </div>
      </div>

      {ruleContentNode}

      <Footer onCancel={onClose} onSubmit={handleSubmitRule} />
    </BaseModal>
  );
}

export default CustomRuleModal;
