import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsLoadBalancersResponse } from 'graphql/types/AwsLoadBalancer';

export interface IGetAwsLoadBalancersVariables {
  cloudId: number;
  region: string;
  request: {
    loadBalancerArns?: string[];
    pageSize?: number;
  }
}

export interface IAwsLoadBalancersResponseData {
  getAwsLoadBalancers: IGqlResponseNewData<AwsLoadBalancersResponse>;
}

const lazyGetAwsLoadBalancers = () => useLazyQuery<IAwsLoadBalancersResponseData, IGetAwsLoadBalancersVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsLoadBalancers;