import DropdownContainer from '../../../components/DropdownContainer';
import { REQUEST_AGGREGATION_KEY } from './constant';
import InputContainer from '../Regular/InpectNode/InputContainer';
import TextTransformation from '../Regular/InpectNode/TextTransformation';
import HeaderFieldName from '../../../IpSet/HeaderFieldName';
import MissingIpAddress from '../../../IpSet/MissingIpAddress';
import { useEffect, useState } from 'react';
import _ from 'lodash';

const maxCount = 5;

function RequestAggregationKey({ item, data, setData }: any) {
  const [count, setCount] = useState(1);

  const isMaxCount = data?.length === maxCount;

  useEffect(() => {
    setCount(data?.length)
  }, [data])

  const handleAddItem = () => {
    if (isMaxCount) return;

    const arr: any = [...data];
    arr.push({...item, id: _.uniqueId()});
    setData(arr);
  };

  const handleChangeItem = (id: string, key: string, value: any) => {
    const arr: any = [...data];

    const index = arr.findIndex((e: { id: string }) => e.id === id);

    if (index < 0) return;

    arr[index] = {
      ...arr[index],
      [key]: value,
    };

    setData(arr);
  };

  const handleRemoveItem = (id: string) => {
    const arr: any = [...data].filter((e: { id: string }) => e.id !== id);

    setData(arr);
  };

  const renderElement = (values: any) => {
    const {
      id,
      type,
      headerName,
      transformation,
      queryArgument,
      cookieName,
      labelNamespace,
      headerFieldName,
      positionInsideHeader,
      missingIpAddress,
    } = values;
    switch (type?.value) {
      case 'Header':
        return (
          <>
            <InputContainer
              title="Header name"
              value={headerName}
              onChange={value => handleChangeItem(id, 'headerName', value)}
              note="Header name must have 1-64 characters. "
            />

            <TextTransformation
              description="AWS WAF applies all transformations to the request component before using it. Transformations are applied in the order presented below,  starting from the top."
              transformation={transformation}
              setTransformation={value => handleChangeItem(id, 'transformation', value)}
            />
          </>
        );

      case 'Query string':
        return (
          <>
            <TextTransformation
              description="AWS WAF applies all transformations to the request component before using it. Transformations are applied in the order presented below,  starting from the top."
              transformation={transformation}
              setTransformation={value => handleChangeItem(id, 'transformation', value)}
            />
          </>
        );

      case 'Query argument':
        return (
          <>
            <InputContainer
              title="Query argument"
              value={queryArgument}
              onChange={value => handleChangeItem(id, 'queryArgument', value)}
              note="Query argument name must have 1-64 characters."
            />

            <TextTransformation
              description="AWS WAF applies all transformations to the request component before using it. Transformations are applied in the order presented below,  starting from the top."
              transformation={transformation}
              setTransformation={value => handleChangeItem(id, 'transformation', value)}
            />
          </>
        );

      case 'Cookie':
        return (
          <>
            <InputContainer
              title="Cookie name"
              value={cookieName}
              onChange={value => handleChangeItem(id, 'cookieName', value)}
              note="Cookie name must have 1-64 characters."
            />

            <TextTransformation
              description="AWS WAF applies all transformations to the request component before using it. Transformations are applied in the order presented below,  starting from the top."
              transformation={transformation}
              setTransformation={value => handleChangeItem(id, 'transformation', value)}
            />
          </>
        );

      case 'Label namespace':
        return (
          <>
            <InputContainer
              title="Label namespace"
              description="A label namespace can be part or all of a label preceding the label name. For example, in the label awswaf:managed:aws:linux-os:LFI_Heaer,awswaf:managed:aws:linux-os: is a label namespace. You can use labels that are added by rules that run before this one and the same web ACL."
              value={labelNamespace}
              onChange={value => handleChangeItem(id, 'labelNamespace', value)}
              note="Label namespace must have 1-1024 characters and end with a colon (:). Valid characters: A-Z, a-z, 0-9, colon (:), - (hyphen), and _ (underscore)."
            />
          </>
        );

      case 'URI path':
        return (
          <>
            <TextTransformation
              description="AWS WAF applies all transformations to the request component before using it. Transformations are applied in the order presented below,  starting from the top."
              transformation={transformation}
              setTransformation={value => handleChangeItem(id, 'transformation', value)}
            />
          </>
        );

      case 'IP address in header':
        return (
          <>
            <HeaderFieldName
              headerFieldName={headerFieldName}
              setHeaderFieldName={value => handleChangeItem(id, 'headerFieldName', value)}
            />

            <DropdownContainer
              dropdownClassName="mw-50"
              title="Position inside header"
              description="AWS WAF uses the first IP address in the header. The header can contain IP addresses of the original client and of any proxies. For example, the header value could be ’10.1.1.1, 127.0.0.0, 10.10.10.10’ where the first IP address identifies the original client and the rest identify proxies that the request went through."
              options={[{ name: 'First IP address', value: 'First IP address' }]}
              value={positionInsideHeader}
              onChange={value => handleChangeItem(id, 'positionInsideHeader', value)}
              disabled
            />

            <MissingIpAddress
              missingIpAddress={missingIpAddress}
              setMissingIpAddress={value => handleChangeItem(id, 'missingIpAddress', value)}
            />
          </>
        );

      default:
        return null;
    }
  };

  return (
    <div className="w-100">
      {data?.map((e: any, index: number) => (
        <div key={e.id}>
          {index ? <div className="space-16 condition">AND</div> : null}

          {index ? <div className="space-16" /> : null}

          <div className="rule-container-group rule-container-group-border">
            <div className="rule-container-group-title">
              <div>
                <p>Request aggregation key {data?.length ? index + 1 : ''}</p>

                <p className="rule-container-group-description">
                  You can aggregate requests by one or more request components. AWS WAF first groups requests by the
                  keys you provide, then counts each group and compares the counts against the rate limit
                </p>
              </div>

              {data?.length > 1 ? (
                <button className="btn-default" onClick={() => handleRemoveItem(e.id)}>
                  Remove
                </button>
              ) : null}
            </div>

            <div className="rule-container-group-content">
              <DropdownContainer
                dropdownClassName="mw-50"
                title=""
                description=""
                options={REQUEST_AGGREGATION_KEY}
                placeholder="Choose a component type"
                value={e.type}
                onChange={value => handleChangeItem(e.id, 'type', value)}
              />

              {renderElement(e)}
            </div>
          </div>
        </div>
      ))}

      <>
        <div className="space-16" />

        <button className="btn-default" onClick={handleAddItem} disabled={isMaxCount}>
          Add a request aggregation key
        </button>

        {isMaxCount ? null : (
          <p className="rule-container-group-description">
            You can add up to {maxCount - count} more request aggregation keys.
          </p>
        )}
      </>
    </div>
  );
}

export default RequestAggregationKey;
