import { gql } from '@apollo/client';

const query = gql`
query getAwsDescribeImages($request: AwsDescribeImagesRequest, $cloudId: Long, $region: String) {
  getAwsDescribeImages(describeImagesRequest: $request, cloudId: $cloudId, region: $region) {
    result
     data{
      ... on AwsDescribeImagesResponse {
        images {
          architecture
          creationDate
          imageId
          imageLocation
          imageType
          kernelId
          ownerId
          platform
          platformDetails
          usageOperation
          productCodes {
            productCodeId
            productCodeType
          }
          ramdiskId
          state
          blockDeviceMappings {
            deviceName
            virtualName
            ebs {
              deleteOnTermination
              iops
              snapshotId
              volumeSize
              volumeType
              kmsKeyId
              throughput
              outpostArn
              encrypted
            }
            noDevice
          }
          description
          enaSupport
          hypervisor
          imageOwnerAlias
          name
          rootDeviceName
          rootDeviceType
          sriovNetSupport
          stateReason {
            code
            message
          }
          tags {
            key
            value
          }
          virtualizationType
          bootMode
          tpmSupport
          deprecationTime
          imdsSupport
          sourceInstanceId
          publicLaunchPermissions
          deregistrationProtection
          lastLaunchedTime
        }
			nextToken
      }
        }
        }
      }
  `;
export default query;