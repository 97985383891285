import RadioAtom from 'components/v2/atoms/RadioAtom';

type RadioGroupContainerPropsType = {
  className?: string;
  title: string;
  description?: string;
  options: { id: string; value: string; name: string; label: string; description?: string }[];
  checked: string;
  onChange: (val: string) => void;
};

function RadioGroupContainer({ className, title, description, options, checked, onChange }: RadioGroupContainerPropsType) {
  return (
    <div className={className}>
      <p className="rule-container-group-content-label">{title}</p>

      <p className="rule-container-group-content-description">{description}</p>

      <div className="space-8">
        {options.map(({ id, label, value, name, description }) => (
          <RadioAtom
            key={id}
            label={label}
            value={value}
            name={name}
            description={description}
            checked={checked}
            onChange={onChange}
          />
        ))}
      </div>
    </div>
  );
}

export default RadioGroupContainer;
