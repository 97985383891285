import { ColumnType, OrderDirection } from "@Types/v2/Table";
import { Fragment, useEffect, useMemo, useState } from "react";
import TableCheckbox from "components/v2/dataDisplay/TableCheckbox";
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from "@Types/error";
import './index.scss';

export type JsonContentType = {
  title: string;
  description?: string;
  value?: string;
  jsonString: string;
};

export type RowHasJsonType = {
  id?: number | string;
  [key: string]: any;
  jsonContent: JsonContentType;
};

export interface IJsonTableProps {
  className?: string;
  columns: ColumnType[];
  rows: RowHasJsonType[];
  reportCheckedList?: (list: string[]) => void;
  reportSelected?: (select: string) => void;
  sortOption?: {
    target: string;
    direction: OrderDirection;
    onChangeSort: (target: string, dir: OrderDirection) => void;
  }
  numberOption?: number;
  isAllRowChecked?: boolean;
}

const TableJsonView = ({ 
  rows,
  columns, 
  className,
  reportCheckedList,
  reportSelected,
  sortOption,
  numberOption = undefined
}:IJsonTableProps) => {
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [currentReport, setCurrentReport] = useState<string>('');

  const copyToClipBoard = (json: string) => {
    navigator.clipboard.writeText(json).then(() => {
      useToast(ErrorCode.SUCCESS, 'Copied');
    });
  };

  useEffect(() => {
    if (checkedList.length === 1) {
      setCurrentReport(checkedList[0]);
    } else {
      setCurrentReport('');
    }
  }, [checkedList]);

  useEffect(() => {
    setCheckedList([]);
    reportCheckedList && reportCheckedList([]);
  }, [rows]);
  
  const renderBody = useMemo(() => {
    return (
      rows.map((row, rowIdx) => (
        <Fragment key={`row-${rowIdx}`}>
          <tr 
            onClick={() => {
              reportSelected && reportSelected(String(row.id));
              setCheckedList([]);
              
              if (reportCheckedList) {
                setCheckedList([String(row.id)]);
              }
            }}
          >
            { 
              reportCheckedList && (
                <td onClick={(e) => e.stopPropagation()}>
                  <TableCheckbox
                    defaultValue={checkedList.includes(String(row.id))} 
                    onChange={() => {
                      if (checkedList.includes(String(row.id))) {
                        const left = checkedList.filter(li => li !== String(row.id));
                        setCheckedList(left);
                        reportCheckedList(left);
                      } else {
                        setCheckedList([...checkedList, String(row.id)]);
                        reportCheckedList([...checkedList, String(row.id)]);
                      }
                    }}
                  />
                </td>
              )
            } 
            {
              numberOption && (
                <td>
                  {
                    numberOption === 1 
                      ? rowIdx + 1 
                      : (rows.length * (numberOption - 1)) + rowIdx + 1 
                  }
                </td>
              )
            }
            {
              columns.map((col, idx) => (
                <td key={`row-${rowIdx}-col-${idx}`}>
                  <div>
                    {
                      col.renderCell
                        ? col.renderCell(row)
                        : (row as { [key: string]: any })[col.field]
                    }
                  </div>
                </td>
              )) 
            }
          </tr>
          <tr className={`json-view-row ${currentReport === row.id ? '' : 'd-none'}`}>
            <td colSpan={columns.length + 1}>
              <div className="json-view-container">
                <div className="json-view-content">
                  <div className="header">
                    <div className="title-container">
                      <p>{row?.jsonContent?.title}</p>
                      {row?.jsonContent?.description && <p>{row?.jsonContent?.description}</p>}
                    </div>
                    <div className="action-group">
                      <button className="button" onClick={() => copyToClipBoard(row?.jsonContent?.value || row?.jsonContent?.jsonString)}>Copy JSON</button>
                    </div>
                  </div>
                  <div className="json-content"><pre>{row?.jsonContent?.jsonString}</pre></div>
                </div>
              </div>
            </td>
          </tr>
        </Fragment>
      ))
    );
  },[rows, columns, sortOption, checkedList, currentReport]);

  return (
    <div className={`data-json-table ${className}`}>
      <table>
        <tbody>
          <tr className="horizontal-header">
            { reportCheckedList && (
              <th style={{ width: '87px', maxWidth: '87px' }}>
                <TableCheckbox
                  defaultValue={checkedList.length > 0 && checkedList.length === rows.length}
                  onChange={() => {
                    const ids = rows.map(row => String(row.id));
                    if (checkedList.length === rows.length) {
                      setCheckedList([]);
                      reportCheckedList([]);
                    } else {
                      setCheckedList(ids);
                      reportCheckedList(ids);
                    }
                  }} 
                />
              </th> )}
            { numberOption && <th style={{ width: '88px', maxWidth: '88px', paddingLeft: '32px' }}>#</th> }
            { columns.map((col, idx) => ( 
              <th 
                key={`col-${idx}`}
                {...(col.width && { style: { width: col.width + 'px' } })}
              >
                <div>
                  {
                    col.renderHeader 
                      ? col.renderHeader() 
                      : col.label 
                        ? col.label 
                        : col.field
                  }
                  {
                    sortOption && col.sort &&
                      <button
                        className={`sort-btn
                        ${sortOption.direction === OrderDirection.ASC && 'upside-down'} 
                        ${col.field === sortOption.target && 'sorted'}`
                      }
                      onClick={() => {
                        if (sortOption.target !== col.field) {
                          sortOption.onChangeSort(col.field, OrderDirection.DES)
                        } else {
                          sortOption.onChangeSort(col.field, sortOption.direction === OrderDirection.ASC ? OrderDirection.DES : OrderDirection.ASC)
                        }
                      }}
                    />
                  }
                </div>
              </th>
            ))}
          </tr>
          {renderBody}
        </tbody>
      </table>
    </div>
  );
};

export default TableJsonView;