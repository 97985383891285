import { AxiosInstance, AxiosResponse } from "axios";

const downloadAssumeRoleCFT = (instance: AxiosInstance) => ( token: string, params:any ):Promise<AxiosResponse> => {
  return instance.request({
    method: 'GET',
    url: `template/downloadAssumeRoleCFT/${params.scope}/${params.features}/${params.externalId}/${params.accountId}`,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
};

const Cloud = (instance: AxiosInstance) => ({
  downloadAssumeRoleCFT: downloadAssumeRoleCFT(instance)
});
export default Cloud;