import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsDescribeTagsResponse } from 'graphql/types/AwsDescribeTagsResponse';

export interface IGetAwsDescribeTagsVariables {
  cloudId: number;
  region: string;
  request: {
    resourceArns: string[];
  }
}

export interface IAwsDescribeTagsResponseData {
  getAwsDescribeTags: IGqlResponseNewData<AwsDescribeTagsResponse>;
}

const lazyGetAwsDescribeTags = () => useLazyQuery<IAwsDescribeTagsResponseData, IGetAwsDescribeTagsVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsDescribeTags;