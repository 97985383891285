import { useMemo, useState } from 'react';
import SelectBox from 'components/UI/SelectBox';
import './index.scss';
import Button from 'components/UI/Button';
import addProjectCloud from 'graphql/mutations/addProjectCloud';
import getAllRuleCloud from 'graphql/queries/getAllRuleCloud';
import { enumFormatter } from 'utils/Formatter';
import { useSnackbar } from 'hooks/useSnackbar';
import { ErrorCode } from '@Types/error';

interface ICreateCloudModalProps {
  onClose: () => void;
  onSubmit: () => void;
  projectId: number;
}
const CreateCloudModal = ({ onClose, onSubmit, projectId }: ICreateCloudModalProps) => {
  const [selectType, setSelectType] = useState<{ name:string, value: string | number }>({
    name: '',
    value: ''
  });

  const [createData, setCreateData] = useState({
    cloudName: '',
    rootAccount: '',
    serviceType: '',
    features: '',
    msspAttrKey: '',
    msspAttrValue: '',
    attr_1: '',
    attr_2: '',
    attr_3: '',
    attr_4: ''
  });

  const { data: cloudList } = getAllRuleCloud();
  const [addCloud] = addProjectCloud();

  const cloudTypeSelectList = useMemo(() => {
    return cloudList?.getAllRuleCloud.data.map(d => ({ name: enumFormatter(d.cloudKind) || d.name, value: d.cloudId })) || [];
  },[cloudList]);
  const handleChangeType = (val: { name:string, value: string | number }) => {
    setSelectType(val);
  };

  const handleCreate = () => {
    /// api 호출
    addCloud({
      variables: {
        reqAddProjectCloud: {
          projectId: projectId,
          rootAccount: createData.rootAccount,
          cloudId: Number(selectType.value),
          name: createData.cloudName,
          serviceType: createData.serviceType,
          features: createData.features,
          msspAttrKey: createData.msspAttrKey,
          msspAttrValue: createData.msspAttrValue,
          ...(Number(selectType.value) === 1 ? 
            { attrKey1: createData.attr_1, attrValue1: createData.attr_1, attrKey2: createData.attr_2, attrValue2: createData.attr_2 } : 
            Number(selectType.value) === 2 ?
              { attrKey1: createData.attr_1, attrValue1: createData.attr_1 } :
              { attrKey1: createData.attr_1, attrValue1: createData.attr_1, attrKey2: createData.attr_2, attrValue2: createData.attr_2, attrKey3: createData.attr_3, attrValue3: createData.attr_3, attrKey4: createData.attr_4, attrValue4: createData.attr_4 }
          )
        }
      }
    }).then(({ data }) => {
      if (data) {
        if (data.addProjectCloud.result === ErrorCode.SUCCESS) {
          useSnackbar(data.addProjectCloud.result);
          onSubmit();
          onClose();
        } else {
          useSnackbar(data.addProjectCloud.result);
        }
      } else {
        useSnackbar(ErrorCode.UNKNOWN);
      }
    });
  };
  
  return (
    <div className="modal-bg" onClick={onClose}>
      <div className="cloudmodal-wrap" onClick={e => e.stopPropagation()}>
        <div className="header-wrap">
          <span/>
          <p>
            연계 클라우드 정보 추가
          </p>
        </div>
        <div className="contents-wrap">
          <p className="title">클라우드 종류를 선택하세요.</p>
          <div className="row">
            <p className="row-title">연계 계정 이름</p>
            <input type="text" className="input" value={createData.cloudName} onChange={e => setCreateData(prev => ({ ...prev, cloudName: e.target.value }))}/>
          </div>
          <div className="row">
            <p className="row-title">클라우드 종류</p>
            <SelectBox id="createcloudtype" style="datainfo" data={cloudTypeSelectList} handleClick={handleChangeType} value={selectType}/>
          </div>
          <div className="row">
            <p className="row-title">클라우드 ID</p>
            <input type="text" className="input" value={createData.rootAccount} onChange={e => setCreateData(prev => ({ ...prev, rootAccount: e.target.value }))}/>
          </div>
          <p className="title" style={{ marginTop: 26 }}>인증 정보를 넣어주세요.</p>
          {Number(selectType.value) === 1 && (
            <><div className="row">
              <p className="row-title">Access key</p>
              <input type="text" className="input" value={createData.attr_1} onChange={e => setCreateData(prev => ({ ...prev, attr_1: e.target.value }))} />
            </div><div className="row">
              <p className="row-title">Secret key</p>
              <input type="text" className="input" value={createData.attr_2} onChange={e => setCreateData(prev => ({ ...prev, attr_2: e.target.value }))} />
            </div></>
          )}
          {Number(selectType.value) === 2 && (
            <div className="row">
              <p className="row-title">Json key</p>
              <input type="text" className="input" value={createData.attr_1} onChange={e => setCreateData(prev => ({ ...prev, attr_1: e.target.value }))} />
            </div>
          )}
          {Number(selectType.value) === 3 && (
            <>
              <div className="row">
                <p className="row-title">Directory ID</p>
                <input type="text" className="input" value={createData.attr_1} onChange={e => setCreateData(prev => ({ ...prev, attr_1: e.target.value }))} />
              </div>
              <div className="row">
                <p className="row-title">Application ID</p>
                <input type="text" className="input" value={createData.attr_2} onChange={e => setCreateData(prev => ({ ...prev, attr_2: e.target.value }))} />
              </div>
              <div className="row">
                <p className="row-title">Client secrets</p>
                <input type="text" className="input" value={createData.attr_3} onChange={e => setCreateData(prev => ({ ...prev, attr_3: e.target.value }))} />
              </div>
              <div className="row">
                <p className="row-title">Subscription ID</p>
                <input type="text" className="input" value={createData.attr_4} onChange={e => setCreateData(prev => ({ ...prev, attr_4: e.target.value }))} />
              </div>
            </>
          )}
          <div className="modal-btn-wrap">
            <Button disabled={createData.cloudName === '' || selectType.value === '' || createData.rootAccount === ''} text={'추가'} handleClick={handleCreate}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCloudModal;