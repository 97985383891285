import React, { useMemo } from 'react';
import { AddRuleGroupsStepPropsType } from '../types';
import RadioAtom from 'components/v2/atoms/RadioAtom';
import { FIREWALL_POLICY_CREATION } from '../configs';
import TablePagination from 'components/v2/dataDisplay/TablePagination';
import Table from 'components/v2/dataDisplay/Table';
import { OrderDirection } from '@Types/v2/Table';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import CheckboxAtom from 'components/v2/atoms/CheckboxAtom';
import '../index.scss';

const AddRuleGroupsStep = (props: AddRuleGroupsStepPropsType) => {
  const {
    ruleAction,
    ruleActionFullPackets,
    ruleActionFragmentedPackets,
    onValueChanged,
    isSameActionForAll,
    onMoveUpButtonClicked,
    onMoveDownButtonClicked,
    onDeleteButtonClicked,
    onAddStatelessRuleGroupButtonClicked,
    statelessRuleGroupList,
    statefulRuleGroupList,
    statelessRuleGroupTablePaginationConfig,
    statefulRuleGroupTablePaginationConfig,
    updateStatelessRuleGroupPaginationConfig,
    updateStatefulRuleGroupPaginationConfig,
    reportSelected,
    reportCheckedList,
    statefulRuleEvaluationOrder,
    statefulRuleDropAction,
    alertOptionAll,
    alertOptionEstablished,
    statelessRuleGroupCapacityUnitConsumed,
    statefulRuleGroupCapacityUnitConsumed,
  } = props;

  const DROP_ACTION_DROPDOWN_LIST = [
    { id: 1, name: FIREWALL_POLICY_CREATION.RULE_EVALUATION_DROP_ALL, value: 'All' },
    { id: 2, name: FIREWALL_POLICY_CREATION.RULE_EVALUATION_DROP_ESTABLISHED, value: 'Established' },
  ];

  const STATELESS_RULE_GROUP_COLUMN_TITLE_LIST = [
    { label: 'Priority', field: 'priority', sort: true },
    { label: 'Name', field: 'name', sort: true },
    { label: 'Capacity', field: 'capacity', sort: true },
  ];

  const STATEFUL_RULE_GROUP_COLUMN_TITLE_LIST = [
    {
      label: FIREWALL_POLICY_CREATION.STATEFUL_RULE_GROUP_PRIORITY_COLUMN_TITLE,
      field: 'priority',
      sort: true,
    },
    { label: FIREWALL_POLICY_CREATION.STATEFUL_RULE_GROUP_NAME_COLUMN_TITLE, field: 'name', sort: true },
    {
      label: FIREWALL_POLICY_CREATION.STATEFUL_RULE_GROUP_CAPACITY_COLUMN_TITLE,
      field: 'capacity',
      sort: true,
    },
  ];

  const statelessRuleActionNode = useMemo(() => {
    if (isSameActionForAll) {
      return (
        <div className="firewall-detail-title">
          {FIREWALL_POLICY_CREATION.RULE_ACTION_TITLE}
          <div className="firewall-detail-sub-title">{FIREWALL_POLICY_CREATION.RULE_ACTION_SUBTITLE}</div>
          <RadioAtom
            label={FIREWALL_POLICY_CREATION.RULE_ACTION_PASS_RADIO_LABEL}
            description={FIREWALL_POLICY_CREATION.RULE_ACTION_PASS_RADIO_DESCRIPTION}
            checked="Pass"
            name=""
            onChange={() => onValueChanged('ruleActionFullPackets', 'Pass')}
            value={ruleActionFullPackets}
          />
          <RadioAtom
            label={FIREWALL_POLICY_CREATION.RULE_ACTION_DROP_RADIO_LABEL}
            description={FIREWALL_POLICY_CREATION.RULE_ACTION_DROP_RADIO_DESCRIPTION}
            checked="Drop"
            name=""
            onChange={() => onValueChanged('ruleActionFullPackets', 'Drop')}
            value={ruleActionFullPackets}
          />
          <RadioAtom
            label={FIREWALL_POLICY_CREATION.RULE_ACTION_FORWARD_RADIO_LABEL}
            description={FIREWALL_POLICY_CREATION.RULE_ACTION_FORWARD_DESCRIPTION}
            checked="Forward"
            name=""
            onChange={() => onValueChanged('ruleActionFullPackets', 'Forward')}
            value={ruleActionFullPackets}
          />
        </div>
      );
    }

    return (
      <>
        <div className="firewall-detail-title">
          {FIREWALL_POLICY_CREATION.RULE_ACTION_FULL_PACKETS_TITLE}
          <div className="firewall-detail-sub-title">{FIREWALL_POLICY_CREATION.RULE_ACTION_FULL_PACKETS_SUBTITLE}</div>
          <RadioAtom
            label={FIREWALL_POLICY_CREATION.RULE_ACTION_FULL_PACKETS_PASS_RADIO_LABEL}
            description={FIREWALL_POLICY_CREATION.RULE_ACTION_FULL_PACKETS_PASS_RADIO_DESCRIPTION}
            checked="Pass"
            name=""
            onChange={() => onValueChanged('ruleActionFullPackets', 'Pass')}
            value={ruleActionFullPackets}
          />
          <RadioAtom
            label={FIREWALL_POLICY_CREATION.RULE_ACTION_FULL_PACKETS_DROP_RADIO_LABEL}
            description={FIREWALL_POLICY_CREATION.RULE_ACTION_FULL_PACKETS_DROP_RADIO_DESCRIPTION}
            checked="Drop"
            name=""
            onChange={() => onValueChanged('ruleActionFullPackets', 'Drop')}
            value={ruleActionFullPackets}
          />
          <RadioAtom
            label={FIREWALL_POLICY_CREATION.RULE_ACTION_FULL_PACKETS_FORWARD_RADIO_LABEL}
            description={FIREWALL_POLICY_CREATION.RULE_ACTION_FULL_PACKETS_FORWARD_DESCRIPTION}
            checked="Forward"
            name=""
            onChange={() => onValueChanged('ruleActionFullPackets', 'Forward')}
            value={ruleActionFullPackets}
          />
        </div>

        <div className="firewall-detail-title">
          {FIREWALL_POLICY_CREATION.RULE_ACTION_FRAGMENTED_PACKETS_TITLE}
          <div className="firewall-detail-sub-title">
            {FIREWALL_POLICY_CREATION.RULE_ACTION_FRAGMENTED_PACKETS_SUBTITLE}
          </div>
          <RadioAtom
            label={FIREWALL_POLICY_CREATION.RULE_ACTION_FRAGMENTED_PACKETS_PASS_RADIO_LABEL}
            description={FIREWALL_POLICY_CREATION.RULE_ACTION_FRAGMENTED_PACKETS_PASS_RADIO_DESCRIPTION}
            checked="Pass"
            name=""
            onChange={() => onValueChanged('ruleActionFragmentedPackets', 'Pass')}
            value={ruleActionFragmentedPackets}
          />
          <RadioAtom
            label={FIREWALL_POLICY_CREATION.RULE_ACTION_FRAGMENTED_PACKETS_DROP_RADIO_LABEL}
            description={FIREWALL_POLICY_CREATION.RULE_ACTION_FRAGMENTED_PACKETS_DROP_RADIO_DESCRIPTION}
            checked="Drop"
            name=""
            onChange={() => onValueChanged('ruleActionFragmentedPackets', 'Drop')}
            value={ruleActionFragmentedPackets}
          />
          <RadioAtom
            label={FIREWALL_POLICY_CREATION.RULE_ACTION_FRAGMENTED_PACKETS_FORWARD_RADIO_LABEL}
            description={FIREWALL_POLICY_CREATION.RULE_ACTION_FRAGMENTED_PACKETS_FORWARD_DESCRIPTION}
            checked="Forward"
            name=""
            onChange={() => onValueChanged('ruleActionFragmentedPackets', 'Forward')}
            value={ruleActionFragmentedPackets}
          />
        </div>
      </>
    );
  }, [ruleActionFullPackets, ruleActionFragmentedPackets, onValueChanged, isSameActionForAll]);

  const statelessRuleGroupNode = useMemo(() => {
    return (
      <div className="rule-group-container">
        <div className="rule-group-details-container">
          <div
            className="rule-group-detail-title"
            style={{ display: 'flex', gap: 20, justifyContent: 'space-between', alignItems: 'center' }}
          >
            {FIREWALL_POLICY_CREATION.STATELESS_RULE_GROUP_TITLE}
            <div>
              <button className="button" style={{ margin: 0 }} onClick={onMoveUpButtonClicked}>
                {FIREWALL_POLICY_CREATION.STATELESS_RULE_GROUP_MOVE_UP_BUTTON}
              </button>
              <button className="button" style={{ marginLeft: 8 }} onClick={onMoveDownButtonClicked}>
                {FIREWALL_POLICY_CREATION.STATELESS_RULE_GROUP_MOVE_DOWN_BUTTON}
              </button>
              <button className="button next-button" style={{ marginLeft: 8 }} onClick={onDeleteButtonClicked}>
                {FIREWALL_POLICY_CREATION.STATELESS_RULE_GROUP_DELETE_BUTTON}
              </button>
              <button className="button" style={{ marginLeft: 8 }} onClick={onAddStatelessRuleGroupButtonClicked}>
                {FIREWALL_POLICY_CREATION.STATELESS_RULE_GROUP_ADD_STATELESS_BUTTON}
              </button>
            </div>
          </div>
          <div
            className="detail-table"
            style={{
              margin: 0,
              border: 'none',
            }}
          >
            <Table
              columns={STATELESS_RULE_GROUP_COLUMN_TITLE_LIST}
              rows={statelessRuleGroupList}
              reportSelected={reportSelected}
              reportCheckedList={reportCheckedList}
              sortOption={{
                target: statelessRuleGroupTablePaginationConfig.target,
                direction: statelessRuleGroupTablePaginationConfig.direction,
                onChangeSort: (target: string, dir: OrderDirection) => {
                  updateStatelessRuleGroupPaginationConfig('target', target);
                  updateStatelessRuleGroupPaginationConfig('direction', dir);
                },
              }}
            />

            <div className="pagination-wrapper flex a-center">
              <p className="flex a-center text-paging">
                Total <span>{statelessRuleGroupTablePaginationConfig.totalItemCount}</span>
              </p>

              <TablePagination
                currentPage={statelessRuleGroupTablePaginationConfig.currentPage}
                updateCurrentPage={pageNumber => {
                  updateStatelessRuleGroupPaginationConfig('currentPage', pageNumber);
                }}
                totalPage={statelessRuleGroupTablePaginationConfig.totalPageCount}
              />
            </div>
          </div>
          <div className="firewall-detail-title">
            {FIREWALL_POLICY_CREATION.FRAGMENTED_PACKETS_TITLE}
            <div className="firewall-detail-sub-title">{FIREWALL_POLICY_CREATION.FRAGMENTED_PACKETS_SUB_TITLE}</div>
            <RadioAtom
              label={FIREWALL_POLICY_CREATION.FRAGMENTED_PACKETS_USE_SAME_ACTION_RADIO_LABEL}
              checked="Same"
              name=""
              onChange={() => onValueChanged('ruleAction', 'Same')}
              value={ruleAction}
            />
            <RadioAtom
              label={FIREWALL_POLICY_CREATION.FRAGMENTED_PACKETS_USE_DIFFERENT_ACTION_RADIO_LABEL}
              checked="Different"
              name=""
              onChange={() => onValueChanged('ruleAction', 'Different')}
              value={ruleAction}
            />
          </div>
        </div>
      </div>
    );
  }, [statelessRuleGroupTablePaginationConfig]);

  const statefulRuleActionNode = useMemo(() => {
    return (
      <div className="rule-group-container">
        <div className="title-group">
          <p className="title">{FIREWALL_POLICY_CREATION.STATEFUL_DEFAULTS_ACTIONS_TITLE}</p>
          <p className="sub-title">{FIREWALL_POLICY_CREATION.STATEFUL_DEFAULTS_ACTIONS_SUBTITLE}</p>
        </div>

        <div className="rule-group-details-container">
          <div className="firewall-detail-title">
            {FIREWALL_POLICY_CREATION.RULE_EVALUATION_ORDER_TITLE}
            <div className="firewall-detail-sub-title">{FIREWALL_POLICY_CREATION.RULE_EVALUATION_ORDER_SUBTITLE}</div>
            <RadioAtom
              label={FIREWALL_POLICY_CREATION.RULE_EVALUATION_STRICT_ORDER_LABEL}
              description={FIREWALL_POLICY_CREATION.RULE_EVALUATION_STRICT_ORDER_DESCRIPTION}
              checked="Strict"
              name=""
              onChange={() => onValueChanged('ruleAction', 'Strict')}
              value={statefulRuleEvaluationOrder}
            />
            <RadioAtom
              label={FIREWALL_POLICY_CREATION.RULE_EVALUATION_ACTION_ORDER_LABEL}
              description={FIREWALL_POLICY_CREATION.RULE_EVALUATION_ACTION_ORDER_DESCRIPTION}
              checked="Action"
              name=""
              onChange={() => onValueChanged('ruleAction', 'Action')}
              value={statefulRuleEvaluationOrder}
            />
          </div>

          <div className="firewall-detail-title">
            {FIREWALL_POLICY_CREATION.RULE_EVALUATION_DROP_ACTION_TITLE}
            <div className="firewall-detail-sub-title">
              {FIREWALL_POLICY_CREATION.RULE_EVALUATION_DROP_ACTION_SUBTITLE}
            </div>
            <DropdownAtom
              id="msg-use"
              data={DROP_ACTION_DROPDOWN_LIST}
              value={
                DROP_ACTION_DROPDOWN_LIST.find(item => item.value === statefulRuleDropAction) ||
                DROP_ACTION_DROPDOWN_LIST[0]
              }
              handleClick={val => onValueChanged('statefulRuleDropAction', val.value)}
            />
          </div>
          <div className="firewall-detail-title">
            {FIREWALL_POLICY_CREATION.RULE_EVALUATION_ALERT_ACTION_TITLE}
            <div className="firewall-detail-sub-title">
              {FIREWALL_POLICY_CREATION.RULE_EVALUATION_ALERT_ACTION_SUBTITLE}
            </div>
            <label className="labeled">
              <CheckboxAtom
                checked={alertOptionAll}
                onchange={() => onValueChanged('alertOptionAll', !alertOptionAll)}
              />

              {FIREWALL_POLICY_CREATION.RULE_EVALUATION_ALERT_ACTION_ALL_LABEL}
              <p className="radio-description">
                {FIREWALL_POLICY_CREATION.RULE_EVALUATION_ALERT_ACTION_ALL_DESCRIPTION}
              </p>
            </label>
            <label className="labeled">
              <CheckboxAtom
                checked={alertOptionEstablished}
                onchange={() => onValueChanged('alertOptionEstablished', !alertOptionEstablished)}
              />

              {FIREWALL_POLICY_CREATION.RULE_EVALUATION_ALERT_ACTION_ESTABLISHED_LABEL}
              <p className="radio-description">
                {FIREWALL_POLICY_CREATION.RULE_EVALUATION_ALERT_ACTION_ESTABLISHED_DESCRIPTION}
              </p>
            </label>
          </div>
        </div>
      </div>
    );
  }, [statefulRuleDropAction, alertOptionAll, alertOptionEstablished]);

  const statefulRuleGroupNode = useMemo(() => {
    return (
      <div className="rule-group-container">
        <div className="rule-group-details-container">
          <div
            className="rule-group-detail-title"
            style={{ display: 'flex', gap: 20, justifyContent: 'space-between', alignItems: 'center' }}
          >
            {FIREWALL_POLICY_CREATION.STATEFUL_RULE_GROUP_TITLE}
            <div>
              <button className="button" style={{ margin: 0 }} onClick={onMoveUpButtonClicked}>
                {FIREWALL_POLICY_CREATION.STATEFUL_RULE_GROUP_MOVE_UP_BUTTON}
              </button>
              <button className="button" style={{ marginLeft: 8 }} onClick={onMoveDownButtonClicked}>
                {FIREWALL_POLICY_CREATION.STATEFUL_RULE_GROUP_MOVE_DOWN_BUTTON}
              </button>
              <button className="button next-button" style={{ marginLeft: 8 }} onClick={onDeleteButtonClicked}>
                {FIREWALL_POLICY_CREATION.STATEFUL_RULE_GROUP_DELETE_BUTTON}
              </button>
              <button className="button" style={{ marginLeft: 8 }} onClick={onAddStatelessRuleGroupButtonClicked}>
                {FIREWALL_POLICY_CREATION.STATEFUL_RULE_GROUP_ADD_STATEFUL_BUTTON}
              </button>
            </div>
          </div>
          <div
            className="detail-table"
            style={{
              margin: 0,
              border: 'none',
            }}
          >
            <Table
              columns={STATEFUL_RULE_GROUP_COLUMN_TITLE_LIST}
              rows={statefulRuleGroupList}
              reportSelected={reportSelected}
              reportCheckedList={reportCheckedList}
              sortOption={{
                target: statefulRuleGroupTablePaginationConfig.target,
                direction: statefulRuleGroupTablePaginationConfig.direction,
                onChangeSort: (target: string, dir: OrderDirection) => {
                  updateStatefulRuleGroupPaginationConfig('target', target);
                  updateStatefulRuleGroupPaginationConfig('direction', dir);
                },
              }}
            />

            <div className="pagination-wrapper flex a-center">
              <p className="flex a-center text-paging">
                Total <span>{statefulRuleGroupTablePaginationConfig.totalItemCount}</span>
              </p>

              <TablePagination
                currentPage={statefulRuleGroupTablePaginationConfig.currentPage}
                updateCurrentPage={pageNumber => {
                  updateStatefulRuleGroupPaginationConfig('currentPage', pageNumber);
                }}
                totalPage={statefulRuleGroupTablePaginationConfig.totalPageCount}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }, [statefulRuleGroupList, statefulRuleGroupTablePaginationConfig]);

  const capacityUnitConsumedNode = useMemo(() => {
    return (
      <div className="rule-group-container">
        <div className="rule-group-details-container" style={{ display: 'flex', flexDirection: 'row' }}>
          <div className="firewall-detail-title" style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            {FIREWALL_POLICY_CREATION.CAPACITY_UNITS_BY_STATELESS_GROUP_TITLE}
            <div className="firewall-detail-sub-title">
              {FIREWALL_POLICY_CREATION.CAPACITY_UNITS_BY_STATELESS_GROUP_DESCRIPTION}
            </div>
          </div>
          <div className="firewall-detail-title" style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            {FIREWALL_POLICY_CREATION.CAPACITY_UNITS_BY_STATEFUL_GROUP_TITLE}
            <div className="firewall-detail-sub-title">
              {FIREWALL_POLICY_CREATION.CAPACITY_UNITS_BY_STATEFUL_GROUP_DESCRIPTION}
            </div>
          </div>
        </div>
        <div className="rule-group-details-container" style={{ display: 'flex', flexDirection: 'row' }}>
          <div className="firewall-detail-title" style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            {statelessRuleGroupCapacityUnitConsumed}
          </div>
          <div className="firewall-detail-title" style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            {statefulRuleGroupCapacityUnitConsumed}
          </div>
        </div>
      </div>
    );
  }, [statelessRuleGroupCapacityUnitConsumed, statefulRuleGroupCapacityUnitConsumed]);

  return (
    <div>
      <div className="rule-group-container">
        <div className="title-group">
          <p className="title">{FIREWALL_POLICY_CREATION.ADD_RULE_GROUP_TITLE}</p>
          <p className="sub-title">{FIREWALL_POLICY_CREATION.ADD_RULE_GROUP_SUBTITLE}</p>
        </div>

        <div className="rule-group-details-container">
          <div className="rule-group-detail-title" style={{ display: 'flex', gap: 20 }}>
            {FIREWALL_POLICY_CREATION.STATELESS_DEFAULTS_ACTIONS_TITLE}
            <span className="rule-group-detail-sub-title">
              {FIREWALL_POLICY_CREATION.STATELESS_DEFAULTS_ACTIONS_SUBTITLE}
            </span>
          </div>

          <div className="firewall-detail-title">
            {FIREWALL_POLICY_CREATION.FRAGMENTED_PACKETS_TITLE}
            <div className="firewall-detail-sub-title">{FIREWALL_POLICY_CREATION.FRAGMENTED_PACKETS_SUB_TITLE}</div>
            <RadioAtom
              label={FIREWALL_POLICY_CREATION.FRAGMENTED_PACKETS_USE_SAME_ACTION_RADIO_LABEL}
              checked="Same"
              name=""
              onChange={() => onValueChanged('ruleAction', 'Same')}
              value={ruleAction}
            />
            <RadioAtom
              label={FIREWALL_POLICY_CREATION.FRAGMENTED_PACKETS_USE_DIFFERENT_ACTION_RADIO_LABEL}
              checked="Different"
              name=""
              onChange={() => onValueChanged('ruleAction', 'Different')}
              value={ruleAction}
            />
          </div>
        </div>
      </div>
      {statelessRuleActionNode}
      {statelessRuleGroupNode}
      {statefulRuleActionNode}
      {statefulRuleGroupNode}
      {capacityUnitConsumedNode}
    </div>
  );
};

export default AddRuleGroupsStep;
