import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsListGroupsForUserResponseEntry } from 'graphql/types/AwsListGroupsForUser';

export interface IAwsListGroupsForUserVariables {
  cloudId: number;
  region: string;
  request: {
    maxItems?: number;
    userName: string;
    nextToken?: string;
  };
}

export interface IAwsListGroupsForUserResponseData {
  getAwsListGroupsForUser: IGqlResponseNewData<AwsListGroupsForUserResponseEntry>;
}

const lazyGetAwsListGroupsForUser = () =>
  useLazyQuery<IAwsListGroupsForUserResponseData, IAwsListGroupsForUserVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsListGroupsForUser;
