import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from 'graphql/types';
import { AwsListResourcesForWebACLResponse } from 'graphql/types/AwsListResourcesForWebACL';

export interface IAwsListResourcesForWebACLVariables {
  cloudId: number;
  region: string;
  request: {
    webACLArn: string;
    resourceType?: string;
  }
}

export interface IAwsListResourcesForWebACLResponseData {
  getAwsListResourcesForWebACL: IGqlResponseData<AwsListResourcesForWebACLResponse>;
}

const lazyGetListResourcesForWebACL = () => useLazyQuery<IAwsListResourcesForWebACLResponseData, IAwsListResourcesForWebACLVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetListResourcesForWebACL;