import React from 'react';
import InputAtom from "components/v2/atoms/InputAtom";

type RuleNamePropsType = {
  className?: string;
  ruleName: string;
  setRuleName: (val: string) => void;
};

function RuleName({className = '', ruleName, setRuleName}: RuleNamePropsType) {
  return (
    <div className={`rule-container-group ${className}`}>
      <p className='rule-container-group-title'>Rule</p>

      <div className='rule-container-group-content'>
        <div className='input-group'>
          <p className='input-group-label'>Name</p>

          <div className="input-container w-100">
            <InputAtom
              placeholder={''}
              onChangeValue={val => {
                if (val.length <= 128) setRuleName(val);
              }}
              value={ruleName}
              required={true}
              noClear={true}
            />

            <p className="text-note">
              The name must have 1-128 characters. Valid characters: A-Z, a-z, 0-9, -(hyphen), and _(underscore).
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RuleName;