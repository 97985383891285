import { useCallback, useEffect, useMemo, useState } from 'react';
import { IMgdTablePaginationProps, IMgdTotalPageProps } from 'layouts/v3/MgdLayout';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import { variableCombineNextToken } from 'pages/v2/Organ/Management/Utils';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import Table from 'components/v2/dataDisplay/Table';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';
import lazyGetAwsDescribeSpotFleetInstances, {
  IAwsDescribeSpotFleetInstancesVariables,
} from 'graphql/queries/getAwsDescribeSpotFleetInstances';
import { InstanceFleetInstacePropsType } from '../../../types';
import './style.scss';

const Instances = (props: InstanceFleetInstacePropsType) => {
  const { currentSpotFleetInstance, cloudId, region } = props;
  const [getAwsDescribeSpotFleetInstances, { loading: awsDescribeSpotFleetInstanceLoading }] =
    lazyGetAwsDescribeSpotFleetInstances();
  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [mainTblRows, setMainTblRows] = useState<RowType[]>([]);
  const [tablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });
  const [mainNextToken, setMainNextToken] = useState<string>('');

  const columns = useMemo((): ColumnType[] => {
    return [
      {
        label: 'Instance ID',
        field: 'instanceId',
        sort: true,
      },
      {
        label: 'Instance type',
        field: 'instanceType',
        sort: true,
      },
      {
        label: 'Health status',
        field: 'instanceHealth',
        sort: true,
      },
      {
        label: 'Request ID',
        field: 'spotInstanceRequestId',
        sort: true,
      },
    ];
  }, []);

  const fetchDescribeSpotFleetActiveInstances = useCallback(
    (nextToken?: string, isInitial?: boolean) => {
      const requestVariable: IAwsDescribeSpotFleetInstancesVariables = {
        cloudId: cloudId,
        region: region,
        request: {
          maxResults: tablePagination.limit,
          spotFleetRequestId: currentSpotFleetInstance?.spotFleetRequestId,
        },
      };
      getAwsDescribeSpotFleetInstances({ variables: variableCombineNextToken(requestVariable, nextToken) }).then(
        ({ data: awsDescribeSpotFleetInstanceResponse }) => {
          const awsDescribeSpotFleetInstances =
            awsDescribeSpotFleetInstanceResponse?.getAwsDescribeSpotFleetInstances?.data?.[0];
          if (
            awsDescribeSpotFleetInstances?.activeInstances &&
            awsDescribeSpotFleetInstances.activeInstances.length > 0
          ) {
            setMainNextToken(awsDescribeSpotFleetInstances?.nextToken);
            const activeInstances: RowType[] = [];
            awsDescribeSpotFleetInstances.activeInstances.map(instance => {
              activeInstances.push({
                id: instance?.instanceId,
                ...instance,
              });
            });

            let totalActiveInstances: RowType[] = [];
            if (isInitial) {
              totalActiveInstances = activeInstances;
            } else {
              totalActiveInstances = [...mainTblRows, ...activeInstances];
            }

            setMainTblRows(activeInstances);
            setMainTblTotal({
              totalPage: Math.ceil(totalActiveInstances.length / tablePagination.itemPerPage),
              totalElement: totalActiveInstances.length,
            });
          }
        },
      );
    },
    [tablePagination, currentSpotFleetInstance, cloudId, region, mainTblRows],
  );

  const mainRowsCurrentPage = useMemo(() => {
    const startIndex = (tablePagination.currentPage - 1) * tablePagination.itemPerPage;
    const endIndex = startIndex + tablePagination.itemPerPage;

    return orderAlphabetical(mainTblRows, tablePagination.target, tablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [currentSpotFleetInstance, mainTblTotal, tablePagination, mainNextToken]);

  useEffect(() => {
    fetchDescribeSpotFleetActiveInstances('', true);
  }, [currentSpotFleetInstance]);

  const updateTablePagination = (key: string, value: number | string | OrderDirection) => {
    setMainTablePagination(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <div className="detail-info">
      <div className="detail-info-title">
        <p>Instances</p>
      </div>
      {awsDescribeSpotFleetInstanceLoading ? (
        <div className="progress-container">
          <div className="progress-gif" />
          Loading ...
        </div>
      ) : (
        <div className="detail-info-content">
          {!mainRowsCurrentPage.length && !awsDescribeSpotFleetInstanceLoading ? (
            <div className="data-grid-wrap">
              <p className="empty-row">Empty</p>
            </div>
          ) : (
            <div className="data-grid-wrap">
              <Table
                rows={mainRowsCurrentPage}
                columns={columns}
                sortOption={{
                  target: tablePagination.target,
                  direction: tablePagination.direction,
                  onChangeSort: (target: string, dir: OrderDirection) => {
                    updateTablePagination('target', target);
                    updateTablePagination('direction', dir);
                  },
                }}
                isLoading={awsDescribeSpotFleetInstanceLoading}
                horizontalScrollable={true}
              />

              {mainRowsCurrentPage?.length && !awsDescribeSpotFleetInstanceLoading ? (
                <div className="fleet-instance pagination-wrapper flex a-center">
                  <p className="flex a-center">
                    Total <span>{mainTblTotal.totalElement}</span>
                  </p>
                  <TableManagePagination
                    ableFetchMore={!!mainNextToken}
                    currentPage={tablePagination.currentPage}
                    updateCurrentPage={page =>
                      setMainTablePagination(prev => ({
                        ...prev,
                        ['currentPage']: page,
                      }))
                    }
                    totalPage={mainTblTotal.totalPage}
                  />
                </div>
              ) : null}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Instances;
