import { useLazyQuery } from '@apollo/client';
import { IGqlResponseData } from 'graphql/types';
import query from './query';
import { AwsDescribeSecurityGroupRulesResponse } from 'graphql/types/AwsSecurityGroupRules';

export interface IGetAwsSecurityGroupRulesVariables {
  cloudId: number;
  region: string;
  request: {
    maxResults: number;
    nextToken?: string;
    filters?: {
      name: string;
      values: string[]
    };
    dryRun?: boolean;
  }
}

export interface IGetAwsSecurityGroupRulesResponseData {
  getAwsSecurityGroupRules: IGqlResponseData<AwsDescribeSecurityGroupRulesResponse[]>;
}

const lazyGetAwsSecurityGroupRules = () => useLazyQuery<IGetAwsSecurityGroupRulesResponseData, IGetAwsSecurityGroupRulesVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsSecurityGroupRules;