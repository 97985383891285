import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { NetworkingPropsType } from '../types';
import DetailTab from '../../DetailTab';
import { ELASTIC_IP_ADDRESSES_TABLE_COLUMNS, NETWORK_INTERFACE_TABLE_COLUMNS } from '../configs';
import DetailTable from '../../DetailTable';
import lazyGetAwsAllAddresses from 'graphql/queries/getAwsAllAddress';
import { AwsAddressType } from 'graphql/types/AwsAddress';
import { TextTypeEnum } from 'layouts/v3/MgdLayout';

const Networking = (props: NetworkingPropsType) => {
  const { instance, cloudId, region } = props;

  // API
  const [getAwsAllAddress] = lazyGetAwsAllAddresses();

  // State
  const [addresses, setAddresses] = useState<Array<AwsAddressType>>([]);

  const getAddresses = useCallback(() => {
    if (instance?.instanceId) {
      const variables = {
        cloudId: cloudId,
        region: region.value as string,
        request: {
          filters: {
            name: 'instance-id',
            values: [instance.instanceId],
          },
        },
      };

      getAwsAllAddress({ variables }).then(res => {
        setAddresses(res?.data?.getAwsAddresses?.data ?? []);
      });
    }
  }, [cloudId, region, getAwsAllAddress, instance]);

  // Init data
  useEffect(() => {
    getAddresses();
  }, [getAddresses]);

  const networkingDetails = useMemo(() => {
    const { publicIpAddress, privateIpAddress, vpcId, publicDnsName, privateDnsName, subnetId } = instance ?? {};

    return [
      { title: 'Public IPv4 address', type: TextTypeEnum.COPY, description: publicIpAddress ?? '-' },
      { title: 'Private IPv4 addresses', type: TextTypeEnum.COPY, description: privateIpAddress ?? '-' },
      { title: 'VPC ID', type: TextTypeEnum.COPY_LINK, description: vpcId ?? '-' },
      { title: 'Public IPv4 DNS', description: publicDnsName ?? '-' },
      { title: 'Private IP DNS name(IPv4 only)', type: TextTypeEnum.COPY, description: privateDnsName ?? '-' },
      { title: 'Subnet Id', type: TextTypeEnum.COPY_LINK, description: subnetId ?? '-' },
      { title: 'IPv6 addresses', description: '-' },
      { title: 'Secondary private IPv4 addresses', type: TextTypeEnum.COPY_LINK, description: '-' },
      { title: 'Availability zone', type: TextTypeEnum.COPY, description: '-' },
      { title: 'Carrier IP addresses(ephemeral)', description: '-' },
      { title: 'Outpost ID', description: '-' },
      { title: 'Use RBN as guest OS hostname', type: TextTypeEnum.COPY, description: '-' },
      { title: 'Answer RBN DNS hostname IPv4', description: '-' },
    ];
  }, [instance]);

  const networkInterfaces = useMemo(() => {
    return instance?.networkInterfaces ?? [];
  }, []);

  const getInterfaceType = useCallback((interfaceKey?: string) => {
    if (interfaceKey === 'interface') {
      return 'Elastic network interface';
    }

    if (interfaceKey === 'efa') {
      return 'Elastic Fabric Adapter';
    }

    if (interfaceKey === 'trunk') {
      return 'Trunk network interface';
    }

    return '-';
  }, []);

  const networkInterfaceRows = useMemo(() => {
    return (
      instance?.networkInterfaces?.map(networkInterface => {
        const {
          networkInterfaceId,
          description,
          ipv4Prefixes,
          ipv6Prefixes,
          association,
          privateIpAddress,
          ipv6Addresses,
          attachment,
          ownerId,
          vpcId,
          subnetId,
          sourceDestCheck,
          interfaceType,
          groups,
        } = networkInterface;

        return {
          id: networkInterfaceId,
          interfaceId: networkInterfaceId ?? '-',
          description: description || '-',
          ipv4Prefixes: ipv4Prefixes ?? '-',
          ipv6Prefixes: ipv6Prefixes ?? '-',
          publicIpv4Address: instance?.publicIpAddress ?? '-',
          privateIpv4Address: privateIpAddress ?? '-',
          ipv6Addresses: ipv6Addresses ?? '-',
          primaryIpv6Address: ipv6Addresses?.filter(address => address?.isPrimaryIpv6) ?? '-',
          attachmentTime: attachment?.attachTime ?? '-',
          interfaceOwner: ownerId ?? '-',
          attachmentStatus: attachment?.status ?? '-',
          vpcId: vpcId ?? '-',
          subnetId: subnetId ?? '-',
          deleteOnTerminate: attachment?.deleteOnTermination ? 'enabled' : 'disabled',
          sourceDestinationCheck: sourceDestCheck ? 'enabled' : 'disabled',
          securityGroups: groups?.map(group => `${group?.groupId ?? ''} (${group?.groupName ?? ''})`) ?? '-',
          interfaceType: getInterfaceType(interfaceType) ?? '-',
          enaExpress: attachment?.enaSrdSpecification?.enaSrdEnabled ? 'enabled' : 'disabled',
          enaExpressUdp: attachment?.enaSrdSpecification?.enaSrdUdpSpecification?.enaSrdUdpEnabled
            ? 'enabled'
            : 'disabled',
        };
      }) ?? []
    );
  }, [instance]);

  const elasticIpAddressRows = useMemo(() => {
    return addresses?.map((address, index) => {
      const { tags, publicIp, publicIpv4Pool, allocationId } = address;

      return {
        id: `address-${index}`,
        name: tags?.find(tag => tag?.key === 'Name')?.key ?? '-',
        allocatedIpv4Address: publicIp ?? '-',
        type: 'Public IP',
        addressPool: publicIpv4Pool ?? '-',
        allocationId: allocationId ?? '-',
      };
    });
  }, [addresses]);

  return (
    <Fragment>
      <DetailTab title={'Networking details'} data={networkingDetails} />
      <DetailTable data={networkInterfaceRows} columns={NETWORK_INTERFACE_TABLE_COLUMNS} title="Networking interface" />
      <DetailTable
        data={elasticIpAddressRows}
        columns={ELASTIC_IP_ADDRESSES_TABLE_COLUMNS}
        title="Elastic IP addresses"
      />
    </Fragment>
  );
};

export default Networking;
