import { gql } from '@apollo/client';

const query = gql`
query getAwsKeyPolicy($request: AwsGetKeyPolicyRequest, $cloudId: Long, $region: String) {
  getAwsKeyPolicy(
    getKeyPolicyRequest: $request
    cloudId: $cloudId
    region: $region
  ) {
    result
    data {
      ... on AwsGetKeyPolicyResponse {
        policy
      }
    }
  }
}`;
export default query;