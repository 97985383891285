import { gql } from '@apollo/client';

const query = gql`
query getAwsAllInternetGateways($request: AwsDescribeInternetGatewaysRequest, $cloudId: Long, $region: String) {
  getAwsAllInternetGateways(
    describeInternetGatewaysRequest: $request
    cloudId: $cloudId
    region: $region
  ) {
    result
    nextToken
    data {
      ... on AwsInternetGateway {
        attachments {
          vpcId
          state
        }
        internetGatewayId
        ownerId
        tags {
          key
          value
        }
      }
    }
  }
}`;
export default query;