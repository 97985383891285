import { useLazyQuery } from '@apollo/client';
import query from './query';
import { Paging } from 'graphql/types/Paging';
import { AwsDescribeFirewallResponse } from 'graphql/types/AwsFirewall';
import { IGqlResponseNewData } from 'graphql/types';

export interface IGetAwsFirewallVariables {
  cloudId: number;
  region: string;
  request: {
    firewallName: string;
    firewallArn: string;
    maxResults?: number;
    nextToken?: string;
    filters?: {
      name: string;
      values: string[];
    };
    pageInfo?: Paging;
  };
}

export interface IAwsFirewallResponseData {
  getAwsFirewall: IGqlResponseNewData<AwsDescribeFirewallResponse>;
}

const lazyGetAwsFirewallPage = () =>
  useLazyQuery<IAwsFirewallResponseData, IGetAwsFirewallVariables>(query, {
    fetchPolicy: 'no-cache',
  });
export default lazyGetAwsFirewallPage;
