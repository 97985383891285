import { gql } from '@apollo/client';

const query = gql`
query getAwsSubnets($request: AwsDescribeSubnetsRequest, $cloudId: Long, $region: String) {
  getAwsSubnets(
    describeSubnetsRequest: $request
    cloudId: $cloudId
    region: $region
  ) {
    result
    data {
      ... on AwsDescribeSubnetsResponse {
        subnets {
          ... on AwsSubnet {
            availabilityZone
            availabilityZoneId
            availableIpAddressCount
            cidrBlock
            defaultForAz
            enableLniAtDeviceIndex
            mapPublicIpOnLaunch
            mapCustomerOwnedIpOnLaunch
            customerOwnedIpv4Pool
            state
            subnetId
            assignIpv6AddressOnCreation
            ipv6CidrBlockAssociationSet {
              ... on AwsSubnetIpv6CidrBlockAssociation {
                associationId
                ipv6CidrBlock
                ipv6CidrBlockState {
                  ... on AwsSubnetCidrBlockState {
                    state
                    statusMessage
                  }
                }
              }
            }
            subnetArn
            outpostArn
            enableDns64
            ipv6Native
            privateDnsNameOptionsOnLaunch {
              ... on AwsPrivateDnsNameOptionsOnLaunch {
                hostnameType
                enableResourceNameDnsARecord
                enableResourceNameDnsAAAARecord
              }
            }
            vpcId
            ownerId
            tags {
              ... on AwsTag {
                key
                value
              }
            }
          }
        }
      }
    }
  }
}
`;
export default query;