import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsDescribeListenersResponse } from 'graphql/types/AwsDescribeListenersResponse';

export interface IGetAwsDescribeListenersVariables {
  cloudId: number;
  region: string;
  request: {
    loadBalancerArn: string;
    listenerArns?: string[];
    marker?: string;
    pageSize?: number;
  }
}

export interface IAwsDescribeListenersResponseData {
  getAwsDescribeListeners: IGqlResponseNewData<AwsDescribeListenersResponse>;
}

const lazyGetAwsDescribeListeners = () => useLazyQuery<IAwsDescribeListenersResponseData, IGetAwsDescribeListenersVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsDescribeListeners;