import { gql } from '@apollo/client';

const query = gql`
query getAwsDescribeSecret($request: AwsDescribeSecretRequest, $cloudId: Long, $region: String) {
  getAwsDescribeSecret(describeSecretRequest: $request, cloudId: $cloudId, region: $region) {
    result
     data{
      ... on AwsDescribeSecretResponse {
        arn
        name
        description
        kmsKeyId
        rotationEnabled
        rotationLambdaARN
        rotationRules {
          automaticallyAfterDays
          duration
          scheduleExpression
        }
        lastRotatedDate
        lastChangedDate
        lastAccessedDate
        deletedDate
        nextRotationDate
        tags {
          key
          value
        }
        versionIdsToStages
        owningService
        createdDate
        primaryRegion
        replicationStatus {
          region
          kmsKeyId
          status
          statusMessage
          lastAccessedDate
        }
        }
      }
    }
  }
`;
export default query;