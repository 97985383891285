import './index.scss';
import LinkIcon from 'assets/svgs/v3/ico_link.svg';

import CopyableText from 'components/v3/CopyableText';
import { IMgdDetailContentProps, IMgdTabProps, TextTypeEnum } from '../..';
import Icon from 'components/v2/atoms/Icon';
import MgdTooltip from 'components/v2/MgdTooltip';
import { Fragment, useMemo } from 'react';

interface IContentKeyValueProps {
  id?: string;
  type?: TextTypeEnum;
  title: string | string[];
  description: any;
  mainTabSelected?: IMgdTabProps;
  detailHyperLinkClick?: (currTab: IMgdTabProps, data: any) => void;
}

const Content = (props: IContentKeyValueProps) => {
  const { type, title, description, mainTabSelected, detailHyperLinkClick } = props;

  return (
    <div className="content">
      <div className="title">
        {title instanceof Array ? (
          <>
            {title.map((subTitle, index) => {
              return <p key={`content-title-${index}`}>{subTitle}</p>;
            })}
          </>
        ) : (
          <>
            {title.split('\n').map((subTitle, index) => {
              return <p key={`content-title-${index}`}>{subTitle}</p>;
            })}
          </>
        )}
      </div>
      {type === TextTypeEnum.YELLOW && (
        <>
          {description instanceof Array ? (
            <>
              {description.map((des, index) => {
                return (
                  <div className="yellow-text" key={`content-description-${index}`}>
                    <p>{des}</p>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              <div className="yellow-text">
                <p>{description}</p>
              </div>
            </>
          )}
        </>
      )}
      {type === TextTypeEnum.COPY && (
        <>
          {description instanceof Array ? (
            <>
              {description.map((des, index) => {
                return (
                  <Fragment key={`content-description-${index}`}>
                    {des != '-' ? <CopyableText text={des} /> : <p>-</p>}
                  </Fragment>
                );
              })}
            </>
          ) : (
            <>{description != '-' ? <CopyableText text={description} /> : <p>-</p>}</>
          )}
        </>
      )}
      {type === TextTypeEnum.STATUS && (
        <>
          {description instanceof Array ? (
            <>
              {description.map((des, index) => {
                return (
                  <div className="status" key={`content-description-${index}`}>
                    <p>{des}</p>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              <div className="status">
                <p>{description}</p>
              </div>
            </>
          )}
        </>
      )}
      {type === TextTypeEnum.LINK && (
        <>
          {description ? (
            <div className="value flex link">
              <Icon
                width={14}
                height={14}
                src={LinkIcon}
                onClick={() => {
                  detailHyperLinkClick &&
                    detailHyperLinkClick(mainTabSelected ? mainTabSelected : ({} as IMgdTabProps), props);
                }}
              />
              {description.type ? (
                <MgdTooltip
                  id={`${description.type}-tooltip`}
                  title={`${description.values.length} ${description.title}`}
                >
                  {description.values.map((value: string, index: number) => {
                    return (
                      <div
                        key={index}
                        className="link"
                        onClick={() => {
                          detailHyperLinkClick &&
                            detailHyperLinkClick(mainTabSelected ? mainTabSelected : ({} as IMgdTabProps), {
                              ...props,
                              description: value,
                            });
                        }}
                      >
                        {value}
                      </div>
                    );
                  })}
                </MgdTooltip>
              ) : (
                <p
                  onClick={() => {
                    detailHyperLinkClick &&
                      detailHyperLinkClick(mainTabSelected ? mainTabSelected : ({} as IMgdTabProps), props);
                  }}
                >
                  {description}
                </p>
              )}
            </div>
          ) : (
            <p>-</p>
          )}
        </>
      )}
      {type === TextTypeEnum.NORMAL && (
        <>
          {description instanceof Array ? (
            <>
              {description.map((des, index) => {
                return (
                  <div className="value" key={`content-description-${index}`}>
                    <p>{des}</p>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              <div className="value">
                <p>{description}</p>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

const Detail = (data: IMgdDetailContentProps) => {
  const { contents, totalContent, detailContentWidth, detailHyperLinkClick, mainTabSelected, numberOfColumns } = data;

  const renderContentByNumberOfColumns = useMemo(() => {
    if (numberOfColumns === 2) {
      return (
        <>
          {contents?.map((detail, index) => {
            if ('title' in detail && 'type' in detail && 'description' in detail) {
              return (
                <div className={`col ${(index + 1) % 2 === 0 ? 'last' : ''} two-columns`} key={`${index}`}>
                  <Content
                    id={detail?.id}
                    type={detail.type}
                    title={detail.title}
                    description={detail.description}
                    mainTabSelected={mainTabSelected}
                    detailHyperLinkClick={detailHyperLinkClick}
                  />
                </div>
              );
            }
          })}
        </>
      );
    }

    if (numberOfColumns === 1) {
      return (
        <>
          {contents?.map((detail, index) => {
            if ('title' in detail && 'type' in detail && 'description' in detail) {
              return (
                <div className={`col last one-column`} key={`${index}`}>
                  <Content
                    id={detail?.id}
                    type={detail.type}
                    title={detail.title}
                    description={detail.description}
                    mainTabSelected={mainTabSelected}
                    detailHyperLinkClick={detailHyperLinkClick}
                  />
                </div>
              );
            }
          })}
        </>
      );
    }

    return (
      <>
        {contents?.map((detail, index) => {
          if ('title' in detail && 'type' in detail && 'description' in detail) {
            return (
              <div
                className={`col ${
                  detailContentWidth &&
                  (index + 1) % Math.floor((detailContentWidth > 1160 ? 1160 : detailContentWidth) / 290) === 0
                    ? 'last'
                    : ''
                }`}
                key={`${index}`}
              >
                <Content
                  id={detail?.id}
                  type={detail.type}
                  title={detail.title}
                  description={detail.description}
                  mainTabSelected={mainTabSelected}
                  detailHyperLinkClick={detailHyperLinkClick}
                />
              </div>
            );
          }
        })}
      </>
    );
  }, [detailContentWidth, contents]);

  return (
    <>
      {renderContentByNumberOfColumns}
      {totalContent && (
        <div className="total-container">
          <div className="total-content">
            {totalContent.map((item, index) => {
              return (
                <div className="total-item" key={`total-item-${index}`}>
                  <div className="title">
                    <p>{item.title}</p>
                  </div>
                  <div className="description">
                    {item.type === TextTypeEnum.GREEN && <p className="text-green-01">{item.description}</p>}
                    {item.type === TextTypeEnum.YELLOW && <p className="yellow-text">{item.description}</p>}
                    {item.type === TextTypeEnum.RED && <p className="text-red-01">{item.description}</p>}
                    {item.type === TextTypeEnum.NORMAL && <p>{item.description}</p>}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default Detail;
