import { useCallback, useEffect, useMemo, useState } from 'react';
import { TargetsPropsType } from '../types';
import { TARGETS_TABLE_COLUMNS } from '../config';
import { RowType } from '@Types/v2/Table';
import DetailTable from '../../DetailTable';
import lazyGetAwsDescribeTargetHealth, {
  IGetAwsDescribeTargetHealthVariables,
} from 'graphql/queries/getAwsDescribeTargetHealth';
import { AwsTargetHealthDescription } from 'graphql/types/AwsDescribeTargetHealthResponse';
import lazyGetAllAwsDescribeInstances, {
  AwsAllDescribeInstancesRequestType,
} from 'graphql/queries/getAwsAllDescribeInstances';
import { AwsDescribeInstanceType } from 'graphql/types/AwsDescribeInstance';
import { dateFormatter } from 'utils/Formatter';

const Targets = (props: TargetsPropsType) => {
  const { targetGroup, cloudId, region } = props;
  const [getAwsDescribeTargetHealth, { loading: awsDescribeTargetHealthLoading }] = lazyGetAwsDescribeTargetHealth();
  const [getAllAwsDescribeInstances, { loading: awsDescribeInstanceLoading }] = lazyGetAllAwsDescribeInstances();
  const [targetHealthDescriptions, setTargetHealthDescriptions] = useState<AwsTargetHealthDescription[]>([]);
  const [instances, setInstances] = useState<AwsDescribeInstanceType[]>([]);

  const apiIsLoading = useMemo(() => {
    return awsDescribeTargetHealthLoading || awsDescribeInstanceLoading;
  }, [awsDescribeTargetHealthLoading, awsDescribeInstanceLoading]);

  const fetchGetAwsDescribeTargetHealth = useCallback(() => {
    if (targetGroup) {
      const healthVariableRequest: IGetAwsDescribeTargetHealthVariables = {
        cloudId: cloudId,
        region: region,
        request: {
          targetGroupArn: targetGroup.targetGroupArn,
        },
      };
      getAwsDescribeTargetHealth({ variables: healthVariableRequest }).then(({ data: awsDescribeTargetHealthData }) => {
        const targetHealthDes =
          awsDescribeTargetHealthData?.getAwsDescribeTargetHealth?.data?.[0]?.targetHealthDescriptions;
        if (targetHealthDes) {
          setTargetHealthDescriptions(targetHealthDes);

          const instanIds = targetHealthDes.map(targetHealth => targetHealth?.target?.id);
          const instanceRequest: AwsAllDescribeInstancesRequestType = {
            cloudId: cloudId,
            region: region,
            request: {
              instanceIds: instanIds,
            },
          };
          getAllAwsDescribeInstances({ variables: instanceRequest }).then(({ data: awsDescribeInstanceData }) => {
            const awsDescribeInstances = awsDescribeInstanceData?.getAwsAllDescribeInstances?.data;
            if (awsDescribeInstances) {
              setInstances(awsDescribeInstances);
            }
          });
        }
      });
    }
  }, [cloudId, region, targetGroup]);

  const targetsTableRows = useMemo((): RowType[] => {
    const targetRows: RowType[] = [];
    if (instances.length > 0) {
      targetHealthDescriptions.map(targetDescription => {
        const instance = instances.find(
          instance => instance.instances?.[0].instanceId === targetDescription?.target?.id,
        )?.instances?.[0];
        if (instance) {
          targetRows.push({
            id: targetDescription?.target?.id,
            instanceName: instance?.tags?.find(t => t?.key == 'Name')?.value,
            port: targetDescription?.target?.port,
            zone: instance?.placement?.availabilityZone,
            healthStatus: targetDescription?.targetHealth?.state,
            healthStatusDetail: targetDescription?.targetHealth?.reason,
            launchTime: dateFormatter(instance?.launchTime as string, 'datetime'),
            anomalyDetectionResult: targetDescription?.anomalyDetection?.result,
          });
        }
      });
    }

    return targetRows;
  }, [targetHealthDescriptions, instances]);

  useEffect(() => {
    fetchGetAwsDescribeTargetHealth();
  }, []);

  if (apiIsLoading) {
    return (
      <div className="detail-info">
        <div className="progress-container">
          <div className="progress-gif" />
          Loading ...
        </div>
      </div>
    );
  }

  return (
    <DetailTable
      data={targetsTableRows}
      columns={TARGETS_TABLE_COLUMNS}
      title={'Registered targets'}
      description={
        'Target groups route requests to individual registered targets using the protocol and port number specified. Health checks are performed an all registered \n targets according to the target group’s health check settings. Anomaly detection is automatically applied to HTTP/HTTPS target groups with at least 3 healthy targets'
      }
    />
  );
};

export default Targets;
