import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import lazyGetAwsListIPSets from 'graphql/queries/getAwsListIPSets';
import { variableCombineNextToken } from 'pages/v2/Organ/Management/Utils';
import { RowType } from '@Types/v2/Table';
import {
  ACTION,
  IP_ADDRESS_SELECTOR,
  MISSING_IP_ADDRESS,
  POSITION_INSIDE_HEADER,
} from 'pages/v2/Organ/Management/WAF/CustomRuleModal/constant';
import CheckboxAtom from 'components/v2/atoms/CheckboxAtom';
import Collapse from 'pages/v2/Organ/Management/WAF/CustomRuleModal/components/Collapse';
import RuleName from 'pages/v2/Organ/Management/WAF/CustomRuleModal/IpSet/RuleName';
import IpAddress from 'pages/v2/Organ/Management/WAF/CustomRuleModal/IpSet/IpAddress';
import HeaderFieldName from 'pages/v2/Organ/Management/WAF/CustomRuleModal/IpSet/HeaderFieldName';
import PositionInsideHeader from 'pages/v2/Organ/Management/WAF/CustomRuleModal/IpSet/PositionInsideHeader';
import MissingIpAddress from 'pages/v2/Organ/Management/WAF/CustomRuleModal/IpSet/MissingIpAddress';
import Action from 'pages/v2/Organ/Management/WAF/CustomRuleModal/components/Action';
import ImmunityTime from 'pages/v2/Organ/Management/WAF/CustomRuleModal/components/ImmunityTime';
import CustomHeader from 'pages/v2/Organ/Management/WAF/CustomRuleModal/components/CustomHeader';
import IpSetOption from '../components/IpSetOption';
import { AwsIPSets } from 'graphql/types/AwsListIPSet';

type RuleGroupPropsType = {
  cloudId: number;
  selectedRegion: DropdownListDataType;
  ipSets: Array<AwsIPSets>;
};

function IpSet({ cloudId, selectedRegion, ipSets }: RuleGroupPropsType) {
  const [ruleName, setRuleName] = useState('');
  const [ipSet, setIpSet] = useState<DropdownListDataType>({
    name: '',
    value: '',
  });
  const [ipAddressSelector, setIpAddressSelector] = useState(IP_ADDRESS_SELECTOR[0].value);
  const [headerFieldName, setHeaderFieldName] = useState('');
  const [positionInsideHeader, setPositionInsideHeader] = useState(POSITION_INSIDE_HEADER[0].value);
  const [missingIpAddress, setMissingIpAddress] = useState(MISSING_IP_ADDRESS[0].value);
  const [action, setAction] = useState(ACTION[0].value);
  const [seconds, setSeconds] = useState('300');
  const [isCustomImmunityTime, setIsCustomImmunityTime] = useState(false);
  const [isEnable, setIsEnable] = useState(false);
  const [customHeaderList, setCustomHeaderList] = useState<[]>([]);

  const handleSelectAction = (value: string) => {
    setAction(value);
    setSeconds('300');
    setIsCustomImmunityTime(false);
    setIsEnable(false);
    setCustomHeaderList([]);
  };

  return (
    <>
      <RuleName ruleName={ruleName} setRuleName={setRuleName} />

      <div className="rule-container-group">
        <p className="rule-container-group-title">IP set</p>

        <div className="rule-container-group-content">
          <IpSetOption
            cloudId={cloudId}
            selectedRegion={selectedRegion}
            ipSet={ipSet}
            setIpSet={setIpSet}
            ipSets={ipSets}
          />

          <IpAddress ipAddressSelector={ipAddressSelector} setIpAddressSelector={setIpAddressSelector} />

          {ipAddressSelector === IP_ADDRESS_SELECTOR[1].value ? (
            <>
              <HeaderFieldName headerFieldName={headerFieldName} setHeaderFieldName={setHeaderFieldName} />

              <PositionInsideHeader
                positionInsideHeader={positionInsideHeader}
                setPositionInsideHeader={setPositionInsideHeader}
              />

              <MissingIpAddress missingIpAddress={missingIpAddress} setMissingIpAddress={setMissingIpAddress} />
            </>
          ) : null}

          <Action action={action} setAction={val => handleSelectAction(val)} />

          {action === ACTION[3].value || action === ACTION[4].value ? (
            <ImmunityTime
              seconds={seconds}
              setSeconds={setSeconds}
              isCustomImmunityTime={isCustomImmunityTime}
              setIsCustomImmunityTime={setIsCustomImmunityTime}
            />
          ) : null}
        </div>
      </div>

      {action !== ACTION[4].value ? (
        <Collapse
          className="rule-container-group-list-container"
          label={
            <div className="rule-container-group-title">
              <p>Custom request</p>

              <span>-optional</span>
            </div>
          }
        >
          {action === ACTION[1].value ? (
            <div>
              <p className="rule-container-group-content-description">
                With the Block action, you can send a custom response to the web request.
              </p>

              <div className="checkbox-container space-8">
                <label>
                  <CheckboxAtom checked={isEnable} onchange={() => setIsEnable(!isEnable)} />
                  Enable
                </label>
              </div>
            </div>
          ) : (
            <CustomHeader customHeaderList={customHeaderList} setCustomHeaderList={setCustomHeaderList} />
          )}
        </Collapse>
      ) : null}
    </>
  );
}

// @ts-ignore
export default IpSet;
