import { gql } from '@apollo/client';

const query = gql`
mutation deleteWebACL($request: AwsDeleteWebAclRequest, $cloudId: Long, $region: String) {
  deleteWebACL(deleteWebAclRequest: $request, cloudId: $cloudId, region: $region) {
    result
    data {
      __typename
    }
  }
}
`;
export default query;
