import React from 'react';
import {IP_ADDRESS_SELECTOR} from "pages/v2/Organ/Management/WAF/CustomRuleModal/constant";
import RadioAtom from "components/v2/atoms/RadioAtom";

type IpAddressPropsType = {
  ipAddressSelector: string;
  setIpAddressSelector: (val: string) => void;
};

function IpAddress({ipAddressSelector, setIpAddressSelector}: IpAddressPropsType) {
  return (
    <div className='group-ip-address'>
      <p className='rule-container-group-content-label'>IP address to use as the originating address</p>

      <p className='rule-container-group-content-description'>When request comes through a CDN or other proxy
        network, the
        source IP address identifies the proxy and the original IP address is sent in a header. Use caution with
        the
        option, IP address in header, because headers can be handled inconsistently by proxies and they can be
        modified to bypass inspection.</p>

      <div className='space-8'>
        {IP_ADDRESS_SELECTOR.map(({id, label, value, name}) => (
          <RadioAtom
            key={id}
            label={label}
            value={value}
            name={name}
            checked={ipAddressSelector}
            onChange={setIpAddressSelector}
          />
        ))}
      </div>
    </div>
  );
}

export default IpAddress;