import { DropdownListDataType } from "components/Dropdown/types";
import { IMgdTabProps, TextTypeEnum } from "layouts/v3/MgdLayout";

export interface IStagingLabelContentProps {
  label: string;
}
export interface IStagingLabelProps {
  [key: string]: IStagingLabelContentProps;
}

export const mainTabs: IMgdTabProps[] = [
  {id: 'awsManagedKey',name: 'AWS Key', title: 'AWS Key'},
  {id: 'customerKey',name: 'Customer key', title: 'Customer key'},
  {id: 'secret',name: 'Secret', title: 'Secrets'}
];

export const detailAwsKey: IMgdTabProps[] = [
  {id: 'generalConfiguration', name: 'General configuration', title: 'General configuration'},
  {id: 'keyPolicy', name: 'Key policy', title: 'Key policy'},
  {id: 'cryptographicConfiguration', name: 'Cryptographic configuration', title: 'Cryptographic configuration'}
]

export const detailCustomerKeyTabs: IMgdTabProps[] = [
  {id: 'generalConfig', name: 'General configuration', title: 'General configuration'},
  {id: 'keyPolicy', name: 'Key policy', title: 'Key policy'},
  {id: 'cryptographicConfig', name: 'Cryptographic configuration', title: 'Cryptographic configuration'},
  {id: 'tags', name: 'Tags', title: 'Tags'},
  {id: 'keyRotation', name: 'Key rotation', title: 'Key rotation'},
  {id: 'regionality', name: 'Regionality', title: 'Regionality'},
  {id: 'aliases', name: 'Aliases', title: 'Aliases'}
]

export const detailCustomerKeyTextDes: {id: string, value: string, type: string}[] = [
  {id: 'name', value: 'Alias', type: TextTypeEnum.NORMAL},
  {id: 'status', value: 'Status', type: TextTypeEnum.STATUS},
  {id: 'createDate', value: 'Creation date', type: TextTypeEnum.NORMAL},
  {id: 'arn', value: 'ARN', type: TextTypeEnum.COPY},
  {id: 'description', value: 'Description', type: TextTypeEnum.NORMAL},
  {id: 'regionality', value: 'Regionality', type: TextTypeEnum.NORMAL}
];

export const detailSecretTabs: IMgdTabProps[] = [
  {id: 'secret-detail', name: 'Secret details', title: 'Secret details'},
  {id: 'overview', name: 'Overview', title: 'Overview'},
  {id: 'retation', name: 'Rotations', title: 'Rotations'},
  {id: 'version', name: 'Versions', title: 'Versions'},
  {id: 'replications', name: 'Replication', title: 'Replication'},
  {id: 'tags', name: 'Tags', title: 'Tags'}
]

export const detailAwsKeyKeys = ['alias', 'status', 'creationDate', 'arn', 'description', 'regionality']
export const detailAwsKeyTextDes: {id: string, value: string, type: TextTypeEnum}[] = [
  {id: 'alias', value: 'Alias', type: TextTypeEnum.NORMAL},
  {id: 'status', value: 'Status', type: TextTypeEnum.STATUS},
  {id: 'creationDate', value: 'Creation date', type: TextTypeEnum.NORMAL},
  {id: 'arn', value: 'ARN', type: TextTypeEnum.LINK},
  {id: 'description', value: 'Description', type: TextTypeEnum.NORMAL},
  {id: 'regionality', value: 'Regionality', type: TextTypeEnum.NORMAL},
]

export const detailSecretKeys = ['kmsKeyId', 'description', 'name', 'deletedDate', 'arn'];
export const detailSecretTextDes: {id: string, value: string, type: string}[] = [
  {id: 'kmsKeyId', value: 'Encryption key', type: TextTypeEnum.COPY},
  {id: 'description', value: 'Secret description', type: TextTypeEnum.NORMAL},
  {id: 'name', value: 'Secret name', type: TextTypeEnum.COPY},
  {id: 'deletedDate', value: '', type: TextTypeEnum.NORMAL},
  {id: 'arn', value: 'Secret ARN', type: TextTypeEnum.COPY}
];

export const STAGING_LABLE_MAPPING: IStagingLabelProps = {
  'AWSCURRENT': {label: 'AWS current'},
  '': {label: '-'}
};

export const awsKeyFilterDropdown: DropdownListDataType[] = [
  {id: 1, name: 'Aliases', value: 'aliases'},
  {id: 2, name: 'Key ID', value: 'key'}
];

export const customerKeyFilterDropdown: DropdownListDataType[] = [
  {id: 1, name: 'Aliases', value: 'aliases'},
  {id: 2, name: 'Key ID', value: 'keyId'}
];

export const secretFilterDropdown: DropdownListDataType[] = [
  {id: 1, name: 'Name', value: 'name'},
  {id: 2, name: 'Description', value: 'description'}
];