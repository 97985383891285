import { gql } from '@apollo/client';

const query = gql`
query getAwsLoadBalancers($request: AwsDescribeLoadBalancersRequest, $cloudId: Long, $region: String) {
  getAwsLoadBalancers(decribeLoadBalancersRequest: $request, cloudId: $cloudId, region: $region) {
    result
    data {
      ... on AwsDescribeLoadBalancersResponse {
         loadBalancers {
          loadBalancerArn
          dnsName
          canonicalHostedZoneId
          createdTime
          loadBalancerName
          scheme
          vpcId
          state
          type
          availabilityZones {
            state
            optInStatus
            messages {
              message
            }
            regionName
            zoneName
            zoneId
            groupName
            networkBorderGroup
            zoneType
            parentZoneName
            parentZoneId
          }
          securityGroups
          ipAddressType
          customerOwnedIpv4Pool
          enforceSecurityGroupInboundRulesOnPrivateLinkTraffic
        }
        nextMarker
        }
      }
    }
  }
`;
export default query;