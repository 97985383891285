import { gql } from '@apollo/client';

const query = gql`
query getAwsListAliases($request: AwsListAliasesRequest, $cloudId: Long, $region: String) {
  getAwsListAliases(
    listAliasesRequest: $request
    cloudId: $cloudId
    region: $region
  ) {
    result
    data {
      ... on AwsListAliasesResponse {
        aliases {
          aliasName
          aliasArn
          targetKeyId
          lastUpdatedDate
          creationDate
        }
        nextMarker
        truncated
      }
    }
  }
}`;
export default query;