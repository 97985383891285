import { useLazyQuery } from '@apollo/client';
import query from './query';
import { AwsInternetGatewayType } from 'graphql/types/AwsInternetGateway';
import { IGqlResponseNewData } from 'graphql/types';

export interface IAwsAllInternetGatewaysVariables {
  cloudId: number;
  region: string;
  request: {
    maxResults: number;
    nextToken?: string;
    internetGatewayIds?: string[];
    dryRun?: boolean;
    filters?: {
      name: string;
      values: string[];
    }
  }
}

export interface IAwsAllInternetGatewaysResponseData {
  getAwsAllInternetGateways: IGqlResponseNewData<AwsInternetGatewayType>;
}

const lazyGetAllAwsInternetGateways = () => useLazyQuery<IAwsAllInternetGatewaysResponseData, IAwsAllInternetGatewaysVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAllAwsInternetGateways;