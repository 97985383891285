type TextareaContainerPropsType = {
  title: string;
  description?: string;
  note?: string;
  value: string;
  onChange: (val: string) => void;
  placeholder?: string;
};

function TextareaContainer({ title, description, note, value, onChange, placeholder }: TextareaContainerPropsType) {
  return (
    <div>
      <p className="rule-container-group-content-label">{title}</p>

      <p className="rule-container-group-content-description">
        {description}
      </p>

      <div className="textarea-container">
        <textarea
          placeholder={placeholder}
          value={value}
          onChange={event => onChange(event.target.value)}
        />
      </div>

      <p className="rule-container-group-content-description">
        {note}
      </p>
    </div>
  );
}

export default TextareaContainer;
