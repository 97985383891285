import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import CopyableText from 'components/v3/CopyableText';
import { IMgdDetailKeyValueProps, TextTypeEnum } from 'layouts/v3/MgdLayout';
import './index.scss';

export type DetailTabPropsType = {
  title?: string;
  isApiLoading?: boolean;
  description?: string;
  styleClass?: string;
  textTypeDescription?: 'STATUS' | 'LINK' | 'NORMAL' | 'COPY';
  handleLinkClick?: (item: IMgdDetailKeyValueProps) => void;
  data?: IMgdDetailKeyValueProps[];
  numberOfColumns?: number;
};

const DetailTabTargetGroup = ({
  title,
  styleClass,
  description,
  data,
  textTypeDescription,
  numberOfColumns,
  handleLinkClick,
  isApiLoading,
}: DetailTabPropsType) => {
  const detailInfoRef = useRef<HTMLDivElement>(null);

  const [detailInfoWidth, setDetailInfoWidth] = useState(0);

  useEffect((): () => void => {
    const observer = new ResizeObserver(entries => {
      setDetailInfoWidth(entries[0].contentRect.width);
    });
    if (detailInfoRef.current) {
      observer.observe(detailInfoRef.current);
    }

    return () => detailInfoRef.current && observer.unobserve(detailInfoRef.current);
  }, [setDetailInfoWidth, data?.length, isApiLoading]);

  const renderValue = useCallback((detail: IMgdDetailKeyValueProps) => {
    switch (detail.type) {
      case TextTypeEnum.COPY:
        if (detail.description instanceof Array) {
          return (
            <Fragment>
              {detail.description.map((item, index) => {
                return <CopyableText text={item} key={`detail-tab-copy-${index}`} />;
              })}
            </Fragment>
          );
        } else {
          return <CopyableText text={detail.description} />;
        }
      case TextTypeEnum.GREEN:
        return (
          <div className="status">
            <p>{detail.description}</p>
          </div>
        );
      case TextTypeEnum.RED:
        return (
          <div className="text-red-01">
            <p>{detail.description}</p>
          </div>
        );
      case TextTypeEnum.LINK:
        if (detail.description instanceof Array) {
          return (
            <Fragment>
              {detail.description.map((item, index) => {
                return (
                  <p
                    key={`detail-tab-link-${index}`}
                    className="description link"
                    onClick={e => {
                      e.stopPropagation();
                      handleLinkClick && handleLinkClick(detail);
                    }}
                  >
                    {item}
                  </p>
                );
              })}
            </Fragment>
          );
        } else {
          return (
            <p
              className="description link"
              onClick={e => {
                e.stopPropagation();
                handleLinkClick && handleLinkClick(detail);
              }}
            >
              {detail.description}
            </p>
          );
        }
      case TextTypeEnum.COPY_LINK:
        return <CopyableText text={detail.description} onTextClicked={() => handleLinkClick?.(detail)} />;
      case TextTypeEnum.NORMAL:
      default:
        return <p>{detail.description}</p>;
    }
  }, []);

  const renderDivColumnNode = useCallback(
    (numberOfColumns: number) => {
      switch (numberOfColumns) {
        case 1:
          return (
            <div className="detail-target-group-content" ref={detailInfoRef}>
              {data?.map((detail: IMgdDetailKeyValueProps, index: number) => (
                <div className={'col one-column'} key={`${index}`}>
                  <div className="content">
                    <div className="title">
                      <p>{detail.title}</p>
                    </div>
                    <div className="value">{renderValue(detail)}</div>
                  </div>
                </div>
              ))}
            </div>
          );
        default:
          return (
            <div className="detail-target-group-content flex-wrap" ref={detailInfoRef}>
              {data?.map((detail: IMgdDetailKeyValueProps, index: number) => (
                <div
                  className={`col ${
                    (index + 1) % Math.floor((detailInfoWidth > 1160 ? 1160 : detailInfoWidth) / 290) === 0
                      ? 'last'
                      : ''
                  }`}
                  key={`${index}`}
                >
                  <div className="content">
                    <div className="title">
                      <p>{detail.title}</p>
                    </div>
                    <div className="value">{renderValue(detail)}</div>
                  </div>
                </div>
              ))}
            </div>
          );
      }
    },
    [detailInfoWidth, data, renderValue],
  );

  return (
    <div className={`detail-target-group ${styleClass}`}>
      {isApiLoading ? (
        <div className="progress-container">
          <div className="progress-gif" />
          Loading ...
        </div>
      ) : (
        <Fragment>
          {title && (
            <div className="detail-target-group-title">
              <p>{title}</p>
              {textTypeDescription === 'COPY' ? (
                <CopyableText text={description ?? ''} key={'detail-tab-copy'} />
              ) : (
                <p>{description}</p>
              )}
            </div>
          )}
          {renderDivColumnNode(numberOfColumns ?? 0)}
        </Fragment>
      )}
    </div>
  );
};

export default DetailTabTargetGroup;
