import { gql } from '@apollo/client';

const query = gql`
  query getAwsAllResources($request: AwsGetResourcesRequest, $cloudId: Long, $region: String) {
    getAwsAllResources(getResourcesRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsResourceTagMapping {
          resourceARN
          tags {
            key
            value
          }
          complianceDetails {
            noncompliantKeys
            keysWithNoncompliantValues
            complianceStatus
          }
        }
      }
    }
  }
`;
export default query;
