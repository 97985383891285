import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseData, IGqlResponseNewData } from 'graphql/types';
import { AwsListWebAcLsResponse } from 'graphql/types/AwsWebACLSummary';

export interface IGetAwsListWebACLsVariables {
  cloudId: number;
  region: string;
  request: {
    limit: number;
    nextMarker?: string;
    scope: string;
  };
}

export interface IAwsListWebACLsResponseData {
  getAwsListWebACLs: IGqlResponseNewData<AwsListWebAcLsResponse>;
}

const lazyGetAwsListWebACLs = () =>
  useLazyQuery<IAwsListWebACLsResponseData, IGetAwsListWebACLsVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsListWebACLs;
