import { gql } from '@apollo/client';

const query = gql`
query getAwsAllListRuleGroupsFirewall($request: AwsListRuleGroupsFirewallRequest, $cloudId: Long, $region: String) {
  getAwsAllListRuleGroupsFirewall(
    listRuleGroupsFirewallRequest: $request
    cloudId: $cloudId
    region: $region
  ) {
    result
    nextToken
    data {
      ... on AwsRuleGroupMetadata {
        name
        arn
      }
    }
  }
}`;
export default query;