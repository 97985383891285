import {useEffect, useMemo, useState} from 'react';
import {fleetTab} from "pages/v2/Organ/Management/EC2/components/SpotRequest/Common/Constant";
import Description from "pages/v2/Organ/Management/EC2/components/SpotRequest/Common/Components/Fleet/Description";
import Instances from "pages/v2/Organ/Management/EC2/components/SpotRequest/Common/Components/Fleet/Instances";
import History from "pages/v2/Organ/Management/EC2/components/SpotRequest/Common/Components/Fleet/History";
import { FleetInstancePropsType } from '../types';
import { IMgdTabProps } from 'layouts/v3/MgdLayout';
import { ColumnType } from '@Types/v2/Table';
import TagTab from '../../TagTab';

const Fleet = (props: FleetInstancePropsType) => {
  const {cloudId, region, currentSpotFleetInstance} = props;
  const [tabSelected, setTabSelected] = useState<IMgdTabProps>(fleetTab[0]);

  useEffect(() => {
    if (!currentSpotFleetInstance) return;

    setTabSelected(fleetTab[0])
  }, [currentSpotFleetInstance]);

  const tagColumns = useMemo((): ColumnType[] => {
    return [
      {
        label: 'Key',
        field: 'key',
        sort: true,
      },
      {
        label: 'Value',
        field: 'value',
        sort: true,
      },
    ];
  }, []);

  const renderTab = useMemo(() => {
    switch (tabSelected) {
      case fleetTab[0]:
        return <Description currentSpotFleetInstance={currentSpotFleetInstance} />

      case fleetTab[1]:
        return <TagTab title={'Tags'} rows={currentSpotFleetInstance?.tags} columns={tagColumns} />

      case fleetTab[2]:
        return (
          <Instances cloudId={cloudId} region={region} currentSpotFleetInstance={currentSpotFleetInstance} />
        )

      case fleetTab[3]:
        return (
          <History
            cloudId={cloudId}
            region={region}
            currentSpotFleetInstance={currentSpotFleetInstance}
          />
        )

      default:
        return null;
    }
  }, [tabSelected, currentSpotFleetInstance, cloudId, region, tagColumns]);

  return (
    <div className="box-summary">
      <div className="box-summary-id">{currentSpotFleetInstance?.spotFleetRequestId}</div>

      <div className="detail-tab flex a-center">
        {fleetTab.map(tab => {
          return (
            <button
              className={`detail-tab-items ${tabSelected?.id === tab.id && 'active'}`}
              key={tab.id}
              data-tab={tab.id}
              onClick={e => setTabSelected(tab)}
            >
              <p>{tab.name}</p>
            </button>
          );
        })}
      </div>

      <div className="content-tab">
        {renderTab}
      </div>
    </div>
  );
}

export default Fleet;