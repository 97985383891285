import { gql } from '@apollo/client';

const query = gql`
  mutation createAwsRegexPatternSet($reqData: AwsCreateRegexPatternSetRequest, $cloudId: Long, $region: String) {
    createAwsRegexPatternSet(createRegexPatternSetRequest: $reqData, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsCreateRegexPatternSetResponse {
          summary {
            name
            id
            lockToken
            description
            arn
          }
        }
      }
    }
  }
  
`;
export default query;