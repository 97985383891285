import { useCallback, useEffect, useMemo, useState } from 'react';
import { IAMPropsType } from './types';
import { IMgdTabProps } from 'layouts/v3/MgdLayout';
import { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import { useOutletContext } from 'react-router-dom';
import { lazyGetEnumTypeCode } from 'graphql/queries/getEnumTypeCode';
import MgdTabLayout from 'layouts/v3/MgdTabLayout';
import { IAM_TAB_LIST } from './configs';
import { MgdTabLayoutTypeEnum } from 'layouts/v3/MgdTabLayout/configs';
import UserGroups from './components/UserGropus/ListUp';
import UserGroupsDetail from './components/UserGropus/Detail';
import { UserGroupRowType } from './components/UserGropus/types';
import User from './components/User/ListUp';

import './index.scss';

const IAM = (props: IAMPropsType) => {
  const {
    onBackButtonClicked,
    projectId,
    cloudName,
    regionCode,
    regions,
    recentRelatedClouds,
    relatedCloudSelected,
    recentRelatedCloudOnChange,
  } = props;

  // Context
  const { defaultRegionList, updateDefaultRegionList } = useOutletContext<{
    defaultRegionList: { projectId: number; cloudId: number; name: string; value: string }[];
    updateDefaultRegionList: (projectId: number, cloudId: number, name: string, value: string) => void;
  }>();

  // API
  const [getEnumTypeCode, { loading: enumTypeLoading }] = lazyGetEnumTypeCode();

  // State
  const [currentRegion, setCurrentRegion] = useState<DropdownListDataType>({ name: '', value: '' });
  const [regionList, setRegionList] = useState<DropdownListDataType[]>([]);
  const [layoutType, setLayoutType] = useState<MgdTabLayoutTypeEnum>(MgdTabLayoutTypeEnum.MGD_LIST_SUMMARY);
  const [mainTabSelected, setMainTabSelected] = useState<IMgdTabProps>(IAM_TAB_LIST[0]);
  const [isDetailPage, setIsDetailPage] = useState(false);
  const [userGroup, setUserGroup] = useState<UserGroupRowType>();

  const cloudId = useMemo((): number => {
    return Number(relatedCloudSelected.value);
  }, [relatedCloudSelected]);
  // Get initial region
  const getInitialRegion = useCallback(() => {
    const hasDefaultRegion = defaultRegionList.find(r => {
      return r.projectId === projectId && r.cloudId === cloudId;
    });

    if (!!hasDefaultRegion) {
      setCurrentRegion({
        name: hasDefaultRegion.name,
        value: hasDefaultRegion.value,
      });
    } else {
      getEnumTypeCode({
        variables: { text: regionCode || 'AwsRegionCode' },
      }).then(res => {
        if (res.data) {
          const regionsRes: DropdownListDataType[] = res.data.getEnumTypeCode.data
            .filter(val => regions.includes(val.value))
            .map(val => ({
              ...val,
              name: val.value,
            }));
          setRegionList(regionsRes);
          const region = regionsRes.find(r => r.name === currentRegion.name && r.value === currentRegion.value);
          setCurrentRegion(region || regionsRes[0]);
        }
      });
    }
  }, [cloudId]);

  // For initial data
  useEffect(() => {
    getInitialRegion();
  }, [getInitialRegion]);

  const resetMainStates = useCallback(() => {}, []);

  const mainTabClickHandler = useCallback(
    (tabSelected: IMgdTabProps) => {
      setMainTabSelected(IAM_TAB_LIST.find(tabItem => tabItem.id === tabSelected.id) || IAM_TAB_LIST[0]);
      resetMainStates();

      switch (tabSelected) {
        case IAM_TAB_LIST[0]:
          setUserGroup(undefined);
          break;
        case IAM_TAB_LIST[1]:
          break;
        case IAM_TAB_LIST[2]:
          break;
        case IAM_TAB_LIST[3]:
          break;
        default:
          break;
      }
    },
    [setMainTabSelected, resetMainStates],
  );

  const tabContentNode = useMemo(() => {
    if (!currentRegion?.value || !cloudId) return;

    switch (mainTabSelected) {
      case IAM_TAB_LIST[0]: {
        return (
          <UserGroups
            cloudId={cloudId}
            region={currentRegion.value.toString()}
            setUserGroup={setUserGroup}
            setIsDetailPage={() => setIsDetailPage(true)}
          />
        );
      }
      case IAM_TAB_LIST[1]:
        return (
          <User
            cloudId={cloudId}
            region={currentRegion.value.toString()}
          />
        );
      case IAM_TAB_LIST[2]:
      case IAM_TAB_LIST[3]:
      default:
        return null;
    }
  }, [mainTabSelected, currentRegion, cloudId]);

  const tabSummaryNode = useMemo(() => {
    switch (mainTabSelected) {
      case IAM_TAB_LIST[1]:
      case IAM_TAB_LIST[2]:
      case IAM_TAB_LIST[3]:
      default:
        return null;
    }
  }, [mainTabSelected, cloudId, currentRegion, projectId, userGroup]);

  const pageBackClick = () => {
    setIsDetailPage(false);
    setUserGroup(undefined);
  };

  const tabDetailNode = useMemo(() => {
    if (!isDetailPage) return null;

    switch (mainTabSelected) {
      case IAM_TAB_LIST[0]:
        return (
          <UserGroupsDetail
            cloudId={cloudId}
            region={currentRegion.value.toString()}
            userGroup={userGroup}
            pageBackClick={pageBackClick}
          />
        );
      case IAM_TAB_LIST[1]:
      case IAM_TAB_LIST[2]:
      case IAM_TAB_LIST[3]:
      default:
        return null;
    }
  }, [mainTabSelected, isDetailPage, cloudId, currentRegion, userGroup]);

  return (
    <div className="iam-container">
      {isDetailPage ? (
        tabDetailNode
      ) : (
        <MgdTabLayout
          layoutType={layoutType}
          pageTitle={cloudName}
          hasFavorite={true}
          onBackButtonClicked={onBackButtonClicked}
          headerDropList={regionList}
          headerDropValue={currentRegion}
          headerDropOnchange={val => {
            setCurrentRegion(() => ({
              name: val.name ? val.name : '',
              value: String(val.value),
            }));
          }}
          mainTabList={IAM_TAB_LIST}
          mainTabItemClick={mainTabClickHandler}
          mainTitle={mainTabSelected?.title || ''}
          mainTabSelected={mainTabSelected}
          tabContentNode={tabContentNode}
          tabSummaryNode={tabSummaryNode}
          recentRelatedClouds={recentRelatedClouds}
          relatedCloudSelected={relatedCloudSelected}
          recentRelatedCloudOnChange={relatedCloud => recentRelatedCloudOnChange(relatedCloud)}
        />
      )}
    </div>
  );
};

export default IAM;
