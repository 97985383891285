import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsDescribeSpotFleetInstancesResponse } from 'graphql/types/AwsDescribeSpotFleetInstancesResponse';

export interface IAwsDescribeSpotFleetInstancesVariables {
  cloudId: number;
  region: string;
  request: {
    spotFleetRequestId?: string;
    maxResults?: number;
    nextToken?: string;
    dryRun?: boolean;
  };
}

export interface IAwsDescribeSpotFleetInstancesResponseData {
  getAwsDescribeSpotFleetInstances: IGqlResponseNewData<AwsDescribeSpotFleetInstancesResponse>;
}

const lazyGetAwsDescribeSpotFleetInstances = () =>
  useLazyQuery<IAwsDescribeSpotFleetInstancesResponseData, IAwsDescribeSpotFleetInstancesVariables>(query, {
    fetchPolicy: 'no-cache',
  });
export default lazyGetAwsDescribeSpotFleetInstances;
