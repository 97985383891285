import { Fragment, useCallback, useMemo, useState } from 'react';
import { ColumnType, RowType } from '@Types/v2/Table';
import './Storage.scss';
import { AwsImageType } from 'graphql/types/AwsImage';
import { capitalize } from '../Common/Functions';
import TagTab from '../../TagTab';
import { handleFormatText } from 'utils/Common';

export type StoragePropsType = {
  data?: AwsImageType;
};

const Storage = (props: StoragePropsType) => {
  const { data } = props;

  const [apiIsLoading, setApiIsLoading] = useState<boolean>(false);

  const handleHyperLinkClick = useCallback((row: RowType) => {}, []);

  const columns = useMemo((): ColumnType[] => {
    return [
      {
        label: 'Device ID',
        field: 'deviceId',
        sort: true,
        width: 240,
        renderCell: (row: RowType) => (
          <>
            {
              <div
                className="link"
                onClick={e => {
                  e.stopPropagation();
                  handleHyperLinkClick(row);
                }}
              >
                {handleFormatText(row.deviceId)}
              </div>
            }
          </>
        ),
      },
      { label: 'Device name', field: 'deviceName', sort: true },
      { label: 'Volume size (GiB)', field: 'volumnSize', sort: true },
      { label: 'Volume type', field: 'volumnType', sort: true },
      { label: 'Encrypted', field: 'encrypted', sort: true },
      { label: 'Delete on termination', field: 'deleteOnTerminate', sort: true },
      { label: 'KMS key ID', field: 'ksmKeyId', sort: true },
      { label: 'Outpost ID', field: 'outpostId', sort: true },
    ];
  }, []);

  const rows = useMemo((): RowType[] => {
    return (
      data?.blockDeviceMappings?.map(block => {
        return {
          id: block.deviceName,
          deviceId: block?.ebs?.snapshotId ?? '-',
          deviceName: block.deviceName,
          volumnSize: block?.ebs?.volumeSize ?? '-',
          volumnType: block?.ebs?.volumeType.toUpperCase() ?? '-',
          encrypted: capitalize(block?.ebs?.encrypted.toString() ?? '-'),
          deleteOnTerminate: capitalize(block?.ebs?.deleteOnTermination ?? '-'),
          ksmKeyId: block?.ebs?.kmsKeyId ?? '-',
          outpostId: block?.ebs?.outpostArn ?? '-',
        };
      }) ?? []
    );
  }, [data?.blockDeviceMappings]);

  const rootDeviceName = useMemo((): string => {
    return data?.rootDeviceName ?? '';
  }, [data]);

  const rootDeviceType = useMemo((): string => {
    return data?.rootDeviceType.toUpperCase() ?? '';
  }, [data]);

  return (
    <Fragment>
      {apiIsLoading ? (
        <div className="storage-info">
          <div className="progress-container">
            <div className="progress-gif" />
            Loading ...
          </div>
        </div>
      ) : (
        <Fragment>
          <div className="root-device-detail">
            <p className="title">Root device details</p>
            <div className="content-container">
              <div className="first">
                <p>Root device name</p>
                <p>{rootDeviceName}</p>
              </div>
              <div className="second">
                <p>Root device type</p>
                <p>{rootDeviceType}</p>
              </div>
            </div>
          </div>

          <TagTab title={'Block devices'} rows={rows} columns={columns} />
        </Fragment>
      )}
    </Fragment>
  );
};

export default Storage;
