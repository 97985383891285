import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import { OVERSIZE_HANDLING_DATA } from '../../../../constant';

type OversizeHandlingPropsType = {
  oversizeHandling: DropdownListDataType;
  setOversizeHandling: (val: DropdownListDataType) => void;
};

function OversizeHandling({ oversizeHandling, setOversizeHandling }: OversizeHandlingPropsType) {
  return (
    <div className="mw-50">
      <p className="rule-container-group-content-label">Oversize handling</p>

      <p className="rule-container-group-content-description">
        AWS WAF applies oversize handling to web request contents that are larger than AWS WAF can inspect.{' '}

        <a href="#">Learn more {'>'}</a>
      </p>

      <DropdownAtom
        id={(Math.random() + 1).toString(36).substring(2, 8)}
        data={OVERSIZE_HANDLING_DATA}
        placeholder="Choose how to handle web requests that have oversize content."
        value={oversizeHandling}
        handleClick={setOversizeHandling}
      />
    </div>
  );
}

export default OversizeHandling;
