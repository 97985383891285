import { useLazyQuery } from '@apollo/client';
import query from './query';
import { AwsDescribeAvailabilityZonesResponse } from 'graphql/types/AwsAvailabilityZones';

export interface IAwsAvailabilityZonesVariables {
  cloudId: number;
  region: string;
  request: {
    zoneNames?: string[];
    zoneIds?: string[];
    allAvailabilityZones?: boolean;
    dryRun?: boolean;
    filters?: {
      name: string;
      values: string[];
    }
  }
}

export interface IAwsAvailabilityZonesResponseData {
  getAwsAvailabilityZones: AwsDescribeAvailabilityZonesResponse;
}

const lazyGetAwsAvailabilityZones = () => useLazyQuery<IAwsAvailabilityZonesResponseData, IAwsAvailabilityZonesVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsAvailabilityZones;