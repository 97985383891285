import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import './NetworkMapping.scss';
import { AwsLoadBalancer } from 'graphql/types/AwsLoadBalancer';
import lazyGetAwsDescribeLoadBalancerAttributes, {
  IGetAwsDescribeLoadBalancerAttributesVariables,
} from 'graphql/queries/getAwsDescribeLoadBalancerAttributes';
import { AwsLoadBalancerAttribute } from 'graphql/types/AwsDescribeLoadBalancerAttributesResponse';
import DetailTab from '../../DetailTab';
import { IMgdDetailKeyValueProps, TextTypeEnum } from 'layouts/v3/MgdLayout';
import {
  ITextDescription,
  attributeTabAZRoutingConfigKeys,
  attributeTabAZRoutingConfigTextDes,
  attributeTabMonitoringKeys,
  attributeTabMonitoringTextDes,
  attributeTabPackHandlingKeys,
  attributeTabPackHandlingTextDes,
  attributeTabProtectionKeys,
  attributeTabProtectionTextDes,
  attributeTabTrafficConfigKeys,
  attributeTabTrafficConfigTextDes,
} from '../config';
import './Attribute.scss';
import { capitalize } from '../../Image/Common/Functions';

export type AttributePropsType = {
  cloudId: number;
  region: string;
  data?: AwsLoadBalancer;
};

const Attribute = (props: AttributePropsType) => {
  const { data, cloudId, region } = props;
  const [getAwsDescribeLoadBalancerAttributes, { loading: awsDescribeLoadBalancerAttributesLoading }] =
    lazyGetAwsDescribeLoadBalancerAttributes();
  const [loadBalancerAttributes, setLoadBalancerAttributes] = useState<AwsLoadBalancerAttribute[]>([]);

  const apiIsLoading = useMemo(() => {
    return awsDescribeLoadBalancerAttributesLoading;
  }, [awsDescribeLoadBalancerAttributesLoading]);

  const getDataMappingSection = useCallback(
    (
      attributes: AwsLoadBalancerAttribute[],
      keys: string[],
      textDescription: ITextDescription[],
    ): IMgdDetailKeyValueProps[] => {
      const sectionData: IMgdDetailKeyValueProps[] = [];
      const sectionDataOrdered: IMgdDetailKeyValueProps[] = [];
      if (attributes) {
        attributes.map(attribute => {
          Object.entries(attribute).map(([key, value]) => {
            if (keys.indexOf(value) > -1) {
              const textDes = textDescription.find(text => text.id == value);
              let desValue: string | string[] = '-';
              desValue = attribute.value as string;

              if (typeof attribute.value === 'string') {
                if (attribute.value === 'true') {
                  desValue = 'On';
                } else if (attribute.value === 'false') {
                  desValue = 'Off';
                } else if (value === 'idle_timeout.timeout_seconds' || value === 'client_keep_alive.seconds') {
                  desValue = `${attribute.value} seconds`;
                } else {
                  desValue = capitalize(attribute.value as string);
                }
              }

              if (textDes) {
                sectionData.push({
                  id: value,
                  type: textDes.type as TextTypeEnum,
                  title: textDes.value,
                  description: desValue,
                });
              }
            }
          });
        });

        // order data
        keys.map(key => {
          const dataByKey = sectionData.find(data => data.id == key);
          if (dataByKey) sectionDataOrdered.push(dataByKey);
        });
      }
      return sectionDataOrdered;
    },
    [],
  );

  const trafficConfigurationSectionData = useMemo((): IMgdDetailKeyValueProps[] => {
    return getDataMappingSection(
      loadBalancerAttributes,
      attributeTabTrafficConfigKeys,
      attributeTabTrafficConfigTextDes,
    );
  }, [loadBalancerAttributes]);

  const packHandlingSectionData = useMemo((): IMgdDetailKeyValueProps[] => {
    return getDataMappingSection(loadBalancerAttributes, attributeTabPackHandlingKeys, attributeTabPackHandlingTextDes);
  }, [loadBalancerAttributes]);

  const azRoutingConfigSectionData = useMemo((): IMgdDetailKeyValueProps[] => {
    return getDataMappingSection(
      loadBalancerAttributes,
      attributeTabAZRoutingConfigKeys,
      attributeTabAZRoutingConfigTextDes,
    );
  }, [loadBalancerAttributes]);

  const monitoringSectionData = useMemo((): IMgdDetailKeyValueProps[] => {
    return getDataMappingSection(loadBalancerAttributes, attributeTabMonitoringKeys, attributeTabMonitoringTextDes);
  }, [loadBalancerAttributes]);

  const protectionSectionData = useMemo((): IMgdDetailKeyValueProps[] => {
    return getDataMappingSection(loadBalancerAttributes, attributeTabProtectionKeys, attributeTabProtectionTextDes);
  }, [loadBalancerAttributes]);

  const fetchAwsDescribeTags = useCallback(() => {
    const attributeVariable: IGetAwsDescribeLoadBalancerAttributesVariables = {
      cloudId: cloudId,
      region: region,
      request: {
        loadBalancerArn: data?.loadBalancerArn || '',
      },
    };

    getAwsDescribeLoadBalancerAttributes({ variables: attributeVariable }).then(
      ({ data: awsDescribeLoadBalancerAttributes }) => {
        const awsDescribeLoadBalancerAttributeData =
          awsDescribeLoadBalancerAttributes?.getAwsDescribeLoadBalancerAttributes?.data?.[0]?.attributes;
        if (awsDescribeLoadBalancerAttributeData) {
          setLoadBalancerAttributes(awsDescribeLoadBalancerAttributeData);
        }
      },
    );
  }, [cloudId, region, data]);

  useEffect(() => {
    fetchAwsDescribeTags();
  }, []);

  return (
    <Fragment>
      {apiIsLoading ? (
        <div className="attribute-info">
          <div className="progress-container">
            <div className="progress-gif" />
            Loading ...
          </div>
        </div>
      ) : (
        <div className="attribute-info">
          <p className="title">Attributes</p>
          <DetailTab title={'Traffic configuration'} data={trafficConfigurationSectionData} isApiLoading={false} />
          <DetailTab title={'Packet handling'} data={packHandlingSectionData} isApiLoading={false} />
          <div className="azRoutingHandling-section">
            <p className="sub-title">Availability Zone routing configuration</p>
            <div className="content-container">
              <div className="first border-none">
                <p>Cross-zone load balancing</p>
                <p>{azRoutingConfigSectionData[0]?.description}</p>
              </div>
            </div>
          </div>
          <div className="monitoring-section">
            <p className="sub-title">Monitoring</p>
            <div className="content-container">
              <div className="first">
                <p>Access tags</p>
                <p>{monitoringSectionData[0]?.description}</p>
              </div>
              <div className="second">
                <p>Connection logs</p>
                <p>{monitoringSectionData[1]?.description}</p>
              </div>
            </div>
          </div>
          <div className="protection-section">
            <p className="sub-title">Protection</p>
            <div className="content-container">
              <div className="first border-none">
                <p>Deletion protection</p>
                <p>{protectionSectionData[0]?.description}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Attribute;
