import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseData } from '../../types';

export interface IValidateAssumeRoleConnectionVariables {
  reqData: {
    externalId: string;
    roleArn: string;
    accountId: string;
    regionList: string[];
  }
}

export interface IValidateAssumeRoleConnectionResponseData {
  validateAssumeRoleConnection: IGqlResponseData<undefined>;
}

const lazyValidateAssumeRoleConnection = () => useLazyQuery<IValidateAssumeRoleConnectionResponseData, IValidateAssumeRoleConnectionVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyValidateAssumeRoleConnection;