import { useEffect, useState } from 'react';
import PageDetailTitle from '../../PageDetailTitle';
import { UserGroupDetailPropsType } from '../types';
import { userGroupTab } from '../Common/Constant';
import lazyGetAwsGroupIAM from 'graphql/queries/getAwsGroupIAM';
import { variableCombineNextToken } from 'pages/v2/Organ/Management/Utils';
import Summary from '../Common/Components/Summary';
import Users from '../Common/Components/Users';
import AccessAdvisor from '../Common/Components/AccessAdvisor';
import Permissions from '../Common/Components/Permissions';

import './index.scss';

const UserGroupsDetail = (props: UserGroupDetailPropsType) => {
  const { userGroup, pageBackClick, cloudId, region } = props;

  const [isLoading, setIsLoading] = useState(false);

  const [dataSummary, setDataSummary] = useState<any>();
  // @ts-ignore
  const [tabSelected, setTabSelected] = useState(userGroupTab[0]);

  const [getAwsGroupIAM] = lazyGetAwsGroupIAM();

  const handleGetAwsGroupIAM = async (groupName?: string) => {
    if (!groupName) return {};

    const payload = {
      cloudId,
      region,
      request: {
        groupName,
      },
    };

    return getAwsGroupIAM({
      variables: variableCombineNextToken(payload),
    });
  };

  const handleGetDetail = async () => {
    try {
      setIsLoading(true);
      const res: any = await handleGetAwsGroupIAM(userGroup?.groupName);
      setDataSummary(res?.data?.getAwsGroupIAM?.data?.[0]);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setTabSelected(userGroupTab[0]);

    if (!userGroup?.id) return;

    handleGetDetail();
  }, [userGroup?.id]);

  const renderTab = () => {
    switch (tabSelected.id) {
      case userGroupTab[0].id:
        return <Users cloudId={cloudId} region={region} userGroup={userGroup} pageBackClick={() => {}} />;

      case userGroupTab[1].id:
        return <Permissions cloudId={cloudId} region={region} userGroup={userGroup} pageBackClick={() => {}} />;

      case userGroupTab[2].id:
        return <AccessAdvisor cloudId={cloudId} region={region} userGroup={userGroup} pageBackClick={() => {}} />;

      default:
        return null;
    }
  };

  return (
    <div className="page-detail">
      <PageDetailTitle title={userGroup?.groupName} pageBackClick={pageBackClick} />

      {!dataSummary && isLoading ? (
        <div className="detail-info">
          <div className="progress-container">
            <div className="progress-gif" />
            Loading ...
          </div>
        </div>
      ) : (
        <>
          <Summary data={dataSummary} />

          <div className="page-detail-tab">
            <div className="detail-tab flex a-center">
              {userGroupTab.map(tab => {
                return (
                  <button
                    className={`detail-tab-items ${tabSelected?.id === tab.id && 'active'}`}
                    key={tab.id}
                    data-tab={tab.id}
                    onClick={e => setTabSelected(tab)}
                  >
                    <p>{tab.name}</p>
                  </button>
                );
              })}
            </div>

            <div className="content-tab">{renderTab()}</div>
          </div>
        </>
      )}
    </div>
  );
};

export default UserGroupsDetail;
