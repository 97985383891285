import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsDescribeTargetGroupsResponse } from 'graphql/types/AwsDescribeTargetGroup';

export interface IGetAwsDescribeTargetGroupsVariables {
  cloudId: number;
  region: string;
  request: {
    loadBalancerArn?: string;
    targetGroupArns?: string[];
    names?: string[];
    marker?: string;
    pageSize?: number;
  };
}

export interface IAwsDescribeTargetGroupsResponseData {
  getAwsDescribeTargetGroups: IGqlResponseNewData<AwsDescribeTargetGroupsResponse>;
}

const lazyGetAwsDescribeTargetGroups = () =>
  useLazyQuery<IAwsDescribeTargetGroupsResponseData, IGetAwsDescribeTargetGroupsVariables>(query, {
    fetchPolicy: 'no-cache',
  });
export default lazyGetAwsDescribeTargetGroups;
