import { gql } from '@apollo/client';

const query = gql`
mutation updateAwsRegexPatternSet($reqData: AwsUpdateRegexPatternSetRequest, $cloudId: Long, $region: String) {
  updateAwsRegexPatternSet(updateRegexPatternSetRequest: $reqData, cloudId: $cloudId, region: $region) {
    result
    data {
      ... on AwsUpdateRegexPatternSetResponse {
        nextLockToken
      }
    }
    }
  }
`;
export default query;