import { useState, useEffect, useCallback } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import IconEditKmsEncryption from 'assets/svgs/v3/ico_kms_encryption.svg';
import IconSearch from 'assets/svgs/v3/ico_search.svg';
import Icon from 'components/v2/atoms/Icon';
import './index.scss';
import { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import CheckboxAtom from 'components/v2/atoms/CheckboxAtom';
import CopyableText from 'components/v3/CopyableText';
import AutoCompleteDropdownAtom from 'components/v2/atoms/AutoCompleteDropdownAtom';

interface IEditKMSEncryptionProps extends IBaseModalProps {
  header: string;
  onChangeValue?: (index: number, value: string) => void;
}

const dropdownDummyData: DropdownListDataType[] = [
  {id: 0, value: 'Vpc-0a58a948ec4c594ba (nemo-dev-vpc)', description: '10.670.0/15'},
  {id: 1, value: 'Vpc-0a58a948ec4c594bb (nemo-dev-vpc)', description: '10.670.0/15'},
  {id: 2, value: 'Vpc-0a58a948ec4c594bc (nemo-dev-vpc)', description: '10.670.0/15'}
]

const EditKMSEncryptionModal = ({
  header,
  onChangeValue,
  ...baseModalProps
}: IEditKMSEncryptionProps) => {

  const [isEncryptionChecked, setIsEncryptionChecked] = useState<boolean>(false);
  const [isShowDropdown, setIsShowDropdown] =  useState<boolean>(false);
  const [valueSelected, setValueSelected] =  useState<string>('');

  useEffect(() => {
    if (baseModalProps.open) {
      setIsEncryptionChecked(false);
      setIsShowDropdown(false);
      setValueSelected('');
    }
  }, [baseModalProps.open]);

  const isShowKMSKeyDetail = useCallback(() => {
    return !!dropdownDummyData.find((item) => item.value === valueSelected);
  }, [valueSelected]);

  return (
    <BaseModal
      title={() => <><Icon width={32} height={32} src={IconEditKmsEncryption} />{header}</>}
      {...baseModalProps}>
      <div className="kms-encryption-modal">
        <div className="kms-encryption">
          <div className="kms-container">
            <p className="text-description">Your data is encrypted by default with a key that AWS owns and manages for you. To choose a different key, customize your encryption settings.</p>
            <label className="labeled">
              <CheckboxAtom id='checkbox-protection'
                checked={isEncryptionChecked}
                onchange={() => { setIsEncryptionChecked(!isEncryptionChecked) }}/>
              Customize encryption settings
            </label>
          </div>

          {isEncryptionChecked &&
            <>
              <div className="kms-container">
                <p className="text-description">To use the default key, clear this option.</p>
                <p className="text-title">Choose an AWS KMS key</p>
                <p className="text-description">This key will be used for encryption instead of the default key.</p>
                <AutoCompleteDropdownAtom
                  icon={IconSearch}
                  data={dropdownDummyData}
                  placeholder="Choose an AWS KMS key or enter an ARN"
                  handleClick={(val) => { 
                    setValueSelected(val.value as string);
                  }}
                  handleChange={(value: string)=>{
                      setValueSelected(value);
                   }}
                  
                   handleAddClass={(value: boolean) =>{
                    setIsShowDropdown(value);
                   }}
                  
                />
              </div>
           </>
          }

          {isShowKMSKeyDetail() &&
            <>
              <div className="kms-container">
                <p className="text-title">AWS KMS key details</p>
                <div className="kms-key-detail">
                   <p className="text-title">Key ARN</p>
                   <CopyableText text='arn-aws.kms.ap-northeast-2-92jfkdjfjdf0334'/>
                </div>
                <div className="kms-key-detail">
                   <p className="text-title">Key status</p>
                   <p className="text-description">Enabled</p>
                </div>
                <div className="kms-key-detail">
                   <p className="text-title">Key aliases</p>
                   <p className="text-description">key-dev-macie-enc</p>
                </div>
              </div>
           </>
          }

        </div>
        <div className={`button-group ${(isShowDropdown && !isShowKMSKeyDetail())? 'pt-100' : ''}`}>
          <button onClick={baseModalProps.onClose}>Cancel</button>
          <button className="delete-btn" onClick={() => { }}>
            Save
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default EditKMSEncryptionModal;
