import { AwsArnData } from "../../../WAF/Commons/Constant";
import moment from "moment";

export const getStrValueOrNull = (key: string) => {
  return key ? key : '-'
}

export const extractCertificateArnData = (arn:string) => {
  // Split the ARN string
  const arnComponents = arn.split(':');

  // Extract specific parts
  const service = arnComponents[2];
  const region = arnComponents[3];
  const accountId = arnComponents[4];
  const resourceData = arnComponents[5].split('/'); // Split resource type and name

  const resourceType = resourceData[0];
  const resourceId = resourceData[1];

  const arnData: AwsArnData = {
    id: resourceId,
    service: service,
    region: region,
    accountId: accountId,
    resourceType: resourceType
  }
  return arnData;
}

export const extractResourceArnData = (arn:string) => {
  // Split the ARN string
  const arnComponents = arn.split(':');

  // Extract specific parts
  const service = arnComponents[2];
  const region = arnComponents[3];
  const accountId = arnComponents[4];
  const resourceData = arnComponents[5].split('/'); // Split resource type and name

  const resourceType = resourceData[0];
  const resourceId = resourceData[1];

  const arnData: AwsArnData = {
    id: resourceId,
    service: service,
    region: region,
    accountId: accountId,
    resourceType: resourceType
  }
  return arnData;
}

export const getFormatedDateWithTimezone = (dateString?: string, dateTimeFormat?: string) => {
  if(dateString) {
    let string: string = '';
    const date = moment(dateString);
    const isUTC = date.isUTC();
    const fullDateString = date.utcOffset(date.utcOffset()).format();
    const stringTimeZone = fullDateString.substring(fullDateString.length - 6, fullDateString.length);
    const format = dateTimeFormat ? dateTimeFormat : 'MMM DD, YYYY, hh:mm';

    string = `${format} ${stringTimeZone}`;
    const datetime = moment(dateString).format(string);
    const plusIndex = datetime.indexOf('+');
    const fullString = `${datetime.substring(0, plusIndex)} (${isUTC ? 'UTC' : 'GMT'}${datetime.substring(plusIndex, datetime.length)})`;
    return fullString; 
  }
  return '';
}