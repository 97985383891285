import React, {useRef, useState} from 'react';
import NetworkTitle from "components/v3/NetworkTitle";
import './index.scss';
import DropdownAtom, {DropdownListDataType} from "components/v2/atoms/DropdownAtom";
import ClearIcon from "assets/svgs/v2/ico_input_clear.svg";

type valueData = {
  value?: string;
}

type InputFormProps = {
  type?: 'INPUT' | 'DROPDOWN' | 'VALUE';
  field?: string;
  index?: number;
  clearable?: boolean;
  value?: valueData;
  setValue?: (key: string, value: string, index: number) => void;
}
export const InputForm = (props: InputFormProps) => {
  const {type, value, setValue, field, index} = props;

  const inputRef = useRef<HTMLInputElement>(null);

  const handleOnchange = (value: string) => {
    if(setValue) {
      setValue(field ?? '', value, index ?? 0);
    }
  }

  const handleClear = () => {
    handleOnchange('');
    if(inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }

  return (
    <div className='input-form-container'>
      {
        type === 'INPUT' &&
        <input className='input-form-border' onChange={(event) => {setValue && setValue('', event.target.value, 0)}}/>
      }
      {
        type === 'VALUE' &&
        <div className='value-wrapper input-form-border'>
          <input className='value'
                 value={value?.value as string} onChange={(event) => {handleOnchange(event.target.value)}}
                 ref={inputRef}
          />
          <img
            src={ClearIcon}
            width={26}
            height={26}
            style={{cursor: 'pointer'}}
            onClick={() => {handleClear()}}
          />
        </div>
      }
    </div>
  )
}

type noItemProps = {
  handleShowTagsList: () =>void;
}
export const NoItem = (props: noItemProps) => {
  const { handleShowTagsList } = props;

  return (
    <div className='no-item-wrapper'>
      <div className='title' style={{textAlign: 'center', marginBottom: '15px'}}>No item</div>
      <button className='button'
              style={{margin: 0}}
              onClick={() => {handleShowTagsList()}}
      >Add tag</button>
    </div>
  )
}

export type tagType = {
  key: string;
  value: string;
}
type tagsListProps = {
  tags?: tagType[];
  handleRemoveTag?: (index: number) => void;
  handleUpdateTag?: (key: string, value: string, index: number) => void;
}
export const TagsList = (props: tagsListProps) => {
  const { tags, handleRemoveTag, handleUpdateTag } = props;

  const handleUpdateTags = (key: string, value: string, index: number) => {
    if(handleUpdateTag) {
      handleUpdateTag(key, value, index);
    }
  }

  return (
    <div className='tags-list-container'>
      <div className='tags-header'>
        <div className='tags-header-item title'>Key</div>
        <div className='tags-header-item title'>Value-optional</div>
        <div style={{width: 110}}></div>
      </div>

      <div className='tags-body'>
        {
          tags?.map((tag, index) => {
            return (
              <div key={index} className='tags-row'>
                <div className='tags-column'>
                  <InputForm type='VALUE'
                             clearable={true}
                             value={{value: tag.key}}
                             index={index}
                             field='key'
                             setValue={(key: string, value: string, index: number) => {handleUpdateTags(key, value, index)}}
                  />
                </div>
                <div className='tags-column'>
                  <InputForm type='VALUE'
                             clearable={true}
                             value={{value: tag.value}}
                             index={index}
                             field='value'
                             setValue={(key: string, value: string, index: number) => {handleUpdateTags(key, value, index)}}/>
                </div>
                <div style={{width: 110}}>
                  <button className='button' style={{margin: 0, width: '100%'}} onClick={() => {
                    handleRemoveTag && handleRemoveTag(index);
                  }}>Remove tag</button>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

type networkACLCreation = {
  listVpc: DropdownListDataType[];
  pageBackClick: () => void;
}
const NetWorkACLCreation = (props: networkACLCreation) => {
  const {listVpc, pageBackClick} = props;
  const [listTags, setListTags] = useState<tagType[]>([]);
  const [vpc, setVpc] = useState<DropdownListDataType>(listVpc[0]);
  const [isShow, setIsShow] = useState<boolean>(false);
  const [name, setName] = useState<string>('');

  const handleAddTag = () => {
    const newTags = listTags.slice();
    newTags.push({
      key: 'A',
      value: 'B'
    });

    setListTags(newTags);
  }

  const handleRemoveTag = (index: number) => {
    if (index > -1) {
      const newTags = listTags.slice();
      newTags.splice(index, 1);
      setListTags(newTags);
    }
  }

  const handleUpdateTag = (key: string, value: string, index: number) => {
    const newTags = listTags.map((tag, i) => {
      if(index === i) {
        return {
          ...tag,
          [key]: value
        }
      }
      return tag;
    });

    setListTags(newTags);
  }

  const handleShowTagsList = () => {
    setIsShow(true);
  }

  const handleCreate = () => {
    const data = {
      name: name,
      vpc: vpc,
      tags: listTags
    };

    console.log('Create network acl with data: ', data);
  }

  return (
    <div id="mgd-list-layout">
      <div className="row-1 flex j-between a-center">
        <div className="flex j-start a-center" id="title">
          <NetworkTitle pageTitle={'Create network ACL'} id={321} name={'name'} hasPrefixIcon={false}
                        hasFavorite={false} pageBackClick={pageBackClick}/>
        </div>
        <div className="flex a-center" id="action">
          <button className="action-btn" onClick={() => {
          }}>Delete
          </button>
        </div>
      </div>

      <div className="one-page" style={{height: 'calc(100% - 70px)', overflowY: 'auto'}}>
        <div className="row-3 flex j-between a-center">
          <div className="title">
            <p>Network ACL settings</p>
          </div>
        </div>

        <div className="overview-container">
          <div className='nw-acl-screen'>
            <div className='nw-acl-container'>
              <div className='nw-acl-setting-container'>
                <div className='nw-acl-flex'>
                  <div className='title'>Name-optional</div>
                  <InputForm
                    type='INPUT'
                    value={{value: name}}
                    setValue={(key: string, value: string, number: number) => {setName(value)}}
                  />
                </div>
                <div className='nw-acl-flex'>
                  <div className='title'>VPC</div>
                  <div>
                    <DropdownAtom
                      id=""
                      className=""
                      data={listVpc}
                      value={vpc}
                      handleClick={(value) => {
                        setVpc(value);
                      }}/>
                  </div>
                </div>
              </div>

              <div className='nw-acl-tags-container'>
                <div className='tags-title'>Tags</div>
                <div className='tags-body-container'>
                  {
                    isShow ?
                      <TagsList tags={listTags}
                                handleRemoveTag={handleRemoveTag}
                                handleUpdateTag={(key: string, value: string, index: number) => {handleUpdateTag(key, value, index)}}
                      />
                      :
                      <NoItem handleShowTagsList={() => {handleShowTagsList()}}/>
                  }
                  {
                    isShow &&
                    <button className="button add-tag-button" style={{marginLeft: 16, marginBottom: 16}}
                            onClick={() => {
                              handleAddTag();
                            }}>Add tag
                    </button>
                  }
                </div>
              </div>
            </div>

            <div className='nw-acl-container-bottom'>
              <button className="button"
                      style={{margin: 0}}
                      onClick={() => {
                        pageBackClick();
                      }}
              >
                Cancel
              </button>
              <button className="button add-tag-button"
                      style={{marginLeft: 8}}
                      onClick={() => {handleCreate()}}
              >
                Create network ACL
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default NetWorkACLCreation;