import { useLazyQuery } from '@apollo/client';
import query from './query';
import { AwsKeyRotationStatusResponse } from 'graphql/types/AwsKeyRotationStatus';

export interface IAwsKeyRotationStatusVariables {
  cloudId: number;
  region: string;
  request: {
    keyId: string;
  }
}

export interface IAwsKeyRotationStatusResponseData {
  getAwsKeyRotationStatus: AwsKeyRotationStatusResponse;
}

const lazyGetAwsKeyRotationStatus = () => useLazyQuery<IAwsKeyRotationStatusResponseData, IAwsKeyRotationStatusVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsKeyRotationStatus;