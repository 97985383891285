import { gql } from '@apollo/client';

const query = gql`
query getAwsResourceShareAssociations($request: AwsGetResourceShareAssociationsRequest, $cloudId: Long, $region: String) {
  getAwsResourceShareAssociations(
    getResourceShareAssociationsRequest: $request
    cloudId: $cloudId
    region: $region
  ) {
    result
    data {
      ... on AwsGetResourceShareAssociationsResponse {
        resourceShareAssociations {
          resourceShareArn
          resourceShareName
          associatedEntity
          associationType
          status
          statusMessage
          creationTime
          lastUpdatedTime
          external
        }
        nextToken
      }
    }
  }
}
`;
export default query;