import { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import MgdLayout, {
  DetailTypeEnum,
  IMgdDetailContentProps,
  IMgdDetailKeyValueProps,
  IMgdTablePaginationProps,
  IMgdTabProps,
  IMgdTotalPageProps,
  MgdLayoutTypeEnum,
  TextTypeEnum,
} from 'layouts/v3/MgdLayout';
import CopyableText from 'components/v3/CopyableText';
import { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { lazyGetEnumTypeCode } from 'graphql/queries/getEnumTypeCode';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import {
  awsKeyFilterDropdown,
  customerKeyFilterDropdown,
  detailAwsKey,
  detailAwsKeyTextDes,
  detailCustomerKeyTabs,
  detailCustomerKeyTextDes,
  detailSecretKeys,
  detailSecretTabs,
  detailSecretTextDes,
  mainTabs,
  secretFilterDropdown,
  STAGING_LABLE_MAPPING,
} from './Common/Constant';
import lazyGetAwsAllListAliases from 'graphql/queries/getAwsAllListAliases';
import lazyGetAwsDescribeKey, { IAwsDescribeKeyVariables } from 'graphql/queries/getAwsDescribeKey';
import lazyGetAwsAllListSecrets from 'graphql/queries/getAwsAllListSecrets';
import lazyGetAwsDescribeSecret from 'graphql/queries/getAwsDescribeSecret';
import lazyGetAwsListAliases, { IAwsListAliasesVariables } from 'graphql/queries/getAwsListAliases';
import { getFormatedDateWithTimezone } from '../Firewall/Common/Function';
import lazyGetAwsKeyPolicy from 'graphql/queries/getAwsKeyPolicy';
import lazyGetAwsListResourceTags from 'graphql/queries/getAwsListResourceTags';
import lazyGetAwsKeyRotationStatus from 'graphql/queries/getAwsKeyRotationStatus';
import lazyGetAwsListKeyRotations from 'graphql/queries/getAwsListKeyRotations';
import lazyGetAwsListSecretVersionIds from 'graphql/queries/getAwsListSecretVersionIds';
import lazyGetAwsPublicKey from 'graphql/queries/getAwsPublicKey';
import { AwsListAliasesResponse } from 'graphql/types/AwsListAliases';
import { useOutletContext } from 'react-router-dom';
import { orderAlphabetical } from '../Utils/Sorting';
import { variableCombineNextToken } from '../Utils';
import { handleFormatText } from 'utils/Common';
import { FilterPropsType } from '../EC2/components/Image/types';

interface IKeyAndSecretProps {
  projectId: number;
  cloudName: string;
  regionCode: string;
  regions: string[];
  pageBackClick: () => void;
  recentRelatedClouds: DropdownListDataType[];
  relatedCloudSelected: DropdownListDataType;
  recentRelatedCloudOnChange: (option: DropdownListDataType) => void;
}

const KeyAndSecret = ({
  projectId,
  cloudName,
  regionCode,
  regions,
  pageBackClick,
  recentRelatedClouds,
  relatedCloudSelected,
  recentRelatedCloudOnChange,
}: IKeyAndSecretProps) => {
  const { defaultRegionList, updateDefaultRegionList } = useOutletContext<{
    defaultRegionList: { projectId: number; cloudId: number; name: string; value: string }[];
    updateDefaultRegionList: (projectId: number, cloudId: number, name: string, value: string) => void;
  }>();

  const [isShowing, setIsShowing] = useState<MgdLayoutTypeEnum.MGD_LIST_SUMMARY | MgdLayoutTypeEnum.MGD_DETAIL>(
    MgdLayoutTypeEnum.MGD_LIST_SUMMARY,
  );
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [layoutType, setLayoutType] = useState<MgdLayoutTypeEnum>(MgdLayoutTypeEnum.MGD_LIST_SUMMARY);
  const [regionList, setRegionList] = useState<DropdownListDataType[]>([]);
  const [selectedRegion, setSelectedRegion] = useState<DropdownListDataType>({ name: '', value: '' });

  const [mainNextToken, setMainNextToken] = useState<string>('');
  const [mainSelected, setMainSelected] = useState<string>('');
  const [mainTabSelected, setMainTabSelected] = useState<IMgdTabProps>(mainTabs[0]);
  const [mainSearchDropdown, setMainSearchDropdown] = useState<DropdownListDataType[]>(awsKeyFilterDropdown);
  const [finalMainSearchValue, setFinalMainSearchValue] = useState<FilterPropsType>();
  const [mainFilterValue, setMainFilterValue] = useState<DropdownListDataType>(mainSearchDropdown[0]);
  const [mainSearchValue, setMainSearchValue] = useState<FilterPropsType>({ name: mainFilterValue.value.toString(), value: '' });
  const [mainTblRows, setMainTblRows] = useState<RowType[]>([]);
  const [searchData, setSearchData] = useState<RowType[]>([]);
  const [mainTblColumns, setMainTblColumns] = useState<ColumnType[]>([]);
  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({ totalPage: 0, totalElement: 0 });
  const [mainTablePagination, setMainTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });
  const [awsKeyPageData, setAwsKeyPageData] = useState<RowType[]>([]);
  const [customerKeyPageData, setCustomerKeyPageData] = useState<RowType[]>([]);
  const [secretPageData, setSecretPageData] = useState<RowType[]>([]);
  const [detailsData, setDetailsData] = useState<IMgdDetailKeyValueProps[]>();
  const [detailTitle, setDetailTitle] = useState<string>('');
  const [noPermissionMessage, setNoPermissionMessage] = useState<string>('');
  const [detailTabList, setDetailTabList] = useState<IMgdTabProps[]>([]);
  const [detailTabSelected, setDetailTabSelected] = useState<IMgdTabProps>();
  const [detailContents, setDetailContents] = useState<IMgdDetailContentProps[]>([]);
  const [detailsExtraData, setDetailsExtraData] = useState<IMgdDetailContentProps[]>([]);

  const [getAwsAllListSecrets] = lazyGetAwsAllListSecrets();
  const [getAwsDescribeSecret] = lazyGetAwsDescribeSecret();
  const [getAwsListAliases] = lazyGetAwsListAliases();
  const [getAwsListSecretVersionIds] = lazyGetAwsListSecretVersionIds();

  const [getEnumTypeCode] = lazyGetEnumTypeCode();
  const [getAwsAllListAliasesPage] = lazyGetAwsAllListAliases();
  const [getAwsDescribeKey] = lazyGetAwsDescribeKey();
  const [getAwsKeyPolicy] = lazyGetAwsKeyPolicy();
  const [getAwsKeyRotationStatus] = lazyGetAwsKeyRotationStatus();
  const [getAwsListResourceTags] = lazyGetAwsListResourceTags();
  const [getAwsListKeyRotations] = lazyGetAwsListKeyRotations();
  const [getAwsPublicKey] = lazyGetAwsPublicKey();
  const [mainApiIsLoading, setMainApiIsLoading] = useState<boolean>(false);
  const [detailApiIsLoading, setDetailApiIsLoading] = useState<boolean>(false);

  const pinButtonClickHandle = useCallback(() => {
    updateDefaultRegionList(
      projectId,
      Number(relatedCloudSelected.value),
      selectedRegion.name || '',
      selectedRegion.value.toString(),
    );
  }, [projectId, relatedCloudSelected, selectedRegion]);

  useEffect(() => {
    const hasDefaultRegion = defaultRegionList.find(r => {
      return r.projectId === projectId && r.cloudId === relatedCloudSelected.value;
    });
    if (!!hasDefaultRegion) {
      setSelectedRegion({
        name: hasDefaultRegion.name,
        value: hasDefaultRegion.value,
      });
    } else {
      getEnumTypeCode({
        variables: { text: regionCode || 'AwsRegionCode' },
      }).then(res => {
        if (res.data) {
          const regionsRes: DropdownListDataType[] =
            res.data.getEnumTypeCode.data.filter(val =>
              regions.includes(val.value),
            ).map((val) => ({
              ...val,
              name: val.value
            }));
          setRegionList(regionsRes);
          const currentRegion = regionsRes.find(
            r => r.name === selectedRegion.name && r.value === selectedRegion.value,
          );
          setSelectedRegion(currentRegion || regionsRes[0]);
        }
      });
    }
  }, [defaultRegionList, relatedCloudSelected]);

  const resetDetailStates = () => {
    setMainSelected('');
    setDetailContents([]);
    setDetailsExtraData([]);
  };

  const resetMainStates = useCallback(() => {
    setAwsKeyPageData([]);
    setCustomerKeyPageData([]);
    setSecretPageData([]);
  }, []);

  const mainTabClickHandler = useCallback(
    (tabSelected: IMgdTabProps) => {
      setMainTabSelected(mainTabs.find(tabItem => tabItem.id === tabSelected.id) || mainTabs[0]);

      setMainSelected('');
      setMainSearchValue({ name: '', value: '' });

      resetMainStates();

      switch (tabSelected) {
        case mainTabs[0]:
          getAwsKeyMainDataRows();
          break;
        case mainTabs[1]:
          getCustomerKeyMainDataRows();
          break;
        case mainTabs[2]:
          getSecretMainDataRows();
          break;
        default:
          break;
      }
    },
    [selectedRegion],
  );

  useEffect(() => {
    if (mainTabSelected === mainTabs[2]) {
      getSecretMainDataRows();
    }
  }, [mainTabSelected, finalMainSearchValue]);

  const onSearchKeyDownHandler = useCallback((e: any) => {
    if (e.key === 'Enter' && mainSearchValue) {
      if (mainSearchValue.value != '') {
        setFinalMainSearchValue({ name: mainSearchValue.name, value: mainSearchValue.value });
        const rearchResult = searchData.filter(row => row?.[mainSearchValue.name]?.includes(mainSearchValue.value));
        setMainTblRows(rearchResult);
        setMainTblTotal({
          totalPage: Math.ceil(rearchResult.length / mainTablePagination.itemPerPage),
          totalElement: rearchResult.length,
        });
      } else {
        setFinalMainSearchValue(undefined);
        setMainTblRows(searchData);
        setMainTblTotal({
          totalPage: Math.ceil(searchData.length / mainTablePagination.itemPerPage),
          totalElement: searchData.length,
        });
      }
    }
  }, [mainSearchValue, mainTabSelected]);

  const handleHDetailHyperlinkMainClick = (currTab: IMgdTabProps, data: any) => {
    switch (currTab) {
      case mainTabs[0]:
        break;

      default:
        break;
    }
  };

  const getVariableData = useCallback(
    (requestType: string) => {

      if (requestType == mainTabs[0].id) {
        return {
          cloudId: relatedCloudSelected.value,
          region: selectedRegion.value as string,
          request: {
            pageInfo: {
              page: mainTablePagination.currentPage - 1,
              size: mainTablePagination.limit,
              orders: [
                {
                  target: mainTablePagination.target === 'modifiedAt' ? 'lastUpdatedDate' : mainTablePagination.target,
                  direction: mainTablePagination.direction,
                },
              ],
            },
          },
        };
      }

      if (requestType == mainTabs[1].id) {
        return {
          cloudId: relatedCloudSelected.value,
          region: selectedRegion.value as string,
          request: {
            pageInfo: {
              page: mainTablePagination.currentPage - 1,
              size: mainTablePagination.limit,
              orders: [
                {
                  target: mainTablePagination.target === 'modifiedAt' ? 'lastUpdatedDate' : mainTablePagination.target,
                  direction: mainTablePagination.direction,
                },
              ],
            },
          },
        };
      }

      if (requestType == mainTabs[2].id) {
        if (!!finalMainSearchValue) {
          return {
            cloudId: relatedCloudSelected.value,
            region: selectedRegion.value as string,
            request: {
              pageInfo: {
                page: mainTablePagination.currentPage - 1,
                size: mainTablePagination.limit,
                orders: [
                  {
                    target: mainTablePagination.target === 'name' ? 'name' : mainTablePagination.target,
                    direction: mainTablePagination.direction,
                  },
                ],
              },
              filters: {
                name: finalMainSearchValue?.name || '',
                values: [finalMainSearchValue?.value || ''],
              },
            },
          };
        }

        return {
          cloudId: relatedCloudSelected.value,
          region: selectedRegion.value as string,
          request: {
            pageInfo: {
              page: mainTablePagination.currentPage - 1,
              size: mainTablePagination.limit,
              orders: [
                {
                  target: mainTablePagination.target === 'name' ? 'name' : mainTablePagination.target,
                  direction: mainTablePagination.direction,
                },
              ],
            },
          },
        };
      }

      return {
        cloudId: relatedCloudSelected.value,
        region: selectedRegion.value as string,
        request: {
          maxResults: 100,
        },
      };
    },
    [selectedRegion, mainTablePagination, finalMainSearchValue, relatedCloudSelected],
  );

  const resetDetailData = () => {
    setDetailContents([]);
    setDetailsExtraData([]);
  };

  useEffect(() => {
    if (checkedList.length > 0) {
      setMainSelected(checkedList[checkedList.length - 1]);
    } else {
      setMainSelected('');
    }
  }, [checkedList]);

  useEffect(() => {
    if (isShowing) {
      getEnumTypeCode({ variables: { text: regionCode || 'AwsRegionCode' } }).then(res => {
        if (res.data) {
          const regionsRes: DropdownListDataType[] = res.data.getEnumTypeCode.data.filter(val =>
            regions.includes(val.value),
          ).map((val) => ({
            ...val,
            name: val.value
          }));
          setRegionList(regionsRes);
          setSelectedRegion(regionsRes[0]);
        }
      });
    }
  }, [isShowing]);

  // INIT LOADING
  useEffect(() => {
    getEnumTypeCode({ variables: { text: regionCode || 'AwsRegionCode' } }).then(res => {
      if (res.data) {
        const regionsRes: DropdownListDataType[] = res.data.getEnumTypeCode.data.filter(val =>
          regions.includes(val.value),
        ).map((val) => ({
          ...val,
          name: val.value
        }));
        setRegionList(regionsRes);
        setSelectedRegion(regionsRes[0]);
      }
    });
  }, []);

  // this effect for getting data for list-up
  useEffect(() => {
    let summaryTabs: IMgdTabProps[] = [];
    let searchDropdown: DropdownListDataType[] = [];
    let mainFilterValue: DropdownListDataType = { value: '' };
    setMainTablePagination({ ...mainTablePagination, currentPage: 1 });
    switch (mainTabSelected) {
      case mainTabs[0]: // Aws key tab
        summaryTabs = detailAwsKey;
        searchDropdown = awsKeyFilterDropdown;
        mainFilterValue = awsKeyFilterDropdown[0];
        getAwsKeyMainDataRows();
        getAwsKeyMainDataColumns();
        break;
      case mainTabs[1]: // Customer key tab
        summaryTabs = detailCustomerKeyTabs;
        searchDropdown = customerKeyFilterDropdown;
        mainFilterValue = customerKeyFilterDropdown[0];
        getCustomerKeyMainDataRows();
        getCustomerKeyMainDataColumns();
        break;
      case mainTabs[2]: // Secret tab
        summaryTabs = detailSecretTabs;
        searchDropdown = secretFilterDropdown;
        mainFilterValue = secretFilterDropdown[0];
        getSecretMainDataRows();
        getSecretMainDataColumns();
        break;
      default:
        break;
    }
    setMainSearchDropdown(searchDropdown);
    setMainFilterValue(mainFilterValue);
    setMainSearchValue(prev => ({ value: prev.value, name: mainFilterValue.value.toString() }));
    setDetailTabList(summaryTabs);
  }, [mainTabSelected, selectedRegion]);

  useEffect(() => {
    let selectedTab = { id: '', name: '', title: '' };
    resetDetailData();
    switch (mainTabSelected) {
      case mainTabs[0]:
        selectedTab = detailAwsKey[1];
        break;
      case mainTabs[1]:
        selectedTab = detailCustomerKeyTabs[1];
        break;
      case mainTabs[2]:
        selectedTab = detailSecretTabs[1];
        break;
      default:
        break;
    }
    setDetailTabSelected(selectedTab);
  }, [mainTabSelected]);

  useEffect(() => {
    if (mainSelected) {
      switch (mainTabSelected) {
        case mainTabs[0]:
          getAwsKeyDetailData();
          break;
        case mainTabs[1]:
          setNoPermissionMessage('');
          getCustomerKeyDetailData();
          break;
        case mainTabs[2]:
          getSecretDetailData();
          break;
        default:
          resetDetailData();
          break;
      }
    }
  }, [mainSelected, detailTabSelected]);

  const getAwsKeyMainDataRows = useCallback(
    async (nextToken?: string) => {
      if (selectedRegion.value) {
        setMainApiIsLoading(true);
        const variable = variableCombineNextToken(getVariableData(mainTabs[0].id), nextToken);
        const data = await getAwsAllListAliasesPage({ variables: variable }).then(({ data: listAliases }) => {
          return listAliases;
        });

        const listAliases = data?.getAwsAllListAliases?.data ?? [];
        const rows: RowType[] = [];
        if (listAliases.length > 0) {
          for (const aliases of listAliases) {
            if (aliases.aliasName.startsWith('alias/aws/') && aliases.targetKeyId) {
              const variable = {
                cloudId: Number(relatedCloudSelected.value),
                region: selectedRegion.value as string,
                request: {
                  keyId: aliases.targetKeyId,
                },
              };
              const keyData = await getAwsDescribeKey({ variables: variable }).then(({ data: keyData }) => {
                return keyData;
              });
  
              rows.push({
                id: aliases.targetKeyId,
                name: aliases.aliasName,
                aliases: aliases.aliasName,
                key: keyData?.getAwsDescribeKey.data[0]?.keyMetadata?.keyId ?? '-',
                status: keyData?.getAwsDescribeKey.data[0]?.keyMetadata?.keyState ?? '-',
              });
            }
          }
          let allAwsKeyPageData: RowType[] = [];
          if (nextToken) {
            allAwsKeyPageData = [...awsKeyPageData, ...rows];
          } else {
            allAwsKeyPageData = rows;
          }
          setAwsKeyPageData(allAwsKeyPageData);
          setMainNextToken(data?.getAwsAllListAliases?.nextToken as string);
          setMainTblRows(allAwsKeyPageData);
          setSearchData(allAwsKeyPageData);
          setMainTblTotal({
            totalPage: Math.ceil(allAwsKeyPageData.length / mainTablePagination.itemPerPage),
            totalElement: allAwsKeyPageData.length,
          });
        } else {
          setAwsKeyPageData([]);
          setMainNextToken('')
          setMainTblRows([]);
          setSearchData([]);
        }
        setMainApiIsLoading(false);
      }
    },
    [selectedRegion, awsKeyPageData, mainTablePagination, getVariableData],
  );

  const getCustomerKeyMainDataRows = useCallback(
    async (nextToken?: string) => {
      if (selectedRegion.value) {
        setMainApiIsLoading(true);
        const variable = variableCombineNextToken(getVariableData(mainTabs[1].id), nextToken);
        const data = await getAwsAllListAliasesPage({ variables: variable }).then(({ data: listAliases }) => {
          return listAliases;
        });

        const listAliases = data?.getAwsAllListAliases?.data ?? [];
        const rows: RowType[] = [];
        if (listAliases.length > 0) {
          for (const aliases of listAliases) {
            if (!aliases.aliasName.startsWith('alias/aws/') && aliases.targetKeyId) {
              const variable = {
                cloudId: Number(relatedCloudSelected.value),
                region: selectedRegion.value as string,
                request: { keyId: aliases.targetKeyId },
              };
              const keyData = await getAwsDescribeKey({ variables: variable }).then(({ data: keyData }) => {
                return keyData?.getAwsDescribeKey?.data?.[0];
              });

              rows.push({
                id: aliases.targetKeyId,
                name: aliases.aliasName.substring(6),
                keyId: aliases.targetKeyId,
                status: keyData?.keyMetadata?.keyState || 'Not authorized',
                keyType: keyData?.keyMetadata?.keySpec
                  ? keyData?.keyMetadata?.keySpec.startsWith('RSA_')
                    ? 'Asymmetric'
                    : 'Symmetric'
                  : 'Not authorized',
                keySpec: keyData?.keyMetadata?.keySpec || 'Not authorized',
                keyUsage: keyData?.keyMetadata?.keyUsage || 'Not authorized',
                isMultiRegion: keyData?.keyMetadata?.multiRegion,
              });
            }
          }

          // Count duplicate keyId and merge data
          const itemMap = new Map<string, RowType>();
          rows.forEach(item => {
            if (itemMap.has(item.keyId)) {
              const existingItem = itemMap.get(item.keyId)!;
              existingItem.count!++;
            } else {
              itemMap.set(item.keyId, { ...item, count: 0 });
            }
          });

          const finalAwsRouteTables = [...customerKeyPageData, ...Array.from(itemMap.values())];
          let allAwsRouteTables: RowType[] = [];
          if (nextToken) {
            allAwsRouteTables = [...customerKeyPageData, ...finalAwsRouteTables];
          } else {
            allAwsRouteTables = finalAwsRouteTables;
          }
          setCustomerKeyPageData(allAwsRouteTables);
          setMainTblRows(allAwsRouteTables);
          setSearchData(allAwsRouteTables);
          setMainNextToken(data?.getAwsAllListAliases?.nextToken as string);
          setMainTblTotal({
            totalPage: Math.ceil(allAwsRouteTables.length / mainTablePagination.itemPerPage),
            totalElement: allAwsRouteTables.length,
          });
        } else {
          setCustomerKeyPageData([]);
          setMainNextToken('');
          setMainTblRows([]);
          setSearchData([]);
        }
        setMainApiIsLoading(false);
      }
    },
    [selectedRegion, customerKeyPageData, mainTablePagination, getVariableData],
  );

  const getSecretMainDataRows = useCallback(
    (nextToken?: string) => {
      setMainApiIsLoading(true);
      getAwsAllListSecrets({
        variables: variableCombineNextToken(getVariableData(mainTabs[2].id), nextToken),
      }).then(({ data: res }) => {
        const awsAllListSecrets = res?.getAwsAllListSecrets?.data;
        const awsSecretRowData: RowType[] = [];
        if (awsAllListSecrets) {
          awsAllListSecrets?.map(content => {
            const lastAccesseFormated = getFormatedDateWithTimezone(content?.lastAccessedDate);
            awsSecretRowData.push({
              ...content,
              lastAccessedDate: lastAccesseFormated,
              id: content?.arn,
            });
          });
          let allAwsRouteTables: RowType[] = [];
          if (nextToken) {
            allAwsRouteTables = [...secretPageData, ...awsSecretRowData];
          } else {
            allAwsRouteTables = awsSecretRowData;
          }
          setSecretPageData(allAwsRouteTables);
          setMainNextToken(res?.getAwsAllListSecrets?.nextToken as string);
          setMainTblRows(allAwsRouteTables);
          setMainTblTotal({
            totalPage: Math.ceil(allAwsRouteTables.length / mainTablePagination.itemPerPage),
            totalElement: allAwsRouteTables.length,
          });
        } else {
          setSecretPageData([]);
          setMainNextToken('');
          setMainTblRows([]);
        }
        
        setMainApiIsLoading(false);
      });
    },
    [selectedRegion, secretPageData, mainTablePagination, getVariableData],
  );

  const getAwsKeyMainDataColumns = () => {
    let mainColumns: ColumnType[] = [];
    mainColumns = [
      {
        label: 'Aliases',
        field: 'aliases',
        sort: true,
        renderCell: row => (
          <p
            className="link"
            onClick={() => {
              setMainSelected(row.id as string);
              setLayoutType(MgdLayoutTypeEnum.MGD_DETAIL);
              setDetailTabSelected(detailAwsKey[1]);
            }}
          >
            {handleFormatText(row.aliases)}
          </p>
        ),
      },
      {
        label: 'Key',
        field: 'key',
        sort: true,
        renderCell: row =>
          row.key !== '-' ? (
            <p
              className="link"
              onClick={() => {
                setLayoutType(MgdLayoutTypeEnum.MGD_DETAIL);
              }}
            >
              {handleFormatText(row.key)}
            </p>
          ) : (
            <p>{handleFormatText(row.key)}</p>
          ),
      },
      {
        label: 'Status',
        field: 'status',
        sort: true,
        renderCell: row => (
          <p className="status" onClick={() => {}}>
            {row.status}
          </p>
        ),
      },
    ];
    setMainTblColumns(mainColumns);
  };

  const getCustomerKeyMainDataColumns = () => {
    let mainColumns: ColumnType[] = [];
    mainColumns = [
      {
        label: 'Aliases',
        field: 'name',
        sort: true,
        renderCell: row => {
          return (
            <div style={{ display: 'flex' }}>
              <p
                className="link"
                onClick={() => {
                  setMainSelected(row.id as string);
                  setLayoutType(MgdLayoutTypeEnum.MGD_DETAIL);
                  const tabList = detailCustomerKeyTabs.map(t => {
                    if (t.id === 'keyRotation') {
                      if (row.keyType === 'Asymmetric') {
                        t.name = 'Public key';
                        t.title = 'Public key';
                      } else {
                        t.name = 'Key rotation';
                        t.title = 'Key rotation';
                      }
                    }
                    return t;
                  });
                  if (!row.isMultiRegion) {
                    tabList.splice(5, 1);
                  }
                  setDetailTabList(tabList);
                  setDetailTabSelected(detailCustomerKeyTabs[1]);
                }}
              >
                {handleFormatText(row.name)}
              </p>
              {row.count > 0 && (
                <>
                  &nbsp;(
                  <p
                    className="link"
                    onClick={() => {
                      setMainSelected(row.id as string);
                      setLayoutType(MgdLayoutTypeEnum.MGD_DETAIL);
                      const tabList = detailCustomerKeyTabs.map(t => {
                        if (t.id === 'keyRotation') {
                          if (row.keyType === 'Asymmetric') {
                            t.name = 'Public key';
                            t.title = 'Public key';
                          } else {
                            t.name = 'Key rotation';
                            t.title = 'Key rotation';
                          }
                        }
                        return t;
                      });
                      if (!row.isMultiRegion) {
                        tabList.splice(5, 1);
                      }
                      setDetailTabList(tabList);
                      setDetailTabSelected(detailCustomerKeyTabs[5]);
                    }}
                  >
                    +{row.count} more
                  </p>
                  )
                </>
              )}
            </div>
          );
        },
      },
      {
        label: 'Key ID',
        field: 'keyId',
        sort: true,
        renderCell: row => (
          <p className="link" onClick={() => {}}>
            {handleFormatText(row.keyId)}
          </p>
        ),
      },
      { label: 'Status', field: 'status', sort: true },
      { label: 'Key type', field: 'keyType', sort: true },
      { label: 'Key spec', field: 'keySpec', sort: true },
      { label: 'Key usage', field: 'keyUsage', sort: true },
    ];

    setMainTblColumns(mainColumns);
  };

  const getSecretMainDataColumns = () => {
    const mainColumns: ColumnType[] = [
      {
        label: 'Secret name',
        field: 'name',
        sort: true,
        renderCell: (row: RowType) => (
          <>
            {
              <span
                className="link"
                onClick={() => {
                  setMainSelected(row.id as string);
                  setLayoutType(MgdLayoutTypeEnum.MGD_DETAIL);
                  setDetailTabSelected(detailSecretTabs[1]);
                }}
              >
                {handleFormatText(row?.name)}
              </span>
            }
          </>
        ),
      },
      { label: 'Description', field: 'description', sort: true },
      { label: 'Last retrieved (UTC)', field: 'lastAccessedDate', sort: true },
    ];
    setMainTblColumns(mainColumns);
  };

  const getAwsKeyDetailData = async () => {
    setDetailApiIsLoading(true);
    const detailData = mainTblRows.find(item => item.id === mainSelected);
    const data: IMgdDetailKeyValueProps[] = [];

    const awsKeyVariable: IAwsDescribeKeyVariables = {
      cloudId: Number(relatedCloudSelected.value),
      region: selectedRegion.value as string,
      request: {
        keyId: detailData?.key ?? '',
      },
    };
    const awsKeyMetaData = await getAwsDescribeKey({ variables: awsKeyVariable }).then(data => {
      return data.data?.getAwsDescribeKey.data[0].keyMetadata;
    });

    const listAliasesVariable: IAwsListAliasesVariables = {
      cloudId: Number(relatedCloudSelected.value),
      region: selectedRegion.value as string,
      request: {
        keyId: detailData?.key ?? '',
      },
    };
    const alias = await getAwsListAliases({ variables: listAliasesVariable }).then(data => {
      return data.data?.getAwsListAliases.data[0].aliases[0];
    });
    const dataCombined: { [key: string]: string } = {
      alias: alias?.aliasName ?? '-',
      status: awsKeyMetaData?.keyState ?? '-',
      creationDate: awsKeyMetaData?.creationDate ? getFormatedDateWithTimezone(awsKeyMetaData?.creationDate) : '-',
      arn: awsKeyMetaData?.arn ?? '-',
      description: awsKeyMetaData?.description ?? '-',
      regionality: awsKeyMetaData?.multiRegion
        ? `Multi-Region ${awsKeyMetaData.multiRegionConfiguration.multiRegionKeyType}`
        : 'Single region',
    };
    detailAwsKeyTextDes.forEach((item, index) => {
      data.push({
        id: item.id,
        title: item.value,
        type: item.type,
        description: dataCombined[item.id],
      });
    });
    setDetailsData(data);

    switch (detailTabSelected) {
      case detailAwsKey[1]:
        const keyPolicyVariable = {
          cloudId: Number(relatedCloudSelected.value),
          region: selectedRegion.value as string,
          request: {
            keyId: detailData?.key ?? '',
          },
        };
        const keyPolicyData = await getAwsKeyPolicy({ variables: keyPolicyVariable }).then(data => {
          return data.data?.getAwsKeyPolicy.data[0].policy;
        });
        const keyPolicy: IMgdDetailContentProps[] = [];
        keyPolicy.push({
          type: DetailTypeEnum.JSONVIEWER,
          title: 'Key Policy',
          jsonViewerData: JSON.parse(keyPolicyData ?? ''),
        });
        setDetailsExtraData(keyPolicy);
        setDetailApiIsLoading(false);
        break;
      case detailAwsKey[2]:
        const cryptographicConfiguration: IMgdDetailContentProps[] = [];
        cryptographicConfiguration.push({
          type: DetailTypeEnum.MULTICOLUMN,
          contents: [
            {
              title: 'Cryptographic configuration',
              contents: [
                {
                  title: 'Key type',
                  description: awsKeyMetaData?.keySpec.startsWith('SYMMETRIC_') ? 'Symmetric' : 'Asymmetric',
                  type: TextTypeEnum.NORMAL,
                },
                {
                  title: 'Origin',
                  description: awsKeyMetaData?.origin ?? '-',
                  type: TextTypeEnum.NORMAL,
                },
                {
                  title: 'Key Spec',
                  description: awsKeyMetaData?.keySpec ?? '-',
                  type: TextTypeEnum.NORMAL,
                },
                {
                  title: 'Key Usage',
                  description: awsKeyMetaData?.keyUsage,
                  type: TextTypeEnum.NORMAL,
                },
              ],
            },
          ],
        });
        setDetailsExtraData(cryptographicConfiguration);
        setDetailApiIsLoading(false);
        break;
      default:
        setDetailApiIsLoading(false);
        break;
    }
  };

  const getCustomerKeyDetailData = async () => {
    setDetailApiIsLoading(true);
    const detailData = mainTblRows.find(mainData => mainData.id === mainSelected);
    if (detailData) {
      const variables = {
        cloudId: Number(relatedCloudSelected.value),
        region: selectedRegion.value as string,
        request: { keyId: detailData.keyId },
      };
      const keyData = await getAwsDescribeKey({ variables: variables }).then(({ data: keyData }) => {
        return keyData?.getAwsDescribeKey?.data?.[0];
      });
      const aliasData = await getAwsListAliases({ variables: variables }).then(({ data: alias }) => {
        return alias?.getAwsListAliases?.data?.[0];
      });
      if (keyData) {
        const detailsOrderedData: IMgdDetailKeyValueProps[] = [];
        const formatedData = detailCustomerKeyTextDes.map(item => {
          let description = '';
          if (item.id === 'name') {
            description = aliasData?.aliases?.[0]?.aliasName || '-';
          } else if (item.id === 'status') {
            description = keyData.keyMetadata?.keyState || '-';
          } else if (item.id === 'createDate') {
            description = keyData.keyMetadata?.creationDate || '-';
          } else if (item.id === 'arn') {
            description = keyData.keyMetadata?.arn || '-';
          } else if (item.id === 'description') {
            description = keyData.keyMetadata?.description || '-';
          } else {
            description = keyData.keyMetadata?.multiRegion
              ? `Multi Region ${keyData.keyMetadata?.multiRegionConfiguration.multiRegionKeyType}`
              : 'Single Region';
          }
          return {
            id: item.id,
            type: item.type as TextTypeEnum,
            description: description,
            title: item.value,
          };
        });
        detailsOrderedData.push(...formatedData);
        setDetailsData(detailsOrderedData);

        switch (detailTabSelected) {
          case detailCustomerKeyTabs[1]:
            //Key policy
            const keyPolicy = await getAwsKeyPolicy({ variables: variables }).then(({ data: awsKeyPolicy }) => {
              return awsKeyPolicy?.getAwsKeyPolicy?.data?.[0]?.policy;
            });
            const keyPolicyContent: IMgdDetailContentProps[] = [];
            keyPolicyContent.push({
              type: DetailTypeEnum.JSONVIEWER,
              title: 'Key Policy',
              jsonViewerData: JSON.parse(keyPolicy || ''),
            });
            setDetailsExtraData(keyPolicyContent);
            setDetailApiIsLoading(false);
            break;
          case detailCustomerKeyTabs[2]:
            //Cryptographic configuration
            const cryptConfigContent: IMgdDetailContentProps[] = [];
            const cryptConfigContentData: IMgdDetailKeyValueProps[] = [
              {
                id: 'keyType',
                type: TextTypeEnum.NORMAL,
                title: 'Key type',
                description:
                  keyData?.keyMetadata?.keySpec && !keyData?.keyMetadata?.keySpec.startsWith('RSA_')
                    ? 'Symmetric'
                    : 'Asymmetric',
              },
              {
                id: 'origin',
                type: TextTypeEnum.NORMAL,
                title: 'Origin',
                description: keyData?.keyMetadata.origin || '-',
              },
              {
                id: 'keySpec',
                type: TextTypeEnum.NORMAL,
                title: 'Key Spec',
                description: keyData?.keyMetadata.keySpec || '-',
              },
              {
                id: 'keyUsage',
                type: TextTypeEnum.NORMAL,
                title: 'Key Usages',
                description: keyData?.keyMetadata.keyUsage || '-',
              },
            ];
            if (!keyData.keyMetadata.keySpec.startsWith('SYMMETRIC_') && keyData.keyMetadata.encryptionAlgorithms) {
              cryptConfigContentData.push({
                id: 'encryptionAlgorithms',
                type: TextTypeEnum.NORMAL,
                title: 'Encryption algorithms',
                description: keyData?.keyMetadata.encryptionAlgorithms,
              });
            }
            if (!keyData.keyMetadata.keySpec.startsWith('SYMMETRIC_') && keyData.keyMetadata.macAlgorithms) {
              cryptConfigContentData.push({
                id: 'macAlgorithms',
                type: TextTypeEnum.NORMAL,
                title: 'MAC algorithms',
                description: keyData?.keyMetadata.macAlgorithms,
              });
            }

            cryptConfigContent.push({
              type: DetailTypeEnum.DETAIL,
              title: 'Cryptographic configuration',
              contents: cryptConfigContentData,
            });

            setDetailsExtraData(cryptConfigContent);
            setDetailApiIsLoading(false);
            break;
          case detailCustomerKeyTabs[3]:
            //Tags
            const tags = await getAwsListResourceTags({ variables: variables }).then(({ data: awstags }) => {
              return awstags?.getAwsListResourceTags?.data?.[0]?.tags;
            });
            const tagsContent: IMgdDetailContentProps[] = [];
            const tagsSectionContent = [];
            const tagsSectionContentRows: RowType[] = [];
            const tagsSectionContentColumns: ColumnType[] = [
              { label: 'Key', field: 'key', sort: true },
              { label: 'Value', field: 'value', sort: true },
            ];
            if (tags) {
              tags.map(item => {
                tagsSectionContentRows.push({
                  key: item.key,
                  value: item.value,
                });
              });
            }

            tagsSectionContent.push({
              title: 'Tags',
              rows: tagsSectionContentRows,
              columns: tagsSectionContentColumns,
              hasPagination: false,
              total: {
                totalPage: 1,
                totalElement: tagsSectionContentRows.length,
              },
              tablePagination: mainTablePagination,
              updateTablePagination: (key: string, value: number | string | OrderDirection) =>
                setMainTablePagination(prev => ({
                  ...prev,
                  [key]: value,
                })),
            });
            const tagsSection: IMgdDetailContentProps = {
              type: DetailTypeEnum.TABLE,
              contents: tagsSectionContent,
            };
            tagsContent.push(tagsSection);
            setDetailsExtraData(tagsContent);
            setDetailApiIsLoading(false);
            break;
          case detailCustomerKeyTabs[4]:
            //Key rotation || public key
            if (keyData.keyMetadata.keySpec.startsWith('RSA_')) {
              //get public key
              const awsPublicKey = await getAwsPublicKey({ variables: variables }).then(({ data: awstags }) => {
                return awstags?.getAwsPublicKey?.data?.[0];
              });
            } else {
              const keyRotationContent: IMgdDetailContentProps[] = [];
              const keyRotationStatus = await getAwsKeyRotationStatus({ variables: variables }).then(
                ({ data: awsKeyRotationStatus }) => {
                  return awsKeyRotationStatus?.getAwsKeyRotationStatus?.data?.[0];
                },
              );
              let lastDateRotation = '-';
              if (keyRotationStatus && keyRotationStatus.keyRotationEnabled) {
                const keyRotations = await getAwsListKeyRotations({ variables: variables }).then(
                  ({ data: awsKeyRotation }) => {
                    return awsKeyRotation?.getAwsListKeyRotations?.data?.[0].rotations;
                  },
                );
                lastDateRotation = keyRotations?.[0]?.rotationDate || '-';
              }
              keyRotationContent.push({
                type: DetailTypeEnum.DETAIL,
                title: 'Automatic key rotation',
                description: 'AWS KMS automatically rotates the key based on the rotation period that you define.',
                contents: [
                  {
                    id: 'status',
                    type: TextTypeEnum.STATUS,
                    title: 'Status',
                    description: keyRotationStatus?.keyRotationEnabled ? 'Enabled' : 'Disabled',
                  },
                  {
                    id: 'rotationPeriod',
                    type: TextTypeEnum.NORMAL,
                    title: 'Rotation period',
                    description: keyRotationStatus?.rotationPeriodInDays || '-',
                  },
                  {
                    id: 'lastDateRotation',
                    type: TextTypeEnum.NORMAL,
                    title: 'Date of last automatic rotation',
                    description: lastDateRotation,
                  },
                  {
                    id: 'nextRotationDate',
                    type: TextTypeEnum.NORMAL,
                    title: 'Next rotation date',
                    description: keyRotationStatus?.nextRotationDate || '-',
                  },
                ],
              });
              setDetailsExtraData(keyRotationContent);
              setDetailApiIsLoading(false);
            }
            break;
          case detailCustomerKeyTabs[5]:
            if (keyData.keyMetadata.multiRegion) {
              const regionalityContent: IMgdDetailContentProps[] = [];
              const overviewTitle =
                keyData.keyMetadata.multiRegionConfiguration.multiRegionKeyType === 'PRIMARY'
                  ? 'Primary key'
                  : 'Replica key';
              const overviewDescription =
                keyData.keyMetadata.multiRegionConfiguration.multiRegionKeyType === 'PRIMARY'
                  ? `This is a multi-Region primary key. It has ${keyData.keyMetadata.multiRegionConfiguration.replicaKeys.length} replica. You can change any replica to the primary key.`
                  : 'This is a replica key. Replica keys share key material with their related multi-Region keys.';
              regionalityContent.push({
                type: DetailTypeEnum.MULTICOLUMN,
                contents: [
                  {
                    title: overviewTitle,
                    subTitle: overviewDescription,
                  },
                ],
              });

              const regionalitySectionContent = [];
              const regionalitySectionContentRows: RowType[] = [];
              const regionalitySectionContentColumns: ColumnType[] = [
                { label: 'Regions', field: 'regions', sort: true },
                {
                  label: 'Key ARN',
                  field: 'keyArn',
                  sort: true,
                  renderCell: row => {
                    return <CopyableText value={row.keyArn} text={handleFormatText(row.keyArn)} />;
                  },
                },
                { label: 'Status', field: 'status', sort: true },
                { label: 'Regionality', field: 'regionality', sort: true },
              ];
              if (keyData.keyMetadata.multiRegionConfiguration.multiRegionKeyType !== 'PRIMARY') {
                const primaryRegion = keyData.keyMetadata.multiRegionConfiguration.primaryKey;
                regionalitySectionContentRows.push({
                  regions: primaryRegion.region,
                  keyArn: primaryRegion.arn,
                  status: keyData.keyMetadata.keyState,
                  regionality: 'Primary',
                });
              } else {
                const replicaRegion = keyData.keyMetadata.multiRegionConfiguration.replicaKeys;
                replicaRegion.map(item => {
                  regionalitySectionContentRows.push({
                    regions: item.region,
                    keyArn: item.arn,
                    status: keyData.keyMetadata.keyState,
                    regionality: 'Replica',
                  });
                });
              }

              regionalitySectionContent.push({
                title: 'Related multi-Region keys',
                rows: regionalitySectionContentRows,
                columns: regionalitySectionContentColumns,
                hasPagination: false,
                total: {
                  totalPageprimaryRegion: 1,
                  totalElement: regionalitySectionContentRows.length,
                },
                tablePagination: mainTablePagination,
                updateTablePagination: (key: string, value: number | string | OrderDirection) =>
                  setMainTablePagination(prev => ({
                    ...prev,
                    [key]: value,
                  })),
              });
              const regionalitySection: IMgdDetailContentProps = {
                type: DetailTypeEnum.TABLE,
                contents: regionalitySectionContent,
              };
              regionalityContent.push(regionalitySection);
              setDetailsExtraData(regionalityContent);
              setDetailApiIsLoading(false);
            } else {
              getAliasesData(aliasData);
            }
            break;
          case detailCustomerKeyTabs[6]:
            getAliasesData(aliasData);
            break;
          default:
            setDetailApiIsLoading(false);
            break;
        }
      } else {
        setNoPermissionMessage('You do not have permission to access the KMS keys');
        setDetailsData([]);
      }
    }
  };

  const getAliasesData = (aliasData: AwsListAliasesResponse | undefined) => {
    const aliasesContent: IMgdDetailContentProps[] = [];
    const aliasesSectionContent = [];
    const aliasesSectionContentRows: RowType[] = [];
    const aliasesSectionContentColumns: ColumnType[] = [
      { label: 'Alias name', field: 'name', sort: true },
      { label: 'Alias ARN', field: 'arn', sort: true },
    ];
    if (aliasData?.aliases) {
      aliasData?.aliases.map(item => {
        aliasesSectionContentRows.push({
          name: item.aliasName,
          arn: item.aliasArn,
        });
      });
    }

    aliasesSectionContent.push({
      title: 'Aliases',
      rows: aliasesSectionContentRows,
      columns: aliasesSectionContentColumns,
      hasPagination: false,
      total: {
        totalPage: 1,
        totalElement: aliasesSectionContentRows.length,
      },
      tablePagination: mainTablePagination,
      updateTablePagination: (key: string, value: number | string | OrderDirection) =>
        setMainTablePagination(prev => ({
          ...prev,
          [key]: value,
        })),
    });
    const aliasesSection: IMgdDetailContentProps = {
      type: DetailTypeEnum.TABLE,
      contents: aliasesSectionContent,
    };
    aliasesContent.push(aliasesSection);
    setDetailsExtraData(aliasesContent);
    setDetailApiIsLoading(false);
  };

  const getSecretDetailData = () => {
    setDetailApiIsLoading(true);
    const detailData = mainTblRows.find(mainData => mainData.id === mainSelected);
    if (detailData?.id || detailData?.name) {
      const desSecVariable = {
        cloudId: Number(relatedCloudSelected.value),
        region: selectedRegion.value as string,
        request: { secretId: detailData.id || detailData.name },
      };
      getAwsDescribeSecret({ variables: desSecVariable }).then(async ({ data: awsDescribeSecretData }) => {
        const describeSecret = awsDescribeSecretData?.getAwsDescribeSecret?.data?.[0];
        const detailsData: IMgdDetailKeyValueProps[] = [];
        const detailsOrderedData: IMgdDetailKeyValueProps[] = [];
        if (describeSecret) {
          for (const [key, value] of Object.entries(describeSecret)) {
            if (detailSecretKeys.indexOf(key) > -1) {
              let desValue = '-';
              if (key === detailSecretKeys[0]) {
                if (value) {
                  const awsAliasesVariable = {
                    cloudId: Number(relatedCloudSelected.value),
                    region: selectedRegion.value as string,
                    request: { keyId: value as string },
                  };
                  const aliasName = await getAwsListAliases({ variables: awsAliasesVariable }).then(
                    ({ data: alias }) => {
                      return alias?.getAwsListAliases?.data?.[0]?.aliases?.[0]?.aliasName;
                    },
                  );
                  desValue = aliasName || '-';
                } else {
                  desValue = 'aws/secretsmanager';
                }
              }
              const textDes = detailSecretTextDes.find(text => text.id == key);
              if (typeof value === 'string') {
                desValue = value.toString();
              }

              if (key === detailSecretKeys[3]) {
                desValue = '';
              }

              if (textDes) {
                detailsData.push({
                  id: key,
                  type: textDes.type as TextTypeEnum,
                  title: textDes.value,
                  description: desValue,
                });
              }
            }
          }

          detailSecretKeys.map(key => {
            const dataByKey = detailsData.find(data => data.id == key);
            if (dataByKey) detailsOrderedData.push(dataByKey);
          });
          setDetailsData(detailsOrderedData);
        }
      });
    }

    switch (detailTabSelected) {
      case detailSecretTabs[1]:
        const overviewContent: IMgdDetailContentProps[] = [];

        overviewContent.push({
          type: DetailTypeEnum.MULTICOLUMN,
          contents: [
            {
              title: 'Secret value',
              subTitle: 'Retreive and view the secret value',
            },
          ],
        });

        overviewContent.push({
          type: DetailTypeEnum.MULTICOLUMN,
          contents: [
            {
              title: 'Resource permissions-optional',
              subTitle: 'Add or edit a resource policy to access secrets across AWS accounts.',
            },
          ],
        });

        setDetailsExtraData(overviewContent);
        setDetailApiIsLoading(false);
        break;
      case detailSecretTabs[2]:
        const rotationContent: IMgdDetailContentProps[] = [];
        let rotationEnable = 'Disabled';
        let rotationSchedule = '-';
        let lastRoatedDate = '-';
        let nextRoatedDate = '-';
        let roationLambdaDate = '-';
        if (detailData?.rotationEnabled) {
          rotationEnable = detailData?.rotationEnabled;
        }
        if (detailData?.rotationRules?.cheduleExpression) {
          rotationSchedule = detailData?.rotationRules?.cheduleExpression;
        }
        if (detailData?.lastRotatedDate) {
          lastRoatedDate = getFormatedDateWithTimezone(detailData?.lastRotatedDate);
        }
        if (detailData?.nextRotationDate) {
          nextRoatedDate = getFormatedDateWithTimezone(detailData?.nextRotationDate);
        }
        if (detailData?.rotationLambdaARN) {
          roationLambdaDate = detailData?.rotationLambdaARN;
        }
        rotationContent.push({
          type: DetailTypeEnum.DETAIL,
          title: 'Secret valuRotations configuratione',
          contents: [
            { type: TextTypeEnum.NORMAL, title: 'Rotation status', description: rotationEnable },
            { type: TextTypeEnum.NORMAL, title: 'Rotation schedule', description: rotationSchedule },
            { type: TextTypeEnum.NORMAL, title: 'Last rotated date (UTC)', description: lastRoatedDate },
            {
              type: TextTypeEnum.NORMAL,
              title: 'Net rotation date (UTC) \n The next rotation is scheduled to occur on or before this date.',
              description: nextRoatedDate,
            },
            {
              type: TextTypeEnum.NORMAL,
              title: 'Lambda rotation function \n The Lambda function that has permissions to rotate this secret.',
              description: roationLambdaDate,
            },
          ],
          numberOfColumns: 1,
        });
        setDetailsExtraData(rotationContent);
        setDetailApiIsLoading(false);
        break;
      case detailSecretTabs[3]:
        const versionIdContent: IMgdDetailContentProps[] = [];
        if (detailData?.id || detailData?.name) {
          const desSecVariable = {
            cloudId: Number(relatedCloudSelected.value),
            region: selectedRegion.value as string,
            request: { secretId: detailData.id || detailData.name },
          };
          getAwsListSecretVersionIds({ variables: desSecVariable }).then(({ data: awsListSecretVersionId }) => {
            const awsListSecretVersionIdData = awsListSecretVersionId?.getAwsListSecretVersionIds?.data?.[0];

            if (awsListSecretVersionIdData) {
              const versionIdRows: RowType[] = [];
              const versionIdList = awsListSecretVersionIdData?.versions;

              if (versionIdList && versionIdList.length > 0) {
                versionIdList.map(versionIdData => {
                  versionIdRows.push({
                    id: versionIdData?.versionId,
                    versionId: versionIdData?.versionId,
                    stagingLabel: STAGING_LABLE_MAPPING[versionIdData?.versionStages.toString() || ''].label,
                    lastAccessed: moment(versionIdData?.lastAccessedDate).format('MMMM DD, YYYY'),
                    createOnUtc: getFormatedDateWithTimezone(versionIdData?.createdDate),
                  });
                });
              }

              versionIdContent.push({
                type: DetailTypeEnum.TABLE,
                contents: [
                  {
                    title: 'Secret versions',
                    rows: versionIdRows,
                    columns: [
                      {
                        field: 'versionId',
                        label: 'Version ID',
                        sort: true,
                        renderCell: row => {
                          return <CopyableText value={row?.versionId} text={handleFormatText(row?.versionId)} />;
                        },
                      },
                      {
                        field: 'stagingLabel',
                        label: 'Staging labels',
                        sort: true,
                        renderCell: row => {
                          return <p className="text-green-01">{handleFormatText(row.stagingLabel)}</p>;
                        },
                      },
                      { field: 'lastAccessed', label: 'Last accessed', sort: true },
                      { field: 'createOnUtc', label: 'Created on UTC', sort: true },
                    ],
                    hasPagination: false,
                    total: {
                      totalPage: 1,
                      totalElement: versionIdRows?.length,
                    },
                    tablePagination: mainTablePagination,
                    updateTablePagination: () => {},
                  },
                ],
              });

              setDetailsExtraData(versionIdContent);
              setDetailApiIsLoading(false);
            }
          });
        }
        break;
      case detailSecretTabs[4]:
        setDetailsExtraData([
          {
            type: DetailTypeEnum.DETAIL,
            title: 'Replication',
            contents: [],
            numberOfColumns: 1,
          },
        ]);
        setDetailApiIsLoading(false);
        break;
      case detailSecretTabs[5]:
        const tagsContent: IMgdDetailContentProps[] = [];
        const detailRows: { key: string; value: string }[] = [];
        detailData?.tags?.map((tag: any) => {
          detailRows.push({
            key: tag.key,
            value: tag.value,
          });
        });

        tagsContent.push({
          type: DetailTypeEnum.TABLE,
          contents: [
            {
              title: 'Tags',
              rows: detailRows ? detailRows : [],
              columns: [
                { field: 'key', label: 'Key', sort: true },
                { field: 'value', label: 'Value', sort: true },
              ],
              total: {
                totalPage: 1,
                totalElement: detailRows.length,
              },
              tablePagination: mainTablePagination,
              updateTablePagination: () => {},
            },
          ],
        });
        setDetailsExtraData(tagsContent);
        setDetailApiIsLoading(false);
        break;

      default:
        setDetailApiIsLoading(false);
        break;
    }
  };

  const fetchMoreMainRowsHandler = useCallback(
    (nextToken: string) => {
      switch (mainTabSelected) {
        case mainTabs[0]:
          getAwsKeyMainDataRows(nextToken);
          break;
        case mainTabs[1]:
          getCustomerKeyMainDataRows(nextToken);
          break;
        case mainTabs[2]:
          getSecretMainDataRows(nextToken);
          break;
        default:
          break;
      }
    },
    [mainTabSelected],
  );

  const mainRowsCurrentPage = useMemo(() => {
    if (mainTablePagination.currentPage > mainTblTotal.totalPage && !!mainNextToken) {
      fetchMoreMainRowsHandler(mainNextToken);
    }
    const startIndex = (mainTablePagination.currentPage - 1) * mainTablePagination.itemPerPage;
    const endIndex = startIndex + mainTablePagination.itemPerPage;

    return orderAlphabetical(mainTblRows, mainTablePagination.target, mainTablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [mainTblRows, mainTablePagination]);

  const renderPage = useMemo(() => {
    switch (layoutType) {
      case MgdLayoutTypeEnum.MGD_DETAIL:
        const detailInfo = mainTblRows.find(r => r?.id == mainSelected);
        return (
          <MgdLayout
            layoutType={layoutType}
            pageTitle={`${detailInfo?.name != undefined ? detailInfo?.name : ''}`}
            hasFavorite={false}
            pageBackClick={() => {
              setLayoutType(MgdLayoutTypeEnum.MGD_LIST_SUMMARY);
              setMainSelected('');
            }}
            rows={[]}
            columns={[]}
            total={mainTblTotal}
            tablePagination={mainTablePagination}
            updateTablePagination={(key: string, value: number | string | OrderDirection) =>
              setMainTablePagination(prev => ({
                ...prev,
                [key]: value,
              }))
            }
            checkedList={checkedList}
            reportCheckedList={(list: string[]) => setCheckedList(list)}
            currentReport={mainSelected}
            reportSelected={(id: string) => {
              setMainSelected(id);
            }}
            mainTabList={mainTabs}
            mainTabItemClick={mainTabClickHandler}
            mainTitle={detailTabList[0]?.title || ''}
            mainTabSelected={detailTabSelected}
            detailTabList={detailTabList.slice(1)}
            detailTabItemClick={setDetailTabSelected}
            detailTabSelected={detailTabSelected}
            detailHyperLinkClick={handleHDetailHyperlinkMainClick}
            detailsExtraData={detailsExtraData}
            detailsData={detailsData}
            detailsDataNumberOfColumns={mainTabSelected == mainTabs[2] ? 2 : 0}
            detailContents={detailContents}
            noDetailsDataMessage={noPermissionMessage}
            detailIsLoading={detailApiIsLoading}
            mainIsLoading={mainApiIsLoading}
          />
        );
      default:
        return (
          <MgdLayout
            layoutType={layoutType}
            pageTitle={cloudName}
            hasFavorite={true}
            pageBackClick={pageBackClick}
            recentRelatedClouds={recentRelatedClouds}
            relatedCloudSelected={relatedCloudSelected}
            recentRelatedCloudOnChange={relatedCloud => recentRelatedCloudOnChange(relatedCloud)}
            headerDropList={regionList}
            headerDropValue={selectedRegion}
            headerDropOnchange={val => {
              setSelectedRegion(() => ({
                name: val.name ? val.name : '',
                value: String(val.value),
              }));
            }}
            rows={mainRowsCurrentPage}
            columns={mainTblColumns}
            total={mainTblTotal}
            tablePagination={mainTablePagination}
            updateTablePagination={(key: string, value: number | string | OrderDirection) =>
              setMainTablePagination(prev => ({
                ...prev,
                [key]: value,
              }))
            }
            checkedList={checkedList}
            reportCheckedList={(list: string[]) => {}}
            currentReport={mainSelected}
            reportSelected={(id: string) => {}}
            isPined={
              !!defaultRegionList.find(r => {
                return r.projectId === projectId && r.cloudId === relatedCloudSelected.value;
              })
            }
            pinBtnActHandle={pinButtonClickHandle}
            pinBtnDisabled={false}
            mainTabList={mainTabs}
            mainTabItemClick={mainTabClickHandler}
            mainTitle={mainTabSelected.title}
            mainTabSelected={mainTabSelected}
            mainTypeList={mainSearchDropdown}
            mainTypeValue={mainFilterValue}
            mainTypeDropdownOnchange={val => {
              setMainFilterValue(val);
              setMainSearchValue(prev => ({ value: prev.value, name: val.value.toString() }));
            }}
            searchValue={mainSearchValue?.value}
            searchInputOnchange={str => setMainSearchValue(prev => ({ name: prev?.name, value: str }))}
            onSearchKeyDown={(event: any) => onSearchKeyDownHandler(event)}
            detailTabList={detailTabList}
            detailTabItemClick={setDetailTabSelected}
            detailTitle={detailTitle}
            detailContents={detailContents}
            detailTabSelected={detailTabSelected}
            detailHyperLinkClick={handleHDetailHyperlinkMainClick}
            detailIsLoading={detailApiIsLoading}
            mainIsLoading={mainApiIsLoading}
          />
        );
    }
  }, [
    layoutType,
    regionList,
    selectedRegion,
    mainTabs,
    mainTabSelected,
    mainTblRows,
    mainTblColumns,
    mainTblTotal,
    mainSelected,
    detailTabSelected,
    mainTablePagination,
    mainSearchDropdown,
    mainFilterValue,
    mainSearchValue,
    detailTabList,
    detailTitle,
    detailContents,
    detailsData,
    detailsExtraData,
    mainApiIsLoading,
    detailApiIsLoading,
    mainRowsCurrentPage,
    relatedCloudSelected,
  ]);
  return (
    <div className="category" style={{ height: '100%' }}>
      {renderPage}
    </div>
  );
};

export default KeyAndSecret;
