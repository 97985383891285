import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { ReviewPropsType } from '../CreateWebACL/type';
import { PageTitle, SectionContainer, SectionTitle, StepContainer } from '../CreateWebACL/components';
import './styles.scss';
import DetailTab from '../../../EC2/components/DetailTab';
import DetailTable from '../../../EC2/components/DetailTable';
import { RULES_COLUMN_LIST, TOKEN_DOMAIN_COLUMN_LIST } from './configs';
import SectionItem from '../CreateWebACL/components/SectionItem';
import Button, { ButtonTypeEnum } from '../CreateWebACL/components/Button';

const Review = (props: ReviewPropsType) => {
  const { onBackButtonClicked, creationData } = props;

  const {name, scope, description, region, cloudWatchMetricName, defaultAction} = creationData

  const webACLDetail = useMemo(() => {
    return [
      { id: 'name', title: 'Name', description: name },
      { id: 'scope', title: 'Scope', description: scope },
      { id: 'description', title: 'Description', description: description },
      { id: 'region', title: 'Region', description: region.value },
      { id: 'metricName', title: 'CloudWatch metric name', description: cloudWatchMetricName },
    ];
  }, []);

  const sampleRequests = useMemo(() => {
    return [
      { id: 'sampledRequests', title: 'Sampled requests', description: '' },
      { id: 'sampledRequestAction', title: 'Sampled requests for web ACL default actions', description: '' },
    ];
  }, []);

  const onCancelButtonClicked = useCallback(() => {
    onBackButtonClicked();
  }, []);

  const onPreviousButtonClicked = useCallback(() => {
    onBackButtonClicked();
  }, []);

  const onCreateButtonClicked = useCallback(() => {}, []);

  return (
    <div id="create-webacl-page-container">
      <PageTitle title={'Create web ACL: Review'} hasBackButton onBackButtonClicked={onBackButtonClicked} />
      <SectionTitle title="Review and create web ACL" customStyle="white-background" />
      <StepContainer title="Step 1. Describe web ACL and associate it to AWS resources">
        <SectionContainer customStyle="no-padding-bottom">
          <DetailTab data={webACLDetail} title="Web ACL details" />
        </SectionContainer>
      </StepContainer>
      <StepContainer title="Step 2 and 3. Add rules and set rule priority">
        <DetailTable
          title="Rules"
          description="If a request matches a rule, take the corresponding action. The rules are prioritized in order they appear."
          data={[]}
          columns={RULES_COLUMN_LIST}
        />
        <SectionContainer
          title="Web ACL capacity units (WCUs) used by your web ACL"
          description="The WCUs used by the web ACL will be less than equal to the sum of the capacities for all of the rules in the web ACL."
          customStyle="no-padding-bottom"
        >
          <SectionItem.Container
            title={'The total WCUs for a web ACL can’t exceed 5000. Using over 1500 WCUs affects your costs.'}
          >
            <SectionItem.StyledText text={'125/5000 WCUs'} />
          </SectionItem.Container>
        </SectionContainer>
        <SectionContainer
          title="Default web ACL action for requests that don’t match any rules"
          customStyle="no-padding-bottom"
        >
          <SectionItem.Container title={'Action'}>
            <SectionItem.StyledText text={defaultAction} />
          </SectionItem.Container>
        </SectionContainer>
        <SectionContainer title="Token domain list" customStyle="no-padding-bottom">
          <DetailTable data={[]} columns={TOKEN_DOMAIN_COLUMN_LIST} />
        </SectionContainer>
      </StepContainer>
      <StepContainer title="Step 4. Configure metrics">
        <DetailTable title="Amazon CloudWatch metrics" data={[]} columns={RULES_COLUMN_LIST} />
        <SectionContainer customStyle="no-padding-bottom">
          <DetailTab data={sampleRequests} title="Sampled requests" />
        </SectionContainer>
      </StepContainer>
      <div className="button-group">
        <Button label="Cancel" onClick={onCancelButtonClicked} />
        <Button label="Previous" onClick={onPreviousButtonClicked} />
        <Button label="Create web ACL" type={ButtonTypeEnum.PRIMARY} onClick={onCreateButtonClicked} />
      </div>
    </div>
  );
};

export default Review;
