import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseData, IGqlResponseNewData } from 'graphql/types';
import { AwsRegexPatternSetResponse } from 'graphql/types/AwsRegexPatternSet';

export interface IGetAwsRegexPatternSetVariables {
  cloudId: number;
  region: string;
  request: {
    id: string;
    name: string;
    scope: string;
  };
}

export interface IAwsRegexPatternSetResponseData {
  getAwsRegexPatternSet: IGqlResponseNewData<AwsRegexPatternSetResponse>;
}

const lazyGetAwsRegexPatternSet = () =>
  useLazyQuery<IAwsRegexPatternSetResponseData, IGetAwsRegexPatternSetVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsRegexPatternSet;
