import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsDescribeTargetHealthResponse, AwsTargetDescriptionLog } from 'graphql/types/AwsDescribeTargetHealthResponse';

export interface IGetAwsDescribeTargetHealthVariables {
  cloudId: number;
  region: string;
  request: {
    targetGroupArn?: string;
    targets?: AwsTargetDescriptionLog[];
    include?: string;
  };
}

export interface IAwsDescribeTargetHealthResponseData {
  getAwsDescribeTargetHealth: IGqlResponseNewData<AwsDescribeTargetHealthResponse>;
}

const lazyGetAwsDescribeTargetHealth = () =>
  useLazyQuery<IAwsDescribeTargetHealthResponseData, IGetAwsDescribeTargetHealthVariables>(query, {
    fetchPolicy: 'no-cache',
  });
export default lazyGetAwsDescribeTargetHealth;
