import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';

export interface IAwsDescribeVolumeStatusVariables {
  cloudId: number;
  region: string;
  request: {
    volumeIds: string;
  };
}

export interface IAwsDescribeVolumeStatusResponseData {
  getAwsDescribeVolumeStatus: IGqlResponseNewData<any>;
}

const lazyGetAwsDescribeVolumeStatus = () =>
  useLazyQuery<IAwsDescribeVolumeStatusResponseData, IAwsDescribeVolumeStatusVariables>(query, {
    fetchPolicy: 'no-cache',
  });
export default lazyGetAwsDescribeVolumeStatus;
