import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsListGroupsIAMResponseEntry } from 'graphql/types/AwsListGroupsIAM';

export interface IAwsListGroupsIAMVariables {
  cloudId: number;
  region: string;
  request: {
    maxItems: number;
    nextToken?: string;
    filters?: {
      name: string;
      values: string[];
    };
  };
}

export interface IAwsListGroupsIAMResponseData {
  getAwsListGroupsIAM: IGqlResponseNewData<AwsListGroupsIAMResponseEntry>;
}

const lazyGetAwsListGroupsIAM = () =>
  useLazyQuery<IAwsListGroupsIAMResponseData, IAwsListGroupsIAMVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsListGroupsIAM;
