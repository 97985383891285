import { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import { IMgdTabProps, TextTypeEnum } from 'layouts/v3/MgdLayout';

export const SCOPE_DROPDOWN: DropdownListDataType[] = [
  { id: 1, name: 'Owned by me', value: 'owners' },
  { id: 2, name: 'Private images', value: 'is-private' },
  { id: 3, name: 'Public images', value: 'is-public' },
  { id: 4, name: 'Disabled images', value: 'includeDisabled' },
];

export const IMAGE_FILTER_DROPDOWN: DropdownListDataType[] = [
  { id: 1, name: 'AMI ID', value: 'image-id' },
  { id: 2, name: 'AMI name', value: 'name' },
  { id: 3, name: 'Name', value: 'tag:Name' },
  { id: 4, name: 'Description', value: 'description' },
  { id: 5, name: 'Platform', value: 'platform' },
];

export const SUMMARY_TAB_LIST: Array<IMgdTabProps> = [
  { id: 'detail', name: 'Detail', title: 'Detail' },
  { id: 'permissions', name: 'Permissions', title: 'Permissions' },
  { id: 'storage', name: 'Storage', title: 'Storage' },
  { id: 'tags', name: 'Tags', title: 'Tags' },
];

export const DETAIL_TAB_LIST: Array<IMgdTabProps> = [
  { id: 'permissions', name: 'Permissions', title: 'Permissions' },
  { id: 'storage', name: 'Storage', title: 'Storage' },
  { id: 'tags', name: 'Tags', title: 'Tags' },
];

export const detailTabKeys = [
  'imageId',
  'imageType',
  'platformDetails',
  'rootDeviceType',
  'name',
  'ownerId',
  'architecture',
  'usageOperation',
  'rootDeviceName',
  'state',
  'imageLocation',
  'virtualizationType',
  'bootMode',
  'stateReason',
  'creationDate',
  'kernelId',
  'description',
  'productCodes',
  'ramdiskId',
  'deprecationTime',
  'lastLaunchedTime',
  'blockDeviceMappings',
  'deregistrationProtection',
];

export const detailTabTextDes: { id: string; value: string; type: string }[] = [
  { id: 'imageId', value: 'AMI ID', type: TextTypeEnum.COPY },
  { id: 'imageType', value: 'Image Type', type: TextTypeEnum.NORMAL },
  { id: 'platformDetails', value: 'Platform details', type: TextTypeEnum.NORMAL },
  { id: 'rootDeviceType', value: 'Root device type', type: TextTypeEnum.NORMAL },
  { id: 'name', value: 'AMI Name', type: TextTypeEnum.COPY },
  { id: 'ownerId', value: 'Owner account ID', type: TextTypeEnum.COPY },
  { id: 'architecture', value: 'Architecture', type: TextTypeEnum.NORMAL },
  { id: 'usageOperation', value: 'Usage operation', type: TextTypeEnum.NORMAL },
  { id: 'rootDeviceName', value: 'Root device name', type: TextTypeEnum.NORMAL },
  { id: 'state', value: 'Status', type: TextTypeEnum.NORMAL },
  { id: 'imageLocation', value: 'Source', type: TextTypeEnum.COPY },
  { id: 'virtualizationType', value: 'Virtualization type', type: TextTypeEnum.NORMAL },
  { id: 'bootMode', value: 'Boot mode', type: TextTypeEnum.NORMAL },
  { id: 'stateReason', value: 'State reason', type: TextTypeEnum.NORMAL },
  { id: 'creationDate', value: 'Creation date', type: TextTypeEnum.NORMAL },
  { id: 'kernelId', value: 'Kernel ID', type: TextTypeEnum.NORMAL },
  { id: 'description', value: 'Description', type: TextTypeEnum.NORMAL },
  { id: 'productCodes', value: 'Product codes', type: TextTypeEnum.NORMAL },
  { id: 'ramdiskId', value: 'RAM disk ID', type: TextTypeEnum.NORMAL },
  { id: 'deprecationTime', value: 'Deprecation time', type: TextTypeEnum.NORMAL },
  { id: 'lastLaunchedTime', value: 'Last launch time', type: TextTypeEnum.NORMAL },
  { id: 'blockDeviceMappings', value: 'Block devices', type: TextTypeEnum.COPY },
  { id: 'deregistrationProtection', value: 'Deregistration protection', type: TextTypeEnum.NORMAL },
];