import { gql } from '@apollo/client';

const query = gql`
  query getAwsAllDescribeInstances($request: AwsDescribeInstancesRequest, $cloudId: Long, $region: String) {
    getAwsAllDescribeInstances(describeInstancesRequest: $request, cloudId: $cloudId, region: $region) {
      result
      nextToken
      data {
        ... on AwsReservationLog {
          groups {
            groupName
            groupId
          }
          instances {
            amiLaunchIndex
            imageId
            instanceId
            instanceType
            kernelId
            keyName
            launchTime
            monitoring {
              state
            }
            placement {
              availabilityZone
              affinity
              groupName
              partitionNumber
              hostId
              tenancy
              spreadDomain
              hostResourceGroupArn
              groupId
            }
            platform
            privateDnsName
            privateIpAddress
            productCodes {
              productCodeId
              productCodeType
            }
            publicDnsName
            publicIpAddress
            ramdiskId
            state {
              code
              name
            }
            stateTransitionReason
            subnetId
            vpcId
            architecture
            blockDeviceMappings {
              deviceName
              ebs {
                attachTime
                deleteOnTermination
                status
                volumeId
                associatedResource
                volumeOwnerId
              }
            }
            clientToken
            ebsOptimized
            enaSupport
            hypervisor
            iamInstanceProfile {
              arn
              id
            }
            instanceLifecycle
            elasticGpuAssociations {
              elasticGpuId
              elasticGpuAssociationId
              elasticGpuAssociationState
              elasticGpuAssociationTime
            }
            elasticInferenceAcceleratorAssociations {
              elasticInferenceAcceleratorArn
              elasticInferenceAcceleratorAssociationId
              elasticInferenceAcceleratorAssociationState
              elasticInferenceAcceleratorAssociationTime
            }
            networkInterfaces {
              association {
                carrierIp
                ipOwnerId
                customerOwnedIp
                publicDnsName
              }
              attachment {
                attachTime
                attachmentId
                deleteOnTermination
                deviceIndex
                status
                networkCardIndex
                enaSrdSpecification {
                  enaSrdEnabled
                  enaSrdUdpSpecification {
                    enaSrdUdpEnabled
                  }
                }
              }
              description
              ipv6Addresses {
                ipv6Address
                isPrimaryIpv6
              }
              groups {
                groupName
                groupId
              }
              macAddress
              networkInterfaceId
            }
            outpostArn
            rootDeviceNam
            rootDeviceTyp
            securityGroups {
              groupName
              groupId
            }
            sourceDestCheck
            spotInstanceRequestId
            sriovNetSupport
            stateReason {
              code
              message
            }
            tags {
              key
              value
            }
            virtualizationType
            cpuOptions {
              coreCount
              threadsPerCore
              amdSevSnp
            }
            capacityReservationId
            capacityReservationSpecification {
              capacityReservationPreference
              capacityReservationTarget {
                capacityReservationId
                capacityReservationResourceGroupArn
              }
            }
            hibernationOptions {
              configured
            }
            licenses {
              licenseConfigurationArn
            }
            metadataOptions {
              state
              httpTokens
              httpPutResponseHopLimit
              httpEndpoint
              httpProtocolIpv6
              instanceMetadataTags
            }
            enclaveOptions {
              enabled
            }
            bootMode
            platformDetails
            usageOperation
            usageOperationUpdateTime
            privateDnsNameOptions {
              hostnameType
              enableResourceNameDnsARecord
              enableResourceNameDnsAAAARecord
            }
            ipv6Address
            tpmSupport
            maintenanceOptions {
              autoRecovery
            }
            currentInstanceBootMode
          }
          ownerId
          requesterId
          reservationId
        }
      }
    }
  }
`;
export default query;
