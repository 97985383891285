import { useLazyQuery } from '@apollo/client';
import query from './query';
import { AwsSubnetCidrReservationsRespons } from 'graphql/types/AwsSubnetCidrReservations';

export interface IAwsSubnetCidrReservationsVariables {
  cloudId: number;
  region: string;
  request: {
    maxResults: number;
    nextToken?: string;
    subnetIds?: string[];
    dryRun?: boolean;
    filters?: {
      name: string;
      values: string[];
    }
  }
}

export interface IAwsSubnetCidrReservationsResponseData {
  getAwsSubnetCidrReservations: AwsSubnetCidrReservationsRespons;
}

const lazyGetAwsSubnetCidrReservations = () => useLazyQuery<IAwsSubnetCidrReservationsResponseData, IAwsSubnetCidrReservationsVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsSubnetCidrReservations;