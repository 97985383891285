import { gql } from '@apollo/client';

const query = gql`
query getAwsAllListGroupResources($request: AwsListGroupResourcesRequest, $cloudId: Long, $region: String) {
  getAwsAllListGroupResources(
    listGroupResourcesRequest: $request
    cloudId: $cloudId
    region: $region
  ) {
    result
    data {
      ... on AwsResourceIdentifier {
        resourceArn
        resourceType
      }
    }
  }
}`;
export default query;