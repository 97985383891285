import React from 'react';
import InputAtom from "components/v2/atoms/InputAtom";
import CheckboxAtom from "components/v2/atoms/CheckboxAtom";

type ImmunityTimePropsType = {
  seconds: string;
  setSeconds: (val: string) => void;
  isCustomImmunityTime: boolean;
  setIsCustomImmunityTime: (val: boolean) => void;
};

function ImmunityTime({seconds, setSeconds, isCustomImmunityTime, setIsCustomImmunityTime}: ImmunityTimePropsType) {
  return (
    <div className='group-immunity-time'>
      <div className='group-immunity-time-title'>
        <p className='rule-container-group-content-label'>Immunity time</p>

        <p className='rule-container-group-content-description'>Specify how long a CAPTCHA token can be used
          after
          it’s created. The AWS WAF default setting is 300 seconds. You can configure this at the web ACL
          level
          and the rule level. The web ACL configuration applies to all rules that don’t have their own
          setting.
          Additional pricing applies. See AWS WAF Pricing {'>'}</p>
      </div>

      <div className="input-container">
        <div className='second-input-group'>
          <div className='mw-50 w-100'>
            <InputAtom
              placeholder=''
              onChangeValue={val => setSeconds(val)}
              value={seconds}
              noClear
              disabled
            />
          </div>

          <p>seconds</p>
        </div>

        <p className='space-8'>Default configuration</p>
      </div>

      <div className='checkbox-container space-16'>
        <label>
          <CheckboxAtom
            checked={isCustomImmunityTime}
            onchange={() => setIsCustomImmunityTime(!isCustomImmunityTime)}
          />

          Set a custom immunity time for this rule
        </label>
      </div>
    </div>
  );
}

export default ImmunityTime;