import React, { useMemo } from 'react';
import { ButtonPropsType } from './types';
import './styles.scss';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';

export enum ButtonTypeEnum {
  PRIMARY = 'PRIMARY',
  GENERAL = 'GENERAL',
  WARNING = 'WARNING',
  DROPDOWN = 'DROPDOWN',
}

const Button = (props: ButtonPropsType) => {
  const { label, type, onClick, disabled, dropdownList, dropdownValue, onDropdownValueChanged } = props;

  const className = useMemo(() => {
    switch (type) {
      case ButtonTypeEnum.WARNING:
        return 'warning-button';

      case ButtonTypeEnum.PRIMARY:
        return 'primary-button';

      case ButtonTypeEnum.GENERAL:
      default:
        return '';
    }
  }, [type]);

  if (type === ButtonTypeEnum.DROPDOWN && dropdownValue && onDropdownValueChanged && dropdownList) {
    return <DropdownAtom data={dropdownList} id="" value={dropdownValue} handleClick={onDropdownValueChanged} />;
  }

  return (
    <button disabled={disabled} className={`button-container ${className} ${disabled && 'disabled'}`} onClick={onClick}>
      {label}
    </button>
  );
};

export default Button;
