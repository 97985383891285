import React from 'react';
import PropTypes from 'prop-types';
import { SectionTitlePropsType } from './types';
import Button from './Button';
import './styles.scss';
import ButtonGroup from './ButtonGroup';

const SectionTitle = (props: SectionTitlePropsType) => {
  const { title, description, hasBottomBorder, caption, rightButtons, customStyle } = props;

  return (
    <div className={`section-title-container ${hasBottomBorder && ' bottom-border'} ${customStyle}`}>
      <div className="title-container">
        <p className="title">
          {title}
          <span className="caption">{caption}</span>
        </p>
        <p className="section-description">{description}</p>
      </div>
      {rightButtons && (
        <div className="button-group-container">
          <ButtonGroup buttons={rightButtons} />
        </div>
      )}
    </div>
  );
};

export default SectionTitle;
