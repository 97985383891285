import { gql } from '@apollo/client';

const query = gql`
query getAwsDescribeTargetGroups($request: AwsDescribeTargetGroupsRequest, $cloudId: Long, $region: String) {
  getAwsDescribeTargetGroups(describeTargetGroupsRequest: $request, cloudId: $cloudId, region: $region) {
    result
    data {
      ... on AwsDescribeTargetGroupsResponse {
        targetGroups {
          targetGroupArn
          targetGroupName
          protocol
          port
          vpcId
          healthCheckProtocol
          healthCheckPort
          healthCheckEnabled
          healthCheckIntervalSeconds
          healthCheckTimeoutSeconds
          healthyThresholdCount
          unhealthyThresholdCount
          healthCheckPath
          matcher {
            httpCode
            grpcCode
          }
          loadBalancerArns
          targetType
          protocolVersion
          ipAddressType
        }
        nextMarker
        }
      }
    }
  }
`;
export default query;