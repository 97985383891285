import './index.scss';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import NetworkTitle from 'components/v3/NetworkTitle';
import lazyGetAwsRegexPatternSet, { IGetAwsRegexPatternSetVariables } from 'graphql/queries/getAwsRegexPatternSet';
import { AwsRegexPatternSet } from 'graphql/types/AwsRegexPatternSet';
import { RowType } from '@Types/v2/Table';
import Table from 'components/v2/dataDisplay/Table';
import { CLOUDFRONT_OPTION, CLOUDFRONT_REGION, WafScopeEnum, getRegionFromArnsData } from '../../Commons/Constant';
import EditRegexPatternSetInfoModal from '../UpdateRegexPattern/EditRegexPatternSetInfo';
import AddRegexPatternModal from '../UpdateRegexPattern/AddRegexPattern';
import DeleteRegexPatternModal from '../UpdateRegexPattern/DeleteRegexPattern';
import { AwsRegexPatternSets } from 'graphql/types/AwsListRegexPatternSets';
import updateAwsRegexPatternSetMutation, {
  AwsRegexLog,
  IUpdateRegexPatternSetVariables,
} from 'graphql/mutations/updateRegexPatternSet';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';

interface IRegexPatternSetDetailProps {
  cloudId: number;
  region: string;
  currentRegexPattern: AwsRegexPatternSets;
  pageBackClick: () => void;
}

const RegexPatternSetDetail = ({
  cloudId,
  region,
  currentRegexPattern,
  pageBackClick,
}: IRegexPatternSetDetailProps) => {
  const [regexPatternSet, setRegexPatternSet] = useState<AwsRegexPatternSet>();
  const [lockToken, setLockToken] = useState<string>('');
  const [patternRows, setPatternRows] = useState<RowType[]>([]);
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [selected, setSelected] = useState<string>('');
  const [description, setDescription] = useState<string>(regexPatternSet?.description ?? '');
  const [isOpenEditRegexPatternSetInfoModal, setIsOpenEditRegexPatternSetInfoModal] = useState<boolean>(false);
  const [isOpenAddRegexPatternModal, setIsOpenAddRegexPatternoModal] = useState<boolean>(false);
  const [isOpenDeleteRegexPatternModal, setIsOpenDeleteRegexPatternoModal] = useState<boolean>(false);

  const [getAwsRegexPatternSet] = lazyGetAwsRegexPatternSet();
  const [updateAwsRegexPatternSet] = updateAwsRegexPatternSetMutation();

  const deleteBtnDisabled = useMemo((): boolean => {
    return selected === '' || checkedList.length !== 1;
  }, [selected, checkedList]);

  const scope = useMemo((): string => {
    return region === CLOUDFRONT_OPTION.value ? WafScopeEnum.CLOUDFRONT : WafScopeEnum.REGIONAL;
  }, [region]);

  const regionValue = useMemo((): string => {
    return region === CLOUDFRONT_OPTION.value ? CLOUDFRONT_REGION : region;
  }, [region]);

  const getRegexPatternSet = useCallback(() => {
    const reqVariable: IGetAwsRegexPatternSetVariables = {
      cloudId: cloudId,
      region: regionValue,
      request: {
        id: currentRegexPattern.id,
        name: currentRegexPattern.name,
        scope: scope,
      },
    };
    getAwsRegexPatternSet({ variables: reqVariable }).then(({ data: awsRegexPatternSetResponse }) => {
      const regexPatternSetData = awsRegexPatternSetResponse?.getAwsRegexPatternSet?.data?.[0];
      if (regexPatternSetData) {
        const patterns = regexPatternSetData?.regexPatternSet;
        setLockToken(regexPatternSetData?.lockToken);
        if (patterns) {
          setRegexPatternSet(patterns);
          setDescription(patterns?.description);
          const regextRows: RowType[] = [];
          patterns?.regularExpressionList?.map((regexPattern, index) => {
            regextRows.push({
              id: `${patterns.id}-${index}`,
              regexString: regexPattern.regexString,
            });
          });
          setPatternRows(regextRows);
        }
      }
    });
  }, [cloudId, regionValue, scope]);

  const executeUpdateAwsRegexPattern = useCallback(
    (reqVariable: IUpdateRegexPatternSetVariables, successMessage: string, failMessage: string) => {
      updateAwsRegexPatternSet({ variables: reqVariable }).then(({ data: awsRegexPatternResponse }) => {
        const nextLockToken = awsRegexPatternResponse?.updateAwsRegexPatternSet?.data?.[0]?.nextLockToken;
        if (nextLockToken) {
          getRegexPatternSet();
          useToast(ErrorCode.SUCCESS, successMessage);
          setIsOpenEditRegexPatternSetInfoModal(false);
          setIsOpenAddRegexPatternoModal(false);
          setIsOpenDeleteRegexPatternoModal(false);
        } else {
          useToast(ErrorCode.UNKNOWN, failMessage);
        }
      });
    },
    [getRegexPatternSet],
  );

  const handleEditRegexPatternSave = useCallback(
    (descriptionUpdated: string) => {
      if (regexPatternSet) {
        const regexStringList = regexPatternSet?.regularExpressionList?.map(regularExpression => {
          return { regexString: regularExpression.regexString } as AwsRegexLog;
        });
        const reqVariable: IUpdateRegexPatternSetVariables = {
          cloudId: cloudId,
          region: regionValue,
          reqData: {
            id: regexPatternSet?.id,
            name: regexPatternSet?.name,
            scope: scope,
            lockToken: lockToken,
            description: descriptionUpdated,
            regularExpressionList: regexStringList || [],
          },
        };
        executeUpdateAwsRegexPattern(
          reqVariable,
          'Edit regex pattern set successful.',
          'Edit regex pattern set failed.',
        );
      }
    },
    [regexPatternSet, cloudId, regionValue, lockToken, scope],
  );

  const handleAddRegexPatternSave = useCallback(
    (regexPattern: string) => {
      if (regexPatternSet) {
        const regexStringList: AwsRegexLog[] = regexPatternSet?.regularExpressionList?.map(regularExpression => {
          return { regexString: regularExpression.regexString } as AwsRegexLog;
        });
        const regexPatternUpdated: AwsRegexLog[] = regexPattern?.split('\n')?.map(string => {
          return { regexString: string } as AwsRegexLog;
        });
        regexStringList.push(...regexPatternUpdated);
        const reqVariable: IUpdateRegexPatternSetVariables = {
          cloudId: cloudId,
          region: regionValue,
          reqData: {
            id: regexPatternSet?.id,
            name: regexPatternSet?.name,
            scope: scope,
            lockToken: lockToken,
            description: description,
            regularExpressionList: regexStringList || [],
          },
        };
        executeUpdateAwsRegexPattern(reqVariable, 'Add regex pattern successful.', 'Add regex pattern failed.');
      }
    },
    [regexPatternSet, cloudId, regionValue, lockToken, scope, description],
  );

  const handleDeleteRegexPatternSave = useCallback(() => {
    if (regexPatternSet) {
      const regexPatterns = patternRows?.filter(row => row.id !== selected);
      const regexPatternUpdated: AwsRegexLog[] = regexPatterns?.map(row => {
        return { regexString: row?.regexString } as AwsRegexLog;
      });
      const reqVariable: IUpdateRegexPatternSetVariables = {
        cloudId: cloudId,
        region: regionValue,
        reqData: {
          id: regexPatternSet?.id,
          name: regexPatternSet?.name,
          scope: scope,
          lockToken: lockToken,
          description: description,
          regularExpressionList: regexPatternUpdated || [],
        },
      };
      executeUpdateAwsRegexPattern(reqVariable, 'Delete regex pattern successful.', 'Delete regex pattern failed.');
    }
  }, [selected, patternRows]);

  useEffect(() => {
    getRegexPatternSet();
  }, []);

  return (
    <Fragment>
      <div id="regex-pattern-detail">
        <div className="row-1 flex j-between a-center">
          <div className="flex j-start a-center" id="title">
            <NetworkTitle
              pageTitle={'Regex pattern sets: ' + regexPatternSet?.name}
              id={321}
              name={'Regex pattern sets: ' + regexPatternSet?.name}
              hasPrefixIcon={false}
              hasFavorite={false}
              pageBackClick={pageBackClick}
            />
          </div>
        </div>
        <div className="resize-container vertical">
          <div className="details">
            <div className="row-3">
              <div className="title flex j-between a-center border-bottom">
                <p>Info</p>
                <div className="flex action a-center">
                  <button className="edit-btn" onClick={() => setIsOpenEditRegexPatternSetInfoModal(true)}>
                    Edit
                  </button>
                </div>
              </div>

              <div className="content flex">
                <div className="info border-right">
                  <p>Name</p>
                  <p>{regexPatternSet?.name}</p>
                </div>
                <div className="info border-right padding-left">
                  <p>Region</p>
                  <p>{getRegionFromArnsData(regexPatternSet?.arn)}</p>
                </div>
                <div className="info padding-left">
                  <p>Description</p>
                  <p>{description}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="details">
            <div className="row-3">
              <div className="title flex j-between a-center">
                <div>
                  <p>Regex patterns</p>
                </div>
                <div className="flex action a-center">
                  <button
                    className="action-btn"
                    onClick={() => setIsOpenDeleteRegexPatternoModal(true)}
                    disabled={deleteBtnDisabled}
                  >
                    Delete
                  </button>
                  <button className="action-btn" onClick={() => setIsOpenAddRegexPatternoModal(true)}>
                    Add regex pattern
                  </button>
                </div>
              </div>
            </div>
            {patternRows.length == 0 ? (
              <div className="data-grid-wrap">
                <p className="empty-row">Empty</p>
              </div>
            ) : (
              <div className="data-grid-wrap">
                <Table
                  rows={patternRows}
                  columns={[{ label: 'Pattern', field: 'regexString' }]}
                  reportCheckedList={(list: string[]) => {
                    setCheckedList(list);
                    if (list.length > 0) {
                      setSelected(list[list.length - 1]);
                    }
                  }}
                  reportSelected={(id: string) => {
                    setSelected(id);
                    setCheckedList([id]);
                  }}
                  horizontalScrollable={true}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {isOpenEditRegexPatternSetInfoModal ? (
        <EditRegexPatternSetInfoModal
          header={'Edit IP set info'}
          open={isOpenEditRegexPatternSetInfoModal}
          description={description}
          onClose={() => setIsOpenEditRegexPatternSetInfoModal(false)}
          onSave={description => handleEditRegexPatternSave(description)}
        />
      ) : null}
      {isOpenAddRegexPatternModal ? (
        <AddRegexPatternModal
          header={'Add IP addresses to ' + currentRegexPattern.name}
          open={isOpenAddRegexPatternModal}
          onClose={() => setIsOpenAddRegexPatternoModal(false)}
          onSave={regexPattern => handleAddRegexPatternSave(regexPattern)}
        />
      ) : null}
      {isOpenDeleteRegexPatternModal ? (
        <DeleteRegexPatternModal
          resourceName={patternRows.find(row => row.id === selected)?.regexString}
          open={isOpenDeleteRegexPatternModal}
          onClose={() => setIsOpenDeleteRegexPatternoModal(false)}
          onSave={() => handleDeleteRegexPatternSave()}
        />
      ) : null}
    </Fragment>
  );
};
export default RegexPatternSetDetail;
