import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsGetServiceLastAccessedDetailsWithEntitiesResponseEntry } from 'graphql/types/AwsGetServiceLastAccessedDetailsWithEntitiesResponse';

export interface IAwsServiceLastAccessedDetailsWithEntitiesVariables {
  cloudId: number;
  region: string;
  request: {
    jobId: string;
    serviceNamespace?: string;
  };
}

export interface IAwsServiceLastAccessedDetailsWithEntitiesResponseData {
  getAwsServiceLastAccessedDetailsWithEntities: AwsGetServiceLastAccessedDetailsWithEntitiesResponseEntry;
}

const lazyGetAwsServiceLastAccessedDetailsWithEntities = () =>
  useLazyQuery<IAwsServiceLastAccessedDetailsWithEntitiesResponseData, IAwsServiceLastAccessedDetailsWithEntitiesVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsServiceLastAccessedDetailsWithEntities;
