import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { AtributesPropsType } from '../types';
import './Atributes.scss';
import lazyGetAwsDescribeTargetGroupAttributes, {
  IGetAwsDescribeTargetGroupAttributesVariables,
} from 'graphql/queries/getAwsDescribeTargetGroupAttributes';
import { AwsTargetGroupAttribute } from 'graphql/types/AwsDescribeTargetGroupAttributesResponse';
import { ITextDescription } from '../../LoadBalancer/config';
import { IMgdDetailKeyValueProps, TextTypeEnum } from 'layouts/v3/MgdLayout';
import { capitalize } from '../../Image/Common/Functions';
import {
  targetDeregistrationManagementKeys,
  targetDeregistrationManagementTextDes,
  targetGroupHealthRequirementKeys,
  targetGroupHealthRequirementTextDes,
  targetSelectionConfigurationKeys,
  targetSelectionConfigurationTextDes,
  trafficConfigKeys,
  trafficConfigTextDes,
} from '../config';

const Attributes = (props: AtributesPropsType) => {
  const { targetGroup, cloudId, region } = props;
  const [getAwsDescribeTargetGroupAttributes, { loading: awsDescribeTargetGroupAttributeLoading }] =
    lazyGetAwsDescribeTargetGroupAttributes();
  const [attributes, setAttributes] = useState<AwsTargetGroupAttribute[]>([]);

  const apiIsLoading = useMemo(() => {
    return awsDescribeTargetGroupAttributeLoading;
  }, [awsDescribeTargetGroupAttributeLoading]);

  const getDataMappingSection = useCallback(
    (
      attributes: AwsTargetGroupAttribute[],
      keys: string[],
      textDescription: ITextDescription[],
    ): IMgdDetailKeyValueProps[] => {
      const sectionData: IMgdDetailKeyValueProps[] = [];
      const sectionDataOrdered: IMgdDetailKeyValueProps[] = [];
      if (attributes) {
        attributes.map(attribute => {
          Object.entries(attribute).map(([key, value]) => {
            if (keys.indexOf(value) > -1) {
              const textDes = textDescription.find(text => text.id == value);
              let desValue: string | string[] = '-';
              desValue = attribute.value as string;

              if (typeof attribute.value === 'string') {
                if (attribute.value === 'true') {
                  desValue = 'On';
                } else if (attribute.value === 'false') {
                  desValue = 'Off';
                } else if (attribute.value === 'use_load_balancer_configuration') {
                  desValue = 'Inherit settings from load balancer attributes';
                } else {
                  desValue = capitalize(attribute.value as string);
                }
              }

              if (textDes) {
                sectionData.push({
                  id: value,
                  type: textDes.type as TextTypeEnum,
                  title: textDes.value,
                  description: desValue,
                });
              }
            }
          });
        });

        // order data
        keys.map(key => {
          const dataByKey = sectionData.find(data => data.id == key);
          if (dataByKey) sectionDataOrdered.push(dataByKey);
        });
      }
      return sectionDataOrdered;
    },
    [],
  );

  const targetDeregistrationManagementSectionData = useMemo((): IMgdDetailKeyValueProps[] => {
    return getDataMappingSection(attributes, targetDeregistrationManagementKeys, targetDeregistrationManagementTextDes);
  }, [attributes]);

  const trafficConfigSectionData = useMemo((): IMgdDetailKeyValueProps[] => {
    return getDataMappingSection(attributes, trafficConfigKeys, trafficConfigTextDes);
  }, [attributes]);

  const targetSelectionConfigurationSectionData = useMemo((): IMgdDetailKeyValueProps[] => {
    return getDataMappingSection(attributes, targetSelectionConfigurationKeys, targetSelectionConfigurationTextDes);
  }, [attributes]);

  const targetGroupHealthRequirementSectionData = useMemo((): IMgdDetailKeyValueProps[] => {
    return getDataMappingSection(attributes, targetGroupHealthRequirementKeys, targetGroupHealthRequirementTextDes);
  }, [attributes]);

  useEffect(() => {
    if (targetGroup) {
      const attributeVariable: IGetAwsDescribeTargetGroupAttributesVariables = {
        cloudId: cloudId,
        region: region,
        request: {
          targetGroupArn: targetGroup.targetGroupArn,
        },
      };
      getAwsDescribeTargetGroupAttributes({ variables: attributeVariable }).then(
        ({ data: awsDescribeTargetGroupAttributesData }) => {
          setAttributes(
            awsDescribeTargetGroupAttributesData?.getAwsDescribeTargetGroupAttributes?.data?.[0]?.attributes || [],
          );
        },
      );
    }
  }, [targetGroup, cloudId, region]);

  if (apiIsLoading) {
    return (
      <div className="detail-info">
        <div className="progress-container">
          <div className="progress-gif" />
          Loading ...
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      <div className="attribute-info">
        <p className="title">Attributes</p>
        <div className="targetDeregistrationManagement-section">
          <p className="sub-title">Target deregistration management</p>
          <div className="content-container">
            <div className="first border-none">
              <p>{targetDeregistrationManagementTextDes[0].value}</p>
              <p>{targetDeregistrationManagementSectionData[0]?.description}</p>
            </div>
          </div>
        </div>
        <div className="trafficConfig-section">
          <p className="sub-title">Traffic configuration</p>
          <div className="content-container">
            <div className="first">
              <p>{trafficConfigTextDes[0].value}</p>
              <p>{trafficConfigSectionData[0]?.description.replaceAll('_', ' ')}</p>
            </div>
            <div className="second">
              <p>{trafficConfigTextDes[1].value}</p>
              <p>{`${trafficConfigSectionData[1]?.description} seconds`}</p>
            </div>
          </div>
        </div>
        <div className="targetSelectionConfiguration-section">
          <p className="sub-title">Target selection configuration</p>
          <div className="content-container">
            <div className="first">
              <p>{targetSelectionConfigurationTextDes[0].value}</p>
              <p>{targetSelectionConfigurationSectionData[0]?.description}</p>
            </div>
            <div className="second">
              <p>{targetSelectionConfigurationTextDes[1].value}</p>
              <p>{targetSelectionConfigurationSectionData[1]?.description.replaceAll('_', ' ')}</p>
            </div>
          </div>
        </div>
        <div className="targetGroupHealthRequirement-section">
          <p className="sub-title">Target group health requirements</p>
          <div className="content-container">
            <div className="first">
              <p>{targetGroupHealthRequirementTextDes[0].value}</p>
              <p>{`Minimum healthy target count: ${targetGroupHealthRequirementSectionData[0]?.description}`}</p>
              <p>{`Minimum healthy target percentage: ${targetGroupHealthRequirementSectionData[1]?.description}`}</p>
            </div>
            <div className="second">
              <p>{targetGroupHealthRequirementTextDes[2].value}</p>
              <p>{`Minimum healthy target count: ${targetGroupHealthRequirementSectionData[2]?.description}`}</p>
              <p>{`Minimum healthy target percentage: ${targetGroupHealthRequirementSectionData[3]?.description}`}</p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Attributes;
