import { gql } from '@apollo/client';

const query = gql`
query getAwsNetworkAcls($request: AwsDescribeNetworkAclsRequest, $cloudId: Long, $region: String) {
  getAwsNetworkAcls(
    describeNetworkAclsRequest: $request
    cloudId: $cloudId
    region: $region
  ) {
    result
    data {
      ... on AwsDescribeNetworkAclsResponse {
        networkAcls {
          ... on AwsNetworkAcl {
            associations {
              ... on AwsNetworkAclAssociation {
                networkAclAssociationId
                networkAclId
                subnetId
              }
            }
            entries {
              ... on AwsNetworkAclEntry {
                cidrBlock
                egress
                icmpTypeCode {
                  ... on AwsIcmpTypeCode {
                    code
                    type
                  }
                }
                ipv6CidrBlock
                portRange {
                  ... on AwsPortRange {
                    from
                    to
                  }
                }
                protocol
                ruleAction
                ruleNumber
              }
            }
            isDefault
            networkAclId
            vpcId
            ownerId
            tags {
              ... on AwsTag {
                key
                value
              }
            }
          }
        }
      }
    }
  }
}
`;
export default query;