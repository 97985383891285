import { useLazyQuery } from '@apollo/client';
import query from './query';

export interface IAwsListMFADevicesVariables {
  cloudId: number;
  region: string;
  request: {
    userName?: string;
    maxItems?: number;
  };
}

export interface IAwsListMFADevicesResponseData {
  getAwsListMFADevices: any;
}

const lazyGetAwsListMFADevices = () =>
  useLazyQuery<IAwsListMFADevicesResponseData, IAwsListMFADevicesVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsListMFADevices;
