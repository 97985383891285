import { useLazyQuery } from '@apollo/client';
import query from './query';
import { AwsListAttachedGroupPoliciesEntry } from 'graphql/types/AwsListAttachedGroupPolicies';

export interface IGetAwsListAttachedGroupPoliciesVariables {
  cloudId: number;
  region: string;
  request: {
    groupName?: string;
    maxItems?: number;
  }
}

export interface IAwsListAttachedGroupPoliciesResponseData {
  getAwsListAttachedGroupPolicies: AwsListAttachedGroupPoliciesEntry;
}

const lazyGetAwsListAttachedGroupPolicies = () => useLazyQuery<IAwsListAttachedGroupPoliciesResponseData, IGetAwsListAttachedGroupPoliciesVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsListAttachedGroupPolicies