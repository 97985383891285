import React from 'react';
import InputAtom from "components/v2/atoms/InputAtom";

type CustomHeaderPropsType = {
  customHeaderList: [];
  setCustomHeaderList: (val: []) => void;
};

function CustomHeader({customHeaderList, setCustomHeaderList}: CustomHeaderPropsType) {
  const handleAddNewCustomHeader = () => {
    const customHeader = {
      id: (Math.random() + 1).toString(36).substring(2, 8),
      key: '',
      value: ''
    }

    const arr: any = [...customHeaderList]
    arr.push(customHeader)
    setCustomHeaderList(arr)
  }

  const handleChangeCustomHeader = (id: string, key: string, value: string) => {
    const arr: any = [...customHeaderList];

    const index = arr.findIndex((e: {id: string}) => e.id === id)

    if (index < 0) return;

    arr[index] = {
      ...arr[index],
      [key]: value
    }

    setCustomHeaderList(arr)
  }

  const handleRemoveCustomHeader = (id: string) => {
    const arr: any = [...customHeaderList].filter((e: {id: string}) => e.id !== id)

    setCustomHeaderList(arr)
  }

  return (
    <div>
      <p className='rule-container-group-content-description'>With the Allow action, you can add custom
        headers to
        the web request. AWS WAF prefixes your custom header names with x-amzn-waf- when it inserts them.</p>

      <div className='list-container'>
        {
          customHeaderList?.length ?
            customHeaderList.map(({id, key, value}: { id: string; key: string; value: string }) => (
              <div key={id} className='item-container'>
                <div>
                  <label>Key</label>

                  <InputAtom
                    placeholder=''
                    onChangeValue={(val) => handleChangeCustomHeader(id, 'key', val)}
                    value={key}
                    noClear
                  />
                </div>

                <div>
                  <label>Value</label>

                  <InputAtom
                    placeholder=''
                    value={value}
                    onChangeValue={(val) => handleChangeCustomHeader(id, 'value', val)}
                    noClear
                  />
                </div>

                <button className='btn-default' onClick={() => handleRemoveCustomHeader(id)}>Remove
                </button>
              </div>
            )) : <p className='space-16'>No custom headers specified.</p>
        }
      </div>

      <button className='btn-primary space-8' onClick={handleAddNewCustomHeader}>Add new custom
        header
      </button>
    </div>
  );
}

export default CustomHeader;