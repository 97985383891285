import { useLazyQuery } from '@apollo/client';
import query from './query';
import { AwsDescribeLoggingConfigurationResponse } from 'graphql/types/AwsDescribeLoggingConfigurationResponse';

export interface IGetAwsDescribeLoggingConfigurationVariables {
  cloudId: number;
  region: string;
  request: {
    firewallName?: string;
    firewallArn?: string;
  }
}

export interface IAwsDescribeLoggingConfigurationResponseData {
  getAwsLoggingConfigurationFirewall: AwsDescribeLoggingConfigurationResponse;
}

const lazyGetAwsLoggingConfigurationFirewall = () => useLazyQuery<IAwsDescribeLoggingConfigurationResponseData, IGetAwsDescribeLoggingConfigurationVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsLoggingConfigurationFirewall;