import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsRuleGroupResponse } from 'graphql/types/AwsRuleGroup';

export interface IGetAwsRuleGroupVariables {
  cloudId: number;
  region: string;
  request: {
    id: string;
    name: string;
    scope: string;
  };
}

export interface IAwsRuleGroupResponseData {
  getAwsRuleGroup: IGqlResponseNewData<AwsRuleGroupResponse>;
}

const lazyGetAwsRuleGroup = () =>
  useLazyQuery<IAwsRuleGroupResponseData, IGetAwsRuleGroupVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsRuleGroup;
