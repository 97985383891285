import { gql } from '@apollo/client';

const query = gql`
query getAwsAllSubnets($request: AwsDescribeSubnetsRequest, $cloudId: Long, $region: String) {
  getAwsAllSubnets(
    describeSubnetsRequest: $request
    cloudId: $cloudId
    region: $region
  ) {
    result
    nextToken
    data {
      ... on AwsSubnet {
        availabilityZone
        availabilityZoneId
        availableIpAddressCount
        cidrBlock
        defaultForAz
        enableLniAtDeviceIndex
        mapPublicIpOnLaunch
        mapCustomerOwnedIpOnLaunch
        customerOwnedIpv4Pool
        state
        subnetId
        subnetArn
        outpostArn
        enableDns64
        ipv6Native
        privateDnsNameOptionsOnLaunch {
          hostnameType
          enableResourceNameDnsARecord
          enableResourceNameDnsAAAARecord
        }
        assignIpv6AddressOnCreation
        ipv6CidrBlockAssociationSet {
          associationId
          ipv6CidrBlock
          ipv6CidrBlockState {
            state
            statusMessage
          }
        }
        vpcId
        ownerId
        tags {
          key
          value
        }
      }
    }
  }
}`;
export default query;