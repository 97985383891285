import { gql } from '@apollo/client';

const query = gql`
  query getAwsGenerateServiceLastAccessedDetails(
    $request: AwsGenerateServiceLastAccessedDetailsRequest
    $cloudId: Long
    $region: String
  ) {
    getAwsGenerateServiceLastAccessedDetails(
      generateServiceLastAccessedDetailsRequest: $request
      cloudId: $cloudId
      region: $region
    ) {
      result
      data {
        ... on AwsGenerateServiceLastAccessedDetailsResponse {
          jobId
        }
      }
    }
  }
`;
export default query;
