import { gql } from '@apollo/client';

const query = gql`
query getAwsDescribeSpotFleetRequestHistory($request: AwsDescribeSpotFleetRequestHistoryRequest, $cloudId: Long, $region: String) {
  getAwsDescribeSpotFleetRequestHistory(describeSpotFleetRequestHistoryRequest: $request, cloudId: $cloudId, region: $region) {
    result
    data {
      ... on AwsDescribeSpotFleetRequestHistoryResponse {
      historyRecords {
        eventInformation
        eventType
        timestamp
      }
        lastEvaluatedTime
        nextToken
        spotFleetRequestId
        startTime
        }
    }
  }
}
`;
export default query;
