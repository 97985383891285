import './index.scss';
import LikeIcon from 'assets/svgs/v3/ico_like.svg';
import DislikeIcon from 'assets/svgs/v3/ico_dislike.svg';
import CopyIcon from 'assets/svgs/v3/ico_copy.svg';

import Icon from 'components/v2/atoms/Icon';

interface IMessageProps {
  key: string,
  isLeft: boolean,
  message: string,
  liked: boolean,
  disLiked: boolean,
  dateTime: string,
  hasAction: boolean,
}

const Message = ({isLeft, message, liked, disLiked, dateTime, hasAction}: IMessageProps) => {

  return (
    <>
      <div className={isLeft ? 'left-message' : 'right-message'}>
        <div className="content">
          <p className="message-text">{message}</p>
        </div>
        <div className="tooltip">
          {hasAction && 
            <div className="action">
              <Icon width={14} height={14} src={CopyIcon} />
              <Icon width={14} height={14} src={LikeIcon} />
              <Icon width={14} height={14} src={DislikeIcon} />
            </div>
          }
          <p className="date-time-text">{dateTime}</p>
        </div>
      </div>
    </>
  );
};

export default Message;
