import React from 'react';
import { FIREWALL_POLICY_CREATION, MOCK_AWS_KMS_KEY_DETAILS } from '../configs';
import CheckboxAtom from 'components/v2/atoms/CheckboxAtom';
import { ConfigureAdvancedSettingsStepPropsType } from '../types';
import '../index.scss';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import TextareaAtom from 'components/v2/atoms/TextareaAtom';

const ConfigureAdvancedSettingsStep = (props: ConfigureAdvancedSettingsStepPropsType) => {
  const { onValueChanged, customizeEncryptionSettings, policyVariables } = props;

  return (
    <div>
      <div className="rule-group-container">
        <div className="title-group">
          <p className="title">{FIREWALL_POLICY_CREATION.CONFIGURE_ADVANCED_SETTINGS_TITLE}</p>
          <p className="sub-title">{FIREWALL_POLICY_CREATION.CONFIGURE_ADVANCED_SETTINGS_SUBTITLE}</p>
        </div>

        <div className="rule-group-details-container">
          <div
            className="rule-group-detail-title"
            style={{ gap: 8, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
          >
            {FIREWALL_POLICY_CREATION.CUSTOMER_MANAGED_KEY_TITLE}
            <span className="rule-group-detail-sub-title">
              {FIREWALL_POLICY_CREATION.CUSTOMER_MANAGED_KEY_SUBTITLE}
            </span>
          </div>

          <div
            className="rule-group-detail-title"
            style={{ gap: 8, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
          >
            <span className="rule-group-detail-sub-title">
              {FIREWALL_POLICY_CREATION.CUSTOMER_MANAGED_KEY_DESCRIPTION}
            </span>
            <label className="checkbox-labeled">
              <CheckboxAtom
                checked={customizeEncryptionSettings}
                onchange={() => onValueChanged('customizeEncryptionSettings', !customizeEncryptionSettings)}
              />
              {FIREWALL_POLICY_CREATION.CUSTOMIZE_ENCRYPTION_SETTINGS_CHECKBOX_LABEL}
            </label>
            {customizeEncryptionSettings && (
              <div style={{ width: '50%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <DropdownAtom
                  id="msg-use"
                  placeholder={FIREWALL_POLICY_CREATION.CHOOSE_A_KEY_PLACEHOLDER}
                  data={[]}
                  value={{ name: '', value: '' }}
                  handleClick={val => onValueChanged('statefulRuleDropAction', val.value)}
                />
                <button className="button" style={{ marginLeft: 16 }} onClick={() => {}}>
                  {FIREWALL_POLICY_CREATION.CHOOSE_A_KEY_REMOVE_BUTTON}
                </button>
              </div>
            )}
          </div>
          {customizeEncryptionSettings && (
            <>
              <div
                className="rule-group-detail-title"
                style={{ gap: 8, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
              >
                {FIREWALL_POLICY_CREATION.KEY_DETAILS_TITLE}
                <span className="rule-group-detail-sub-title">{FIREWALL_POLICY_CREATION.KEY_ARN_LABEL}</span>
                <span className="checkbox-labeled">{MOCK_AWS_KMS_KEY_DETAILS.KEY_ARN}</span>
              </div>
              <div
                className="rule-group-detail-title"
                style={{ gap: 8, display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
              >
                <div style={{ gap: 8, display: 'flex', flexDirection: 'column', justifyContent: 'center', flex: 1 }}>
                  <span className="rule-group-detail-sub-title">{FIREWALL_POLICY_CREATION.KEY_STATUS_LABEL}</span>
                  <span className="checkbox-labeled">{MOCK_AWS_KMS_KEY_DETAILS.KEY_STATUS}</span>
                </div>
                <div
                  style={{
                    gap: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    flex: 1,
                    borderLeft: '1px solid #E0E0E0',
                    paddingLeft: 24,
                  }}
                >
                  <span className="rule-group-detail-sub-title">{FIREWALL_POLICY_CREATION.KEY_ALIASES_LABEL}</span>
                  <span className="checkbox-labeled">{MOCK_AWS_KMS_KEY_DETAILS.KEY_ALIASES}</span>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="rule-group-container">
        <div className="rule-group-details-container">
          <div className="rule-group-detail-title" style={{ gap: 16, display: 'flex', flexDirection: 'row' }}>
            {FIREWALL_POLICY_CREATION.POLICY_VARIABLES_TITLE}
            <span className="rule-group-detail-sub-title">{FIREWALL_POLICY_CREATION.POLICY_VARIABLES_DESCRIPTION}</span>
          </div>

          <div className="rule-group-detail-title" style={{ gap: 16, display: 'flex', flexDirection: 'column' }}>
            <span className="checkbox-labeled">
              {FIREWALL_POLICY_CREATION.HOME_NET_VARIABLES_OVERRIDE_VALUES_TITLE}
            </span>
            <TextareaAtom
              placeholder={''}
              value={policyVariables}
              onChangeValue={value => onValueChanged('policyVariables', value)}
              disabled={false}
              noClear={true}
            />
            <span className="rule-group-detail-sub-title">
              {FIREWALL_POLICY_CREATION.HOME_NET_VARIABLES_OVERRIDE_VALUES_DESCRIPTION}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfigureAdvancedSettingsStep;
