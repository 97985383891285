import { gql } from '@apollo/client';

const query = gql`
query getAwsRegexPatternSet($request: AwsGetRegexPatternSetRequest, $cloudId: Long, $region: String) {
  getAwsRegexPatternSet(getRegexPatternSetRequest: $request, cloudId: $cloudId, region: $region) {
    result
     data{
      ... on AwsGetRegexPatternSetResponse {
						regexPatternSet {
              name
              id
              arn
              description
              regularExpressionList{
                regexString
              }
            }
        lockToken
          }
        }
      }
    }
`;
export default query;