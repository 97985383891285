import { gql } from '@apollo/client';

const query = gql`
  query getAwsPolicy($request: AwsGetPolicyRequest, $cloudId: Long, $region: String) {
    getAwsPolicy(getPolicyRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsGetPolicyResponse {
          policy {
            policyName
            policyId
            arn
            path
            defaultVersionId
            attachmentCount
            permissionsBoundaryUsageCount
            isAttachable
            description
            createDate
            updateDate
            tags {
              key
              value
            }
          }
        }
      }
    }
  }
`;
export default query;
