import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import InputAtom from 'components/v2/atoms/InputAtom';
import Table from 'components/v2/dataDisplay/Table';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';
import SearchIcon from 'assets/svgs/v3/ico_search.svg';
import { LB_TARGET_GROUP_COLUMN_LIST, LB_TARGET_GROUP_SEARCH_TYPES } from './config';
import { OrderDirection, RowType } from '@Types/v2/Table';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import { LBTargetGroupPropsType } from '../types';
import './LBTargetGroupListup.scss';
import { IMgdTotalPageProps } from 'layouts/v3/MgdLayout';
import lazyGetAwsDescribeTargetGroups, {
  IGetAwsDescribeTargetGroupsVariables,
} from 'graphql/queries/getAwsDescribeTargetGroups';
import lazyGetAwsLoadBalancers, { IGetAwsLoadBalancersVariables } from 'graphql/queries/getAwsLoadBalancers';
import { variableCombineNextToken } from '../../../Utils';
import { AwsTargetGroupRowType } from 'graphql/types/AwsDescribeTargetGroup';

const LBTargetGroup = (props: LBTargetGroupPropsType) => {
  const { cloudId, region, onTargetGroupSelected, onNameClicked } = props;

  const [getAwsLoadBalancers, { loading: awsLoadBalancersLoading }] = lazyGetAwsLoadBalancers();
  const [getAwsDescribeTargetGroups, { loading: awsDescribeTargetGroupLoading }] = lazyGetAwsDescribeTargetGroups();

  // State
  const [tablePagination, setTablePagination] = useState({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });
  const [mainTblTotal, setMainTblTotal] = useState<IMgdTotalPageProps>({
    totalPage: 0,
    totalElement: 0,
  });
  const [nextToken, setNextToken] = useState<string>('');
  const [searchValue, setSearchValue] = useState<{ name: string; value: string }>({ name: '', value: '' });
  const [searchType, setSearchType] = useState(LB_TARGET_GROUP_SEARCH_TYPES[0]);
  const [lbTargetGroups, setLbTargetGroups] = useState<AwsTargetGroupRowType[]>([]);
  const [filterValue, setFilterValue] = useState<{ name: string; value: string } | undefined>(undefined);

  const apiIsLoading = useMemo(() => {
    return awsLoadBalancersLoading || awsDescribeTargetGroupLoading;
  }, [awsLoadBalancersLoading, awsDescribeTargetGroupLoading]);

  const updateTablePagination = useCallback((key: string, value: any) => {
    setTablePagination(prevState => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  }, []);

  const awsTargetGroupVariable = useMemo((): IGetAwsDescribeTargetGroupsVariables => {
    return {
      cloudId: cloudId,
      region: region,
      request: {
        pageSize: tablePagination.limit,
      },
    };
  }, [cloudId, region, tablePagination]);

  const currentPageLbTargetGroupTableRows = useMemo((): RowType[] => {
    if (tablePagination.currentPage > mainTblTotal.totalPage && !!nextToken) {
      fetchDescribeTargetGroups(nextToken);
    }
    const firstItemIndex = (tablePagination.currentPage - 1) * tablePagination.itemPerPage;
    const lastItemIndex = tablePagination.currentPage * tablePagination.itemPerPage - 1;

    let allRows = orderAlphabetical(lbTargetGroups, tablePagination.target, tablePagination.direction);

    if (filterValue && filterValue?.name !== '' && filterValue?.value !== '') {
      allRows = allRows.filter(row => {
        if (Object.hasOwn(row, filterValue.name)) {
          let hasItem = false;
          if (filterValue.name === 'loadBalancers') {
            row[filterValue.name]?.map((lb: any) => {
              if (lb.loadBalancerName === filterValue.value) {
                hasItem = true;
              }
            });
          } else {
            if (row[filterValue.name] === filterValue.value) {
              hasItem = true;
            }
          }
          return hasItem;
        }
      });
    }

    setMainTblTotal({
      totalPage: Math.ceil(allRows.length / tablePagination.itemPerPage),
      totalElement: allRows.length,
    });

    return allRows?.filter((_, index) => index >= firstItemIndex && index <= lastItemIndex);
  }, [lbTargetGroups, tablePagination, filterValue, nextToken]);

  const handleSearch = useCallback(() => {
    updateTablePagination('currentPage', 1);
    if (searchValue.value.length === 0) {
      setFilterValue(undefined);
    } else {
      setFilterValue({
        name: searchType.value.toString(),
        value: searchValue.value,
      });
    }
  }, [searchType, searchValue]);

  const onHandleTargetGroupLBNameClicked = useCallback(
    (targetGroup: AwsTargetGroupRowType) => {
      onTargetGroupSelected(targetGroup);
      onNameClicked();
    },
    [],
  );

  const fetchDescribeTargetGroups = useCallback(
    (nextToken?: string, isInitial?: boolean) => {
      const combinedVariable = variableCombineNextToken(awsTargetGroupVariable, nextToken);
      getAwsDescribeTargetGroups({ variables: combinedVariable }).then(
        async ({ data: awsDescribeTargetGroupsData }) => {
          const awsDescribeTargetGroups = awsDescribeTargetGroupsData?.getAwsDescribeTargetGroups?.data?.[0];
          const targetGroupRows: AwsTargetGroupRowType[] = [];
          if (awsDescribeTargetGroups?.targetGroups) {
            await Promise.all(
              awsDescribeTargetGroups?.targetGroups.map(async targetGroup => {
                const lbVariable: IGetAwsLoadBalancersVariables = {
                  cloudId: cloudId,
                  region: region,
                  request: {
                    loadBalancerArns: targetGroup?.loadBalancerArns,
                  },
                };

                const loadBalancers = await getAwsLoadBalancers({ variables: lbVariable }).then(
                  ({ data: awsLoadBalancersData }) => {
                    return awsLoadBalancersData?.getAwsLoadBalancers?.data?.[0]?.loadBalancers;
                  },
                );
                const target: AwsTargetGroupRowType = {
                  id: targetGroup.targetGroupArn,
                  ...targetGroup,
                  loadBalancers: loadBalancers,
                };
                targetGroupRows.push({
                  ...target,
                  onTargetGroupLBNameClicked: () => onHandleTargetGroupLBNameClicked(target),
                });
              }),
            );
          }

          let allTargetGroup: AwsTargetGroupRowType[] = [];
          if (isInitial) {
            allTargetGroup = targetGroupRows;
          } else {
            allTargetGroup = [...lbTargetGroups, ...targetGroupRows];
          }
          setLbTargetGroups(allTargetGroup);
          setNextToken(awsDescribeTargetGroups?.nextMarker as string);
          setMainTblTotal({
            totalPage: Math.ceil(allTargetGroup.length / tablePagination.itemPerPage),
            totalElement: allTargetGroup.length,
          });
        },
      );
    },
    [awsTargetGroupVariable, nextToken, cloudId, region, lbTargetGroups, tablePagination, onHandleTargetGroupLBNameClicked],
  );

  // For initial data
  useEffect(() => {
    fetchDescribeTargetGroups('', true);
  }, [awsTargetGroupVariable]);

  return (
    <Fragment>
      <div className="row-3 flex j-between a-center">
        <div className="title">
          <p>Load balancer target groups</p>
        </div>
        <div className="flex action a-center">
          <DropdownAtom
            id="types-dropdown"
            className=""
            data={LB_TARGET_GROUP_SEARCH_TYPES}
            value={searchType}
            handleClick={val => {
              setSearchType(val);
            }}
          />
          <InputAtom
            type={'text'}
            value={searchValue?.value}
            defaultValue={''}
            onChangeValue={value => setSearchValue(prev => ({ name: prev.name, value }))}
            hasPrefixIcon={true}
            srcPrefixIcon={SearchIcon}
            prefixIconOnClick={() => {}}
          />
          <button className="action-btn" onClick={handleSearch}>
            Search
          </button>
        </div>
      </div>
      <div className="data-grid-wrap lb-target-group-listup">
        <Table
          rows={currentPageLbTargetGroupTableRows}
          columns={LB_TARGET_GROUP_COLUMN_LIST}
          reportCheckedList={() => {}}
          reportSelected={id => {
            onTargetGroupSelected(lbTargetGroups.find(item => item.targetGroupArn === id)!);
          }}
          sortOption={{
            target: tablePagination.target,
            direction: tablePagination.direction,
            onChangeSort: (target: string, dir: OrderDirection) => {
              updateTablePagination('target', target);
              updateTablePagination('direction', dir);
            },
          }}
          horizontalScrollable={true}
          isLoading={apiIsLoading}
        />
        {lbTargetGroups?.length > 0 && !apiIsLoading && (
          <div className="pagination-wrapper flex a-center">
            <p className="flex a-center">
              Total <span>{mainTblTotal.totalElement}</span>
            </p>
            <TableManagePagination
              ableFetchMore={false}
              currentPage={tablePagination.currentPage}
              updateCurrentPage={page =>
                setTablePagination({
                  ...tablePagination,
                  currentPage: page,
                })
              }
              totalPage={mainTblTotal.totalPage}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default LBTargetGroup;
