import { useLazyQuery } from '@apollo/client';
import query from './query';
import { AwsListSecretVersionIdsEntry } from 'graphql/types/AwsListSecretVersionIdsEntry';

export interface IAwsLisSecretVersionIdsVariables {
  cloudId: number;
  region: string;
  request: {
    secretId: string;
    maxResults?: number;
    nextToken?: string;
    includeDeprecated?: boolean
  }
}

export interface IAwsListSecretVersionIdsResponseData {
  getAwsListSecretVersionIds: AwsListSecretVersionIdsEntry;
}

const lazyGetAwsListSecretVersionIds = () => useLazyQuery<IAwsListSecretVersionIdsResponseData, IAwsLisSecretVersionIdsVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsListSecretVersionIds;