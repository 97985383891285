import './index.scss';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import NetworkTitle from 'components/v3/NetworkTitle';
import lazyGetAwsRuleGroup, { IGetAwsRuleGroupVariables } from 'graphql/queries/getAwsRuleGroup';
import CustomResponse from './CustomResponseLayout';
import InfoLayout from './InfoLayout';
import { CLOUDFRONT_OPTION, CLOUDFRONT_REGION, CONTENT_TYPE_MAPPING, WafScopeEnum, ruleGroupDetailTabs } from '../../Commons/Constant';
import { AwsRuleGroup } from 'graphql/types/AwsRuleGroup';
import { AwsRuleGroupSummary } from 'graphql/types/AwsListRuleGroup';
import EditRuleGroupInfoModal from '../UpdateRuleGroup/EditRuleGroupInfo';
import { RowType } from '@Types/v2/Table';
import updateAwsRuleGroupMutation, {
  AwsVisibilityConfiglog,
  IUpdateRuleGroupVariables,
} from 'graphql/mutations/updateRuleGroup';
import { useToast } from 'hooks/v2/useToast';
import { ErrorCode } from '@Types/error';
import _, { isNull } from 'lodash';
import DeleteRuleModal from '../UpdateRuleGroup/DeleteRule';
import DeleteResponseBodyModal from '../UpdateRuleGroup/DeleteResponseBody';
import CustomResponseModal from '../../CustomResponseModal';
interface IRuleGroupDetailProps {
  cloudId: number;
  region: string;
  currentRuleGroup: AwsRuleGroupSummary;
  pageBackClick: () => void;
}

export interface ITabProps {
  id: string;
  name: string;
  title: string;
  description?: string;
}

export interface ICustomeResponseBody {
  id: string;
  name: string;
  contentType: string;
  responseBody: string;
  cellLableContentType: string;
}

const RuleGroupDetail = (props: IRuleGroupDetailProps) => {
  const { cloudId, region, currentRuleGroup, pageBackClick } = props;

  const [getAwsRuleGroup] = lazyGetAwsRuleGroup();
  const [updateAwsRuleGroup] = updateAwsRuleGroupMutation();

  const [ruleGroupDetail, setRuleGroupDetail] = useState<AwsRuleGroup>();
  const [lockToken, setLockToken] = useState<string>('');
  const [activeTab, setActiveTab] = useState<ITabProps>(ruleGroupDetailTabs[0]);
  const [description, setDescription] = useState<string>(currentRuleGroup?.description ?? '');
  const [isEditRuleGroupInfoModalVisible, setEditRuleGroupInfoModalVisisble] = useState<boolean>(false);
  const [isDeleteRuleModalVisible, setDeleteRuleModalVisisble] = useState<boolean>(false);
  const [isEditRuleVisible, setEditRuleModalVisisble] = useState<boolean>(false);
  const [isEditResponseBodyVisible, setEditResponseBodyModalVisisble] = useState<boolean>(false);
  const [isDeleteResponseBodyVisible, setDeleteResponseBodyModalVisisble] = useState<boolean>(false);
  const [customResponseBodyRows, setCustomResponseBodyRows] = useState<ICustomeResponseBody[]>([]);
  const [responseBodyRowSelected, setResponseBodyRowSelected] = useState<string>('');

  const scope = useMemo((): string => {
    return region === CLOUDFRONT_OPTION.value ? WafScopeEnum.CLOUDFRONT : WafScopeEnum.REGIONAL;
  }, [region]);

  const regionValue = useMemo((): string => {
    return region === CLOUDFRONT_OPTION.value ? CLOUDFRONT_REGION : region;
  }, [region]);

  const handleActiveTabClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
    const tabTarget = e.currentTarget;
    if (!!tabTarget) {
      const tab: ITabProps = ruleGroupDetailTabs.find(tab => tab.id === tabTarget?.getAttribute('data-tab'))!;
      setActiveTab(tab);
    }
  }, []);

  const responseBodyRows = useMemo((): RowType[] => {
    const customResBodyMapped: ICustomeResponseBody[] = [];
    if (ruleGroupDetail?.customResponseBodies) {
      Object.entries(ruleGroupDetail?.customResponseBodies).map(([key, value]) => {
        const customeBody = {
          id: key,
          name: key,
          responseBody: value.content,
          contentType: value.contentType,
          cellLableContentType: CONTENT_TYPE_MAPPING[value.contentType].cellLable,
        };
        customResBodyMapped.push(customeBody);
      });
      setCustomResponseBodyRows(customResBodyMapped);
    }
    return customResBodyMapped;
  }, [ruleGroupDetail]);

  const responseBodySelected = useMemo((): ICustomeResponseBody => {
    return customResponseBodyRows.find(row => row.id === responseBodyRowSelected)!;
  }, [customResponseBodyRows, responseBodyRowSelected]);

  const renderShowing = useMemo(() => {
    if (ruleGroupDetail) {
      switch (activeTab) {
        default:
          return (
            <InfoLayout
              ruleGroup={ruleGroupDetail}
              onEditRuleGroupInfo={() => setEditRuleGroupInfoModalVisisble(true)} //TODO: need BE update API
              onEditRule={ruleId => setEditRuleModalVisisble(true)} //TODO: waiting for Rule builder
              onAddRule={() => {}} //TODO: waiting for Rule builder
              onDeleteRule={ruleId => setDeleteRuleModalVisisble(true)}
            />
          );
        case ruleGroupDetailTabs[1]:
          return (
            <CustomResponse
              customResponseBodyRows={responseBodyRows}
              onSelectedCustomReponseBody={setResponseBodyRowSelected}
              onEditResponseBody={() => setEditResponseBodyModalVisisble(true)}
              onDeleteResponseBody={() => setDeleteResponseBodyModalVisisble(true)}
              onCreateResponseBody={() => {}} //TODO: created at mgd-update/delete-waf (not integrate API yet)
            />
          );
      }
    }
  }, [activeTab, ruleGroupDetailTabs, ruleGroupDetail, responseBodyRows]);

  const omitDeep = (collection: Object, excludeKeys: string[]) => {
    const omitFunc = (value: string) => {
      if (value && typeof value === 'object') {
        excludeKeys.forEach((key: string) => {
          delete value[key];
        });
      }
    };
    return _.cloneDeepWith(collection, omitFunc);
  };

  const getRuleGroup = useCallback(() => {
    const reqVariable: IGetAwsRuleGroupVariables = {
      cloudId: cloudId,
      region: regionValue,
      request: {
        id: currentRuleGroup.id,
        name: currentRuleGroup.name,
        scope: scope,
      },
    };
    getAwsRuleGroup({ variables: reqVariable }).then(({ data: awsRuleGroupResponse }) => {
      const ruleGroupData = awsRuleGroupResponse?.getAwsRuleGroup?.data?.[0];
      if (ruleGroupData) {
        const ruleGroup = ruleGroupData?.ruleGroup;
        setLockToken(ruleGroupData?.lockToken);
        if (ruleGroup) {
          setRuleGroupDetail(ruleGroup);
          setDescription(ruleGroup?.description);
        }
      }
    });
  }, [cloudId, regionValue, scope, currentRuleGroup]);

  const executeUpdateAwsRuleGroup = useCallback(
    (reqVariable: IUpdateRuleGroupVariables, successMessage: string, failMessage: string) => {
      updateAwsRuleGroup({ variables: reqVariable }).then(({ data: awsRuleGroupResponse }) => {
        const nextLockToken = awsRuleGroupResponse?.updateAwsRuleGroup?.data?.[0]?.nextLockToken;
        if (nextLockToken) {
          getRuleGroup();
          useToast(ErrorCode.SUCCESS, successMessage);
          setEditRuleGroupInfoModalVisisble(false);
        } else {
          useToast(ErrorCode.UNKNOWN, failMessage);
        }
      });
    },
    [getRuleGroup],
  );

  const handleEditRuleGroupSave = useCallback(
    (descriptionUpdated: string) => {
      if (ruleGroupDetail) {
        const reqVariable: IUpdateRuleGroupVariables = {
          cloudId: cloudId,
          region: regionValue,
          reqData: {
            id: ruleGroupDetail?.id,
            name: ruleGroupDetail?.name,
            scope: scope,
            lockToken: lockToken,
            description: descriptionUpdated,
            // rules: _.values(ruleGroupDetail?.rules?.map(rule => _.omitBy(rule, _.isNil)))?.map(rule =>
            //   _.omit(rule, '__typename'),
            // ) as AwsRule[],
            // rules: _.values(ruleGroupDetail?.rules?.map(rule => _.omit(rule, '__typename'))),
            rules: omitDeep(ruleGroupDetail?.rules, ['__typename']),
            visibilityConfig: _.omit(ruleGroupDetail?.visibilityConfig, '__typename'),
          },
        };
        executeUpdateAwsRuleGroup(reqVariable, 'Edit rule group info successful.', 'Edit rule group info failed.');
      }
    },
    [ruleGroupDetail, executeUpdateAwsRuleGroup, cloudId, ruleGroupDetail, scope, lockToken, omitDeep],
  );

  useEffect(() => {
    getRuleGroup();
  }, []);

  return (
    <Fragment>
      <div id="rule-group-detail">
        <div className="row-1 flex j-between a-center">
          <div className="flex j-start a-center" id="title">
            <NetworkTitle
              pageTitle={'waf rule groups: ' + currentRuleGroup.name}
              id={321}
              name={'waf rule groups: ' + currentRuleGroup.name}
              hasPrefixIcon={false}
              hasFavorite={false}
              pageBackClick={pageBackClick}
            />
          </div>
        </div>
        <div className="row-2 flex a-center">
          {ruleGroupDetailTabs.map(tab => {
            return (
              <button
                className={`tab-items ${activeTab?.id === tab.id && 'active'}`}
                key={tab.id}
                data-tab={tab.id}
                onClick={e => handleActiveTabClick(e)}
              >
                <p>{tab.name}</p>
              </button>
            );
          })}
        </div>
        {renderShowing}
      </div>
      {isEditRuleGroupInfoModalVisible ? (
        <EditRuleGroupInfoModal
          open={isEditRuleGroupInfoModalVisible}
          description={description}
          onClose={() => setEditRuleGroupInfoModalVisisble(false)}
          onSave={description => handleEditRuleGroupSave(description)}
        />
      ) : null}
      {isDeleteRuleModalVisible ? (
        <DeleteRuleModal
          resourceName={'RULE-NAME'}
          open={isDeleteRuleModalVisible}
          onClose={() => setDeleteRuleModalVisisble(false)}
          onDelete={() => {}}
        />
      ) : null}

      {isEditResponseBodyVisible ? (
        <CustomResponseModal
          header={`Edit ${responseBodySelected.name}`}
          name={responseBodySelected.name}
          contentType={responseBodySelected.contentType}
          responseBody={responseBodySelected.responseBody}
          open={isEditResponseBodyVisible}
          onClose={() => setEditResponseBodyModalVisisble(false)}
          onSave={() => {}}
        />
      ) : null}
      {isDeleteResponseBodyVisible ? (
        <DeleteResponseBodyModal
          resourceName={responseBodySelected?.name}
          open={isDeleteResponseBodyVisible}
          onClose={() => setDeleteResponseBodyModalVisisble(false)}
          onDelete={() => {}}
        />
      ) : null}
    </Fragment>
  );
};
export default RuleGroupDetail;
