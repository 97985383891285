import React, { useCallback, useMemo, useState } from 'react';
import InputAtom from 'components/v2/atoms/InputAtom';
import {
  DEFAULT_REQUEST_AGGREGATION_KEY_ITEM,
  DEFAULT_STATEMENT_ITEM,
  RULE_BUILDER_REQUEST_CONDITION_DATA,
  RULE_SUB_TYPE_SELECTOR,
  RULE_TYPE_DATA,
  RuleSubTypeSelectorEnum,
} from 'pages/v2/Organ/Management/WAF/CustomRuleModal/constant';
import RadioAtom from 'components/v2/atoms/RadioAtom';
import Regular from 'pages/v2/Organ/Management/WAF/CustomRuleModal/RuleBuilder/VisualEditor/Regular';
import RateBased from 'pages/v2/Organ/Management/WAF/CustomRuleModal/RuleBuilder/VisualEditor/RateBased';
import { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import { AwsRuleLog } from 'graphql/types/AwsDescribeRulesResponse';
import { AwsRuleGroup } from 'graphql/types/AwsRuleGroup';
import { EVALUATION_WINDOW, INSPECTION_AND_RATE_LIMIT, REQUEST_AGGREGATION } from './RateBased/constant';

type VisualPropsType = {
  cloudId: number;
  selectedRegion: DropdownListDataType;
  dropdownConditionRequestValue: DropdownListDataType;
  seconds: string;
  isCustomImmunityTime: boolean;
  setDropdownConditionRequestValue: (value: any) => void;
  setSeconds: (value: any) => void;
  setIsCustomImmunityTime: (value: any) => void;
  inspectionAndRateLimit: string;
  requestAggregationKeyData: any;
  setInspectionAndRateLimit: (value: any) => void;
  setRequestAggregationKeyData: (value: any) => void;
  rule: any;
  handleChangeRuleValue: (path: string, value: any) => void;
};

function Visual(props: VisualPropsType) {
  const {
    cloudId,
    selectedRegion,
    rule,
    handleChangeRuleValue,
    seconds,
    setSeconds,
    isCustomImmunityTime,
    setIsCustomImmunityTime,
    requestAggregationKeyData,
    setRequestAggregationKeyData,
  } = props;

  const ruleSubType = useMemo(() => {
    if (rule?.statement?.hasOwnProperty('rateBasedStatement')) {
      return RuleSubTypeSelectorEnum.RATE_BASED;
    }

    return RuleSubTypeSelectorEnum.REGULAR;
  }, [rule?.statement]);

  const setRuleSubType = useCallback(
    (type: string) => {
      if (type === RuleSubTypeSelectorEnum.REGULAR) {
        handleChangeRuleValue('statement', {});
      }

      if (type === RuleSubTypeSelectorEnum.RATE_BASED) {
        handleChangeRuleValue('statement', { rateBasedStatement: {} });
      }
    },
    [handleChangeRuleValue],
  );

  const isEnableBlockCustomResponse = useMemo(() => {
    if (rule?.action?.hasOwnProperty('block') && rule?.action?.block?.hasOwnProperty('customResponse')) {
      return true;
    }

    return false;
  }, [rule]);

  const setEnableBlockCustomResponse = useCallback(
    (isEnable: boolean) => {
      if (isEnable) {
        handleChangeRuleValue('action', { block: { customResponse: {} } });
        return;
      }

      handleChangeRuleValue('action', { block: {} });
    },
    [handleChangeRuleValue],
  );

  const customHeaderList = useMemo(() => {
    if (
      rule?.action?.hasOwnProperty('block') &&
      rule?.action?.block?.hasOwnProperty('customResponse') &&
      rule?.action?.block?.customResponse?.hasOwnProperty('responseHeaders')
    ) {
      return rule?.action?.block?.customResponse?.responseHeaders;
    }

    return [];
  }, [rule]);

  const labelList = useMemo(() => {
    if (rule?.hasOwnProperty('ruleLabels')) {
      return rule?.ruleLabels;
    }

    return [];
  }, [rule]);

  const setCustomHeaderList = useCallback(
    (list: any) => {
      handleChangeRuleValue('action', { block: { customResponse: { responseHeaders: list } } });
    },
    [handleChangeRuleValue],
  );

  const setLabelList = useCallback(
    (list: any) => {
      handleChangeRuleValue('ruleLabels', list);
    },
    [handleChangeRuleValue],
  );

  const setAction = useCallback(
    (value: string) => {
      const newAction = {
        [value]: {},
      };

      handleChangeRuleValue('action', newAction);
    },
    [handleChangeRuleValue],
  );

  const action = useMemo(() => {
    return Object.keys(rule.action)[0];
  }, [rule]);

  const handleSelectAction = useCallback((value: string) => {
    setAction(value);
    setSeconds('300');
    setIsCustomImmunityTime(false);
    setEnableBlockCustomResponse(false);
    setCustomHeaderList([]);
  }, []);

  const rateLimit = useMemo(() => {
    if (
      rule?.statement?.hasOwnProperty('rateBasedStatement') &&
      rule?.statement?.rateBasedStatement?.hasOwnProperty('limit')
    ) {
      return rule?.statement?.rateBasedStatement?.limit;
    }

    return '';
  }, [rule]);

  const setRateLimit = useCallback(
    (limit: number) => {
      handleChangeRuleValue('statement.rateBasedStatement.limit', limit);
    },
    [handleChangeRuleValue],
  );

  const evaluationWindowSec = useMemo(() => {
    if (
      rule?.statement?.hasOwnProperty('rateBasedStatement') &&
      rule?.statement?.rateBasedStatement?.hasOwnProperty('evaluationWindowSec')
    ) {
      return (
        EVALUATION_WINDOW.find(item => item?.value === rule?.statement?.rateBasedStatement?.evaluationWindowSec) ??
        EVALUATION_WINDOW[0]
      );
    }

    return EVALUATION_WINDOW[0];
  }, [rule]);

  const setEvaluationWindowSec = useCallback(
    (item: DropdownListDataType) => {
      handleChangeRuleValue('statement.rateBasedStatement.evaluationWindowSec', item.value);
    },
    [handleChangeRuleValue],
  );

  const requestAggregationKeyType = useMemo(() => {
    if (
      rule?.statement?.hasOwnProperty('rateBasedStatement') &&
      rule?.statement?.rateBasedStatement?.hasOwnProperty('aggregateKeyType')
    ) {
      return rule?.statement?.rateBasedStatement?.aggregateKeyType ?? REQUEST_AGGREGATION[0].value;
    }

    return REQUEST_AGGREGATION[0].value;
  }, [rule]);

  const setRequestAggregationKeyType = useCallback(
    (type: string) => {
      handleChangeRuleValue('statement.rateBasedStatement.aggregateKeyType', type);
    },
    [handleChangeRuleValue],
  );

  const headerFieldName = useMemo(() => {
    if (
      rule?.statement?.hasOwnProperty('rateBasedStatement') &&
      rule?.statement?.rateBasedStatement?.hasOwnProperty('forwardedIPConfig') &&
      rule?.statement?.rateBasedStatement?.forwardedIPConfig?.hasOwnProperty('headerName')
    ) {
      return rule?.statement?.rateBasedStatement?.forwardedIPConfig?.headerName;
    }

    return '';
  }, [rule]);

  const setHeaderFieldName = useCallback(
    (headerName: string) => {
      handleChangeRuleValue('statement.rateBasedStatement.forwardedIPConfig.headerName', headerName);
    },
    [handleChangeRuleValue],
  );

  const fallbackBehavior = useMemo(() => {
    if (
      rule?.statement?.hasOwnProperty('rateBasedStatement') &&
      rule?.statement?.rateBasedStatement?.hasOwnProperty('forwardedIPConfig') &&
      rule?.statement?.rateBasedStatement?.forwardedIPConfig?.hasOwnProperty('fallbackBehavior')
    ) {
      return rule?.statement?.rateBasedStatement?.forwardedIPConfig?.fallbackBehavior;
    }

    return '';
  }, [rule]);

  const setFallbackBehavior = useCallback(
    (isEnable: boolean) => {
      if (isEnable) {
        handleChangeRuleValue('statement.rateBasedStatement.scopeDownStatement', {});
        return;
      }

      handleChangeRuleValue('statement.rateBasedStatement.scopeDownStatement', undefined);
    },
    [handleChangeRuleValue],
  );

  const scopeOfInspectionAndRateLimiting = useMemo(() => {
    if (rule?.statement?.rateBasedStatement?.scopeDownStatement) {
      return INSPECTION_AND_RATE_LIMIT[1].value;
    }

    return INSPECTION_AND_RATE_LIMIT[0].value;
  }, [rule]);

  const setScopeOfInspectionAndRateLimiting = useCallback(
    (value: string) => {
      if (value === INSPECTION_AND_RATE_LIMIT[0].value) {
        handleChangeRuleValue('statement.rateBasedStatement.scopeDownStatement', undefined);
        return;
      }

      handleChangeRuleValue('statement.rateBasedStatement', { scopeDownStatement: {} });
    },
    [handleChangeRuleValue],
  );

  const dropdownConditionRequestValue = useMemo(() => {
    const regularStatement = rule?.statement;
    const rateBasedStatement = rule?.rateBasedStatement?.scopeDownStatement;

    switch (true) {
      case !!regularStatement?.andStatement || !!rateBasedStatement?.andStatement:
        return RULE_BUILDER_REQUEST_CONDITION_DATA[1];

      case !!regularStatement?.orStatement || !!rateBasedStatement?.orStatement:
        return RULE_BUILDER_REQUEST_CONDITION_DATA[2];

      case !!regularStatement?.notStatement || !!rateBasedStatement?.notStatement:
        return RULE_BUILDER_REQUEST_CONDITION_DATA[3];

      default:
        return RULE_BUILDER_REQUEST_CONDITION_DATA[0];
    }
  }, [rule]);

  const setDropdownConditionRequestValue = useCallback(
    (item: DropdownListDataType) => {
      let statement = {};
      switch (item.value) {
        default:
        case RULE_BUILDER_REQUEST_CONDITION_DATA[0].value:
          statement = {};
          break;

        case RULE_BUILDER_REQUEST_CONDITION_DATA[1].value:
          statement = {
            andStatement: {},
          };
          break;

        case RULE_BUILDER_REQUEST_CONDITION_DATA[2].value:
          statement = {
            orStatement: {},
          };
          break;

        case RULE_BUILDER_REQUEST_CONDITION_DATA[3].value:
          statement = {
            notStatement: {},
          };
          break;
      }

      if (ruleSubType === RuleSubTypeSelectorEnum.REGULAR) {
        handleChangeRuleValue('statement', statement);
        return;
      }

      handleChangeRuleValue('statement.rateBasedStatement.scopeDownStatement', statement);
    },
    [handleChangeRuleValue],
  );

  const statementData = useMemo(() => {
    return [DEFAULT_STATEMENT_ITEM];
  }, [rule]);

  const setStatementData = useCallback(() => {}, []);

  const renderElement = useMemo(() => {
    switch (ruleSubType) {
      case RULE_SUB_TYPE_SELECTOR[0].value:
        return (
          <Regular
            cloudId={cloudId}
            selectedRegion={selectedRegion}
            dropdownConditionRequestValue={dropdownConditionRequestValue}
            seconds={seconds}
            isCustomImmunityTime={isCustomImmunityTime}
            isEnable={isEnableBlockCustomResponse}
            customHeaderList={customHeaderList}
            anotherLabelList={labelList}
            setDropdownConditionRequestValue={setDropdownConditionRequestValue}
            setSeconds={setSeconds}
            setIsCustomImmunityTime={setIsCustomImmunityTime}
            setIsEnable={setEnableBlockCustomResponse}
            setCustomHeaderList={setCustomHeaderList}
            setAnotherLabelList={setLabelList}
            setAction={setAction}
            handleSelectAction={handleSelectAction}
            statementData={statementData}
            action={action}
            setStatementData={setStatementData}
          />
        );

      case RULE_SUB_TYPE_SELECTOR[1].value:
        return (
          <RateBased
            cloudId={cloudId}
            selectedRegion={selectedRegion}
            dropdownConditionRequestValue={dropdownConditionRequestValue}
            seconds={seconds}
            isCustomImmunityTime={isCustomImmunityTime}
            isEnable={isEnableBlockCustomResponse}
            customHeaderList={customHeaderList}
            anotherLabelList={customHeaderList}
            setDropdownConditionRequestValue={setDropdownConditionRequestValue}
            setSeconds={setSeconds}
            setIsCustomImmunityTime={setIsCustomImmunityTime}
            setIsEnable={setEnableBlockCustomResponse}
            setCustomHeaderList={setCustomHeaderList}
            setAnotherLabelList={setLabelList}
            setAction={setAction}
            handleSelectAction={handleSelectAction}
            rateLimit={rateLimit}
            evaluationWindow={evaluationWindowSec}
            requestAggregation={requestAggregationKeyType}
            inspectionAndRateLimit={scopeOfInspectionAndRateLimiting}
            headerFieldName={headerFieldName}
            positionInsideHeader={{
              name: 'First IP address',
              value: 'First IP address',
            }}
            missingIpAddress={fallbackBehavior}
            requestAggregationKeyData={requestAggregationKeyData}
            setRateLimit={setRateLimit}
            setEvaluationWindow={setEvaluationWindowSec}
            setRequestAggregation={setRequestAggregationKeyType}
            setInspectionAndRateLimit={setScopeOfInspectionAndRateLimiting}
            setHeaderFieldName={setHeaderFieldName}
            setPositionInsideHeader={() => {}}
            setMissingIpAddress={setFallbackBehavior}
            setRequestAggregationKeyData={setRequestAggregationKeyData}
            statementData={statementData}
            action={action}
            setStatementData={setStatementData}
          />
        );

      default:
        return null;
    }
  }, [
    ruleSubType,
    dropdownConditionRequestValue,
    seconds,
    isCustomImmunityTime,
    isEnableBlockCustomResponse,
    customHeaderList,
    labelList,
    rateLimit,
    evaluationWindowSec,
    requestAggregationKeyType,
    headerFieldName,
    fallbackBehavior,
    requestAggregationKeyData,
    statementData,
    action,
    handleChangeRuleValue,
    setDropdownConditionRequestValue,
    setStatementData,
  ]);

  const ruleName = useMemo(() => {
    if (rule?.hasOwnProperty('name')) {
      return rule?.name;
    }

    return '';
  }, [rule]);

  const setRuleName = useCallback(
    (value: string) => {
      handleChangeRuleValue('name', value);
    },
    [handleChangeRuleValue],
  );

  return (
    <>
      <div className="rule-container-group rule-container-group-border">
        <div className="rule-container-group-title">
          <p>Rule</p>

          <button className="btn-default">Validate</button>
        </div>

        <div className="rule-container-group-content">
          <div className="input-group">
            <p className="input-group-label">Name</p>

            <div className="input-container">
              <InputAtom
                placeholder={''}
                onChangeValue={val => {
                  // setRuleName(val);
                  if (val.length <= 128) {
                    setRuleName(val);
                  }
                }}
                value={rule?.name}
                required={true}
                noClear={true}
              />

              <p className="text-note">
                The name must have 1-128 characters. Valid characters: A-Z, a-z, 0-9, -(hyphen), and _(underscore).
              </p>
            </div>
          </div>

          <div>
            <p className="rule-container-group-content-label">Type</p>

            <div className="space-8" />

            {RULE_SUB_TYPE_SELECTOR.map(({ id, label, value, name, description }) => (
              <RadioAtom
                key={id}
                label={label}
                value={value}
                name={name}
                description={description}
                checked={ruleSubType}
                onChange={type => setRuleSubType(type as RuleSubTypeSelectorEnum)}
              />
            ))}
          </div>
        </div>
      </div>

      {renderElement}
    </>
  );
}

export default Visual;
