import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsFilter } from 'graphql/types/AwsListLoggingConfigurations';
import { AwsDescribeKeyPairsResponse } from 'graphql/types/AwsDescribeKeyPairsResponse';

export interface IAwsDescribeKeyPairsVariables {
  cloudId: number;
  region: string;
  request: {
    filters?: {
      name: string;
      values: string[];
    }[];
    dryRun?: boolean;
    keyNames?: string[];
    keyPairIds?: string[];
    includePublicKey?: boolean;
  };
}

export interface IAwsDescribeKeyPairsResponseData {
  getAwsDescribeKeyPairs: IGqlResponseNewData<AwsDescribeKeyPairsResponse>;
}

const lazyGetAwsDescribeKeyPairs = () =>
  useLazyQuery<IAwsDescribeKeyPairsResponseData, IAwsDescribeKeyPairsVariables>(query, {
    fetchPolicy: 'no-cache',
  });
export default lazyGetAwsDescribeKeyPairs;
