import { useEffect, useMemo } from 'react';
import ArrowIcon from 'assets/svgs/v2/ico_dropdown_arrow.svg';
import './index.scss';
import classNames from 'classnames';

type ValueType = string | number;

export type ActionDropdownListDataType = {
  id?: number;
  name?: string;
  description?: string;
  value: ValueType;
  disabled?: boolean;
}

interface IActionDropdownAtomProps {
  id: string;
  data: ActionDropdownListDataType[];
  value: ActionDropdownListDataType;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  error?: boolean;
  handleClick: (val: ActionDropdownListDataType) => void;
}

const ActionDropdownAtom = ({
  id,
  data,
  value,
  className,
  placeholder,
  disabled = false,
  error,
  handleClick
}:IActionDropdownAtomProps) => {

  const openMenu = (parentId: string) => {
    Array.from(document.getElementsByClassName(`${id}`)).forEach(el => {
      const parentEl = el.parentElement;
      if (parentEl?.id === parentId) {
        if (el.classList.contains('none')) {
          el.classList.remove('none');
        } else {
          el.classList.add('none');
        }
      } else {
        el.classList.add('none');
      }
    });
  };

  const closeAll = () => {
    Array.from(document.getElementsByClassName('action-dropdown-list')).forEach(el => el.classList.add('none'));
  };

  useEffect(() => {
    document.addEventListener('click', closeAll);

    return () => {
      document.removeEventListener('click', closeAll);
    };
  },[]);

  return (
    <div className={classNames('action-dropdown', className)} id={`${id}-container-wrap`}>
      <li id={`${id}-container-wrap`}>
        <button 
          type="button" 
          id={id}
          disabled={disabled}
          className={classNames(error && 'error')}
          onClick={(e) => {
            e.stopPropagation();
            openMenu(`${id}-container-wrap`);
          }}
        >
          <input readOnly placeholder={placeholder} value={value.name} disabled={disabled} />
          <img src={ArrowIcon} width={24} height={24} />
        </button>
        {!disabled &&
          <div className={`action-dropdown-list ${id} none`}>
            <ul>
              {data.map((d, idx) => 
                <li
                  key={`${idx}-${d.value}`}
                  className={`list-item ${d.disabled == true ? 'disabled' : ''} ${idx == data.length - 1 ? 'last' : ''}`}
                  onClick={(e) => {
                    if (d.disabled != true) {
                      e.stopPropagation();
                      handleClick(d);
                      e.currentTarget.parentElement?.parentElement?.classList.add('none');
                    }
                  }}
                >{d.name}
                {d.description && <pre>{d.description}</pre>}
                </li>
              )}
            </ul>
          </div>
        }
      </li>
    </div>
  );
};
export default ActionDropdownAtom;