import { gql } from '@apollo/client';

const query = gql`
query getAwsSecurityGroups($request: AwsDescribeSecurityGroupsRequest, $cloudId: Long, $region: String) {
  getAwsSecurityGroups(describeSecurityGroupsRequest: $request, cloudId: $cloudId, region: $region) {
    result
    data {
      ... on AwsDescribeSecurityGroupsResponse {
          securityGroups{
            description
            groupName
            ipPermissions {
              fromPort
              ipProtocol
              ipRanges {
                cidrIp
                description
              }
              ipv6Ranges {
                cidrIpv6
                description
              }
              prefixListIds {
                prefixListId
                description
              }
              toPort
            }
            ownerId
            groupId
            ipPermissionsEgress {
              fromPort
              ipProtocol
              ipRanges {
                cidrIp
                description
              }
              ipv6Ranges {
                cidrIpv6
                description
              }
              prefixListIds {
                prefixListId
                description
              }
              toPort
            }
            tags {
              key
              value
            }
            vpcId
          }
          nextToken
        }
      }
    }
  }
`;
export default query;