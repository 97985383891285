import { gql } from '@apollo/client';

const query = gql`
query getAwsListSecretVersionIds($request: AwsListSecretVersionIdsRequest, $cloudId: Long, $region: String) {
  getAwsListSecretVersionIds(listSecretVersionIdsRequest: $request, cloudId: $cloudId, region: $region) {
    result
    nextToken
     data{
      ... on AwsListSecretVersionIdsResponse {
       versions{
        versionId
        versionStages
        lastAccessedDate
        createdDate
        kmsKeyIds
      }
        arn
        name
        }
      }
    }
  }`;
export default query;