import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import './Listeners.scss';
import { AwsLoadBalancer } from 'graphql/types/AwsLoadBalancer';
import lazyGetAwsDescribeListeners, {
  IGetAwsDescribeListenersVariables,
} from 'graphql/queries/getAwsDescribeListeners';
import { IMgdTablePaginationProps } from 'layouts/v3/MgdLayout';
import { AwsAction, AwsListener } from 'graphql/types/AwsDescribeListenersResponse';
import lazyGetAwsDescribeRules, { IGetAwsDescribeRulesVariables } from 'graphql/queries/getAwsDescribeRules';
import lazyGetAwsDescribeTags, { IGetAwsDescribeTagsVariables } from 'graphql/queries/getAwsDescribeTags';
import DetailTable from '../../DetailTable';
import { handleFormatText } from 'utils/Common';

export type ListenersPropsType = {
  cloudId: number;
  region: string;
  data?: AwsLoadBalancer;
};

const Listeners = (props: ListenersPropsType) => {
  const { data, cloudId, region } = props;
  const [tablePagination, setTablePagination] = useState<IMgdTablePaginationProps>({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });
  const [rows, setRows] = useState<RowType[]>([]);
  const [getAwsDescribeListeners, { loading: awsDescribeListenersLoading }] = lazyGetAwsDescribeListeners();
  const [getAwsDescribeRules, { loading: awsDescribeRulesLoading }] = lazyGetAwsDescribeRules();
  const [getAwsDescribeTags, { loading: awsDescribeTagsLoading }] = lazyGetAwsDescribeTags();

  const handleHyperLinkClick = useCallback((row: RowType) => {}, []);

  const apiIsLoading = useMemo(() => {
    return awsDescribeListenersLoading || awsDescribeRulesLoading || awsDescribeTagsLoading;
  }, [awsDescribeListenersLoading, awsDescribeRulesLoading, awsDescribeTagsLoading]);

  const renderDefaultActionColumnNode = useCallback((listener: RowType) => {
    return listener.defaultActions.map((defaultAction: AwsAction, index: number) => {
      const type = defaultAction.type;

      switch (type) {
        case 'fixed-response':
          return (
            <Fragment key={`load-balancer-listener-tab-${type}-${index}`}>
              <p>Return fixed response</p>
              <ul>
                <li>Response code: {defaultAction.fixedResponseConfig.statusCode}</li>
                <li>
                  Response body
                  {defaultAction.fixedResponseConfig.messageBody
                    ? `: ${defaultAction.fixedResponseConfig.messageBody}`
                    : ''}
                </li>
                <li>Response content type: {defaultAction.fixedResponseConfig.contentType}</li>
              </ul>
            </Fragment>
          );
        case 'redirect':
          const { protocol, host, port, path, query, statusCode } = defaultAction?.redirectConfig;
          return (
            <Fragment key={`load-balancer-listener-tab-${type}-${index}`}>
              <p>{`Redirect to ${protocol}://${host}:${port}${path}?${query}`}</p>
              <ul>
                <li>Status code: {statusCode}</li>
              </ul>
            </Fragment>
          );
        case 'forward':
          return (
            <Fragment key={`load-balancer-listener-tab-${type}-${index}`}>
              <p>Forward to target group</p>
              <ul>
                <li>
                  <span className="link">{`${defaultAction?.forwardConfig?.targetGroups?.[0]?.targetGroupArn}`}</span>
                  {`: ${defaultAction?.forwardConfig?.targetGroups?.[0]?.weight} (100%)`}
                </li>
                <li>
                  Target group stickiness:{' '}
                  {defaultAction?.forwardConfig?.targetGroupStickinessConfig?.enabled === false ? 'Off' : 'On'}
                </li>
              </ul>
            </Fragment>
          );
        // TODO: AUTHENTICATE_COGNITO | AUTHENTICATE_OIDC | FIXED_RESPONSE | FORWARD | REDIRECT
        default:
          return <></>;
      }
    });
  }, []);

  const columns = useMemo((): ColumnType[] => {
    return [
      { label: 'Protocol:Port', field: 'protocolPort', sort: true },
      {
        label: 'Default action',
        field: 'defaultAction',
        sort: true,
        width: 300,
        renderCell: (row: RowType) => renderDefaultActionColumnNode(row),
      },
      {
        label: 'Rules',
        field: 'ruleCount',
        sort: true,
        renderCell: (row: RowType) => (
          <>
            {
              <div
                className="link"
                onClick={e => {
                  e.stopPropagation();
                  handleHyperLinkClick(row);
                }}
              >
                {handleFormatText(row.ruleCount)}
              </div>
            }
          </>
        ),
      },
      {
        label: 'ARN',
        field: 'listenerArn',
        sort: true,
        renderCell: (row: RowType) => <p>ARN</p>,
      },
      { label: 'Security policy', field: 'securitypolicy', sort: true },
      { label: 'Default SSL/TLS certificate', field: 'sslTlsCertificate', sort: true, width: 200 },
      { label: 'mTLS', field: 'mTLS', sort: true },
      { label: 'Trust store', field: 'trustStore', sort: true },
      { label: 'Trust store', field: 'trustStore', sort: true },
      { label: 'ALPN Policy', field: 'alpnPolicy', sort: true },
      { label: 'Tags', field: 'tagCount', sort: true },
    ];
  }, []);

  const fetchAwsDescribeListeners = useCallback(() => {
    const listenerVariable: IGetAwsDescribeListenersVariables = {
      cloudId: cloudId,
      region: region,
      request: {
        loadBalancerArn: data?.loadBalancerArn || '',
      },
    };
    getAwsDescribeListeners({ variables: listenerVariable }).then(({ data: awsDescribeListeners }) => {
      const awsDescribeListenersData = awsDescribeListeners?.getAwsDescribeListeners?.data?.[0]?.listeners;
      if (awsDescribeListenersData) {
        const allRows: RowType[] = [];
        awsDescribeListenersData?.map((listener: AwsListener) => {
          const rulesVariable: IGetAwsDescribeRulesVariables = {
            cloudId: cloudId,
            region: region,
            request: {
              listenerArn: listener.listenerArn,
            },
          };

          getAwsDescribeRules({ variables: rulesVariable }).then(({ data: awsDescribeRules }) => {
            const awsDescribeRulesData = awsDescribeRules?.getAwsDescribeRules?.data?.[0]?.rules;
            if (awsDescribeRulesData) {
              const tagsVariable: IGetAwsDescribeTagsVariables = {
                cloudId: cloudId,
                region: region,
                request: {
                  resourceArns: [listener.listenerArn],
                },
              };

              getAwsDescribeTags({ variables: tagsVariable }).then(({ data: awsDescribeTags }) => {
                const awsDescribeTagsData = awsDescribeTags?.getAwsDescribeTags?.data?.[0]?.tagDescriptions;
                if (awsDescribeTagsData) {
                  allRows.push({
                    ...listener,
                    rules: awsDescribeRulesData,
                    ruleCount: `${awsDescribeRulesData.length} rules`,
                    protocolPort: `${listener?.protocol}:${listener?.port}`,
                    listenerArn: listener?.listenerArn,
                    securitypolicy: listener?.sslPolicy || 'Not applicable',
                    sslTlsCertificate: listener?.certificates?.[0]?.certificateArn || 'Not applicable',
                    mTLS: listener?.mutualAuthentication?.mode || 'Not applicable',
                    trustStore: listener?.mutualAuthentication?.trustStoreArn || 'Not applicable',
                    alpnPolicy: listener?.alpnPolicy || 'None',
                    tagCount: `${awsDescribeTagsData.length} tags`,
                    tags: awsDescribeTagsData,
                  });
                }
              });
            }
          });
        });
        setRows(allRows);
      }
    });
  }, [cloudId, region, data]);

  useEffect(() => {
    fetchAwsDescribeListeners();
  }, []);

  return (
    <Fragment>
      {apiIsLoading ? (
        <div className="listeners-info">
          <div className="progress-container">
            <div className="progress-gif" />
            Loading ...
          </div>
        </div>
      ) : (
        <Fragment>
          <div className="listeners-info">
            <div className="table-container">
              <DetailTable
                title={'Listeners'}
                description={
                  'A listener checks for connection requests using the protocol and port that you configure. Traffic received by a Network Load Balancer listener is forwarded to the selected target group.'
                }
                data={rows}
                columns={columns}
                hasPagination={false}
              />
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default Listeners;
