import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ACTION } from 'pages/v2/Organ/Management/WAF/CustomRuleModal/constant';
import RadioAtom from 'components/v2/atoms/RadioAtom';
import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import lazyGetAwsListIPSets from 'graphql/queries/getAwsListIPSets';
import { variableCombineNextToken } from 'pages/v2/Organ/Management/Utils';
import { RowType } from '@Types/v2/Table';
import { AwsIPSets } from 'graphql/types/AwsListIPSet';

type IpSetOptionPropsType = {
  cloudId: number;
  selectedRegion: DropdownListDataType;
  ipSet: DropdownListDataType;
  setIpSet: (val: DropdownListDataType) => void;
  ipSets: Array<AwsIPSets>;
};

function IpSetOption(props: IpSetOptionPropsType) {
  const { ipSet, setIpSet, ipSets } = props;

  const getOptionIpSets = useMemo(
    () =>
      ipSets.map(e => ({
        name: e.name,
        value: e.name,
      })),
    [ipSets],
  );

  return (
    <div className="mw-50">
      <p className="rule-container-group-content-label">IP set</p>

      <DropdownAtom
        id="dropdown-atom-ip-set"
        data={getOptionIpSets}
        placeholder="Choose IP set"
        value={ipSet}
        handleClick={val => setIpSet(val)}
      />
    </div>
  );
}

export default IpSetOption;
