import { gql } from '@apollo/client';

const query = gql`
query getAwsAllRouteTable($request: AwsDescribeRouteTablesRequest, $cloudId: Long, $region: String) {
  getAwsAllRouteTable(
    describeRouteTablesRequest: $request
    cloudId: $cloudId
    region: $region
  ) {
    result
    nextToken
    data {
      ... on AwsRouteTable {
        associations {
          main
          routeTableAssociationId
          routeTableId
          subnetId
          gatewayId
          associationState {
            state
            statusMessage
          }
          vpcId
          ownerId
          tags {
            key
            value
          }
        }
        propagatingVgws {
          gatewayId
        }
        routes {
          destinationCidrBlock
          destinationIpv6CidrBlock
          destinationPrefixListId
          egressOnlyInternetGatewayId
          gatewayId
          instanceId
          instanceOwnerId
          natGatewayId
          transitGatewayId
          localGatewayId
          carrierGatewayId
          networkInterfaceId
          origin
          state
          vpcPeeringConnectionId
          coreNetworkArn
        }
        routeTableId
        vpcId
        ownerId
        tags {
          key
          value
        }
      }
    }
  }
}`;
export default query;