import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from '../../types';

export interface IDeleteRuleGroupVariables {
  cloudId: number;
  region: string;
  reqData: {
    name: string;
    scope: string;
    id: string;
    lockToken: string;
  }
}

export interface IDeleteRuleGroupResponseData {
  deleteRuleGroup: IGqlResponseNewData<undefined>;
}

const deleteRuleGroupMutation = () => useMutation<IDeleteRuleGroupResponseData, IDeleteRuleGroupVariables>(query);
export default deleteRuleGroupMutation;