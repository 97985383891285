import { useLazyQuery } from '@apollo/client';
import query from './query';
import { Paging } from 'graphql/types/Paging';
import { AwsNatGatewayType } from 'graphql/types/AwsNatGateway';
import { IGqlResponseNewData } from 'graphql/types';

export interface IAwsNatGatewayscPageVariables {
  cloudId: number;
  region: string;
  request: {
    maxResults: number;
    nextToken?: string;
    natGatewayIds?: string[];
    dryRun?: boolean;
    filters?: {
      name: string;
      values: string[];
    }
    pageInfo?: Paging;
  }
}

export interface IAwsNatGatewaysResponseData {
  getAwsAllNatGateways: IGqlResponseNewData<AwsNatGatewayType> ;
}

const lazyGetAllAwsNatGateways = () => useLazyQuery<IAwsNatGatewaysResponseData, IAwsNatGatewayscPageVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAllAwsNatGateways;