import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from '../../types';
import { AwsCreateIPSetResponse } from 'apis/v2/Manage/WafSchema';

interface AwsTaglog {
  key: string;
  value: string;
}

export interface ICreateIPSetVariables {
  cloudId: number;
  region: string;
  reqData: {
    name: string;
    scope: string;
    description?: string;
    ipAddressVersion: string;
    addresses: string[];
    tags?: AwsTaglog[];
  }
}

export interface ICreateIPSetResponseData {
  createAwsIPSet: IGqlResponseNewData<AwsCreateIPSetResponse>;
}

const createAwsIPSetMutation = () => useMutation<ICreateIPSetResponseData, ICreateIPSetVariables>(query);
export default createAwsIPSetMutation;