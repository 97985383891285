import InputAtom from 'components/v2/atoms/InputAtom';

type InputContainerPropsType = {
  title: string;
  description?: string;
  value: string;
  onChange: (val: string) => void;
  note?: string;
};

function InputContainer({ title, description, value, onChange, note }: InputContainerPropsType) {
  return (
    <div>
      <p className="rule-container-group-content-label">{title}</p>

      <p className="rule-container-group-content-description">{description}</p>

      <div className="input-container">
        <InputAtom className='mw-50' placeholder="" value={value} onChangeValue={onChange} noClear />

        <p className="rule-container-group-content-description">{note}</p>
      </div>
    </div>
  );
}

export default InputContainer;
