import React, { useMemo } from 'react';
import { LoadBalancerDetailPropsType } from '../../types';
import LoadBalancerSummary from '../Summary';
import PageDetailTitle from '../../PageDetailTitle';
import { IMgdDetailKeyValueProps, TextTypeEnum } from 'layouts/v3/MgdLayout';
import { detailTabKeys, detailTabTextDes } from '../config';
import { getFormatedDateWithTimezone } from 'pages/v2/Organ/Management/Firewall/Common/Function';
import DetailTab from '../../DetailTab';

const LoadBalancerDetail = (props: LoadBalancerDetailPropsType) => {
  const { detailData, onBackButtonClicked, cloudId, region } = props;

  const title = useMemo(() => {
    return `Load balancer: ${detailData?.dnsName}`;
  }, [detailData]);

  const detailTabContentData = useMemo((): IMgdDetailKeyValueProps[] => {
    const detailTabData: IMgdDetailKeyValueProps[] = [];
    const detailTabDataOrdered: IMgdDetailKeyValueProps[] = [];
    if (detailData) {
      Object.entries(detailData).map(([key, value]) => {
        if (detailTabKeys.indexOf(key) > -1) {
          const textDes = detailTabTextDes.find(text => text.id == key);
          let desValue: string | string[] = '-';

          if (typeof value === 'string') {
            desValue = value.toString();

            if (key === detailTabKeys[7]) desValue = getFormatedDateWithTimezone(desValue, 'MMM DD, YYYY HH:MM');
          }

          if (key === detailTabKeys[6]) {
            desValue = detailData?.availabilityZones?.map(az => az.zoneName || '-') || '-';
          }

          if (textDes) {
            detailTabData.push({
              id: key,
              type: textDes.type as TextTypeEnum,
              title: textDes.value,
              description: desValue,
            });
          }
        }
      });

      // order data
      detailTabKeys.map(key => {
        const dataByKey = detailTabData.find(data => data.id == key);
        if (dataByKey) detailTabDataOrdered.push(dataByKey);
      });
    }
    return detailTabDataOrdered;
  }, [detailData]);

  return (
    <div className="ec2-detail scroll">
      <PageDetailTitle title={title} pageBackClick={onBackButtonClicked} />
      <DetailTab title={'Details'} data={detailTabContentData} isApiLoading={false} />
      <LoadBalancerSummary detailData={detailData} cloudId={cloudId} region={region} isSummaryHidden={true} />
    </div>
  );
};

export default LoadBalancerDetail;
