import React, { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  CheckboxPropsType,
  SectionItemCollapsiblePropsType,
  SectionItemContainerPropsType,
  SectionItemStyledTextPropsType,
} from './types';
import SectionTitle from './SectionTitle';
import Icon from 'components/v2/atoms/Icon';
import ArrowDown from 'assets/svgs/v2/ico_table_sort.svg';

const Container = (props: SectionItemContainerPropsType) => {
  const { title, children } = props;

  return (
    <div className="section-item-container">
      <p className="title">{title}</p>
      {children}
    </div>
  );
};

const StyledText = (props: SectionItemStyledTextPropsType) => {
  const { text } = props;
  return <p className="styled-text">{text}</p>;
};

const Collapsible = (props: SectionItemCollapsiblePropsType) => {
  const { title, description, caption, children } = props;

  const [isCollapse, setCollapse] = useState(false);

  const handleOnClick = useCallback(() => {
    setCollapse(!isCollapse);
  }, [isCollapse]);

  return (
    <>
      <div className="collapsible-container" onClick={handleOnClick}>
        <Icon className={`collapsible-button ${!isCollapse && 'expanded'}`} src={ArrowDown} width={12} height={12} />
        <SectionTitle title={title} caption={caption} description={description} />
      </div>

      {!isCollapse && children}
    </>
  );
};

const Checkbox = (props: CheckboxPropsType) => {
  const { id, checked, disabled, onchange, label } = props;

  return (
    <label className="checkbox-item">
      <input type="checkbox" {...(id && { id: id })} checked={checked} onChange={onchange} disabled={disabled} />
      <span />
      <p>{label}</p>
    </label>
  );
};

const SectionItem = { Container, StyledText, Collapsible, Checkbox };

export default SectionItem;
