import React from 'react';
import { AddTagsStepPropsType } from '../types';
import { FIREWALL_POLICY_CREATION } from '../configs';

const AddTagsStep = (props: AddTagsStepPropsType) => {
  const { onAddButtonClicked } = props;

  return (
    <div className="rule-group-container">
      <div className="title-group">
        <p className="title">{FIREWALL_POLICY_CREATION.ADD_TAGS_TITLE}</p>
        <p className="sub-title">{FIREWALL_POLICY_CREATION.ADD_TAGS_DESCRIPTION}</p>
      </div>
      <div className="rule-group-details-container">
        <div
          className="rule-group-detail-title"
          style={{ gap: 8, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
        >
          {FIREWALL_POLICY_CREATION.FIREWALL_POLICY_TAGS_TITLE}
          <span className="rule-group-detail-sub-title">
            {FIREWALL_POLICY_CREATION.FIREWALL_POLICY_DESCRIPTION}
          </span>
        </div>
        <div
          className="rule-group-detail-title"
          style={{ gap: 8, justifyContent: 'center', display: 'flex', flexDirection: 'column'}}
        >
          <span className="rule-group-detail-sub-title">
            {FIREWALL_POLICY_CREATION.ADD_TAG_TITLE}
          </span>
          <button className="button next-button" style={{marginLeft: 0, width: 80 }} onClick={onAddButtonClicked}>
              {FIREWALL_POLICY_CREATION.ADD_TAG_BUTTON}
            </button>
          <span className="rule-group-detail-sub-title">
            {FIREWALL_POLICY_CREATION.ADD_TAG_DESCRIPTION}
          </span>
        </div>
      </div>
    </div>
  );
};

export default AddTagsStep;
