import { gql } from '@apollo/client';

const query = gql`
query getAwsListIPSets($request: AwsListIpSetsRequest, $cloudId: Long, $region: String) {
  getAwsListIPSets(
    decribeListIpSetsRequest: $request
    cloudId: $cloudId
    region: $region
  ) {
    result
    data {
      ... on AwsListIpSetsResponse {
        nextMarker
        ipSets {
          name
          id
          description
          lockToken
          arn
        }
      }
    }
  }
}`;
export default query;