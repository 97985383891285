import React from 'react';
import { StepContainerPropsType } from './types';
import './styles.scss';

const StepContainer = (props: StepContainerPropsType) => {
  const { title, description, children } = props;

  return (
    <div className="step-container">
      <p className="step-title">{title}</p>
      {description && <p className="step-description">{description}</p>}
      {children}
    </div>
  );
};

export default StepContainer;
