import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { StoragePropsType } from '../types';
import DetailTab from '../../DetailTab';
import DetailTable from '../../DetailTable';
import { BLOCK_DEVICES_TABLE_COLUMNS, RECENT_ROOT_VOLUME_REPLACEMENT_TASK_TABLE_COLUMNS } from '../configs';
import lazyGetAwsDescribeVolumes from 'graphql/queries/getAwsDescribeVolumes';
import lazyGetAwsDescribeReplaceRootVolumeTasks from 'graphql/queries/getAwsDescribeReplaceRootVolumeTasks';
import { AwsReplaceRootVolumeTaskType } from 'graphql/types/AwsDescribeReplaceRootVolumeTask';
import { AwsVolumeType } from 'graphql/types/AwsDescribeVolumes';
import { TextTypeEnum } from 'layouts/v3/MgdLayout';

const Storage = (props: StoragePropsType) => {
  const { instance, cloudId, region } = props;

  // API
  const [getAwsDescribeVolumes] = lazyGetAwsDescribeVolumes();
  const [getAwsDescribeReplaceRootVolumeTasks] = lazyGetAwsDescribeReplaceRootVolumeTasks();

  // State
  const [volumes, setVolumes] = useState<Array<AwsVolumeType>>([]);
  const [replaceRootVolumeTasks, setReplaceRootVolumeTasks] = useState<Array<AwsReplaceRootVolumeTaskType>>([]);

  const getVolumes = useCallback(() => {
    if (instance?.blockDeviceMappings) {
      const volumeIds =
        instance?.blockDeviceMappings?.map(device => device?.ebs?.volumeId ?? '')?.filter(volumeId => !!volumeId) ?? [];

      const variables = {
        cloudId: cloudId,
        region: region.value as string,
        request: {
          volumeIds,
        },
      };

      getAwsDescribeVolumes({ variables }).then(res => {
        setVolumes(res?.data?.getAwsDescribeVolumes?.data?.[0]?.volumes ?? []);
      });
    }
  }, [instance, getAwsDescribeVolumes, cloudId, region]);

  const getReplaceRootVolumeTasks = useCallback(() => {
    if (instance?.instanceId) {
      const variables = {
        cloudId: cloudId,
        region: region.value as string,
        request: {
          filters: {
            name: 'instance-id',
            values: [instance.instanceId],
          },
        },
      };

      getAwsDescribeReplaceRootVolumeTasks({ variables }).then(res => {
        setReplaceRootVolumeTasks(
          res?.data?.getAwsDescribeReplaceRootVolumeTasks?.data?.[0]?.replaceRootVolumeTasks ?? [],
        );
      });
    }
  }, [instance, getAwsDescribeReplaceRootVolumeTasks, cloudId, region]);

  // Init data
  useEffect(() => {
    getVolumes();
    getReplaceRootVolumeTasks();
  }, [getVolumes]);

  const rootDeviceDetails = useMemo(() => {
    const { rootDeviceNam, rootDeviceTyp, ebsOptimized } = instance ?? {};

    return [
      { title: 'Root device name', type: TextTypeEnum.COPY, description: rootDeviceNam ?? '-' },
      { title: 'Root device type', description: rootDeviceTyp ?? '-' },
      { title: 'EBS optimization', type: TextTypeEnum.COPY_LINK, description: ebsOptimized ? 'enabled' : 'disabled' },
    ];
  }, [instance]);

  const blockDeviceRows = useMemo(() => {
    return (
      instance?.blockDeviceMappings?.map(blockDevice => {
        const { ebs, deviceName } = blockDevice;
        const { size, attachments, encrypted, kmsKeyId } =
          volumes?.find(volume => volume?.volumeId === ebs?.volumeId) ?? {};

        return {
          id: ebs?.volumeId,
          volumnId: ebs?.volumeId ?? '-',
          deviceName: deviceName || '-',
          volumnSize: size ?? '-',
          attachmentStatus: attachments?.[0]?.state ?? '-',
          attachmentTime: attachments?.[0]?.attachTime ?? '-',
          encrypted: encrypted ? 'Yes' : 'No',
          kmsKeyId: kmsKeyId ?? '-',
          deleteOnTermination: attachments?.[0]?.deleteOnTermination ? 'Yes' : 'No',
        };
      }) ?? [instance]
    );
  }, [instance, volumes]);

  const recentRootVolumeReplacementTaskRows = useMemo(() => {
    return replaceRootVolumeTasks?.map(task => {
      const { replaceRootVolumeTaskId, taskState, startTime, completeTime } = task;

      return {
        id: replaceRootVolumeTaskId,
        taskId: replaceRootVolumeTaskId ?? '-',
        taskState: taskState ?? '-',
        startTime: startTime ?? '-',
        completionTime: completeTime ?? '-',
      };
    });
  }, [replaceRootVolumeTasks]);

  return (
    <Fragment>
      <DetailTab title={'Root device details'} data={rootDeviceDetails} />
      <DetailTable data={blockDeviceRows} columns={BLOCK_DEVICES_TABLE_COLUMNS} title="Block devices" />
      <DetailTable
        data={recentRootVolumeReplacementTaskRows}
        columns={RECENT_ROOT_VOLUME_REPLACEMENT_TASK_TABLE_COLUMNS}
        title="Recent root volume replacement tasks"
      />
    </Fragment>
  );
};

export default Storage;
