import { useLazyQuery } from '@apollo/client';
import query from './query';
import { AwsRouteTableType } from 'graphql/types/AwsRouteTable';
import { IGqlResponseNewData } from 'graphql/types';

export interface IAwsAllRouteTableVariables {
  cloudId: number;
  region: string;
  request: {
    maxResults: number;
    nextToken?: string;
    routeTableIds?: string[];
    dryRun?: boolean;
    filters?: {
      name: string;
      values: string[];
    }
  }
}

export interface IAwsAllRouteTableResponseData {
  getAwsAllRouteTable: IGqlResponseNewData<AwsRouteTableType>;
}

const lazyGetAllAwsRouteTable = () => useLazyQuery<IAwsAllRouteTableResponseData, IAwsAllRouteTableVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAllAwsRouteTable;