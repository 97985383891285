import { gql } from '@apollo/client';

const query = gql`
query getAwsDescribeSpotInstanceFleetRequestsCombined($request: AwsDescribeSpotInstanceFleetRequestsCombineRequest, $cloudId: Long, $region: String) {
  getAwsDescribeSpotInstanceFleetRequestsCombined(describeSpotInstanceFleetRequestsCombineRequest: $request, cloudId: $cloudId, region: $region) {
    result
    data {
      ... on AwsDescribeSpotInstanceFleetRequestsCombineResponse {
       awsDescribeSpotInstanceRequestsResponse {
        spotInstanceRequests {
          actualBlockHourlyPrice
          availabilityZoneGroup
          blockDurationMinutes
          createTime
          fault {
            code
            message
          }
          instanceId
          launchGroup
          launchSpecification {
            userData
            securityGroups {
              groupName
              groupId
            }
            addressingType
            blockDeviceMappings {
              deviceName
              virtualName
              ebs {
                deleteOnTermination
                iops
                snapshotId
                volumeSize
                volumeType
                kmsKeyId
                throughput
                outpostArn
                encrypted
              }
              noDevice
            }
            ebsOptimized
            iamInstanceProfile {
              arn
              name
            }
            imageId
            instanceType
            kernelId
            keyName
            networkInterfaces {
              associatePublicIpAddress
              deleteOnTermination
              description
              deviceIndex
              groups
              ipv6AddressCount
              ipv6Addresses {
                ipv6Address
                isPrimaryIpv6
              }
              networkInterfaceId
              privateIpAddress
              privateIpAddresses {
                primary
                privateIpAddress
              }
              secondaryPrivateIpAddressCount
              subnetId
              associateCarrierIpAddress
              interfaceType
              networkCardIndex
              ipv4Prefixes {
                ipv4Prefix
              }
              ipv4PrefixCount
              ipv6Prefixes {
                ipv6Prefix
              }
              ipv6PrefixCount
              primaryIpv6
              enaSrdSpecification {
                enaSrdEnabled
                enaSrdUdpSpecification {
                  enaSrdUdpEnabled
                }
              }
              connectionTrackingSpecification {
                tcpEstablishedTimeout
                udpStreamTimeout
                udpTimeout
              }
            }
            placement {
              availabilityZone
              groupName
              tenancy
            }
            ramdiskId
            subnetId
            monitoring {
              enabled
            }
          }
          launchedAvailabilityZone
          productDescription
          spotInstanceRequestId
          spotPrice
          state
          status {
            code
            message
            updateTime
          }
          tags {
            key
            value
          }
          type
          validFrom
          validUntil
          instanceInterruptionBehavior
        }
        nextToken
      }
        awsDescribeSpotFleetRequestsResponse {
       spotFleetRequestConfigs {
        activityStatus
        createTime
        spotFleetRequestConfig {
          allocationStrategy
          onDemandAllocationStrategy
          spotMaintenanceStrategies {
            capacityRebalance {
              replacementStrategy
              terminationDelay
            }
          }
          clientToken
          excessCapacityTerminationPolicy
          fulfilledCapacity
          onDemandFulfilledCapacity
          iamFleetRole
          launchSpecifications {
            securityGroups {
              groupName
              groupId
            }
            addressingType
            blockDeviceMappings {
              deviceName
              virtualName
              noDevice
            }
            ebsOptimized
            iamInstanceProfile {
              arn
              name
            }
            imageId
            instanceType
            kernelId
            keyName
            monitoring {
              enabled
            }
            networkInterfaces {
              associatePublicIpAddress
              deleteOnTermination
              description
              deviceIndex
              groups
              ipv6AddressCount
              ipv6Addresses {
                ipv6Address
                isPrimaryIpv6
              }
              networkInterfaceId
              privateIpAddress
              privateIpAddresses {
                primary
                privateIpAddress
              }
            }
            placement {
              availabilityZone
              groupName
              tenancy
            }
            ramdiskId
            spotPrice
            subnetId
            userData
            weightedCapacity
            tagSpecifications {
              resourceType
            }
            instanceRequirements {
              spotMaxPricePercentageOverLowestPrice
              onDemandMaxPricePercentageOverLowestPrice
              bareMetal
              burstablePerformance
              requireHibernateSupport
              localStorage
              maxSpotPriceAsPercentageOfOptimalOnDemandPrice
            }
          }
          launchTemplateConfigs{
            launchTemplateSpecification {
              launchTemplateId
             version
              launchTemplateName
            }
            overrides {
              instanceType
              spotPrice
              subnetId
              availabilityZone
              weightedCapacity
              priority
              instanceRequirements {
                vCpuCount{
                  min
                  max
                }
                memoryMiB {
                  min
                 max
                }
                cpuManufacturers
                memoryGiBPerVCpu {
                  min
                  max
                }
                excludedInstanceTypes
                instanceGenerations
                spotMaxPricePercentageOverLowestPrice
                onDemandMaxPricePercentageOverLowestPrice
                bareMetal
                burstablePerformance
                requireHibernateSupport
                networkInterfaceCount {
                  min
                  max
                }
                localStorage
                localStorageTypes
                totalLocalStorageGB {
                  min
                  max
                }
                baselineEbsBandwidthMbps {
                  min
                  max
                }
                acceleratorTypes
                acceleratorCount {
                  min
                  max
                }
                acceleratorManufacturers
                acceleratorNames
                acceleratorTotalMemoryMiB {
                  min
                  max
                }
                networkBandwidthGbps {
                  min
                  max
                }
                allowedInstanceTypes
                maxSpotPriceAsPercentageOfOptimalOnDemandPrice
              }
            }
          }
          spotPrice
          targetCapacity
          onDemandTargetCapacity
          onDemandMaxTotalPrice
          spotMaxTotalPrice
          terminateInstancesWithExpiration
          type
          validFrom
          validUntil
          replaceUnhealthyInstances
          instanceInterruptionBehavior
          loadBalancersConfig {
            classicLoadBalancersConfig {
              classicLoadBalancers {
                name
              }
            }
            targetGroupsConfig {
              targetGroups {
                arn
              }
            }
          }
          instancePoolsToUseCount
          context
          targetCapacityUnitType
          tagSpecifications {
            resourceType
          }
        }
        spotFleetRequestId
        spotFleetRequestState
        tags {
          key
          value
        }
      }
        nextToken
        }
        }
      }
    }
  }
`;
export default query;
