import { useLazyQuery } from '@apollo/client';
import query from './query';
import { AwsGetKeyPolicyResponse } from 'graphql/types/AwsKeyPolicy';

export interface IAwsKeyPolicyVariables {
  cloudId: number;
  region: string;
  request: {
    keyId: string;
  }
}

export interface IAwsKeyPolicyResponseData {
  getAwsKeyPolicy: AwsGetKeyPolicyResponse;
}

const lazyGetAwsKeyPolicy = () => useLazyQuery<IAwsKeyPolicyResponseData, IAwsKeyPolicyVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsKeyPolicy;