import { gql } from '@apollo/client';

const query = gql`
query getAwsDescribeVolumes($request: AwsDescribeVolumesRequest, $cloudId: Long, $region: String) {
  getAwsDescribeVolumes(describeVolumesRequest: $request, cloudId: $cloudId, region: $region) {
    result
     data{
      ... on AwsDescribeVolumesResponse {
        volumes {
          attachments {
            attachTime
            device
            instanceId
            state
            volumeId
            deleteOnTermination
            associatedResource
            instanceOwningService
          }
          availabilityZone
          createTime
          encrypted
          kmsKeyId
          outpostArn
          size
          snapshotId
          state
          volumeId
          iops
          tags {
            key
            value
          }
          volumeType
          fastRestored
          multiAttachEnabled
          throughput
          sseType
        }
        nextToken
        }
      }
    }
  }
`;
export default query;
