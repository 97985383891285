import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { InstancePropsType } from '../types';
import { INSTANCE_COLUMN_LIST, INSTANCE_SEARCH_TYPES } from '../../configs';
import Table from 'components/v2/dataDisplay/Table';
import { OrderDirection, RowType } from '@Types/v2/Table';
import TableManagePagination from 'components/v2/dataDisplay/TableManagePagination';
import lazyGetAllAwsDescribeInstances from 'graphql/queries/getAwsAllDescribeInstances';
import { variableCombineNextToken } from '../../../Utils';
import { AwsGroupIdentifierLogType, AwsInstanceType } from 'graphql/types/AwsDescribeInstance';
import InputAtom from 'components/v2/atoms/InputAtom';
import SearchIcon from 'assets/svgs/v3/ico_search.svg';
import DropdownAtom from 'components/v2/atoms/DropdownAtom';
import lazyGetAwsDescribeInstanceStatus from 'graphql/queries/getAwsDescribeInstanceStatus';
import { AwsInstanceStatusType } from 'graphql/types/AwsDescribeInstanceStatus';
import lazyGetAwsAllAddresses from 'graphql/queries/getAwsAllAddress';
import { AwsAddressType } from 'graphql/types/AwsAddress';
import { orderAlphabetical } from '../../../Utils/Sorting';
import { getFormatedDateWithTimezone } from '../../../Firewall/Common/Function';

const Instance = (props: InstancePropsType) => {
  const { cloudId, region, tabInformation, onInstanceSelected, onInstanceIdClicked } = props;

  // API
  const [getAwsDescribeInstances, { loading: describeInstancesLoading }] = lazyGetAllAwsDescribeInstances();
  const [getAwsDescribeInstanceStatus, { loading: describeInstancesStatusLoading }] =
    lazyGetAwsDescribeInstanceStatus();
  const [getAwsAddresses, { loading: addressesLoading }] = lazyGetAwsAllAddresses();

  // State
  const [instanceList, setInstanceList] = useState<Array<AwsInstanceType>>([]);
  const [instanceStatusList, setInstanceStatusList] = useState<Array<AwsInstanceStatusType>>([]);
  const [awsAddressList, setAwsAddressList] = useState<Array<AwsAddressType>>([]);
  const [tablePagination, setTablePagination] = useState({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });
  const [searchValue, setSearchValue] = useState<{ name: string; value: string }>({ name: '', value: '' });
  const [searchType, setSearchType] = useState(INSTANCE_SEARCH_TYPES[0]);

  const describeInstancesRequestVariables = useMemo(() => {
    if (!!searchValue?.value) {
      return {
        cloudId: cloudId,
        region: region.value as string,
        request: {
          maxResults: 100,
          filters: {
            name: searchType.value,
            values: [searchValue.value],
          },
        },
      };
    }

    return {
      cloudId: cloudId,
      region: region.value as string,
      request: {
        maxResults: 100,
      },
    };
  }, [cloudId, region, searchValue, searchType]);

  const instanceStatusRequestVariables = useMemo(() => {
    return {
      cloudId: cloudId,
      region: region.value as string,
      request: {
        maxResults: 100,
      },
    };
  }, [cloudId, region]);

  const awsAddressRequestVariables = useMemo(() => {
    return {
      cloudId: cloudId,
      region: region.value as string,
      request: {},
    };
  }, [cloudId, region]);

  useEffect(() => {
    if (cloudId && region?.value) {
      getAwsDescribeInstances({ variables: variableCombineNextToken(describeInstancesRequestVariables) }).then(res => {
        let instances: Array<AwsInstanceType> = [];
        res?.data?.getAwsAllDescribeInstances?.data?.forEach(instanceGroup => {
          instances.push(...(instanceGroup?.instances ?? []));
        });

        setInstanceList(instances);
      });

      getAwsDescribeInstanceStatus({ variables: variableCombineNextToken(instanceStatusRequestVariables) }).then(
        res => {
          setInstanceStatusList(res?.data?.getAwsDescribeInstanceStatus?.data?.[0]?.instanceStatuses ?? []);
        },
      );

      getAwsAddresses({ variables: variableCombineNextToken(awsAddressRequestVariables) }).then(res => {
        setAwsAddressList(res?.data?.getAwsAddresses?.data ?? []);
      });
    }
  }, [
    getAwsDescribeInstances,
    getAwsDescribeInstanceStatus,
    getAwsAddresses,
    describeInstancesRequestVariables,
    instanceStatusRequestVariables,
    awsAddressRequestVariables,
  ]);

  const isLoading = useMemo(() => {
    return describeInstancesLoading && describeInstancesStatusLoading && addressesLoading;
  }, [describeInstancesLoading, describeInstancesStatusLoading, addressesLoading]);

  const updateTablePagination = useCallback((key: string, value: any) => {
    setTablePagination(prevState => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  }, []);

  const getStatusCheck = useCallback(
    (id: string): string => {
      const instanceStatusItem = instanceStatusList.find(item => item?.instanceId === id);

      if (instanceStatusItem?.instanceStatus?.status === 'ok' && instanceStatusItem?.systemStatus?.status === 'ok') {
        return '2/2 checks passed';
      }

      if (instanceStatusItem?.instanceStatus?.status === 'ok' || instanceStatusItem?.systemStatus?.status === 'ok') {
        return '2/2 checks passed';
      }

      return '-';
    },
    [instanceStatusList],
  );

  const getElasticIP = useCallback(
    (id: string) => {
      const addressItem = awsAddressList?.find(item => item?.instanceId === id);

      return addressItem?.publicIp ?? '-';
    },
    [awsAddressList],
  );

  const getSecurityGroupsString = useCallback((securityGroups: Array<AwsGroupIdentifierLogType>) => {
    let securityGroupNames = securityGroups.map(item => item.groupName);

    return securityGroupNames.join(',');
  }, []);

  const handleInstanceIdClicked = useCallback(
    (instanceId: string) => {
      onInstanceSelected(instanceList.find(instance => instance.instanceId === instanceId)!);
      onInstanceIdClicked();
    },
    [instanceList],
  );

  const instanceTableRows = useMemo((): Array<RowType> => {
    const dataList = instanceList.map(instance => {
      const {
        instanceId,
        state,
        instanceType,
        placement,
        publicDnsName,
        publicIpAddress,
        ipv6Address,
        monitoring,
        securityGroups,
        keyName,
        launchTime,
        platform,
        tags,
      } = instance;

      const name = tags?.find(tag => tag.key === 'Name')?.value ?? '';

      return {
        id: instanceId,
        name,
        instanceId,
        instanceState: state?.name,
        instanceType: instanceType,
        statusCheck: getStatusCheck(instanceId ?? ''),
        availableZone: placement?.availabilityZone,
        publicIpv4Dns: publicDnsName ?? '-',
        publicIpv4Address: publicIpAddress ?? '-',
        elasticIp: getElasticIP(instanceId ?? ''),
        monitoring: monitoring?.state,
        ipv6Ips: ipv6Address ?? '-',
        securityGroupName: getSecurityGroupsString(securityGroups ?? []),
        keyName: keyName ?? '-',
        launchTime: getFormatedDateWithTimezone(launchTime, 'MMM DD, YYYY HH:MM') ?? '-',
        platform: platform ?? '-',
        onInstanceIdClicked: handleInstanceIdClicked,
      };
    });

    return dataList;
  }, [instanceList, instanceStatusList, getStatusCheck]);

  const currentPageInstanceTableRows = useMemo(() => {
    const firstItemIndex = (tablePagination.currentPage - 1) * tablePagination.itemPerPage;
    const lastItemIndex = tablePagination.currentPage * tablePagination.itemPerPage - 1;

    const arr = instanceTableRows?.filter((_, index) => index >= firstItemIndex && index <= lastItemIndex);

    const startIndex = (tablePagination.currentPage - 1) * tablePagination.itemPerPage;
    const endIndex = startIndex + tablePagination.itemPerPage;

    return orderAlphabetical(arr, tablePagination.target, tablePagination.direction).slice(
      startIndex,
      endIndex,
    );
  }, [instanceTableRows, tablePagination]);

  if (currentPageInstanceTableRows.length == 0 && INSTANCE_COLUMN_LIST.length == 0) {
    <div className="data-grid-wrap">
      <p className="empty-row">Empty</p>
    </div>;
  }

  return (
    <>
      <div className="row-3 flex j-between a-center">
        <div className="title">
          <p>{tabInformation.title}</p>
        </div>
        <div className="flex action a-center">
          <DropdownAtom
            id="types-dropdown"
            className=""
            data={INSTANCE_SEARCH_TYPES}
            value={searchType}
            handleClick={setSearchType}
          />
          <InputAtom
            type={'text'}
            value={searchValue?.value}
            defaultValue={''}
            onChangeValue={value => setSearchValue(prev => ({ name: prev.name, value }))}
            hasPrefixIcon={true}
            srcPrefixIcon={SearchIcon}
            prefixIconOnClick={() => {}}
          />
        </div>
      </div>
      <div className="data-grid-wrap">
        <Table
          rows={currentPageInstanceTableRows}
          columns={INSTANCE_COLUMN_LIST}
          reportCheckedList={() => {}}
          reportSelected={id => {
            onInstanceSelected(instanceList.find(item => item.instanceId === id)!);
          }}
          sortOption={{
            target: tablePagination.target,
            direction: tablePagination.direction,
            onChangeSort: (target: string, dir: OrderDirection) => {
              updateTablePagination('target', target);
              updateTablePagination('direction', dir);
            },
          }}
          horizontalScrollable={true}
          isLoading={isLoading}
        />
        {instanceTableRows?.length > 0 && !isLoading && (
          <div className="pagination-wrapper flex a-center">
            <p className="flex a-center">
              Total <span>{instanceTableRows.length}</span>
            </p>
            <TableManagePagination
              ableFetchMore={false}
              currentPage={tablePagination.currentPage}
              updateCurrentPage={page => updateTablePagination('currentPage', page)}
              totalPage={Math.ceil(instanceTableRows.length / tablePagination.itemPerPage)}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Instance;
