import {gql} from '@apollo/client';

const query = gql`
query getAwsTLSInspectionConfiguration($request: AwsDescribeTlsInspectionConfigurationRequest, $cloudId: Long, $region: String) {
  getAwsTLSInspectionConfiguration(describeTlsInspectionConfigurationRequest: $request, cloudId: $cloudId, region: $region) {
    result
     data{
      ... on AwsDescribeTlsInspectionConfigurationResponse {
        updateToken
        tlsInspectionConfiguration {
          serverCertificateConfigurations {
            serverCertificates {
              resourceArn
            }
            scopes {
              sources {
                addressDefinition
              }
              destinations {
                addressDefinition
              }
              sourcePorts {
                fromPort
                toPort
              }
              destinationPorts {
                fromPort
                toPort
              }
              protocols
            }
            certificateAuthorityArn
            checkCertificateRevocationStatus{
              revokedStatusAction
              unknownStatusAction
            }
          }
        }
        tlsInspectionConfigurationResponse {
          tlsInspectionConfigurationArn
          tlsInspectionConfigurationName
          tlsInspectionConfigurationId
          tlsInspectionConfigurationStatus
          description
          tags {
            key
            value
          }
          lastModifiedTime
          numberOfAssociations
          encryptionConfiguration {
            keyId
            type
          }
          certificates {
            certificateArn
            certificateSerial
            status
            statusMessage
          }
          certificateAuthority {
            certificateArn
            certificateSerial
            status
            statusMessage
          }
        }
        }
      }
    }
  }
`;
export default query;