import React from 'react';
import {MISSING_IP_ADDRESS} from "pages/v2/Organ/Management/WAF/CustomRuleModal/constant";
import RadioAtom from "components/v2/atoms/RadioAtom";

type MissingIpAddressPropsType = {
  missingIpAddress: string;
  setMissingIpAddress: (val: string) => void;
};

function MissingIpAddress({missingIpAddress, setMissingIpAddress}: MissingIpAddressPropsType) {
  return (
    <div>
      <p className='rule-container-group-content-label'>Fallback for missing IP address</p>

      <p className='rule-container-group-content-description'>Handling for requests that don’t have a valid IP
        address in the specified header. Note that, if the specified header isn’t present at all in the
        request, AWS WAF doesn’t apply the rule to the request.</p>

      <div className='space-8'>
        {MISSING_IP_ADDRESS.map(({id, label, value, name, description}) => (
          <RadioAtom
            key={id}
            label={label}
            value={value}
            name={name}
            description={description}
            checked={missingIpAddress}
            onChange={setMissingIpAddress}
          />
        ))}
      </div>
    </div>
  );
}

export default MissingIpAddress;