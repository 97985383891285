import './index.scss';

interface IRadioAtomProps {
  id?: string;
  label: string,
  description?: string;
  value: string,
  name: string,
  checked: string,
  onChange: (value: string) => void
}
const RadioAtom = ({ id, description, label, value, name, checked, onChange }:IRadioAtomProps) => {
  return (
    <div className="radio-atom" onClick={() => onChange(value)} key={`radio_${name}_${id}`}>
      <span className="radio-lable">{label}</span>
      {description && <p className="radio-description">{description}</p>}
      <input type="radio" {...(id && { id: id })} name={name} checked={checked == value} onChange={() => onChange(value)}/>
      <span className="check-mark"></span>
    </div>
  );
};
export default RadioAtom;