import { useLazyQuery } from '@apollo/client';
import query from './query';
import { AwsListResourceTagsEntry } from 'graphql/types/AwsListResourceTags';

export interface IAwsListResourceTagsVariables {
  cloudId: number;
  region: string;
  request: {
    keyId: string;
  }
}

export interface IAwsListResourceTagsResponseData {
  getAwsListResourceTags: AwsListResourceTagsEntry;
}

const lazyGetAwsListResourceTags = () => useLazyQuery<IAwsListResourceTagsResponseData, IAwsListResourceTagsVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsListResourceTags;