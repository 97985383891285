import { gql } from '@apollo/client';

const query = gql`
  query getAwsAllListTLSInspectionConfigurations(
    $request: AwsListTlsInspectionConfigurationsRequest
    $cloudId: Long
    $region: String
  ) {
    getAwsAllListTLSInspectionConfigurations(
      listTlsInspectionConfigurationsRequest: $request
      cloudId: $cloudId
      region: $region
    ) {
      result
      data {
        ... on AwsTLSInspectionConfigurationMetadata {
          name
          arn
        }
      }
    }
  }
`;
export default query;
