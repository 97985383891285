import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { SecurityPropsType } from '../types';
import DetailTab from '../../DetailTab';
import { AwsGroupIdentifierLogType } from 'graphql/types/AwsDescribeInstance';
import DetailTable from '../../DetailTable';
import { INBOUND_RULE_TABLE_COLUMNS } from '../configs';
import lazyGetAwsSecurityGroupRules from 'graphql/queries/getAwsSecurityGroupRules';
import { AwsSecurityGroupRuleType } from 'graphql/types/AwsSecurityGroupRules';
import { TextTypeEnum } from 'layouts/v3/MgdLayout';

const Security = (props: SecurityPropsType) => {
  const { instance, cloudId, region } = props;

  // API
  const [getAwsSecurityGroupRules, { loading: awsSGLoading }] = lazyGetAwsSecurityGroupRules();

  // State
  const [securityGroupRules, setSecurityGroupRules] = useState<Array<AwsSecurityGroupRuleType>>([]);

  // Get security group rules
  const getSecurityGroupRules = useCallback(() => {
    if (instance?.securityGroups) {
      const groupIds = instance?.securityGroups?.map(group => group?.groupId ?? '');

      const securityGroupRuleVariable = {
        cloudId: cloudId,
        region: region.value as string,
        request: {
          maxResults: 50,
          filters: {
            name: 'group-id',
            values: groupIds,
          },
        },
      };

      getAwsSecurityGroupRules({ variables: securityGroupRuleVariable }).then(res => {
        setSecurityGroupRules(res?.data?.getAwsSecurityGroupRules?.data?.[0]?.securityGroupRules ?? []);
      });
    }
  }, [cloudId, region, instance]);

  // Init data
  useEffect(() => {
    getSecurityGroupRules();
  }, [getSecurityGroupRules]);

  const securityDetails = useMemo(() => {
    const { launchTime, securityGroups } = instance ?? {};

    return [
      { title: 'IAM Role', description: '-', type: TextTypeEnum.COPY_LINK }, // TODO: GetInstanceProfileResponse.instanceProfile().roles().roleName()
      { title: 'Owner ID', description: '-', type: TextTypeEnum.COPY }, // TODO: Reservation.ownerId()
      { title: 'Launch time', description: launchTime ?? '-' },
      {
        title: 'Security groups',
        description: securityGroups?.map?.(item => item?.groupName) ?? '-',
        type: TextTypeEnum.COPY_LINK,
      },
    ];
  }, [instance]);

  const getSecurityGroupsString = useCallback((securityGroups: Array<AwsGroupIdentifierLogType>) => {
    let securityGroupNames = securityGroups.map(item => item.groupName);

    return securityGroupNames.join(',');
  }, []);

  const inboundRules = useMemo(() => {
    return securityGroupRules?.filter(rule => !rule?.isEgress);
  }, [securityGroupRules]);

  const inboundRulesRows = useMemo(() => {
    return inboundRules?.map(rule => ({
      id: rule?.groupId,
      name: rule?.tags?.find(tag => tag?.key === 'Name')?.key ?? '-',
      securityGroupRuleId: rule?.securityGroupRuleId ?? '-',
      portRange: `${rule?.fromPort}:${rule?.toPort}`,
      protocol: rule?.ipProtocol ?? '-',
      source: rule?.cidrIpv4 || rule?.cidrIpv6 || '-',
      securityGroups: getSecurityGroupsString(instance?.securityGroups ?? []) ?? '-',
      description: rule?.description ?? '-',
    }));
  }, [inboundRules]);

  const outboundRules = useMemo(() => {
    return securityGroupRules?.filter(rule => rule?.isEgress);
  }, [securityGroupRules]);

  const outboundRulesRows = useMemo(() => {
    return outboundRules?.map(rule => ({
      id: rule?.groupId,
      name: rule?.tags?.find(tag => tag?.key === 'Name')?.key ?? '-',
      securityGroupRuleId: rule?.securityGroupRuleId ?? '-',
      portRange: `${rule?.fromPort}:${rule?.toPort}`,
      protocol: rule?.ipProtocol ?? '-',
      source: rule?.cidrIpv4 || rule?.cidrIpv6 || '-',
      securityGroups: getSecurityGroupsString(instance?.securityGroups ?? []) ?? '-',
      description: rule?.description ?? '-',
    }));
  }, [outboundRules]);

  return (
    <>
      <DetailTab title={'Instance summary'} data={securityDetails} />
      <DetailTable data={inboundRulesRows} columns={INBOUND_RULE_TABLE_COLUMNS} title="Inbound rules" />
      <DetailTable data={outboundRulesRows} columns={INBOUND_RULE_TABLE_COLUMNS} title="Outbound rules" />
    </>
  );
};

Security.propTypes = {};

export default Security;
