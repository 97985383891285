import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { ColumnType, OrderDirection, RowType } from '@Types/v2/Table';
import './NetworkMapping.scss';
import { AwsLoadBalancer } from 'graphql/types/AwsLoadBalancer';
import DetailTable from '../../DetailTable';
import lazyGetAwsSubnetsPageByParam, { IAwsSubnetsVariables } from 'graphql/queries/getAwsSubnets';
import { orderAlphabetical } from 'pages/v2/Organ/Management/Utils/Sorting';
import { handleFormatText } from 'utils/Common';

export type NetworkMappingPropsType = {
  cloudId: number;
  region: string;
  data?: AwsLoadBalancer;
};

const NetworkMapping = (props: NetworkMappingPropsType) => {
  const { data, cloudId, region } = props;
  
  const [getAwsSubnets, { loading: awsSubnetLoading }] = lazyGetAwsSubnetsPageByParam();

  const [tablePagination, setTablePagination] = useState({
    limit: 50,
    itemPerPage: 10,
    target: 'modifiedAt',
    direction: OrderDirection.DES,
    currentPage: 1,
  });
  const [rows, setRows] = useState<RowType[]>([]);

  const handleHyperLinkClick = useCallback((row: RowType) => {}, []);

  const columns = useMemo((): ColumnType[] => {
    return [
      { label: 'Zone', field: 'zone', sort: true },
      {
        label: 'Subnet',
        field: 'subnet',
        sort: true,
        renderCell: (row: RowType) => (
          <p className="link" onClick={() => {}}>
            {handleFormatText(row.subnetId)}
          </p>
        ),
      },
      { label: 'IPv4 address', field: 'ipV4Address', sort: true },
      { label: 'Private IPv4 address', field: 'privateIpv4Address', sort: true },
      { label: 'IPv6 address', field: 'ipV6Address', sort: true },
    ];
  }, []);

  const apiIsLoading = useMemo(() => {
    return awsSubnetLoading;
  }, [awsSubnetLoading]);

  const vpcId = useMemo(() => {
    return data?.vpcId || '-';
  }, [data]);

  const ipAddressType = useMemo(() => {
    return data?.ipAddressType || '-';
  }, [data]);

  const fetchAwsSubnets = useCallback(() => {
    const subnetVariable: IAwsSubnetsVariables = {
      cloudId: cloudId,
      region: region,
      request: {
        maxResults: 100,
        filters: {
          name: 'vpc-id',
          values: [vpcId],
        },
      },
    };

    getAwsSubnets({ variables: subnetVariable }).then(({ data: awsSubnets }) => {
      const awsSubnetData = awsSubnets?.getAwsSubnets?.data?.[0]?.subnets;
      if (awsSubnetData) {
        const subnetRows: RowType[] = [];
        awsSubnetData.map(subnet => {
          const ipV4AddressValue =
            subnet.mapPublicIpOnLaunch === true
              ? 'Assigned by AWS'
              : subnet.mapCustomerOwnedIpOnLaunch === true
              ? 'Assigned by CustomerOwnedIp'
              : 'Not assigned';

          const privateIpv4AddressValue =
            subnet.mapPublicIpOnLaunch === true
              ? subnet.cidrBlock
              : subnet.mapCustomerOwnedIpOnLaunch === true
              ? subnet.customerOwnedIpv4Pool
              : '';

          const ipV6AddressValue =
            subnet.assignIpv6AddressOnCreation === true ? subnet.ipv6CidrBlockAssociationSet?.[0].ipv6CidrBlock : '';

          subnetRows.push({
            ...subnet,
            id: subnet.subnetId,
            zone: `${subnet.availabilityZone} (${subnet.availabilityZoneId})`,
            ipV4Address: ipV4AddressValue,
            privateIpv4Address: privateIpv4AddressValue ? `Assigned from CIDR ${privateIpv4AddressValue}` : '-',
            ipV6Address: ipV6AddressValue ? ipV6AddressValue : 'Not applicable',
          });
        });
        setRows(subnetRows);
      }
    });
  }, [cloudId, region, vpcId]);

  const currentRowPage = useMemo((): RowType[] => {
    return orderAlphabetical(rows, tablePagination.target, tablePagination.direction);
  }, [rows, tablePagination]);

  useEffect(() => {
    fetchAwsSubnets();
  }, []);

  return (
    <Fragment>
      {apiIsLoading ? (
        <div className="storage-info">
          <div className="progress-container">
            <div className="progress-gif" />
            Loading ...
          </div>
        </div>
      ) : (
        <Fragment>
          <div className="root-device-detail">
            <div className="title-container">
              <p className="title">Network mapping</p>
              <p className="description">
                Targets in the listed zones and subnets are available for traffic from the load balancer using the IP
                addresses shown.
              </p>
            </div>

            <div className="content-container">
              <div className="first">
                <p>VPC</p>
                <p>{vpcId}</p>
              </div>
              <div className="second">
                <p>Load balancer IP address type:</p>
                <p>{ipAddressType}</p>
              </div>
            </div>
          </div>

          <div className="listeners-info">
            <div className="table-container">
              <DetailTable
                title={'Mappings'}
                description={
                  'Including two or more Availability Zones, and corresponding subnets, increases the fault tolerance of your applications.'
                }
                data={currentRowPage}
                columns={columns}
                hasPagination={false}
              />
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default NetworkMapping;
