import Action from '../../../components/Action';
import { ACTION } from '../../../constant';
import ImmunityTime from '../../../components/ImmunityTime';
import Collapse from '../../../components/Collapse';
import CheckboxAtom from 'components/v2/atoms/CheckboxAtom';
import CustomHeader from '../../../components/CustomHeader';
import AnotherLabel from '../Regular/AddLabel';

type ThenGroupPropsType = {
  action: string;
  handleSelectAction: (val: string) => void;
  seconds: string;
  setSeconds: (val: string) => void;
  isCustomImmunityTime: boolean;
  setIsCustomImmunityTime: () => void;
  isEnable: boolean;
  setIsEnable: () => void;
  customHeaderList: [];
  setCustomHeaderList: (customHeaderList: []) => void;
  anotherLabelList: [];
  setAnotherLabelList: (anotherLabelList: []) => void;
};

function ThenGroup({
  action,
  handleSelectAction,
  seconds,
  setSeconds,
  isCustomImmunityTime,
  setIsCustomImmunityTime,
  isEnable,
  setIsEnable,
  customHeaderList,
  setCustomHeaderList,
  anotherLabelList,
  setAnotherLabelList,
}: ThenGroupPropsType) {
  return (
    <div className="rule-container-group">
      <p className="rule-container-group-title">Then</p>

      <div className="space-8" />

      <div className=" rule-container-group-border">
        <p className="rule-container-group-title">Action</p>

        <div className="rule-container-group-content">
          <Action action={action} setAction={val => handleSelectAction(val)} />

          {action === ACTION[3].value || action === ACTION[4].value ? (
            <ImmunityTime
              seconds={seconds}
              setSeconds={setSeconds}
              isCustomImmunityTime={isCustomImmunityTime}
              setIsCustomImmunityTime={setIsCustomImmunityTime}
            />
          ) : null}
        </div>
      </div>

      <div className="space-16"></div>

      {action !== ACTION[4].value ? (
        <Collapse
          className="rule-container-group-list-container"
          label={
            <div className="rule-container-group-title">
              <p>Custom request</p>

              <span>-optional</span>
            </div>
          }
        >
          {action === ACTION[1].value ? (
            <div>
              <p className="rule-container-group-content-description">
                With the Block action, you can send a custom response to the web request.
              </p>

              <div className="checkbox-container space-8">
                <label>
                  <CheckboxAtom checked={isEnable} onchange={setIsEnable} />
                  Enable
                </label>
              </div>
            </div>
          ) : (
            <CustomHeader customHeaderList={customHeaderList} setCustomHeaderList={setCustomHeaderList} />
          )}
        </Collapse>
      ) : null}

      <div className="space-16"></div>

      <Collapse
        className="rule-container-group-list-container"
        label={
          <div>
            <div className="rule-container-group-title">
              <p>Add label </p>

              <span>- optional</span>
            </div>

            <div className="rule-container-group-description">
              Add labels to requests that match this rule. Rules that are evaluated later in the same web ACL can
              reference the labels that this rule adds.
            </div>
          </div>
        }
      >
        <div>
          <p className="rule-container-group-content-description">
            Enter the string containing the label name and optional prefix and namespaces. For example, namespace1:name
            or awswaf:managed:aws:managed-rule-set:namespace1:name.
          </p>

          <ul className="rule-container-group-content-note">
            <li>Each namespace or name can have up to 128 characters.</li>
            <li>You can specify uptime to 5 namespaces in  a label.</li>
            <li>Labels are case sensitive.</li>
            <li>
              You can’t use reserved names in labels. Reserved names include “awswaf”, “aws”, “was”, “rule group”,
              “webacl”, “regexpatternset”, “Ipset”, and “managed”.
            </li>
          </ul>

          <AnotherLabel anotherLabelList={anotherLabelList} setAnotherLabelList={setAnotherLabelList} />
        </div>
      </Collapse>
    </div>
  );
}

export default ThenGroup;
