import { useLazyQuery } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from 'graphql/types';
import { AwsDescribeNetworkInterfacesRequest } from 'graphql/types/AwsDescribeNetworkInterfaces';

export interface IAwsNetworkInterfacesVariables {
  cloudId: number;
  region: string;
  request: {
    networkInterfaceIds ?: string;
    maxResults?: number;
    nextToken?: string;
    dryRun?: boolean;
    filters?: {
      name: string;
      values: string[];
    };
  };
}

export interface IAwsNetworkInterfacesResponseData {
  getAwsNetworkInterfaces: IGqlResponseNewData<AwsDescribeNetworkInterfacesRequest>;
}

const lazyGetAwsNetworkInterfaces = () =>
  useLazyQuery<IAwsNetworkInterfacesResponseData, IAwsNetworkInterfacesVariables>(query, {
    fetchPolicy: 'no-cache',
  });
export default lazyGetAwsNetworkInterfaces;
