import {gql} from '@apollo/client';

const query = gql`
query getAwsGroup($request: AwsGetGroupRequest, $cloudId: Long, $region: String) {
  getAwsGroup(getGroupRequest: $request, cloudId: $cloudId, region: $region) {
    result
    data {
      ... on AwsGetGroupResponse {
        group {
          groupArn
          name
          description
        }
      }
    }
  }
}`;
export default query;