import { gql } from '@apollo/client';

const query = gql`
  query getAwsListMFADevices($request: AwsListMfaDevicesRequest, $cloudId: Long, $region: String) {
    getAwsListMFADevices(listMfaDevicesRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsListMfaDevicesResponse {
          mfaDevices {
            userName
            serialNumber
            enableDate
          }
          isTruncated
          marker
        }
      }
    }
  }
`;
export default query;
