import { gql } from '@apollo/client';

const query = gql`
  query getAwsListPoliciesGrantingServiceAccess($request: AwsListPoliciesGrantingServiceAccessRequest, $cloudId: Long, $region: String) {
  getAwsListPoliciesGrantingServiceAccess(listPoliciesGrantingServiceAccessRequest: $request, cloudId: $cloudId, region: $region) {
    result
     data{
      ... on AwsListPoliciesGrantingServiceAccessResponse {
       policiesGrantingServiceAccess {
        serviceNamespace
        policies {
          policyName
          policyType
          entityType
          policyArn
          entityName
        }
      }
        isTruncated
        marker
      }
        }
        }
      }
  
`;
export default query;
