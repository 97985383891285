import { useLazyQuery } from '@apollo/client';
import query from './query';
import { AwsSubnetType } from 'graphql/types/AwsSubnet';
import { IGqlResponseNewData } from 'graphql/types';

export interface IAwsAllSubnetsVariables {
  cloudId: number;
  region: string;
  request: {
    maxResults: number;
    nextToken?: string;
    subnetIds?: string[];
    dryRun?: boolean;
    filters?: {
      name: string;
      values: string[];
    }
  }
}

export interface IAwsAllSubnetsResponseData {
  getAwsAllSubnets: IGqlResponseNewData<AwsSubnetType>;
}

const lazyGetAllAwsSubnet = () => useLazyQuery<IAwsAllSubnetsResponseData, IAwsAllSubnetsVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAllAwsSubnet;