import { useLazyQuery } from '@apollo/client';
import { IGqlResponseNewData } from 'graphql/types';
import query from './query';
import { AwsDescribeNetworkAclResponse } from 'graphql/types/AwsNetworkAcl';

export interface IAwskNetworkAclsVariables {
  cloudId: number;
  region: string;
  request: {
    maxResults: number;
    nextToken?: string;
    networkAclIds?: string[];
    filters?: {
      name: string;
      values: string[];
    };
    dryRun?: boolean;
  }
}

export interface IAwsDescribeNetworkAclResponseData {
  getAwsNetworkAcls: IGqlResponseNewData<AwsDescribeNetworkAclResponse>;
}

const lazyGetAwsNetworkAcls = () => useLazyQuery<IAwsDescribeNetworkAclResponseData, IAwskNetworkAclsVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsNetworkAcls;