import { useState, useEffect } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import InputAtom from 'components/v2/atoms/InputAtom';
import Icon from 'components/v2/atoms/Icon';
import IconTokenDomain from 'assets/svgs/v3/ico_policy_variables.svg';
import './index.scss';

interface IEditTokenImmunityTimeModalProps extends IBaseModalProps {
  header: string;
  subHeader?: string;
  note?: string;
  onSave: (value: number) => void;
}

const EditTokenImmunityTimeModal = ({
  header,
  subHeader,
  note,
  onSave,
  ...baseModalProps
}: IEditTokenImmunityTimeModalProps) => {
  const [secondsTimeInputValue, setSecondsTimeInputValue] = useState<number>(300);
  useEffect(() => {
    if (baseModalProps.open) {
    }
  }, [baseModalProps.open]);

  return (
    <BaseModal
      title={() => (
        <>
          <Icon width={32} height={32} src={IconTokenDomain} />
          <div className="title-header-modal">{header}</div>
        </>
      )}
      {...baseModalProps}
    >
      <div className="edit-token-immunity-time-model">
        <div className="container-note">
          <div className="note-title">{subHeader}</div>
          <div className="note-description">{note}</div>
        </div>

        <div className="token-immunity-time-row-container">
          <div className="token-immunity-time-row-input">
            <div className="column">
              <InputAtom
                type={'number'}
                value={secondsTimeInputValue.toString()}
                onChangeValue={(value: string) => {
                  if (parseInt(value) > 0) {
                    setSecondsTimeInputValue(parseInt(value));
                  } else {
                    setSecondsTimeInputValue(0);
                  }
                }}
                defaultValue={''}
                noClear={true}
                hasPrefixIcon={true}
              />
            </div>
          </div>
          <p className="text-detail">seconds</p>
          <div className="token-time-row-btn">
            <button
              className="btn revert-btn"
              onClick={() => {
                setSecondsTimeInputValue(300);
              }}
            >
              Revert to default
            </button>
          </div>
        </div>

        <div className="button-group">
          <button className="btn" onClick={baseModalProps.onClose}>
            Cancel
          </button>
          <button className="btn save-btn" onClick={() => onSave(secondsTimeInputValue)}>
            Save
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

export default EditTokenImmunityTimeModal;
