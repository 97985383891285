import React from 'react';
import './styles.scss';

type JSONViewerProps = {
  data: any;
  isJsonOnly?: boolean;
  bottomDescription?: string
};

const JSONViewer = (props: JSONViewerProps) => {
  const { data, bottomDescription } = props;

  return (
    <div className="json-container">
      <div className="json-content">
        <pre>{JSON.stringify(data, null, 4)}</pre>
      </div>
      <p className='bottom-description'>{bottomDescription}</p>
    </div>
  );
};

export default JSONViewer;
