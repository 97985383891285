import { useLazyQuery } from '@apollo/client';
import { IGqlResponseNewData } from 'graphql/types';
import query from './query';
import { AwsDescribeRouteTablesResponse } from 'graphql/types/AwsRouteTable';

export interface IGetAwsRouteTablesVariables {
  cloudId: number;
  region: string;
  request: {
    maxResults: number;
    nextToken?: string;
    filters?: {
      name: string;
      values: string[]
    };
    routeTableIds?: string[];
    dryRun?: boolean;
  }
}

export interface IGetAwsRouteTablesResponseData {
  getAwsRouteTables: IGqlResponseNewData<AwsDescribeRouteTablesResponse>;
}

const lazyGetAwsRouteTables = () => useLazyQuery<IGetAwsRouteTablesResponseData, IGetAwsRouteTablesVariables>(query, { fetchPolicy: 'no-cache' });
export default lazyGetAwsRouteTables;