import { gql } from '@apollo/client';

const query = gql`
  query getAwsListGroupPolicies($request: AwsListGroupPoliciesRequest, $cloudId: Long, $region: String) {
    getAwsListGroupPolicies(listGroupPoliciesRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsListGroupPoliciesResponse {
          policyNames
          isTruncated
          marker
        }
      }
    }
  }
`;
export default query;
