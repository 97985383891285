import { useState } from 'react';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import InputAtom from 'components/v2/atoms/InputAtom';
import IconAddManaged from 'assets/svgs/v3/ico_add_managed_statefull_rule_group.svg';
import Icon from 'components/v2/atoms/Icon';
import './index.scss';
import Table from 'components/v2/dataDisplay/Table';
import { ColumnType, RowType } from '@Types/v2/Table';
import ToggleSwitchAtom from 'components/v2/atoms/ToggleSwitchAtom';
import { handleFormatText } from 'utils/Common';

interface IAddManagedStatefullRuleGroupProps extends IBaseModalProps {
  onChangeValue?: () => void;
}

export interface RowData {
  id: number;
  name: string;
  description: string;
  capacity: number;
  alertMode: boolean;
}

const dummyDomainIp = [
  {
    id: 1,
    name: 'AbsedLegitBotNetCommandAndControlDomainsStrictOrder',
    description: 'Contains rules that allow you to block requests to a class of domains which are generally legitimate but are compromised and Amy host botnets. This can help reduce the risk of resources accessing botnets originating from the sources with poor reputation.',
    capacity: 100,
    alertMode: true
  },
  {
    id: 2,
    name: 'MalwareDomainStrictOrder',
    description: 'Contains rules that allow you to block requests to domains that are known for hosting malware. This can help reduce the risk of receiving malware or viruses originating from these known sources.',
    capacity: 100,
    alertMode: false
  },
  {
    id: 3,
    name: 'AbusedLegitMalwareDomainStrictOrder',
    description: 'Contains rules that allow you to block requests to a class of domains which are generally legitimate but are compromised and may host malware. This can help reduce the risk of receiving malware or viruses originating from these sources with poor reputation.',
    capacity: 100,
    alertMode: false
  }
]

const dummyThreatSignature = [
  {
    id: 1,
    name: 'ThreatSignaturesMalwareStrictOrder',
    description: 'Signatures that detect malware (tcp,udp,smtp,icmp,smb,ig) and worm.',
    capacity: 100,
    alertMode: false
  },
  {
    id: 2,
    name: 'ThreatSignaturesMalareWebStrictOrder',
    description: 'Signatures to detect malicious code in HTTP and TLS protocols.',
    capacity: 100,
    alertMode: false
  },
  {
    id: 3,
    name: 'ThreatSignatureEmergingEventsStrictOrder',
    description: 'Signatures with rules developed in response to active and short-lived campaigns and other temporary, high-profile events.',
    capacity: 100,
    alertMode: false
  },
  {
    id: 4,
    name: 'ThreatSignatureBotnetWindowsStrictOrder',
    description: 'Signatures that detect Windows botnets.',
    capacity: 100,
    alertMode: false
  },
  {
    id: 5,
    name: 'ThreatSignaturesFUPStrictOrder',
    description: '',
    capacity: 100,
    alertMode: false
  },
  {
    id: 6,
    name: 'ThrestSignaturesIOCStrictOrder',
    description: 'Signatures to detect activity related to Exploit Kits, their infrastructure, and delivery.',
    capacity: 100,
    alertMode: false
  }
]



const AddManagedStatefullRuleGroupModal = ({
  onChangeValue,
  ...baseModalProps
}: IAddManagedStatefullRuleGroupProps) => {
  const [domainIPRows, setDomainIpRows] = useState<RowData[]>(dummyDomainIp);
  const [threatSignatureRows, setThreadSinatureRows] = useState<RowData[]>(dummyThreatSignature);
  const [checkedDomainIpList, setCheckedDomainIpList] = useState<string[]>([]);
  const [selectedDomainIp, setSelectedDomainIp] = useState<string>('');
  const [checkedThreatSignatureList, setCheckedThreatSignatureList] = useState<string[]>([]);
  const [selectedThreatSignature, setSelectedThreatSignature] = useState<string>('');
  const domainIpColumns: ColumnType[] = [
    {
      label: 'Name',
      field: 'name',
      renderCell: (row: RowType) => {
      return <>
        <p className='content'>{handleFormatText(row.name)}</p>
        <p className='description'>{handleFormatText(row.description)}</p>
      </>}
    },
    {
      label: 'Capacity',
      field: 'capacity',
    },
    {
      label: 'Alert mode',
      field: 'alertMode',
      renderCell: (row: RowType) => <>
        <div className='alert-mode'>
          <ToggleSwitchAtom value={row.alertMode} onChange={(checked) => {
            const newRows = domainIPRows.map((r) =>
              r.id === row.id ? { ...r, alertMode: checked } : r
            );
            setDomainIpRows(newRows);
          }
          }/> 
          {row.alertMode ? <span className='enabled'>Enabled</span>:<span className='disabled'>Disabled</span>}
        </div>
      </>
    }
  ];

  const threatSignatureColumns: ColumnType[] = [
    {
      label: 'Name',
      field: 'name',
      renderCell: (row: RowType) => {
      return <>
        <p className='content'>{handleFormatText(row.name)}</p>
        <p className='description'>{handleFormatText(row.description)}</p>
      </>}
    },
    {
      label: 'Capacity',
      field: 'capacity',
    },
    {
      label: 'Alert mode',
      field: 'alertMode',
      renderCell: (row: RowType) => <>
        <div className='alert-mode'>
          <ToggleSwitchAtom value={row.alertMode} onChange={(checked) => {
            const newRows = threatSignatureRows.map((r) =>
              r.id === row.id ? { ...r, alertMode: checked } : r
            );
            setThreadSinatureRows(newRows);
          }
          }/> 
          {row.alertMode ? <span className='enabled'>Enabled</span>:<span className='disabled'>Disabled</span>}
        </div>
      </>
    }
  ];

  return (
    <BaseModal
      title={() => <>
      <div>
        <div className='title flex'>
          <Icon width={32} height={32} src={IconAddManaged} /> <label>Add managed stateful rule groups</label>
        </div>
        <p >Select the managed stateful rule groups that you want to add to your policy.</p>
      </div>
        </>}
      {...baseModalProps}>
      <div className="add-managed-modal">
        <div className='domain-ip'>
          <p className='title'>Domain and IP rule groups</p>
          <Table 
            columns={domainIpColumns} 
            rows={domainIPRows}
            reportCheckedList={(list: string[]) => {
              setCheckedDomainIpList(list);
            }}
            reportSelected={(id: string) => {
              setSelectedDomainIp(id);
            }}/>
        </div>
        <div className='thread-signature'>
          <p className='title'>Threat signature rule groups</p>
          <Table 
            columns={threatSignatureColumns} 
            rows={threatSignatureRows}
            reportCheckedList={(list: string[]) => {
              setCheckedThreatSignatureList(list);
            }}
            reportSelected={(id: string) => {
              setSelectedThreatSignature(id);
            }}/>
        </div>
        <div className="button-group">
          <button onClick={baseModalProps.onClose}
          >Cancel</button>
          <button className="save-btn"
            onClick={() => {}}
          >Add to policy</button>
        </div>
      </div>
    </BaseModal>
  );
};

export default AddManagedStatefullRuleGroupModal;
