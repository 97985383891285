import { gql } from '@apollo/client';

const query = gql`
query getAwsDescribeLoadBalancerAttributes($request: AwsDescribeLoadBalancerAttributesRequest, $cloudId: Long, $region: String) {
  getAwsDescribeLoadBalancerAttributes(describeLoadBalancerAttributesRequest: $request, cloudId: $cloudId, region: $region) {
    result
    data {
      ... on AwsDescribeLoadBalancerAttributesResponse {
        attributes {
          key
          value
        }
        }
      }
    }
  }
`;
export default query;