import DropdownAtom, { DropdownListDataType } from 'components/v2/atoms/DropdownAtom';
import { MATCH_TYPE_DATA } from '../../../../constant';

type MatchTypePropsType = {
  matchType: DropdownListDataType;
  setMatchType: (val: DropdownListDataType) => void;
};

function MatchType({ matchType, setMatchType }: MatchTypePropsType) {
  return (
    <div className="mw-50">
      <p className="rule-container-group-content-label">Match type</p>

      <DropdownAtom
        id={(Math.random() + 1).toString(36).substring(2, 8)}
        data={MATCH_TYPE_DATA}
        placeholder="Choose an inspection option"
        value={matchType}
        handleClick={setMatchType}
      />
    </div>
  );
}

export default MatchType;
