import {DropdownListDataType} from "components/v2/atoms/DropdownAtom";

export const spotRequestFilterDropdown: DropdownListDataType[] = [
  {id: 1, name: 'Request ID', value: 'requestId'},
  {id: 2, name: 'Request type', value: 'requestType'},
  {id: 3, name: 'Instance type', value: 'instance'},
  {id: 4, name: 'State', value: 'state'},
  {id: 5, name: 'Status', value: 'status'},
  {id: 6, name: 'Persistence', value: 'type'},
]

export const REQUEST_TYPE = {
  INSTANCE: 'instance',
  FLEET: 'fleet',
}

export const instanceTab = [
  { id: 'description', name: 'Description', title: 'Description'},
  { id: 'tags', name: 'Tags', title: 'Tags' },
];

export const fleetTab = [
  { id: 'description', name: 'Description', title: 'Description'},
  { id: 'tags', name: 'Tags', title: 'Tags' },
  { id: 'instances', name: 'Instances', title: 'Instances' },
  { id: 'history', name: 'History', title: 'History' },
];

