import { gql } from '@apollo/client';

const query = gql`
  query getAwsServiceLastAccessedDetails(
    $request: AwsGetServiceLastAccessedDetailsRequest
    $cloudId: Long
    $region: String
  ) {
    getAwsServiceLastAccessedDetails(
      getServiceLastAccessedDetailsRequest: $request
      cloudId: $cloudId
      region: $region
    ) {
      result
      data {
        ... on AwsGetServiceLastAccessedDetailsResponse {
          jobStatus
          jobType
          jobCreationDate
          servicesLastAccessed {
            serviceName
            lastAuthenticated
            serviceNamespace
            lastAuthenticatedEntity
            lastAuthenticatedRegion
            totalAuthenticatedEntities
            trackedActionsLastAccessed {
              actionName
              lastAccessedEntity
              lastAccessedTime
              lastAccessedRegion
            }
          }
          jobCompletionDate
          isTruncated
          marker
          error {
            message
            code
          }
        }
      }
    }
  }
`;
export default query;
