import { gql } from '@apollo/client';

const query = gql`
query getAwsAllNetworkAcls($request: AwsDescribeNetworkAclsRequest, $cloudId: Long, $region: String) {
  getAwsAllNetworkAcls(
    describeNetworkAclsRequest: $request
    cloudId: $cloudId
    region: $region
  ) {
    result
    data {
      ... on AwsNetworkAcl {
        associations {
          networkAclAssociationId
          networkAclId
          subnetId
        }
        entries {
          cidrBlock
          egress
          icmpTypeCode {
            code
            type
          }
          ipv6CidrBlock
          portRange {
            from
            to
          }
          protocol
          ruleAction
          ruleNumber
        }
        isDefault
        networkAclId
        vpcId
        ownerId
        tags {
          key
          value
        }
      }
    }
  }
}
`;
export default query;