import { gql } from '@apollo/client';

const query = gql`
query getAwsAllDescribeVolumes($request: AwsDescribeVolumesRequest, $cloudId: Long, $region: String) {
  getAwsAllDescribeVolumes(describeVolumesRequest: $request, cloudId: $cloudId, region: $region) {
    result
    nextToken
     data{
      ... on AwsVolume {
       attachments {
        attachTime
        device
        instanceId
        state
        volumeId
        deleteOnTermination
        associatedResource
        instanceOwningService
      }
        availabilityZone
        createTime
        encrypted
        kmsKeyId
        outpostArn
        size
        snapshotId
        state
        volumeId
        iops
        tags {
          key
          value
        }
        volumeType
        fastRestored
        multiAttachEnabled
        throughput
        sseType
        
        }
      }
    }
  }
`;
export default query;
