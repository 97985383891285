import React, {useEffect, useState} from 'react';
import Icon from "components/v2/atoms/Icon";
import IconDrownDirection from "assets/svgs/v3/ico_down_direction.svg";

import './index.scss'

function Collapse({label, children, className = ''}: any) {
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    return () => {
      setIsActive(false)
    }
  }, []);

  return (
    <div className={`collapse-container ${isActive ? 'active' : ''} ${className}`}>
      <div
        className='collapse-container-label'
        onClick={() => setIsActive(!isActive)}
      >
        <Icon width={16} height={16} src={IconDrownDirection} />

        {label}
      </div>

      <div className='collapse-container-children'>
        {children}
      </div>
    </div>
  );
}

export default Collapse;