import { RadioPropsType } from './types';
import RequiredIcon from 'assets/svgs/v2/ico_require.svg';
import RadioButtonGroup from './RadioButtonGroup';
// import './styles.scss'

const Radio = (props: RadioPropsType) => {
  const {
    title,
    description,
    topDescription,
    bottomDescription,
    data,
    value,
    onValueChanged,
    isRequired,
    disabled,
    isVertical,
    error,
  } = props;

  if (isVertical) {
    return (
      <div id="input-container">
        <div className="left-column-content">
          <p className="title">{title}</p>
          {isRequired && <img src={RequiredIcon} width={4} height={4} />}
        </div>
        <RadioButtonGroup
          data={data}
          value={value}
          onChangeValue={onValueChanged}
        />
      </div>
    );
  }

  return (
    <div id="radio-container">
      <tr className="row-content">
        <td className="left-column-content" />
        <td>
          <p className="description">{topDescription}</p>
        </td>
      </tr>
      <tr className="row-content">
        <div className="left-column-content">
          <p className="title">{title}</p>
          {isRequired && <img src={RequiredIcon} width={4} height={4} />}
        </div>
        <RadioButtonGroup
          data={data}
          value={value}
          onChangeValue={onValueChanged}
        />
      </tr>
      <tr className="row-content">
        <td className="left-column-content" />
        <td>
          <p className="description">{bottomDescription}</p>
        </td>
      </tr>
    </div>
  );
};

export default Radio;
