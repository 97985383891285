import { useMutation } from '@apollo/client';
import query from './query';
import { IGqlResponseNewData } from '../../types';
import { AwsCreateRegexPatternSetResponse } from 'apis/v2/Manage/WafSchema';

interface AwsTaglog {
  key: string;
  value: string;
}

interface AwsRegexLog {
  regexString: string;
}

export interface ICreateRegexPatternSetVariables {
  cloudId: number;
  region: string;
  reqData: {
    name: string;
    scope: string;
    description?: string;
    regularExpressionList: AwsRegexLog[];
    tags?: AwsTaglog[];
  }
}

export interface ICreateRegexPatternSetResponseData {
  createAwsRegexPatternSet: IGqlResponseNewData<AwsCreateRegexPatternSetResponse>;
}

const createRegexPatternSetMutation = () => useMutation<ICreateRegexPatternSetResponseData, ICreateRegexPatternSetVariables>(query);
export default createRegexPatternSetMutation;