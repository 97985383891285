
import {REQUEST_TYPE} from "pages/v2/Organ/Management/EC2/components/SpotRequest/Common/Constant";
import Instance from "pages/v2/Organ/Management/EC2/components/SpotRequest/Summary/Instance";
import Fleet from "pages/v2/Organ/Management/EC2/components/SpotRequest/Summary/Fleet";
import './index.scss';
import { SpotRequestSummaryPropsType } from '../../types';
import { AwsSpotFleetRequestConfig, AwsSpotInstanceRequest } from 'graphql/types/AwsDescribeSpotInstanceFleetRequestsCombinedResponse';

const SpotRequestSummary = (props: SpotRequestSummaryPropsType) => {
  const {cloudId, region, currentSpotInstance} = props;

  return (
    <>
      {
        currentSpotInstance ? (
          currentSpotInstance.requestType === REQUEST_TYPE.INSTANCE ? (
            <Instance currentSpotInstance={currentSpotInstance.responseData as AwsSpotInstanceRequest} />
          ) : (
            <Fleet
              cloudId={cloudId}
              region={region}
              currentSpotFleetInstance={currentSpotInstance.responseData as AwsSpotFleetRequestConfig}
            />
          )
        ) : (
          <div className="detail-data">
            <p className="empty-page">Select a item</p>
          </div>
        )
      }
    </>
  );
};

export default SpotRequestSummary;
