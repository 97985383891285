import { gql } from '@apollo/client';

const query = gql`
query getAwsDescribeRules($request: AwsDescribeRulesRequest, $cloudId: Long, $region: String) {
  getAwsDescribeRules(describeRulesRequest: $request, cloudId: $cloudId, region: $region) {
    result
    data {
      ... on AwsDescribeRulesResponse {
          rules {
            ruleArn
            priority
            conditions {
              field
              values
              hostHeaderConfig {
                values
              }
              pathPatternConfig {
                values
              }
              httpHeaderConfig {
                values
              }
              queryStringConfig {
                values {
                  key
                  value
                }
              }
              httpRequestMethodConfig {
                value
              }
              sourceIpConfig {
                value
              }
            }
            actions {
              type
              targetGroupArn
              authenticateOidcConfig {
                issuer
                authorizationEndpoint
                tokenEndpoint
                userInfoEndpoint
                clientId
                clientSecret
                sessionCookieName
                scope
                sessionTimeout
                authenticationRequestExtraParams
                onUnauthenticatedRequest
                useExistingClientSecret
              }
              authenticateCognitoConfig {
                userPoolArn
                userPoolClientId
                userPoolDomain
                sessionCookieName
                scope
                sessionTimeout
                authenticationRequestExtraParams
                onUnauthenticatedRequest
              }
              order
              redirectConfig {
                protocol
                port
                host
                path
                query
                statusCode
              }
              fixedResponseConfig {
                messageBody
                statusCode
                contentType
              }
              forwardConfig {
                targetGroups {
                  targetGroupArn
                  weight
                }
                targetGroupStickinessConfig {
                  enabled
                  durationSeconds
                }
              }
            }
            isDefault
          }
        nextMarker
        }
      }
    }
  }
`;
export default query;