import { useEffect, useMemo, useState } from 'react';
import './index.scss';

import LabelInput from 'components/v2/LabelInput';
import BaseModal, { IBaseModalProps } from 'components/v2/modals/BaseModal';
import Icon from 'components/v2/atoms/Icon';
import IconWarning from 'assets/svgs/v3/ico_warning.svg';
import IconDelete from 'assets/svgs/v3/ico_delete_network.svg';

interface IDisassociateRuleGroupsModalProps extends IBaseModalProps {
  name: string;
  header: string;
  onDelete: () => void;
}

const DisassociateRuleGroups = ({
  name,
  header,
  onDelete,
  ...baseModalProps
}:IDisassociateRuleGroupsModalProps) => {

  const [payload, setPayload] = useState<string>('');

  useEffect(() => {
    if (baseModalProps.open) {
      setPayload('');
    }
  }, [baseModalProps.open]);

  return (
    <BaseModal 
      title={() => <><Icon width={32} height={32} src={IconDelete} />{header}</>} 
      {...baseModalProps}
    >
      <div className="delete-webacl-modal">
        <div className={`row-1 flex col`}>
          <div className="header">
            <div className="delete-warning">
              <Icon width={32} height={32} src={IconWarning} />
              <p className="detail-warning">{name}</p>
            </div>
          </div>
        </div>
        <div className="row-2">
          <div className="button-group">
            <button className="big-sub-btn"
              onClick={baseModalProps.onClose}>Cancel</button>
            <button className="big-main-btn"
              onClick={() => onDelete()} >Disassociate from policy</button>
          </div>
          
        </div>
      </div>
    </BaseModal>
  );
};

export default DisassociateRuleGroups;
