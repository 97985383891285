import { gql } from '@apollo/client';

const query = gql`
  query getAwsListAttachedGroupPolicies(
    $request: AwsListAttachedGroupPoliciesRequest
    $cloudId: Long
    $region: String
  ) {
    getAwsListAttachedGroupPolicies(listAttachedGroupPoliciesRequest: $request, cloudId: $cloudId, region: $region) {
      result
      data {
        ... on AwsListAttachedGroupPoliciesResponse {
          attachedPolicies {
            policyName
            policyArn
          }
          isTruncated
          marker
        }
      }
    }
  }
`;
export default query;
